var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { ref: "headerQuery" },
        [
          _c(
            "el-row",
            { staticClass: "headerClass" },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "border-left": "5px solid #0073E9",
                    "padding-left": "10px",
                  },
                  attrs: { span: 4 },
                },
                [_vm._v("厂商维护")]
              ),
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "queryForm",
                      staticStyle: { float: "right" },
                      attrs: { model: _vm.queryParams, inline: true },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "name" } },
                        [
                          _c("el-autocomplete", {
                            staticStyle: {
                              width: "320px",
                              height: "36px!important",
                              "line-height": "36px",
                            },
                            attrs: {
                              "fetch-suggestions": _vm.queryManufacturerName,
                              placeholder:
                                "请输入厂商ID/名称/别名/编号/统一社会信用代码",
                              clearable: "",
                              size: "small",
                              "value-key": "name",
                            },
                            on: {
                              select: _vm.handleQuery,
                              clear: function ($event) {
                                return _vm.setBlur()
                              },
                            },
                            nativeOn: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleQuery($event)
                              },
                            },
                            model: {
                              value: _vm.queryParams.searchValue,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "searchValue", $$v)
                              },
                              expression: "queryParams.searchValue",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态", prop: "status" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "statusClass",
                              staticStyle: { width: "100px" },
                              attrs: {
                                placeholder: "状态",
                                clearable: "",
                                size: "small",
                              },
                              model: {
                                value: _vm.queryParams.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "status", $$v)
                                },
                                expression: "queryParams.status",
                              },
                            },
                            _vm._l(_vm.statusOptions, function (dict) {
                              return _c("el-option", {
                                key: "statusOptions" + dict.conceptId,
                                attrs: {
                                  label: dict.conceptVal,
                                  value: dict.conceptId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-search",
                                size: "small",
                              },
                              on: { click: _vm.handleQuery },
                            },
                            [_vm._v("检索")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "addClass",
                              attrs: {
                                icon: "el-icon-circle-plus-outline",
                                size: "small",
                              },
                              on: { click: _vm.handleAdd },
                            },
                            [_vm._v("新增厂商 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { icon: "fa fa-cog", size: "small" },
                              on: { click: _vm.columnSettings },
                            },
                            [_vm._v("设置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { ref: "tableContainer" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "refundTable",
              attrs: {
                border: "",
                stripe: "",
                data: _vm.manufacturerList,
                height: _vm.tableHeight,
              },
              on: {
                "sort-change": _vm.tableSortChange,
                "row-contextmenu": _vm.rowContextmenu,
              },
            },
            [
              _vm._l(_vm.columnOptions, function (data) {
                return _c("el-table-column", {
                  key: "columnOptions" + data.id,
                  attrs: {
                    prop: data.prop,
                    label: data.name,
                    fixed: data.fixed,
                    resizable: false,
                    sortable: data.sortable,
                    "min-width": data.width,
                    align: data.align,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            data.prop == "aliasList"
                              ? _c(
                                  "div",
                                  _vm._l(
                                    scope.row.aliasList,
                                    function (item, index) {
                                      return _c(
                                        "P",
                                        { key: item.id + "aliasList" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s("[" + (index + 1) + "]") +
                                              _vm._s(item.alias)
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    index <
                                                    scope.row.aliasList.length -
                                                      1,
                                                  expression:
                                                    "index<scope.row.aliasList.length-1",
                                                },
                                              ],
                                            },
                                            [_vm._v(";")]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                )
                              : data.prop == "labelList"
                              ? _c(
                                  "div",
                                  _vm._l(
                                    scope.row.labelList,
                                    function (item, index) {
                                      return _c(
                                        "P",
                                        { key: item.id + "labelList" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s("[" + (index + 1) + "]") +
                                              _vm._s(item.label)
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    index <
                                                    scope.row.labelList.length -
                                                      1,
                                                  expression:
                                                    "index<scope.row.labelList.length-1",
                                                },
                                              ],
                                            },
                                            [_vm._v(";")]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                )
                              : data.prop == "manufacturerCode"
                              ? _c(
                                  "div",
                                  _vm._l(
                                    scope.row.codeList,
                                    function (item, index) {
                                      return _c(
                                        "P",
                                        { key: item.id + "codeList" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s("[" + (index + 1) + "]") +
                                              _vm._s(item.manufacturerCode)
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    index <
                                                    scope.row.codeList.length -
                                                      1,
                                                  expression:
                                                    "index<scope.row.codeList.length-1",
                                                },
                                              ],
                                            },
                                            [_vm._v(";")]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _c("div", [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row[data.prop])),
                                  ]),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                key: "status",
                attrs: {
                  label: "状态",
                  align: "center",
                  width: "100",
                  resizable: false,
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          staticClass: "switch",
                          attrs: {
                            "active-color": "#5DB730",
                            "active-text": "启用",
                            "inactive-text": "停用",
                            "inactive-color": "#B5CCF5",
                            "active-value": "0",
                            "inactive-value": "1",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleStatusChange(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.status,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "status", $$v)
                            },
                            expression: "scope.row.status",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm.isResult === false
                ? _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-booking.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ])
                : _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-left-data.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("未查询到结果，请修改查询条件重试！")]),
                  ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
          "page-sizes": [10, 20, 50, 100],
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.selectManufacturerList,
        },
      }),
      _vm.tableContextMenuVisible
        ? _c("table-context-menu", {
            ref: "contextbutton",
            attrs: { action: _vm.tableAction },
            on: { foo: _vm.foo, handleTableAction: _vm.handleTableAction },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "厂商维护",
            visible: _vm.manufacturerEditDialogVisible,
            width: "80%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.manufacturerEditDialogVisible = $event
            },
            close: _vm.cancelEditManufacturer,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "manufacturerForm",
              attrs: {
                "status-icon": "",
                model: _vm.manufacturerFormData,
                "label-width": "140px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "厂商名称:",
                            prop: "name",
                            rules: [
                              {
                                required: true,
                                message: "请输入厂商名称",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: {
                              placeholder: "请输入厂商名称",
                              maxlength: "180",
                              clearable: "",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.manufacturerFormData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.manufacturerFormData, "name", $$v)
                              },
                              expression: "manufacturerFormData.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "厂商编号:" } },
                        [
                          _vm._l(
                            _vm.manufacturerFormData.codeList,
                            function (item, index) {
                              return _c(
                                "el-tag",
                                {
                                  key: item.id + item.manufacturerCode,
                                  staticStyle: {
                                    height: "32px",
                                    padding: "0 10px",
                                    "line-height": "30px",
                                    "font-size": "14px",
                                  },
                                  attrs: {
                                    closable: "",
                                    "disable-transitions": false,
                                  },
                                  on: {
                                    close: function ($event) {
                                      return _vm.handleCodeClose(item, index)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(item.manufacturerCode) + " "
                                  ),
                                ]
                              )
                            }
                          ),
                          _vm.manufacturerCodeInputVisible
                            ? _c("el-input", {
                                ref: "saveCodeTagInput",
                                staticClass: "input-new-tag",
                                attrs: {
                                  size: "small",
                                  maxlength: "30",
                                  clearable: "",
                                  "show-word-limit": "",
                                },
                                on: { blur: _vm.handleCodeInputConfirm },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleCodeInputConfirm($event)
                                  },
                                },
                                model: {
                                  value: _vm.manufacturerCodeInputValue,
                                  callback: function ($$v) {
                                    _vm.manufacturerCodeInputValue = $$v
                                  },
                                  expression: "manufacturerCodeInputValue",
                                },
                              })
                            : _c(
                                "el-button",
                                {
                                  staticClass: "button-new-tag",
                                  attrs: { size: "small" },
                                  on: { click: _vm.showCodeInput },
                                },
                                [_vm._v("+ 添加新编号")]
                              ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "统一社会信用代码:",
                            prop: "uscCode",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: {
                              placeholder: "请输入统一社会信用代码",
                              maxlength: "30",
                              clearable: "",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.manufacturerFormData.uscCode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.manufacturerFormData,
                                  "uscCode",
                                  $$v
                                )
                              },
                              expression: "manufacturerFormData.uscCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "注册地址:", prop: "address" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: {
                              placeholder: "请输入统一注册地址",
                              maxlength: "200",
                              clearable: "",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.manufacturerFormData.address,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.manufacturerFormData,
                                  "address",
                                  $$v
                                )
                              },
                              expression: "manufacturerFormData.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系方式:", prop: "contacts" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: {
                              placeholder: "请输入统一联系方式",
                              maxlength: "50",
                              clearable: "",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.manufacturerFormData.contacts,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.manufacturerFormData,
                                  "contacts",
                                  $$v
                                )
                              },
                              expression: "manufacturerFormData.contacts",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "邮编:", prop: "zipCode" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: {
                              placeholder: "请输入邮编",
                              maxlength: "20",
                              clearable: "",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.manufacturerFormData.zipCode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.manufacturerFormData,
                                  "zipCode",
                                  $$v
                                )
                              },
                              expression: "manufacturerFormData.zipCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "传真:", prop: "fax" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: {
                              placeholder: "请输入传真",
                              maxlength: "20",
                              clearable: "",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.manufacturerFormData.fax,
                              callback: function ($$v) {
                                _vm.$set(_vm.manufacturerFormData, "fax", $$v)
                              },
                              expression: "manufacturerFormData.fax",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "网址:", prop: "website" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: {
                              placeholder: "请输入网址",
                              maxlength: "100",
                              clearable: "",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.manufacturerFormData.website,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.manufacturerFormData,
                                  "website",
                                  $$v
                                )
                              },
                              expression: "manufacturerFormData.website",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "原厂家:",
                            prop: "originalManufacturerId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "500px" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                remote: "",
                                "reserve-keyword": "",
                                placeholder: "请输入关键字搜索",
                                "remote-method": _vm.queryManufacturer,
                                loading: _vm.loading,
                              },
                              model: {
                                value:
                                  _vm.manufacturerFormData
                                    .originalManufacturerId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.manufacturerFormData,
                                    "originalManufacturerId",
                                    $$v
                                  )
                                },
                                expression:
                                  "manufacturerFormData.originalManufacturerId",
                              },
                            },
                            _vm._l(_vm.manufacturerOptions, function (item) {
                              return _c("el-option", {
                                key: item.id + "manufacturerOptions",
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "别名:" } },
                        [
                          _vm._l(
                            _vm.manufacturerFormData.aliasList,
                            function (item, index) {
                              return _c(
                                "el-tag",
                                {
                                  key: item.id + item.alias,
                                  staticStyle: {
                                    height: "32px",
                                    padding: "0 10px",
                                    "line-height": "30px",
                                    "font-size": "14px",
                                  },
                                  attrs: {
                                    closable: "",
                                    "disable-transitions": false,
                                  },
                                  on: {
                                    close: function ($event) {
                                      return _vm.handleAliasClose(item, index)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.alias) + " ")]
                              )
                            }
                          ),
                          _vm.manufacturerAliasInputVisible
                            ? _c("el-input", {
                                ref: "saveAliasTagInput",
                                staticClass: "input-new-tag",
                                attrs: {
                                  size: "small",
                                  maxlength: "180",
                                  clearable: "",
                                  "show-word-limit": "",
                                },
                                on: { blur: _vm.handleAliasInputConfirm },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleAliasInputConfirm($event)
                                  },
                                },
                                model: {
                                  value: _vm.manufacturerAliasInputValue,
                                  callback: function ($$v) {
                                    _vm.manufacturerAliasInputValue = $$v
                                  },
                                  expression: "manufacturerAliasInputValue",
                                },
                              })
                            : _c(
                                "el-button",
                                {
                                  staticClass: "button-new-tag",
                                  attrs: { size: "small" },
                                  on: { click: _vm.showAliasInput },
                                },
                                [_vm._v("+ 添加新别名")]
                              ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "标签:" } },
                        [
                          _vm._l(
                            _vm.manufacturerFormData.labelList,
                            function (item, index) {
                              return _c(
                                "el-tag",
                                {
                                  key: item.id + item.label,
                                  staticStyle: {
                                    height: "32px",
                                    padding: "0 10px",
                                    "line-height": "30px",
                                    "font-size": "14px",
                                  },
                                  attrs: {
                                    closable: "",
                                    "disable-transitions": false,
                                  },
                                  on: {
                                    close: function ($event) {
                                      return _vm.handleLabelClose(item, index)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            }
                          ),
                          _vm.manufacturerLabelInputVisible
                            ? _c("el-input", {
                                ref: "saveLabelTagInput",
                                staticClass: "input-new-tag",
                                attrs: {
                                  size: "small",
                                  maxlength: "80",
                                  clearable: "",
                                  "show-word-limit": "",
                                },
                                on: { blur: _vm.handleLabelInputConfirm },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleLabelInputConfirm($event)
                                  },
                                },
                                model: {
                                  value: _vm.manufacturerLabelInputValue,
                                  callback: function ($$v) {
                                    _vm.manufacturerLabelInputValue = $$v
                                  },
                                  expression: "manufacturerLabelInputValue",
                                },
                              })
                            : _c(
                                "el-button",
                                {
                                  staticClass: "button-new-tag",
                                  attrs: { size: "small" },
                                  on: { click: _vm.showLabelInput },
                                },
                                [_vm._v("+ 添加新标签")]
                              ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 12 } }),
                  _c("el-col", { attrs: { span: 12 } }),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注信息:", prop: "comment" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px", float: "left" },
                            attrs: {
                              type: "textarea",
                              rows: 6,
                              placeholder: "请输入备注信息",
                              maxlength: "500",
                              clearable: "",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.manufacturerFormData.comment,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.manufacturerFormData,
                                  "comment",
                                  $$v
                                )
                              },
                              expression: "manufacturerFormData.comment",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "40px" },
                      attrs: { span: 20 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            "margin-left": "20px",
                            background: "#2B60F8",
                            float: "right",
                          },
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.confirmEditManufacturer },
                        },
                        [_vm._v("确定 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-right": "10px",
                          },
                          attrs: { size: "small" },
                          on: { click: _vm.cancelEditManufacturer },
                        },
                        [_vm._v("取消 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }