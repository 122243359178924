import request from '@/utils/request'


//开始数据处理
export function importDrug() {
  return request({
    url: '/tools/nhsaHosDrug/importDrug',
    method: 'post',
    timeout: 20 * 1000,
  })
}



// 查询上传记录列表
export function uploadRecordList(query) {
  return request({
    url: '/tools/nhsaHosDrug/uploadRecordList',
    method: 'post',
    params: query
  })
}

// 获取上传任务执行进度
export function getProgress() {
  return request({
    url: '/tools/nhsaHosDrug/getProgress',
    method: 'get'
  })
}


// 删除版本数据
export function delVersion(versionNo) {
  return request({
    url: '/tools/nhsaHosDrug/delVersion/' + versionNo,
    method: 'get',
    timeout: 60 * 1000,
  })
}

// 下载差异报告
export function downloadReport(versionNo) {
  return request({
    url: '/tools/nhsaHosDrug/downloadReportFile',
    method: 'get',
    params: {versionNo:versionNo},
    responseType: 'blob'
  })
}

// 查询药品列表
export function nhsaHosDrugList(query) {
  query.downloadFlag=0
  return request({
    url: '/tools/nhsaHosDrug/drugList',
    method: 'post',
    params: query,
    timeout: 30 * 1000,
  })
}


// 下载药品列表
export function downloadNhsaHosDrugList(query) {
  query.downloadFlag=1
  return request({
    url: '/tools/nhsaHosDrug/drugList',
    method: 'post',
    params: query,
    timeout: 5*60 * 1000,
    responseType: 'blob'
  })
}


// 查询全部版本号
export function selectAllVersionNo() {
  return request({
    url: '/tools/nhsaHosDrug/selectAllVersionNo',
    method: 'get'
  })
}
