/***知识库版本升级 */
// 
export const QUERY_OBJ = {
  orgName: '', // 机构名称
  status: '' // 状态
}
export const STATUS_OPTIONS = [
  // 1:等待中 2:升级中 3:升级完成 4:已取消
  {
    value: '1',
    label: '等待中'
  },
  {
    value: '2',
    label: '升级中'
  },
  {
    value: '3',
    label: '升级完成'
  },
  {
    value: '4',
    label: '已取消'
  }
]
export const SEARCH_OBJ = {
  orgName: '', // 机构名称
  pageNum: 1,
  pageSize: 10,
  status: '',
  statusList: []
}
