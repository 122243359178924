import request from '@/utils/request'

// 获得注册机构列表
export function registerDeptList(query) {
  return request({
    url: '/system/dept/getRegisterDeptList',
    method: 'post',
    params: query
  })
}


// 获得注册机构的菜单选择树
export function registerDeptMenuTree(deptId) {
  return request({
    url: '/system/dept/getRegisterDeptMenuTree',
    method: 'get',
    params: {deptId:deptId}
  })
}

// 编辑注册机构的菜单
export function updateRegisterDeptMenu(data) {
  return request({
    url: '/system/dept/updateRegisterDeptMenu',
    method: 'post',
    data: data
  })
}


// 获得注册机构的用户列表
export function registerDeptUserList(deptId) {
  return request({
    url: '/system/dept/registerDeptUserList',
    method: 'get',
    params: {deptId:deptId}
  })
}
