import request from '@/utils/request'


//开始数据处理
export function importNhsaDrug(batchNumber) {
  return request({
    url: '/tools/nhsaDrug/importNhsaDrug',
    method: 'post',
    params: {batchNumber:batchNumber},
    timeout: 20 * 1000,
  })
}



// 查询上传记录列表
export function uploadRecordList(query) {
  return request({
    url: '/tools/nhsaDrug/uploadRecordList',
    method: 'post',
    params: query
  })
}



// 删除版本数据
export function delVersion(versionNo) {
  return request({
    url: '/tools/nhsaDrug/delVersion/' + versionNo,
    method: 'get',
    timeout: 60 * 1000,
  })
}




// 获取上传任务执行进度
export function getProgress() {
  return request({
    url: '/tools/nhsaDrug/getProgress',
    method: 'get'
  })
}


// 下载差异报告
export function downloadReport(versionNo,fileType) {
  return request({
    url: '/tools/nhsaDrug/downloadReportFile',
    method: 'get',
    params: {versionNo:versionNo,fileType:fileType},
    responseType: 'blob'
  })
}


// 查询国家医保药品列表
export function nhsaDrugList(query) {
  query.downloadFlag=0
  return request({
    url: '/tools/nhsaDrug/drugList',
    method: 'post',
    params: query,
    timeout: 30 * 1000,
  })
}

// 下载国家医保药品列表
export function downloadNhsaDrugList(query) {
  query.downloadFlag=1
  return request({
    url: '/tools/nhsaDrug/drugList',
    method: 'post',
    params: query,
    timeout: 5*60 * 1000,
    responseType: 'blob'
  })
}


// 查询最新版本的国家医保药品列表
export function selectMaxVersionNhsaDrugList(query) {
  return request({
    url: '/tools/nhsaDrug/selectMaxVersionDrugList',
    method: 'post',
    params: query
  })
}


// 查询全部版本号
export function selectAllVersionNo() {
  return request({
    url: '/tools/nhsaDrug/selectAllVersionNo',
    method: 'get'
  })
}

