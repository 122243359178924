var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "layout-container platform-report" },
    [
      _c(
        "el-header",
        { staticClass: "platform-report-header" },
        [
          _c(
            "div",
            {
              staticClass: "title-header-container",
              attrs: { id: "1" },
              on: { click: _vm.onSearchTable },
            },
            [
              _c("span", { staticClass: "title-header" }),
              _c("h4", { staticClass: "title-text" }, [
                _vm._v("目标数据审核设置"),
              ]),
            ]
          ),
          _c(
            "el-form",
            { attrs: { model: _vm.queryObj, inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-autocomplete", {
                    staticStyle: {
                      width: "200px",
                      height: "36px!important",
                      "line-height": "36px",
                    },
                    attrs: {
                      "fetch-suggestions": _vm.onOrgSearch,
                      placeholder: "输入机构名称",
                      clearable: "",
                      size: "medium",
                      "value-key": "name",
                    },
                    model: {
                      value: _vm.queryObj.orgName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.queryObj,
                          "orgName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "queryObj.orgName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        icon: "el-icon-search",
                      },
                      on: { click: _vm.onSearchTable },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-main",
        { staticClass: "platform-report-main" },
        [
          _c(
            "el-table",
            {
              ref: "tableWrap",
              staticClass: "platform-report-table",
              staticStyle: { width: "100%", height: "100%" },
              attrs: {
                data: _vm.tableData,
                "header-cell-style": { textAlign: "center" },
                stripe: "",
                border: "",
              },
              on: { "sort-change": _vm.onSortChange },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", width: "100", fixed: "" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("机构ID")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.orgId))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("机构名称")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.orgName))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("审核字段数")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.dmFieldStr))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("质控字段数")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.evalFieldStr))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "100",
                  type: "index",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("操作")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onSet(scope.row)
                              },
                            },
                          },
                          [_vm._v("设置")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-footer",
        [
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.queryObj.pageNum,
              limit: _vm.queryObj.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryObj, "pageNum", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryObj, "pageSize", $event)
              },
              pagination: _vm.getTableHandle,
            },
          }),
        ],
        1
      ),
      _vm.isShowDialog
        ? _c("settings-dialog", {
            attrs: {
              "org-id": _vm.currentRow.orgId,
              "is-show-dialog": _vm.isShowDialog,
            },
            on: { closeDialog: _vm.onCloseDialog },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }