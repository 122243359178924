/**目标数据审核设置 */
import request from '@/utils/request'

// 分页查询接口
export function getFieldSettingsListApi(data) {
  return request({
    url: '/dm/sys/conf/field/list',
    method: 'get',
    params: data
  })
}
// 根据部门名称查找部门相关内容
export function getOrgDataApi(data) {
  return request({
    url: '/dm/sys/conf/field/org/search',
    method: 'get',
    params: data
  })
}
// 查询该机构审核字段规则
export function getFieldsRuleByOrgApi(data) {
  return request({
    url: '/dm/sys/conf/field/org',
    method: 'get',
    params: data
  })
}
// 更新该机构审核字段规则
export function updateFieldsRuleByOrgApi(data) {
  return request({
    url: '/dm/sys/conf/field/org',
    method: 'put',
    data
  })
}
