<template>
<div>
  <h1>非外链页面2</h1>
  <el-input v-model="name" placeholder="请输入内容"></el-input>
  <el-button type="primary" @click="goto('/hello/test111')">跳转到非外链页面1</el-button>
  <el-button type="primary" @click="goto('/hello/list')">跳转到外链页面list</el-button>
</div>




</template>
<script>
import { pushRoute } from '@/utils/routerUtil'
export default {
  name: 'Test222',
  computed: {

  },
  data() {
    return {
      total: 0,
      tableData: [],
      name:'2'
    }
  },
  async created() {
  },
  async mounted() {

  },
  methods: {
    goto(path){
      pushRoute(path)
    }


  }
}
</script>
<style lang="scss" scoped>

</style>
