<template>
  <div class="app-container" style="overflow: auto"
       v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="请稍后">


    <div ref="headerQuery">
      <el-row class="headerClass">
        <el-col :span="4" style="border-left: 5px solid #0073E9;padding-left: 10px;">国家医保药品列表</el-col>
        <el-col :span="20">
          <el-form :model="queryParams" ref="queryForm" :inline="true" style="float: right">


            <el-form-item label="代码/通用名/商品名:" prop="searchValue">
              <el-input v-model="queryParams.searchValue" placeholder="代码/通用名/商品名" maxlength="50" clearable></el-input>
            </el-form-item>

            <el-form-item label="本位码/批准文号:" prop="searchCode">
              <el-input v-model="queryParams.searchCode" placeholder="本位码/批准文号" maxlength="50" clearable></el-input>
            </el-form-item>

            <el-form-item label="厂商:" prop="manufacturer">
              <el-input v-model="queryParams.manufacturer" placeholder="厂商" maxlength="50" clearable></el-input>
            </el-form-item>

            <el-form-item label="关联知识库药品" prop="relevanceStatus" v-if="!this.queryParams.versionNo">
              <el-select
                v-model="queryParams.relevanceStatus"
                placeholder="关联状态"
                clearable
                size="small"
                class="statusClass"
                style="width: 100px"
              >
                <el-option
                  v-for="dict in relevanceStatusOptions"
                  :key="'relevanceStatus'+dict.code"
                  :label="dict.name"
                  :value="dict.code"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="版本号:" prop="versionNo">
              <el-select
                v-model="queryParams.versionNo"
                placeholder="版本号"
                size="small"
                class="statusClass"
                style="width: 200px"
                @change="versionNoChange"
                clearable
              >
                <el-option
                  v-for="item in versionNoOptions"
                  :key="item.versionNo+'versionNo'"
                  :label="item.versionNo"
                  :value="item.versionNo"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="类型:" prop="modifyType" v-if="this.queryParams.versionNo">
              <el-select
                v-model="queryParams.modifyType"
                placeholder="版本号"
                size="small"
                class="statusClass"
                style="width: 200px"
                clearable
              >
                <el-option
                  v-for="item in modifyTypeOptions"
                  :key="item.code+'modifyType'"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">检索</el-button>
              <el-button type="primary"  icon="el-icon-document" size="small" @click="handleExport">导出</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>


    <div ref="tableContainer">
      <el-table
        border
        stripe
        ref="refundTable"
        :data="drugList"
        :height="tableHeight"
        v-loading="loading"
        @sort-change="tableSortChange"
        @row-contextmenu="rowContextmenu"
        :span-method="objectSpanMethod"
        :cell-class-name="compareTableCellClass"
      >
        <el-table-column
          v-for="data in tableOptions"
          :key="'tableOptions'+data.id"
          :prop="data.prop"
          :label="data.name"
          :fixed="data.fixed"
          :resizable="false"
          :sortable="data.sortable"
          :min-width="data.width"
          v-if="data.show"
          :align="data.align"
        >
          <template slot-scope="scope">

            <div v-if="data.prop == 'modifyType'">
              <el-tag type="info" v-show="scope.row.modifyType==0">无变化</el-tag>
              <el-tag  v-show="scope.row.modifyType==1">修改</el-tag>
              <el-tag type="success" v-show="scope.row.modifyType==2">新增</el-tag>
              <el-tag type="danger" v-show="scope.row.modifyType==3">删除</el-tag>
            </div>

            <div v-else>
              <span>{{ scope.row[data.prop] }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column  label="操作" fixed="right" width="80"  v-if="!this.queryParams.versionNo"
                          align="center">
          <template slot-scope="scope">
            <el-button
              size="mini" type="text"
              @click="editNhsaDrugComment(scope.row)"
            >编辑备注
            </el-button>
          </template>
        </el-table-column>
        <template slot="empty" v-if="drugList.length==0">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
    </div>

    <table-context-menu v-if="tableContextMenuVisible"
                        @foo="foo" ref="contextbutton"
                        :action="tableAction"
                        @handleTableAction="handleTableAction"
    ></table-context-menu>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="selectNhsaDrugList"
      :page-sizes="[10, 20, 50, 100]"
    />



    <el-dialog v-dialogDrag title="相关知识库药品" width="60%" append-to-body :visible.sync="relevanceDrugDialogVisible"
               :close-on-click-modal="false"
    >
      <el-row>
        <el-col>
          <span style="margin-right: 10px;">{{selectTableRow.registeredProductName}}</span>
          <span style="margin-right: 10px;">{{selectTableRow.registeredOutlook}}</span>
          <span style="margin-right: 10px;">{{selectTableRow.companyNamesc}}</span>
          <span>{{selectTableRow.approvalCode}}</span>
        </el-col>
      </el-row>
      <el-table
        border
        stripe
        ref="relevanceDrugTable"
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        :data="relevanceDrugTableData"
        :height="300"
      >
        <el-table-column  type="index" width="40" fixed="left"></el-table-column>
        <el-table-column property="pkgDrugProdId" label="包装药品ID" width="80"></el-table-column>
        <el-table-column property="drugName" label="包装药品名称" min-width="120"></el-table-column>
        <el-table-column property="packSpecDesc" label="包装规格描述" width="100"></el-table-column>
        <el-table-column property="packTypeDesc" label="包装类型" width="80"></el-table-column>
        <el-table-column property="packQty" label="数量" width="120">
          <template slot-scope="scope">
            {{ scope.row.packQty }}
            {{ scope.row.specUnitDesc }}
            <span style="font-size: 18px;font-weight: bold;">/</span>
            {{ scope.row.packQtyUnitDesc }}
          </template>
        </el-table-column>
        <el-table-column property="matId" label="包装材料ID" width="80"></el-table-column>
        <el-table-column property="wrapperDesc" label="包装材料描述" width="100"></el-table-column>
        <el-table-column property="stateMedicareNum" label="国家医保代码" width="150"></el-table-column>
        <el-table-column property="matId" label="关联状态" width="80" fixed="right">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.relevanceStatus==1">已关联</el-tag>
            <el-tag type="info" v-if="scope.row.relevanceStatus==0">未关联</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button
              :disabled="scope.row.relevanceStatus==1"
              size="mini" type="text" title="更新国家医保代码"
              @click="updateMedicareNum(scope.row.matId, relevanceDrugQueryParams.stateMedicareNum)"
            >更新
            </el-button>

          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="relevanceDrugTableDataTotal>0"
        :total="relevanceDrugTableDataTotal"
        :page.sync="relevanceDrugQueryParams.pageNum"
        :limit.sync="relevanceDrugQueryParams.pageSize"
        @pagination="selectRelevanceDrugList(undefined)"
        :page-sizes="[10, 20, 50, 100]"
      />
    </el-dialog>


    <el-dialog v-dialogDrag title="备注信息编辑" width="500px" append-to-body
               :visible.sync="editDrugCommentDialogVisible"
               :close-on-click-modal="false"
    >
      <el-input type="textarea" :rows="6" placeholder="请输入备注信息"
                v-model="drugComment"
                maxlength="500" clearable show-word-limit
                style="width: 450px;"
      />
      <span slot="footer" class="dialog-footer" style="margin-right:20px;">
        <el-button @click="editDrugCommentDialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="submitEditDrugComment">确 定</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>

import conceptConst from '@/const/conceptConst'
import AcrossUtil from '@/utils/acrossUtil'
import { UCgetConfig } from '@/utils/columnSettings'
import { GLOBAL_CONFIGURATION_CONFIGKEY } from '@/utils/constants'
import { isEmpty,axiosDownload } from '@/utils/common'
import { nhsaDrugList,selectAllVersionNo,downloadNhsaDrugList } from '@/api/assistTool/nhsaDrug'
import { nhsaDrugList as fullNhsaDrugList ,downloadNhsaDrugList as downloadFullNhsaDrugList,updateDrugComment} from '@/api/dkm/dictDrug'
import { selectPdProdMatList,updateMedicareNum } from '@/api/dkm/drugProd'
export default {
  name: 'NhsaDrugList',
  components: {},
  data() {
    return {
      fullscreenLoading: false,
      conceptConst,
      // 遮罩层
      loading: true,
      drugList: [],
      // 总条数
      total: 0,
      // 表格高度
      tableHeight: window.innerHeight - 100 - 150,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        searchValue: null,
        orderByColumn: undefined,
        isAsc: undefined,
        versionNo:undefined,
        modifyType:4,
        searchCode:undefined,
        relevanceStatus:0,
        manufacturer:""
      },
      /*表格右键菜单显示隐藏*/
      tableContextMenuVisible: false,
      tableAction: [{ name: '查询相关知识库药品' }],
      versionNoOptions:[],
      modifyTypeOptions:[
        {code:4,name:'当前版本全量数据'},
        {code:0,name:'与之前版本完全一致'},
        {code:1,name:'当前版本修改'},
        {code:2,name:'当前版本新增'},
        {code:3,name:'当前版本删除'},
      ],
      relevanceStatusOptions:[
        {code:0,name:'全部'},
        {code:1,name:'已关联'},
        {code:2,name:'未关联'},
      ],
      /*查询关联知识库药品查询参数*/
      relevanceDrugQueryParams:{
        pageNum: 1,
        pageSize: 10,
        orderByColumn: undefined,
        isAsc: undefined,
        approvalNum:undefined,
        standardCode:undefined,
        stateMedicareNum:undefined

      },
      /*关联知识库药品表格数据*/
      relevanceDrugTableData:[],
      relevanceDrugTableDataTotal:0,
      relevanceDrugDialogVisible:false,
      selectTableRow:{},
      editDrugCommentDialogVisible:false,
      drugComment:"",
      drugId:""


    }
  },
  computed: {
    tableOptions() {
      return [
        {
          id: 1,
          prop: 'ypbm',
          name: '药品代码',
          fixed: true,
          show: true,
          sortable: 'custom',
          align: 'center',
          width: '100'
        },
        {
          id: 2,
          prop: 'registeredProductName',
          name: '注册名称',
          fixed: true,
          show: true,
          sortable: this.queryParams.versionNo?false:'custom',
          align: 'center',
          width: '120'
        },
        {
          id: 3,
          prop: 'goodsName',
          name: '商品名',
          fixed: false,
          show: true,
          sortable: this.queryParams.versionNo?false:'custom',
          align: 'center',
          width: '70'
        },
        {
          id: 4,
          prop: 'registeredMedicineModel',
          name: '注册剂型',
          fixed: false,
          show: true,
          align: 'center',
          width: '70'
        },
        {
          id: 5,
          prop: 'registeredOutlook',
          name: '注册规格',
          fixed: false,
          show: true,
          align: 'center',
          width: '80'
        },
        {
          id: 51,
          prop: 'realitySpec',
          name: '实际规格',
          fixed: false,
          show: true,
          align: 'center',
          width: '80'
        },
        {
          id: 6,
          prop: 'materialName',
          name: '包装材料',
          fixed: false,
          show: true,
          align: 'center',
          width: '80'
        },
        {
          id: 7,
          prop: 'factor',
          name: '最小包装数量',
          fixed: false,
          show: true,
          align: 'center',
          width: '80'
        },{
          id: 8,
          prop: 'minUnit',
          name: '最小制剂单位',
          fixed: false,
          show: true,
          align: 'center',
          width: '80'
        },{
          id: 9,
          prop: 'unit',
          name: '最小包装单位',
          fixed: false,
          show: true,
          align: 'center',
          width: '60'
        },{
          id: 10,
          prop: 'companyNamesc',
          name: '药品企业',
          fixed: false,
          show: true,
          sortable: this.queryParams.versionNo?false:'custom',
          align: 'center',
          width: '100'
        },{
          id: 11,
          prop: 'approvalCode',
          name: '批准文号',
          fixed: false,
          show: true,
          sortable: this.queryParams.versionNo?false:'custom',
          align: 'center',
          width: '100'
        },{
          id: 12,
          prop: 'goodsStandardCode',
          name: '药品本位码',
          fixed: false,
          show: true,
          sortable: this.queryParams.versionNo?false:'custom',
          align: 'center',
          width: '100'
        },{
          id: 13,
          prop: 'productInsuranceType',
          name: '医保药品目录甲乙类',
          fixed: false,
          show: true,
          align: 'center',
          width: '60'
        },{
          id: 14,
          prop: 'productCode',
          name: '医保药品目录编号',
          fixed: false,
          show: true,
          align: 'center',
          width: '60'
        },{
          id: 15,
          prop: 'productName',
          name: '医保药品目录药品名称',
          fixed: false,
          show: true,
          align: 'center',
          width: '60'
        },{
          id: 16,
          prop: 'productMedicineModel',
          name: '医保药品目录药品剂型',
          fixed: false,
          show: true,
          align: 'center',
          width: '60'
        },{
          id: 17,
          prop: 'productRemark',
          name: '备注',
          fixed: false,
          show: true,
          align: 'center',
          width: '220'
        }
        ,{
          id: 18,
          prop: 'modifyType',
          name: '数据类型',
          fixed: false,
          show: this.queryParams.versionNo?true:false,
          align: 'center',
          width: '80'
        },{
          id: 19,
          prop: 'comment',
          name: '备注信息',
          fixed: false,
          show: this.queryParams.versionNo?false:true,
          align: 'center',
          width: '100'
        }
      ]
    }
  },
  created() {
    this.$nextTick(() => {
      this.setTableHeight()
      if(this.$route.query && this.$route.query.versionNo){
        // 从其他页面路由传参版本号，快速搜索
        this.queryParams.versionNo=this.$route.query.versionNo
      }
      /* 查询全部版本号*/
      selectAllVersionNo().then(response => {
        this.versionNoOptions = response.data
        if( this.versionNoOptions.length==0){
          this.msgInfo("当前无数据版本，请先执行上传操作")
          this.loading=false
          return
        }
        //页面初始化完毕，默认触发一次排序
        this.$refs.refundTable.sort('ypbm','descending');
      })
    })

  },
  activated() {
    //激活时重新设置表格高度
    this.setTableHeight()
  },
  mounted() {
    var _this = this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      AcrossUtil.$emit('resetTableHeight')
    }

    AcrossUtil.$on('resetTableHeight', function(param) {
      setTimeout(function() {
        _this.setTableHeight()
      }, 300)
    })

  },
  methods: {
    /*设置表格的高度*/
    setTableHeight() {
      // 获取高度值 （内容高+padding+边框）
      let formHeight = this.$refs.headerQuery.offsetHeight
      this.tableHeight = window.innerHeight - formHeight - 150
    },


    /*鼠标右键点击事件*/
    rowContextmenu(row, column, event) {
      this.tableContextMenuVisible = false
      this.tableContextMenuVisible = true
      // 阻止右键默认行为
      event.preventDefault()
      this.$nextTick(() => {
        this.$refs.contextbutton.init(row, column, event)
      })
    },
    /* 取消鼠标监听事件 菜单栏*/
    foo() {
      this.tableContextMenuVisible = false
      document.removeEventListener('click', this.foo)
    },
    /* 处理表格右键菜单点击*/
    handleTableAction(actionName, row, column) {
      if (actionName === '查询相关知识库药品') {
        if(this.queryParams.versionNo){
          this.msgError("当前查看的是版本["+this.queryParams.versionNo+"]的数据，暂不支持该操作，请移除版本号筛选条件后重试")
        }else{
          this.selectRelevanceDrugList(row)
        }
      }
    },
    /** 查询上传记录列表 */
    async selectNhsaDrugList() {
      this.loading = true
      let response
      if(this.queryParams.versionNo){
        //有版本号，查询带版本的数据
         response=await nhsaDrugList(this.queryParams)
      }else{
        //没有版本号，查询全量数据
         response=await fullNhsaDrugList(this.queryParams)
      }
      this.drugList = response.rows
      this.drugList.forEach(item=>{
        if(item.goodsCode){
          item.ypbm=item.goodsCode
        }
        let modifyType=item.modifyType
        Object.assign(item, item.currentDrug)
        item.modifyType=modifyType
      })
      this.total = response.total
      //解决表格固定列-滚动时候错位
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.refundTable.doLayout()
        })
      }, 300)
      this.loading = false

      this.handleUpdateTableData()
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.isResult = true
      this.queryParams.pageNum = 1
      this.selectNhsaDrugList()
    },
    /** 导出按钮操作 */
    async handleExport(){
      this.fullscreenLoading=true
      let response
      if(this.queryParams.versionNo){
        //有版本号，查询带版本的数据
        response=await downloadNhsaDrugList(this.queryParams)
      }else{
        //没有版本号，查询全量数据
        response=await downloadFullNhsaDrugList(this.queryParams)
      }
      axiosDownload("国家医保药品目录.xlsx", response)
      this.fullscreenLoading=false
    },
    /*表格排序切换*/
    tableSortChange({column, prop, order}){
      if(order!=null){
        order=order=='descending'?'desc':'asc';
      }else{
        order=''
      }
      if(this.queryParams.versionNo){
        //有版本号，查询带版本的数据

      }else{
        //没有版本号，查询全量数据
        if(prop=='ypbm'){
          prop='goodsCode'
        }

      }
      this.queryParams.orderByColumn=prop
      this.queryParams.isAsc=order
      this.handleQuery()
    },

    compareTableCellClass({row, column, rowIndex, columnIndex}){
      let property=column.property
      if(['registeredProductName','goodsName','registeredMedicineModel','registeredOutlook','realitySpec','materialName',
        'factor','minUnit','unit','companyNamesc','approvalCode','goodsStandardCode',
        'productInsuranceType','productCode','productName','productMedicineModel','productRemark'].indexOf(property)>-1 ){
        let nowVersionRow
        let lastVersionRow
        if(row.dataType=='当前版本'){
           nowVersionRow=row
           lastVersionRow=this.drugList[rowIndex+1]
        }else if(row.dataType=='上一个版本'){
          nowVersionRow=this.drugList[rowIndex-1]
          lastVersionRow=row
        }
       if(nowVersionRow &&lastVersionRow && nowVersionRow[property]!=lastVersionRow[property]){
          return 'difference-cell';
        }
      }else{
        return '';
      }
    },
    /*处理-修改类型的数据*/
    handleUpdateTableData(){
      let newDrugList=[]
      this.drugList.forEach(item=>{
        if(item.modifyType!=1){
          newDrugList.push(item)
          return
        }
        let nowVersion=item
        nowVersion.ypbm=nowVersion.goodsCode
        let lastVerson=item.lastDrug
        lastVerson.ypbm=lastVerson.goodsCode
        if(item.modifyType==1){
          for(let key in nowVersion){
            if(['registeredProductName','goodsName','registeredMedicineModel','registeredOutlook','realitySpec','materialName',
              'factor','minUnit','unit','companyNamesc','approvalCode','goodsStandardCode',
              'productInsuranceType','productCode','productName','productMedicineModel','productRemark'].indexOf(key)>-1){
              if(nowVersion[key]!=lastVerson[key]){
                nowVersion.hasDifference=true
                nowVersion.dataType='当前版本'
                lastVerson.hasDifference=true
                lastVerson.dataType='上一个版本'
                lastVerson.modifyType=1
              }
            }
          }
          newDrugList.push(nowVersion,lastVerson)
        }else{
          newDrugList.push(nowVersion)
        }

      })
      this.drugList=newDrugList
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      let property=column.property
      if (property === "ypbm" ) {
        if(row.dataType=='当前版本'){
          return {
            rowspan: 2,
            colspan: 1
          }
        }else if(row.dataType=='上一个版本'){
          return {
            rowspan: 0,
            colspan: 0
          }
        }else{
          return {
            rowspan: 1,
            colspan: 1
          }
        }
      }
    },
    /*版本号变化*/
    versionNoChange(val){
      if(this.queryParams.versionNo){
        //有版本号，查询带版本的数据
        this.queryParams.orderByColumn="ypbm"
      }else{
        //没有版本号，查询全量数据
        this.queryParams.orderByColumn="goodsCode"
      }
      this.queryParams.relevanceStatus=0
      this.handleQuery()
    },
    /*查询相关知识库药品*/
    selectRelevanceDrugList(row){
      this.selectTableRow=row
      if(row){
        this.relevanceDrugQueryParams.approvalNum=row.approvalCode
        this.relevanceDrugQueryParams.standardCode=row.goodsStandardCode
        this.relevanceDrugQueryParams.stateMedicareNum=row.goodsCode
        this.relevanceDrugQueryParams.pageNum=1
        this.relevanceDrugQueryParams.pageSize=10
      }
      this.loading = true

      selectPdProdMatList(this.relevanceDrugQueryParams).then(res=>{
        this.relevanceDrugDialogVisible=true
        res.rows.forEach(item=>{
          //是否关联知识库药品
          if(item.stateMedicareNum &&item.stateMedicareNum.indexOf(this.relevanceDrugQueryParams.stateMedicareNum)>=0){
            item.relevanceStatus=1
          }else{
            item.relevanceStatus=0
          }
        })
        this.relevanceDrugTableData = res.rows
        this.relevanceDrugTableDataTotal = res.total
        this.loading = false
        //解决表格固定列-滚动时候错位
        setTimeout(() => {
          this.$nextTick(() => {
            this.$refs.relevanceDrugTable.doLayout()
          })
        }, 300)

      })
    },
    /* 更新国家医保编码*/
    updateMedicareNum(matId,stateMedicareNum){
      updateMedicareNum(matId,stateMedicareNum, '').then(res => {
        this.selectRelevanceDrugList(this.selectTableRow)
      })
    },
    /* 编辑国家医保药品备注信息*/
    editNhsaDrugComment(row){
      this.editDrugCommentDialogVisible=true
      this.drugComment=row.comment
      this.drugId=row.id
    },
    /* 保存国家医保药品备注信息*/
    submitEditDrugComment(){
      updateDrugComment(this.drugId,this.drugComment,1).then(res => {
        this.editDrugCommentDialogVisible=false
        this.msgSuccess()
        this.selectNhsaDrugList()
      })
    }



  }
}
</script>

<style lang="scss" scoped>
.el-table .cell .el-form-item {
  margin-bottom: 0px;
}

</style>



<style>

.el-table .difference-cell .cell {
  color:#f50707;
}


</style>
