<template>
<!--  制剂-->
  <div class="app-container">
    <div ref="headerQuery">
      <el-row class="headerClass">
        <el-col :span="4" style="border-left: 5px solid #0073E9;padding-left: 10px;">制剂规格管理</el-col>
        <el-col :span="20">
          <el-form :model="zJquery" ref="queryForm"  class="query-form" :inline="true" v-show="showSearch" style="float: right">
            <el-form-item label="" prop="name" class="el-item-dropdown-300">
              <el-autocomplete
                :popper-append-to-body="false"
                v-model="zJquery.name"
                :fetch-suggestions="querySearchAsync"
                placeholder="输入制剂ID/名称"
                @select="handleQuery"
                @clear="setBlur()"
                clearable
                size="small"
                value-key="name"
                style="width: 250px;height: 36px!important;line-height: 36px;"
              >
                <el-select v-model="zJquery.nameSearchType"
                           style="width: 74px;"
                           slot="prepend" placeholder="请选择">
                  <el-option label="模糊" value="1"></el-option>
                  <el-option label="精确" value="2"></el-option>
                  <el-option label="前缀" value="3"></el-option>
                </el-select>
              </el-autocomplete>
            </el-form-item>
            <el-form-item label="" style="width: 250px;" class="el-item-dropdown-300">
              <el-autocomplete
                :popper-append-to-body="false"
                v-model="zJquery.drugSearch"
                :fetch-suggestions="queryDrugName"
                placeholder="药物名称"
                @select="handleQuery"
                @clear="setBlur()"
                clearable
                size="small"
                value-key="name"
                style="height: 36px!important;line-height: 36px;"
                @keydown.enter.native="handleQuery"
              >
                <el-select v-model="zJquery.drugSearchType"
                           style="width: 74px;"
                           slot="prepend" placeholder="请选择">
                  <el-option label="模糊" value="1"></el-option>
                  <el-option label="精确" value="2"></el-option>
                  <el-option label="前缀" value="3"></el-option>
                </el-select>
              </el-autocomplete>
            </el-form-item>
            <el-form-item label="" prop="status">
              <el-select
                v-model="zJquery.status"
                placeholder="状态"
                clearable
                size="small"
                class="statusClass"
                style="width: 74px;"
              >
                <el-option
                  v-for="dict in statusOptions"
                  :key="dict.conceptId"
                  :label="dict.conceptVal"
                  :value="dict.conceptId"
                />
              </el-select>
            </el-form-item>

            <el-form-item>
              <!--            <el-checkbox v-model="zJquery.relatedDrugDisable" style="padding-right:10px;" lable="1">关联药物作废</el-checkbox>-->
              <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">检索</el-button>
              <el-button icon="el-icon-circle-plus-outline" size="small" @click="handleAdd" class="addClass">新增制剂</el-button>
              <el-button icon="el-icon-circle-plus-outline" size="small" @click="columnSettings">设置</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>


    <div ref="tableContainer" id="prepTableList">
      <el-table
        border
        stripe
        ref="refundTable"
        :data="preparationList"
        :height="tableHeight"
        v-loading="loading"
        :row-class-name="psRowClass"
        @sort-change="tableSortChange"
        @row-contextmenu="rowContextmenu"
      >
        <el-table-column type="expand" width="45" fixed="left">
          <template slot-scope="props">
            <el-table
              border
              stripes
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              :data="props.row.specList"
              style="width: 100%">
              <el-table-column
                label="规格ID"
                width="120">
                <template slot-scope="scope">
                  <span style="margin-right: 5px;">{{scope.row.specId}}-{{scope.row.id}}</span>
                  <span v-for="item in scope.row.drugCategoryList"
                        :key="item.id"
                  >
                    <svg-icon :icon-class="item.icon" v-if="item.icon"/>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="showSpec"
                label="规格"
                width="200">
              </el-table-column>
              <el-table-column
                prop="specDesc"
                label="规格描述"
                show-overflow-tooltip
                width="300">
              </el-table-column>
              <el-table-column
                prop="isDefault"
                label="首选项"
                width="180">
                <template slot-scope="scope">
                  <span v-if="scope.row.isDefault === true">是</span>
                  <span v-else></span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="200" >
                <template slot-scope="scope">
                  <el-button @click="addDrugProd(scope.$index,scope.row)" type="text" size="small">新增药品</el-button>
                  <el-button @click="queryRelatedDrugProd(scope.row,2)" type="text" size="small">查询相关药品</el-button>
                  <el-button @click="addIngredient(scope.$index,scope.row)" type="text" size="small">成分</el-button>
                </template>
              </el-table-column>
              <template slot="empty">
                <img src="@/assets/images/no-booking.svg" alt="">
                <p>暂无数据</p>
              </template>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column
          v-for="data in columnOptions"
          :key="data.id"
          :prop="data.prop"
          :label="data.name"
          :fixed="data.fixed"
          :resizable="false"
          :sortable="data.sortable"
          :min-width="data.width"
          :align="data.align"
        >
          <template slot-scope="scope">
            <div v-if="data.prop == 'drugName'">
              <span v-if="scope.row.drugStatus === '1'" style="text-decoration: line-through #ff0000;margin-right:5px;">{{ scope.row[data.prop] }}</span>
              <span  v-else style="margin-right:5px;">{{ scope.row[data.prop] }}</span>
            </div>
            <div v-else-if="data.prop == 'preparationAlias'">
<!--              <span style="margin-right:5px;">{{ scope.row[data.prop] }}</span>-->
<!--              <span v-if="scope.row.status === '1'" style="text-decoration: line-through #ff0000;margin-right:5px;">{{ scope.row[data.prop] }}</span>-->
              <span  style="margin-right:5px;">{{ scope.row[data.prop] }}</span>
              <span v-for="item in scope.row.drugCategoryList"
                    :key="item.id"
              >
                <svg-icon :icon-class="item.icon" v-if="item.icon"/>
              </span>
            </div>
            <div v-else>
              <span>{{ scope.row[data.prop] }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center" key="status" width="100" fixed="right">
          <template slot-scope="scope">
            <el-switch
              class="switch"
              active-color="#5DB730"
              active-text='启用'
              inactive-text='停用'
              inactive-color="#B5CCF5"
              v-model="scope.row.status"
              active-value="0"
              inactive-value="1"
              @change="handleStatusChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>


        <template slot="empty" v-if="isResult === false">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
    </div>
    <table-context-menu v-if="tableContextMenuVisible"
                        @foo="foo" ref="contextbutton"
                        :action="tableAction"
                        @handleTableAction="handleTableAction"></table-context-menu>
    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="zJquery.pageNum"
      :limit.sync="zJquery.pageSize"
      @pagination="selectPreparationList"
      :page-sizes="[10, 20, 50, 100]"
    />
    <el-dialog v-dialogDrag
      :visible.sync="channelDialogVisible"
      width="600"
      :title="'用药途径管理[' + psTitle + ']'"
      center
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :before-close="channelHandleClose">
      <el-form :inline="true" :model="ppRouteBeanList" class="demo-form-inline" ref="ppRouteBeanList">
        <el-form-item label="用药途径列表"
                      :rules="[{ required: true, message: '请输入查询内容', trigger: 'blur'},{ min: 1, max: 30, message: '查询内容长度在 1 到 30 个字符', trigger: 'blur' }]"
        >
<!--          <el-input placeholder="输入用药途径名称" v-model="ppRouteBeanList.routeName" maxlength="30" clearable-->
<!--                    show-word-limit style="width: 200px;float: left"-->
<!--          />-->
<!--          <el-button type="primary" icon="el-icon-search" style="margin-left: 20px;background: #2B60F8"-->
<!--                     @click="queryRouteBeanList"-->
<!--          >检索-->
<!--          </el-button>-->
          <el-select
            v-model="ppRouteBeanList.routeName"
            filterable
            remote
            popper-class="select_component"
            :remote-method="remoteMethod_Route"
            reserve-keyword
            clearable
            placeholder="输入用药途径名称"
            style="width:200px;"
            @change="(val)=>changeRemote_Route(val)">
            <div class="clearfix table_Header">
              <span style="width:200px;flex:2;">用药途径</span>
              <span style="width:100px;flex:1;">操作</span>
            </div>
            <el-option
              v-for="(item, index) in routeTableData"
              :key="item.routeId"
              :label="item.routeName"
              :value="item.routeId">
              <div class="clearfix spanWrap">
                <p :title="item.routeName"
                   style="width:200px;flex:2;">{{ item.routeName }}</p>
                <p style="width:100px;flex:1;color:#2B60F8;" @click="addRoute(index, item)">添加</p>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-table
        :data="resultNameOptions02"
        border
        stripe
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        :height="400"
        ref="routeName">
        <el-table-column property="routeName" label="用药途径"></el-table-column>
        <el-table-column label="首选项">
          <template slot-scope="scope">
            <el-select v-model="scope.row.isDefault" placeholder="请选择用药途径">
              <el-option v-for="item in firstChoiceOptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button @click="deleteRouteRow(scope.$index, resultNameOptions02)" type="text" size="small" style="color: #FF3333;">删除</el-button>
            <el-button v-if="scope.row.isDefault === true" type="text" size="small" style="color: #1e1e1e" disabled>默认推荐值</el-button>
            <el-button v-else-if="scope.row.isDefault === false" @click="setRouteRow(scope.$index, resultNameOptions02)" type="text" size="small">设为推荐值</el-button>
          </template>
        </el-table-column>
        <template slot="empty">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
      </el-table>
      <el-footer style="text-align:right;margin-top:20px;">
        <el-button size="small" @click="returnBackList">返回</el-button>
        <el-button type="primary" size="small" @click="saveRoute">保存</el-button>
      </el-footer>
    </el-dialog>
    <el-dialog v-dialogDrag title="用药途径检索" :visible.sync="ppRouteBeanList.drugGroupTableVisible">
      <el-table
        border
        stripe
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        :data="ppRouteBeanList.drugGroupTableData"
        :height="300">
        <el-table-column property="routeName" label="用药途径" width="200"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini" type="primary" icon="el-icon-success"
              @click="chooseRouteBean(scope.$index, scope.row)"
            >选择
            </el-button>
          </template>
        </el-table-column>
        <template slot="empty">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
      </el-table>
    </el-dialog>
    <el-dialog v-dialogDrag
      :visible.sync="siteDialogVisible"
      width="600"
      :title="'给药部位管理[' + psTitle + ']'"
      center
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :before-close="siteHandleClose">
      <el-form :inline="true" :model="ppSiteBeanList" class="demo-form-inline" ref="ppSiteBeanListForm">
        <el-form-item label="给药部位列表"
                      :rules="[{ required: true, message: '请输入查询内容', trigger: 'blur'},{ min: 1, max: 30, message: '查询内容长度在 1 到 30 个字符', trigger: 'blur' }]"
        >
<!--          <el-input placeholder="输入给药部位名称" v-model="ppSiteBeanList.siteName" maxlength="30" clearable-->
<!--                    show-word-limit style="width: 200px;float: left"-->
<!--          />-->
<!--          <el-button type="primary" icon="el-icon-search" style="margin-left: 20px;background: #2B60F8"-->
<!--                     @click="querySiteBeanList"-->
<!--          >检索-->
<!--          </el-button>-->
          <el-select
            v-model="ppSiteBeanList.siteName"
            filterable
            remote
            popper-class="select_component"
            :remote-method="remoteMethod_site"
            reserve-keyword
            clearable
            placeholder="输入给药部位名称"
            style="width:200px;"
            @change="(val)=>changeRemote_site(val)">
            <div class="clearfix table_Header">
              <span style="width:200px;flex:2;">用药部位</span>
              <span style="width:100px;flex:1;">操作</span>
            </div>
            <el-option
              v-for="(item, index) in siteTableData"
              :key="item.siteId"
              :label="item.siteName"
              :value="item.siteId">
              <div class="clearfix spanWrap">
                <p :title="item.siteName"
                   style="width:200px;flex:2;">{{ item.siteName }}</p>
                <p style="width:100px;flex:1;color:#2B60F8;" @click="addSite(index, item)">添加</p>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-table
        :data="resultNameOptions"
        border
        stripe
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        :height="400"
        ref="siteName">
        <el-table-column property="siteName" label="用药部位"></el-table-column>
        <el-table-column label="首选项">
          <template slot-scope="scope">
            <el-select v-model="scope.row.isDefault" placeholder="请选择用药部位">
              <el-option v-for="item in firstChoiceOptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button @click="deleteSiteRow(scope.$index, resultNameOptions)" type="text" size="small" style="color: #FF3333;">删除</el-button>
            <el-button v-if="scope.row.isDefault === true" type="text" size="small" style="color: #1e1e1e" disabled>默认推荐值</el-button>
            <el-button v-else-if="scope.row.isDefault === false" @click="setSiteRow(scope.$index, resultNameOptions)" type="text" size="small">设为推荐值</el-button>
          </template>
        </el-table-column>
        <template slot="empty">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
      </el-table>
      <el-footer style="text-align:right;margin-top:20px;">
        <el-button size="small" @click="returnBackSiteList">返回</el-button>
        <el-button type="primary" size="small" @click="saveSite">保存</el-button>
      </el-footer>
    </el-dialog>
    <el-dialog v-dialogDrag title="给药部位检索" :visible.sync="ppSiteBeanList.drugGroupTableVisible">
      <el-table
        border
        stripe
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        :data="ppSiteBeanList.drugGroupTableData"
        :height="300">
        <el-table-column property="siteName" label="用药部位" width="200"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini" type="primary" icon="el-icon-success"
              @click="chooseSiteBean(scope.$index, scope.row)"
            >选择
            </el-button>
          </template>
        </el-table-column>
        <template slot="empty">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
      </el-table>
    </el-dialog>
    <!--    别名管理-->
    <el-dialog v-dialogDrag
      :visible.sync="aliasDialogVisible"
      width="850px"
      :title="'别名管理[' + psTitle + ']'"
      center
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :before-close="aliasHandleClose">
      <el-form :inline="true" :model="aliasManagement" class="demo-form-inline" ref="aliasManagementForm">
        <el-form-item
          v-for="(item, index) in aliasManagement.preparationAliasList"
          :label="index==0?'制剂名称:':''"
          :key="'preparation-name-'+index"
          :rules="[
            { required: true, message: '请输入制剂名称', trigger: 'blur'},
             { validator: validatePreparationAlias, trigger: 'blur'}
            ]"

          :prop="'preparationAliasList.' + index + '.preparationAlias'"
        >
          <el-input placeholder="请输入制剂名称" v-model="item.preparationAlias" style="width: 450px;float: left"
                    maxlength="300" clearable show-word-limit
          />
          <el-select v-model="item.languageId" placeholder="请选择语言" style="width: 80px;float: left">
            <el-option
              v-for="item in languageConcept"
              :key="item.conceptId"
              :label="item.conceptVal"
              :value="item.conceptId"
            ></el-option>
          </el-select>
          <el-form-item style="float: left;margin-bottom: 0px;"
                        :rules="[
          { required: true, message: '请选择名称类型', trigger: 'blur'},
           { validator: validateAliasType, trigger: 'blur'}
          ]"
                        :prop="'preparationAliasList.' + index + '.aliasType'"
          >
            <el-select v-model="item.aliasType" placeholder="请选择类型" style="width: 120px;float: left">
              <el-option
                v-for="item in aliasTypeConcept"
                :key="item.conceptId"
                :label="item.conceptVal"
                :value="item.conceptId"
              ></el-option>
            </el-select>
          </el-form-item>
          <i class="el-icon-remove" @click.prevent="delSubstanceName(item)"></i>
          <el-radio v-model="aliasManagement.namePrimary" :label="index" border size="small"
                    @change="primaryChange($event,'name',index)"
                    style="margin-right: 10px;padding: 8px 8px 0 5px;"
          >
            首选项
          </el-radio>
          <!--          <el-button type="text" @click="showResourceTable('dkb_drug_alias',index,'name')">添加来源</el-button>-->
        </el-form-item>

        <el-form-item>
          <el-button type="primary" plain icon="el-icon-circle-plus-outline" style="width: 200px;"
                     @click="addSubstanceName('中文')"
          >添加中文制剂名称
          </el-button>
          <el-button type="primary" plain icon="el-icon-circle-plus-outline" style="width: 200px;"
                     @click="addSubstanceName('英语')"
          >添加英文制剂名称
          </el-button>
        </el-form-item>
      </el-form>
      <el-footer style="text-align:right;margin-top:20px;">
        <el-button size="small" @click="returnBackAliasList">返回</el-button>
        <el-button type="primary" size="small" @click="saveAlias">保存</el-button>
      </el-footer>
    </el-dialog>
    <!--    规格管理-->
    <el-dialog v-dialogDrag
      :visible.sync="specDialogVisible"
      width="1000px"
      :title="'制剂规格管理[' + psTitle + ']'"
      center
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :before-close="specHandleClose">
      <el-row style="margin-bottom: 10px;">
        <el-col :span="12" class="title-bar">制剂规格列表</el-col>
        <el-col :span="12" style="text-align: right;">
          <el-button type="primary" style="text-align:right;background: #2B60F8" @click="addGg">
            添加规格
          </el-button>
        </el-col>
      </el-row>
      <el-table
        :data="tableData"
        border
        stripe
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        ref="specTableData"
        style="width: 100%">
        <el-table-column
          label="规格ID"
          prop="specId"
          sortable
          width="120">
          <template slot-scope="scope">
            <span style="margin-right: 5px;">{{scope.row.specId}}-{{scope.row.id}}</span>
            <span v-for="item in scope.row.drugCategoryList"
                  :key="item.id"
            >
                    <svg-icon :icon-class="item.icon" v-if="item.icon"/>
                  </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="showSpec"
          sortable
          label="规格">
        </el-table-column>
        <el-table-column
          prop="isDefault"
          label="首选项">
          <template slot-scope="scope">
            <span v-if="scope.row.isDefault === true">是</span>
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column
          prop="descList"
          sortable
          label="规格描述">
          <template slot-scope="scope">
            <div v-for="item in scope.row.descList" :key="item.id">
              <div v-if="item.isDefault===true">
                <span>{{ item.specDesc }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="启用" align="center" key="status" width="120">
          <template slot-scope="scope">
            <el-switch
              class="switch"
              active-color="#5DB730"
              active-text='启用'
              inactive-text='停用'
              inactive-color="#B5CCF5"
              v-model="scope.row.status"
              active-value="0"
              inactive-value="1"
              @change="handleSpecStatusChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="250">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="updateSpec(scope.row, scope.$index)"
            >修改
            </el-button>
            <el-button
              size="mini"
              type="text"
              @click="copydateSpec(scope.row, scope.$index)"
            >复制
            </el-button>
            <el-button
              size="mini"
              type="text"
              style="color: #FF3333;"
              @click="deleteSpec(scope.row, scope.$index)"
            >删除
            </el-button>
            <el-button
              size="mini"
              type="text"
              @click="handleSource02(scope.row, scope.$index)"
            >来源
            </el-button>
            <el-button
              v-if="scope.row.id"
              size="mini"
              type="text"
              @click="setDrugProd(scope.row, scope.$index)"
            >新建药品
            </el-button>
            <el-button
              size="mini"
              type="text"
              @click="editSpecIngredient(scope.row, scope.$index)"
            >成分
            </el-button>
            <el-button
              size="mini"
              type="text"
              @click="copySpec(scope.row)"
            >创建同规格
            </el-button>
            <el-button
              size="mini"
              type="text"
              @click="$refs.drugCategoryEdit.refreshDrugCategoryTree(scope.row.id,5)"
            >分类维护
            </el-button>
            <el-button
              v-if="scope.row.isDefault === true"
              size="mini"
              type="text"
              style="color: #1e1e1e"
              disabled
            >默认首选规格
            </el-button>
            <el-button
              v-else-if="scope.row.isDefault === false"
              size="mini"
              type="text"
              @click="setSpecPrimary(scope.row, scope.$index)"
            >设为首选规格
            </el-button>
          </template>
        </el-table-column>
        <template slot="empty">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
      </el-table>
      <el-footer style="text-align:right;margin-top:20px;">
        <el-button size="small" @click="returnBackSpecList">返回</el-button>
        <el-button type="primary" size="small" @click="saveSpec">保存</el-button>
      </el-footer>
    </el-dialog>
    <el-dialog v-dialogDrag :title="'引用来源管理[' + psTitle + ']'" :visible.sync="resourceTableVisible">
      <el-row>
        <el-col :span="24" style="margin-bottom: 10px;">
          <el-button v-if="isSpecShow === false" style="float: right;margin-right: 10px;" icon="el-icon-circle-plus-outline"
                     size="small" @click="addResource"
          >添加
          </el-button>
          <el-button v-else-if="isSpecShow === true" style="float: right;margin-right: 10px;" icon="el-icon-circle-plus-outline"
                     size="small" @click="addResource02"
          >添加
          </el-button>
        </el-col>
      </el-row>
      <el-form ref="resourceTableForm" status-icon :model="resourceTableForm">
        <el-table
          border
          stripe
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
          :data="resourceTableForm.list">
          <el-table-column property="date" label="引用资源类型" >
            <template slot-scope="scope">
              <el-form-item
                :prop="'list.' + scope.$index + '.'+'resourceTypeId'"
                :rules="[{ required: true, message: '请选择药物名称', trigger: 'blur'}]"
              >
                <el-select
                  v-model="scope.row.resourceTypeId"
                  placeholder="引用资源类型"
                  size="small"
                  style="width: 200px"
                >
                  <el-option
                    v-for="dict in resourceTypeOption"
                    :key="'resourceTypeOption'+dict.id"
                    :label="dict.typeName"
                    :value="dict.id"
                  />
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column property="name" label="引用资源名称" >
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.resourceId"
                filterable
                remote
                style="width: 200px"
                reserve-keyword
                placeholder="请输入关键词"
                @change="resourceNameChange($event,scope.$index)"
                :remote-method="(query)=>{resourceNameQuery(query,scope.row.resourceTypeId)}"
                :loading="loading"
                :disabled="!scope.row.resourceTypeId?true:false"
              >
                <el-option
                  v-for="item in resourceDataOption"
                  :key="'resourceDataOption'+item.id"
                  :label="item.name"
                  :value="item.id"
                />
                <el-option
                  v-for="item in [scope.row]"
                  :key="item.resourceId+'original'"
                  :label="item.resourceName"
                  :value="item.resourceId"
                />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="200">
            <template slot-scope="scope">

              <el-button
                size="mini"
                type="text"
                style="color: #FF3333;"
                @click="handleResourceDelete(scope.$index,scope.row)"
              >删除
              </el-button>
            </template>
          </el-table-column>
          <template slot="empty">
            <img src="@/assets/images/no-booking.svg" alt="">
            <p>暂无数据</p>
          </template>
        </el-table>
      </el-form>
      <el-row>
        <el-col :span="24" style="margin-top: 10px;">
          <el-button v-if="isSpecShow === false" type="primary" style="float: right;margin-right: 10px;"
                     size="small" @click="saveResource"
          >保存
          </el-button>
          <el-button v-else-if="isSpecShow === true" type="primary" style="float: right;margin-right: 10px;"
                     size="small" @click="saveResource02"
          >保存
          </el-button>
          <el-button style="float: right;margin-right: 10px;"
                     size="small" @click="resourceTableVisible=false"
          >取消
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <AddSpecification v-if="isAddSF"
                      :visible="isAddSF"
                      :specGroupKey="specGroupKey"
                      :preparationId="preparationId"
                      :specMode="specMode"
                      :specData="specData"
                      :isEdit="isEdit"
                      :specModeChoice="specModeChoice"
                      :specTableData="tableData"
                      @tableValue="tableValue"
                      @closeAddSF="closeAddSF"/>


    <drug-category-edit ref="drugCategoryEdit"></drug-category-edit>
    <edit-ingredient
      v-if="isEditIngredient"
      :EditDialogVisible="isEditIngredient"
      :singleSpecId="singleSpecId"
      :specId="specId"
      :ingredientData="ppSpecIngredientList"
      :isGetArrShow="isGetArrShow"
      @closeEditIngredient="closeEditIngredient"
      @editFinish="handleIngredientEditFinish"
      @getIngredientList="getIngredientList">
    </edit-ingredient>
  </div>
</template>

<script>
import {
  selectDrugNameList as selectPreparationNameList,
  preparationList,
  delPreparation,
  modifyPreparation,
  routeEnable,
  route,
  siteEnable,
  modifyRoute,
  getSpecInfo,
  getAliasInfo,
  statusSpec,
  delSpec,
  defaultSpec,
  modifyAlias,
  addSpecBatch,
  modifySite,
  site,
  checkFields
} from '@/api/dkm/preparationSpecification'
import { selectDrugNameList } from '@/api/dkm/drugGroup'
import tableConst from '@/const/tableConst'
import { getConcept } from '@/utils/concept'
import conceptConst from '@/const/conceptConst'
import { UCgetConfig } from '@/utils/columnSettings'
import { GLOBAL_CONFIGURATION_CONFIGKEY } from '@/utils/constants'
import AddSpecification from '@/components/AddSpecification'
import { DEEP_CLONE } from '@/utils/constants'
import {
  PPSPECBEANLIST_DATA
} from '@/utils/addSpecification'
import { selectReferenceInfo, saveOrUpdateInfoList, resourceNameQuery } from '@/api/dkm/resource'
import { getResourceCategory } from '@/utils/resourceReference'
import { isEmpty } from '@/utils/common'
import { setCache, getCache } from '@/utils/cacheUtil'
import AcrossUtil from '@/utils/acrossUtil'
import EditIngredient from "./EditIngredient";
export default {
  name: 'PreparationSpecification',
  components: {
    AddSpecification,
    EditIngredient
  },
  data() {
    return {
      conceptConst,
      isAddSF: false,
      specMode: '1',
      specData: {},
      isEdit: false,
      tableData: [],
      languageConcept: [],
      /*名称类型下拉列表*/
      aliasTypeConcept: [],
      // 用药途径列表
      ppRouteBeanList: {
        /*用药途径检索输入框数据*/
        routeName: null,
        /*药物组检索表格数据*/
        drugGroupTableData: [],
        /*药物组检索表格弹是否显示*/
        drugGroupTableVisible: false
      },
      // 给药部位列表
      ppSiteBeanList: {
        /*给药部位检索输入框数据*/
        siteName: null,
        /*药物组检索表格数据*/
        drugGroupTableData: [],
        /*药物组检索表格弹是否显示*/
        drugGroupTableVisible: false
      },
      // 别名管理
      aliasManagement: {
        preparationAliasList: [],
        // preparationAlias: '',
        // languageId: '',
        namePrimary: 0

      },
      specDialogVisible: false, // 规格弹出层
      aliasDialogVisible: false, // 别名弹出层
      siteDialogVisible:false, // 用药部位弹出层
      channelDialogVisible: false, // 途径弹出层
      preparationSearchList: [], // 制剂模糊查询列表
      tableConst,
      // 遮罩层
      loading: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 制剂维护表格数据
      preparationList: [],
      // 表格高度
      tableHeight: window.innerHeight - 100 - 100,
      // 状态数据字典
      statusOptions: [],
      // 查询参数
      zJquery: {
        pageNum: 1,
        pageSize: 10,
        drugSearch: null,
        drugSearchType:"1",
        name: null,
        nameSearchType:"1",
        // relatedDrugDisable: null,
        status: null,
        orderByColumn:undefined,
        isAsc:undefined,
      },
      sltConfigKey: '', // 制剂管理-制剂列表-表格配置
      sltConfigClassify: '',
      columnOptions: [],
      options: [
        {
          id: 1,
          prop: 'preparationId',
          name: '制剂ID',
          fixed: false,
          show: true,
          sortable:'custom',
          align: 'center',
          width: '120'
        },
        {
          id: 2,
          prop: 'drugName',
          name: '所属药物',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '250'
        },
        {
          id: 3,
          prop: 'preparationAlias',
          name: '制剂名称',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '250'
        },
        {
          id: 4,
          prop: 'typeName',
          name: '制剂剂型',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '180'
        },
        {
          id: 5,
          prop: 'routeName',
          name: '用药途径',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '180'
        },
        {
          id: 6,
          prop: 'siteName',
          name: '给药部位',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '180'
        },
        {
          id: 7,
          prop: 'drugTypeSpecName',
          name: '中药类型及规格',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '300'
        },
        {
          id: 8,
          prop: 'drugProcMethodName',
          name: '中药炮制方法',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '300'
        },
        {
          id: 9,
          prop: 'updateTime',
          name: '更新时间',
          fixed: false,
          show: true,
          sortable:  'custom',
          align: 'center',
          width: '150'
        }
      ],
      checked: true,
      resultNameOptions: [], // 已选择给药部位list
      resultNameOptions02: [], // 已选择用药途径list
      firstChoiceOptions: [
        { label: '是', value: true },
        { label: '否', value: false},
      ],
      preparationId: '',
      routeTableData: [], // 用药途径下拉选择
      siteTableData: [], // 给药部位下拉选择
      specIndex: null, // 规格index
      specGroupKey: null, // 创建同规格
      classify: '', // 药物类型 100403 草药
      /* 控制引用来源表格弹窗显示隐藏*/
      resourceTableVisible: false,
      /*来源类型下拉列表*/
      resourceTypeOption: [],
      resourceDataOption: [],
      /*当前操作表格行数据*/
      editTableRow: [],
      /* 来源引用表格数据*/
      resourceTableForm: {
        list: []
      },
      isSpecShow: false,
      isResult: false,
      /*表格右键菜单显示隐藏*/
      tableContextMenuVisible:false,
      tableAction:[
        {name:"修改"},
        {name:"途径"},
        {name:"部位"},
        {name:"别名"},
        {name:"规格"},
        {name:"来源"},
        {name:"查询相关药品"},
        {name:"删除",style:'color: #FF3333;'},
        {name:"分类维护"},
        {name: '查看'}
      ],
      concentration: null, // 单行制剂浓度
      specModeChoice: false,
      psTitle: '',
      isEditIngredient: false, // 是否修改规格中的成分
      specId: '',
      singleSpecId: '',
      ppSpecIngredientList: [],
      isGetArrShow: true
    }
  },
  async created() {
    let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 5)
    this.sltConfigKey = item.configKey // 制剂管理-制剂列表-表格配置
    this.sltConfigClassify = item.configClassify // 配置分类
    this.getSettingTable().then(() => {
      this.$nextTick(() => {
        this.setTableHeight()
        if(this.$route.query && this.$route.query.drugName){
          // 从其他页面路由传参药物名称，快速搜索
          this.zJquery.drugSearch=this.$route.query.drugName
        }
        //页面初始化完毕，默认触发一次按照更新时间排序
        this.$refs.refundTable.sort('preparationId','descending');
      })
    })
    //获取状态数据字典
    await getConcept(conceptConst.PUB_DATA_STATUS, 0).then(result => {
      this.statusOptions = result
    })
    /*获取语言概念字典*/
    await getConcept(conceptConst.PUB_LANG, 0).then(result => {
      this.languageConcept = result
    })
    //获取引用资源类型
    await getResourceCategory().then(result => {
      this.resourceTypeOption = result
    })
    /*获取名称类型字典*/
    await getConcept(conceptConst.PUB_NAME_TYPE, 0).then(result => {
      this.aliasTypeConcept = result
    })
  },
  activated() {
    //激活时重新设置表格高度
    this.setTableHeight()
  },
  mounted() {
    var _this=this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      AcrossUtil.$emit('resetTableHeight');
    }

    /*监听编辑页面发送的事件，执行相应的函数*/
    AcrossUtil.$on('tableReload', function (param) {
      _this.selectPreparationList()
    })

    AcrossUtil.$on('resetTableHeight', function (param) {
      setTimeout(function () {
        _this.setTableHeight()
      }, 300);
    })

  },
  methods: {
    /*设置表格的高度*/
    setTableHeight(){
      // 获取高度值 （内容高+padding+边框）
      if(this.$refs.headerQuery){
        let formHeight = this.$refs.headerQuery.offsetHeight
        this.tableHeight = window.innerHeight - formHeight-140
      }

    },
    /**药物名称检索*/
    queryDrugName(queryString, cb) {
      if (!isEmpty(queryString)) {
        setTimeout(() => {
          selectDrugNameList({queryStr:queryString.trim()}).then(response => {
            cb(response.data)
          })
        }, 200)
      } else {
        cb([])
      }
    },
    // 修改规格
    updateSpec(row, index) {
      // console.log(row)
      this.specMode = row.specMode.toString()
      // this.specData = row
      this.isAddSF = false
      this.isEdit = true
      this.specIndex = index
      // console.log(this.isEdit)
      this.$nextTick(() => {
        this.specData = row
        if (this.concentration !== null && this.concentration !== undefined && this.concentration !== '') {
          this.specData.concentration = this.concentration
          this.specData.maxConcentration = this.concentration
          this.specMode = '2' // 【规格模式】变更为【质量/体积（w/v）、体积/体积（v/v）、国际单位/体积（IU/v）、质量/质量（w/w）】
          this.specModeChoice = true
        } else {
          this.specData.concentration = null
          this.specData.maxConcentration = null
          // this.specMode = '1'
          this.specModeChoice = false
        }
        this.isAddSF = true
      })
      // console.log(index)
      // console.log(this.specIndex)
    },
    // 复制规格
    copydateSpec(row) {
      this.specMode = row.specMode.toString()
      this.specData = DEEP_CLONE(row)
      this.isAddSF = true
      this.isEdit = false
      this.specIndex = null
      this.specData.specGroupKey = Math.random().toString()
      this.specData.specId = null
      this.specData.id = null
      this.specData.descList.forEach((item) => {
        item.id = null
        item.specId = null
      })
      if (this.concentration !== null && this.concentration !== undefined && this.concentration !== '') {
        this.specData.concentration = this.concentration
        this.specData.maxConcentration = this.concentration
        this.specMode = '2' // 【规格模式】变更为【质量/体积（w/v）、体积/体积（v/v）、国际单位/体积（IU/v）、质量/质量（w/w）】
        this.specModeChoice = true
      } else {
        this.specData.concentration = null
        this.specData.maxConcentration = null
        // this.specMode = '1'
        this.specModeChoice = false
      }
    },
    copySpec(row) {
      this.specGroupKey = row.specGroupKey
      this.isEdit = false
      this.isAddSF = true
      this.specData = DEEP_CLONE(PPSPECBEANLIST_DATA)
      if (this.concentration !== null && this.concentration !== undefined && this.concentration !== '') {
        this.specData.concentration = this.concentration
        this.specData.maxConcentration = this.concentration
        this.specMode = '2' // 【规格模式】变更为【质量/体积（w/v）、体积/体积（v/v）、国际单位/体积（IU/v）、质量/质量（w/w）】
        this.specModeChoice = true
      } else {
        this.specData.concentration = null
        this.specData.maxConcentration = null
        // this.specMode = '1'
        this.specModeChoice = false
      }
      // console.log(this.preparationId)
      this.specIndex = null
    },
    // /** 删除规格按钮操作 */
    // deleteSpec(row) {
    //   const id = row.id
    //   let that = this
    //   this.$confirm('是否删除编号为"' + id + '"的规格?', '警告', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(function() {
    //     let obj = {}
    //     obj.id = id
    //     delSpec(obj)
    //   }).then(() => {
    //     // this.selectPreparationList()
    //     this.msgSuccess('删除成功')
    //   }).catch(function() {
    //
    //   })
    // },
    /** 删除规格按钮操作 */
    deleteSpec(row, index) {
      console.log(row)
      const id = row.id
      let that = this
      if (id) {
        this.$confirm('是否删除编号为"' + id + '"的规格?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let obj = {}
          obj.id = id
          let res = await delSpec(obj)
          if (res.code === 200) {
            this.msgSuccess('删除成功')
            await this.getSpecInfoFn(this.preparationId)
            this.$nextTick(() => {
              this.$refs.specTableData.doLayout()
            })
          }
        })
      } else {
        this.$confirm('是否删除index为"' + index + '"的规格?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // console.log(this.tableData)
          this.tableData.splice(index, 1)
          // console.log(this.tableData)
          this.msgSuccess('删除成功')
          // this.getSpecInfoFn(this.preparationId)
          this.$nextTick(() => {
            this.$refs.specTableData.doLayout()
          })
        }).catch(function () {

        })
      }
    },
    // 设首选规格
    setSpecPrimary(row, index) {
      // console.log(row)
      const id = row.id
      let that = this
      this.$confirm('是否设置index为"' + index + '"的规格为首选项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.tableData.forEach((item) => {
          if (item.specGroupKey === row.specGroupKey) {
            item.isDefault = false
          }
        })
        this.tableData[index].isDefault = true
        this.$nextTick(() => {
          this.$refs.specTableData.doLayout()
        })
        this.msgSuccess('设置成功')
      }).catch(function () {

      })
    },
    // // 设首选规格
    // setSpecPrimary(row) {
    //   const id = row.id
    //   let that = this
    //   this.$confirm('是否设置编号为"' + id + '"的规格为首选项?', '警告', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(function() {
    //     let obj = {}
    //     obj.id = id
    //     defaultSpec(obj)
    //   }).then(() => {
    //     // this.selectPreparationList()
    //     this.msgSuccess('设置成功')
    //   }).catch(function() {
    //
    //   })
    // },
    /**规格状态修改*/
    handleSpecStatusChange(row) {
      let text = row.status === '0' ? '启用' : '停用'
      this.$confirm('确认要"' + text + '"ID为"' + row.id + '"的规格吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        return statusSpec(row.id, row.status)
      }).then(() => {
        this.msgSuccess(text + '成功')
      }).catch(function() {
        row.status = row.status === '0' ? '1' : '0'
      })
    },
    // 添加规格
    // 添加规格
    addGg() {
      this.specGroupKey = null
      this.isEdit = false
      this.isAddSF = true
      this.specData = DEEP_CLONE(PPSPECBEANLIST_DATA)
      if (this.concentration !== null && this.concentration !== undefined && this.concentration !== '') {
        this.specData.concentration = this.concentration
        this.specData.maxConcentration = this.concentration
        this.specMode = '2' // 【规格模式】变更为【质量/体积（w/v）、体积/体积（v/v）、国际单位/体积（IU/v）、质量/质量（w/w）】
        this.specModeChoice = true
      } else {
        this.specData.concentration = null
        this.specData.maxConcentration = null
        this.specMode = '1'
        this.specModeChoice = false
      }
      this.specIndex = null
    },
    async tableValue(val) {
      // // console.log(val)
      // // console.log(this.preparationId)
      // // this.tableData.push(val)
      // // this.preparationId = row.preparationId.toString()
      // let res = await getSpecInfo(this.preparationId)
      // // console.log(res)
      // if(res.code === 200) {
      //   this.tableData = res.data
      // }
      // this.selectPreparationList()

      // console.log(val)
      // console.log(this.specIndex)
      // console.log(typeof this.specIndex)
      if (this.specIndex !== null && this.specIndex !== undefined && this.specIndex !== '') {
        // console.log(this.specIndex)
        this.tableData[this.specIndex] = val
        // console.log(this.tableData[this.specIndex])
      } else {
        // console.log("*****************specGroupKey****************")
        // console.log(this.specGroupKey)
        if (this.specGroupKey !== null && this.specGroupKey !== undefined && this.specGroupKey !== '') {
          val.specGroupKey = this.specGroupKey
          val.isDefault = false
        }
        this.tableData.push(val)
        // console.log("****************tableData*****************")
        // console.log(this.tableData)
      }
      this.$nextTick(() => {
        this.$refs.specTableData.doLayout()
      })
    },
    closeAddSF() {
      this.isAddSF = false
    },
    // 远程搜索获取制剂名称
    querySearchAsync(queryString, cb) {
      if (queryString && queryString.trim() !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          selectPreparationNameList(queryString.trim()).then(response => {
            cb(response.data)
          })
        }, 200)
      } else {
        cb([])
      }
    },
    // 设置按钮-获取设置的数据
    async getSettingTable () {
      let _this = this
      let obj = {}
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 5)
      this.sltConfigKey = item.configKey //-表格配置
      this.sltConfigClassify = item.configClassify // 配置分类
      obj.configKey = this.sltConfigKey
      this.columnOptions = []
      let res = await UCgetConfig(obj)
      if (res.data  && res.data.configValue) {
        let list = JSON.parse(res.data.configValue).list
        if (list.length > 0) {
          list.forEach(function (item) {
            if (item.show==undefined || item.show==true) {
              _this.columnOptions.push(item)
            }
          })
        }else {
          _this.msgError('获取数据表格配置信息失败')
        }
      }else{
        let defaultConfig=this.options
        defaultConfig.forEach(function (item) {
          if (item.show==undefined || item.show==true) {
            _this.columnOptions.push(item)
          }
        })
      }
      _this.$nextTick(() => {
        _this.$refs.refundTable.doLayout()
      })
    },
    // 设置
    async columnSettings() {
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 5)
      this.sltConfigKey = item.configKey
      this.sltConfigClassify = item.configClassify
      let _this = this
      _this.$ColumnSettings.showColSettings(
        _this.sltConfigKey,
        _this.sltConfigClassify,
        _this.options,
        _this.getSettingTable
      )
    },
    /** 查询制剂维护列表 */
    selectPreparationList() {
      this.loading = true
      preparationList(this.zJquery).then(response => {
        this.zJquery.total=response.data.total
        setCache("preparationQueryParams",this.zJquery)
        setCache("preparationPageData",response.data.rows)
        this.preparationList = response.data.rows
        // console.log(response)
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.isResult = true
      this.zJquery.pageNum = 1
      this.selectPreparationList()
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.$router.push('/preparationSpecification/edit/')
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      const preparationId = row.preparationId.toString()
      this.$router.push('/preparationSpecification/edit/' + preparationId)
    },
    /*添加制剂名称输入表单组件*/
    addSubstanceName(type) {
      let defaultVal
      if (this.languageConcept && this.languageConcept.length > 0) {
        this.languageConcept.forEach(item=>{
          if(item.conceptVal==type){
            defaultVal=item.conceptId
          }
        })
      }
      this.aliasManagement.preparationAliasList.push({
        preparationAlias: '',
        languageId: defaultVal,
        isDefault: this.aliasManagement.preparationAliasList.length == 0 ? true : false
      })
      if (this.aliasManagement.preparationAliasList.length == 1) {
        //添加组件时，默认第一个选中为默认
        this.aliasManagement.namePrimary = 0
      }

    },
    /** 点击别名修改 */
    async handleAlias(row) {
      // console.log(row)
      this.preparationId = row.preparationId.toString()
      let res = await getAliasInfo(this.preparationId)
      // console.log(res)
      if(res.code === 200) {
        // this.tableData = res.data
        this.aliasManagement.preparationAliasList = res.data
        this.aliasDialogVisible = true
      }
    },
    aliasHandleClose() {
      this.aliasDialogVisible = false
    },
    returnBackAliasList() {
      this.aliasDialogVisible = false
    },
    // 规格管理弹出框
    returnBackSpecList() {
      this.specDialogVisible = false
    },
    // 修改规格保存
     async saveSpec() {
       let obj = {}
       obj.ppSpecBeanList = this.tableData
       obj.preparationId = this.preparationId
       // console.log(obj)
       let res = await addSpecBatch(this.preparationId, this.tableData)
       if (res.code === 200) {
         this.msgSuccess('修改成功')
         this.specDialogVisible = false
       }
    },
    /*移除制剂名称输入表单组件*/
    delSubstanceName(item) {
      // aliasManagement.preparationAliasList
      let index = this.aliasManagement.preparationAliasList.indexOf(item)
      if (this.aliasManagement.preparationAliasList.length > 1) {
        this.aliasManagement.preparationAliasList.splice(index, 1)
      } else {
        this.$message('制剂名称至少保留一个！')
      }
      // 删除后如果没有默认值了，取第一个作为默认值
      let primary = -1
      this.aliasManagement.preparationAliasList.forEach(function(item, index) {
        if (item.isDefault === true) {
          primary = index
          return
        }
      })
      if (primary === -1) {
        //删除后没有默认值了
        this.aliasManagement.preparationAliasList[0].isDefault = true
        this.aliasManagement.namePrimary = 0
      }

    },
    primaryChange(value, type, index) {
      // console.log(value, type, index)
      let field = type === 'name' ? 'preparationAliasList' : 'dkbSubstanceDesc'
      let primaryField = type === 'name' ? 'namePrimary' : 'descPrimary'
      this.aliasManagement[primaryField] = index
      // console.log(this.aliasManagement[primaryField])
      this.aliasManagement[field].forEach(function(item, i) {
        if (i === index) {
          item.isDefault = true
        } else {
          item.isDefault = false
        }
      })
      this.$forceUpdate()
    },
    async saveAlias() {
      this.$refs['aliasManagementForm'].validate(valid => {
        if (valid) {
          let ppRouteOptBean = {}
          ppRouteOptBean.preparationId = this.preparationId
          ppRouteOptBean.preparationAliasList = this.aliasManagement.preparationAliasList
          modifyAlias(ppRouteOptBean).then(res =>{
            if(res.code === 200) {
              this.msgSuccess('名称修改成功')
              this.aliasDialogVisible = false
              this.selectPreparationList()
            }
          })
        } else {
          this.msgError('请规范填写')
        }
      })
    },
    /** 点击规格修改 */
    async handleSpec(row) {
      this.concentration = row.concentration
      this.preparationId = row.preparationId.toString()
      await this.getSpecInfoFn(this.preparationId)
      this.specDialogVisible = true
    },
    async getSpecInfoFn(preparationId) {
      let res = await getSpecInfo(preparationId)
      // console.log(res)
      if(res.code === 200) {
        this.tableData = res.data
      }
    },
    specHandleClose() {
      this.specDialogVisible = false
    },
    remoteMethod_Route(val) {
      // console.log(val)
      if (val && val.trim() !== '') {
        let arr = []
        this.resultNameOptions02.forEach((item) => {
          arr.push(item.routeId)
        })
        // console.log(arr)
        let obj = {}
        obj.searchVal = val
        obj.ignoreIds = arr.toString()
        routeEnable(obj).then(response => {
          // console.log(response)
          this.routeTableData = response.data
        })
      }
    },
    changeRemote_Route(val) {
      // console.log(val)
    },
    addRoute(index, row) {
      // console.log(index, row)
      // console.log(this.resultNameOptions02)
      const item = this.resultNameOptions02.find(item => item.routeId === row.routeId)
      if (item) {
        this.msgError('用药途径不能重复选择，请重新添加！')
        return false
      } else {
        // this.isChoiceType = false
        let drugComponentObj = {}
        drugComponentObj.routeName = row.routeName
        drugComponentObj.routeId = row.routeId
        drugComponentObj.isDefault = row.isDefault
        drugComponentObj.id = row.id
        drugComponentObj.preparationId = row.preparationId
        this.resultNameOptions02.push(drugComponentObj)
        // console.log(this.resultNameOptions02)
        this.$nextTick(() => {
          this.$refs.routeName.doLayout()
        })
      }
    },
    remoteMethod_site(val) {
      // console.log(val)
      if (val && val.trim() !== '') {
        // console.log(this.resultNameOptions)
        let arr = []
        this.resultNameOptions.forEach((item) => {
          arr.push(item.siteId)
        })
        // console.log(arr)
        let obj = {}
        obj.searchVal = val
        obj.ignoreIds = arr.toString()
        siteEnable(obj).then(response => {
          // console.log(response)
          this.siteTableData = response.data
        })
      }
    },
    changeRemote_site(val) {
      // console.log(val)
    },
    addSite(index, row) {
      // console.log(index, row)
      // console.log(this.resultNameOptions)
      const item = this.resultNameOptions.find(item => item.siteId === row.siteId)
      if (item) {
        this.msgError('给药部位不能重复选择，请重新添加！')
        return false
      } else {
        // this.isChoiceType = false
        let drugComponentObj = {}
        drugComponentObj.siteName = row.siteName
        drugComponentObj.siteId = row.siteId
        drugComponentObj.isDefault = row.isDefault
        drugComponentObj.id = row.id
        drugComponentObj.preparationId = row.preparationId
        // drugComponentArr.push(drugComponentObj)
        this.resultNameOptions.push(drugComponentObj)
        // console.log(this.resultNameOptions)
        this.$nextTick(() => {
          this.$refs.siteName.doLayout()
        })
      }
    },
    /** 点击用药部位修改 */
    async handlePosition(row) {
      // console.log(row.preparationId)
      this.preparationId = row.preparationId.toString()
      let res = await site(this.preparationId)
      // console.log(res)
      if(res.code === 200) {
        this.resultNameOptions = res.data
        this.siteDialogVisible = true
      }
    },
    siteHandleClose() {
      this.siteDialogVisible = false
    },
    /*给药部位检索*/
    querySiteBeanList() {
      this.$refs['ppSiteBeanListForm'].validate(valid => {
        if (valid) {
          let obj = {}
          obj.searchVal = this.ppSiteBeanList.siteName
          this.ppSiteBeanList.drugGroupTableVisible = true
          siteEnable(obj).then(response => {
            // console.log(response)
            this.ppSiteBeanList.drugGroupTableData = response.data
            this.loading = false
          })
        } else {
        }
      })
    },
    deleteSiteRow(index, rows) {
      rows.splice(index, 1);
    },
    setSiteRow(index, rows) {
      // console.log(index, rows)
      rows.forEach((item) => {
        item.isDefault = false
      })
      if (rows[index].isDefault === false) {
        rows[index].isDefault = true
      }
    },
    /*给药部位检索表格，选中数据*/
    chooseSiteBean(index, row) {
      // console.log(index, row)
      const item = this.resultNameOptions.find(item => item.id === row.id)
      if (item) {
        this.msgError('给药部位不能重复选择，请重新添加！')
        return false
      } else {
        let drugComponentObj = {}
        drugComponentObj.siteName = row.siteName
        drugComponentObj.siteId = row.siteId
        drugComponentObj.isDefault = row.isDefault
        drugComponentObj.id = row.id
        drugComponentObj.preparationId = row.preparationId
        // drugComponentArr.push(drugComponentObj)
        this.resultNameOptions.push(drugComponentObj)
        // console.log(this.resultNameOptions)
        // this.$nextTick(() => {
        //   this.$refs.rno.doLayout()
        // })
      }
    },
    // 修改途径返回
    returnBackSiteList() {
      this.siteDialogVisible = false
    },
    async saveSite() {
      let ppRouteOptBean = {}
      ppRouteOptBean.preparationId = this.preparationId
      ppRouteOptBean.ppSiteBeanList = this.resultNameOptions
      // console.log(ppRouteOptBean)
      let res = await modifySite(ppRouteOptBean)
      if(res.code === 200) {
        this.msgSuccess('用药部位修改成功')
        this.siteDialogVisible = false
        this.selectPreparationList()
      }

    },
    deleteRouteRow(index, rows) {
      rows.splice(index, 1);
    },
    /*点击途径修改 */
    async handleChannel(row) {
      // console.log(row.preparationId)
      this.preparationId = row.preparationId
      let res = await route(this.preparationId)
      // console.log(res)
      if(res.code === 200) {
        this.resultNameOptions02 = res.data
        this.channelDialogVisible = true
      }
    },
    /*用药途径检索*/
    queryRouteBeanList() {
      this.$refs['ppRouteBeanList'].validate(valid => {
        if (valid) {
          let obj = {}
          obj.searchVal = this.ppRouteBeanList.routeName
          this.ppRouteBeanList.drugGroupTableVisible = true
          routeEnable(obj).then(response => {
            // console.log(response)
            this.ppRouteBeanList.drugGroupTableData = response.data
          })
        }
      })
    },
    /*用药途径检索表格，选中数据*/
    chooseRouteBean(index, row) {
      // console.log(index, row)
      const item = this.resultNameOptions02.find(item => item.id === row.id)
      if (item) {
        this.msgError('用药途径不能重复选择，请重新添加！')
        return false
      } else {
        let drugComponentObj = {}
        drugComponentObj.routeName = row.routeName
        drugComponentObj.routeId = row.routeId
        drugComponentObj.isDefault = row.isDefault
        drugComponentObj.id = row.id
        drugComponentObj.preparationId = row.preparationId
        this.resultNameOptions02.push(drugComponentObj)
        // console.log(this.resultNameOptions02)
        // this.$nextTick(() => {
        //   this.$refs.rno.doLayout()
        // })
      }
    },
    setRouteRow(index, rows) {
      // console.log(index, rows)
      rows.forEach((item) => {
        item.isDefault = false
      })
      if (rows[index].isDefault === false) {
        rows[index].isDefault = true
      }
    },
    // 修改途径返回
    returnBackList() {
      this.channelDialogVisible = false
    },
    async saveRoute() {
      let ppRouteOptBean = {}
      ppRouteOptBean.preparationId = this.preparationId
      ppRouteOptBean.ppRouteBeanList = this.resultNameOptions02
      // console.log(ppRouteOptBean)
      let res = await modifyRoute(ppRouteOptBean)
      if(res.code === 200) {
        this.msgSuccess('途径修改成功')
        this.channelDialogVisible = false
        this.selectPreparationList()
      }

    },
    channelHandleClose() {
      this.channelDialogVisible = false
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const id = row.preparationId
      // let that = this
      this.$confirm('确认要删除' + '"[' + row.preparationId + ']-' + row.preparationAlias + '"吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        let obj = {}
        obj.id = id
        delPreparation(obj)
      }).then(() => {
        this.selectPreparationList()
        this.msgSuccess('删除成功')
      }).catch(function() {

      })
    },
    /*制剂状态修改*/
    handleStatusChange(row) {
      let text = row.status === '0' ? '启用' : '停用'
      this.$confirm('确认要"' + text + '"ID为"' + row.preparationId + '"的制剂吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        return modifyPreparation(row.preparationId, row.status)
      }).then(() => {
        this.msgSuccess(text + '成功')
      }).catch(function() {
        row.status = row.status === '0' ? '1' : '0'
      })
    },
    /** 来源按钮操作 */
    handleSource(row) {
      // console.log('制剂来源')
      this.isSpecShow = false
      this.editTableRow = row
      this.resourceTableForm.list =[]
      //查询药物来源引用信息
      //查询数据库获取引用资源信息
      selectReferenceInfo(tableConst.DKB_PP, row.preparationId, '').then(response => {
        //this.resourceTableData = response.data
        if (response.data) {
          this.resourceTableForm.list = response.data
        } else {
          this.resourceTableForm.list = []
        }
        this.resourceTableVisible = true
        if (response.data.length > 0) {
          //还原表单验证
          this.resetForm('resourceTableForm')
        }

      })

    },
    handleSource02(row, index) { // 查看规格来源
      // console.log('规格来源')
      this.isSpecShow = true
      this.specIndex = index
      this.resourceTableVisible = true
      this.resourceTableForm.list = []
      if (row.dkbReferenceInfoList) {
        this.resourceTableForm.list = row.dkbReferenceInfoList
      } else {
        this.resourceTableForm.list = []
      }
    },
    // 新建药品
    setDrugProd(row, index) {
      // console.log(row)
      this.$router.push('/drugProd/addByPpSpecId/' + row.id)
    },
    editSpecIngredient(row, index) {
      this.isEditIngredient = true
      this.ppSpecIngredientList = row.ppSpecIngredientList
      this.singleSpecId = row.id
      this.specId = row.specId
      this.specIndex = index
    },
    /*规格添加一个来源*/
    addResource02() {
      // console.log(this.resourceTableForm)
      this.resourceTableForm.list.push({
        sourceTable: tableConst.DKB_PP_SPEC,
        sourceTableField: '',
        sourceTableId: this.editTableRow.preparationId,
        resourceTypeId: null,
        resourceId: null
      })
    },
    /*制剂添加一个来源*/
    addResource() {
      this.resourceTableForm.list.push({
        sourceTable: tableConst.DKB_PP,
        sourceTableField: '',
        sourceTableId: this.editTableRow.preparationId,
        resourceTypeId: null,
        resourceId: null
      })
    },
    /* 保存引用资源*/
    saveResource() {
      this.$refs['resourceTableForm'].validate(valid => {
        if (valid) {
          saveOrUpdateInfoList(this.resourceTableForm.list, this.editTableRow.preparationId, tableConst.DKB_PP).then(response => {
            this.resourceTableVisible = false;
            this.msgSuccess()
          })
        }else{
          this.msgError('请规范填写')
        }
      })
    },
    /* 保存引用资源*/
    saveResource02() {
      this.$refs['resourceTableForm'].validate(valid => {
        if (valid) {
          // console.log(this.resourceTableForm.list)
          this.tableData[this.specIndex].dkbReferenceInfoList = this.resourceTableForm.list
          // console.log(this.tableData)
          this.resourceTableVisible = false
        }else{
          this.msgError('请规范填写')
        }
      })

    },
    resourceNameChange() {
      this.resourceDataOption = []
    },
    /*引用资源名称下拉列表搜索*/
    resourceNameQuery(query, resourceTypeId) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          resourceNameQuery(resourceTypeId, query).then(response => {
            this.resourceDataOption = response.data

          })
        }, 200)
      } else {
        this.options = []
      }
    },
    /*处理来源引用表格删除*/
    handleResourceDelete(index, row) {
      this.resourceTableForm.list.splice(index, 1)

    },
    /*表格排序切换*/
    tableSortChange({column, prop, order}){
      if(order!=null){
        order=order=='descending'?'desc':'asc';
      }else{
        order=''
      }

      prop=prop=='preparationId'?'id':prop
      this.zJquery.orderByColumn=prop
      this.zJquery.isAsc=order
      this.handleQuery()
    },
    psRowClass({row, column, rowIndex, columnIndex}){
      if ( row.specList == null){
        //隐藏表格展开折叠行图标
        return 'hidden-expand';
      } else {
        return '';
      }

    },
    /*鼠标右键点击事件*/
    rowContextmenu (row, column, event) {
      this.tableContextMenuVisible = false
      this.tableContextMenuVisible = true
      // 阻止右键默认行为
      event.preventDefault()
      this.$nextTick(() => {
        this.$refs.contextbutton.init(row,column,event)
      })
    },
    /* 取消鼠标监听事件 菜单栏*/
    foo() {
      this.tableContextMenuVisible = false
      document.removeEventListener('click', this.foo)
    },
    /* 处理表格右键菜单点击*/
    handleTableAction (actionName,row, column) {
      this.psTitle = row.preparationAlias
      if(actionName==='修改'){
        this.handleUpdate(row)
      }else if(actionName==='途径'){
        this.handleChannel(row)
      }else if(actionName==='部位'){
        this.handlePosition(row)
      }else if(actionName==='别名'){
        this.handleAlias(row)
      }else if(actionName==='规格'){
        this.handleSpec(row)
      }else if(actionName==='来源'){
        this.handleSource(row)
      }else if(actionName==='删除'){
        this.handleDelete(row)
      }else if(actionName==='查询相关药品'){
        this.queryRelatedDrugProd(row,1)
      } else if (actionName === '查看') {
        this.handleSearchdate(row)
      } else if(actionName==='分类维护'){
        this.handleDrugCategoryEdit(row.preparationId)
      }
    },
    /** 查看按钮操作 */
    handleSearchdate(row) {
      const preparationId = row.preparationId
      this.$router.push('/preparationSpecification/view/' + preparationId)
    },
    /*查询相关药品*/
    queryRelatedDrugProd(row,type){
      console.log(row,type)
      let query={}
      if(type==1){
        //根据制剂名称查询
        query.preparationName=row.preparationAlias
        query.preparationId=row.preparationId
      }else if(type==2){
        //根据制剂规格查询
        query.specId=row.specId
        query.specDesc=row.specDesc
        query.preparationName=row.preparationAlias
        query.preparationId=row.preparationId
      }
      this.$store.dispatch('tagsView/delCachedView', {name: "DrugProd"}).then(res => {
        this.$router.push({
          path:'/drugProd/list',
          query: query
        })
      })

    },
    /*新增药品*/
    addDrugProd(index,row){
      this.$router.push('/drugProd/addByPpSpecId/' + row.specId)
    },
    // 成分新增或修改
    addIngredient(index,row) {
      console.log(row)
      this.isGetArrShow = false
      this.isEditIngredient = true
      this.specId = row.specId
      this.singleSpecId = row.id
    },
    closeEditIngredient(val){
      this.isEditIngredient = val
    },
    handleIngredientEditFinish() {
      this.isEditIngredient = false
      this.handleQuery()
    },
    getIngredientList(val) {
      this.isEditIngredient = false
      this.tableData[this.specIndex].ppSpecIngredientList = val
    },
    /* 名称类型-校验*/
    validateAliasType(rule, value, callback, index) {
      if (!isEmpty(value)) {
        let allName=this.aliasManagement.preparationAliasList
        let englishNameSize=0
        let genericNameSize=0
        allName.forEach(function(item, i) {
          if(item.aliasType==conceptConst.PUB_NAME_TYPE_EN){
            englishNameSize++
          } else if(item.aliasType==conceptConst.PUB_NAME_TYPE_GENERIC){
            genericNameSize++
          }
        })
        if(englishNameSize>1){
          callback(new Error('英文名只能有一个'))
        }else if(genericNameSize>1){
          callback(new Error('通用名只能有一个'))
        }else{
          callback()
        }

      }else {
        callback()
      }

    },
    /* 制剂名称-重复性校验*/
    validatePreparationAlias(rule, value, callback,index){
      if(!isEmpty(value)){
        // 调用接口校验是否有重复
        let obj = {}
        obj.fieldName = 'preparation_alias'
        obj.fieldValue = value
        obj.preparationId =this.preparationId
        checkFields(obj).then(response => {
          let item=response.data && response.data !== null ? response.data:undefined
          if(item){
            callback(new Error('制剂名称已存在,制剂id为['+item.preparationId+']'));
          }else{
            //页面表单校验
            let aliasList = this.aliasManagement.preparationAliasList
            let key = Object.keys(index)[0]
            let nameIndex = key.replaceAll('preparationAliasList.', '').replaceAll('.preparationAlias', '')
            let nowName=aliasList[nameIndex]
            if (aliasList) {
              aliasList.forEach(function(j, i) {
                //名称完全一样算重复
                if (i != nameIndex && j.preparationAlias && j.preparationAlias.trim() === value.trim() ) {
                  callback(new Error('制剂名称已存在'))
                }
              })
            }

            callback()
          }
        })

      }else{
        callback();
      }
    },
    // 处理分类维护
    handleDrugCategoryEdit(dataId){
      this.$refs.drugCategoryEdit.refreshDrugCategoryTree(dataId,4)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-table .cell .el-form-item {
  margin-bottom: 0px;
}

/*查看来源引用图标*/
.el-icon-question{
  color: #E99B00;
  font-size: 16px;
  margin-right: 6px;
}
.leftIconName{
  display:block;
  float:left;
  text-align:right;
  min-width:40px;
}
.rightContentName{
  display:block;
  float:left;
  word-break:break-all;
  display:-webkit-box; /**对象作为伸缩盒子模型展示**/
  -webkit-box-orient:vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
  -webkit-line-clamp:2; /**显示的行数**/
  overflow:hidden;
  text-align:left;
}
.leftIcon{
  display:block;
  float:left;
  // width:50px;
}
.rightContent{
  display:block;
  float:left;
  width:650px;
  word-break:break-all;
  display:-webkit-box; /**对象作为伸缩盒子模型展示**/
  -webkit-box-orient:vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
  -webkit-line-clamp:2; /**显示的行数**/
  overflow:hidden;
  text-align:left;
}
.iconStyle{
  display: inline-block;
  // background: #ff0;
  min-width: 30px;
  text-align: right;
}
/*删除按钮*/
.el-icon-remove {
  color: #F52C2C;
  font-size: 20px;
  padding: 0px 6px;
}
.spanWrap p{
  margin: 0!important;
}
.el-table {
  th.gutter, colgroup.gutter {
    width: 5px !important;//此处的宽度值，对应你自定义滚动条的宽度即可
  }
}
</style>
<style lang="scss">
/*隐藏表格行的展开折叠按钮*/
.hidden-expand .el-table__expand-column .cell {
  display: none!important;
}
  #prepTableList .el-table__expanded-cell {
    padding: 0px !important;
    padding-left: 44px !important;
    border-bottom: 1px solid #dfe6ec;
  }

.headerClass .query-form .el-form-item__content{
  line-height: 0px;
}
</style>
