var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { height: "100%" } },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { height: "100%" },
        },
        [
          _c(
            "el-col",
            { staticStyle: { height: "100%" }, attrs: { span: 12 } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    height: "50px",
                    "line-height": "50px",
                    background: "#f4f4f5",
                    "padding-left": "20px",
                    "border-right": "1px solid #DCDFE6",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { size: "small", placeholder: "请输入内容" },
                    model: {
                      value: _vm.treeSearchVal,
                      callback: function ($$v) {
                        _vm.treeSearchVal = $$v
                      },
                      expression: "treeSearchVal",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "30px" },
                      attrs: {
                        type: "info",
                        size: "small",
                        icon: "el-icon-refresh",
                      },
                      on: { click: _vm.selectResourceCategoryTree },
                    },
                    [_vm._v("刷新 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-circle-plus-outline",
                      },
                      on: { click: _vm.addCategory },
                    },
                    [_vm._v("新增 ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    height: "calc(100% - 50px)",
                    overflow: "auto",
                    "padding-top": "20px",
                  },
                },
                [
                  _c("el-tree", {
                    ref: "categoryTree",
                    attrs: {
                      data: _vm.treeData,
                      "default-expand-all": "",
                      props: _vm.defaultTreeProps,
                      "expand-on-click-node": false,
                      "highlight-current": true,
                      "filter-node-method": _vm.filterTreeNode,
                    },
                    on: { "node-click": _vm.handleNodeClick },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-col",
            {
              staticStyle: { height: "100%", overflow: "auto" },
              attrs: { span: 12 },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    height: "50px",
                    "line-height": "50px",
                    background: "#f4f4f5",
                    "padding-left": "20px",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.editCategoryForm.typeName) + " ")]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    height: "calc(100% - 50px)",
                    overflow: "auto",
                    "padding-top": "20px",
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.editCategoryForm.id,
                          expression: "editCategoryForm.id",
                        },
                      ],
                      ref: "editCategoryForm",
                      staticClass: "edit-category-form",
                      staticStyle: { width: "calc(100% - 10px)" },
                      attrs: {
                        model: _vm.editCategoryForm,
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "上级分类", prop: "pid" } },
                        [
                          _vm.selectTreeShow
                            ? _c("treeselect", {
                                attrs: {
                                  options: _vm.treeData,
                                  normalizer: _vm.normalizer,
                                  "show-count": true,
                                  placeholder: "选择上级分类",
                                },
                                model: {
                                  value: _vm.editCategoryForm.pid,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editCategoryForm, "pid", $$v)
                                  },
                                  expression: "editCategoryForm.pid",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "排序码:" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.editCategoryForm.orderNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.editCategoryForm, "orderNo", $$v)
                              },
                              expression: "editCategoryForm.orderNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "分类名称:",
                            prop: "typeName",
                            rules: [
                              {
                                required: true,
                                message: "请输入分类名称",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "50",
                              clearable: "",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.editCategoryForm.typeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.editCategoryForm, "typeName", $$v)
                              },
                              expression: "editCategoryForm.typeName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "国内外标签:" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.editCategoryForm.domesticFlag,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.editCategoryForm,
                                    "domesticFlag",
                                    $$v
                                  )
                                },
                                expression: "editCategoryForm.domesticFlag",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: 1 } }, [
                                _vm._v("国内"),
                              ]),
                              _c("el-radio-button", { attrs: { label: 2 } }, [
                                _vm._v("国外"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-divider"),
                      _vm._l(
                        _vm.editCategoryForm.propertyList,
                        function (item, index) {
                          return _c(
                            "el-form-item",
                            {
                              key: "propertyList-" + index,
                              attrs: {
                                prop: "propertyList." + index + ".contents",
                                rules: [
                                  {
                                    validator: function (
                                      rule,
                                      value,
                                      callback
                                    ) {
                                      _vm.validateProperty(
                                        rule,
                                        value,
                                        callback,
                                        item
                                      )
                                    },
                                    trigger: "blur",
                                  },
                                ],
                                label: item.dictName + ":",
                              },
                            },
                            [
                              item.dictType == 1
                                ? _c("el-input", {
                                    staticStyle: { width: "calc(100% - 60px)" },
                                    model: {
                                      value: item.contents,
                                      callback: function ($$v) {
                                        _vm.$set(item, "contents", $$v)
                                      },
                                      expression: "item.contents",
                                    },
                                  })
                                : _vm._e(),
                              item.dictType == 2
                                ? _c(
                                    "el-upload",
                                    {
                                      staticStyle: {
                                        float: "left",
                                        width: "calc(100% - 60px)",
                                      },
                                      attrs: {
                                        action:
                                          _vm.baseURL +
                                          "/system/file/uploadFile",
                                        "file-list": item.fileList,
                                        data: _vm.uploadParams,
                                        "on-preview": _vm.handlePicturePreview,
                                        accept: ".bmp, .gif, .jpg, .jpeg, .png",
                                        "auto-upload": true,
                                        "before-upload": _vm.beforeUpload,
                                        "on-remove": function (file, fileList) {
                                          _vm.handleFileRemove(
                                            file,
                                            fileList,
                                            item,
                                            index
                                          )
                                        },
                                        "on-success": function (
                                          res,
                                          file,
                                          fileList
                                        ) {
                                          _vm.uploadSuccess(
                                            res,
                                            file,
                                            fileList,
                                            item,
                                            index
                                          )
                                        },
                                        "list-type": "picture-card",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-plus",
                                        attrs: { slot: "default" },
                                        slot: "default",
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "el-upload__tip",
                                          attrs: { slot: "tip" },
                                          slot: "tip",
                                        },
                                        [
                                          _vm._v(
                                            "只能上传bmp/gif/jpg/jpeg/png文件，且大小不超过5MB"
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.dictType == 3
                                ? _c("el-date-picker", {
                                    staticStyle: { width: "calc(100% - 60px)" },
                                    attrs: {
                                      "value-format": "yyyy-MM",
                                      format: "yyyy-MM",
                                      type: "date",
                                      placeholder: "选择日期",
                                    },
                                    model: {
                                      value: item.contents,
                                      callback: function ($$v) {
                                        _vm.$set(item, "contents", $$v)
                                      },
                                      expression: "item.contents",
                                    },
                                  })
                                : _vm._e(),
                              _c("i", {
                                staticClass: "el-icon-remove",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.delProperty(item, index)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                      _c(
                        "el-form-item",
                        { staticStyle: { "margin-bottom": "50px" } },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top-start",
                                width: "400",
                                trigger: "click",
                              },
                            },
                            [
                              _c(
                                "el-table",
                                { attrs: { data: _vm.propertyDict } },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      "min-width": "100",
                                      property: "dictName",
                                      label: "名称",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      "min-width": "80",
                                      property: "dictType",
                                      label: "类型",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.dictType == 1
                                              ? _c(
                                                  "el-tag",
                                                  {
                                                    attrs: { type: "success" },
                                                  },
                                                  [_vm._v("文本输入")]
                                                )
                                              : _vm._e(),
                                            scope.row.dictType == 2
                                              ? _c("el-tag", [
                                                  _vm._v("图片上传"),
                                                ])
                                              : _vm._e(),
                                            scope.row.dictType == 3
                                              ? _c(
                                                  "el-tag",
                                                  {
                                                    attrs: { type: "warning" },
                                                  },
                                                  [_vm._v("日期(yyyy-MM)")]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "80",
                                      p: "",
                                      label: "操作",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "small",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleAddProperty(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("选择")]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "addData",
                                  staticStyle: { width: "calc(100% - 100px)" },
                                  attrs: {
                                    slot: "reference",
                                    type: "primary",
                                    plain: "",
                                  },
                                  slot: "reference",
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-circle-plus el-icon--left",
                                    staticStyle: {
                                      color: "#7C9DF7",
                                      width: "16px",
                                      height: "16px",
                                      display: "inline-block",
                                    },
                                  }),
                                  _vm._v(" 添加属性 "),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editCategoryForm.id,
                      expression: "editCategoryForm.id",
                    },
                  ],
                  staticStyle: {
                    height: "80px",
                    width: "200px",
                    "padding-right": "0px",
                    position: "fixed",
                    right: "20px",
                    bottom: "6px",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.delCategory()
                        },
                      },
                    },
                    [_vm._v("删除")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.submitEdit()
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "图片预览",
            width: "70%",
            visible: _vm.picturePreviewDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.picturePreviewDialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.picturePreviewUrl, alt: "" },
          }),
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "编辑分类信息",
            visible: _vm.editCategoryDialogVisible,
            width: "50%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editCategoryDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "addCategoryForm",
                      attrs: {
                        model: _vm.addCategoryForm,
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "上级分类", prop: "pid" } },
                        [
                          _vm.selectTreeShow
                            ? _c("treeselect", {
                                attrs: {
                                  options: _vm.treeData,
                                  normalizer: _vm.normalizer,
                                  "show-count": true,
                                  placeholder: "选择上级分类",
                                },
                                model: {
                                  value: _vm.addCategoryForm.pid,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addCategoryForm, "pid", $$v)
                                  },
                                  expression: "addCategoryForm.pid",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "排序码:" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.addCategoryForm.orderNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.addCategoryForm, "orderNo", $$v)
                              },
                              expression: "addCategoryForm.orderNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "分类名称:",
                            prop: "typeName",
                            rules: [
                              {
                                required: true,
                                message: "请输入分类名称",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "50",
                              clearable: "",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.addCategoryForm.typeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.addCategoryForm, "typeName", $$v)
                              },
                              expression: "addCategoryForm.typeName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "国内外标签:" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.addCategoryForm.domesticFlag,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addCategoryForm,
                                    "domesticFlag",
                                    $$v
                                  )
                                },
                                expression: "addCategoryForm.domesticFlag",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: 1 } }, [
                                _vm._v("国内"),
                              ]),
                              _c("el-radio-button", { attrs: { label: 2 } }, [
                                _vm._v("国外"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-right": "20px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.editCategoryDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.submitAdd()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }