<!--上传文件组件-->
<template>
	<el-dialog
		v-if="uploadTemplete"
		:title="title"
		:visible.sync="uploadTemplete"
    @close="delUploadDataName"
		width="430px"
		center>
		<div v-show="uploadDataName === '' || uploadDataName === null || uploadDataName === undefined">
			<el-row>
				<el-col>
					<el-button type="text" @click="downloadDocument">{{ btnText }}</el-button>
				</el-col>
			</el-row>
			<el-row>
				<el-col>
					<el-upload
						ref="uploadExcel"
						:limit="1"
						:on-exceed="handleExceed"
						:on-remove="handleRemove"
						:http-request="uploadFile"
						:show-file-list="true"
						:file-list="fileList"
						:auto-upload="true"
						class="upload-demo"
						drag
						action="https://jsonplaceholder.typicode.com/posts/"
						multiple>
						<svg-icon icon-class="shangchuanwendang" style="width:40px;height:44px;color:#4D78F5;margin: 20px 0px 5px;"/>
						<div class="el-upload__text">将文件拖到此处上传</div>
						<div class="el-upload__text">或者，您可以单击此处选择一个文件</div>
						<div class="el-upload__text"><em>点击上传</em></div>
					</el-upload>
				</el-col>
			</el-row>
		</div>
		<div v-show="uploadDataName">
			<el-row>
				<el-col>
					<el-input
						v-model="uploadDataName"
						disabled
						clearable
						style="width: 300px;"
						@change="uploadDataNameChange"/>
					<i class="el-icon-remove" @click.prevent="delUploadDataName"></i>
				</el-col>
			</el-row>
      <el-row v-show="isShowError">
        <el-col>
          <el-button class="download-btn" type="text" @click="onDownloadDetails">上传文件有误，点此下载明细</el-button>
        </el-col>
      </el-row>
			<el-row>
				<el-col style="text-align: center;margin:40px 0px 20px;">
					<el-button type="primary" @click="submitDocument" v-if="isSubmit === false">开始校验并上传</el-button>
					<el-button type="primary" :loading="isSubmit" v-else>校验中请稍后</el-button>
          <!-- <el-button @click="onBack">返回上传</el-button> -->
				</el-col>
			</el-row>
		</div>
	</el-dialog>
</template>
<script>
import {
	downloadExcelApi,
	uploadApi,
  downloadDetailsApi,
  reUploadApi
} from '@/api/dm/drugMetadataUpload/drugMetadataUpload'
import {
  onStartDownload
} from '@/utils/downloadFile'
export default {
	props: {
    title: {
      type: String,
      default: '处理文档'
    },
    btnText: {
      type: String,
      default: '下载机构文档'
    },
		recordId: {
			type: String
		},
    isReUploaded: {
      // 是否是重新上传
      type: Boolean,
      default: false
    },
		downloadApi: {
      // 下载的接口
			type: Function,
			default: downloadExcelApi
		},
		uploadApi: {
      // 上传的接口
			type: Function,
			default: uploadApi
		},
    downloadDetailsApi: {
      // 下载明细的接口
      type: Function,
      default: downloadDetailsApi
    }
	},
	data() {
		return {
			uploadTemplete: false,
			isSubmit: false,
			uploadDataName: '',
      isShowError: false, // 上传文件有误
      downloadDetailsUrl: '',
			fileList: [],
			uploadData: []
		}
	},
  created() {
    this.isSubmit = false
    // console.log('上传组件 created')
  },
	methods: {
    // 校验文件格式是否为excel    
    checkFileType(){   
      const name = this.uploadDataName   
      const suffix = name.substr(name.lastIndexOf('.'))
      if (name === '') {
        this.$message.warning('选择需要导入的Excel文件或json文件！')
        return false
      }
      if (suffix !== '.xls' && suffix !== '.xlsx') {
        this.$message.warning('文件错误，请重新检查')
        return false
      }
      return true
    },
    // 开始校验并上传
		async submitDocument() {
      try {
        this.isSubmit = true
        let checkFlag = this.checkFileType()
        if (!checkFlag) {
          this.isSubmit = false
          // this.delUploadDataName()
          return
        }
        const fileObj = this.uploadData[0]
        // FormData 对象
        const form = new FormData()
        // 文件对象
        form.append('file', fileObj)
        form.append('recordId', this.recordId)
        let res = null
        if (!this.isReUploaded) {
          res = await uploadApi(form)
        } else {
          res = await reUploadApi(form)
        }
        let code1 = res.data && res.data.code
        let code2 = res && res.code
        if (code1 !== 200 && code2 !== 200) {
          this.isSubmit = false
        }
        if (code1 === 200 || code2 === 200) {
          this.isSubmit = false
          this.$message.success('文件：' + fileObj.name + '上传成功')
          this.delUploadDataName() //
          this.uploadTemplete = false
          this.$emit('uploadSucess')
        } else if (code1 === 18888 || code2 === 18888) {
          this.isShowError = true // 显示上传文件错误
          this.downloadDetailsUrl = res.msg || res.data.msg
        } else {
          this.isSubmit = false
        }
      } catch(err) {
        this.isSubmit = false
        console.log(err)
      }
    },
    // 下载明细
    async onDownloadDetails() {
      const obj = { url: this.downloadDetailsUrl}
      let res = await downloadDetailsApi(obj)
      onStartDownload(res)
    },
		// 下载文档
		async downloadDocument() {
			// let recordId = '1'
			const obj = {
				mouldName: 'drug'
			}
			let res = await this.downloadApi(obj)
			// console.log(res)
			this.download(res)
		},
    delUploadDataName() {
      this.isSubmit = false
      this.isShowError = false
      this.uploadDataName = ''
      this.$refs.uploadExcel.clearFiles()
      this.uploadData = []
    },
		// 下载文件
		download (data) {
			// if (!data) {
			// 	return
			// }
      // let downloadName = decodeURI(data.headers['filename'])
			// let url = window.URL.createObjectURL(new Blob([data.data]))
			// let link = document.createElement('a')
			// link.style.display = 'none'
			// link.href = url
			// link.setAttribute('download', downloadName)
			// document.body.appendChild(link)
			// link.click()
      onStartDownload(data)
      this.uploadDataName = ''
		},
		// 上传文件个数超过定义的数量
		handleExceed (files, fileList) {
			this.$message.warning(`当前限制选择 1 个文件，请删除后继续上传`)
		},
		// 删除上传文件
		handleRemove(file) {
			// console.log(file)
			for(let i = 0; i < this.uploadData.length; i++){
				if(file.uid == this.uploadData[i].uid){
					this.uploadData.splice(i,1)
				}
			}
			this.uploadDataName = this.uploadData[0] && this.uploadData[0].name
		},
		// 上传文件
		uploadFile (item) {
			this.uploadData.push(item.file)
			this.uploadDataName = this.uploadData[0].name
		},
    // change选择excel表格
    uploadDataNameChange(val) {
      // console.log(val)
    }
	}
}
</script>
<style lang="scss" scoped>
  .download-btn {
    color: #ED4014;
  }
</style>