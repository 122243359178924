<template>
  <div class="app-container" style="overflow: auto">
    <div ref="headerQuery">
      <el-row class="headerClass">
        <el-col :span="4" style="border-left: 5px solid #0073E9;padding-left: 10px;">物质管理</el-col>
        <el-col :span="20">
          <el-form :model="queryParams" class="query-form" ref="queryForm" :inline="true" v-show="showSearch" style="float: right">
            <el-form-item label="" prop="name">

              <el-autocomplete
                v-model="queryParams.searchValue"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入物质ID/名称/代码/拼音/五笔码"
                @select="handleQuery"
                @clear="setBlur()"
                clearable
                size="small"
                value-key="name"
                style="width: 300px;height: 36px!important;line-height: 36px;"
                @keydown.enter.native="handleQuery"
              >
                <el-select v-model="queryParams.searchValueSearchType"
                           style="width: 74px;"
                           slot="prepend" placeholder="请选择">
                  <el-option label="模糊" value="1"></el-option>
                  <el-option label="精确" value="2"></el-option>
                  <el-option label="前缀" value="3"></el-option>
                </el-select>
              </el-autocomplete>
            </el-form-item>

            <el-form-item label="" prop="status">
              <el-select
                v-model="queryParams.status"
                placeholder="状态"
                clearable
                size="small"
                class="statusClass"
                style="width: 74px"
              >
                <el-option
                  v-for="dict in statusOptions"
                  :key="'statusOptions'+dict.conceptId"
                  :label="dict.conceptVal"
                  :value="dict.conceptId"
                />
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">检索</el-button>
              <el-button icon="el-icon-circle-plus-outline" size="small" class="addClass" @click="handleAdd">新增物质</el-button>
              <el-button icon="fa fa-cog" size="small" @click="columnSettings">设置</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>


    <div ref="tableContainer">
      <el-table
        border
        stripe
        ref="refundTable"
        :data="drugList"
        :height="tableHeight"
        v-loading="loading"
        @sort-change="tableSortChange"
        @row-contextmenu="rowContextmenu"
      >
        <el-table-column
          v-for="data in columnOptions"
          :key="'columnOptions'+data.id"
          :prop="data.prop"
          :label="data.name"
          :fixed="data.fixed"
          :resizable="false"
          :sortable="data.sortable"
          :min-width="data.width"
          :align="data.align"
        >
          <template slot-scope="scope">
            <div v-if="data.prop == 'dkbSubstanceCode'">
              <p v-html="scope.row.substanceCode"></p>
            </div>
            <div v-else-if="data.prop == 'substanceProps'">
              <p v-html="scope.row.substanceProps"></p>
            </div>
            <div v-else-if="data.prop == 'substanceDesc'">
              <el-popover v-show="scope.row.substanceDescReferenceInfos.length>0" placement="top" title="来源引用信息"
                          width="200" trigger="click"
              >
                <div>
                  <p v-for="item in scope.row.substanceDescReferenceInfos"
                     :key="'substanceDescReferenceInfos'+item.id"
                  >
                    {{ '[' + item.resourceTypeName + ']' }}{{ item.resourceName || '' }}
                  </p>
                </div>
                <i class="el-icon-question" slot="reference" title="点击查看来源引用"></i>
              </el-popover>

              <el-popover placement="top" title="全部物质描述"  trigger="click" width="600">
                <div>
                  <p v-for="item in scope.row.dkbSubstanceDesc" :key="'dkbSubstanceDesc'+item.id">
                    {{ item.substanceDesc }}
                  </p>
                </div>
                <span slot="reference" class="setTwoLine" style="color: #2B60F8;font-weight: 700;" title="点击查看全部物质描述">
<!--                  {{ scope.row.substanceDesc.length>50?scope.row.substanceDesc.substring(0,50)+'...':scope.row.substanceDesc }}-->
                  {{ scope.row.substanceDesc }}
                </span>
              </el-popover>
            </div>

            <div v-else-if="data.prop == 'substanceName'">

              <span>
                 <el-popover v-show="scope.row.substanceNameReferenceInfos.length>0" placement="top" title="来源引用信息"
                             width="200" trigger="click"
                 >
                   <div>
                     <p v-for="item in scope.row.substanceNameReferenceInfos"
                        :key="'substanceNameReferenceInfos'+item.id"
                     >
                       {{ '[' + item.resourceTypeName + ']' }}{{ item.resourceName || '' }}
                     </p>
                   </div>
                <i class="el-icon-question" slot="reference" title="点击查看来源引用"></i>
              </el-popover>

              <el-popover placement="top" title="全部名称" width="400" trigger="click">
                <div>
                     <p v-for="item in scope.row.dkbSubstanceName" :key="'dkbSubstanceName'+item.id">
                       {{ item.name }}
                     </p>
                   </div>
                <span slot="reference" style="color: #2B60F8;font-weight: 700;" title="点击查看全部物质名称">
                  <span style="margin-right:5px;">{{ scope.row.substanceName }}</span>
                  <span v-for="item in scope.row.drugCategoryList"
                        :key="item.id"
                  >
                    <svg-icon :icon-class="item.icon" v-if="item.icon"/>
                  </span>
                </span>
              </el-popover>
              </span>
            </div>

            <div v-else>
              <span>{{ scope.row[data.prop] }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center" key="status" width="100" :resizable="false" fixed="right">
          <template slot-scope="scope">
            <el-switch
              class="switch"
              active-color="#5DB730"
              active-text='启用'
              inactive-text='停用'
              inactive-color="#B5CCF5"
              v-model="scope.row.status"
              active-value="0"
              inactive-value="1"
              @change="handleStatusChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>


        <template slot="empty" v-if="isResult === false">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
    </div>

    <table-context-menu v-if="tableContextMenuVisible"
                        @foo="foo" ref="contextbutton"
                        :action="tableAction"
                        @handleTableAction="handleTableAction"></table-context-menu>
    <drug-category-edit ref="drugCategoryEdit"></drug-category-edit>
    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="selectSubstanceList"
      :page-sizes="[10, 20, 50, 100]"
    />



    <el-dialog v-dialogDrag :title="'引用来源管理[' + substanceName + ']'" :visible.sync="resourceTableVisible">
      <el-row>
        <el-col :span="24" style="margin-bottom: 10px;">
          <el-button style="float: right;margin-right: 10px;" icon="el-icon-circle-plus-outline"
                     size="small" @click="addResource"
          >添加
          </el-button>
        </el-col>
      </el-row>
      <el-form ref="resourceTableForm" status-icon :model="resourceTableForm">
        <el-table
          border
          stripe
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
          :data="resourceTableForm.list">
          <el-table-column property="date" label="引用资源类型">
            <template slot-scope="scope">
              <el-form-item
                :prop="'list.' + scope.$index + '.'+'resourceTypeId'"
                :rules="[{ required: true, message: '请选择物质名称', trigger: 'blur'}]"
              >
                <el-select
                  v-model="scope.row.resourceTypeId"
                  placeholder="引用资源类型"
                  size="small"
                  style="width: 200px"
                  @change="resourceTypeChange($event,scope.$index)"
                >
                  <el-option
                    v-for="dict in resourceTypeOption"
                    :key="'resourceTypeOption'+dict.id"
                    :label="dict.typeName"
                    :value="dict.id"
                  />
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column property="name" label="引用资源名称">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.resourceId"
                filterable
                remote
                style="width: 200px"
                reserve-keyword
                placeholder="请输入关键词"
                @change="resourceNameChange($event,scope.$index)"
                :remote-method="(query)=>{resourceNameQuery(query,scope.row.resourceTypeId)}"
                :loading="loading"
                :disabled="!scope.row.resourceTypeId?true:false"
              >
                <el-option
                  v-for="item in resourceDataOption"
                  :key="'resourceDataOption'+item.id"
                  :label="item.name"
                  :value="item.id"
                />
                <el-option
                  v-for="item in [scope.row]"
                  :key="item.resourceId+'original'"
                  :label="item.resourceName"
                  :value="item.resourceId"
                />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="200">
            <template slot-scope="scope">

              <el-button
                size="mini"
                type="text"
                style="color: #FF3333;"
                @click="handleResourceDelete(scope.$index,scope.row)"
              >删除
              </el-button>
            </template>
          </el-table-column>
          <template slot="empty">
            <img src="@/assets/images/no-booking.svg" alt="">
            <p>暂无数据</p>
          </template>
        </el-table>
      </el-form>
      <el-row>
        <el-col :span="24" style="margin-top: 10px;">
          <el-button type="primary" style="float: right;margin-right: 10px;"
                     size="small" @click="saveResource"
          >保存
          </el-button>
          <el-button style="float: right;margin-right: 10px;"
                     size="small" @click="resourceTableVisible=false"
          >取消
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>



  </div>
</template>

<script>
import { del, list, updateStatus, selectSubstanceNameList } from '@/api/dkm/substance'
import { selectReferenceInfo, saveOrUpdateInfoList, resourceNameQuery } from '@/api/dkm/resource'
import { getResourceCategory, getReferenceInfo } from '@/utils/resourceReference'
import tableConst from '@/const/tableConst'
import { getConcept } from '@/utils/concept'
import conceptConst from '@/const/conceptConst'
import { UCgetConfig } from '@/utils/columnSettings'
import { GLOBAL_CONFIGURATION_CONFIGKEY } from '@/utils/constants'
import { isEmpty } from '@/utils/common'
import {   oligomictDrugInfo } from '@/api/dkm/drug'
import { setCache, getCache } from '@/utils/cacheUtil'
import AcrossUtil from '@/utils/acrossUtil'
export default {
  name: 'Substance',
  components: {},
  filters: {
    choiceName: function(value) {
      const item = value.find(item => item.isPrimary === '1')
      if (item) {
        return item.name
      }
    }
  },
  data() {
    return {
      conceptConst,
      tableConst,
      // 遮罩层
      loading: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 药品维护表格数据
      drugList: [],
      // 表格高度
      tableHeight: window.innerHeight - 100 - 100,
      // 状态数据字典
      statusOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        searchValue: null,
        searchValueSearchType:'1',
        orderByColumn:undefined,
        isAsc:undefined,
      },
      /* 控制引用来源表格弹窗显示隐藏*/
      resourceTableVisible: false,
      /*来源类型下拉列表*/
      resourceTypeOption: [],
      resourceDataOption: [],
      /*当前操作表格行数据*/
      editTableRow: [],
      /* 来源引用表格数据*/
      resourceTableForm: {
        list: []
      },
      sltConfigKey: '', // 物质管理-物质列表-表格配置
      sltConfigClassify: '',
      // searchValue: '',
      columnOptions: [],
      refundForm: {
        refundTableData: []
      },
      tableOptions: [
        {
          id: 1,
          prop: 'id',
          name: '物质ID',
          fixed: false,
          show: true,
          sortable:'custom',
          align: 'center',
          width: '100'
        },
        {
          id: 2,
          prop: 'substanceName',
          name: '物质名称',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '200'
        },
        {
          id: 3,
          prop: 'substanceDesc',
          name: '物质描述',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '400'
        },
        {
          id: 4,
          prop: 'dkbSubstanceCode',
          name: '物质代码',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '300'
        },
        {
          id: 5,
          prop: 'substanceProps',
          name: '物质属性',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '500'
        },
        {
          id: 6,
          prop: 'relevanceName',
          name: '关联物质',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '150'
        },
        {
          id: 7,
          prop: 'updateTime',
          name: '更新时间',
          fixed: false,
          show: true,
          sortable:  'custom',
          align: 'center',
          width: '150'
        }
      ],
      isResult: false,
      /*表格右键菜单显示隐藏*/
      tableContextMenuVisible:false,
      tableAction:[{name:"修改"},{name:"来源"},{name:"删除",style:'color: #FF3333;'},{name:"分类维护"},{name:"查看"}],
      substanceName: ''
    }
  },
  created() {
    let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 1)
    this.sltConfigKey = item.configKey // 物质管理-物质列表-表格配置
    this.sltConfigClassify = item.configClassify // 配置分类
    this.getSettingTable().then(() => {
      this.$nextTick(() => {
        this.setTableHeight()
        //页面初始化完毕，默认触发一次按照更新时间排序
        this.$refs.refundTable.sort('id','descending');
      })
    })
    //this.selectSubstanceList()
    //获取引用资源类型
    getResourceCategory().then(result => {
      this.resourceTypeOption = result
    })
    //获取状态数据字典
    getConcept(conceptConst.PUB_DATA_STATUS, 0).then(result => {
      this.statusOptions = result
    })

  },
  activated() {
    //激活时重新设置表格高度
    this.setTableHeight()
  },
  mounted() {
    var _this=this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      AcrossUtil.$emit('resetTableHeight');
    }

    /*监听编辑页面发送的事件，执行相应的函数*/
    AcrossUtil.$on('tableReload', function (param) {
      _this.selectSubstanceList()
    })

    AcrossUtil.$on('resetTableHeight', function (param) {
      setTimeout(function () {
        _this.setTableHeight()
      }, 300);
    })

  },
  methods: {
    /*设置表格的高度*/
    setTableHeight(){
      // 获取高度值 （内容高+padding+边框）
      if(this.$refs.headerQuery){
        let formHeight = this.$refs.headerQuery.offsetHeight
        this.tableHeight = window.innerHeight - formHeight-150
      }

    },
    // 设置按钮-获取设置的数据
    async getSettingTable () {
      let _this = this
      let obj = {}
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 1)
      this.sltConfigKey = item.configKey // 药物管理-药物列表-表格配置
      this.sltConfigClassify = item.configClassify // 配置分类
      obj.configKey = this.sltConfigKey
      this.columnOptions = []
      let res = await UCgetConfig(obj)
      if (res.data  && res.data.configValue) {
        let list = JSON.parse(res.data.configValue).list
        if (list.length > 0) {
          list.forEach(function (item) {
            if (item.show==undefined || item.show==true) {
              _this.columnOptions.push(item)
            }
          })
        }else {
          _this.msgError('获取数据表格配置信息失败')
        }
      }else{
        let defaultConfig=this.tableOptions
        defaultConfig.forEach(function (item) {
          if (item.show==undefined || item.show==true) {
            _this.columnOptions.push(item)
          }
        })
      }
      _this.$nextTick(() => {
        _this.$refs.refundTable.doLayout()
      })
    },
    // 设置
    async columnSettings() {
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 1)
      this.sltConfigKey = item.configKey
      this.sltConfigClassify = item.configClassify
      let _this = this
      _this.$ColumnSettings.showColSettings(
        _this.sltConfigKey,
        _this.sltConfigClassify,
        _this.tableOptions,
        _this.getSettingTable
      )
    },


    // 物质名称是否显示icon
    isShowIcon(val) {
      const item = val.find(item => item.isPrimary === '1')
      if (item) {
        if (item.dkbReferenceInfos === null || item.dkbReferenceInfos === undefined || item.dkbReferenceInfos === '' || item.dkbReferenceInfos.length === 0) {
          return false
        } else {
          return true
        }
      }
    },
    /** 查询药品维护列表 */
    selectSubstanceList() {
      this.loading = true
      list(this.queryParams).then(response => {
        this.queryParams.total=response.total
        setCache("substanceQueryParams",this.queryParams)
        setCache("substancePageData",response.rows)
        this.drugList = response.rows
        this.drugList.forEach((item) => {
          // 推荐的物质名称 和 名称的来源引用信息
          item.substanceNameReferenceInfos=[]
          item.dkbSubstanceName.forEach((j) => {
            if (j.isPrimary === '1') {
              item.substanceName = j.name
              item.substanceNameReferenceInfos = j.dkbReferenceInfos || []
            }
          })
          // 推荐的物质描述 和物质描述的来源引用信息
          item.substanceDescReferenceInfos=[]
          item.substanceDesc=''
          item.dkbSubstanceDesc.forEach((j) => {
            if (j.isPrimary === '1') {
              item.substanceDesc = j.substanceDesc
              item.substanceDescReferenceInfos = j.dkbReferenceInfos
            }
          })

          // 处理物质代码
          item.substanceCode = ''
          if (item.dkbSubstanceCode) {
            item.dkbSubstanceCode.forEach((j) => {
              if (j.codeSysName === 'unii') {
                item.substanceCode += '<span style="padding: 0px 5px;"><span style="font-weight: 700;">UNII:</span>'
                item.substanceCode += '<span>' + j.substanceCode + '</span></span>'
              } else if (j.codeSysName === 'cas') {
                item.substanceCode += '<span style="padding: 0px 5px;"><span style="font-weight: 700;">CAS:</span>'
                item.substanceCode += '<span>' + j.substanceCode + '</span></span>'
              }else if (j.codeSysName === 'GBT-31774-2015') {
                item.substanceCode += '<span style="padding: 0px 5px;"><span style="font-weight: 700;">GBT-31774-2015:</span>'
                item.substanceCode += '<span>' + j.substanceCode + '</span></span>'
              }

            })
          }

          // 处理物质属性
          item.substanceProps = ''
          if (item.dkbSubstanceProps) {
            item.dkbSubstanceProps.forEach((j) => {
              item.substanceProps += '<span style="padding: 0px 5px;"><span style="font-weight: 700;">' + j.nameDesc + ':</span>'
              item.substanceProps += '<span>' + j.valueDesc + '</span></span>'
            })
          }

        })
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.isResult = true
      this.queryParams.pageNum = 1
      this.selectSubstanceList()
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.$router.push('/substance/edit/')
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      const substanceId = row.substanceId
      setCache("editSubstanceId",substanceId)
        //获取只含有该物质的单成分药物信息
      oligomictDrugInfo(substanceId).then(response => {
        if(response.data){
          //存在只含有该物质的药物,直接跳转到药物编辑界面
          this.$router.push('/drug/editOligomictDrug/' + response.data.id)
        }else{
          //跳转到物质编辑
          this.$router.push('/substance/edit/' + substanceId)
        }
      })

    },
    /** 查看按钮操作 */
    handleSearchdate(row) {
      const substanceId = row.substanceId
      this.$router.push('/substance/search/' + substanceId)
    },
    /** 来源按钮操作 */
    handleSource(row) {

      this.editTableRow = row
      this.resourceTableForm.list = []
      //查询物质来源引用信息
      //查询数据库获取引用资源信息
      selectReferenceInfo('dkb_substance', row.id, '').then(response => {
        //this.resourceTableData = response.data
        this.resourceTableForm.list = response.data
        this.resourceTableVisible = true

        if (response.data.length > 0) {
          //还原表单验证
          this.resetForm('resourceTableForm')
        }

      })

    },

    /** 删除按钮操作 */
    handleDelete(row) {
      const id = row.id
      let that = this
      this.$confirm('确认要删除' + '"[' + row.id + ']-' + row.substanceName + '"吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        del(id).then(response => {
          that.selectSubstanceList()
          that.msgSuccess('删除成功')
        })
      })
    },
    /*物质状态修改*/
    handleStatusChange(row) {
      let text = row.status === '0' ? '启用' : '停用'
      this.$confirm('确认要' + text + '"[' + row.id + ']-' + row.substanceName + '"吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        return updateStatus(row.substanceId, row.status)
      }).then(() => {
        this.msgSuccess(text + '成功')
      }).catch(function() {
        row.status = row.status === '0' ? '1' : '0'
      })
    },
    /*添加一个来源*/
    addResource() {
      this.resourceTableForm.list.push({
        sourceTable: 'dkb_substance',
        sourceTableField: '',
        sourceTableId: this.editTableRow.id,
        resourceTypeId: null,
        resourceId: null
      })
    },
    /* 保存引用资源*/
    saveResource() {
      this.$refs['resourceTableForm'].validate(valid => {
        if (valid) {
          saveOrUpdateInfoList(this.resourceTableForm.list, this.editTableRow.id, 'dkb_substance').then(response => {
            this.resourceTableVisible = false
            this.msgSuccess()
          })
        } else {
          this.msgError('请规范填写')
        }
      })

    },
    resourceNameChange() {
      this.resourceDataOption = []
    },
    /*引用资源名称下拉列表搜索*/
    resourceNameQuery(query, resourceTypeId) {
      if (!isEmpty(query)) {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          resourceNameQuery(resourceTypeId, query.trim()).then(response => {
            this.resourceDataOption = response.data

          })
        }, 200)
      } else {
        this.options = []
      }
    },
    /*处理来源引用表格删除*/
    handleResourceDelete(index, row) {
      this.resourceTableForm.list.splice(index, 1)

    },
    /*引用资源类型--下拉列表值变化*/
    resourceTypeChange(value,index){
      this.resourceDataOption=[];
      let item=this.resourceTableForm.list[index];
      item.resourceId=undefined
      item.resourceName=undefined

    },
    /*重置表单*/
    resetForm(formName) {
      let form = this.$refs[formName]
      if (form) {
        form.resetFields()
      }
    },
    /* 点击表格内部图标查看物质名称和描述的来源引用*/
    showResourceReference(row, tableName) {
      let id
      let data
      if (tableName === tableConst.DKB_SUBSTANCE_NAME) {
        if (row.dkbSubstanceName && row.dkbSubstanceName[0]) {
          data = row.dkbSubstanceName[0]
        }

      } else if (tableName === tableConst.DKB_SUBSTANCE_DESC) {
        if (row.dkbSubstanceDesc && row.dkbSubstanceDesc[0]) {
          data = row.dkbSubstanceDesc[0]
        }
      }
      getReferenceInfo(tableName, data.id).then(result => {
        if (result.length == 0) {
          result = null
        }
        data.dkbReferenceInfos = result
      })

    },
    /* 物质名称检索*/
    querySearchAsync(queryString, cb) {
      if (queryString && queryString.trim() !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          selectSubstanceNameList(queryString.trim()).then(response => {
            cb(response.data)
          })
        }, 200)
      } else {
        cb([])
      }
    },
    /*表格排序切换*/
    tableSortChange({column, prop, order}){
      if(order!=null){
        order=order=='descending'?'desc':'asc';
      }else{
        order=''
      }
      this.queryParams.orderByColumn=prop
      this.queryParams.isAsc=order
      this.handleQuery()
    },
    /*鼠标右键点击事件*/
    rowContextmenu (row, column, event) {
      this.tableContextMenuVisible = false
      this.tableContextMenuVisible = true
      // 阻止右键默认行为
      event.preventDefault()
      this.$nextTick(() => {
        this.$refs.contextbutton.init(row,column,event)
      })
    },
   /* 取消鼠标监听事件 菜单栏*/
    foo() {
      this.tableContextMenuVisible = false
      document.removeEventListener('click', this.foo)
    },
   /* 处理表格右键菜单点击*/
    handleTableAction (actionName,row, column) {
      // console.log(row)
      this.substanceName = row.substanceName
      if(actionName==='修改'){
        this.handleUpdate(row)
      }else if(actionName==='来源'){
        this.handleSource(row)
      }else if(actionName==='删除'){
        this.handleDelete(row)
      } else if(actionName==='查看'){
        this.handleSearchdate(row)
      } else if(actionName==='分类维护'){
        this.handleDrugCategoryEdit(row.id)
      }
    },
    // 处理分类维护
    handleDrugCategoryEdit(dataId){
      this.$refs.drugCategoryEdit.refreshDrugCategoryTree(dataId,1)
    }






  }
}
</script>

<style lang="scss" scoped>
.el-table .cell .el-form-item {
  margin-bottom: 0px;
}

/*查看来源引用图标*/
.el-icon-question {
  color: #E99B00;
  font-size: 16px;
  margin-right: 6px;
}

.leftIconName {
  display: block;
  float: left;
  text-align: right;
  min-width: 40px;
}

.rightContentName {
  display: block;
  float: left;
  word-break: break-all;
  display: -webkit-box; /**对象作为伸缩盒子模型展示**/
  -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
  -webkit-line-clamp: 2; /**显示的行数**/
  overflow: hidden;
  text-align: left;
}

.leftIcon {
  display: block;
  float: left;
  // width:50px;
}

.rightContent {
  display: block;
  float: left;
  width: 650px;
  word-break: break-all;
  display: -webkit-box; /**对象作为伸缩盒子模型展示**/
  -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
  -webkit-line-clamp: 2; /**显示的行数**/
  overflow: hidden;
  text-align: left;
}

.iconStyle {
  display: inline-block;
  // background: #ff0;
  min-width: 30px;
  text-align: right;
}
</style>

<style>
.headerClass .query-form .el-form-item__content{
  line-height: 0px;
}
</style>
