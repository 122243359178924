import request from '@/utils/request'

// 查询上传记录列表
export function uploadRecordList(query) {
  return request({
    url: '/tools/smpaDrug/uploadRecordList',
    method: 'post',
    params: query
  })
}


// 上传文件
export function uploadFiles(data) {
  return request({
    url: '/tools/smpaDrug/uploadFiles',
    method: 'post',
    data: data,
    timeout: 30 * 1000,
  })
}


// 查询上海医保药品列表
export function smpaDrugList(query) {
  query.downloadFlag=0
  return request({
    url: '/tools/smpaDrug/drugList',
    method: 'post',
    params: query,
    timeout: 30 * 1000,
  })
}

// 下载上海医保药品列表
export function downloadSmpaDrugList(query) {
  query.downloadFlag=1
  return request({
    url: '/tools/smpaDrug/drugList',
    method: 'post',
    params: query,
    timeout: 5*60 * 1000,
    responseType: 'blob'
  })
}



// 查询最新版本的上海医保药品列表
export function selectMaxVersionSmpaDrugList(query) {
  return request({
    url: '/tools/smpaDrug/selectMaxVersionDrugList',
    method: 'post',
    params: query
  })
}


// 查询全部版本号
export function selectAllVersionNo() {
  return request({
    url: '/tools/smpaDrug/selectAllVersionNo',
    method: 'get'
  })
}


// 查询单个药品的明细信息
export function drugInfo(tbdm,versionNo) {
  return request({
    url: '/tools/smpaDrug/drugInfo',
    method: 'get',
    params: {tbdm:tbdm,versionNo:versionNo}
  })
}



// 获取上传任务执行进度
export function getProgress() {
  return request({
    url: '/tools/smpaDrug/getProgress',
    method: 'get'
  })
}


// 删除版本数据
export function delVersion(versionNo) {
  return request({
    url: '/tools/smpaDrug/delVersion/' + versionNo,
    method: 'get',
    timeout: 60 * 1000,
  })
}


// 下载差异报告
export function downloadReport(versionNo,fileType) {
  return request({
    url: '/tools/smpaDrug/downloadReportFile',
    method: 'get',
    params: {versionNo:versionNo,fileType:fileType},
    responseType: 'blob'
  })
}



// 查询相关信息（药品价格规则/药品采购规则/品医保可报销条件规则/医保报销比例规则）
export function getRelevanceInfo(tbdm,versionNo) {
  return request({
    url: '/tools/smpaDrug/getRelevanceInfo',
    method: 'get',
    params: {tbdm:tbdm,versionNo:versionNo}
  })
}

