<!--知识库版本升级-->
<template>
  <el-container class="layout-container version-upgrade-container">
    <el-header class="layout-header">
      <section class="header-top">
        <h3 class="title-header-container">
          <span class="title-header"></span>
          <h4 class="title-text">知识库升级维护</h4>
        </h3>
        <div>
          <span style="font-size: 16px;">当前知识库版本号：</span>
          <span style="color: #666;font-size: 16px;">{{ latestVersionObj.version }}</span>
          <span style="margin-left: 20px;font-size: 16px;">更新时间：</span>
          <span style="color: #666;font-size: 16px;">{{ latestVersionObj.pubTime }}</span>
        </div>
      </section>
      <section class="header-btm">
        <el-form :model="formObj" :inline="true" label-width="90px">
          <el-form-item label="机构名称：">
            <el-autocomplete
              v-model.trim="formObj.orgName"
              :fetch-suggestions="onOrgSearch"
              placeholder="输入机构名称"
              clearable
              size="medium"
              value-key="name"
              style="width: 200px;height: 36px!important;line-height: 36px;"/>
          </el-form-item>
          <el-form-item label="状态：">
            <el-select v-model="formObj.status" placeholder="请选择" clearable style="width: 100px;">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" icon="el-icon-search" @click="onSearchTable">查询</el-button>
          </el-form-item>
        </el-form>
      </section>
    </el-header>
    <el-main class="version-upgrade-main">
      <el-table
        :data="tableData"
        :header-cell-style="{textAlign:'center'}"
        style="width: 100%;height: 100%;"
        stripe
        border
        class="platform-report-table">
      <el-table-column
        align="center"
        width="100"
        prop="id"
        fixed>
        <template slot="header" slot-scope="scope">
          <span>编号</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center">
        <template slot="header" slot-scope="scope">
          <span>机构名称</span>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.orgName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center">
        <template slot="header" slot-scope="scope">
          <span>机构库版本号</span>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.orgVersion }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center">
        <template slot="header" slot-scope="scope">
          <span>知识库版本号</span>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.oldDkbVersion }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center">
        <template slot="header" slot-scope="scope">
          <span>知识库升级版本</span>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.dkbVersion }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center">
        <template slot="header" slot-scope="scope">
          <span>最近操作时间</span>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.updateTime }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="statusStr">
        <template slot="header" slot-scope="scope">
          <span>状态</span>
        </template>
        <template slot-scope="scope">
          <span :class="statusText(scope)">{{ scope.row.status | statusShow }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="100"
        type="index"
        label="操作"
        fixed="right">
        <template slot-scope="scope">
          <el-button type="text" @click="onHandleRow(scope.row)">
            {{ scope | operationText }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    </el-main>
    <el-footer>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="formObj.pageNum"
        :limit.sync="formObj.pageSize"
        @pagination="getTableHandle"/>
    </el-footer>
  </el-container>
</template>
<script>
  import {
    getOrgDataApi,
    getTableDataByPageApi,
    getInfoApi
  } from '@/api/dm/knowledgeBaseVersionUpgrade'
  import {
    SEARCH_OBJ,
    STATUS_OPTIONS
  } from '@/utils/dm/knowledgeBaseVersionUpgrade/data'
  import Cookies from "js-cookie"
  export default {
    name: 'KnowledgeBaseVersionUpgrade',
    filters: {
      operationText(scope) {
        // 1:等待中 2:升级中 3:升级完成 4:已取消
        const { status } = scope.row
        if (status === '1') {
          return ''
        } else if (status === '2') {
          return '修改匹配'
        } else if (status === '3') {
          return '查看'
        } else if (status === '4') {
          return ''
        }
      },
      statusShow(status) {
        if (status === '1') {
          return '等待中'
        } else if (status === '2') {
          return '升级中'
        } else if (status === '3') {
          return '升级完成'
        } else if (status === '4') {
          return '已取消'
        }
      }
    },
    computed: {
      statusText() {
        return function (scope) {
          if (scope.row.status === '1') {
            // 报告生成中（待审核）
            return 'to-check-status-text'
          } else if (scope.row.status === '2') {
            return 'published-status-text'
          }
        }
      }
    },
    data() {
      return {
        total: 0,
        formObj: this.$deepClone(SEARCH_OBJ),
        statusOptions: this.$deepClone(STATUS_OPTIONS),
        latestVersionObj: {
          pubTime: '',
          version: ''
        },
        tableData: []
      }
    },
    async activated() {
      await this.fetchTableData()
    },
    async created() {
      // await this.fetchTableData()
      // console.log('created')
    },
    methods: {
      async fetchTableData() {
        this.formObj.statusList = this.formObj.status !== '' ? this.formObj.status.split(',') : []
        console.log(this.formObj.statusList)
        let res = await getTableDataByPageApi(this.formObj)
        let infoRes = await getInfoApi()
        if (res.code === 200) {
          this.tableData = res.data.rows
          this.total = res.data.total
        }
        if (infoRes.code === 200) {
          this.latestVersionObj.pubTime = infoRes.data.pubTime
          this.latestVersionObj.version = infoRes.data.version
        }
      },
      // 机构查询
      async onOrgSearch(queryString, cb) {
        if (queryString && queryString.trim() !== '') {
          let res = await getOrgDataApi({ searchValue: queryString })
          if (res.code === 200) {
            cb(res.data)
          }
        } else {
          cb([])
        }
      },
      // 查询按钮
      async onSearchTable() {
        await this.fetchTableData()
      },
      onHandleRow(row) {
        Cookies.set('drugMappingType', 'knowledgeBaseVersionUpgrade')
        if (row.status === '2') {
          this.$router.push({
            path: '/dm/editDrugMatch',
            query: {
              recordId: row.id,
              type: 'edit',
              dkbVersion: row.dkbVersion
            }
          })
        } else if (row.status === '3') {
          this.$router.push({
            path: '/dm/viewDrugMatch',
            query: {
              recordId: row.id,
              type: 'view',
              dkbVersion: row.dkbVersion
            }
          })
        }
      },
      // 分页查询
      async getTableHandle() {
        await this.fetchTableData()
      },
    }
  }
</script>
<style lang="scss" scoped>
  .version-upgrade-container {
    padding: 10px 0;
    .layout-header {
      .header-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .title-header-container {
          /* display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center; */
          .title-header {
            display: inline-block;
            width: 4px;
            height: 18px;
            background: #0073E9;
            border-radius: 2px;
          }
          .title-text {
            display: inline-block;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 18px;
            margin-left: 5px;
            vertical-align: top;
          }
        }
      }
      .header-btm {
        text-align: right;
      }
    }
    .version-upgrade-main {
      overflow: auto;
      .el-table {
        overflow: auto;
      }
    }
  }
</style>