//用户行为记录

import request from '@/utils/request'

// 查询用户行为记录列表
export function actionRecordList(params) {
  return request({
    url: '/system/actionRecord/actionRecordList',
    method: 'post',
    params: params
  })
}


// 查询配置列表
export function selectConfigList(params) {
  return request({
    url: '/system/actionRecord/selectConfigList',
    method: 'post',
    params: params
  })
}


// 添加actionKey到过滤列表，添加后的actionKey不再记录日志
export function addFilterActionKey(actionKey, url, content) {
  let dataParams = new URLSearchParams()
  dataParams.append("actionKey", actionKey);
  dataParams.append("url", url);
  dataParams.append("content", content);
  return request({
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    url: '/system/actionRecord/addFilterActionKey',
    method: 'post',
    data: dataParams
  })
}

// 添加url映射关系
export function addUrlMapping(sourceUrl, mappingUrl,comment) {
  return request({
    url: '/system/actionRecord/addUrlMapping',
    method: 'post',
    params: {sourceUrl: sourceUrl, mappingUrl: mappingUrl,comment:comment}
  })
}

// 添加用户行为标签
export function addActionLabel(actionKey, labelName) {
  return request({
    url: '/system/actionRecord/addActionLabel',
    method: 'post',
    params: {actionKey: actionKey, labelName: labelName}
  })
}


// 根据关键字模糊查询前50条用户行为标签名称
export function selectActionLabelList(queryStr) {
  return request({
    url: '/system/actionRecord/selectActionLabelList',
    method: 'post',
    params: {queryStr:queryStr}
  })
}


// 删除配置项
export function delConfig(configId, configType) {
  return request({
    url: '/system/actionRecord/delConfig',
    method: 'post',
    params: {configId: configId, configType: configType}
  })
}
