import request from '@/utils/request'


// 查询DDD列表
export function getDDDTable(data) {
  return request({
    url: '/dkm/ddd/dddList',
    method: 'post',
    data: data
  })
}
// DDD:删除记录
export function delDDDList(data) {
  return request({
    url: '/dkm/ddd/delDdd',
    method: 'post',
    params: data
  })
}
// DDD:获取版本列表
export function getVersionList() {
  return request({
    url: '/dkm/ddd/getVersionList',
    method: 'post',
    params: {}
  })
}
// DDD:新增
export function newAddList(data) {
  return request({
    url: '/dkm/ddd/addDdd',
    method: 'post',
    data: data
  })
}
// DDD:查询记录详情
export function getDDDinfo(data) {
  return request({
    url: '/dkm/ddd/info',
    method: 'get',
    params: data
  })
}
// DDD:修改
export function editList(data) {
  return request({
    url: '/dkm/ddd/editDdd',
    method: 'post',
    data: data
  })
}
// DDD关联数据:查询关联数据列表
export function getRefDataList(data) {
  return request({
    url: '/dkm/ddd/getRefDataList',
    method: 'post',
    data: data
  })
}
// DDD关联数据:查询添加药物数据列表
export function getDrugList(data) {
  return request({
    url: '/dkm/drug/list',
    method: 'post',
    params: data
  })
}
// DDD关联数据:查询添加药物组数据列表
export function getDrugGroupList(data) {
  return request({
    url: '/dkm/drugGroup/list',
    method: 'post',
    params: data
  })
}
// DDD关联数据:添加关联数据
export function addRefData(data) {
  return request({
    url: '/dkm/ddd/addRefData',
    method: 'post',
    data: data
  })
}
// DDD关联数据:删除记录
export function delRefData(data) {
  return request({
    url: '/dkm/ddd/delRefData',
    method: 'post',
    params: data
  })
}
// DDD:获取给药途径列表
export function getAdminRouteList() {
  return request({
    url: '/dkm/ddd/getAdminRouteList',
    method: 'get'
  })
}
// DDD:获取给药途径列表
export function getUnitList() {
  return request({
    url: '/dkm/ddd/getUnitList',
    method: 'get'
  })
}