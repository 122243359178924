/***用户反馈信息 */
export const STATUS_OPTIONS = [
  {
    value: '',
    label: '全部'
  },
  {
    value: 1,
    label: '待处理'
  },
  {
    value: 2,
    label: '已处理'
  }
]
export const QUERY_OBJ = {
  searchValue: '',
  startTime: '',
  endTime: '',
  timeList: [],
  isAsc: '',
  orderByColumn: '',
  orgName: '',
  pageNum: 1,
  pageSize: 10,
  processStatus: '', // 处理状态 1:待处理 2:已处理,可用值:1,2

}