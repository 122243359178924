<template>
  <div class="app-container">
    <div ref="headerQuery">
      <el-row class="headerClass">
        <el-col :span="4" style="border-left: 5px solid #0073E9;padding-left: 10px;">api机构注册审批</el-col>
        <el-col :span="20">
          <el-form :model="queryParams" ref="queryForm" :inline="true" style="float: right">
            <el-form-item label="注册机构名称" prop="orgName">
              <el-input v-model="queryParams.orgName"
                        placeholder=""
                        style="width: 110px;height: 36px!important;line-height: 36px;"/>
            </el-form-item>
            <el-form-item label="申请时间">
              <el-date-picker
                v-model="setDateRange"
                type="daterange"
                value-format="yyyy-MM-dd"
                style="width:250px"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="timeChange">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="审批状态" prop="applyStatus">
              <el-select
                v-model="queryParams.applyStatus"
                placeholder="状态"
                clearable
                size="small"
                class="statusClass"
                style="width: 100px"
              >
                <el-option
                  v-for="dict in statusOptions"
                  :key="'statusOptions'+dict.value"
                  :label="dict.label"
                  :value="dict.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery()">查询</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <el-table
      ref="oraTableData"
      v-loading="loading"
      border
      stripe
      :height="tableHeight"
      :header-cell-style="{'text-align':'center'}"
      :cell-style="{'text-align':'center'}"
      :data="applyList">
      <el-table-column label="审批编号" prop="id" width="120" />
      <el-table-column label="提交机构名称" prop="applyDeptName" :show-overflow-tooltip="true"/>
      <el-table-column label="注册机构名称" prop="hospName" :show-overflow-tooltip="true"/>
      <el-table-column label="省市" prop="hospAddress" :show-overflow-tooltip="true"/>
<!--      <el-table-column label="审批状态" align="center" width="100">s-->
<!--        <template slot-scope="scope">-->
<!--          <span>{{ scope.row.status | selectApplyStatus}}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="审批状态" align="center" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.applyStatus === 0" class="dsp">待审核</span>
          <span v-else-if="scope.row.applyStatus === 1" class="ytg">通过</span>
          <span v-else-if="scope.row.applyStatus === 2" class="yjj">不通过</span>
        </template>
      </el-table-column>
      <el-table-column label="申请时间" align="center" prop="createTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审批时间" align="center" prop="processTime" width="180">
        <template slot-scope="scope">
          <span>{{ scope.row.processTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="120">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.applyStatus === 0"
            size="mini"
            type="text"
            @click="handleApply(scope.row)"
          >审批</el-button>
          <el-button
            v-if="scope.row.applyStatus === 1"
            size="mini"
            type="text"
            @click="lookDatas(scope.row)"
          >查看</el-button>
          <el-button
            v-if="scope.row.applyStatus === 2"
            size="mini"
            type="text"
            @click="lookDataf(scope.row)"
          >查看</el-button>
        </template>
      </el-table-column>
      <template slot="empty" v-if="isResult === false">
        <img src="@/assets/images/no-booking.svg" alt="">
        <p>暂无数据</p>
      </template>
      <template slot="empty" v-else>
        <img src="@/assets/images/no-left-data.svg" alt="">
        <p>未查询到结果，请修改查询条件重试！</p>
      </template>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 审批 -->
    <el-dialog title="审批" :visible.sync="open" width="500px" append-to-body class="approvalWrap">
      <div class="approvalContentDiv">
        <el-row class="approvalContent">
          <el-col :span="12" style="text-align: right;" class="approvalContentLeft">
            <span>机构名称：</span>
          </el-col>
          <el-col :span="12" style="text-align: left;" class="approvalContentRight">
            <span>{{ applyData.hospName }}</span>
          </el-col>
        </el-row>
        <el-row class="approvalContent">
          <el-col :span="12" style="text-align: right;" class="approvalContentLeft">
            <span>用户姓名：</span>
          </el-col>
          <el-col :span="12" style="text-align: left;" class="approvalContentRight">
            <span>{{ applyData.userName }}</span>
          </el-col>
        </el-row>
        <el-row class="approvalContent">
          <el-col :span="12" style="text-align: right;" class="approvalContentLeft">
            <span>手机号：</span>
          </el-col>
          <el-col :span="12" style="text-align: left;" class="approvalContentRight">
            <span>{{ applyData.userCell }}</span>
          </el-col>
        </el-row>
        <el-row class="approvalContent">
          <el-col :span="12" style="text-align: right;" class="approvalContentLeft">
            <span>邮箱：</span>
          </el-col>
          <el-col :span="12" style="text-align: left;" class="approvalContentRight">
            <span>{{ applyData.userEmail }}</span>
          </el-col>
        </el-row>
        <el-row class="approvalContent" >
          <el-col :span="12" style="text-align: right;" class="approvalContentLeft">
            <span>医院等级：</span>
          </el-col>
          <el-col :span="12" style="text-align: left;" class="approvalContentRight">
            <span>{{ applyData.hospLevelStr }}</span>
          </el-col>
        </el-row>
      </div>
      <el-row>
        <el-col :span="24" style="text-align: center;">
          <el-button type="success" @click="adoptNext" class="adoptClass">通过</el-button>
          <el-button type="danger" @click="refuseNext" class="refuseClass">拒绝</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 查看审批通过理由 -->
    <el-dialog title="审批结果" :visible.sync="isSuccess" width="500px" append-to-body class="approvalWrap">
      <el-row>
        <el-col :span="12" style="text-align: right;">
          <i class="el-icon-success" style="color:#01C200;font-size: 30px;padding-right: 5px;"></i>
        </el-col>
        <el-col :span="12" style="text-align: left;color: #333333;font-size: 16px;line-height: 30px;font-family: PingFang SC;">
          <span>审批已通过！</span>
        </el-col>
      </el-row>
      <div class="approvalContentDiv">
        <el-row class="approvalContent">
          <el-col :span="12" style="text-align: right;" class="approvalContentLeft">
            <span>机构名称：</span>
          </el-col>
          <el-col :span="12" style="text-align: left;" class="approvalContentRight">
            <span>{{ applyData.hospName }}</span>
          </el-col>
        </el-row>
        <el-row class="approvalContent">
          <el-col :span="12" style="text-align: right;" class="approvalContentLeft">
            <span>用户姓名：</span>
          </el-col>
          <el-col :span="12" style="text-align: left;" class="approvalContentRight">
            <span>{{ applyData.userName }}</span>
          </el-col>
        </el-row>
        <el-row class="approvalContent">
          <el-col :span="12" style="text-align: right;" class="approvalContentLeft">
            <span>省市</span>
          </el-col>
          <el-col :span="12" style="text-align: left;" class="approvalContentRight">
            <span>{{ applyData.hospAddress }}</span>
          </el-col>
        </el-row>
        <el-row class="approvalContent">
          <el-col :span="12" style="text-align: right;" class="approvalContentLeft">
            <span>手机号：</span>
          </el-col>
          <el-col :span="12" style="text-align: left;" class="approvalContentRight">
            <span>{{ applyData.userCell }}</span>
          </el-col>
        </el-row>
        <el-row class="approvalContent">
          <el-col :span="12" style="text-align: right;" class="approvalContentLeft">
            <span>邮箱：</span>
          </el-col>
          <el-col :span="12" style="text-align: left;" class="approvalContentRight">
            <span>{{ applyData.userEmail }}</span>
          </el-col>
        </el-row>
        <el-row class="approvalContent" >
          <el-col :span="12" style="text-align: right;" class="approvalContentLeft">
            <span>医院等级：</span>
          </el-col>
          <el-col :span="12" style="text-align: left;" class="approvalContentRight">
            <span>{{ applyData.hospLevelStr }}</span>
          </el-col>
        </el-row>
        <el-row class="approvalContent">
          <el-col :span="12" style="text-align: right;" class="approvalContentLeft">
            <span>审批通过备注说明：</span>
          </el-col>
          <el-col :span="12" style="text-align: left;" class="approvalContentRight">
            <el-form :model="formLabelAlign_look_success"  ref="form_look_success" :rules="rules_look_success">
              <el-form-item label="" prop="remark">
                <el-input type="textarea" v-model="formLabelAlign_look_success.remark" placeholder="" disabled="disabled"></el-input>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
      <el-row>
        <el-col :span="24" style="text-align: center;">
          <el-button type="primary" @click="submitLookSuccessForm" class="successClass">确定</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 查看审批拒绝理由 -->
    <el-dialog title="审批结果" :visible.sync="isFail" width="500px" append-to-body class="approvalWrap">
      <el-row>
        <el-col :span="12" style="text-align: right;">
          <i class="el-icon-error" style="color:#FF3B30;font-size: 30px;padding-right: 5px;"></i>
        </el-col>
        <el-col :span="12" style="text-align: left;color: #333333;font-size: 16px;line-height: 30px;font-family: PingFang SC;">
          <span>审批已拒绝！</span>
        </el-col>
      </el-row>
      <div class="approvalContentDiv">
        <el-row class="approvalContent">
          <el-col :span="12" style="text-align: right;" class="approvalContentLeft">
            <span>机构名称：</span>
          </el-col>
          <el-col :span="12" style="text-align: left;" class="approvalContentRight">
            <span>{{ applyData.hospName }}</span>
          </el-col>
        </el-row>
        <el-row class="approvalContent">
          <el-col :span="12" style="text-align: right;" class="approvalContentLeft">
            <span>用户姓名：</span>
          </el-col>
          <el-col :span="12" style="text-align: left;" class="approvalContentRight">
            <span>{{ applyData.userName }}</span>
          </el-col>
        </el-row>
        <el-row class="approvalContent">
          <el-col :span="12" style="text-align: right;" class="approvalContentLeft">
            <span>省市</span>
          </el-col>
          <el-col :span="12" style="text-align: left;" class="approvalContentRight">
            <span>{{ applyData.hospAddress }}</span>
          </el-col>
        </el-row>
        <el-row class="approvalContent">
          <el-col :span="12" style="text-align: right;" class="approvalContentLeft">
            <span>手机号：</span>
          </el-col>
          <el-col :span="12" style="text-align: left;" class="approvalContentRight">
            <span>{{ applyData.userCell }}</span>
          </el-col>
        </el-row>
        <el-row class="approvalContent">
          <el-col :span="12" style="text-align: right;" class="approvalContentLeft">
            <span>邮箱：</span>
          </el-col>
          <el-col :span="12" style="text-align: left;" class="approvalContentRight">
            <span>{{ applyData.userEmail }}</span>
          </el-col>
        </el-row>
        <el-row class="approvalContent" >
          <el-col :span="12" style="text-align: right;" class="approvalContentLeft">
            <span>医院等级：</span>
          </el-col>
          <el-col :span="12" style="text-align: left;" class="approvalContentRight">
            <span>{{ applyData.hospLevelStr }}</span>
          </el-col>
        </el-row>
        <el-row class="approvalContent">
          <el-col :span="12" style="text-align: right;" class="approvalContentLeft">
            <span>拒绝理由：</span>
          </el-col>
          <el-col :span="12" style="text-align: left;" class="approvalContentRight">
            <el-form :model="formLabelAlign_look_fail"  ref="form_look_fail" :rules="rules_look_fail">
              <el-form-item label="" prop="remark">
                <el-input type="textarea" v-model="formLabelAlign_look_fail.remark" placeholder="请填写拒绝理由！" disabled="disabled"></el-input>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
      <el-row>
        <el-col :span="24" style="text-align: center;">
          <el-button type="primary" @click="submitLookFailForm" class="successClass">确定</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 查看审批已通过待确定 -->
    <el-dialog title="审批结果" :visible.sync="isApprovalSuccess" width="500px" append-to-body class="approvalWrap">
      <el-form :model="formLabelAlign" ref="form" :rules="rules">
        <el-form-item label="审批通过备注说明：" prop="remark">
          <el-input type="textarea" v-model="formLabelAlign.remark" placeholder="请填写审批通过备注说明..."
                    show-word-limit
                    maxlength="200"></el-input>
        </el-form-item>
      </el-form>
      <el-row>
        <el-col :span="24" style="text-align: center;">
          <el-button type="primary" @click="submitSuccessApplyForm" class="successClass">提交</el-button>
          <el-button @click="successCancel" class="failClass">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 查看审批已拒绝待确定 -->
    <el-dialog title="审批结果" :visible.sync="isApprovalFail" width="500px" append-to-body class="approvalWrap">
      <el-form :model="formLabelAlign_fail"  ref="form_fail" :rules="rules_fail">
        <el-form-item label="拒绝理由：" prop="remark">
          <el-input type="textarea" v-model="formLabelAlign_fail.remark" placeholder=""
                    show-word-limit
                    maxlength="200"></el-input>
        </el-form-item>
      </el-form>
      <el-row>
        <el-col :span="24" style="text-align: center;">
          <el-button type="primary" @click="submitFailApplyForm" class="successClass">提交</el-button>
          <el-button @click="failCancel" class="failClass">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { exportRole, dataScope, changeRoleStatus } from "@/api/system/role";
import { listPack, getPack, addPack, delPack } from "@/api/system/servicePackageManagement";
import { pageList, applyAdopt } from "@/api/dm/apiOrgRegister";
import { DEEP_CLONE } from '@/utils/constants'
import { treeselect as menuTreeselect, roleMenuTreeselect } from "@/api/system/menu";
import { treeselect as deptTreeselect, roleDeptTreeselect } from "@/api/system/dept";

export default {
  name: "organizationRegistrationApproval",
  filters: {
    selectApplyStatus(val) {
      if (val === '0') {
        return '待审批'
      } else if (val === '1') {
        return '已通过'
      } else if (val === '2') {
        return '已拒绝'
      }
    }
  },
  data() {
    return {
      // 表格高度
      tableHeight: window.innerHeight - 100 - 100,
      setDateRange: [], // 创建时间范围
      serviceList: [],
      // 审批状态list
      statusOptions: [
        {
          value: "",
          label: "全部"
        },
        {
          value: "0",
          label: "待审批"
        },
        {
          value: "1",
          label: "已通过"
        },
        {
          value: "2",
          label: "已拒绝"
        }
      ],
      // 服务包查询参数
      serveiceParams: {
        pageNum: '',
        pageSize: '',
        name: '',
        status: undefined
      },
      // 查询参数
      queryParams: {
        beginTime: '',
        endTime: '',
        orgName: '',
        packId: '',
        pageNum: 1,
        pageSize: 10,
        applyStatus: ''
      },
      applyData: {
      },
      formLabelAlign: {
      }, // 通过
      formLabelAlign_fail: {
      }, // 拒绝
      formLabelAlign_look_success: {
        remark: null
      }, // 查看通过
      formLabelAlign_look_fail: {
        remark: null
      }, // 查看拒绝
      // 遮罩层
      loading: true,
      // 表单校验
      rules: {
        // remark: [
        //   { required: true, message: "审批通过备注说明不能为空", trigger: "blur" }
        // ]
      },
      rules_fail:{
        remark: [
          { required: true, message: "审批拒绝理由说明不能为空", trigger: "blur" }
        ]
      },
      rules_look_success: {
        // remark: [
        //   { required: true, message: "审批通过备注说明不能为空", trigger: "blur" }
        // ]
      },
      rules_look_fail:{
        remark: [
          { required: true, message: "审批拒绝理由说明不能为空", trigger: "blur" }
        ]
      },
      // 总条数
      total: 0,
      // 角色表格数据
      applyList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      isSuccess: false, // 审批已通过
      isFail: false, // 审批已拒绝
      isApprovalSuccess: false, // 审批通过理由
      isApprovalFail: false, // 审批拒绝理由
      isResult: false
    };
  },
  created() {
    this.getList() // list
    // this.getServiceList() // 服务包list
    // this.$nextTick(() => {
    //   this.setTableHeight()
    // })
  },
  activated() {
    //激活时重新设置表格高度
    this.setTableHeight()
  },
  mounted() {
    // 获取高度值 （内容高+padding+边框）
    var _this=this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      _this.setTableHeight()
    }

  },
  methods: {
    /*设置表格的高度*/
    setTableHeight(){
      // 获取高度值 （内容高+padding+边框）
      let formHeight = this.$refs.headerQuery.offsetHeight
      console.log(formHeight)
      this.tableHeight = window.innerHeight - formHeight-150
    },
    timeChange(val) {
      this.setDateRange = val
    },
    /** 获取服务包列表 */
    getServiceList() {
      listPack(this.serveiceParams).then(
        response => {
          // console.log(response)
          // console.log(response.data.rows)
          this.serviceList = response.data.rows
        }
      );
    },
    /** 查询机构注册审批列表 */
    getList() {
      this.loading = true;
      if (this.setDateRange) {
        this.queryParams.beginTime = this.setDateRange[0]
        this.queryParams.endTime = this.setDateRange[1]
      } else {
        this.queryParams.beginTime = ''
        this.queryParams.endTime = ''
      }
      pageList(this.queryParams).then(
        response => {
          console.log(response.rows)
          this.applyList = response.rows
          this.total = response.total
          this.loading = false
        }
      );
    },
    /** 审批按钮操作 */
    handleApply(row) {
      // console.log(row)
      this.title = "审批"
      this.open = true;
      this.applyData = row
    },
    /** 通过提交按钮 */
    submitSuccessApplyForm() {
      this.$refs["form"].validate(async valid => {
        if (valid) {
          let obj = {}
          let copyData = {}
          copyData = DEEP_CLONE(this.applyData)
          obj.remark = this.formLabelAlign.remark
          obj.applyStatus = 1
          obj.id = copyData.id
          let res = await applyAdopt(obj)
          // console.log(res)
          if (res.code === 200) {
            this.isApprovalSuccess = false
            this.getList()
          }
        }
      });
    },
    /** 拒绝提交按钮 */
    submitFailApplyForm() {
      this.$refs["form_fail"].validate(async valid => {
        if (valid) {
          let obj = {}
          let copyData = {}
          copyData = DEEP_CLONE(this.applyData)
          obj.remark = this.formLabelAlign_fail.remark
          obj.applyStatus = 2
          obj.id = copyData.id
          let res = await applyAdopt(obj)
          // console.log(res)
          if (res.code === 200) {
            this.isApprovalFail = false
            this.getList()
          }
        }
      });
    },
    /** 查看通过提交按钮 */
    submitLookSuccessForm() {
      this.isSuccess = false
      this.getList()
    },
    /** 查看拒绝提交按钮 */
    submitLookFailForm() {
      // this.isApprovalFail = false
      this.isFail = false
      this.getList()
    },
    // 通过next
    adoptNext() {
      this.formLabelAlign_look_success = {}
      this.open = false
      this.isApprovalSuccess = true
    },
    // 拒绝next
    refuseNext() {
      this.formLabelAlign_fail = {}
      this.open = false
      this.isApprovalFail = true
    },
    // 查看已成功
    lookDatas(row) {
      console.log(row)
      this.isSuccess = true
      this.applyData = row
      this.formLabelAlign_look_success.remark = row.remark
    },
    // 查看已拒绝
    lookDataf(row) {
      console.log(row)
      this.isFail = true
      this.applyData = row
      this.formLabelAlign_look_fail.remark = row.remark
    },
    // 查看审批已通过待确定取消按钮
    successCancel() {
      this.isApprovalSuccess = false
    },
    // 查看审批已拒绝待确定取消按钮
    failCancel() {
      this.isApprovalFail = false;
    },
    handleQuery() {
      this.isResult = true
      this.queryParams.pageNum = 1
      this.getList();
    }
  }
};
</script>

<style lang="scss" scoped>
.dsp{
  display: inline-block;
  background: #FFEBD6;
  border-radius: 3px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #E4860A;
  width: 58px;
  height: 26px;
  line-height: 26px;
}
.yjj{
  display: inline-block;
  background: #FFE0E5;
  border-radius: 3px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FF3333;
  width: 58px;
  height: 26px;
  line-height: 26px;
}
.ytg{
  display: inline-block;
  background: #D4FFCC;
  border-radius: 3px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #49B812;
  width: 58px;
  height: 26px;
  line-height: 26px;
}
   .adoptClass {
     width: 110px;
     height: 32px;
     background: #6CCD3B;
     border-radius: 3px;
   }

   .refuseClass{
     width: 110px;
     height: 32px;
     background: #FF424C;
     border-radius: 3px;
   }
   .successClass{
     width: 110px;
     height: 32px;
     background: #2B60F8;
     border-radius: 3px;
   }
   .failClass{
     width: 110px;
     height: 32px;
     //background: #F3F5F9;
     border-radius: 3px;
   }
   .approvalContentDiv{
     margin-bottom: 10px;
   }
   .approvalContent .el-col{
     //width: 145px;
     //height: 14px;
     font-size: 14px;
     font-family: PingFang SC;
     font-weight: 400;
     color: #666666;
     line-height:18px;
     padding:8px;
   }
   .approvalContentRight{
     color: #333333!important;
   }
</style>

<style lang="scss">
.approvalWrap .el-dialog__body {
  padding:20px 20px 30px!important;
}
</style>
