var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-header",
    { staticClass: "report-table-header" },
    [
      _c("div", { staticClass: "title-header-container" }, [
        _c("span", { staticClass: "title-header" }),
        _c("h4", { staticClass: "title-text" }, [_vm._v(" 匹配情况 ")]),
      ]),
      _c(
        "el-form",
        { attrs: { model: _vm.tableSearchObj, inline: true } },
        [
          !_vm.isSelectedMatch
            ? _c(
                "el-form-item",
                { attrs: { label: "未匹配原因：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        "collapse-tags": "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.tableSearchObj.errorCodeList,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableSearchObj, "errorCodeList", $$v)
                        },
                        expression: "tableSearchObj.errorCodeList",
                      },
                    },
                    _vm._l(_vm.errorOptions, function (item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.name, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "输入编号/药品通用名" },
                model: {
                  value: _vm.tableSearchObj.searchValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableSearchObj, "searchValue", $$v)
                  },
                  expression: "tableSearchObj.searchValue",
                },
              }),
            ],
            1
          ),
          _vm.isSelectedMatch
            ? _c(
                "el-form-item",
                { attrs: { label: "只看问题字段" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#2c5ff9",
                      "inactive-color": "#b5ccf5",
                    },
                    on: { change: _vm.errorFilterChange },
                    model: {
                      value: _vm.tableSearchObj.errorProblemnFlag,
                      callback: function ($$v) {
                        _vm.$set(_vm.tableSearchObj, "errorProblemnFlag", $$v)
                      },
                      expression: "tableSearchObj.errorProblemnFlag",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isSelectedMatch
            ? _c(
                "el-form-item",
                { attrs: { label: "筛选" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#2c5ff9",
                      "inactive-color": "#b5ccf5",
                    },
                    model: {
                      value: _vm.tableSearchObj.isFilter,
                      callback: function ($$v) {
                        _vm.$set(_vm.tableSearchObj, "isFilter", $$v)
                      },
                      expression: "tableSearchObj.isFilter",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isSelectedMatch
            ? _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.onFilterReset },
                    },
                    [_vm._v("筛选重置")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-search",
                  },
                  on: { click: _vm.onSearchReportTable },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _vm.isSelectedMatch
            ? _c(
                "el-form-item",
                [
                  !_vm.enlarge
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            size: "small",
                            icon: "el-icon-zoom-in",
                            type: "primary",
                          },
                          on: { click: _vm.onEnlarge },
                        },
                        [_vm._v("放大")]
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: {
                            size: "small",
                            icon: "el-icon-zoom-in",
                            type: "primary",
                          },
                          on: { click: _vm.onEnNormal },
                        },
                        [_vm._v("放大")]
                      ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }