<!--设置审核字段-->
<template>
  <el-dialog
		v-if="isShowDialog"
		title="设置审核字段"
		:visible="isShowDialog"
    :append-to-body="true"
    @close="closeDialog"
		width="500px"
    class="vertical-middle-dialog"
		center>
    <el-table
      :data="tableData"
      :header-cell-style="{textAlign: 'center', color: '#333'}"
      border
      height="100%"
      class="right-table-wrap">
      <el-table-column
        type="index"
        label="序号"
        width="60"
        align="center">
      </el-table-column>>
      <el-table-column
        prop="fieldName"
        label="字段名称">
      </el-table-column>>
      <!-- <el-table-column
        prop="dmStatus"
        label="加入治理"
        width="100"
        type="selection"
        label-class-name="DisabledSelection"
        align="left">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.dmStatusBoolean"/>
        </template>
      </el-table-column>> -->
      <el-table-column :show-overflow-tooltip="true" align="center">
        <template slot="header" slot-scope="scope">
          <el-checkbox
            :indeterminate="isAllGovernedIndeterminate"
            v-model="checkAllGovern"
            @change="handleCheckAllGovern">加入治理</el-checkbox>
        </template>
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.dmStatusBoolean" @change="handleCheckGovern"></el-checkbox>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="evalStatus"
        label="加入质控"
        width="100"
        align="left">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.evalStatusBoolean"/>
        </template>
      </el-table-column>> -->
      <el-table-column :show-overflow-tooltip="true" align="center">
        <template slot="header" slot-scope="scope">
          <el-checkbox
            :indeterminate="isAllQCIndeterminate"
            v-model="checkAllQC"
            @change="handleCheckAllQC">加入质控</el-checkbox>
        </template>
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.evalStatusBoolean" @change="handleCheckQC"></el-checkbox>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="onConfirm">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import {
    getFieldsRuleByOrgApi,
    updateFieldsRuleByOrgApi
  } from '@/api/dm/targetDataAuditSettings'
  export default {
    name: 'targetSettingsDialog',
    props: {
      orgId: {
        type: Number,
        required: true
      },
      isShowDialog: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        tableData: [],
        isAllGovernedIndeterminate: false,
        isAllQCIndeterminate: false,
        checkAllGovern: false, // 是否全部加入治理
        checkAllQC: false, // 是否全部加入质控
      }
    },
    watch: {
      async orgId(newVal, oldVal) {
        if (newVal && oldVal) {
          await this.fetchData()
        }
      }
    },
    async created() {
      await this.fetchData()
    },
    methods: {
      async fetchData() {
        const obj = {
          orgId: this.orgId
        }
        let res = await getFieldsRuleByOrgApi(obj)
        if (res.code === 200) {
          res.data && res.data.forEach(item => {
            item.evalStatusBoolean = item.evalStatus === 1 ? true : false
            item.dmStatusBoolean = item.dmStatus === 1 ? true : false
          })
          this.tableData = res.data || []
          let governedCount = 0
          let QCCount = 0
          this.tableData.forEach(item => {
            if (item.dmStatusBoolean) {
              governedCount++
            }
            if (item.evalStatusBoolean) {
              QCCount++
            }
          })
          console.log(QCCount)
          this.isAllGovernedIndeterminate = this.tableData.length > governedCount && governedCount > 0 ? true : false
          this.isAllQCIndeterminate = this.tableData.length === QCCount ? false : true
          this.checkAllGovern = this.tableData.length === governedCount ? true : false
          this.checkAllQC = this.tableData.length === QCCount ? true : false
        }
      },
      closeDialog() {
        this.$emit('closeDialog', false)
      },
      // 是否全部加入治理
      handleCheckAllGovern(val) {
        this.tableData.forEach(item => {
          item.dmStatusBoolean = val
          // item.dmStatus = item.dmStatusBoolean ? 1 : 0 // 治理
        })
      },
      // 单个是否加入治理
      handleCheckGovern(val) {
        let governedCount = 0
        let QCCount = 0
        this.tableData.forEach(item => {
          if (item.dmStatusBoolean) {
            governedCount++
          }
        })
        this.isAllGovernedIndeterminate = this.tableData.length > governedCount ? true : false
      },
      // 是否全部加入质控
      handleCheckAllQC(val) {
        this.tableData.forEach(item => {
          item.evalStatusBoolean = val
          // item.evalStatus = item.evalStatusBoolean ? 1 : 0 // 治理
        })
      },
      // 单个是否加入质控
      handleCheckQC(val) {
        let QCCount = 0
        this.tableData.forEach(item => {
          if (item.evalStatusBoolean) {
            QCCount++
          }
        })
        this.isAllQCIndeterminate = this.tableData.length > QCCount ? true : false
      },
      async onConfirm() {
        const obj = {
          confFieldList: this.tableData
        }
        this.tableData.forEach(item => {
          item.evalStatus = item.evalStatusBoolean ? 1 : 0
          item.dmStatus = item.dmStatusBoolean ? 1 : 0
        })
        let res = await updateFieldsRuleByOrgApi(this.tableData)
        if (res.code === 200) {
          await this.fetchData()
          this.$message.success("设置成功")
          this.$emit('closeDialog', false, 'success')
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .el-table ::v-deep.DisabledSelection .cell .el-checkbox__inner{
    margin-left: -70px;
    position: relative;
  }
  .el-table ::v-deep.DisabledSelection .cell:before{
    content: "加入治理";
    position: absolute;
    right: 15px;
  }
</style>