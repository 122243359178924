<!--新药入库维护-->
<template>
  <el-container class="layout-container platform-report">
    <el-header class="platform-report-header">
      <div class="title-header-container" id="1" @click="onSearchTable">
        <span class="title-header"></span>
        <h4 class="title-text">新药入库维护</h4>
      </div>
      <el-form :model="queryObj" :inline="true">
        <el-form-item>
          <el-autocomplete
            v-model.trim="queryObj.orgName"
            :fetch-suggestions="onOrgSearch"
            placeholder="输入机构名称"
            clearable
            size="medium"
            value-key="name"
            style="width: 200px;height: 36px!important;line-height: 36px;"
            @select="onSelectOrg"
            @clear="onClearOrg"/>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model.trim="queryObj.searchVal"
            placeholder="输入贯标码/药品通用名"
            clearable
            size="medium"
            value-key="name"
            style="width: 200px;height: 36px!important;line-height: 36px;"/>
        </el-form-item>
        <el-form-item label="申请时间">
          <el-date-picker
            v-model="queryObj.timeList"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="是否已处理">
          <el-radio-group v-model="queryObj.webStatus">
            <el-radio label="">全部</el-radio>
            <el-radio :label="3">未处理</el-radio>
            <el-radio :label="4">已处理</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" icon="el-icon-search" @click="onSearchTable">查询</el-button>
        </el-form-item>
      </el-form>
    </el-header>
    <el-main class="platform-report-main">
      <el-table
        ref="tableRef"
        :data="tableData"
        :header-cell-style="{textAlign:'center'}"
        style="width: 100%;height: 100%;"
        stripe
        border
        class="platform-report-table"
        @sort-change="onSortChange">
      <el-table-column
        align="center"
        width="100"
        fixed>
        <template slot="header" slot-scope="scope">
          <span>机构ID</span>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.orgId }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center">
        <template slot="header" slot-scope="scope">
          <span>机构名称</span>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.orgName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center">
        <template slot="header" slot-scope="scope">
          <span>贯标码</span>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.stateMedicareNum }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center">
        <template slot="header" slot-scope="scope">
          <span>药品通用名</span>
          <svg-icon :icon-class="genericNameOrderIcon" style="width: 24px;height: 16px;cursor: pointer;" @click="genericNameOrder"/>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.pdpName }}</span>
        </template>
      </el-table-column><el-table-column
      align="center">
      <template slot="header" slot-scope="scope">
        <span>规格包装</span>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.pdpSpecTxt }}</span>
      </template>
    </el-table-column>
      <el-table-column
        align="center">
        <template slot="header" slot-scope="scope">
          <span>包装材料</span>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.wrapperDesc }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center">
        <template slot="header" slot-scope="scope">
          <span>生产厂家</span>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.manufacturerName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="addTime"
        sortable="custom"
        column-key="addTime">
        <template slot="header" slot-scope="scope">
          <span>进入列表时间</span>
        </template>
        <template slot-scope="scope">
          <span :class="statusText(scope)">{{ scope.row.addTime }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        align="center"
        width="100"
        type="index"
        fixed="right">
        <template slot="header" slot-scope="scope">
          <span>已处理</span>
        </template>
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.webStatus"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="(val) => onHandleChange(val, scope.row)">
          </el-switch>
        </template>
      </el-table-column> -->
      <el-table-column
        align="center"
        width="100"
        type="index">
        <template slot="header" slot-scope="scope">
          <span>状态</span>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.storageStatusDesc }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="100"
        type="index"
        fixed="right">
        <template slot="header" slot-scope="scope">
          <span>操作</span>
        </template>
        <template slot-scope="scope">
          <el-button v-if="showHandle(scope.row.storageStatus)" type="text" @click="toHandle(scope.row)">处理</el-button>
          <el-button v-else type="text" @click="toView(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    </el-main>
    <el-footer>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryObj.pageNum"
        :limit.sync="queryObj.pageSize"
        @pagination="getTableHandle"/>
    </el-footer>
  </el-container>
</template>
<script>
import { QUERY_DATA_OBJ } from '@/utils/dm/newDrugWarehousing/data'
import { getOrgDataApi } from '@/api/dm/platformGovernanceReport'
import { getDrugListApi, updateDrugStatusApi } from '@/api/dm/newDrugWarehousing'
export default {
  name: 'NewDrugWarehousing',
  computed: {
    statusText() {
      return function (scope) {
        if (scope.row.status === '1') {
          // 报告生成中（待审核）
          return 'to-check-status-text'
        } else if (scope.row.status === '2') {
          return 'published-status-text'
        }
      }
    },
    showHandle: function() {
      return function (sts) {
        if (Number(sts) === 3) {
          return true
        } else if (Number(sts) === 1) {
          return false
        }
      }
    }
  },
  data() {
    return {
      genericNameOrderIcon: 'letter-paixu', // 通用名排序icon
      genericNameOrderFlag: false, // 为false表示显示的是升序图标反之则是降序图标
      total: 0,
      queryObj: this.$deepClone(QUERY_DATA_OBJ),
      tableData: [],
    }
  },
  filters: {
    storageStatusStr(webStatus) {
      const status = Number(webStatus)
      if (status === 1) {
        return '待入库'
      } else if (status === 2) {
        return '已入库'
      } else if (status === 3) {
        return '待确认'
      }
    }
  },
  async activated() {
    await this.fetchTableData()
  },
  async mounted() {
    this.$nextTick(() => {
      // console.log(document.getElementById('1'))
    })
  },
  methods: {
    async fetchTableData() {
      if (this.queryObj.timeList && this.queryObj.timeList.length > 0) {
        this.queryObj.beginTime = this.queryObj.timeList[0]
        this.queryObj.endTime = this.queryObj.timeList[1]
      } else {
        this.queryObj.beginTime = ''
        this.queryObj.endTime = ''
      }
      let res = await getDrugListApi(this.queryObj)
      if (res.code === 200) {
        /* res.rows.forEach(item => {
          item.webStatus = item.webStatus === 1 ? true : false
        }) */
        this.tableData = res.rows || []
        this.total = res.total
      }
      this.$nextTick(() => {
        this.$refs.tableRef && this.$refs.tableRef.doLayout()
      })
    },
    // 
    // 分页查询
    async getTableHandle() {
      await this.fetchTableData()
    },
    // 查询按钮
    async onSearchTable() {
      this.queryObj.orderByColumn = ''
      this.queryObj.isAsc = ''
      await this.fetchTableData()
    },
    // 机构查询
    async onOrgSearch(queryString, cb) {
      if (queryString && queryString.trim() !== '') {
        let res = await getOrgDataApi({ searchValue: queryString })
        if (res.code === 200) {
          cb(res.data)
        }
      } else {
        cb([])
      }
    },
    onSelectOrg(item) {
      this.queryObj.orgId = item.code
    },
    onClearOrg(val) {
      this.queryObj.orgId = ''
    },
    // 药品通用名排序
    async genericNameOrder() {
      this.queryObj.pageNum = 1
      this.queryObj.orderByColumn = 'pdpName'
      this.genericNameOrderFlag = !this.genericNameOrderFlag
      if (this.genericNameOrderFlag) {
        this.genericNameOrderIcon = 'letter-paixu'
        this.queryObj.isAsc = 'asc'
      } else {
        this.genericNameOrderIcon = 'letter-paixu-1'
        this.queryObj.isAsc = 'desc'
      }
      await this.fetchTableData()
    },
    // 进入列表时间排序
    async onSortChange({ column, prop, order }) {
      this.queryObj.pageNum = 1
      this.queryObj.orderByColumn = 'addTime'
      if (order === 'ascending') {
        this.queryObj.isAsc = 'asc'
      } else if (order === 'descending') {
        this.queryObj.isAsc = 'desc'
      } else {
        this.queryObj.isAsc = ''
        this.queryObj.orderByColumn = ''
      }
      await this.fetchTableData()
    },
    //
    async onHandleChange(val, row) {
      let webStatus = 1
      webStatus = val ? 1 : 3
      const obj = {
        pdpMatId: row.pdpMatId,
        webStatus,
        orgId: row.orgId
      }
      let res = await updateDrugStatusApi(obj)
      if (res.code === 200) {
        await this.fetchTableData()
      }
    },
    // 处理
    toHandle(row) {
      this.$router.push({
        path: '/dm/newDrugWarehousingHandle',
        query: {
          id: row.id,
          orgId: row.orgId,
          type: 'handle'
        }
      })
    },
    // 查看
    toView(row) {
      this.$router.push({
        path: '/dm/newDrugWarehousingView',
        query: {
          id: row.id,
          orgId: row.orgId,
          type: 'view'
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.platform-report {
  .platform-report-header {
    display: flex;
    flex-wrap: wrap;
    height: auto !important;
    min-height: 55px;
    justify-content: space-between;
    align-items: center;
    .title-header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      .title-header {
        width: 4px;
        height: 18px;
        background: #0073E9;
        border-radius: 2px;
      }
      .title-text {
        display: inline-block;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
        margin-left: 5px;
        vertical-align: top;
      }
    }
    .el-form {
      .el-form-item {
        margin-bottom: 0 !important;
        &:last-child {
          margin-right: 0 !important;
        }
        ::v-deep.el-form-item__label {
          font-size: 14px;
          /* color: #333;
          font-weight: 500; */
        }
        .el-radio {
          margin-right: 10px !important;
        }
        .el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
          width: 280px !important;
        }
      }
    }
  }
  .platform-report-main {
    padding: 0 20px !important;
    overflow: auto;
    .el-table {
      position: relative;
      overflow: auto;
      width: 100%;
      .to-check-status-text{
        display: inline-block;
        padding: 5px !important;
        color: #E4860A;
        background-color: #FFEBD6;
        font-size: 14px;
        text-align: center;
      }
      .published-status-text {
        display: inline-block;
        padding: 5px !important;
        color: #2B60F8;
        background-color: #D7E3FA;
        font-size: 14px;
        text-align: center;
      }
    }
  }
}
</style>
