<!--知识库导出-->
<template>
  <el-container class="layout-container version-upgrade-container">
    <el-header class="layout-header">
      <section class="header-top">
        <h3 class="title-header-container">
          <span class="title-header"></span>
          <h4 class="title-text">知识库导出</h4>
        </h3>
        <!-- <div>
          <span style="font-size: 16px;">当前知识库版本号：</span>
          <span style="color: #666;font-size: 16px;">{{ latestVersionObj.version }}</span>
          <span style="margin-left: 20px;font-size: 16px;">更新时间：</span>
          <span style="color: #666;font-size: 16px;">{{ latestVersionObj.pubTime }}</span>
        </div> -->
      </section>
      <section class="header-btm">
        <el-form :model="formObj" :inline="true" label-width="90px">
          <el-form-item label="机构名称：">
            <el-autocomplete
              v-model.trim="formObj.orgName"
              :fetch-suggestions="onOrgSearch"
              placeholder="输入机构名称"
              clearable
              size="medium"
              value-key="name"
              style="width: 200px;height: 36px!important;line-height: 36px;"/>
          </el-form-item>
          <!-- <el-form-item label="状态：">
            <el-select v-model="formObj.status" placeholder="请选择" clearable style="width: 100px;">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item>
            <el-button size="small" type="primary" icon="el-icon-search" @click="onSearchTable">查询</el-button>
          </el-form-item>
        </el-form>
      </section>
    </el-header>
    <el-main class="version-upgrade-main">
      <el-table
        :data="tableData"
        :header-cell-style="{textAlign:'center'}"
        style="width: 100%;height: 100%;"
        height="100%"
        stripe
        border
        class="platform-report-table">
        <el-table-column
          align="center"
          width="100"
          prop="orgId"
          fixed>
          <template slot="header" slot-scope="scope">
            <span>机构id</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center">
          <template slot="header" slot-scope="scope">
            <span>机构名称</span>
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.orgName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center">
          <template slot="header" slot-scope="scope">
            <span>最新版本号</span>
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.orgVersion }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center">
          <template slot="header" slot-scope="scope">
            <span>版本创建时间</span>
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.updateTime }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          align="center"
          prop="statusStr">
          <template slot="header" slot-scope="scope">
            <span>状态</span>
          </template>
          <template slot-scope="scope">
            <span :class="statusText(scope)">{{ scope.row.status | statusShow }}</span>
          </template>
        </el-table-column> -->
        <el-table-column
          align="center"
          width="100"
          type="index"
          label="操作"
          fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="showDialogHandle(scope.row)">
              <!-- {{ scope | operationText }} -->
              下载最新
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer style="height: 48px;">
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="formObj.pageNum"
        :limit.sync="formObj.pageSize"
        @pagination="getTableHandle"/>
    </el-footer>
    <password-vue :dialog-visible="dialogVisible" @close="closeDialogHandle" @success="onDownload"/>
  </el-container>
</template>
<script>
  // import {
  //   getOrgDataApi,
  //   getTableDataByPageApi,
  //   getInfoApi
  // } from '@/api/dm/knowledgeBaseVersionUpgrade'
  import {
    getOrgDataApi,
    getTableDataByPageApi,
    getInfoApi
  } from '@/api/dm/mappingMaintenance'
  import {
    SEARCH_OBJ,
    STATUS_OPTIONS
  } from '@/utils/dm/knowledgeBaseVersionUpgrade/data'
  import { downloadCpmdApi } from '@/api/dm/knowledgeBaseExport/index'
  import {
    onStartDownload
  } from '@/utils/downloadFile'
  import PasswordVue from './components/Password.vue'
  export default {
    name: 'KnowledgeBaseExport',
    components: {
      PasswordVue
    },
    filters: {
      operationText(scope) {
        // 1:等待中 2:升级中 3:升级完成 4:已取消
        const { status } = scope.row
        if (status === '1') {
          return ''
        } else if (status === '2') {
          return '修改匹配'
        } else if (status === '3') {
          return '查看'
        } else if (status === '4') {
          return ''
        }
      },
      statusShow(status) {
        if (status === '1') {
          return '等待中'
        } else if (status === '2') {
          return '升级中'
        } else if (status === '3') {
          return '升级完成'
        } else if (status === '4') {
          return '已取消'
        }
      }
    },
    computed: {
      statusText() {
        return function (scope) {
          if (scope.row.status === '1') {
            // 报告生成中（待审核）
            return 'to-check-status-text'
          } else if (scope.row.status === '2') {
            return 'published-status-text'
          }
        }
      }
    },
    data() {
      return {
        total: 0,
        formObj: this.$deepClone(SEARCH_OBJ),
        statusOptions: this.$deepClone(STATUS_OPTIONS),
        latestVersionObj: {
          pubTime: '',
          version: ''
        },
        tableData: [],
        dialogVisible: false,
        currentOrgId: ''
      }
    },
    async activated() {
      console.log('知识库导出activated')
      await this.fetchTableData()
    },
    async created() {
      // await this.fetchTableData()
      console.log('知识库导出created')
    },
    methods: {
      async fetchTableData() {
        let res = await getTableDataByPageApi(this.formObj)
        // let infoRes = await getInfoApi()
        if (res.code === 200) {
          this.tableData = res.data.rows
          this.total = res.data.total
        }
        /* if (infoRes.code === 200) {
          this.latestVersionObj.pubTime = infoRes.data.pubTime
          this.latestVersionObj.version = infoRes.data.version
        } */
      },
      // 机构查询
      async onOrgSearch(queryString, cb) {
        if (queryString && queryString.trim() !== '') {
          let res = await getOrgDataApi({ searchValue: queryString })
          if (res.code === 200) {
            cb(res.data)
          }
        } else {
          cb([])
        }
      },
      // 查询按钮
      async onSearchTable() {
        await this.fetchTableData()
      },
      // 分页查询
      async getTableHandle() {
        await this.fetchTableData()
      },
      showDialogHandle(row) {
        this.dialogVisible = true
        this.currentOrgId = row.orgId
      },
      closeDialogHandle() {
        this.dialogVisible = false
      },
      // 
      async onDownload(password) {
        let obj = {
          orgId: this.currentOrgId,
          password
        }
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let res = await downloadCpmdApi(obj)
        loading.close()
        onStartDownload(res)
        this.dialogVisible = false
        // console.log(res)
      }
    }
  }
</script>
<style lang="scss" scoped>
  .version-upgrade-container {
    padding: 10px 0;
    .layout-header {
      .header-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .title-header-container {
          /* display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center; */
          .title-header {
            display: inline-block;
            width: 4px;
            height: 18px;
            background: #0073E9;
            border-radius: 2px;
          }
          .title-text {
            display: inline-block;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 18px;
            margin-left: 5px;
            vertical-align: top;
          }
        }
      }
      .header-btm {
        text-align: right;
      }
    }
    .version-upgrade-main {
      overflow: auto;
      .el-table {
        overflow: auto;
      }
    }
  }
</style>