<!--机构药品库-->
<template>
  <el-container v-if="isEmptyData" class="layout-container empty-layout-container no-data-container">
    <el-main class="no-data-main">
      <div class="none-data-wrap">
        <h3 class="no-data-title">精细化药物管理，机构药品库 </h3>
        <span class="no-data-subtext">便捷、易用、准确、完整</span>
      </div>
    </el-main>
  </el-container>
  <el-container v-else class="drug-storage-container layout-container">
    <el-aside class="institution-table-wrap">
      <el-container class="layout-container institution-table-container">
        <el-header class="title-header">
          <h3 class="table-title inst-table-title">机构药品列表 </h3>
          <el-form
            ref="form"
            :model="searchObj"
            :inline="true"
            class="insti-form"
            label-width="80px"
            @submit.native.prevent>
            <el-form-item>
              <el-input
                v-model="searchObj.searchValue"
                class="search-input"
                clearable
                @keyup.enter.native.prevent="searchHandle"
                placeholder="输入关键字"/>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="searchHandle">查询</el-button>
            </el-form-item>
          </el-form>
        </el-header>
        <el-main style="padding: 10px;overflow-y: hidden;">
          <el-table
            :data="instTableData"
            border
            style="width: 100%"
            :header-cell-style="{color: '#333',fontWeight: '500', fontSize: '14px',textAlign:'center'}"
            :row-class-name="instiRowClassName"
            :cell-class-name="cellClassName"
            height="100%"
            class="insti-table"
            ref="instiTable"
            @row-click="instRowClickHandle">
            <el-table-column
              prop="mapShow"
              label="匹配状态"
              show-overflow-tooltip
              width="90"
              align="center"/>
            <el-table-column
              prop="drugId"
              label="药品ID"
              show-overflow-tooltip
              width="80"/>
            <el-table-column
              prop="name"
              label="药品名称"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              prop="spec"
              label="规格包装"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              prop="manufacturerName"
              label="生产厂家"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              prop="approvalNo"
              label="批准文号"
              show-overflow-tooltip>
            </el-table-column>
            <template slot="empty" v-if="isSearched === false">
              <img src="@/assets/images/no-booking.svg" alt="">
              <p>暂无数据</p>
            </template>
            <template slot="empty" v-else>
              <img src="@/assets/images/no-left-data.svg" alt="">
              <p>未查询到结果，请修改查询条件重试！</p>
            </template>
          </el-table>
        </el-main>
        <el-footer class="insti-footer" style="padding: 0;padding-bottom: 10px;height: auto;">
          <pagination
            v-show="total > 0"
            :total="total"
            :pager-count="7"
            :page.sync="currentPage"
            :limit.sync="pageSize"
            @pagination="getOrgListHandle"
            class="wrap-pagination"/>
            <!-- layout="total, prev, pager, next, jumper" -->
        </el-footer>
      </el-container>
    </el-aside>
    <el-main v-if="!isShowPlatformEmpty" class="platform-table-wrap">
      <el-container class="layout-container platform-table-container">
        <el-header class="title-header platform-title-header">
          <h3 class="table-title platform-table-title">平台标准药品数据</h3>
          <div class="platform-time-line">
            <div class="time-line-wrap">
              <d-k-b-line :timeline-list="timelineList" :version="currentVersion" @getCurrentItem="onTimeLine" />
            </div>
            <el-form ref="form2" :model="searchObj" :inline="true" class="platform-form" label-width="0px">
              <el-form-item>
                <el-select
                  v-model="searchObj.type"
                  class="el-select-type"
                  placeholder="类型"
                  @change="searchTypeHandle"
                  @clear="onTypeClear">
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-date-picker
                  v-if="searchObj.type === 1"
                  v-model="searchObj.year"
                  type="year"
                  :clearable="false"
                  value-format="yyyy"
                  placeholder="年"
                  @change="yearChangeHandle">
                </el-date-picker>
                <date-quarter
                  v-else-if="searchObj.type === 2"
                  ref="dataQuaterRef"
                  @quarterChange="quarterChangeHandle" />
                <el-date-picker
                  v-else
                  v-model="searchObj.yearMonth"
                  type="month"
                  value-format="yyyy-MM"
                  :clearable="false"
                  placeholder="月份"
                  @change="monthChangeHandle">
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
          <div class="platform-text">
            <div class="fl common-text">
              <span>机构库版本号：</span>
              <span>{{ currentVersion }}</span>
              <span style="margin-left: 20px;">知识库版本号：</span>
              <span>{{ currentDkbVersion }}</span>
            </div>
            <el-button
              v-if="currentTimeLineObj.publishStatus === 3"
              icon="el-icon-download"
              type="primary"
              class="download-btn"
              plain
              @click="downloadHandle">
              下载标准数据
            </el-button>
            <el-button
              v-else-if="currentTimeLineObj.publishStatus === 1 || currentTimeLineObj.publishStatus === 2"
              :disabled="settingBtnDisabled"
              icon="el-icon-setting"
              type="primary"
              class="download-btn"
              plain
              @click="onSettingHandle">
              目标数据审核
            </el-button>
          </div>
        </el-header>
        <el-main v-loading="platformTableLoading" style="padding: 10px;">
          <el-table
            ref="dynTable"
            :data="platformTableData"
            style="width: 100%"
            height="105"
            :row-style="rowStyle"
            :header-cell-style="{color: '#666',fontWeight: '400',textAlign:'center'}"
            class="platform-table">
            <template v-for="item in PACK_COLUMN_LIST1">
              <el-table-column
                v-if="item.show"
                :label="item.label"
                :fixed="item.fixed"
                :width="item.width"
                :align="item.align"
                show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="item.isSplited">
                    <span v-for="(itemProp, index) in item.prop" :key="index">{{ itemProp | multilePropColumn(scope.row, item.prop, index) }}</span>
                  </span>
                  <span v-else>{{ item.prop | platforColumn(scope.row) }}</span>
                </template>
              </el-table-column>
            </template>
          </el-table>
          <el-table
            ref="dynTable2"
            :data="platformTableData"
            style="width: 100%"
            height="105"
            :row-style="rowStyle"
            :header-cell-style="{color: '#666',fontWeight: '400',textAlign:'center'}"
            class="platform-table">
            <template v-for="item in PACK_COLUMN_LIST2">
              <el-table-column
                v-if="item.show"
                :key="item.id"
                :label="item.label"
                :fixed="item.fixed"
                :width="item.width"
                :align="item.align"
                show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="item.isSplited">
                    <span v-for="(itemProp, index) in item.prop" :key="index">{{ itemProp | multilePropColumn(scope.row, item.prop, index) }}</span>
                  </span>
                  <span v-else>{{ item.prop | platforColumn(scope.row) }}</span>
                </template>
              </el-table-column>
            </template>
          </el-table>
          <el-table
            ref="dynTable3"
            :data="platformTableData"
            style="width: 100%"
            height="105"
            :row-style="rowStyle"
            :header-cell-style="{color: '#666',fontWeight: '400',textAlign:'center'}"
            class="platform-table">
            <template v-for="item in PACK_COLUMN_LIST3">
              <el-table-column
                v-if="item.show"
                :label="item.label"
                :fixed="item.fixed"
                :width="item.width"
                :align="item.align"
                show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="item.isSplited">
                    <span v-for="(itemProp, index) in item.prop" :key="index">{{ itemProp | multilePropColumn(scope.row, item.prop, index) }}</span>
                  </span>
                  <span v-else>{{ item.prop | platforColumn(scope.row) }}</span>
                </template>
              </el-table-column>
            </template>
          </el-table>
          <el-table
            ref="dynTable4"
            :data="platformTableData"
            style="width: 100%"
            height="105"
            :row-style="rowStyle"
            :header-cell-style="{color: '#666',fontWeight: '400',textAlign:'center'}"
            class="platform-table">
            <el-table-column
              v-for="item in PACK_COLUMN_LIST4"
              :key="item.id"
              :label="item.label"
              :fixed="item.fixed"
              :width="item.width"
              :align="item.align"
              show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-if="item.isSplited">
                  <span v-for="(itemProp, index) in item.prop" :key="index">{{ itemProp | multilePropColumn(scope.row, item.prop, index) }}</span>
                </span>
                <span v-else>{{ item.prop | platforColumn(scope.row) }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-main>
        <el-footer style="height: auto;padding: 10px;">
          <i class="el-icon-warning" style="color: #94AFF7;vertical-align: middle;"></i>
          <span style="margin-left: 3px;font-size: 12px;color: #999;">系统所展示药品信息，仅供参考，不作为使用依据</span>
        </el-footer>
      </el-container>
    </el-main>
    <el-main v-else class="platform-table-wrap">
      <el-container class="layout-container platform-table-container">
        <el-header class="title-header platform-title-header">
          <h3 class="table-title platform-table-title">平台标准药品数据</h3>
          <div class="platform-time-line">
            <div class="time-line-wrap">
              <d-k-b-line :timeline-list="timelineList" :version="currentVersion" @getCurrentItem="onTimeLine" />
            </div>
            <el-form ref="form2" :model="searchObj" :inline="true" class="platform-form" label-width="0px">
              <el-form-item>
                <el-select
                  v-model="searchObj.type"
                  class="el-select-type"
                  placeholder="类型"
                  @change="searchTypeHandle">
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-date-picker
                  v-if="searchObj.type === 1"
                  v-model="searchObj.year"
                  type="year"
                  value-format="yyyy"
                  :clearable="false"
                  placeholder="年"
                  @change="yearChangeHandle">
                </el-date-picker>
                <date-quarter
                  v-else-if="searchObj.type === 2"
                  ref="dataQuaterRef"
                  @quarterChange="quarterChangeHandle" />
                <el-date-picker
                  v-else
                  v-model="searchObj.yearMonth"
                  type="month"
                  value-format="yyyy-MM"
                  :clearable="false"
                  placeholder="月份"
                  @change="monthChangeHandle">
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
          <div class="platform-text">
            <div class="fl common-text">
              <span>机构库版本号：</span>
              <span>{{ currentVersion }}</span>
              <span style="margin-left: 20px;">知识库版本号：</span>
              <span>{{ currentDkbVersion }}</span>
            </div>
            <el-button
              v-if="currentTimeLineObj.publishStatus === 3"
              icon="el-icon-download"
              type="primary"
              class="download-btn"
              plain
              @click="downloadHandle">
              下载标准数据
            </el-button>
            <el-button
              v-else-if="currentTimeLineObj.publishStatus === 2"
              icon="el-icon-setting"
              type="primary"
              class="download-btn"
              plain
              @click="onSettingHandle">
              目标数据审核
            </el-button>
          </div>
        </el-header>
        <el-main style="padding: 10px;">
          <el-table
            :data="platformTableData"
            style="width: 100%;height: 100%;"
            :row-style="rowStyle"
            :header-cell-style="{color: '#666',fontWeight: '400'}"
            class="platform-table platform-table-none">
            <template slot="empty" style="height: 100%;">
              <img src="@/assets/images/no-booking.svg" alt="">
              <p>暂无数据</p>
            </template>
          </el-table>
        </el-main>
        <el-footer style="height: auto;padding: 10px;">
          <i class="el-icon-warning" style="color: #94AFF7;vertical-align: middle;"></i>
          <span style="margin-left: 3px;font-size: 12px;color: #999;">系统所展示药品信息，仅供参考，不作为使用依据</span>
        </el-footer>
      </el-container>
    </el-main>
    <download-data ref="dialog-component" :version="currentVersion.toString()"/>
  </el-container>
</template>

<script>
import downloadData from './components/Download'
import {
  timelineApi,
  orgListApi,
  platformDrugApi
} from '@/api/dm/institutionDrugStorage'
import {
  getStatusApi
} from '@/api/institution/home'
import {
  QUERY_OBJ,
  QUATER_OPTIONS,
  MONTH_OPTIONS,
  TYPE_OPTIONS,
  PLATFORM_DTAT_OBJ,
  NO_MATCHED_LIST,
  PACK_COLUMN_LIST1,
  PACK_COLUMN_LIST2,
  PACK_COLUMN_LIST3,
  PACK_COLUMN_LIST4,
  ID_COLUMN,
  NAME_COLUMN,
  columnHandle
} from '@/utils/dm/institutionDrugStorage/data'
import { selectDrugGroupNameList } from '@/api/dkm/drugGroup'
import { statusHandle } from '@/utils/institution/home/index'
export default {
  name: 'InstitutionDrugStorage',
  components: {
    downloadData
  },
  filters: {
    multilePropColumn(prop, row, propArr, index) {
      // 平台标准药品数据列表。当表格prop多个的时候
      let val = row[prop] ? row[prop] : ''
      if (index === propArr.length - 1) {
        return val
      } else {
        return val + '-'
      }
    },
    platforColumn(prop, row) {
      // 平台标准药品数据列表。当表格prop就只有一个的时候
      if(Array.isArray(prop)) {
        return row[prop[0]]
      } else {
        return row[prop]
      }
    }
  },
  watch: {
    'searchObj.year'(val) {
      if (val) {
        this.searchObj.year = val + '' // 解决报错
      }
    },
    'searchObj.month'(val) {
      if (val) {
        this.searchObj.month = val + '' // 解决报错
      }
    },
    'searchObj.quarterly'(val) {
      if (val) {
        this.searchObj.quarterly = val + '' // 解决报错
      } else {

      }
    },
    'searchObj.yearMonth'(val) {
      if (val) {
        this.searchObj.yearMonth = val + '' // 解决报错
      } else {
        this.searchObj.yearMonth = ''
      }
    },
    PACK_COLUMN_LIST1: {
      handler (val) {
        // console.log(val)
      },
      deep: true
    },
    platformTableData: {
      handler(val) {
        // console.log(val)
      },
      deep: true
    }
  },
  data() {
    return {
      isEmptyData: false, // 是否是空数据页面
      isSearchEmptyData: false, // 查询条件查到的数据为空，但是还是有数据
      dialogVisible: false,
      isSearched: false,
      isShowPlatformEmpty: false, // 是否显示平台数据的空标志
      timelineList: [], // 版本时间轴
      currentVersion: '', // 当前版本
      pushTime: '', // 当前版本创建时间
      currentTimeLineObj: {}, // 当前选中的时间轴对象
      currentDkbVersion: '', // 当前版本知识库版本号
      searchObj: this.$deepClone(QUERY_OBJ),
      total: 0,
      pageSize: 10,
      currentPage: 1,
      instiRowIndex: -1, // 选中机构药品列表的行号
      versionData: [],
      quaterOptions: this.$deepClone(QUATER_OPTIONS),
      monthOptions: this.$deepClone(MONTH_OPTIONS),
      typeOptions: this.$deepClone(TYPE_OPTIONS),
      instTableData: [], // 机构表格数据
      platformTableData: [], // 平台表格数据
      PACK_COLUMN_LIST1: this.$deepClone(PACK_COLUMN_LIST1),
      PACK_COLUMN_LIST2: this.$deepClone(PACK_COLUMN_LIST2),
      PACK_COLUMN_LIST3: this.$deepClone(PACK_COLUMN_LIST3),
      PACK_COLUMN_LIST4: this.$deepClone(PACK_COLUMN_LIST4),
      platformTableLoading: false
    }
  },
  computed: {
    settingBtnDisabled() {
      if (this.currentTimeLineObj.publishStatus === 1) {
        return true
      } else {
        return false
      }
    }
  },
  async activated() {
    // console.log(this.$store.getters.instiDept.homeData.matchStatusCountMap)
    await this.updateStatusData()
    let matchedCount = this.$store.getters.instiDept.homeData.matchStatusCountMap[4]
    if (matchedCount === 0) {
      this.isEmptyData = true
    } else {
      this.isEmptyData = false
    }
    this.searchObj = this.$deepClone(QUERY_OBJ)
    this.searchObj.year = this.$moment(new Date()).format('YYYY')
    await this.getVersionHandle()
    this.platformTableData = []
    this.platformTableData.push(PLATFORM_DTAT_OBJ)
  },
  methods: {
    async updateStatusData() {
      // console.log('数据更新')
      let statusRes = await getStatusApi()
      if (statusRes.code === 200) {
        statusHandle(statusRes.data, this.$store.commit)
      }
    },
    rowStyle({row, rowIndwx}) {
      let styleJson = {}     
      styleJson = {
        height: '40px'
      }
      return styleJson  // 返回对象
    },
    cellClassName({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 0) {
        if (Number(row.status) === 0) {
          // 未匹配
          return 'no-map'
        } else if (Number(row.status) === 2) {
          // 非药品
          return 'non-drug'
        } else {
          return 'maped'
        }
      }
    },
    // 机构药品列表表格
    instiRowClassName({ row, rowIndex }) {
      row.index = rowIndex
      if (this.instiRowIndex === rowIndex) {
        return 'active-insti-row'
      } else {
        return ''
      }
    },
    // 版本时间轴点击事件
    async onTimeLine(index, item) {
      this.instTableData = []
      this.platformTableData = []
      this.platformTableData.push(PLATFORM_DTAT_OBJ)
      this.total = 0
      this.currentVersion = item.version
      this.pushTime = item.pushTime
      this.currentDkbVersion = item.dkbVersion
      this.currentPage = 1
      this.currentTimeLineObj = item
      console.log(item)
      await this.getOrgListHandle(item)
    },
    // 获取版本轴数据
    async getVersionHandle() {
      this.instTableData = []
      this.platformTableData = []
      this.platformTableData.push(PLATFORM_DTAT_OBJ)
      this.total = 0
      let obj = {
        month: this.searchObj.month,
        year: this.searchObj.year,
        quarterly: this.searchObj.quarterly
      }
      let res = await timelineApi(obj)
      if (res && res.code === 200) {
        let len = res.data ? res.data.length : 0
        this.timelineList = res.data ? res.data : []
        this.currentVersion = res.data.length > 0 ? res.data[len - 1].version : ''
        this.pushTime = res.data.length > 0 ? res.data[len - 1].pushTime : ''
        this.currentDkbVersion = res.data.length > 0 ? res.data[len - 1].dkbVersion: ''
        this.currentTimeLineObj = res.data.length > 0 ? res.data[len - 1]: {}
        if (this.timelineList.length > 0) {
          await this.getOrgListHandle()
          this.isEmptyData = false
          this.isSearchEmptyData = false
        } else {
          // if (obj.month && obj.month !== '' && obj.year && obj.quarterly && obj.quarterly !== '') {
          //   this.isEmptyData = true
          // }
          this.isSearchEmptyData = true
        }
      }
    },
    // 获取机构药品列表
    async getOrgListHandle(item) {
      this.instiRowIndex = -1
      const obj = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        searchValue: this.searchObj.searchValue,
        version: this.currentVersion
      }
      let res = await orgListApi(obj)
      if (res && res.code === 200) {
        this.instTableData = res.data.rows || []
        this.total = res.data.total
        // this.total = this.instTableData.length
        this.$nextTick(() => {
          this.$refs.instiTable && this.$refs.instiTable.doLayout()
        })
      }
    },
    /* 药物组名称检索*/
    querySearchAsync(queryString, cb) {
      if (queryString && queryString.trim() !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          let queryData = {}
          queryData.queryStr = queryString.trim()
          selectDrugGroupNameList(queryData).then(response => {
            cb(response.data)
          })
        }, 200)
      } else {
        cb([])
      }
    },
    // 点击机构药品表格某一行获取平台标准药品数据
    async instRowClickHandle(row) {
      this.platformTableLoading = true
      this.instiRowIndex = row.index
      this.platformTableData = []
      this.isShowPlatformEmpty = false
      if (NO_MATCHED_LIST.some(item => item.name === row.mapShow)) {
        console.log('不')
        this.isShowPlatformEmpty = true
        return
      }
      const obj = {
        drugId: row.drugId,
        version: this.currentVersion
      }
      let res = await platformDrugApi(obj)
      if (res && res.code === 200) {
        if (res.data) {
          for (let item in res.data) {
            if (!res.data[item] && res.data[item] !== 0 && res.data[item] !== '0') {
              console.log(item)
              // res.data[item] = '______'
              res.data[item] = ''
            }
          }
          this.platformTableData = []
          console.log(this.platformTableData)
          this.platformTableData.push(res.data)
          columnHandle(row, this.platformTableData[0])
          // this.PACK_COLUMN_LIST1[0].label = ID_COLUMN[0].label
          // this.PACK_COLUMN_LIST1[0].prop = ID_COLUMN[0].prop
          // this.PACK_COLUMN_LIST1[0].isSplited = ID_COLUMN[0].isSplited
          this.PACK_COLUMN_LIST1[0].label = NAME_COLUMN[0].label
          this.PACK_COLUMN_LIST1[0].prop = NAME_COLUMN[0].prop
          this.PACK_COLUMN_LIST1[0].isSplited = NAME_COLUMN[0].isSplited
          this.$nextTick(() => {
            this.$refs.dynTable.doLayout()
          })
          this.platformTableLoading = false
        }
        // console.log(this.platformTableData)
      }
    },
    async handleSizeChange(val) {
      this.pageSize = val
      await this.getOrgListHandle()
    },
    async handleCurrentChange(val) {
      this.currentPage = val
      await this.getOrgListHandle()
    },
    async searchHandle() {
      this.isSearched = true
      await this.getOrgListHandle()
    },
    // 下载标准数据
    downloadHandle() {
      this.dialogVisible = true
      if (this.$refs['dialog-component']) {
        this.$refs['dialog-component'].dialogVisible = true
      }
    },
    onSettingHandle() {
      this.$router.push({
        path: '/institutionDrugStorage/targetDataReview',
        query: {
          version: this.currentTimeLineObj.version
        }
      })
    },
    // 年度、月份、季度清空事件
    onTypeClear() {
      this.searchObj.year = null
      this.searchObj.month = null
      this.searchObj.quarterly = null
      this.searchObj.yearMonth = null
    },
    async searchTypeHandle(val) {
      if (val === 1) {
        // 年份
        this.searchObj.month = null
        this.searchObj.quarterly = null
        this.searchObj.yearMonth = null
        this.searchObj.year = this.$moment(new Date()).format('YYYY')
      } else if (val === 2) {
        // 季度
        this.searchObj.year = null
        this.searchObj.month = null
        this.searchObj.yearMonth = null
        this.searchObj.year = this.$moment(new Date()).format('YYYY')
        this.searchObj.quarterly = this.$moment().quarter() + ''
        this.$nextTick(() => {
          if (this.$refs.dataQuaterRef) {
            const { year, quarterly } = this.searchObj
            this.$refs.dataQuaterRef.year = year
            this.$refs.dataQuaterRef.season = quarterly
            this.$refs.dataQuaterRef.showValue = `${year}年第${quarterly}季`
          }
        })
      } else if (val === 3) {
        // 月份
        this.searchObj.year = null
        this.searchObj.quarterly = null
        this.searchObj.year = this.$moment(new Date()).format('YYYY')
        this.searchObj.month = this.$moment().format('MM') + ''
        this.searchObj.yearMonth = this.searchObj.year + '-' + this.searchObj.month
      }
      await this.getVersionHandle()
    },
    // 年份改变
    async yearChangeHandle(val) {
      this.searchObj.quarterly = ''
      this.searchObj.month = ''
      await this.getVersionHandle()
    },
    // 月份改变
    async monthChangeHandle(val) {
      if (!val) {
        return
      }
      this.searchObj.quarterly = ''
      this.searchObj.year = val.split('-')[0]
      this.searchObj.month = val.split('-')[1]
      await this.getVersionHandle()
    },
    // 季度改变
    async quarterChangeHandle(obj) {
      this.searchObj.year = obj.year
      this.searchObj.quarterly = obj.season
      this.searchObj.month = ''
      this.searchObj.yearMonth = null
      await this.getVersionHandle()
    }
  }
}
</script>

<style lang="scss" scoped>
  .no-data-main {
    padding: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    width: calc(100% + 40px);
    height: calc(100% + 24px);
    left: -20px;
    top: -12px;
    .none-data-wrap {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: #fff;
      background-image: url('../../../assets/images/storage-null-bg.png');
      background-repeat: no-repeat;
      /* background-position: center center; */
      background-size: cover;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .el-button {
        position: absolute;
        top: 35%;
        right: 5.3%;
        width: 240px;
        border-radius: 40px;
        padding: 20px;
        font-size: 24px;
        background-image: linear-gradient(179deg, #4B56F2 28%, #8141EA 100%);
        letter-spacing: 2px;
      }
      span {
        position: absolute;
        right: 5.3%;
        top: 23%;
        letter-spacing: 2px;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #888888;
      }
      .no-data-title {
        position: absolute;
        top: 10%;
        right: 5.3%;
        font-size: 42px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }
  }
  /*.no-data-main {
    background-color: #fff;
    background-image: url('../../../assets/images/storage-null-bg.png');
    background-repeat: no-repeat;
    /* background-size:100% auto; */
    /* background-size: cover; */
    /* background-position: center center; */
    /*position: relative;
    .el-button {
      position: absolute;
      right: 20px;
      top: 40px;
      width: 240px;
      border-radius: 40px;
      padding: 20px;
      font-size: 24px;
      background-image: linear-gradient(179deg, #4B56F2 28%, #8141EA 100%);
      letter-spacing: 2px;
    }
  }
}*/
.drug-storage-container {
  height: 100%;
  background-color: #EBEFF7;
  .institution-table-wrap {
    width: 50% !important;
    padding: 0 10px 0 0 !important;
    margin: 0 !important;
    box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
    border-radius: 4px;
    ::v-deep td.non-drug {
      .cell {
        background: #D8E2FF;
        border-radius: 3px;
        color: orange;
        padding: 5px 6px !important;
      }
    }
    ::v-deep td.maped {
      .cell {
        background: #D8E2FF;
        border-radius: 3px;
        /* color: #2B60F8; */
        color: #2ABB69;
        padding: 5px 6px !important;
      }
    }
    ::v-deep td.no-map {
      .cell {
        background: #DDDDDD;
        border-radius: 3px;
        color: red;
        padding: 5px 6px !important;
      }
    }
    .institution-table-container {
      background-color: #fff;
    }
    .insti-table {
      /* height: 100%; */
      ::v-deep.el-table__fixed {
        /* height:auto !important;  */
        height: calc(100%) !important;
        bottom: 16px;
      }
      ::v-deep.active-insti-row {
        .el-table--border th, .el-table--border td {
          /* border-right: 1px solid $topic-color; */
        }
        td {
          background-color: $topic-color !important;
          /* opacity: 0.8; */
          border-right: 1px solid $topic-color;
          color: #fff !important;
        }
        &:hover {
          td {
            background-color: inherit;
            opacity: 0.8;
            border-right: 1px solid #E8EEFC;
            /* border-right: 1px solid $topic-color; */
            color: #333 !important;
          }
        }
      }
    }
  }
  .platform-table-wrap {
    padding: 0 0px 0px 0px !important;
    box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
    border-radius: 4px;
    .platform-table-container {
      background-color: #fff;
      .platform-time-line {
        clear: both;
        overflow: hidden;
        margin-top: 10px;
        padding: 0 10px;
        position: relative;
        .time-line-wrap {
          width: 65%;
          margin-right: 2%;
          float: left;
        }
        .platform-form {
          width: 34%;
          /* float: left; */
          position: absolute;
          height: 30px;
          top: 50%;
          right: -22px;
          /* text-align: right; */
          /* transform: translateY(-50%); */ 
          margin-top: -16px;
          .el-form-item {
            /* width: calc(50% - 20px); */
            width: 80px;
            .el-date-editor.el-input, .el-date-editor.el-input__inner {
              width: 100%;
              min-width: 130px; 
            }
          }
        }
      }
      .platform-text {
        margin-bottom: 10px;
        padding: 0 10px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .platform-table {
        border: 1px solid #CFDBFB;
        box-shadow: 0px 0px 10px 0px rgba(170, 192, 255, 0.8);
        border-radius: 4px;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
        tbody {
          .el-table__row {
            height: 40px;
          }
        }
      }
      .platform-table-none {
        ::v-deep.el-table__body-wrapper {
          height: 100%;
        }
        ::v-deep.el-table__empty-block {
          min-height: 30px !important;
        }
      }
    }
  }
  .title-header {
    padding: 0 !important;
    height: 78px !important;
    &.platform-title-header {
      height: 140px !important;
    }
    .el-form {
      &.insti-form {
        .el-form-item {
          margin-bottom: 0 !important;
        }
        margin-top: 8px;
        text-align: right;
      }
    }
    .table-title {
      margin: 0 !important;
      padding: 0 !important;
      height: 36px;
      line-height: 36px;
      font-size: 16px;
      font-weight: normal;
      text-align: center;
      width: 100%;
      &.inst-table-title {
        background-color: #FFEBD6;
        color: #E4860A;
      }
      &.platform-table-title {
        background-color: #D4FFCC;
        color: #49B812;
      }
    }
  }
  .common-text {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
  }
}
</style>
