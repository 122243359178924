<template>
  <div class="app-container" style="overflow: auto"
       v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="请稍后">


    <div ref="headerQuery">
      <el-row class="headerClass">
        <el-col :span="4" style="border-left: 5px solid #0073E9;padding-left: 10px;">上海医保药品列表</el-col>
        <el-col :span="20">
          <el-form :model="queryParams" ref="queryForm" :inline="true" style="float: right">


            <el-form-item label="代码/通用名/商品名:" prop="searchValue">
              <el-input v-model="queryParams.searchValue" placeholder="代码/通用名/商品名" maxlength="50" clearable></el-input>
            </el-form-item>

            <el-form-item label="本位码/批准文号:" prop="searchCode">
              <el-input v-model="queryParams.searchCode" placeholder="本位码/批准文号" maxlength="50" clearable></el-input>
            </el-form-item>

            <el-form-item label="厂商:" prop="manufacturer">
              <el-input v-model="queryParams.manufacturer" placeholder="厂商" maxlength="50" clearable></el-input>
            </el-form-item>

            <el-form-item label="关联知识库药品" prop="relevanceStatus" v-if="!this.queryParams.versionNo">
              <el-select v-model="queryParams.relevanceStatus"
                placeholder="关联状态"
                clearable
                size="small"
                class="statusClass"
                style="width: 100px"
              >
                <el-option
                  v-for="dict in relevanceStatusOptions"
                  :key="'relevanceStatus'+dict.code"
                  :label="dict.name"
                  :value="dict.code"
                />
              </el-select>
            </el-form-item>


            <el-form-item label="版本号:" prop="versionNo">
              <el-select
                v-model="queryParams.versionNo"
                placeholder="版本号"
                size="small"
                class="statusClass"
                style="width: 200px"
                @change="versionNoChange"
                clearable
              >
                <el-option
                  v-for="item in versionNoOptions"
                  :key="item.versionNo+'versionNo'"
                  :label="item.versionNo"
                  :value="item.versionNo"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="类型:" prop="modifyType" v-if="this.queryParams.versionNo">
              <el-select
                v-model="queryParams.modifyType"
                placeholder="版本号"
                size="small"
                class="statusClass"
                style="width: 200px"
                clearable
              >
                <el-option
                  v-for="item in modifyTypeOptions"
                  :key="item.code+'modifyType'"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">检索</el-button>
              <el-button type="primary"  icon="el-icon-document" size="small" @click="handleExport">导出</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>


    <div ref="tableContainer">
      <el-table
        border
        stripe
        ref="refundTable"
        :data="drugList"
        :height="tableHeight"
        v-loading="loading"
        @sort-change="tableSortChange"
        @row-contextmenu="rowContextmenu"
        :span-method="objectSpanMethod"
        :cell-class-name="compareTableCellClass"
      >
        <el-table-column
          v-for="data in tableOptions"
          :key="'tableOptions'+data.id"
          :prop="data.prop"
          :label="data.name"
          :fixed="data.fixed"
          :resizable="false"
          :sortable="data.sortable"
          :min-width="data.width"
          v-if="data.show"
          :align="data.align"
        >
          <template slot-scope="scope">


            <div v-if="data.prop == 'modifyType'">
              <el-tag type="info" v-show="scope.row.modifyType==0">无变化</el-tag>
              <el-tag  v-show="scope.row.modifyType==1">修改</el-tag>
              <el-tag type="success" v-show="scope.row.modifyType==2">新增</el-tag>
              <el-tag type="danger" v-show="scope.row.modifyType==3">删除</el-tag>
            </div>

            <div v-else>
              <span>{{ scope.row[data.prop] }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column  label="操作" fixed="right" width="80"  v-if="!this.queryParams.versionNo"
                          align="center">
          <template slot-scope="scope">
            <el-button
              size="mini" type="text"
              @click="editSmpaDrugComment(scope.row)"
            >编辑备注
            </el-button>
          </template>
        </el-table-column>

        <template slot="empty" v-if="drugList.length==0">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
    </div>

    <table-context-menu v-if="tableContextMenuVisible"
                        @foo="foo" ref="contextbutton"
                        :action="tableAction"
                        @handleTableAction="handleTableAction"
    ></table-context-menu>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="selectSmpaDrugList"
      :page-sizes="[10, 20, 50, 100]"
    />




    <el-dialog v-dialogDrag title="相关知识库药品" width="60%" append-to-body :visible.sync="relevanceDrugDialogVisible"
               :close-on-click-modal="false"
    >
      <el-row>
        <el-col>
          <span style="margin-right: 10px;">{{selectTableRow.yptym}}</span>
          <span style="margin-right: 10px;">{{selectTableRow.ggbzwzbs}}</span>
          <span style="margin-right: 10px;">{{selectTableRow.scqy}}</span>
          <span>{{selectTableRow.pzwh}}</span>
        </el-col>
      </el-row>
      <el-table
        border
        stripe
        ref="relevanceDrugTable"
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        :data="relevanceDrugTableData"
        :height="300"
      >
        <el-table-column  type="index" width="40" fixed="left"></el-table-column>
        <el-table-column property="pkgDrugProdId" label="包装药品ID" width="80"></el-table-column>
        <el-table-column property="drugName" label="包装药品名称" min-width="120"></el-table-column>
        <el-table-column property="packSpecDesc" label="包装规格描述" width="100"></el-table-column>
        <el-table-column property="packTypeDesc" label="包装类型" width="80"></el-table-column>
        <el-table-column property="packQty" label="数量" width="120">
          <template slot-scope="scope">
            {{ scope.row.packQty }}
            {{ scope.row.specUnitDesc }}
            <span style="font-size: 18px;font-weight: bold;">/</span>
            {{ scope.row.packQtyUnitDesc }}
          </template>
        </el-table-column>
        <el-table-column property="matId" label="包装材料ID" width="80"></el-table-column>
        <el-table-column property="wrapperDesc" label="包装材料描述" width="100"></el-table-column>
        <el-table-column property="shMedicareNum" label="上海医保代码" width="150"></el-table-column>
        <el-table-column property="matId" label="关联状态" width="80" fixed="right">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.relevanceStatus==1">已关联</el-tag>
            <el-tag type="info" v-if="scope.row.relevanceStatus==0">未关联</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button
              :disabled="scope.row.relevanceStatus==1"
              size="mini" type="text" title="更新上海医保代码"
              @click="updateMedicareNum(scope.row.matId, relevanceDrugQueryParams.shMedicareNum)"
            >更新
            </el-button>

          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="relevanceDrugTableDataTotal>0"
        :total="relevanceDrugTableDataTotal"
        :page.sync="relevanceDrugQueryParams.pageNum"
        :limit.sync="relevanceDrugQueryParams.pageSize"
        @pagination="selectRelevanceDrugList(undefined)"
        :page-sizes="[10, 20, 50, 100]"
      />
    </el-dialog>

    <el-dialog v-dialogDrag title="备注信息编辑" width="500px" append-to-body
               :visible.sync="editDrugCommentDialogVisible"
               :close-on-click-modal="false"
    >
      <el-input type="textarea" :rows="6" placeholder="请输入备注信息"
                v-model="drugComment"
                maxlength="500" clearable show-word-limit
                style="width: 450px;"
      />
      <span slot="footer" class="dialog-footer" style="margin-right:20px;">
        <el-button @click="editDrugCommentDialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="submitEditDrugComment">确 定</el-button>
      </span>
    </el-dialog>



    <el-dialog v-dialogDrag title="相关信息" width="80%" append-to-body
               :visible.sync="relevanceInfoDialogVisible"
               :close-on-click-modal="false"
    >
      <el-tabs >
        <el-tab-pane label="药品价格规则" >
          <el-table
            :data="relevanceInfo.smpaYpjggz"
            border style="width: 100%">
            <el-table-column prop="tbdm" label="统编代码" ></el-table-column>
            <el-table-column prop="fbrq" label="发布日期" ></el-table-column>
            <el-table-column prop="fblx" label="发布类型" ></el-table-column>
            <el-table-column prop="jjdw" label="计价单位" ></el-table-column>
            <el-table-column prop="jhjggzdm" label="进货价格规则代码" ></el-table-column>

            <el-table-column prop="jhjggzsm" label="进货价格规则说明" ></el-table-column>
            <el-table-column prop="jhgzjgje" label="进货规则价格金额" ></el-table-column>
            <el-table-column prop="xsjggzdm" label="销售价格规则代码" ></el-table-column>
            <el-table-column prop="xsjggzsm" label="销售价格规则说明" ></el-table-column>
            <el-table-column prop="xsgzjgje" label="销售规则价格金额" ></el-table-column>


            <el-table-column prop="gzsyfw" label="规则适用范围" ></el-table-column>
            <el-table-column prop="qyrq" label="启用日期" ></el-table-column>
            <el-table-column prop="yxrq" label="有效期限" ></el-table-column>
            <el-table-column prop="bgzd" label="变更字段" ></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="药品采购规则" >
          <el-table
            :data="relevanceInfo.smpaYpcggz"
            border style="width: 100%">
            <el-table-column prop="tbdm" label="统编代码" ></el-table-column>
            <el-table-column prop="fbrq" label="发布日期" ></el-table-column>
            <el-table-column prop="fblx" label="发布类型" ></el-table-column>
            <el-table-column prop="cgfsDesc" label="采购方式" ></el-table-column>
            <el-table-column prop="cgzt" label="采购状态" ></el-table-column>

            <el-table-column prop="cgyxfw" label="采购允许范围" ></el-table-column>
            <el-table-column prop="cgpssx" label="采购配送时限" ></el-table-column>
            <el-table-column prop="jzzq" label="结账周期" ></el-table-column>
            <el-table-column prop="zfyq" label="支付要求" ></el-table-column>
            <el-table-column prop="gzyj" label="规则依据" ></el-table-column>

            <el-table-column prop="qyrq" label="启用日期" ></el-table-column>
            <el-table-column prop="yxrq" label="有效期限" ></el-table-column>
            <el-table-column prop="bgzd" label="变更字段" ></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="医保可报销条件规则" >
          <el-table
            :data="relevanceInfo.smpaYbkbxgz"
            border style="width: 100%">
            <el-table-column prop="tbdm" label="统编代码" ></el-table-column>
            <el-table-column prop="fbrq" label="发布日期" ></el-table-column>
            <el-table-column prop="fblx" label="发布类型" ></el-table-column>
            <el-table-column prop="bxtjdm_desc" label="报销条件代码" ></el-table-column>
            <el-table-column prop="bxtjnr" label="报销条件内容" ></el-table-column>

            <el-table-column prop="gzly" label="规则来源" ></el-table-column>
            <el-table-column prop="qyrq" label="启用日期" ></el-table-column>
            <el-table-column prop="yxrq" label="有效期限" ></el-table-column>
            <el-table-column prop="bgzd" label="变更字段" ></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="医保报销比例规则" >
          <el-table
            :data="relevanceInfo.smpaYbblgz"
            border style="width: 100%">
            <el-table-column prop="tbdm" label="统编代码" ></el-table-column>
            <el-table-column prop="fbrq" label="发布日期" ></el-table-column>
            <el-table-column prop="fblx" label="发布类型" ></el-table-column>
            <el-table-column prop="ybrq_desc" label="医保人群" ></el-table-column>
            <el-table-column prop="kbxbl" label="报销比例" ></el-table-column>

            <el-table-column prop="grcdbl" label="自付比例" ></el-table-column>
            <el-table-column prop="zfxr" label="支付限额" ></el-table-column>
            <el-table-column prop="xejldw" label="限额计量单位" ></el-table-column>
            <el-table-column prop="xeljfs" label="限额累计方式" ></el-table-column>
            <el-table-column prop="sybzdm" label="适用病种代码" ></el-table-column>

            <el-table-column prop="sybzmc" label="适用病种名称" ></el-table-column>
            <el-table-column prop="gzwzsm" label="规则文字说明" ></el-table-column>
            <el-table-column prop="gzly" label="规则来源" ></el-table-column>
            <el-table-column prop="qyrq" label="启用日期" ></el-table-column>
            <el-table-column prop="yxrq" label="有效期限" ></el-table-column>

            <el-table-column prop="bgzd" label="变更字段" ></el-table-column>
            <el-table-column prop="jyl" label="甲乙类" ></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

  </div>
</template>

<script>

import conceptConst from '@/const/conceptConst'
import AcrossUtil from '@/utils/acrossUtil'
import { UCgetConfig } from '@/utils/columnSettings'
import { GLOBAL_CONFIGURATION_CONFIGKEY } from '@/utils/constants'
import { isEmpty,axiosDownload } from '@/utils/common'
import { smpaDrugList,selectAllVersionNo ,drugInfo,downloadSmpaDrugList,getRelevanceInfo} from '@/api/assistTool/smpaDrug'
import { smpaDrugList as fullSmpaDrugList ,downloadSmpaDrugList as downloadFullSmpaDrugList,updateDrugComment} from '@/api/dkm/dictDrug'
import { selectPdProdMatList,updateMedicareNum } from '@/api/dkm/drugProd'

export default {
  name: 'SmpaDrugList',
  components: {},
  data() {
    return {
      fullscreenLoading:false,
      conceptConst,
      // 遮罩层
      loading: true,
      drugList: [],
      // 总条数
      total: 0,
      // 表格高度
      tableHeight: window.innerHeight - 100 - 100,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        searchValue: null,
        orderByColumn: undefined,
        isAsc: undefined,
        versionNo:undefined,
        modifyType:4,
        searchCode:undefined,
        relevanceStatus:0,
        manufacturer:""
      },
      /*表格右键菜单显示隐藏*/
      tableContextMenuVisible: false,
      tableAction: [
        { name: '查询相关知识库药品' },
        { name: '相关信息' },
      ],
      versionNoOptions:[],
      modifyTypeOptions:[
        {code:4,name:'当前版本全量数据'},
        {code:0,name:'与之前版本完全一致'},
        {code:1,name:'当前版本修改'},
        {code:2,name:'当前版本新增'},
        {code:3,name:'当前版本删除'},
      ],
      relevanceStatusOptions:[
        {code:0,name:'全部'},
        {code:1,name:'已关联'},
        {code:2,name:'未关联'},
      ],
      /*查询关联知识库药品查询参数*/
      relevanceDrugQueryParams:{
        pageNum: 1,
        pageSize: 10,
        orderByColumn: undefined,
        isAsc: undefined,
        approvalNum:undefined,
        standardCode:undefined,
        shMedicareNum:undefined

      },
      /*关联知识库药品表格数据*/
      relevanceDrugTableData:[],
      relevanceDrugTableDataTotal:0,
      relevanceDrugDialogVisible:false,
      selectTableRow:{},
      editDrugCommentDialogVisible:false,
      drugComment:"",
      drugId:"",
      relevanceInfoDialogVisible:false,
      //关联信息
      relevanceInfo:{}

    }
  },
  computed: {
    tableOptions() {
      return [
        {
          id: 1,
          prop: 'tbdm',
          name: '统编代码',
          fixed: false,
          show: true,
          sortable: 'custom',
          align: 'center',
          width: '100'
        },
        {
          id: 2,
          prop: 'yptym',
          name: '药品通用名',
          fixed: false,
          show: true,
          sortable: 'custom',
          align: 'center',
          width: '120'
        },
        {
          id: 3,
          prop: 'spm',
          name: '商品名',
          fixed: false,
          show: true,
          sortable: 'custom',
          align: 'center',
          width: '70'
        },
        {
          id: 4,
          prop: 'scqy',
          name: '生产企业',
          fixed: false,
          show: true,
          sortable: this.queryParams.versionNo?false:'custom',
          align: 'center',
          width: '200'
        },
        {
          id: 5,
          prop: 'ggbzwzbs',
          name: '规格包装描述',
          fixed: false,
          show: true,
          align: 'center',
          width: '150'
        },
        {
          id: 6,
          prop: 'ggbz',
          name: '规格包装',
          fixed: false,
          show: true,
          align: 'center',
          width: '150'
        },
        {
          id: 7,
          prop: 'zxybz',
          name: '中西药标志',
          fixed: false,
          show: true,
          align: 'center',
          width: '80'
        },
        {
          id: 8,
          prop: 'pzwh',
          name: '批准文号',
          fixed: false,
          show: true,
          sortable: this.queryParams.versionNo?false:'custom',
          align: 'center',
          width: '100'
        },{
          id: 9,
          prop: 'bwm',
          name: '本位码',
          fixed: false,
          show: true,
          sortable: this.queryParams.versionNo?false:'custom',
          align: 'center',
          width: '100'
        },{
          id: 10,
          prop: 'modifyType',
          name: '数据类型',
          fixed: false,
          show: this.queryParams.versionNo?true:false,
          align: 'center',
          width: '60'
        },{
          id: 11,
          prop: 'comment',
          name: '备注信息',
          fixed: false,
          show: this.queryParams.versionNo?false:true,
          align: 'center',
          width: '100'
        }, {
          id: 12,
          prop: 'bzxx',
          name: '原始备注信息',
          fixed: false,
          show: true,
          align: 'center',
          width: '100'
        }, {
          id: 13,
          prop: 'bgzd',
          name: '变更字段',
          fixed: false,
          show: true,
          align: 'center',
          width: '100'
        }
      ]
    }
  },
  created() {
    this.$nextTick(() => {
      this.setTableHeight()
      if(this.$route.query && this.$route.query.versionNo){
        // 从其他页面路由传参版本号，快速搜索
        this.queryParams.versionNo=this.$route.query.versionNo
      }
      /* 查询全部版本号*/
      selectAllVersionNo().then(response => {
        this.versionNoOptions = response.data
        if( this.versionNoOptions.length==0){
          this.msgInfo("当前无数据版本，请先执行上传操作")
          this.loading=false
          return
        }
        //页面初始化完毕，默认触发一次排序
        this.$refs.refundTable.sort('tbdm','descending');
      })
    })

  },
  activated() {
    //激活时重新设置表格高度
    this.setTableHeight()
  },
  mounted() {
    var _this = this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      AcrossUtil.$emit('resetTableHeight')
    }

    AcrossUtil.$on('resetTableHeight', function(param) {
      setTimeout(function() {
        _this.setTableHeight()
      }, 300)
    })

  },
  methods: {
    /*设置表格的高度*/
    setTableHeight() {
      // 获取高度值 （内容高+padding+边框）
      if(this.$refs.headerQuery){
        let formHeight = this.$refs.headerQuery.offsetHeight
        this.tableHeight = window.innerHeight - formHeight - 150
      }
    },

    /*鼠标右键点击事件*/
    rowContextmenu(row, column, event) {
      this.tableContextMenuVisible = false
      this.tableContextMenuVisible = true
      // 阻止右键默认行为
      event.preventDefault()
      this.$nextTick(() => {
        this.$refs.contextbutton.init(row, column, event)
      })
    },
    /* 取消鼠标监听事件 菜单栏*/
    foo() {
      this.tableContextMenuVisible = false
      document.removeEventListener('click', this.foo)
    },
    /* 处理表格右键菜单点击*/
    handleTableAction(actionName, row, column) {
      //console.log("右键点击",actionName, row, column)
      if (actionName === '查询相关知识库药品') {
        if(this.queryParams.versionNo){
          this.msgInfo("当前查看的是版本["+this.queryParams.versionNo+"]的数据，暂不支持该操作，请移除版本号筛选条件后重试")
        }else{
          this.selectRelevanceDrugList(row)
        }
      } else if (actionName === '相关信息') {
        if(this.queryParams.versionNo){
          this.getRelevanceInfo(row)
        }else{
          this.msgInfo("当前查看的是版本["+this.queryParams.versionNo+"]的数据，暂不支持该操作，请移除版本号筛选条件后重试")
        }
      }
    },
    /** 查询上传记录列表 */
    async selectSmpaDrugList() {
      this.loading = true
      let response
      if(this.queryParams.versionNo){
        //有版本号，查询带版本的数据
        response=await smpaDrugList(this.queryParams)
      }else{
        //没有版本号，查询全量数据
        response=await fullSmpaDrugList(this.queryParams)
      }
      this.drugList = response.rows
      this.total = response.total
      this.loading = false
      /*if(this.queryParams.modifyType==1){
        this.handleUpdateTableData()
      }*/
      this.handleUpdateTableData()
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.isResult = true
      this.queryParams.pageNum = 1
      this.selectSmpaDrugList()
    },
    /** 导出按钮操作 */
    async handleExport(){
      this.fullscreenLoading=true
      let response
      if(this.queryParams.versionNo){
        //有版本号，查询带版本的数据
        response=await downloadSmpaDrugList(this.queryParams)
      }else{
        //没有版本号，查询全量数据
        response=await downloadFullSmpaDrugList(this.queryParams)
      }
      axiosDownload("上海医保药品目录.xlsx", response)
      this.fullscreenLoading=false
    },
    /*表格排序切换*/
    tableSortChange({column, prop, order}){
      if(order!=null){
        order=order=='descending'?'desc':'asc';
      }else{
        order=''
      }
      this.queryParams.orderByColumn=prop
      this.queryParams.isAsc=order
      this.handleQuery()
    },

    compareTableCellClass({row, column, rowIndex, columnIndex}){
      let property=column.property
      if(['yptym','ywm','spm','scqy','ggbzwzbs','pzwh','bwm'].indexOf(property)>-1 ){
        let nowVersionRow
        let lastVersionRow
        if(row.dataType=='当前版本'){
           nowVersionRow=row
           lastVersionRow=this.drugList[rowIndex+1]
        }else if(row.dataType=='上一个版本'){
          nowVersionRow=this.drugList[rowIndex-1]
          lastVersionRow=row
        }
       if(nowVersionRow &&lastVersionRow && nowVersionRow[property]!=lastVersionRow[property]){
          return 'difference-cell';
        }
      }else{
        return '';
      }
    },
    /*处理-修改类型的数据*/
    handleUpdateTableData(){
      let newDrugList=[]
      this.drugList.forEach(item=>{
        let nowVersion=item
        let lastVerson=item.lastVersionSmpaWmDrug
        if(item.modifyType==1){
          for(let key in nowVersion){
            if(['yptym','ywm','spm','scqy','ggbzwzbs','pzwh','bwm'].indexOf(key)>-1){
              if(nowVersion[key]!=lastVerson[key]){
                nowVersion.hasDifference=true
                nowVersion.dataType='当前版本'
                lastVerson.hasDifference=true
                lastVerson.dataType='上一个版本'
                lastVerson.modifyType=1
              }
            }
          }
          newDrugList.push(nowVersion,lastVerson)
        }else{
          newDrugList.push(nowVersion)
        }

      })
      this.drugList=newDrugList
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      let property=column.property
      if (property === "tbdm" ) {
        if(row.dataType=='当前版本'){
          return {
            rowspan: 2,
            colspan: 1
          }
        }else if(row.dataType=='上一个版本'){
          return {
            rowspan: 0,
            colspan: 0
          }
        }else{
          return {
            rowspan: 1,
            colspan: 1
          }
        }
      }
    },
    /*版本号变化*/
    versionNoChange(val){
      this.queryParams.relevanceStatus=0
      this.handleQuery()
    },
    /*查询相关知识库药品*/
    selectRelevanceDrugList(row){
      this.selectTableRow=row
      if(row){
        this.relevanceDrugQueryParams.approvalNum=row.pzwh
        this.relevanceDrugQueryParams.standardCode=row.bwm
        this.relevanceDrugQueryParams.shMedicareNum=row.tbdm
        this.relevanceDrugQueryParams.pageNum=1
        this.relevanceDrugQueryParams.pageSize=10
      }
      this.loading = true

      selectPdProdMatList(this.relevanceDrugQueryParams).then(res=>{
        this.relevanceDrugDialogVisible=true
        res.rows.forEach(item=>{
          //是否关联知识库药品
          if(item.shMedicareNum &&item.shMedicareNum.indexOf(this.relevanceDrugQueryParams.shMedicareNum)>=0){
            item.relevanceStatus=1
          }else{
            item.relevanceStatus=0
          }
        })
        this.relevanceDrugTableData = res.rows
        this.relevanceDrugTableDataTotal = res.total
        this.loading = false
        //解决表格固定列-滚动时候错位
        setTimeout(() => {
          this.$nextTick(() => {
            this.$refs.relevanceDrugTable.doLayout()
          })
        }, 300)

      })
    },
   /* 更新上海医保编码*/
    updateMedicareNum(matId,shMedicareNum){
      updateMedicareNum(matId, '', shMedicareNum).then(res => {
        this.selectRelevanceDrugList(this.selectTableRow)
      })
    },
    /* 编辑上海医保药品备注信息*/
    editSmpaDrugComment(row){
      this.editDrugCommentDialogVisible=true
      this.drugComment=row.comment
      this.drugId=row.id
    },
    /* 保存上海医保药品备注信息*/
    submitEditDrugComment(){
      updateDrugComment(this.drugId,this.drugComment,2).then(res => {
        this.editDrugCommentDialogVisible=false
        this.msgSuccess()
        this.selectSmpaDrugList()
      })
    },
    //查询相关信息（药品价格规则/药品采购规则/品医保可报销条件规则/医保报销比例规则）
    getRelevanceInfo(row){
      this.relevanceInfoDialogVisible=true
      getRelevanceInfo(row.tbdm,row.versionNo).then(res=>{
        console.log(res)
        this.relevanceInfo=res.data
      })

    }



  }
}
</script>

<style lang="scss" scoped>
.el-table .cell .el-form-item {
  margin-bottom: 0px;
}

</style>



<style>

.el-table .difference-cell .cell {
  color:#f50707;
}


</style>
