var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { overflow: "auto" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticClass: "card-box", attrs: { span: 12 } },
            [
              _c("el-card", [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("span", [_vm._v("CPU")]),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "el-table el-table--enable-row-hover el-table--medium",
                  },
                  [
                    _c(
                      "table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { cellspacing: "0" },
                      },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticClass: "is-leaf" }, [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("属性"),
                              ]),
                            ]),
                            _c("th", { staticClass: "is-leaf" }, [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("值"),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("核心数"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.server.cpu
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(_vm._s(_vm.server.cpu.cpuNum)),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("用户使用率"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.server.cpu
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(_vm._s(_vm.server.cpu.used) + "%"),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("系统使用率"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.server.cpu
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(_vm._s(_vm.server.cpu.sys) + "%"),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("当前空闲率"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.server.cpu
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(_vm._s(_vm.server.cpu.free) + "%"),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "card-box", attrs: { span: 12 } },
            [
              _c("el-card", [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("span", [_vm._v("内存")]),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "el-table el-table--enable-row-hover el-table--medium",
                  },
                  [
                    _c(
                      "table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { cellspacing: "0" },
                      },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticClass: "is-leaf" }, [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("属性"),
                              ]),
                            ]),
                            _c("th", { staticClass: "is-leaf" }, [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("内存"),
                              ]),
                            ]),
                            _c("th", { staticClass: "is-leaf" }, [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("JVM"),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("总内存"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.server.mem
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(_vm._s(_vm.server.mem.total) + "G"),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", [
                              _vm.server.jvm
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(_vm._s(_vm.server.jvm.total) + "M"),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("已用内存"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.server.mem
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(_vm._s(_vm.server.mem.used) + "G"),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", [
                              _vm.server.jvm
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(_vm._s(_vm.server.jvm.used) + "M"),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("剩余内存"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.server.mem
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(_vm._s(_vm.server.mem.free) + "G"),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", [
                              _vm.server.jvm
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(_vm._s(_vm.server.jvm.free) + "M"),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("使用率"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.server.mem
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "cell",
                                      class: {
                                        "text-danger":
                                          _vm.server.mem.usage > 80,
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.server.mem.usage) + "%")]
                                  )
                                : _vm._e(),
                            ]),
                            _c("td", [
                              _vm.server.jvm
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "cell",
                                      class: {
                                        "text-danger":
                                          _vm.server.jvm.usage > 80,
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.server.jvm.usage) + "%")]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "card-box", attrs: { span: 24 } },
            [
              _c("el-card", [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("span", [_vm._v("服务器信息")]),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "el-table el-table--enable-row-hover el-table--medium",
                  },
                  [
                    _c(
                      "table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { cellspacing: "0" },
                      },
                      [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("服务器名称"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.server.sys
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(_vm._s(_vm.server.sys.computerName)),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("操作系统"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.server.sys
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(_vm._s(_vm.server.sys.osName)),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("服务器IP"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.server.sys
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(_vm._s(_vm.server.sys.computerIp)),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("系统架构"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.server.sys
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(_vm._s(_vm.server.sys.osArch)),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "card-box", attrs: { span: 24 } },
            [
              _c("el-card", [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("span", [_vm._v("Java虚拟机信息")]),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "el-table el-table--enable-row-hover el-table--medium",
                  },
                  [
                    _c(
                      "table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { cellspacing: "0" },
                      },
                      [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("Java名称"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.server.jvm
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(_vm._s(_vm.server.jvm.name)),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("Java版本"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.server.jvm
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(_vm._s(_vm.server.jvm.version)),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("启动时间"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.server.jvm
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(_vm._s(_vm.server.jvm.startTime)),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("运行时长"),
                              ]),
                            ]),
                            _c("td", [
                              _vm.server.jvm
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(_vm._s(_vm.server.jvm.runTime)),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", { attrs: { colspan: "1" } }, [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("安装路径"),
                              ]),
                            ]),
                            _c("td", { attrs: { colspan: "3" } }, [
                              _vm.server.jvm
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(_vm._s(_vm.server.jvm.home)),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", { attrs: { colspan: "1" } }, [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("项目路径"),
                              ]),
                            ]),
                            _c("td", { attrs: { colspan: "3" } }, [
                              _vm.server.sys
                                ? _c("div", { staticClass: "cell" }, [
                                    _vm._v(_vm._s(_vm.server.sys.userDir)),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "card-box", attrs: { span: 24 } },
            [
              _c("el-card", [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("span", [_vm._v("磁盘状态")]),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "el-table el-table--enable-row-hover el-table--medium",
                  },
                  [
                    _c(
                      "table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { cellspacing: "0" },
                      },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticClass: "is-leaf" }, [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("盘符路径"),
                              ]),
                            ]),
                            _c("th", { staticClass: "is-leaf" }, [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("文件系统"),
                              ]),
                            ]),
                            _c("th", { staticClass: "is-leaf" }, [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("盘符类型"),
                              ]),
                            ]),
                            _c("th", { staticClass: "is-leaf" }, [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("总大小"),
                              ]),
                            ]),
                            _c("th", { staticClass: "is-leaf" }, [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("可用大小"),
                              ]),
                            ]),
                            _c("th", { staticClass: "is-leaf" }, [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("已用大小"),
                              ]),
                            ]),
                            _c("th", { staticClass: "is-leaf" }, [
                              _c("div", { staticClass: "cell" }, [
                                _vm._v("已用百分比"),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm.server.sysFiles
                          ? _c(
                              "tbody",
                              _vm._l(_vm.server.sysFiles, function (sysFile) {
                                return _c("tr", [
                                  _c("td", [
                                    _c("div", { staticClass: "cell" }, [
                                      _vm._v(_vm._s(sysFile.dirName)),
                                    ]),
                                  ]),
                                  _c("td", [
                                    _c("div", { staticClass: "cell" }, [
                                      _vm._v(_vm._s(sysFile.sysTypeName)),
                                    ]),
                                  ]),
                                  _c("td", [
                                    _c("div", { staticClass: "cell" }, [
                                      _vm._v(_vm._s(sysFile.typeName)),
                                    ]),
                                  ]),
                                  _c("td", [
                                    _c("div", { staticClass: "cell" }, [
                                      _vm._v(_vm._s(sysFile.total)),
                                    ]),
                                  ]),
                                  _c("td", [
                                    _c("div", { staticClass: "cell" }, [
                                      _vm._v(_vm._s(sysFile.free)),
                                    ]),
                                  ]),
                                  _c("td", [
                                    _c("div", { staticClass: "cell" }, [
                                      _vm._v(_vm._s(sysFile.used)),
                                    ]),
                                  ]),
                                  _c("td", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "cell",
                                        class: {
                                          "text-danger": sysFile.usage > 80,
                                        },
                                      },
                                      [_vm._v(_vm._s(sysFile.usage) + "%")]
                                    ),
                                  ]),
                                ])
                              }),
                              0
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }