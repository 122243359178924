var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "app-container",
      staticStyle: { overflow: "auto" },
      attrs: { "element-loading-text": "请稍后" },
    },
    [
      _c(
        "div",
        { ref: "headerQuery" },
        [
          _c(
            "el-row",
            { staticClass: "headerClass" },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "border-left": "5px solid #0073E9",
                    "padding-left": "10px",
                  },
                  attrs: { span: 4 },
                },
                [_vm._v("国家医保药品列表")]
              ),
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "queryForm",
                      staticStyle: { float: "right" },
                      attrs: { model: _vm.queryParams, inline: true },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "代码/通用名/商品名:",
                            prop: "searchValue",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "代码/通用名/商品名",
                              maxlength: "50",
                              clearable: "",
                            },
                            model: {
                              value: _vm.queryParams.searchValue,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "searchValue", $$v)
                              },
                              expression: "queryParams.searchValue",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "本位码/批准文号:",
                            prop: "searchCode",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "本位码/批准文号",
                              maxlength: "50",
                              clearable: "",
                            },
                            model: {
                              value: _vm.queryParams.searchCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "searchCode", $$v)
                              },
                              expression: "queryParams.searchCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "厂商:", prop: "manufacturer" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "厂商",
                              maxlength: "50",
                              clearable: "",
                            },
                            model: {
                              value: _vm.queryParams.manufacturer,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "manufacturer", $$v)
                              },
                              expression: "queryParams.manufacturer",
                            },
                          }),
                        ],
                        1
                      ),
                      !this.queryParams.versionNo
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "关联知识库药品",
                                prop: "relevanceStatus",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "statusClass",
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    placeholder: "关联状态",
                                    clearable: "",
                                    size: "small",
                                  },
                                  model: {
                                    value: _vm.queryParams.relevanceStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "relevanceStatus",
                                        $$v
                                      )
                                    },
                                    expression: "queryParams.relevanceStatus",
                                  },
                                },
                                _vm._l(
                                  _vm.relevanceStatusOptions,
                                  function (dict) {
                                    return _c("el-option", {
                                      key: "relevanceStatus" + dict.code,
                                      attrs: {
                                        label: dict.name,
                                        value: dict.code,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "版本号:", prop: "versionNo" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "statusClass",
                              staticStyle: { width: "200px" },
                              attrs: {
                                placeholder: "版本号",
                                size: "small",
                                clearable: "",
                              },
                              on: { change: _vm.versionNoChange },
                              model: {
                                value: _vm.queryParams.versionNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "versionNo", $$v)
                                },
                                expression: "queryParams.versionNo",
                              },
                            },
                            _vm._l(_vm.versionNoOptions, function (item) {
                              return _c("el-option", {
                                key: item.versionNo + "versionNo",
                                attrs: {
                                  label: item.versionNo,
                                  value: item.versionNo,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      this.queryParams.versionNo
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "类型:", prop: "modifyType" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "statusClass",
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    placeholder: "版本号",
                                    size: "small",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.queryParams.modifyType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "modifyType",
                                        $$v
                                      )
                                    },
                                    expression: "queryParams.modifyType",
                                  },
                                },
                                _vm._l(_vm.modifyTypeOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.code + "modifyType",
                                    attrs: {
                                      label: item.name,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-search",
                                size: "small",
                              },
                              on: { click: _vm.handleQuery },
                            },
                            [_vm._v("检索")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-document",
                                size: "small",
                              },
                              on: { click: _vm.handleExport },
                            },
                            [_vm._v("导出")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { ref: "tableContainer" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "refundTable",
              attrs: {
                border: "",
                stripe: "",
                data: _vm.drugList,
                height: _vm.tableHeight,
                "span-method": _vm.objectSpanMethod,
                "cell-class-name": _vm.compareTableCellClass,
              },
              on: {
                "sort-change": _vm.tableSortChange,
                "row-contextmenu": _vm.rowContextmenu,
              },
            },
            [
              _vm._l(_vm.tableOptions, function (data) {
                return data.show
                  ? _c("el-table-column", {
                      key: "tableOptions" + data.id,
                      attrs: {
                        prop: data.prop,
                        label: data.name,
                        fixed: data.fixed,
                        resizable: false,
                        sortable: data.sortable,
                        "min-width": data.width,
                        align: data.align,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                data.prop == "modifyType"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-tag",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  scope.row.modifyType == 0,
                                                expression:
                                                  "scope.row.modifyType==0",
                                              },
                                            ],
                                            attrs: { type: "info" },
                                          },
                                          [_vm._v("无变化")]
                                        ),
                                        _c(
                                          "el-tag",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  scope.row.modifyType == 1,
                                                expression:
                                                  "scope.row.modifyType==1",
                                              },
                                            ],
                                          },
                                          [_vm._v("修改")]
                                        ),
                                        _c(
                                          "el-tag",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  scope.row.modifyType == 2,
                                                expression:
                                                  "scope.row.modifyType==2",
                                              },
                                            ],
                                            attrs: { type: "success" },
                                          },
                                          [_vm._v("新增")]
                                        ),
                                        _c(
                                          "el-tag",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  scope.row.modifyType == 3,
                                                expression:
                                                  "scope.row.modifyType==3",
                                              },
                                            ],
                                            attrs: { type: "danger" },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("div", [
                                      _c("span", [
                                        _vm._v(_vm._s(scope.row[data.prop])),
                                      ]),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  : _vm._e()
              }),
              !this.queryParams.versionNo
                ? _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      fixed: "right",
                      width: "80",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editNhsaDrugComment(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑备注 ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3281130038
                    ),
                  })
                : _vm._e(),
              _vm.drugList.length == 0
                ? _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-booking.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ])
                : _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-left-data.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("未查询到结果，请修改查询条件重试！")]),
                  ]),
            ],
            2
          ),
        ],
        1
      ),
      _vm.tableContextMenuVisible
        ? _c("table-context-menu", {
            ref: "contextbutton",
            attrs: { action: _vm.tableAction },
            on: { foo: _vm.foo, handleTableAction: _vm.handleTableAction },
          })
        : _vm._e(),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
          "page-sizes": [10, 20, 50, 100],
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.selectNhsaDrugList,
        },
      }),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "相关知识库药品",
            width: "60%",
            "append-to-body": "",
            visible: _vm.relevanceDrugDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.relevanceDrugDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", [
                _c("span", { staticStyle: { "margin-right": "10px" } }, [
                  _vm._v(_vm._s(_vm.selectTableRow.registeredProductName)),
                ]),
                _c("span", { staticStyle: { "margin-right": "10px" } }, [
                  _vm._v(_vm._s(_vm.selectTableRow.registeredOutlook)),
                ]),
                _c("span", { staticStyle: { "margin-right": "10px" } }, [
                  _vm._v(_vm._s(_vm.selectTableRow.companyNamesc)),
                ]),
                _c("span", [_vm._v(_vm._s(_vm.selectTableRow.approvalCode))]),
              ]),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "relevanceDrugTable",
              attrs: {
                border: "",
                stripe: "",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
                data: _vm.relevanceDrugTableData,
                height: 300,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", width: "40", fixed: "left" },
              }),
              _c("el-table-column", {
                attrs: {
                  property: "pkgDrugProdId",
                  label: "包装药品ID",
                  width: "80",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  property: "drugName",
                  label: "包装药品名称",
                  "min-width": "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  property: "packSpecDesc",
                  label: "包装规格描述",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  property: "packTypeDesc",
                  label: "包装类型",
                  width: "80",
                },
              }),
              _c("el-table-column", {
                attrs: { property: "packQty", label: "数量", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.packQty) +
                            " " +
                            _vm._s(scope.row.specUnitDesc) +
                            " "
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "18px",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v("/")]
                        ),
                        _vm._v(" " + _vm._s(scope.row.packQtyUnitDesc) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { property: "matId", label: "包装材料ID", width: "80" },
              }),
              _c("el-table-column", {
                attrs: {
                  property: "wrapperDesc",
                  label: "包装材料描述",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  property: "stateMedicareNum",
                  label: "国家医保代码",
                  width: "150",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  property: "matId",
                  label: "关联状态",
                  width: "80",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.relevanceStatus == 1
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("已关联"),
                            ])
                          : _vm._e(),
                        scope.row.relevanceStatus == 0
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("未关联"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: scope.row.relevanceStatus == 1,
                              size: "mini",
                              type: "text",
                              title: "更新国家医保代码",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updateMedicareNum(
                                  scope.row.matId,
                                  _vm.relevanceDrugQueryParams.stateMedicareNum
                                )
                              },
                            },
                          },
                          [_vm._v("更新 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.relevanceDrugTableDataTotal > 0,
                expression: "relevanceDrugTableDataTotal>0",
              },
            ],
            attrs: {
              total: _vm.relevanceDrugTableDataTotal,
              page: _vm.relevanceDrugQueryParams.pageNum,
              limit: _vm.relevanceDrugQueryParams.pageSize,
              "page-sizes": [10, 20, 50, 100],
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.relevanceDrugQueryParams, "pageNum", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(
                  _vm.relevanceDrugQueryParams,
                  "pageSize",
                  $event
                )
              },
              pagination: function ($event) {
                return _vm.selectRelevanceDrugList(undefined)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "备注信息编辑",
            width: "500px",
            "append-to-body": "",
            visible: _vm.editDrugCommentDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editDrugCommentDialogVisible = $event
            },
          },
        },
        [
          _c("el-input", {
            staticStyle: { width: "450px" },
            attrs: {
              type: "textarea",
              rows: 6,
              placeholder: "请输入备注信息",
              maxlength: "500",
              clearable: "",
              "show-word-limit": "",
            },
            model: {
              value: _vm.drugComment,
              callback: function ($$v) {
                _vm.drugComment = $$v
              },
              expression: "drugComment",
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-right": "20px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editDrugCommentDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitEditDrugComment },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }