
// 集采离线更新数据下载  /dm/offline/cpm/download
import request from '@/utils/request'
export function downloadCpmdApi(data) {
  return request({
    url: `/dm/offline/cpm/download`,
    method: 'get',
    params: data,
    resopnseReturn: true,
    responseType: 'blob',
    timeout: 2*60*1000
  })
}