<template>
  <div class="app-container" style="overflow: auto" v-loading="loading">
    <el-row>
      <el-col :span="4" style="border-left: 5px solid #0073E9;padding-left: 10px;">开发者用户信息</el-col>
    </el-row>

    <el-row style="margin: 30px 50px;" v-if="!appUserInfo">
      <span style="padding-right: 10px;font-size: 14px;">您还不是开发者用户</span>
      <el-link type="primary" @click="register">立即申请</el-link>
    </el-row>

    <el-row style="margin: 30px 50px;" v-if="appUserInfo">
      <el-col :span="4" class="label-item">
        <span > 开发者ID(AppID):</span>
      </el-col>
      <el-col :span="20">
        <p class="val-item">{{ appUserInfo.appId }}</p>
        <p class="val-item">开发者ID是平台的开发识别码，配合开发者密码可调用平台的数据接口</p>
      </el-col>

      <el-col :span="24" style="margin-top: 30px;"></el-col>

      <el-col :span="4" class="label-item">
        <span >   开发者密码(AppSecret):</span>
      </el-col>
      <el-col :span="20">
        <span class="val-item">
          <el-link type="primary" @click="resetAppSecret">重置<i class="el-icon-view el-icon-s-tools"
                                                               style="padding-left: 5px;"
          ></i></el-link>
        </span>
        <p class="val-item">
          开发者密码是校验平台开发者身份的密码。切记勿把密码直接交给第三方开发者或直接存储在代码中。
          为保障帐号安全，平台不储存AppSecret；如果遗忘，请重置</p>
      </el-col>
    </el-row>

    <el-divider v-if="appUserInfo"></el-divider>

    <el-row v-if="appUserInfo">
      <el-col :span="4" style="border-left: 5px solid #0073E9;padding-left: 10px;">服务器配置</el-col>
    </el-row>

    <el-row style="margin: 10px 50px;" v-if="appUserInfo">
      <el-col :span="24">
<!--        <el-button type="primary" size="medium" style="float: right;">启用</el-button>-->
        <el-button  size="medium" style="float: right;margin-right: 10px;" @click="showServerMsgDialog">修改配置</el-button>
      </el-col>
      <div>
        <el-col :span="4" class="label-item">
          服务器地址(URL):
        </el-col>
        <el-col :span="20" class="val-item">
          {{appUserInfo.callbackUrl||'未填写'}}
        </el-col>
        <el-col :span="24" style="margin-top: 30px;"></el-col>
        <el-col :span="4" class="label-item">
          令牌(Token):
        </el-col>
        <el-col :span="20" class="val-item">
          {{appUserInfo.token||'未填写'}}
        </el-col>
        <el-col :span="24" style="margin-top: 30px;"></el-col>
<!--        <el-col :span="4" class="label-item">
          消息加解密密钥(EncodingAESKey):
        </el-col>
        <el-col :span="20" class="val-item">
          {{appUserInfo.encodingAesKey||'未填写'}}
        </el-col>-->
      </div>
    </el-row>

    <el-divider v-if="appUserInfo"></el-divider>

    <el-row v-if="appUserInfo">
      <el-col :span="4" style="border-left: 5px solid #0073E9;padding-left: 10px;">接口权限</el-col>
      <el-col :span="24" style="margin-top: 30px;padding: 0px 30px;">
        <el-table
          :data="tableData"
          border
          stripe
          :header-cell-style="{'text-align':'center','background':'#ECF2F0'}"
          :cell-style="{'text-align':'center'}"
          style="width: 100%"
        >
          <el-table-column prop="apiServerName" label="服务名称" min-width="100"></el-table-column>
          <el-table-column prop="apiName" label="API名称" min-width="200"></el-table-column>
          <el-table-column prop="apiUrl" label="API地址" min-width="300"></el-table-column>
        </el-table>
      </el-col>
    </el-row>


    <el-dialog title="服务器信息编辑" width="730px"
               :visible.sync="serverMsgDialogVisible"
               :close-on-click-modal="false"
    >
      <div style="margin-bottom: 30px;">
        <p class="val-item">请填写接口配置信息，此信息需要你拥有自己的服务器资源。</p>
        <p class="val-item">填写的URL需要正确响应知识库系统发送的Token验证，请阅读接入指南。</p>
      </div>
      <el-form ref="serverMsgEditForm" status-icon :model="serverMsgEditFormData" label-width="160px">
        <el-form-item label="URL:" prop="callbackUrl"
                      :rules="[
                        { required: true, message: '请输入URL', trigger: 'blur'},
                        { validator: validateCallbackUrl, trigger: 'blur'}
                        ]"
        >
          <el-input placeholder="请输入URL" maxlength="150" clearable show-word-limit
                    v-model="serverMsgEditFormData.callbackUrl"
                    style="min-width: 200px;max-width:400px;"
          />
          <div class="sub-title">必须以http://或https://开头。</div>
        </el-form-item>

        <el-form-item label="Token:" prop="token"
                      :rules="[
                        { required: true, message: '请输入token', trigger: 'blur'},
                        { validator: validateToken, trigger: 'blur'}
                        ]"
        >
          <el-input placeholder="请输入Token" maxlength="32" minlength="10" clearable show-word-limit
                    v-model="serverMsgEditFormData.token"
                    style="min-width: 200px;max-width:400px;"
          />
          <div class="sub-title">必须为英文或数字，长度为10-32字符。</div>
        </el-form-item>

<!--        <el-form-item label="EncodingAESKey:" prop="encodingAesKey"
                      :rules="[
                        { required: true, message: '请输入encodingAESKey', trigger: 'blur'},
                         { validator: validateAESKey, trigger: 'blur'}
                        ]"
        >
          <el-input placeholder="请输入encodingAESKey" maxlength="43"  clearable show-word-limit
                    v-model="serverMsgEditFormData.encodingAesKey"
                    style="min-width: 200px;max-width:290px;"
          />
          <el-button  size="medium" style="margin-left: 10px;" @click="serverMsgEditFormData.encodingAesKey=randomStr(43)">随机生成</el-button>
          <div class="sub-title">消息加密密钥由43位字符组成，可随机修改，字符范围为A-Z，a-z，0-9。</div>
        </el-form-item>-->

        <el-row>
          <el-col :span="24">
            <el-button type="primary"
                       size="small"
                       style="margin-left: 20px;background: #2B60F8;float:right;"
                       @click="confirmEditServerMsg"


            >确定
            </el-button>
            <el-button style="float: right;margin-right: 10px;"
                       size="small"

            >取消
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>


  </div>
</template>

<script>


import { createAppSecret, getAppUserInfo, register,saveOrUpdateUserInfo } from '@/api/system/appUserRegistration'
import { testCallbackUrl } from '@/api/system/apiGateway'
import { randomStr,isEmpty } from '@/utils/common'
export default {
  name: 'AppUserProfile',
  components: {},
  data() {
    return {
      // 遮罩层
      loading: false,
      tableData: [],
      appUserInfo: undefined,
      /*服务器信息编辑弹窗*/
      serverMsgDialogVisible:false,
      /*服务器信息编辑表单*/
      serverMsgEditFormData:{
        encodingAesKey:undefined,
        callbackUrl:undefined,
        token:undefined
      }

    }
  },
  created() {
    this.getAppUserInfo()
  },
  activated() {

  },
  mounted() {

  },
  methods: {
    randomStr,
    getAppUserInfo() {
      getAppUserInfo().then(res => {
        if (res.code == 200 && !res.data) {
          //不是开发者用户
        } else {
          this.appUserInfo = res.data
          this.tableData = res.data.apiRegistrationList
          this.serverMsgEditFormData.callbackUrl=res.data.callbackUrl
          this.serverMsgEditFormData.token=res.data.token
          this.serverMsgEditFormData.encodingAesKey=res.data.encodingAesKey
        }
      })
    },
    /*重置开发者密码*/
    resetAppSecret() {
      this.$confirm('该操作将重置AppSecret,旧的AppSecret和正在使用中的api—token都会失效， 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        createAppSecret().then(res => {
          this.$alert('<p style="margin:20px;">您的AppSecret为：' + res.data + '</p>', '', {
            dangerouslyUseHTMLString: true
          })
        })
      }).catch(() => {
      })
    },
    register() {
      this.loading=true
      register().then(res => {
        this.getAppUserInfo()
        this.loading=false
        this.msgSuccess('注册成功')
      }).catch(res =>{
        //console.log("异常",res)
        //this.msgSuccess(res)
       this.loading=false
      })
    },
   /* 显示服务器信息编辑弹窗*/
    showServerMsgDialog(){
      this.serverMsgDialogVisible=true
    },
    /*确定编辑服务器配置信息*/
    confirmEditServerMsg(){
      this.$refs['serverMsgEditForm'].validate(valid => {
        if (valid) {
          let callbackUrl=this.serverMsgEditFormData.callbackUrl
          let token=this.serverMsgEditFormData.token
          testCallbackUrl(callbackUrl,token).then(response=>{
            if(response.code==200){
              saveOrUpdateUserInfo(this.serverMsgEditFormData).then(res=>{
                this.serverMsgDialogVisible=false
                this.msgSuccess("修改成功")
                this.getAppUserInfo()
              })
            }
          })

        } else {
          this.msgError('请规范填写')
        }
      })
    },
    /*校验CallbackUrl*/
    validateCallbackUrl(rule, value, callback, index){
      if (!isEmpty(value)) {
        //let urlMatch = /^((http|https):\/\/)?(([A-Za-z0-9]+-[A-Za-z0-9]+|[A-Za-z0-9]+))$/;
        let urlMatch = /^(http|https):.*\/\//;
        if (!urlMatch.test(value)) {
          callback(new Error("url不正确,必须以http://或https://开头"));
        }else{
          callback();
        }
      }else{
        callback()
      }
    },
    validateToken(rule, value, callback, index){
      if (!isEmpty(value)) {
        let match = /^[A-Za-z0-9]+$/;
        if (value.length<10 || value.length>32) {
          callback(new Error("长度为10-32字符"));
        }else if(!match.test(value)){
          callback(new Error("只能输入英文或数字"));
        }else{
          callback();
        }
      }else{
        callback()
      }
    },
    validateAESKey(rule, value, callback, index){
      if (!isEmpty(value)) {
        let match = /^[A-Za-z0-9]+$/;
        if (value.length<43 || value.length>43) {
          callback(new Error("长度为43字符"));
        }else if(!match.test(value)){
          callback(new Error("只能输入英文或数字"));
        }else{
          callback();
        }
      }else{
        callback()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.label-item {
  font-size: 14px;
  color: #606266;
  min-width: 200px;
  font-weight: 700;
  line-height: 16px;
}

.val-item {
  font-size: 14px;
  color: #606266;
  margin-bottom: 10px;
  line-height: 16px;
}

.sub-title{
  color: #9a9a9a;
  font-size: 13px;
}


</style>


<style>


</style>
