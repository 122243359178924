import request from '@/utils/request'

// 机构注册审批列表
export function pageList(query) {
  return request({
    url: '/dm/api/org/apply/page/list',
    method: 'POST',
    data: query
  })
}

// 机构审批-通过-拒绝
export function applyAdopt(query) {
  return request({
    url: '/dm/api/org/apply/status',
    method: 'put',
    data: query
  })
}