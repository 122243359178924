import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/common'

// 历史用户列表
export function historyList(data) {
  return request({
    url: '/thys-api/manage/history/list',
    method: 'get',
    params: data
  })
}

// 匹配状态变更
export function match(data) {
  return request({
    url: '/thys-api/manage/history/match',
    method: 'put',
    data: data
  })
}
// 可能为历史用户的列表
export function originList(data) {
  return request({
    url: '/thys-api/manage/history/origin/list',
    method: 'get',
    params: data
  })
}
// 获取微信用户原始数据
export function origin(data) {
  return request({
    url: '/thys-api/wxUser/origin',
    method: 'get',
    params: data
  })
}
// 获取微信用户原始数据（新接口）
export function compare(data) {
  return request({
    url: '/thys-api/wxUser/origin/compare',
    method: 'get',
    params: data
  })
}
