<!--机构药品库-->
<template>
  <el-container class="drug-storage-container layout-container">
    <el-header class="drug-storage-header headerClass">
      <div class="header-top">
        <div class="header-top-l">
          <div class="fl common-title">
            机构药品库
          </div>
          <div class="fl" style="margin: 0 20px;">
            <span>当前版本：</span>
            <span>{{ currentVersion }}</span>
          </div>
          <div class="fl">
            <span>版本创建时间：</span>
            <span>{{ pushTime }}</span>
          </div>
        </div>
        <div class="header-top-r">
          <el-form ref="form" :model="searchObj" :inline="true" label-width="80px">
            <!-- <el-form-item>
              <el-date-picker
                v-model="searchObj.year"
                type="year"
                value-format="yyyy-MM-dd"
                placeholder="年">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-select v-model="searchObj.quarterly" clearable placeholder="季度">
                <el-option
                  v-for="item in quaterOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select v-model="searchObj.month" clearable placeholder="月份">
                <el-option
                  v-for="item in monthOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item>
              <el-select v-model="searchObj.type" clearable placeholder="类型" @change="searchTypeHandle">
                <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-date-picker
                v-if="searchObj.type === 1"
                v-model="searchObj.year"
                type="year"
                value-format="yyyy"
                placeholder="年"
                @change="yearChangeHandle">
              </el-date-picker>
              <date-quarter v-else-if="searchObj.type === 2" @quarterChange="quarterChangeHandle" />
              <el-date-picker
                v-else
                v-model="searchObj.month"
                type="month"
                value-format="yyyy-MM"
                placeholder="月份"
                @change="monthChangeHandle">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="searchObj.searchValue"
                class="search-input"
                placeholder="输入编码/药品名称/剂型/生产厂家"/>
              <!-- <el-autocomplete
                v-model="searchObj.searchValue"
                :fetch-suggestions="querySearchAsync"
                placeholder="输入编号/药品名称/商品名/生产厂家/批号"
                clearable
                class="search-input"
                size="small"
                value-key="name"/> -->
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="searchHandle">查询</el-button>
              <el-button icon="el-icon-download" type="primary" class="download-btn" plain @click="downloadHandle">下载标准数据</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="header-bottom">
        <!--版本轴-->
        <d-k-b-line :timeline-list="timelineList" :version="currentVersion" @getCurrentItem="onTimeLine" />
      </div>
    </el-header>
    <el-main class="drug-storage-main">
      <el-container class="layout-container">
        <el-aside class="institution-table-wrap">
          <el-container class="layout-container institution-table-container">
            <el-header class="title-header">
              <h3 class="table-title inst-table-title">机构药品列表 </h3>
            </el-header>
            <el-main style="padding: 10px;overflow-y: hidden;">
              <el-table
                :data="instTableData"
                border
                style="width: 100%"
                :header-cell-style="{color: '#333',fontWeight: '500', fontSize: '14px'}"
                height="97%"
                class="insti-table"
                @row-click="instRowClickHandle">
                <el-table-column
                  prop="drugId"
                  label="药品ID"
                  show-overflow-tooltip
                  fixed>
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="药品名称"
                  show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                  prop="spec"
                  label="规格包装"
                  show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                  prop="manufacturerName"
                  label="生产厂家"
                  show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                  prop="approvalNo"
                  label="批准文号"
                  show-overflow-tooltip>
                </el-table-column>
                <template slot="empty" v-if="isSearched === false">
                  <img src="@/assets/images/no-booking.svg" alt="">
                  <p>暂无数据</p>
                </template>
                <template slot="empty" v-else>
                  <img src="@/assets/images/no-left-data.svg" alt="">
                  <p>未查询到结果，请修改查询条件重试！</p>
                </template>
              </el-table>
            </el-main>
            <el-footer style="height: 40px;padding: 0;">
              <pagination
                v-show="total > 0"
                :total="total"
                :page.sync="currentPage"
                :limit.sync="pageSize"
                @pagination="getOrgListHandle"/>
            </el-footer>
          </el-container>
        </el-aside>
        <el-main v-if="!isShowPlatformEmpty" class="platform-table-wrap">
          <el-container class="layout-container platform-table-container">
            <el-header class="title-header">
              <h3 class="table-title platform-table-title">平台标准药品数据</h3>
            </el-header>
            <el-main style="padding: 10px;">
              <el-table
                ref="dynTable"
                :data="platformTableData"
                style="width: 100%"
                :row-style="rowStyle"
                :header-cell-style="{color: '#666',fontWeight: '400'}"
                class="platform-table">
                <el-table-column
                  v-for="item in PACK_COLUMN_LIST1"
                  :key="item.id"
                  :label="item.label"
                  :fixed="item.fixed"
                  :width="item.width">
                  <template slot-scope="scope">
                    <span v-if="item.isSplited">
                      <span v-for="(itemProp, index) in item.prop" :key="index">{{ itemProp | multilePropColumn(scope.row, item.prop, index) }}</span>
                    </span>
                    <span v-else>{{ item.prop | platforColumn(scope.row) }}</span>
                  </template>
                </el-table-column>
              </el-table>
              <el-table
                ref="dynTable2"
                :data="platformTableData"
                style="width: 100%"
                :row-style="rowStyle"
                :header-cell-style="{color: '#666',fontWeight: '400'}"
                class="platform-table">
                <el-table-column
                  v-for="item in PACK_COLUMN_LIST2"
                  :key="item.id"
                  :label="item.label"
                  :fixed="item.fixed"
                  :width="item.width">
                  <template slot-scope="scope">
                    <span v-if="item.isSplited">
                      <span v-for="(itemProp, index) in item.prop" :key="index">{{ itemProp | multilePropColumn(scope.row, item.prop, index) }}</span>
                    </span>
                    <span v-else>{{ item.prop | platforColumn(scope.row) }}</span>
                  </template>
                </el-table-column>
              </el-table>
              <el-table
                ref="dynTable3"
                :data="platformTableData"
                style="width: 100%"
                :row-style="rowStyle"
                :header-cell-style="{color: '#666',fontWeight: '400'}"
                class="platform-table">
                <el-table-column
                  v-for="item in PACK_COLUMN_LIST3"
                  :key="item.id"
                  :label="item.label"
                  :fixed="item.fixed"
                  :width="item.width">
                  <template slot-scope="scope">
                    <span v-if="item.isSplited">
                      <span v-for="(itemProp, index) in item.prop" :key="index">{{ itemProp | multilePropColumn(scope.row, item.prop, index) }}</span>
                    </span>
                    <span v-else>{{ item.prop | platforColumn(scope.row) }}</span>
                  </template>
                </el-table-column>
              </el-table>
              <el-table
                ref="dynTable4"
                :data="platformTableData"
                style="width: 100%"
                :row-style="rowStyle"
                :header-cell-style="{color: '#666',fontWeight: '400'}"
                class="platform-table">
                <el-table-column
                  v-for="item in PACK_COLUMN_LIST4"
                  :key="item.id"
                  :label="item.label"
                  :fixed="item.fixed"
                  :width="item.width">
                  <template slot-scope="scope">
                    <span v-if="item.isSplited">
                      <span v-for="(itemProp, index) in item.prop" :key="index">{{ itemProp | multilePropColumn(scope.row, item.prop, index) }}</span>
                    </span>
                    <span v-else>{{ item.prop | platforColumn(scope.row) }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-main>
          </el-container>
        </el-main>
        <el-main v-else class="platform-table-wrap">
          <el-container class="layout-container platform-table-container">
            <el-header class="title-header">
              <h3 class="table-title platform-table-title">平台标准药品数据</h3>
            </el-header>
            <el-main style="padding: 10px;">
              <el-table
                :data="platformTableData"
                style="width: 100%;height: 100%;"
                :row-style="rowStyle"
                :header-cell-style="{color: '#666',fontWeight: '400'}"
                class="platform-table platform-table-none">
                <template slot="empty" style="height: 100%;">
                  <img src="@/assets/images/no-booking.svg" alt="">
                  <p>暂无数据</p>
                </template>
              </el-table>
            </el-main>
          </el-container>
        </el-main>
      </el-container>
    </el-main>
    <download-data ref="dialog-component" :version="currentVersion.toString()"/>
  </el-container>
</template>

<script>
import downloadData from './components/Download'
import {
  timelineApi,
  orgListApi,
  platformDrugApi
} from '@/api/dm/institutionDrugStorage'
import {
  QUATER_OPTIONS,
  MONTH_OPTIONS,
  TYPE_OPTIONS,
  PLATFORM_DTAT_OBJ,
  NO_MATCHED_LIST,
  PACK_COLUMN_LIST1,
  PACK_COLUMN_LIST2,
  PACK_COLUMN_LIST3,
  PACK_COLUMN_LIST4,
  ID_COLUMN,
  NAME_COLUMN,
  columnHandle
} from '@/utils/dm/institutionDrugStorage/data'
import { selectDrugGroupNameList } from '@/api/dkm/drugGroup'
export default {
  components: {
    downloadData
  },
  filters: {
    multilePropColumn(prop, row, propArr, index) {
      if (index === propArr.length - 1) {
        return row[prop]
      } else {
        return row[prop] + '-'
      }
    },
    platforColumn(prop, row) {
      if(Array.isArray(prop)) {
        console.log(prop)
        return row[prop[0]]
      } else {
        return row[prop]
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      isSearched: false,
      isShowPlatformEmpty: false, // 是否显示平台数据的空标志
      timelineList: [], // 版本时间轴
      currentVersion: '', // 当前版本
      pushTime: '', // 当前版本创建时间
      searchObj: {
        type: 1, // 选择年份还是季度或者月份
        searchValue: '',
        year: null,
        quarterly: null, // 季度
        month: null // 月
      },
      total: 0,
      pageSize: 10,
      currentPage: 1,
      versionData: [],
      quaterOptions: this.$deepClone(QUATER_OPTIONS),
      monthOptions: this.$deepClone(MONTH_OPTIONS),
      typeOptions: this.$deepClone(TYPE_OPTIONS),
      instTableData: [
        {
          code: 1,
          name: '氯化钠注射液',
          spec: '0.5g*5支/盒',
          manufacturerName: '北大医药股份有限公司',
          mapShow: '未匹配',
          approvalNo: 'H310216600'
        },
        {
          code: 1,
          name: '氯化钠注射液',
          spec: '0.5g*5支/盒',
          manufacturerName: '北大医药股份有限公司',
          approvalNo: 'H310216600'
        },
        {
          code: 1,
          name: '氯化钠注射液',
          spec: '0.5g*5支/盒',
          manufacturerName: '北大医药股份有限公司',
          approvalNo: 'H310216600'
        },
        {
          code: 1,
          name: '氯化钠注射液',
          spec: '0.5g*5支/盒',
          manufacturerName: '北大医药股份有限公司',
          approvalNo: 'H310216600'
        },
        {
          code: 1,
          name: '氯化钠注射液',
          spec: '0.5g*5支/盒',
          manufacturerName: '北大医药股份有限公司',
          approvalNo: 'H310216600'
        },
        {
          code: 1,
          name: '氯化钠注射液',
          spec: '0.5g*5支/盒',
          manufacturerName: '北大医药股份有限公司',
          approvalNo: 'H310216600'
        },
        {
          code: 1,
          name: '氯化钠注射液',
          spec: '0.5g*5支/盒',
          manufacturerName: '北大医药股份有限公司',
          approvalNo: 'H310216600'
        },
        {
          code: 1,
          name: '氯化钠注射液',
          spec: '0.5g*5支/盒',
          manufacturerName: '北大医药股份有限公司',
          approvalNo: 'H310216600'
        },
        {
          code: 1,
          name: '氯化钠注射液',
          spec: '0.5g*5支/盒',
          manufacturerName: '北大医药股份有限公司',
          approvalNo: 'H310216600'
        },
        {
          code: 1,
          name: '氯化钠注射液',
          spec: '0.5g*5支/盒',
          manufacturerName: '北大医药股份有限公司',
          approvalNo: 'H310216600'
        }
      ], // 机构表格数据
      platformTableData: [], // 平台表格数据
      PACK_COLUMN_LIST1: this.$deepClone(PACK_COLUMN_LIST1),
      PACK_COLUMN_LIST2: this.$deepClone(PACK_COLUMN_LIST2),
      PACK_COLUMN_LIST3: this.$deepClone(PACK_COLUMN_LIST3),
      PACK_COLUMN_LIST4: this.$deepClone(PACK_COLUMN_LIST4)
    }
  },
  watch: {
    PACK_COLUMN_LIST1: {
      handler (val) {
        console.log(val)
      },
      // 这里是关键，代表递归监听 demo 的变化
      deep: true
    }
  },
  async created() {
    this.searchObj.year = this.$moment(new Date()).format('YYYY')
    await this.getVersionHandle()
    await this.getOrgListHandle()
    this.platformTableData = []
    this.platformTableData.push(PLATFORM_DTAT_OBJ)
  },
  methods: {
    rowStyle({row, rowIndwx}) {
      let styleJson = {}     
      styleJson = {
        height: '40px'
      }
      return styleJson  // 返回对象
    },
    // 版本时间轴点击事件
    async onTimeLine(index, item) {
      this.instTableData = []
      this.platformTableData = []
      this.platformTableData.push(PLATFORM_DTAT_OBJ)
      this.total = 0
      await this.getOrgListHandle(item)
      this.currentVersion = item.version
      this.pushTime = item.pushTime
    },
    // 获取版本轴数据
    async getVersionHandle() {
      this.instTableData = []
      this.platformTableData = []
      this.platformTableData.push(PLATFORM_DTAT_OBJ)
      this.total = 0
      const obj = {
        month: this.searchObj.month,
        year: this.searchObj.year,
        quarterly: this.searchObj.quarterly
      }
      let res = await timelineApi(obj)
      if (res && res.code === 200) {
        let len = res.data ? res.data.length : 0
        this.timelineList = res.data ? res.data : []
        this.currentVersion = res.data.length > 0 ? res.data[len - 1].version : ''
        this.pushTime = res.data.length > 0 ? res.data[len - 1].pushTime : ''
      }
    },
    // 获取机构药品列表
    async getOrgListHandle(item) {
      const obj = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        searchValue: this.searchObj.searchValue,
        version: this.currentVersion
      }
      let res = await orgListApi(obj)
      if (res && res.code === 200) {
        this.instTableData = res.data.rows || []
        this.total = res.data.total
        // this.total = this.instTableData.length
      }
    },
    /* 药物组名称检索*/
    querySearchAsync(queryString, cb) {
      if (queryString && queryString.trim() !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          let queryData = {}
          queryData.queryStr = queryString.trim()
          selectDrugGroupNameList(queryData).then(response => {
            cb(response.data)
          })
        }, 200)
      } else {
        cb([])
      }
    },
    // 点击机构药品表格某一行获取平台标准药品数据
    async instRowClickHandle(row) {
      this.platformTableData = []
      this.isShowPlatformEmpty = false
      if (NO_MATCHED_LIST.some(item => item.name === row.mapShow)) {
        console.log('不')
        this.isShowPlatformEmpty = true
        return
      }
      columnHandle(row.mapShow)
      this.PACK_COLUMN_LIST1[0].label = ID_COLUMN[0].label
      this.PACK_COLUMN_LIST1[0].prop = ID_COLUMN[0].prop
      this.PACK_COLUMN_LIST1[0].isSplited = ID_COLUMN[0].isSplited
      this.PACK_COLUMN_LIST1[1].label = NAME_COLUMN[0].label
      this.PACK_COLUMN_LIST1[1].prop = NAME_COLUMN[0].prop

      this.PACK_COLUMN_LIST1[1].isSplited = NAME_COLUMN[0].isSplited
      this.$nextTick(() => {
        this.$refs.dynTable.doLayout()
      })
      console.log(PACK_COLUMN_LIST1)
      const obj = {
        drugId: row.drugId,
        version: this.currentVersion
      }
      let res = await platformDrugApi(obj)
      if (res && res.code === 200) {
        if (res.data) {
          this.platformTableData.push(res.data)
        }
      }
    },
    async handleSizeChange(val) {
      this.pageSize = val
      await this.getOrgListHandle()
    },
    async handleCurrentChange(val) {
      this.currentPage = val
      await this.getOrgListHandle()
    },
    async searchHandle() {
      this.isSearched = true
      await this.getOrgListHandle()
    },
    // 下载标准数据
    downloadHandle() {
      this.dialogVisible = true
      console.log(this.$refs)
      if (this.$refs['dialog-component']) {
        this.$refs['dialog-component'].dialogVisible = true
      }
    },
    searchTypeHandle(val) {
      if (val === 1) {
        // 年份
        this.searchObj.month = null
        this.searchObj.quarterly = null
      } else if (val === 2) {
        // 季度
        this.searchObj.year = null
        this.searchObj.month = null
      } else if (val === 3) {
        // 月份
        this.searchObj.year = null
        this.searchObj.quarterly = null
      }
    },
    async yearChangeHandle(val) {
      this.searchObj.quarterly = ''
      this.searchObj.month = ''
      await this.getVersionHandle()
    },
    async monthChangeHandle(val) {
      this.searchObj.quarterly = ''
      await this.getVersionHandle()
    },
    // 季度改变
    async quarterChangeHandle(obj) {
      console.log(obj)
      this.searchObj.year = obj.year
      this.searchObj.quarterly = obj.season
      this.searchObj.month = ''
      await this.getVersionHandle()
    }
  }
}
</script>

<style lang="scss" scoped>
.drug-storage-container {
  height: 100%;
  background-color: #EBEFF7;
  /* .el-main, .el-header, .el-footer {
    background: #fff;
  } */
  .title-header {
    padding: 0 !important;
    height: 36px !important;
    .table-title {
      margin: 0 !important;
      padding: 0 !important;
      height: 36px;
      line-height: 36px;
      font-size: 16px;
      font-weight: normal;
      text-align: center;
      width: 100%;
      &.inst-table-title {
        background-color: #FFEBD6;
        color: #E4860A;
      }
      &.platform-table-title {
        background-color: #D4FFCC;
        color: #49B812;
      }
    }
  }
  .drug-storage-header {
    font-size: 14px;
    margin: 10px;
    height: auto !important;
    padding: 0 !important;
    .header-top {
      background: #fff;
      display: flex;
      justify-content: space-between;
      padding-left: 15px;
      line-height: 50px;
      margin-bottom: 10px;
      .header-top-l {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
      }
      .header-top-r {
        margin-top: 6px;
        .el-date-editor.el-input, .el-date-editor.el-input__inner {
          width: 130px;
        }
        .el-form-item {
          margin-bottom: 0 !important;
          height: 36px;
          line-height: 36px;
        }
        .el-select {
          width: 90px;
          font-size: 14px;
          .el-input--suffix .el-input__inner {
            padding-left: 5px !important;
          }
        }
        .search-input {
          width: 260px !important;
          height: 36px !important;
          line-height: 36px !important;
          ::v-deep .el-input--suffix .el-input__inner {
            height: 36px !important;
            line-height: 36px !important;
            padding-right: 5px !important;
          }
        }
        .download-btn {
          background-color: #fff;
          &:hover, &:focus {
            background-color: #2B60F8;
            color: #fff;
          }
        }
      }
    }
    .header-bottom {
      background: #fff;
      height: 50px;
    }
  }
  .drug-storage-main {
    padding: 0 !important;
    .institution-table-wrap {
      width: 50% !important;
      padding: 0 10px 0 !important;
      margin: 0 !important;
      box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
      border-radius: 4px;
      .institution-table-container {
        background-color: #fff;
      }
      .insti-table {
        /* height: 100%; */
      }
    }
    .platform-table-wrap {
      padding: 0 10px 0px 0px !important;
      box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
      border-radius: 4px;
      .platform-table-container {
        background-color: #fff;
        .platform-table {
          border: 1px solid #CFDBFB;
          box-shadow: 0px 0px 10px 0px rgba(170, 192, 255, 0.8);
          border-radius: 4px;
          margin-bottom: 12px;
          &:last-child {
            margin-bottom: 0;
          }
          tbody {
            .el-table__row {
              height: 40px;
            }
          }
        }
        .platform-table-none {
          ::v-deep.el-table__body-wrapper {
            height: 100%;
          }
          ::v-deep.el-table__empty-block {
            min-height: 30px !important;
          }
        }
      }
    }
  }
}
</style>
