<template>
	<div class="add">
		<el-dialog
		:title="title"
		:visible.sync="dialogVisible"
		width="30%"
		center
    :close-on-click-modal="false"
		@close="handleClose">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
			<el-form-item label="ATC代码" prop="atcCode">
				<el-input maxlength="50" show-word-limit v-model="ruleForm.atcCode"></el-input>
			</el-form-item>
			<el-form-item label="分类英文名" prop="enName">
				<el-input  oninput="value=value.replace(/[\u4e00-\u9fa5]/g, '')" maxlength="50" show-word-limit v-model="ruleForm.enName"></el-input>
			</el-form-item>
			<el-form-item label="分类中文名" prop="chName">
				<el-input maxlength="200" show-word-limit v-model="ruleForm.chName"></el-input>
			</el-form-item>
			<div style="display: flex;">
				<el-form-item style="flex: 1" label="DDD值(WHO-ATC)" prop="dddWho">
					<!-- <el-input-number style="width:100%" v-model="ruleForm.dddWho" :min="0" :max="100000000" show-word-limit controls controls-position="right" :precision="2"/> -->
					<el-input :disabled="Boolean(ruleForm.dddC) || Boolean(ruleForm.dddCUnit)" oninput="value=(value.match(/(^(0|[1-9][0-9]*))+(\.[0-9]{0,3})?/g) ?? [''])[0]" maxlength="10" show-word-limit v-model="ruleForm.dddWho"></el-input>
				</el-form-item>
				<el-form-item class="dddWhoUnit" label="" prop="dddWhoUnit">
					<el-select :disabled="Boolean(ruleForm.dddC) || Boolean(ruleForm.dddCUnit)" style="width: 100px;margin-left: 5px;" clearable v-model="ruleForm.dddWhoUnit" placeholder="">
						<el-option
							v-for="item in unitList"
							:key="item.conceptId"
							:label="item.conceptVal"
							:value="item.conceptId">
						</el-option>
					</el-select>
				</el-form-item>
			</div>
			<!-- <el-form-item label="DDD值(C)" prop="dddC">
				<el-input v-model="ruleForm.dddC"></el-input>
			</el-form-item> -->
			<div style="display: flex;">
				<el-form-item style="flex: 1" label="DDD值(C)" prop="dddC">
					<!-- <el-input-number style="width:100%" v-model="ruleForm.dddC" :min="0" :max="100000000" show-word-limit controls controls-position="right" :precision="2"/> -->
					<el-input :disabled="Boolean(ruleForm.dddWho) || Boolean(ruleForm.dddWhoUnit)" oninput="value=(value.match(/(^(0|[1-9][0-9]*))+(\.[0-9]{0,3})?/g) ?? [''])[0]" maxlength="10" show-word-limit v-model="ruleForm.dddC"></el-input>
				</el-form-item>
				<el-form-item class="dddWhoUnit" label="" prop="dddCUnit">
					<el-select :disabled="Boolean(ruleForm.dddWho) || Boolean(ruleForm.dddWhoUnit)" style="width: 100px;margin-left: 5px;" clearable v-model="ruleForm.dddCUnit" placeholder="">
						<el-option
							v-for="item in unitList"
							:key="item.conceptId"
							:label="item.conceptVal"
							:value="item.conceptId">
						</el-option>
					</el-select>
				</el-form-item>
			</div>
			<el-form-item label="给药途径" prop="route">
				<el-select style="width: 100%" v-model="ruleForm.route" placeholder="请选择">
					<el-option
						v-for="item in useDrugList"
						:key="item.conceptId"
						:label="item.conceptVal"
						:value="item.conceptId">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="版本号" prop="version">
				<el-select style="width: 100%" v-model="ruleForm.version" placeholder="请选择">
					<el-option
						v-for="item in versionList"
						:key="item.version"
						:label="item.version"
						:value="item.version">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="备注" prop="remark">
				<el-input type="textarea" show-word-limit maxlength="200" :rows="3" v-model="ruleForm.remark"></el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="dialogVisible = false">取 消</el-button>
			<el-button type="primary" @click="confirm">确 定</el-button>
		</span>
	</el-dialog>
	</div>
</template>
<script>
import conceptConst from '@/const/conceptConst'
import { getConcept } from '@/utils/concept'
import {getVersionList, newAddList, getDDDinfo, editList, getAdminRouteList, getUnitList} from '../../../api/dkm/DDDApi'
export default {
	components: {
  },
	props: ['title', 'addVisible', 'isEdit', 'DDDid'],
  data() {
		var chNameValidatePass = (rule, value, callback) => {
				if(!value || value.length < 2) {
					callback(new Error('输入分类中文名且大于2字符'));
				} else {
					callback();
				}
		};
		var dddWhoValidatePass = (rule, value, callback) => {
			if (!this.ruleForm.dddWho && !this.ruleForm.dddC) {
				callback(new Error('DDD值至少输入一项'));
			} else {
				if(!this.ruleForm.dddWho && this.ruleForm.dddWhoUnit) {
					callback(new Error('输入DDD值(WHO-ATC)值'));
				}  else if(!this.ruleForm.dddC && !this.ruleForm.dddCUnit) {
					this.$refs.ruleForm.clearValidate('dddWho');
					this.$refs.ruleForm.clearValidate('dddC');
					callback();
				} else {
					callback();
				}
			}
		};
		var dddCvalidatePass = (rule, value, callback) => {
			if (!this.ruleForm.dddWho && !this.ruleForm.dddC) {
				callback(new Error('DDD值至少输入一项'));
			} else {
				if(!this.ruleForm.dddC && this.ruleForm.dddCUnit) {
					callback(new Error('输入DDD值(C)值'));
				} else if(!this.ruleForm.dddWho && !this.ruleForm.dddWhoUnit) {
					this.$refs.ruleForm.clearValidate('dddWho');
					this.$refs.ruleForm.clearValidate('dddC');
					callback();
				} else {
					callback();
				}
			}
		};
		var dddWhoUnitValidatePass = (rule, value, callback) => {
			if (this.ruleForm.dddWho) {
				if(!value) {
					callback(new Error('选择单位'));
				} else {
					callback();
				}
			} else {
				callback();
			}
		};
		var dddCUnitValidatePass = (rule, value, callback) => {
			if (this.ruleForm.dddC) {
				if(!value) {
					callback(new Error('选择单位'));
				} else {
					callback();
				}
			} else {
				callback();
			}
		};
    return {
			rules: {
				chName: [
					{ required: true, validator: chNameValidatePass, trigger: 'blur' }
				],
				route: [
					{ required: true, message: '请选择给药途径', trigger: 'change' },
				],
				dddWho: [
					{ validator: dddWhoValidatePass, trigger: 'blur' }
				],
				dddC: [
					{ validator: dddCvalidatePass, trigger: 'blur' }
				],
				version: [
					{ required: true, message: '请选择版本号', trigger: 'change' }
				],
				dddWhoUnit: [
					{ validator: dddWhoUnitValidatePass, trigger: 'change' }
				],
				dddCUnit: [
					{ validator: dddCUnitValidatePass, trigger: 'change' }
				],
			},
			dialogVisible: false,
			useDrugList: [],
			versionList: [],
			unitList: [],
			ruleForm: {
				atcCode: '',
				enName: '',
				chName: '',
				dddWho: undefined,
				dddC: undefined,
				route: '',
				version: '',
				remark: '',
				dddWhoUnit: '',
				dddCUnit: ''
			}
    }
  },
	watch: {
		addVisible(newVal) {
			this.dialogVisible = newVal
			if(newVal) {
				if(this.isEdit) {
					this.dddInfo()
				}
				this.getVersionList()
				this.giveDrug()
				this.getUnitList()
			}
		},
	},
  mounted() {
  },
  created() {
  },
  methods: {
		confirm() {
			this.$refs.ruleForm.validate( async (valid) => {
				if (valid) {
					const params = {
						atcCode: this.ruleForm.atcCode,
						chName: this.ruleForm.chName,
						dddC: this.ruleForm.dddC,
						dddCUnit: this.ruleForm.dddCUnit,
						dddWho: this.ruleForm.dddWho,
						dddWhoUnit: this.ruleForm.dddWhoUnit,
						enName: this.ruleForm.enName,
						remark: this.ruleForm.remark,
						route: this.ruleForm.route,
						version: this.ruleForm.version
					}
					if(this.isEdit) {
						params.id = this.DDDid
					}
					let res = this.isEdit ? await editList(params) : await newAddList(params)
					if(res.code == 200) {
						this.dialogVisible = false
						this.$refs.ruleForm.resetFields();
						this.$message({
							type: 'success',
							message: '新增成功!'
						})
						this.$emit('submit')
					}
				} else {
					return false;
				}
			})
		},
		async dddInfo() {
			const params = {
				id: this.DDDid,
			}
			let res = await getDDDinfo(params)
			if(res.code == 200) {
				this.ruleForm.atcCode = res.data.atcCode
				this.ruleForm.chName = res.data.chName
				this.ruleForm.dddC = res.data.dddC || undefined
				this.ruleForm.dddCUnit = res.data.dddCUnit
				this.ruleForm.dddWho = res.data.dddWho || undefined
				this.ruleForm.dddWhoUnit = res.data.dddWhoUnit
				this.ruleForm.enName = res.data.enName
				this.ruleForm.remark = res.data.remark
				this.ruleForm.route = res.data.route
				this.ruleForm.version = res.data.version
			}
		},
		async getVersionList() {
			let res = await getVersionList()
			if(res.code == 200) {
				this.versionList = res.data
			}
		},
		async getUnitList() {
			let res = await getUnitList()
			if(res.code == 200) {
				this.unitList = res.data
			}
			// await getConcept(conceptConst.PUB_MASS_UNIT, 0).then(result => {
      //   this.unitList = result
      // })
		},
		async giveDrug() {
			let res = await getAdminRouteList()
			if(res.code == 200) {
				this.useDrugList = res.data
			}
			// await getConcept(conceptConst.DKB_ADMINISTRATION_ROUTE, 0).then(result => {
      //   this.useDrugList = result
      // })
		},
		handleClose() {
			this.$refs.ruleForm.resetFields();
			this.$emit('closeAddForm')
		}
  }
}
</script>
<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
::v-deep .el-dialog__body {
	height: 500px;
	overflow-y: auto;
}
.add {
	.dddWhoUnit {
		::v-deep .el-form-item__content {
			margin-left: 0 !important;
		}
	}
}
</style>
