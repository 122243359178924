<template>
  <el-container class="historyUserMatch_wrap layout-container">
    <el-aside width="550px" class="historyUserMatch_left">
      <el-row>
        <el-col :span="20">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="未处理" name="0"></el-tab-pane>
            <el-tab-pane label="已处理" name="1"></el-tab-pane>
          </el-tabs>
        </el-col>
        <el-col :span="4" style="text-align: right;line-height: 54px;">
          <el-button type="text" @click="toRefreshLeftData">
              <i class="el-icon-refresh"></i>
              <span>刷新</span>
          </el-button>
        </el-col>
      </el-row>
      <el-row style="margin: 5px 0px 10px;">
        <el-col style="border-left:  5px solid #19B319;padding-left: 10px;line-height: 20px;">可能成为历史用户</el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-table
            :data="tableData"
            style="width: 100%"
            border
            stripe
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
            height="500"
            highlight-current-row
            @current-change="handleCurrentChange">
            <el-table-column
              prop="openId"
              label="OpenID"
              width="150">
            </el-table-column>
            <el-table-column
              prop="wxNumber"
              label="微信号">
            </el-table-column>
            <el-table-column
              prop="relationTypeStr"
              label="关联类型">
            </el-table-column>
<!--            <el-table-column-->
<!--              prop="notice"-->
<!--              label="通用标记"-->
<!--              width="80">-->
<!--              <template slot-scope="scope">-->
<!--                <span v-if="scope.row.notice === true"-->
<!--                  style="color:#6CCD3B;"-->
<!--                  >-->
<!--                  是-->
<!--                </span>-->
<!--                <span v-else>-->
<!--                  否-->
<!--                </span>-->
<!--              </template>-->
<!--            </el-table-column>-->
            <el-table-column label="操作" width="150" fixed="right">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  @click="editGroupInfoClick(scope.row)"
                >查看</el-button>
<!--                <el-button-->
<!--                  v-if="activeName=='0'"-->
<!--                  size="mini"-->
<!--                  type="text"-->
<!--                  style="color:#2D5AFA;"-->
<!--                  @click="leftMatchClick(scope.row)"-->
<!--                >匹配</el-button>-->
                <el-button
                  v-if="scope.row.notice === true && activeName=='0'"
                  size="mini"
                  type="text"
                  style="color:#FF3333;"
                  @click="notDealClick(scope.row)"
                >不处理</el-button>
<!--                <el-button-->
<!--                  v-if="activeName=='1'"-->
<!--                  size="mini"-->
<!--                  type="text"-->
<!--                  style="color:#2D5AFA;"-->
<!--                  @click="reMatchClick(scope.row)"-->
<!--                >重新匹配</el-button>-->
                <el-button
                  v-if="activeName=='1'"
                  size="mini"
                  type="text"
                  style="color:#FF3333;"
                  @click="rebackDealClick(scope.row)"
                >撤销处理</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <pagination
            v-show="originListTotal>0"
            :total="originListTotal"
            :page.sync="originListQuery.pageNum"
            :limit.sync="originListQuery.pageSize"
            @pagination="getOriginList"
          />
        </el-col>
      </el-row>
    </el-aside>
    <el-main class="historyUserMatch_right">
      <el-row style="margin: 5px 0px 10px;">
        <el-col style="border-left:  5px solid #19B319;padding-left: 10px;line-height: 20px;">历史用户信息 </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-table
            :data="nowTableData"
            style="width: 100%"
            border
            stripe
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}">
            <el-table-column
              prop="openId"
              label="现匹配OpenID"
              width="120">
            </el-table-column>
            <el-table-column
              prop="nickName"
              label="现匹配昵称">
            </el-table-column>
          </el-table>
        </el-col>
        <el-form :label-position="labelPosition" label-width="90px" :model="historyListQuery">
        <el-col :span="6">
          <el-row>
            <el-col>
              <el-form-item label="微信号/昵称">
                <el-input v-model="historyListQuery.wxNumber" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="真实姓名">
                <el-input v-model="historyListQuery.realName" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6">
          <el-row>
            <el-col>
              <el-form-item label="医院">
                <el-input v-model="historyListQuery.hospitalName" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="地区">
                <el-input v-model="historyListQuery.region" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="4" style="line-height: 60px;padding-left: 10px;">
          <el-button style="background:#19B319;color: #FFFFFF;width: 100px;" size="small" @click="getHistoryList()">查询</el-button>
        </el-col>
        </el-form>
      </el-row>
      <el-row style="margin-top: 10px;">
        <el-col>
          <el-table
            :data="tableData02"
            style="width: 100%"
            border
            stripe
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
            height="500">
            <el-table-column
              prop="id"
              label="序号"
              width="60">
            </el-table-column>
            <el-table-column
              prop="nickName"
              label="昵称"
              width="120">
            </el-table-column>
            <el-table-column
              prop="userName"
              label="微信号"
              width="120">
            </el-table-column>
            <el-table-column
              prop="realName"
              label="真实姓名"
              width="120">
            </el-table-column>
            <el-table-column
              prop="hospitalName"
              label="医院"
              width="200">
            </el-table-column>
            <el-table-column
              prop="region"
              label="所在地">
            </el-table-column>
            <el-table-column label="操作" width="80" fixed="right">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  style="color:#2D5AFA;"
                  @click="rightMatchClick(scope.row)"
                >确定匹配</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <pagination
            v-show="historyListTotal>0"
            :total="historyListTotal"
            :page.sync="historyListQuery.pageNum"
            :limit.sync="historyListQuery.pageSize"
            @pagination="getHistoryList"
          />
        </el-col>
      </el-row>
    </el-main>
    <!--   查看用户信息-->
    <el-dialog title="查看用户信息" :visible.sync="isHistoryGroupInfo" width="500px" append-to-body class="approvalWrap">
      <div style="line-height: 40px!important;" id="lookInfo">
        <el-row>
          <el-col style="border-left: 5px solid #19B319;padding-left: 10px;line-height: 20px;">个人信息</el-col>
        </el-row>
        <el-row class="personalInfo">
          <el-col :span="6" class="personalInfo_label">
            真实姓名
          </el-col>
          <el-col :span="18" class="personalInfo_content">
            {{ originShowBean.realName }}
          </el-col>
        </el-row>
        <el-row class="personalInfo">
          <el-col :span="6" class="personalInfo_label">
            所在地区
          </el-col>
          <el-col :span="18" class="personalInfo_content">
            {{ originShowBean.location }}
          </el-col>
        </el-row>
        <el-row class="personalInfo">
          <el-col :span="6" class="personalInfo_label">
            所在单位
          </el-col>
          <el-col :span="18" class="personalInfo_content">
            {{ originShowBean.hospitalName }}
          </el-col>
        </el-row>
        <el-row class="personalInfo">
          <el-col :span="6" class="personalInfo_label">
            职业
          </el-col>
          <el-col :span="18" class="personalInfo_content">
            {{ originShowBean.occupationName }}
          </el-col>
        </el-row>
        <el-row class="personalInfo">
          <el-col :span="6" class="personalInfo_label">
            职称
          </el-col>
          <el-col :span="18" class="personalInfo_content">
            {{ originShowBean.titleName }}
          </el-col>
        </el-row>
        <el-row class="personalInfo">
          <el-col :span="6" class="personalInfo_label">
            工牌
          </el-col>
          <el-col :span="18" class="personalInfo_content">
            <el-image style="width: 210px;height: 130px;border-radius: 10px;"
                      :src="originShowBean.workCardUrl"
                      :fit="fit">
              <div slot="error" class="image-slot"
                   style="width: 210px;height: 130px;line-height:130px; text-align:center;border-radius: 10px;background: #F2F2F2;">
                <i class="el-icon-picture-outline"
                   style="color: #999999;font-size: 12px;">
                  暂无工牌
                </i>
              </div>
            </el-image>
          </el-col>
        </el-row>
        <el-row class="personalInfo">
          <el-col :span="6" class="personalInfo_label">
            主攻方向
          </el-col>
          <el-col :span="18" class="personalInfo_content" v-if="originShowBean.mainDirectionStrList">
            <ul class="zgfx_ul"
                style="float: left"
                v-for="item in originShowBean.mainDirectionStrList">
              <li class="zgfx_li"
                style="display: inline-block;
                padding:0px 5px;
                height: 30px;
                line-height: 30px;
                background: #FAEBFF;
                border: 1px dashed #CF75EB;
                text-align: center;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 400;
                color: #CF75EB;
                margin-right: 5px;">{{ item }}</li>
            </ul>
          </el-col>
        </el-row>
        <el-row>
          <el-col style="border-left: 5px solid #19B319;padding-left: 10px;line-height: 20px;">匹配历史用户信息</el-col>
        </el-row>
        <el-row class="personalInfo">
          <el-col :span="6" class="personalInfo_label">
            序号
          </el-col>
          <el-col :span="18" class="personalInfo_content" v-if="historyShowBean">
            {{ historyShowBean.id }}
          </el-col>
        </el-row>
        <el-row class="personalInfo">
          <el-col :span="6" class="personalInfo_label">
            真实姓名
          </el-col>
          <el-col :span="18" class="personalInfo_content" v-if="historyShowBean">
            {{ historyShowBean.realName }}
          </el-col>
        </el-row>
        <el-row class="personalInfo">
          <el-col :span="6" class="personalInfo_label">
            所在地区
          </el-col>
          <el-col :span="18" class="personalInfo_content" v-if="historyShowBean">
            {{ historyShowBean.location }}
          </el-col>
        </el-row>
        <el-row class="personalInfo">
          <el-col :span="6" class="personalInfo_label">
            所在单位
          </el-col>
          <el-col :span="18" class="personalInfo_content" v-if="historyShowBean">
            {{ historyShowBean.hospitalName }}
          </el-col>
        </el-row>
        <el-row class="personalInfo">
          <el-col :span="6" class="personalInfo_label">
            职业
          </el-col>
          <el-col :span="18" class="personalInfo_content" v-if="historyShowBean">
            {{ historyShowBean.occupationName }}
          </el-col>
        </el-row>
        <el-row class="personalInfo">
          <el-col :span="6" class="personalInfo_label">
            职称
          </el-col>
          <el-col :span="18" class="personalInfo_content" v-if="historyShowBean">
            {{ historyShowBean.titleName }}
          </el-col>
        </el-row>
        <el-row class="personalInfo">
          <el-col :span="6" class="personalInfo_label">
            主攻方向
          </el-col>
          <el-col :span="18" class="personalInfo_content" v-if="historyShowBean">
            {{ historyShowBean.medicalDirectionName }}
          </el-col>
<!--          <el-col :span="18" class="personalInfo_content" v-if="historyShowBean.mainDirectionStrList">-->
<!--            <ul class="zgfx_ul"-->
<!--                style="float: left"-->
<!--                v-for="item in historyShowBean.mainDirectionStrList">-->
<!--              <li class="zgfx_li"-->
<!--                  style="display: inline-block;-->
<!--                padding:0px 5px;-->
<!--                height: 30px;-->
<!--                line-height: 30px;-->
<!--                background: #FAEBFF;-->
<!--                border: 1px dashed #CF75EB;-->
<!--                text-align: center;-->
<!--                border-radius: 4px;-->
<!--                font-size: 14px;-->
<!--                font-weight: 400;-->
<!--                color: #CF75EB;-->
<!--                margin-right: 5px;">{{ item }}</li>-->
<!--            </ul>-->
<!--          </el-col>-->
        </el-row>
        <el-row class="personalInfo">
          <el-col :span="6" class="personalInfo_label">
            群信息
          </el-col>
          <el-col :span="18" class="personalInfo_content" v-if="historyShowBean && historyShowBean.groupStrList">
            <ul class="zgfx_ul"
                style="float: left"
                v-for="item in historyShowBean.groupStrList">
              <li
                  style="display: inline-block;
                padding:0px 5px;
                height: 30px;
                line-height: 30px;
                /*background: #FAEBFF;*/
                /*border: 1px dashed #CF75EB;*/
                text-align: center;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 400;
                color: #19B319;
                margin-right: 5px;">{{ item }}</li>
            </ul>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </el-container>
</template>
<script>
import {
  historyList,
  originList,
  match,
  compare
} from "@/api/frp/historyUserMatch";
export default {
  name: "historyUserMatch",
  data() {
    return {
      fit: 'fill',
      isHistoryGroupInfo: false,
      originShowBean: {}, // 显示个人信息
      historyShowBean: {},
      activeName: '0', //0代表未处理，1代表已处理，默认未处理
      originListQuery: {
        pageNum: 1,
        pageSize: 10,
        hasMatch: null
      },
      originListTotal: 0,
      historyListQuery: {
        hospitalName: null,
        wxNumber: null,
        region: null,
        realName: null,
        pageNum: 1,
        pageSize: 10,
      },
      historyListTotal: 0,
      nowTableData: [],
      tableData: [],
      tableData02: [],
      labelPosition: 'right',
      formLabelAlign: {
        name: '',
        region: '',
        type: ''
      },
      originOpenId: '',
      historyId: '',
    }
  },
  created() {
    this.getOriginList()
  },
  methods: {
    // 刷新页面
    toRefreshLeftData() {
      this.tableData = []
      this.originListQuery.pageNum = 1
      this.originListQuery.pageSize = 10
      this.getOriginList()
    },
    handleClick(tab, event) {
      this.activeName = tab.index
      this.tableData = []
      this.originListQuery.pageNum = 1
      this.originListQuery.pageSize = 10
      this.getOriginList()
    },
    addressChange(code) {
      console.log(code)
    },
    /** 可能为历史用户的列表 */
    async getOriginList() {
      this.loading = true;
      if (this.activeName === '1') {
        this.originListQuery.hasMatch = true
      } else {
        this.originListQuery.hasMatch = false
      }
      originList(this.originListQuery).then(
        response => {
          this.tableData = response.data.list
          this.originListTotal = response.data.totalCount
          this.loading = false
        }
      );
    },
    /** 查询历史用户列表 */
    getHistoryList() {
      this.loading = true;
      historyList(this.historyListQuery).then(
        response => {
          this.tableData02 = response.data.list
          this.historyListTotal = response.data.totalCount
          this.loading = false
        }
      );
    },
    // 查看个人信息
    async editGroupInfoClick(row) {
      let obj = {}
      obj.openId = row.openId
      // obj.historyType = 1
      let res = await compare(obj)
      if (res.code === 200) {
        this.originShowBean = res.data.originShowBean
        this.originShowBean.workCardUrl = process.env.VUE_APP_WEIXIN_SERVER + "/thys-api" + res.data.originShowBean.workCardUrl
        this.isHistoryGroupInfo = true
        this.historyShowBean = res.data.historyShowBean
      }
    },
    handleCurrentChange(val) {
      this.nowTableData = []
      this.nowTableData.push(val)
      this.originOpenId = val.openId
    },
    // leftMatchClick(row) {
    //   this.originOpenId = row.openId
    // },
    // 不处理
    async notDealClick(row) {
      let obj = {}
      obj.originOpenId = row.openId
      obj.type = 2
      let res = await match(obj)
      if (res.code === 200) {
        this.getOriginList()
        this.msgSuccess("不处理操作完成！");
      }
    },
    // 重新匹配
    async reMatchClick(row) {
      let obj = {}
      obj.originOpenId = row.openId
      obj.type = 3
      let res = await match(obj)
      if (res.code === 200) {
        this.getOriginList()
        this.msgSuccess("重新匹配完成！");
      }
    },
    // 撤销匹配
    async rebackDealClick(row) {
      let obj = {}
      obj.originOpenId = row.openId
      obj.type = 4
      let res = await match(obj)
      if (res.code === 200) {
        this.getOriginList()
        this.msgSuccess("撤销匹配完成！");
      }
    },
    rightMatchClick(row) {
      this.historyId = row.id
      this.matchFn()
    },
    //match
    async matchFn() {
      let obj = {}
      obj.historyId = this.historyId
      obj.originOpenId = this.originOpenId
      obj.type = 1
      let res = await match(obj)
      if (res.code === 200) {
        this.tableData02 = []
        this.historyListQuery.pageNum = 1
        this.historyListQuery.pageSize = 10
        this.getOriginList()
        this.msgSuccess("匹配完成！");
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .historyUserMatch_wrap{
    background-color: #EBEFF7;
    display: flex;
    height: 100%;
    aside{
      padding: 0px!important;
      margin:0px!important;
    }
    main{
      padding: 0px!important;
      margin:0px!important;
    }
    header{
      padding: 0px!important;
      margin:0px!important;
    }
    .historyUserMatch_left{
      padding:10px!important;
      margin: 10px 5px 0px 10px!important;
      box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
      border-radius: 8px;
      background: #FFFFFF;
    }
    .historyUserMatch_right{
      background: #FFFFFF;
      border-radius: 8px;
      margin: 10px 10px 0px 5px!important;
      box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
      padding:10px!important;
    }
  }
</style>
<style lang="scss">
.historyUserMatch_wrap{
  .el-tabs__item.is-active{
    color: #6CCD3B;
  }
  .el-tabs__active-bar{
    background-color: #6CCD3B;
  }
  .el-tabs__item:hover{
    color: #6CCD3B;
  }
  .el-form-item {
    margin-bottom: 5px;
  }
}
#lookInfo{
  height: 450px!important;
  overflow: auto;
  .personalInfo{
    width: 100%;
    padding:3px 0px;
    .personalInfo_label{
      text-align: right;
      font-size: 14px;
      font-weight: 400;
      color: #888888;
    }
    .personalInfo_content{
      text-indent: 3px;
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      .ipAddress{
        display: inline-block;
        text-indent: 3px;
        font-size: 14px;
        font-weight: 400;
        color: #888888;
      }
      .zgfx_ul{
        float: left;
        .zgfx_li{
          display: inline-block;
          //width: 50px;
          padding:0px 5px;
          height: 30px;
          line-height: 30px;
          background: #FAEBFF;
          border: 1px dashed #CF75EB;
          text-align: center;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 400;
          color: #CF75EB;
          margin-right: 5px;
        }
      }
    }
  }
}

</style>
