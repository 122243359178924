<!--查看药品匹配-->
<template>
  <el-container>
    <drug-match-component from="mappingMaintenance" />
  </el-container>
</template>
<script>
  import DrugMatchComponent from '@/views/dm/drugMetadataMatching/mapping'
  export default {
    name: 'ViewMapping',
    components: {
      DrugMatchComponent
    },
    data() {
      return {}
    }
  }
</script>