<template>
  <div class="app-container" style="overflow: auto" v-loading="loading">


    <div ref="headerQuery">
      <el-row class="headerClass">
        <el-col :span="4" style="border-left: 5px solid #0073E9;padding-left: 10px;">上传记录</el-col>
        <el-col :span="20">
          <el-form :model="queryParams" ref="queryForm" :inline="true" style="float: right">

            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">检索</el-button>
              <el-button icon="el-icon-upload2" size="small" class="addClass" @click="handleUpload" >上传数据</el-button>
              <el-button icon="fa fa-cog" size="small" @click="columnSettings">设置</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>


    <div ref="tableContainer">
      <el-table
        border
        stripe
        ref="refundTable"
        :data="uploadList"
        :height="tableHeight"
        v-loading="loading"
        @sort-change="tableSortChange"
        @row-contextmenu="rowContextmenu"
      >
        <el-table-column
          v-for="data in columnOptions"
          :key="'columnOptions'+data.id"
          :prop="data.prop"
          :label="data.name"
          :fixed="data.fixed"
          :resizable="false"
          :sortable="data.sortable"
          :min-width="data.width"
          :align="data.align"
        >
          <template slot-scope="scope">
            <div v-if="data.prop == 'fileList'">
              <p>{{ scope.row.ypjcxxFileName }}{{ '(' + scope.row.ypjcxxFileRows + ')' }}</p>
              <p>{{ scope.row.storeFileName }}{{ '(' + scope.row.storeFileRows + ')' }}</p>
              <p>{{ scope.row.hospitalFileName }}{{ '(' + scope.row.hospitalFileRows + ')' }}</p>
            </div>
            <div v-else-if="data.prop == 'dataDetail'">
              <el-tag type="info" style="width: 100px;margin: 0px 4px 4px 0px;">无变化:{{
                  scope.row.noChangeSize
                }}
              </el-tag>
              <el-tag style="width: 100px;">修改:{{ scope.row.updateSize }}</el-tag>
              <br>
              <el-tag type="success" style="width: 100px;margin: 0px 4px 4px 0px;">新增:{{ scope.row.addSize }}</el-tag>
              <el-tag type="danger" style="width: 100px;">删除:{{ scope.row.delSize }}</el-tag>
            </div>
            <div v-else-if="data.prop == 'status'">
              <el-tag type="success" v-show="scope.row.status==2">成功</el-tag>
              <el-tag type="danger" v-show="scope.row.status==-1">失败</el-tag>
              <el-tag type="warning" v-show="scope.row.status==1">运行中</el-tag>
              <br>
              <span v-show="scope.row.status==-1">{{scope.row.errorMsg}}</span>
            </div>

            <div v-else>
              <span>{{ scope.row[data.prop] }}</span>
            </div>
          </template>
        </el-table-column>

        <template slot="empty" v-if="uploadList.length==0">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
    </div>

    <table-context-menu v-if="tableContextMenuVisible"
                        @foo="foo" ref="contextbutton"
                        :action="tableAction"
                        @handleTableAction="handleTableAction"
    ></table-context-menu>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="selectUploadRecordList"
      :page-sizes="[10, 20, 50, 100]"
    />


    <el-dialog v-dialogDrag title="数据处理进度" width="70%" append-to-body :visible.sync="progressDialogVisible"
               :close-on-click-modal="false" :show-close="progressDialogShowClose"
               @close="handleQuery"
    >
      <el-steps :active="progress.active" align-center>
        <el-step title="步骤1 开始" :description="progress.step1Desc"></el-step>
        <el-step title="步骤2 下载国家医保数据" :description="progress.step2Desc"></el-step>
        <el-step title="步骤3 与上个版本差异比较" :description="progress.step3Desc"></el-step>
        <el-step title="步骤4 生成差异报告" :description="progress.step4Desc"></el-step>
        <el-step title="步骤5 完成" :description="progress.step5Desc"></el-step>
      </el-steps>

      <p style="color: red">{{ progress.errorMsg }}</p>
    </el-dialog>



  </div>
</template>

<script>

import conceptConst from '@/const/conceptConst'
import AcrossUtil from '@/utils/acrossUtil'
import { UCgetConfig } from '@/utils/columnSettings'
import { GLOBAL_CONFIGURATION_CONFIGKEY } from '@/utils/constants'
import { isEmpty, axiosDownload,getPercent } from '@/utils/common'
import { uploadRecordList,delVersion,getProgress,importNhsaDrug,downloadReport } from '@/api/assistTool/nhsaDrug'
import { formatDate } from '@/utils/index'
import { DEEP_CLONE } from '../../../utils/constants'

export default {
  name: 'NhsaDrug',
  components: {},
  data() {
    return {
      conceptConst,
      /*定时器*/
      timer: '',
      // 遮罩层
      loading: true,
      uploadList: [],
      // 总条数
      total: 0,
      // 表格高度
      tableHeight: window.innerHeight - 100 - 100,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        searchValue: null,
        orderByColumn: undefined,
        isAsc: undefined
      },
      sltConfigKey: '',
      sltConfigClassify: '',
      columnOptions: [],
      tableOptions: [
        {
          id: 1,
          prop: 'versionNo',
          name: '版本号',
          fixed: false,
          show: true,
          sortable: 'custom',
          align: 'center',
          width: '100'
        },
        {
          id: 2,
          prop: 'uploadTime',
          name: '上传时间',
          fixed: false,
          show: true,
          sortable: 'custom',
          align: 'center',
          width: '120'
        },
        {
          id: 3,
          prop: 'batchNumber',
          name: '批次号',
          fixed: false,
          show: true,
          align: 'center',
          width: '200'
        },
        {
          id: 6,
          prop: 'totalSize',
          name: '总数据量',
          fixed: false,
          show: true,
          align: 'center',
          width: '80'
        },
        {
          id: 7,
          prop: 'dataDetail',
          name: '更新明细',
          fixed: false,
          show: true,
          align: 'center',
          width: '200'
        },
        {
          id: 8,
          prop: 'status',
          name: '状态',
          fixed: false,
          show: true,
          align: 'center',
          width: '60'
        },
        {
          id: 9,
          prop: 'elapsedTime',
          name: '执行耗时(分钟)',
          fixed: false,
          show: true,
          align: 'center',
          width: '60'
        }

      ],
      /*表格右键菜单显示隐藏*/
      tableContextMenuVisible: false,
      tableAction: [{ name: '查看明细' }, { name: '下载差异比较报告' }, { name: '删除当前版本数据', style: 'color: #FF3333;' }],
      progressDialogVisible:false,
      progressDialogShowClose:false,
      progress:{
        active: 0,
        step1Desc: '',
        step2Desc: '',
        step3Desc: '',
        step4Desc: '',
        step5Desc: '',
        versionNo: undefined,
        startTime: undefined,
        stopTime: undefined,
        runStatus: 0,
        drugRows: 0,
        drugTaskSize: 0,
        successDrugTaskSize: 0,
        drugTaskRunStatus: 0,
        compareRows: 0,
        compareTaskSize: 0,
        successCompareTaskSize: 0,
        compareTaskRunStatus: 0,
        createReportRunStatus:0,
        createReportFinishRows:0,
        errorMsg: ''
      }


    }
  },
  created() {
    let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 53)
    this.sltConfigKey = item.configKey
    this.sltConfigClassify = item.configClassify
    this.getSettingTable().then(() => {
      this.$nextTick(() => {
        this.setTableHeight()
        //页面初始化完毕，默认触发一次按照更新时间排序
        this.$refs.refundTable.sort('versionNo', 'descending')
      })
    })

  },
  activated() {
    //激活时重新设置表格高度
    this.setTableHeight()
  },
  mounted() {
    var _this = this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      AcrossUtil.$emit('resetTableHeight')
    }

    AcrossUtil.$on('resetTableHeight', function(param) {
      setTimeout(function() {
        _this.setTableHeight()
      }, 300)
    })

    this.startTimer()

  },
  methods: {
    /*设置表格的高度*/
    setTableHeight() {
      // 获取高度值 （内容高+padding+边框）
      let formHeight = this.$refs.headerQuery.offsetHeight
      this.tableHeight = window.innerHeight - formHeight - 150
    },
    // 设置按钮-获取设置的数据
    async getSettingTable() {
      let _this = this
      let obj = {}
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 53)
      this.sltConfigKey = item.configKey // -表格配置
      this.sltConfigClassify = item.configClassify // 配置分类
      obj.configKey = this.sltConfigKey
      this.columnOptions = []
      let res = await UCgetConfig(obj)
      if (res.data && res.data.configValue) {
        let list = JSON.parse(res.data.configValue).list
        if (list.length > 0) {
          list.forEach(function(item) {
            if (item.show == undefined || item.show == true) {
              _this.columnOptions.push(item)
            }
          })
        } else {
          _this.msgError('获取数据表格配置信息失败')
        }
      } else {
        let defaultConfig = this.tableOptions
        defaultConfig.forEach(function(item) {
          if (item.show == undefined || item.show == true) {
            _this.columnOptions.push(item)
          }
        })
      }
      _this.$nextTick(() => {
        _this.$refs.refundTable.doLayout()
      })
    },
    // 设置
    async columnSettings() {
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 51)
      this.sltConfigKey = item.configKey
      this.sltConfigClassify = item.configClassify
      let _this = this
      _this.$ColumnSettings.showColSettings(
        _this.sltConfigKey,
        _this.sltConfigClassify,
        _this.tableOptions,
        _this.getSettingTable
      )
    },
    /*鼠标右键点击事件*/
    rowContextmenu(row, column, event) {
      this.tableContextMenuVisible = false
      this.tableContextMenuVisible = true
      // 阻止右键默认行为
      event.preventDefault()
      this.$nextTick(() => {
        this.$refs.contextbutton.init(row, column, event)
      })
    },
    /* 取消鼠标监听事件 菜单栏*/
    foo() {
      this.tableContextMenuVisible = false
      document.removeEventListener('click', this.foo)
    },
    /* 处理表格右键菜单点击*/
    handleTableAction(actionName, row, column) {
      if (actionName === '查看明细') {
        this.handleViewDetail(row)
      } else if (actionName === '删除当前版本数据') {
        this.handleDel(row)
      } else if (actionName === '下载差异比较报告') {
        this.handleDownload(row.versionNo)
      }
    },
    /*下载差异比较报告*/
    handleDownload(versionNo) {
      let fileName= '-国家医保药品差异比较报告.xlsx'
      this.$confirm('是否确认下载版本[' + versionNo + ']' + fileName + '?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(function() {
        fileName = versionNo + fileName
        downloadReport(versionNo, 1).then(res => {
          axiosDownload(fileName, res)
        })
      })
    },
    /*删除版本数据*/
    handleDel(row) {
      let versionNo = row.versionNo
      let _this = this
      this.$confirm('确认删除版本[' + versionNo + ']数据?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        _this.loading = true
        delVersion(row.versionNo).then(res => {
          _this.handleQuery()
          _this.loading = false
        })
      })

    },
    /*查看明细*/
    handleViewDetail(row) {
      this.$store.dispatch('tagsView/delCachedView', { name: 'NhsaDrugList' }).then(res => {
        this.$router.push({
          path: '/dkm/nhsaDrugList',
          query: {
            versionNo: row.versionNo
          }
        })
      })
    },
    /** 查询上传记录列表 */
    selectUploadRecordList() {
      this.loading = true
      uploadRecordList(this.queryParams).then(response => {
        this.uploadList = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.isResult = true
      this.queryParams.pageNum = 1
      this.selectUploadRecordList()
    },
    /*上传按钮操作*/
    handleUpload(){
      this.$prompt('请输入批次号(示例值:20210322)', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '批次号格式不正确，批次号应为数字格式'
      }).then(({ value }) => {
        importNhsaDrug(value).then(res=>{

          if(res.code==200){
            this.startTimer()
            this.initProgress()
            this.progressDialogVisible=true
          }
        })

      }).catch(() => {
      });
    },
    /*表格排序切换*/
    tableSortChange({ column, prop, order }) {
      if (order != null) {
        order = order == 'descending' ? 'desc' : 'asc'
      } else {
        order = ''
      }
      this.queryParams.orderByColumn = prop
      this.queryParams.isAsc = order
      this.handleQuery()
    },




    /*启动定时器--定时刷新获取进度*/
    startTimer() {
      this.stopTimer()
      this.timer = setInterval(this.getUploadProgress, 2000)
    },
    /* 关闭定时器*/
    stopTimer() {
      clearTimeout(this.timer)
    },
    initProgress(){
      this.progress={
        active: 0,
          step1Desc: '',
          step2Desc: '',
          step3Desc: '',
          step4Desc: '',
          step5Desc: '',
          versionNo: undefined,
          startTime: undefined,
          stopTime: undefined,
          runStatus: 0,
          drugRows: 0,
          drugTaskSize: 0,
          successDrugTaskSize: 0,
          drugTaskRunStatus: 0,
          compareRows: 0,
          compareTaskSize: 0,
          successCompareTaskSize: 0,
          compareTaskRunStatus: 0,
          createReportRunStatus:0,
          createReportFinishRows:0,
          errorMsg: ''
      }
    },
    /*获取上传进度*/
    getUploadProgress() {
      getProgress().then(res => {
        if (res.data) {
          this.progress = DEEP_CLONE(res.data)
          let progress=this.progress
          //progress.active
          if (progress.startTime) {
            progress.step1Desc = formatDate(progress.startTime)
          }
          if (progress.stopTime) {
            progress.step5Desc = formatDate(progress.stopTime)
          }
          progress.step2Desc = (progress.successDrugTaskSize || 0) + '/' + (progress.drugTaskSize || 0)
            +'('+getPercent(progress.successDrugTaskSize,progress.drugTaskSize)+')'
          progress.step3Desc = (progress.successCompareTaskSize || 0) + '/' + (progress.compareTaskSize || 0)
            +'('+getPercent(progress.successCompareTaskSize,progress.compareTaskSize)+')'
          progress.step4Desc = (progress.createReportFinishRows || 0) + '/' + (progress.compareRows || 0)
            +'('+getPercent(progress.createReportFinishRows,progress.compareRows)+')'
          //进度弹窗--隐藏关闭按钮
          this.progressDialogShowClose = false
          if (progress.runStatus == 2) {
            //执行到步骤7 执行成功
            progress.active = 5
            this.progressDialogShowClose = true
            this.progressDialogVisible=true
            this.stopTimer()
          } else if (progress.runStatus == -1) {
            //执行失败
            this.progressDialogShowClose = true
            this.progressDialogVisible=true
            this.stopTimer()
          }else if (progress.createReportRunStatus == 1) {
            //生成差异报告
            progress.active = 4
            this.progressDialogVisible=true
          }else if (progress.compareTaskRunStatus == 1) {
            //差异比较
            progress.active = 3
            this.progressDialogVisible=true
          }else if (progress.drugTaskRunStatus == 1) {
            //下载国家医保数据
            progress.active = 2
            this.progressDialogVisible=true
          }else if (progress.runStatus == 1) {
            //开始执行
            progress.active = 1
            this.progressDialogVisible=true
          }else{
            //初始状态
            this.progressDialogShowClose = true
            progress.active = 0
            this.stopTimer()
          }


        }
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.el-table .cell .el-form-item {
  margin-bottom: 0px;
}

</style>

<style lang="scss">

#uploadFilesDiv .el-input__inner {
  color: #2B60F8;
}

</style>
