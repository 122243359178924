<template>
  <div class="app-container">
    <div ref="headerQuery">
      <el-row class="headerClass">
        <el-col :span="4" style="border-left: 5px solid #0073E9;padding-left: 10px;">厂商维护</el-col>
        <el-col :span="20">
          <el-form :model="queryParams" ref="queryForm" :inline="true" style="float: right">
            <el-form-item label="" prop="name">
              <el-autocomplete
                v-model="queryParams.searchValue"
                :fetch-suggestions="queryManufacturerName"
                placeholder="请输入厂商ID/名称/别名/编号/统一社会信用代码"
                @select="handleQuery"
                @clear="setBlur()"
                clearable
                size="small"
                value-key="name"
                style="width: 320px;height: 36px!important;line-height: 36px;"
                @keydown.enter.native="handleQuery"
              ></el-autocomplete>
            </el-form-item>

            <el-form-item label="状态" prop="status">
              <el-select
                v-model="queryParams.status"
                placeholder="状态"
                clearable
                size="small"
                class="statusClass"
                style="width: 100px"
              >
                <el-option
                  v-for="dict in statusOptions"
                  :key="'statusOptions'+dict.conceptId"
                  :label="dict.conceptVal"
                  :value="dict.conceptId"
                />
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">检索</el-button>
              <el-button icon="el-icon-circle-plus-outline" size="small" class="addClass" @click="handleAdd">新增厂商
              </el-button>
              <el-button icon="fa fa-cog" size="small" @click="columnSettings">设置</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>


    <div ref="tableContainer">
      <el-table
        border
        stripe
        ref="refundTable"
        :data="manufacturerList"
        :height="tableHeight"
        v-loading="loading"
        @sort-change="tableSortChange"
        @row-contextmenu="rowContextmenu"
      >
        <el-table-column
          v-for="data in columnOptions"
          :key="'columnOptions'+data.id"
          :prop="data.prop"
          :label="data.name"
          :fixed="data.fixed"
          :resizable="false"
          :sortable="data.sortable"
          :min-width="data.width"
          :align="data.align"
        >
          <template slot-scope="scope">
            <div v-if="data.prop == 'aliasList'">
              <P v-for="(item,index) in scope.row.aliasList" :key="item.id+'aliasList'">
                {{ '[' + (index + 1) + ']' }}{{ item.alias }}<span v-show="index<scope.row.aliasList.length-1">;</span>
              </P>
            </div>
            <div v-else-if="data.prop == 'labelList'">
              <P v-for="(item,index) in scope.row.labelList" :key="item.id+'labelList'">
                {{ '[' + (index + 1) + ']' }}{{ item.label }}<span v-show="index<scope.row.labelList.length-1">;</span>
              </P>
            </div>
            <div v-else-if="data.prop == 'manufacturerCode'">
              <P v-for="(item,index) in scope.row.codeList" :key="item.id+'codeList'">
                {{ '[' + (index + 1) + ']' }}{{ item.manufacturerCode }}<span
                v-show="index<scope.row.codeList.length-1"
              >;</span>
              </P>
            </div>
            <div v-else>
              <span>{{ scope.row[data.prop] }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center" key="status" width="100" :resizable="false" fixed="right">
          <template slot-scope="scope">
            <el-switch
              class="switch"
              active-color="#5DB730"
              active-text="启用"
              inactive-text="停用"
              inactive-color="#B5CCF5"
              v-model="scope.row.status"
              active-value="0"
              inactive-value="1"
              @change="handleStatusChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>


        <template slot="empty" v-if="isResult === false">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
    </div>


    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="selectManufacturerList"
      :page-sizes="[10, 20, 50, 100]"
    />


    <table-context-menu v-if="tableContextMenuVisible"
                        @foo="foo" ref="contextbutton"
                        :action="tableAction"
                        @handleTableAction="handleTableAction"
    ></table-context-menu>


    <el-dialog title="厂商维护"
               :visible.sync="manufacturerEditDialogVisible"
               @close="cancelEditManufacturer"
               width="80%"
               :close-on-click-modal="false"
    >
      <el-form ref="manufacturerForm" status-icon :model="manufacturerFormData" label-width="140px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="厂商名称:" prop="name"
                          :rules="[{ required: true, message: '请输入厂商名称', trigger: 'blur'}]"
            >
              <el-input placeholder="请输入厂商名称" maxlength="180" clearable show-word-limit
                        v-model="manufacturerFormData.name"
                        style="width: 500px;"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="厂商编号:">
              <el-tag style="height: 32px;padding: 0 10px;line-height: 30px;font-size: 14px;"
                      :key="item.id+item.manufacturerCode"
                      v-for="(item,index) in manufacturerFormData.codeList"
                      closable
                      :disable-transitions="false"
                      @close="handleCodeClose(item,index)"
              >
                {{ item.manufacturerCode }}
              </el-tag>
              <el-input
                class="input-new-tag"
                v-if="manufacturerCodeInputVisible"
                v-model="manufacturerCodeInputValue"
                ref="saveCodeTagInput"
                size="small"
                maxlength="30"
                clearable show-word-limit
                @keyup.enter.native="handleCodeInputConfirm"
                @blur="handleCodeInputConfirm"
              >
              </el-input>
              <el-button v-else class="button-new-tag" size="small" @click="showCodeInput">+ 添加新编号</el-button>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="统一社会信用代码:" prop="uscCode">
              <el-input placeholder="请输入统一社会信用代码" maxlength="30" clearable show-word-limit
                        v-model="manufacturerFormData.uscCode"
                        style="width: 500px;"
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="注册地址:" prop="address">
              <el-input placeholder="请输入统一注册地址" maxlength="200" clearable show-word-limit
                        v-model="manufacturerFormData.address"
                        style="width: 500px;"
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="联系方式:" prop="contacts">
              <el-input placeholder="请输入统一联系方式" maxlength="50" clearable show-word-limit
                        v-model="manufacturerFormData.contacts"
                        style="width: 500px;"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮编:" prop="zipCode">
              <el-input placeholder="请输入邮编" maxlength="20" clearable show-word-limit
                        v-model="manufacturerFormData.zipCode"
                        style="width: 500px;"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="传真:" prop="fax">
              <el-input placeholder="请输入传真" maxlength="20" clearable show-word-limit v-model="manufacturerFormData.fax"
                        style="width: 500px;"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="网址:" prop="website">
              <el-input placeholder="请输入网址" maxlength="100" clearable show-word-limit
                        v-model="manufacturerFormData.website"
                        style="width: 500px;"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="原厂家:" prop="originalManufacturerId">
              <el-select style="width: 500px;"
                         v-model="manufacturerFormData.originalManufacturerId"
                         filterable
                         clearable
                         remote
                         reserve-keyword
                         placeholder="请输入关键字搜索"
                         :remote-method="queryManufacturer"
                         :loading="loading"
              >
                <el-option
                  v-for="item in manufacturerOptions"
                  :key="item.id+'manufacturerOptions'"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="别名:">
              <el-tag style="height: 32px;padding: 0 10px;line-height: 30px;font-size: 14px;"
                      :key="item.id+item.alias"
                      v-for="(item,index) in manufacturerFormData.aliasList"
                      closable
                      :disable-transitions="false"
                      @close="handleAliasClose(item,index)"
              >
                {{ item.alias }}
              </el-tag>
              <el-input
                class="input-new-tag"
                v-if="manufacturerAliasInputVisible"
                v-model="manufacturerAliasInputValue"
                ref="saveAliasTagInput"
                size="small"
                maxlength="180"
                clearable show-word-limit
                @keyup.enter.native="handleAliasInputConfirm"
                @blur="handleAliasInputConfirm"
              >
              </el-input>
              <el-button v-else class="button-new-tag" size="small" @click="showAliasInput">+ 添加新别名</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="标签:">
              <el-tag style="height: 32px;padding: 0 10px;line-height: 30px;font-size: 14px;"
                      :key="item.id+item.label"
                      v-for="(item,index) in manufacturerFormData.labelList"
                      closable
                      :disable-transitions="false"
                      @close="handleLabelClose(item,index)"
              >
                {{ item.label }}
              </el-tag>
              <el-input
                class="input-new-tag"
                v-if="manufacturerLabelInputVisible"
                v-model="manufacturerLabelInputValue"
                ref="saveLabelTagInput"
                size="small"
                maxlength="80"
                clearable show-word-limit
                @keyup.enter.native="handleLabelInputConfirm"
                @blur="handleLabelInputConfirm"
              >
              </el-input>
              <el-button v-else class="button-new-tag" size="small" @click="showLabelInput">+ 添加新标签</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="12"></el-col>
          <el-col :span="12"></el-col>
          <el-col :span="24">


            <!--            <el-form-item label="厂商编号:" prop="manufacturerCode">
                          <el-input placeholder="请输入厂商编号" maxlength="30" clearable show-word-limit
                                    v-model="manufacturerFormData.manufacturerCode"
                                    style="width: 500px;"
                          />
                        </el-form-item>-->




















            <el-form-item label="备注信息:" prop="comment">
              <el-input type="textarea" :rows="6" placeholder="请输入备注信息"
                        v-model="manufacturerFormData.comment"
                        maxlength="500" clearable show-word-limit
                        style="width: 500px;float: left;"
              />
            </el-form-item>


          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20" style="margin-top: 40px;">
            <el-button type="primary" size="small"
                       style="margin-left: 20px;background: #2B60F8;float:right;"
                       @click="confirmEditManufacturer"
            >确定
            </el-button>
            <el-button style="float: right;margin-right: 10px;"
                       size="small" @click="cancelEditManufacturer"
            >取消
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { selectManufacturerNameByKey, list, info, updateStatus, del, saveOrUpdate } from '@/api/dkm/manufacturer'
import { getConcept } from '@/utils/concept'
import conceptConst from '@/const/conceptConst'
import { UCgetConfig } from '@/utils/columnSettings'
import { GLOBAL_CONFIGURATION_CONFIGKEY } from '@/utils/constants'
import { isEmpty } from '@/utils/common'
import { deepClone } from '../../../../utils'

export default {
  name: 'Manufacturer',

  data() {
    return {
      // 遮罩层
      loading: true,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        searchValue: null,
        orderByColumn: undefined,
        isAsc: undefined
      },
      // 表格高度
      tableHeight: window.innerHeight - 100 - 100,
      // 厂商表格数据
      manufacturerList: [],
      isResult: false,
      // 总条数
      total: 0,
      // 状态数据字典
      statusOptions: [],
      columnOptions: [],
      sltConfigKey: '', // 列表-表格配置
      sltConfigClassify: '',
      tableOptions: [
        {
          id: 1,
          prop: 'id',
          name: '厂商ID',
          fixed: false,
          show: true,
          sortable: 'custom',
          align: 'center',
          width: '100'
        },
        {
          id: 2,
          prop: 'name',
          name: '厂商名称',
          fixed: false,
          show: true,
          sortable: 'custom',
          align: 'center',
          width: '200'
        },
        {
          id: 3,
          prop: 'manufacturerCode',
          name: '厂商编号',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '120'
        },
        {
          id: 4,
          prop: 'uscCode',
          name: '统一社会信用代码',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '200'
        },
        {
          id: 5,
          prop: 'aliasList',
          name: '厂商别名',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '300'
        },
        {
          id: 6,
          prop: 'labelList',
          name: '厂商标签',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '300'
        },
        {
          id: 7,
          prop: 'originalManufacturerName',
          name: '原厂家',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '150'
        },
        {
          id: 8,
          prop: 'updateTime',
          name: '更新时间',
          fixed: false,
          show: true,
          sortable: 'custom',
          align: 'center',
          width: '150'
        }
      ],
      /*表格右键菜单显示隐藏*/
      tableContextMenuVisible: false,
      tableAction: [{ name: '修改' }, { name: '删除', style: 'color: #FF3333;' }],
      /*厂商信息编辑弹窗显示隐藏*/
      manufacturerEditDialogVisible: false,
      /*厂商信息编辑表单数据*/
      manufacturerFormData: {},
      /*别名添加输入框显示隐藏*/
      manufacturerAliasInputVisible: false,
      manufacturerAliasInputValue: '',
      /*label添加输入框显示隐藏*/
      manufacturerLabelInputVisible: false,
      manufacturerLabelInputValue: '',
      /*厂商名称下拉列表搜索*/
      manufacturerOptions: [],
      /*厂商编号添加输入框显示隐藏*/
      manufacturerCodeInputVisible: false,
      manufacturerCodeInputValue: ''
    }
  },
  created() {
    //获取状态数据字典
    getConcept(conceptConst.PUB_DATA_STATUS, 0).then(result => {
      this.statusOptions = result
    })

    let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 31)
    this.sltConfigKey = item.configKey
    this.sltConfigClassify = item.configClassify
    this.getSettingTable().then(() => {
      this.$nextTick(() => {
        this.setTableHeight()
        //页面初始化完毕，默认触发一次按照更新时间排序
        this.$refs.refundTable.sort('id', 'descending')
      })
    })

  },
  activated() {
  },
  mounted() {
    window.onresize = () => {
      var _this = this
      setTimeout(function() {
        _this.setTableHeight()
      }, 300)

    }

  },
  methods: {
    /*设置表格的高度*/
    setTableHeight() {
      // 获取高度值 （内容高+padding+边框）
      let formHeight = this.$refs.headerQuery.offsetHeight
      this.tableHeight = window.innerHeight - formHeight - 140
    },
    // 设置按钮-获取设置的数据
    async getSettingTable() {
      let _this = this
      let obj = {}
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 31)
      this.sltConfigKey = item.configKey // -表格配置
      this.sltConfigClassify = item.configClassify // 配置分类
      obj.configKey = this.sltConfigKey
      this.columnOptions = []
      let res = await UCgetConfig(obj)
      if (res.data && res.data.configValue) {
        let list = JSON.parse(res.data.configValue).list
        if (list.length > 0) {
          list.forEach(function(item) {
            if (item.show == undefined || item.show == true) {
              _this.columnOptions.push(item)
            }
          })
        } else {
          _this.msgError('获取数据表格配置信息失败')
        }
      } else {
        let defaultConfig = this.tableOptions
        defaultConfig.forEach(function(item) {
          if (item.show == undefined || item.show == true) {
            _this.columnOptions.push(item)
          }
        })
      }
      _this.$nextTick(() => {
        _this.$refs.refundTable.doLayout()
      })
    },
    // 设置
    async columnSettings() {
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 31)
      this.sltConfigKey = item.configKey
      this.sltConfigClassify = item.configClassify
      let _this = this
      _this.$ColumnSettings.showColSettings(
        _this.sltConfigKey,
        _this.sltConfigClassify,
        _this.tableOptions,
        _this.getSettingTable
      )
    },
    /* 厂商名称检索*/
    queryManufacturerName(queryString, cb) {
      if (queryString && queryString.trim() !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          selectManufacturerNameByKey(queryString.trim()).then(response => {
            cb(response.data)
          })
        }, 200)
      } else {
        cb([])
      }
    },
    /*搜索按钮操作 */
    handleQuery() {
      this.isResult = true
      this.queryParams.pageNum = 1
      this.selectManufacturerList()
    },
    /** 新增按钮操作 */
    handleAdd() {
      let form = this.$refs.manufacturerForm
      if (form) {
        form.resetFields()
      }
      this.manufacturerFormData = { aliasList: [], labelList: [], codeList: [] }
      this.manufacturerEditDialogVisible = true
    },
    /*编辑按钮操作*/
    handleUpdate(row) {
      let form = this.$refs.manufacturerForm
      if (form) {
        form.resetFields()
      }
      info(row.id).then(res => {
        this.manufacturerFormData = res.data
        this.manufacturerEditDialogVisible = true
        this.manufacturerOptions = [{
          id: this.manufacturerFormData.originalManufacturerId,
          name: this.manufacturerFormData.originalManufacturerName
        }]
      })

    },
    /*状态修改*/
    handleStatusChange(row) {
      let text = row.status === '0' ? '启用' : '停用'
      this.$confirm('确认要' + text + '"[' + row.id + ']-' + row.name + '"吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        return updateStatus(row.id, row.status)
      }).then(() => {
        this.msgSuccess(text + '成功')
      }).catch(function() {
        row.status = row.status === '0' ? '1' : '0'
      })
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const id = row.id
      let that = this
      this.$confirm('确认要删除' + '"[' + row.id + ']-' + row.name + '"吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        del(id).then(response => {
          that.selectManufacturerList()
          that.msgSuccess('删除成功')
        })
      })
    },
    /*查询厂商列表*/
    selectManufacturerList() {
      this.loading = true
      list(this.queryParams).then(response => {
        this.manufacturerList = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /*厂商表格排序切换*/
    tableSortChange({ column, prop, order }) {
      if (order != null) {
        order = order == 'descending' ? 'desc' : 'asc'
      } else {
        order = ''
      }
      this.queryParams.orderByColumn = prop
      this.queryParams.isAsc = order
      this.handleQuery()
    },
    /*鼠标右键点击事件*/
    rowContextmenu(row, column, event) {
      this.tableContextMenuVisible = false
      this.tableContextMenuVisible = true
      // 阻止右键默认行为
      event.preventDefault()
      this.$nextTick(() => {
        this.$refs.contextbutton.init(row, column, event)
      })
    },
    /* 取消鼠标监听事件 菜单栏*/
    foo() {
      this.tableContextMenuVisible = false
      document.removeEventListener('click', this.foo)
    },
    /* 处理表格右键菜单点击*/
    handleTableAction(actionName, row, column) {
      // console.log(row)
      this.substanceName = row.substanceName
      if (actionName === '修改') {
        this.handleUpdate(row)
      } else if (actionName === '删除') {
        this.handleDelete(row)
      }
    },
    /*厂商信息编辑弹窗--确定*/
    confirmEditManufacturer() {
      this.$refs['manufacturerForm'].validate(valid => {
        if (valid) {
          saveOrUpdate(this.manufacturerFormData).then(response => {
            this.msgSuccess(response.msg)
            this.manufacturerFormData = { aliasList: [] }
            this.selectManufacturerList()
            this.manufacturerEditDialogVisible = false
          })
        } else {
          this.msgError('请规范填写')
        }
      })
    },
    /*厂商信息编辑弹窗--取消*/
    cancelEditManufacturer() {
      this.manufacturerFormData = { aliasList: [] }
      this.manufacturerEditDialogVisible = false
    },
    /*移除别名*/
    handleAliasClose(item, index) {
      this.manufacturerFormData.aliasList.splice(index, 1)
    },
    /*显示别名输入框*/
    showAliasInput() {
      this.manufacturerAliasInputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveAliasTagInput.$refs.input.focus()
      })
    },
    /*别名输入确认*/
    handleAliasInputConfirm() {
      let manufacturerAliasInputValue = this.manufacturerAliasInputValue
      if (!isEmpty(manufacturerAliasInputValue)) {
        let hasSame = false
        this.manufacturerFormData.aliasList.some(item => {
          if (manufacturerAliasInputValue.trim() === item.alias.trim()) {
            hasSame = true
            return true
          }
        })
        if (!hasSame) {
          this.manufacturerFormData.aliasList.push({ alias: manufacturerAliasInputValue })
        }
      }
      this.manufacturerAliasInputVisible = false
      this.manufacturerAliasInputValue = ''
    },
    /*移除标签*/
    handleLabelClose(item, index) {
      this.manufacturerFormData.labelList.splice(index, 1)
    },
    /*标签输入确认*/
    handleLabelInputConfirm() {
      let manufacturerLabelInputValue = this.manufacturerLabelInputValue
      if (!isEmpty(manufacturerLabelInputValue)) {
        let hasSame = false
        this.manufacturerFormData.labelList.some(item => {
          if (manufacturerLabelInputValue.trim() === item.label.trim()) {
            hasSame = true
            return true
          }
        })
        if (!hasSame) {
          this.manufacturerFormData.labelList.push({ label: manufacturerLabelInputValue })
        }
      }
      this.manufacturerLabelInputVisible = false
      this.manufacturerLabelInputValue = ''
    },
    /*显示标签输入框*/
    showLabelInput() {
      this.manufacturerLabelInputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveLabelTagInput.$refs.input.focus()
      })
    },
    /*远程搜索厂商信息*/
    async queryManufacturer(query) {
      if (!isEmpty(query)) {
        let res = await selectManufacturerNameByKey(query.trim())
        if (res.code === 200) {
          this.manufacturerOptions = res.data
        }
      } else {
        this.manufacturerOptions = []
      }
    },
    /*移除厂商编号*/
    handleCodeClose(item, index) {
      this.manufacturerFormData.codeList.splice(index, 1)
    },
    /*厂商编号输入确认*/
    handleCodeInputConfirm() {
      let manufacturerCodeInputValue = this.manufacturerCodeInputValue
      if (!isEmpty(manufacturerCodeInputValue)) {
        let hasSame = false
        this.manufacturerFormData.codeList.some(item => {
          if (manufacturerCodeInputValue.trim() === item.manufacturerCode.trim()) {
            hasSame = true
            return true
          }
        })
        if (!hasSame) {
          this.manufacturerFormData.codeList.push({ manufacturerCode: manufacturerCodeInputValue })
        }else{
          this.msgInfo("请勿重复添加")
        }
      }
      this.manufacturerCodeInputVisible = false
      this.manufacturerCodeInputValue = ''
    },
    /*显示厂商编号输入框*/
    showCodeInput() {
      this.manufacturerCodeInputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveCodeTagInput.$refs.input.focus()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 350px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
