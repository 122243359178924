var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isEmptyData
    ? _c(
        "el-container",
        {
          staticClass:
            "layout-container empty-layout-container no-data-container",
        },
        [
          _c("el-main", { staticClass: "no-data-main" }, [
            _c("div", { staticClass: "none-data-wrap" }, [
              _c("h3", { staticClass: "no-data-title" }, [
                _vm._v("精细化药物管理，机构药品库 "),
              ]),
              _c("span", { staticClass: "no-data-subtext" }, [
                _vm._v("便捷、易用、准确、完整"),
              ]),
            ]),
          ]),
        ],
        1
      )
    : _c(
        "el-container",
        { staticClass: "drug-storage-container layout-container" },
        [
          _c(
            "el-aside",
            { staticClass: "institution-table-wrap" },
            [
              _c(
                "el-container",
                { staticClass: "layout-container institution-table-container" },
                [
                  _c(
                    "el-header",
                    { staticClass: "title-header" },
                    [
                      _c(
                        "h3",
                        { staticClass: "table-title inst-table-title" },
                        [_vm._v("机构药品列表 ")]
                      ),
                      _c(
                        "el-form",
                        {
                          ref: "form",
                          staticClass: "insti-form",
                          attrs: {
                            model: _vm.searchObj,
                            inline: true,
                            "label-width": "80px",
                          },
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c("el-input", {
                                staticClass: "search-input",
                                attrs: {
                                  clearable: "",
                                  placeholder: "输入关键字",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.preventDefault()
                                    return _vm.searchHandle($event)
                                  },
                                },
                                model: {
                                  value: _vm.searchObj.searchValue,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchObj, "searchValue", $$v)
                                  },
                                  expression: "searchObj.searchValue",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.searchHandle },
                                },
                                [_vm._v("查询")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-main",
                    {
                      staticStyle: { padding: "10px", "overflow-y": "hidden" },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "instiTable",
                          staticClass: "insti-table",
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.instTableData,
                            border: "",
                            "header-cell-style": {
                              color: "#333",
                              fontWeight: "500",
                              fontSize: "14px",
                              textAlign: "center",
                            },
                            "row-class-name": _vm.instiRowClassName,
                            "cell-class-name": _vm.cellClassName,
                            height: "100%",
                          },
                          on: { "row-click": _vm.instRowClickHandle },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "mapShow",
                              label: "匹配状态",
                              "show-overflow-tooltip": "",
                              width: "90",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "drugId",
                              label: "药品ID",
                              "show-overflow-tooltip": "",
                              width: "80",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "name",
                              label: "药品名称",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "spec",
                              label: "规格包装",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "manufacturerName",
                              label: "生产厂家",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "approvalNo",
                              label: "批准文号",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _vm.isSearched === false
                            ? _c("template", { slot: "empty" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/no-booking.svg"),
                                    alt: "",
                                  },
                                }),
                                _c("p", [_vm._v("暂无数据")]),
                              ])
                            : _c("template", { slot: "empty" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/no-left-data.svg"),
                                    alt: "",
                                  },
                                }),
                                _c("p", [
                                  _vm._v("未查询到结果，请修改查询条件重试！"),
                                ]),
                              ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-footer",
                    {
                      staticClass: "insti-footer",
                      staticStyle: {
                        padding: "0",
                        "padding-bottom": "10px",
                        height: "auto",
                      },
                    },
                    [
                      _c("pagination", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.total > 0,
                            expression: "total > 0",
                          },
                        ],
                        staticClass: "wrap-pagination",
                        attrs: {
                          total: _vm.total,
                          "pager-count": 7,
                          page: _vm.currentPage,
                          limit: _vm.pageSize,
                        },
                        on: {
                          "update:page": function ($event) {
                            _vm.currentPage = $event
                          },
                          "update:limit": function ($event) {
                            _vm.pageSize = $event
                          },
                          pagination: _vm.getOrgListHandle,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.isShowPlatformEmpty
            ? _c(
                "el-main",
                { staticClass: "platform-table-wrap" },
                [
                  _c(
                    "el-container",
                    {
                      staticClass: "layout-container platform-table-container",
                    },
                    [
                      _c(
                        "el-header",
                        { staticClass: "title-header platform-title-header" },
                        [
                          _c(
                            "h3",
                            { staticClass: "table-title platform-table-title" },
                            [_vm._v("平台标准药品数据")]
                          ),
                          _c(
                            "div",
                            { staticClass: "platform-time-line" },
                            [
                              _c(
                                "div",
                                { staticClass: "time-line-wrap" },
                                [
                                  _c("d-k-b-line", {
                                    attrs: {
                                      "timeline-list": _vm.timelineList,
                                      version: _vm.currentVersion,
                                    },
                                    on: { getCurrentItem: _vm.onTimeLine },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form",
                                {
                                  ref: "form2",
                                  staticClass: "platform-form",
                                  attrs: {
                                    model: _vm.searchObj,
                                    inline: true,
                                    "label-width": "0px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "el-select-type",
                                          attrs: { placeholder: "类型" },
                                          on: {
                                            change: _vm.searchTypeHandle,
                                            clear: _vm.onTypeClear,
                                          },
                                          model: {
                                            value: _vm.searchObj.type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.searchObj,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression: "searchObj.type",
                                          },
                                        },
                                        _vm._l(
                                          _vm.typeOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    [
                                      _vm.searchObj.type === 1
                                        ? _c("el-date-picker", {
                                            attrs: {
                                              type: "year",
                                              clearable: false,
                                              "value-format": "yyyy",
                                              placeholder: "年",
                                            },
                                            on: {
                                              change: _vm.yearChangeHandle,
                                            },
                                            model: {
                                              value: _vm.searchObj.year,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchObj,
                                                  "year",
                                                  $$v
                                                )
                                              },
                                              expression: "searchObj.year",
                                            },
                                          })
                                        : _vm.searchObj.type === 2
                                        ? _c("date-quarter", {
                                            ref: "dataQuaterRef",
                                            on: {
                                              quarterChange:
                                                _vm.quarterChangeHandle,
                                            },
                                          })
                                        : _c("el-date-picker", {
                                            attrs: {
                                              type: "month",
                                              "value-format": "yyyy-MM",
                                              clearable: false,
                                              placeholder: "月份",
                                            },
                                            on: {
                                              change: _vm.monthChangeHandle,
                                            },
                                            model: {
                                              value: _vm.searchObj.yearMonth,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchObj,
                                                  "yearMonth",
                                                  $$v
                                                )
                                              },
                                              expression: "searchObj.yearMonth",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "platform-text" },
                            [
                              _c("div", { staticClass: "fl common-text" }, [
                                _c("span", [_vm._v("机构库版本号：")]),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.currentVersion)),
                                ]),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "20px" } },
                                  [_vm._v("知识库版本号：")]
                                ),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.currentDkbVersion)),
                                ]),
                              ]),
                              _vm.currentTimeLineObj.publishStatus === 3
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "download-btn",
                                      attrs: {
                                        icon: "el-icon-download",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: { click: _vm.downloadHandle },
                                    },
                                    [_vm._v(" 下载标准数据 ")]
                                  )
                                : _vm.currentTimeLineObj.publishStatus === 1 ||
                                  _vm.currentTimeLineObj.publishStatus === 2
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "download-btn",
                                      attrs: {
                                        disabled: _vm.settingBtnDisabled,
                                        icon: "el-icon-setting",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: { click: _vm.onSettingHandle },
                                    },
                                    [_vm._v(" 目标数据审核 ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-main",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.platformTableLoading,
                              expression: "platformTableLoading",
                            },
                          ],
                          staticStyle: { padding: "10px" },
                        },
                        [
                          _c(
                            "el-table",
                            {
                              ref: "dynTable",
                              staticClass: "platform-table",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.platformTableData,
                                height: "105",
                                "row-style": _vm.rowStyle,
                                "header-cell-style": {
                                  color: "#666",
                                  fontWeight: "400",
                                  textAlign: "center",
                                },
                              },
                            },
                            [
                              _vm._l(_vm.PACK_COLUMN_LIST1, function (item) {
                                return [
                                  item.show
                                    ? _c("el-table-column", {
                                        attrs: {
                                          label: item.label,
                                          fixed: item.fixed,
                                          width: item.width,
                                          align: item.align,
                                          "show-overflow-tooltip": "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  item.isSplited
                                                    ? _c(
                                                        "span",
                                                        _vm._l(
                                                          item.prop,
                                                          function (
                                                            itemProp,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "span",
                                                              { key: index },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "multilePropColumn"
                                                                    )(
                                                                      itemProp,
                                                                      scope.row,
                                                                      item.prop,
                                                                      index
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "platforColumn"
                                                            )(
                                                              item.prop,
                                                              scope.row
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          ),
                          _c(
                            "el-table",
                            {
                              ref: "dynTable2",
                              staticClass: "platform-table",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.platformTableData,
                                height: "105",
                                "row-style": _vm.rowStyle,
                                "header-cell-style": {
                                  color: "#666",
                                  fontWeight: "400",
                                  textAlign: "center",
                                },
                              },
                            },
                            [
                              _vm._l(_vm.PACK_COLUMN_LIST2, function (item) {
                                return [
                                  item.show
                                    ? _c("el-table-column", {
                                        key: item.id,
                                        attrs: {
                                          label: item.label,
                                          fixed: item.fixed,
                                          width: item.width,
                                          align: item.align,
                                          "show-overflow-tooltip": "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  item.isSplited
                                                    ? _c(
                                                        "span",
                                                        _vm._l(
                                                          item.prop,
                                                          function (
                                                            itemProp,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "span",
                                                              { key: index },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "multilePropColumn"
                                                                    )(
                                                                      itemProp,
                                                                      scope.row,
                                                                      item.prop,
                                                                      index
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "platforColumn"
                                                            )(
                                                              item.prop,
                                                              scope.row
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          ),
                          _c(
                            "el-table",
                            {
                              ref: "dynTable3",
                              staticClass: "platform-table",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.platformTableData,
                                height: "105",
                                "row-style": _vm.rowStyle,
                                "header-cell-style": {
                                  color: "#666",
                                  fontWeight: "400",
                                  textAlign: "center",
                                },
                              },
                            },
                            [
                              _vm._l(_vm.PACK_COLUMN_LIST3, function (item) {
                                return [
                                  item.show
                                    ? _c("el-table-column", {
                                        attrs: {
                                          label: item.label,
                                          fixed: item.fixed,
                                          width: item.width,
                                          align: item.align,
                                          "show-overflow-tooltip": "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  item.isSplited
                                                    ? _c(
                                                        "span",
                                                        _vm._l(
                                                          item.prop,
                                                          function (
                                                            itemProp,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "span",
                                                              { key: index },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "multilePropColumn"
                                                                    )(
                                                                      itemProp,
                                                                      scope.row,
                                                                      item.prop,
                                                                      index
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      )
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "platforColumn"
                                                            )(
                                                              item.prop,
                                                              scope.row
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          ),
                          _c(
                            "el-table",
                            {
                              ref: "dynTable4",
                              staticClass: "platform-table",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.platformTableData,
                                height: "105",
                                "row-style": _vm.rowStyle,
                                "header-cell-style": {
                                  color: "#666",
                                  fontWeight: "400",
                                  textAlign: "center",
                                },
                              },
                            },
                            _vm._l(_vm.PACK_COLUMN_LIST4, function (item) {
                              return _c("el-table-column", {
                                key: item.id,
                                attrs: {
                                  label: item.label,
                                  fixed: item.fixed,
                                  width: item.width,
                                  align: item.align,
                                  "show-overflow-tooltip": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          item.isSplited
                                            ? _c(
                                                "span",
                                                _vm._l(
                                                  item.prop,
                                                  function (itemProp, index) {
                                                    return _c(
                                                      "span",
                                                      { key: index },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "multilePropColumn"
                                                            )(
                                                              itemProp,
                                                              scope.row,
                                                              item.prop,
                                                              index
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("platforColumn")(
                                                      item.prop,
                                                      scope.row
                                                    )
                                                  )
                                                ),
                                              ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-footer",
                        { staticStyle: { height: "auto", padding: "10px" } },
                        [
                          _c("i", {
                            staticClass: "el-icon-warning",
                            staticStyle: {
                              color: "#94AFF7",
                              "vertical-align": "middle",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-left": "3px",
                                "font-size": "12px",
                                color: "#999",
                              },
                            },
                            [
                              _vm._v(
                                "系统所展示药品信息，仅供参考，不作为使用依据"
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "el-main",
                { staticClass: "platform-table-wrap" },
                [
                  _c(
                    "el-container",
                    {
                      staticClass: "layout-container platform-table-container",
                    },
                    [
                      _c(
                        "el-header",
                        { staticClass: "title-header platform-title-header" },
                        [
                          _c(
                            "h3",
                            { staticClass: "table-title platform-table-title" },
                            [_vm._v("平台标准药品数据")]
                          ),
                          _c(
                            "div",
                            { staticClass: "platform-time-line" },
                            [
                              _c(
                                "div",
                                { staticClass: "time-line-wrap" },
                                [
                                  _c("d-k-b-line", {
                                    attrs: {
                                      "timeline-list": _vm.timelineList,
                                      version: _vm.currentVersion,
                                    },
                                    on: { getCurrentItem: _vm.onTimeLine },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form",
                                {
                                  ref: "form2",
                                  staticClass: "platform-form",
                                  attrs: {
                                    model: _vm.searchObj,
                                    inline: true,
                                    "label-width": "0px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "el-select-type",
                                          attrs: { placeholder: "类型" },
                                          on: { change: _vm.searchTypeHandle },
                                          model: {
                                            value: _vm.searchObj.type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.searchObj,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression: "searchObj.type",
                                          },
                                        },
                                        _vm._l(
                                          _vm.typeOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    [
                                      _vm.searchObj.type === 1
                                        ? _c("el-date-picker", {
                                            attrs: {
                                              type: "year",
                                              "value-format": "yyyy",
                                              clearable: false,
                                              placeholder: "年",
                                            },
                                            on: {
                                              change: _vm.yearChangeHandle,
                                            },
                                            model: {
                                              value: _vm.searchObj.year,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchObj,
                                                  "year",
                                                  $$v
                                                )
                                              },
                                              expression: "searchObj.year",
                                            },
                                          })
                                        : _vm.searchObj.type === 2
                                        ? _c("date-quarter", {
                                            ref: "dataQuaterRef",
                                            on: {
                                              quarterChange:
                                                _vm.quarterChangeHandle,
                                            },
                                          })
                                        : _c("el-date-picker", {
                                            attrs: {
                                              type: "month",
                                              "value-format": "yyyy-MM",
                                              clearable: false,
                                              placeholder: "月份",
                                            },
                                            on: {
                                              change: _vm.monthChangeHandle,
                                            },
                                            model: {
                                              value: _vm.searchObj.yearMonth,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchObj,
                                                  "yearMonth",
                                                  $$v
                                                )
                                              },
                                              expression: "searchObj.yearMonth",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "platform-text" },
                            [
                              _c("div", { staticClass: "fl common-text" }, [
                                _c("span", [_vm._v("机构库版本号：")]),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.currentVersion)),
                                ]),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "20px" } },
                                  [_vm._v("知识库版本号：")]
                                ),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.currentDkbVersion)),
                                ]),
                              ]),
                              _vm.currentTimeLineObj.publishStatus === 3
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "download-btn",
                                      attrs: {
                                        icon: "el-icon-download",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: { click: _vm.downloadHandle },
                                    },
                                    [_vm._v(" 下载标准数据 ")]
                                  )
                                : _vm.currentTimeLineObj.publishStatus === 2
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "download-btn",
                                      attrs: {
                                        icon: "el-icon-setting",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: { click: _vm.onSettingHandle },
                                    },
                                    [_vm._v(" 目标数据审核 ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-main",
                        { staticStyle: { padding: "10px" } },
                        [
                          _c(
                            "el-table",
                            {
                              staticClass: "platform-table platform-table-none",
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                data: _vm.platformTableData,
                                "row-style": _vm.rowStyle,
                                "header-cell-style": {
                                  color: "#666",
                                  fontWeight: "400",
                                },
                              },
                            },
                            [
                              _c(
                                "template",
                                {
                                  staticStyle: { height: "100%" },
                                  slot: "empty",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/no-booking.svg"),
                                      alt: "",
                                    },
                                  }),
                                  _c("p", [_vm._v("暂无数据")]),
                                ]
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-footer",
                        { staticStyle: { height: "auto", padding: "10px" } },
                        [
                          _c("i", {
                            staticClass: "el-icon-warning",
                            staticStyle: {
                              color: "#94AFF7",
                              "vertical-align": "middle",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-left": "3px",
                                "font-size": "12px",
                                color: "#999",
                              },
                            },
                            [
                              _vm._v(
                                "系统所展示药品信息，仅供参考，不作为使用依据"
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
          _c("download-data", {
            ref: "dialog-component",
            attrs: { version: _vm.currentVersion.toString() },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }