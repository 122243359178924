<!--处理或查看反馈信息-->
<template>
  <el-dialog
		v-if="dialogVisible"
    title="处理"
    :visible.sync="dialogVisible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="details-dialog vertical-middle-dialog"
    width="480px"
		:append-to-body="true"
    center
    :before-close="handleClose">
    <el-form
      ref="form"
      v-if="!isEnlarge"
      :model="formObj"
      label-position="top"
      label-width="130px">
      <el-form-item label="药品描述" class="form-item">
        <!-- <el-input type="textarea" v-model="formObj.desc"></el-input> -->
        <div class="desc-div">
          <span v-if="formObj.drugDesc && formObj.drugDesc !== ''">{{ formObj.drugDesc }}</span>
          <span v-else style="color: #c0c4cc">暂无信息</span>
        </div>
      </el-form-item>
      <el-form-item label="药品图片" class="form-item">
        <div
          v-for="(item, index) in imageObj.urlList"
          :key="item.index"
          class="drug-image-container">
          <!-- <el-image
            style="width: 100px; height: 100px;border-radius: 10px;margin-right: 10px;"
            :src="item.url" 
            :preview-src-list="imageObj.enlargeList"
            class="img-container"
            lazy
            @hover="onImageHover(item)">
          </el-image> -->
          <div
            class="img-container"
            @mouseenter.stop="onImageHover(item)"
            @mouseleave.stop="onImageMouseout(item)"
            @click="onEnlargeImage(item)">
            <img :src="item.url" />
            <!-- <i v-show="item.isHovered" class="el-icon-zoom-in zoom-in-icon"></i> -->
            <div v-show="item.isHovered" class="img-mask" :class="imgMask(item)">
              <i class="el-icon-search zoom-in-icon"></i>
            </div>
          </div>
          <!-- <div v-show="item.isHovered" class="img-mask" :class="imgMask(item)">
            <i class="el-icon-zoom-in zoom-in-icon"></i>
          </div> -->
        </div>
        <div v-if="noImage">
          <span style="color: #c0c4cc">暂无信息</span>
        </div>
      </el-form-item>
      <el-form-item label="处理选项" class="form-item">
        <el-radio-group v-model="formObj.processStatus">
          <el-radio :label="1">暂不处理</el-radio>
          <el-radio :label="2">已处理</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div v-else class="enlarge-image-container">
      <img :src="currentImg" />
      <i class="el-icon-circle-close" @click="onCloseEnlarge"></i>
    </div>
    <span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="confirmHandle">确定</el-button>
			<el-button @click="dialogVisible = false">取消</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import {
    getFeedbackApi,
    updateFeedbackApi,
    getImageUrlApi
  } from '@/api/dm/userFeedback'
  export default {
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        dialogVisible: false,
        isEnlarge: false,
        baseURL: process.env.VUE_APP_BASE_API,
        formObj: {
          path: [],
          searchVal: '',
          processStatus: '',
          drugDesc: '444'
        },
        currentImg: '', // 当前
        pathList: [],
        imageObj: {
          urlList: [
            // {
            //   index: 0,
            //   url: 'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            //   isHovered: false
            // },
            // {
            //   index: 1,
            //   url: 'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
            //   isHovered: false
            // },
            // {
            //   index: 2,
            //   url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            //   isHovered: false
            // }
          ],
          enlargeList: [
            'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',
            'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
            'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg'
          ]
        },
        
      }
    },
    computed: {
      imgMask() {
        return function (item) {
          return 'img-mask-' + item.index
        }
      },
      noImage() {
        if (this.imageObj && this.imageObj.urlList && this.imageObj.urlList.length > 0) {
          return false
        } else {
          return true
        }
      }
    },
    watch: {
      async id(newVal, oldVal) {
        if (newVal && oldVal) {
          await this.getFeedbackInfo()
        }
      }
    },
    async created() {
      await this.getFeedbackInfo()
    },
    methods: {
      // 获取反馈信息
      async getFeedbackInfo() {
        await this.fetchImageData()
        const obj = {
          id: this.id
        }
        let res = await getFeedbackApi(obj)
        if (res.code === 200) {
          this.formObj = Object.assign(this.formObj, res.data)
          this.pathList = res.data.pathList || []
          await this.fetchImageData()
        }
      },
      // 获取图片
      async fetchImageData() {
        this.imageObj.urlList = []
        for (let i = 0; i < this.pathList.length; i++) {
          this.imageObj.urlList.push({
            index: i,
            url: this.baseURL + '/system/file/preview?id=' + this.pathList[i],
            isHovered: false
          })
        }
      },
      async confirmHandle() {
        const res = await updateFeedbackApi(this.formObj)
        if (res.code === 200) {
          this.dialogVisible = false
          this.$emit('editSuccess', true)
        }
      },
      handleClose() {
        this.dialogVisible = false
      },
      onImageHover(item) {
        item.isHovered = true
        // console.log('mouseenter')
      },
      onImageMouseout(item) {
        item.isHovered = false
        // console.log('mouseleave')
      },
      // 预览
      onEnlargeImage(item) {
        this.isEnlarge = true
        this.currentImg = item.url
      },
      onCloseEnlarge() {
        this.isEnlarge = false
        this.imageObj.urlList.forEach(item => item.isHovered = false)
      }
    }
  }
</script>
<style lang="scss" scoped>
  $width: 20px;
  @function fun($width) {
    @return $width * 2;
  }
  .zoom-in-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
.details-dialog {
  ::v-deep.el-dialog {
    height: 560px !important;
    .el-dialog__body {
      .el-form {
        .el-form-item {
          .el-form-item__label {
            line-height: 24px;
          }
          .desc-div {
            border: 1px solid #C3CDED;
            border-radius: 3px;
            min-height: 100px;
            padding-left: 5px;
            font-size: 13px;
            line-height: 22px;
          }
          .drug-image-container {
            display: inline-block;
            position: relative;
            .img-container {
              width: 100px;
              height: 100px;
              /* border-radius: 10px; */
              margin-right: 10px;
              position: relative;
              display: inline-block;
              overflow: hidden;
              img {
                cursor: pointer;
                vertical-align: top;
                width: 100%;
                height: 100%;
                border-radius: 10px;
                position: absolute;
                left: 0;
                top: 0;
                &:hover {
                  /* background-color: #000;
                  opacity: 0.9; */
                  width: 100%;
                height: 100%;
                border-radius: 10px;
                position: absolute;
                left: 0;
                top: 0;
                  background: rgba(0,0,0,0.36);
                }
              }
            }
            .img-mask {
              position: absolute;
              left: 0;
              top: 0;
              width: 100px;
              height: 100px;
              border-radius: 10px;
              background: rgba(0, 0, 0, 0.6);
              cursor: pointer;
              .zoom-in-icon {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: white;
              }
            }
            /* .img-mask-1 {
              left: 110px;
            }
            .img-mask-2 {
              left: 110px;
            } */
          }
        }
      }
      .enlarge-image-container {
        position: relative;
        width: 100%;
        height: 100%;
        .el-icon-circle-close {
          position: absolute;
          top: 15px;
          right: 10px;
          font-size: 30px;
          color: #fff;
          cursor: pointer;
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
    }
  }
}
</style>
