<template>
  <div class="app-container" style="height: 100%">
    <el-container style="height: 100%">
      <el-header class="header">
        <div ref="headerQuery" style="width: 100%">
          <el-row class="headerClass">
            <el-col :span="4" class="title">注册机构管理</el-col>
            <el-col :span="20">
              <el-form :model="queryParams" ref="queryForm" class="query-form" :inline="true" style="float: right">


                <el-form-item label="">
                  <el-input v-model="queryParams.queryStr" size="small"  clearable style="width:300px;"
                            placeholder="请输入机构名称"
                  ></el-input>
                </el-form-item>


                <el-form-item>
                  <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">检索</el-button>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-table border stripe height="100%" v-loading="loading"
                  :data="tableDataList"
                  border
                  stripe
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
                  ref="userTypeTable"
                  :default-sort="{prop: 'createTime', order: 'descending'}"
                  @sort-change="tableSortChange"
        >
          <el-table-column type="index" width="50"></el-table-column>
          <el-table-column label="机构ID" min-width="100" prop="deptId" sortable="custom"/>
          <el-table-column label="机构名称" min-width="180" prop="deptName" sortable="custom"/>
          <el-table-column label="创建时间" min-width="160" prop="createTime" sortable="custom"/>
          <el-table-column label="操作" width="250">
            <template slot-scope="scope">
              <el-button size="mini" type="text" icon="el-icon-edit" @click="handleEditMenu(scope.row)">菜单管理</el-button>
              <el-button size="mini" type="text" icon="el-icon-edit" @click="viewUserList(scope.row)">用户列表</el-button>
            </template>
          </el-table-column>
          <template slot="empty" v-if="tableDataTotal==0">
            <img src="@/assets/images/no-booking.svg" alt="">
            <p>暂无数据</p>
          </template>
          <template slot="empty" v-else>
            <img src="@/assets/images/no-left-data.svg" alt="">
            <p>未查询到结果，请修改查询条件重试！</p>
          </template>
        </el-table>

      </el-main>
      <el-footer class="footer">
        <pagination
          v-show="tableDataTotal>0"
          :total="tableDataTotal"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="selectRegisterDeptList"
          :page-sizes="[10, 20, 50, 100]"
        />

      </el-footer>
    </el-container>

    <!-- 修改菜单对话框 -->
    <el-dialog title="机构菜单编辑" :visible.sync="editMenuDialog" width="50%" append-to-body  :close-on-click-modal="false">

      <el-row>
        <el-col :span="24">
          <el-checkbox v-model="menuExpand" @change="handleCheckedTreeExpand($event)">展开/折叠</el-checkbox>
          <el-checkbox v-model="menuNodeAll" @change="handleCheckedTreeNodeAll($event )">全选/全不选</el-checkbox>
          <el-checkbox v-model="menuCheckStrictly" @change="handleCheckedTreeConnect($event )">父子联动</el-checkbox>
        </el-col>
        <el-col :span="24">
          <el-tree
            class="tree-border"
            :data="menuOptions"
            show-checkbox
            ref="menu"
            node-key="menuId"
            :check-strictly="!menuCheckStrictly"
            empty-text="加载中，请稍后"
            :props="defaultProps"
          ></el-tree>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitEditMenu">确 定</el-button>
        <el-button @click="cancelEditMenu">取 消</el-button>
      </div>
    </el-dialog>


    <!-- 修改菜单对话框 -->
    <el-dialog title="机构用户列表" :visible.sync="userListDialog" width="60%" append-to-body  :close-on-click-modal="false">
      <el-table border stripe height="400" v-loading="loading"
                :data="userListTable"
                border
                stripe
                :header-cell-style="{'text-align':'center'}"
                :cell-style="{'text-align':'center'}"
                ref="userListTable"
                :default-sort="{prop: 'createTime', order: 'descending'}"
      >
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column label="用户ID" min-width="100" prop="userId" />
        <el-table-column label="账号名称" min-width="180" prop="userName" />
        <el-table-column label="用户名称" min-width="180" prop="nickName" />
        <el-table-column label="手机号" min-width="180" prop="phonenumber" />
        <el-table-column label="创建时间" min-width="160" prop="createTime" />
        <template slot="empty" v-if="tableDataTotal==0">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
    </el-dialog>

  </div>
</template>

<script>
import { registerDeptList,registerDeptMenuTree,updateRegisterDeptMenu,registerDeptUserList } from '@/api/system/registerDept'

export default {
  name: 'RegisterDept',
  data() {
    return {
      // 遮罩层
      loading: true,
      // 总条数
      tableDataTotal: 0,
      //表格数据
      tableDataList: [],
      // 查询参数
      queryParams: {

        pageNum: 1,
        pageSize: 10,
        queryStr: undefined,
        orderByColumn: "createTime",
        isAsc: "desc"
      },
      editDept:{},
      editMenuDialog:false,
      defaultProps: {
        children: "children",
        label: "menuName"
      },
      // 菜单列表
      menuOptions: [],
      //展开/折叠
      menuExpand: false,
      //全选/全不选
      menuNodeAll: false,
      //父子联动
      menuCheckStrictly: true,
      userListDialog:false,
      userListTable:[]

    }
  },
  created() {

    this.handleQuery()
  },
  methods: {
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.selectRegisterDeptList()
    },
    selectRegisterDeptList() {
      this.loading = true
      let _this = this
      registerDeptList(this.queryParams).then(response => {
        this.tableDataList = response.rows
        this.tableDataTotal = response.total
        //解决表格固定列-滚动时候错位
        _this.$nextTick(() => {
          _this.$refs.userTypeTable.doLayout()
        })
        this.loading = false
      })
    },
    /*表格排序切换*/
    tableSortChange({ column, prop, order }) {
      if (order != null) {
        order = order == 'descending' ? 'desc' : 'asc'
      } else {
        order = ''
      }
      this.queryParams.orderByColumn = prop
      this.queryParams.isAsc = order
      this.handleQuery()
    },
    //机构菜单管理
    handleEditMenu(row) {
      this.editDept=row
      this.menuExpand=false
      this.menuNodeAll=false
      this.menuCheckStrictly=false
      this.editMenuDialog=true
      const roleMenu = this.getMenuTree(row.deptId);
      console.log(roleMenu)
      this.$nextTick(() => {
        roleMenu.then(res => {
          let checkedKeys = res.data.checkedKeys
          checkedKeys.forEach((v) => {
            this.$nextTick(()=>{
              this.$refs.menu.setChecked(v, true ,false);
            })
          })
        });
      });

    },
    /** 根据机构ID查询菜单树结构 */
    getMenuTree(deptId) {
      return registerDeptMenuTree(deptId).then(response => {
        this.menuOptions = response.data.menus;
        return response;
      });
    },
    // 树权限（展开/折叠）
    handleCheckedTreeExpand(value) {
      let treeList = this.menuOptions;
      for (let i = 0; i < treeList.length; i++) {
        this.$refs.menu.store.nodesMap[treeList[i].id].expanded = value;
      }
    },
    // 树权限（全选/全不选）
    handleCheckedTreeNodeAll(value) {
      this.$refs.menu.setCheckedNodes(value ? this.menuOptions: []);
    },
    // 树权限（父子联动）
    handleCheckedTreeConnect(value) {
      this.menuCheckStrictly = value ? true: false;
    },
    submitEditMenu(){
      const selectMenu = this.getMenuAllCheckedKeys();
      console.log(selectMenu);
      let params={
        menus:selectMenu,
        deptId: this.editDept.deptId,
      }
      updateRegisterDeptMenu(params).then(res=>{
        this.msgSuccess();
        this.editMenuDialog=false
      })

    },
    cancelEditMenu(){
      this.editMenuDialog=false
    },
    // 所有菜单节点数据
    getMenuAllCheckedKeys() {
      // 目前被选中的菜单节点
      let checkedKeys = this.$refs.menu.getCheckedKeys();
      // 半选中的菜单节点
      let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    },
    //获得注册机构的用户列表
    viewUserList(row){
      this.userListDialog=true
      registerDeptUserList(row.deptId).then(res=>{
        this.userListTable=res.data

      })
    }

  }
}
</script>

<style lang="scss" scoped>


.header {
  display: flex;
  flex-wrap: wrap;
  height: auto !important;
  min-height: 55px;
  justify-content: space-between;
  align-items: center;

  .el-form {
    .el-form-item {
      margin-bottom: 0 !important;

      ::v-deep.el-form-item__label {
        font-size: 14px;
        /* color: #333;
        font-weight: 500; */
      }
    }
  }
}

.el-main {
  padding: 6px 0px;
}

.footer {
  height: 40px !important;
}

.title {
  border-left: 5px solid #0073E9;
  padding-left: 10px;
}


</style>
