var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "layout-container platform-report-container" },
    [
      _c(
        "el-header",
        { staticClass: "platform-report-header" },
        [
          _c("div", { staticClass: "title-header-container" }, [
            _c("span", { staticClass: "title-header" }),
            _c("h4", { staticClass: "title-text" }, [_vm._v("审核报告")]),
          ]),
          _c(
            "el-form",
            { attrs: { model: _vm.tableSearchObj, inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "输入编号/药品通用名",
                    },
                    model: {
                      value: _vm.tableSearchObj.searchValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.tableSearchObj, "searchValue", $$v)
                      },
                      expression: "tableSearchObj.searchValue",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        icon: "el-icon-search",
                      },
                      on: { click: _vm.onSearchReportTable },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "筛选模式" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#2c5ff9",
                      "inactive-color": "#b5ccf5",
                    },
                    on: { change: _vm.filterChange },
                    model: {
                      value: _vm.isFilter,
                      callback: function ($$v) {
                        _vm.isFilter = $$v
                      },
                      expression: "isFilter",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.onPublishReport },
                    },
                    [_vm._v("发布报告")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-main",
        { staticClass: "platform-report-main" },
        [
          _c(
            "el-table",
            {
              staticClass: "platform-report-table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "header-cell-class-name": _vm.headerGradeInfo,
                "cell-class-name": _vm.gradeInfo,
                "header-cell-style": { textAlign: "center" },
                height: "100%",
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", width: "160", prop: "drugId" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("药品编码")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.drugId))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { width: "180", prop: "genericNameCompare" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("药品通用名")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "fl" }, [
                          _vm._v(_vm._s(scope.row.genericNameCompare)),
                        ]),
                        _vm.isShowErrorDetails(scope)
                          ? _c("span", { staticClass: "error-details fr" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.genericNameCompareerrorTypeStr
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                staticClass: "none-border",
                attrs: { width: "50", prop: "genericNameCompareStatus" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c("span", {
                          staticStyle: {
                            display: "inline-block",
                            width: "3px",
                            height: "3px",
                          },
                        }),
                        _c(
                          "el-popover",
                          {
                            ref: "elPopover" + scope.column.property,
                            attrs: {
                              trigger: "click",
                              placement: "right",
                              "popper-class": "governance-poper",
                              "visible-arrow": false,
                            },
                            on: {
                              hide: function ($event) {
                                return _vm.onHidePopover(scope)
                              },
                              show: function ($event) {
                                return _vm.onShowPopover(scope)
                              },
                            },
                          },
                          [
                            _c("filter-cascader", {
                              ref: "filter" + scope.$index,
                              attrs: {
                                "show-filter": _vm.isFilter,
                                "filter-options": _vm.filterOptions,
                                column: scope,
                              },
                              on: { closeFilter: _vm.onCloseFilter },
                            }),
                            _c("svg-icon", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isFilter,
                                  expression: "isFilter",
                                },
                              ],
                              staticClass: "filter-icon",
                              staticStyle: {
                                position: "absolute",
                                right: "10px",
                                width: "20px",
                                height: "20px",
                              },
                              attrs: {
                                slot: "reference",
                                "icon-class": "shaixuan",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onFilterClick(scope)
                                },
                              },
                              slot: "reference",
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            ref: "gradePopover" + scope.column.property,
                            attrs: {
                              trigger: "click",
                              placement: "bottom",
                              "visible-arrow": false,
                              "popper-class": "governance-poper",
                            },
                            on: {
                              hide: function ($event) {
                                return _vm.onHidePopover(scope)
                              },
                              show: function ($event) {
                                return _vm.onShowPopover(scope)
                              },
                            },
                          },
                          [
                            _c("grade-cascader", {
                              ref:
                                "grade" + scope.column.property + scope.$index,
                              attrs: {
                                status: scope.row.genericNameCompareStatus,
                                "show-filter": _vm.isFilter,
                                "filter-options": _vm.gradeOptions,
                              },
                              on: { closeFilter: _vm.onCloseGradeFilter },
                            }),
                            _c(
                              "div",
                              {
                                attrs: { slot: "reference" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onGradeFilterClick(scope)
                                  },
                                },
                                slot: "reference",
                              },
                              [
                                _c("svg-icon", {
                                  staticClass: "filter-icon",
                                  staticStyle: {
                                    position: "absolute",
                                    right: "16px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    width: "18px",
                                    height: "18px",
                                  },
                                  attrs: {
                                    "icon-class": _vm.gradeIcon(
                                      scope.row.genericNameCompareStatus
                                    ),
                                  },
                                }),
                                _c("transition", [
                                  _vm.gradeIconFolded(scope)
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-caret-bottom icon-class",
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "el-icon-caret-top icon-class",
                                      }),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "规格", prop: "specCompare" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("规格")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "fl" }, [
                          _vm._v(_vm._s(scope.row.specCompare)),
                        ]),
                        _vm.isShowErrorDetails(scope)
                          ? _c("span", { staticClass: "error-details fr" }, [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.specCompareerrorTypeStr) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { width: "50", prop: "specCompareStatus" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c("span", {
                          staticStyle: {
                            display: "inline-block",
                            width: "3px",
                            height: "3px",
                          },
                        }),
                        _c(
                          "el-popover",
                          {
                            ref: "elPopover" + scope.column.property,
                            attrs: {
                              trigger: "click",
                              placement: "right",
                              "popper-class": "governance-poper",
                              "visible-arrow": false,
                            },
                            on: {
                              hide: function ($event) {
                                return _vm.onHidePopover(scope)
                              },
                              show: function ($event) {
                                return _vm.onShowPopover(scope)
                              },
                            },
                          },
                          [
                            _c("filter-cascader", {
                              ref: "filter" + scope.$index,
                              attrs: {
                                "show-filter": _vm.isFilter,
                                "filter-options": _vm.filterOptions,
                                column: scope,
                              },
                              on: { closeFilter: _vm.onCloseFilter },
                            }),
                            _c("svg-icon", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isFilter,
                                  expression: "isFilter",
                                },
                              ],
                              staticClass: "filter-icon",
                              staticStyle: {
                                position: "absolute",
                                right: "10px",
                                width: "20px",
                                height: "20px",
                              },
                              attrs: {
                                slot: "reference",
                                "icon-class": "shaixuan",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onFilterClick(scope)
                                },
                              },
                              slot: "reference",
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            ref: "gradePopover" + scope.column.property,
                            attrs: {
                              trigger: "click",
                              placement: "bottom",
                              "visible-arrow": false,
                              "popper-class": "governance-poper",
                            },
                            on: {
                              hide: function ($event) {
                                return _vm.onHidePopover(scope)
                              },
                              show: function ($event) {
                                return _vm.onShowPopover(scope)
                              },
                            },
                          },
                          [
                            _c("grade-cascader", {
                              ref:
                                "grade" + scope.column.property + scope.$index,
                              attrs: {
                                status: scope.row.specCompareStatus,
                                "show-filter": _vm.isFilter,
                                "filter-options": _vm.gradeOptions,
                              },
                              on: { closeFilter: _vm.onCloseGradeFilter },
                            }),
                            _c(
                              "div",
                              {
                                attrs: { slot: "reference" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onGradeFilterClick(scope)
                                  },
                                },
                                slot: "reference",
                              },
                              [
                                _c("svg-icon", {
                                  staticClass: "filter-icon",
                                  staticStyle: {
                                    position: "absolute",
                                    right: "16px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    width: "18px",
                                    height: "18px",
                                  },
                                  attrs: {
                                    "icon-class": _vm.gradeIcon(
                                      scope.row.specCompareStatus
                                    ),
                                  },
                                }),
                                _c("transition", [
                                  _vm.gradeIconFolded(scope)
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-caret-bottom icon-class",
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "el-icon-caret-top icon-class",
                                      }),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "剂型名称",
                  width: "180",
                  prop: "formNameCompare",
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("剂型名称")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "fl" }, [
                          _vm._v(_vm._s(scope.row.formNameCompare)),
                        ]),
                        _vm.isShowErrorDetails(scope)
                          ? _c("span", { staticClass: "error-details fr" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.formNameCompareerrorTypeStr
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { width: "50", prop: "formNameCompareStatus" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c("span", {
                          staticStyle: {
                            display: "inline-block",
                            width: "3px",
                            height: "3px",
                          },
                        }),
                        _c(
                          "el-popover",
                          {
                            ref: "elPopover" + scope.column.property,
                            attrs: {
                              trigger: "click",
                              placement: "right",
                              "popper-class": "governance-poper",
                              "visible-arrow": false,
                            },
                            on: {
                              hide: function ($event) {
                                return _vm.onHidePopover(scope)
                              },
                              show: function ($event) {
                                return _vm.onShowPopover(scope)
                              },
                            },
                          },
                          [
                            _c("filter-cascader", {
                              ref: "filter" + scope.$index,
                              attrs: {
                                "show-filter": _vm.isFilter,
                                "filter-options": _vm.filterOptions,
                                column: scope,
                              },
                              on: { closeFilter: _vm.onCloseFilter },
                            }),
                            _c("svg-icon", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isFilter,
                                  expression: "isFilter",
                                },
                              ],
                              staticClass: "filter-icon",
                              staticStyle: {
                                position: "absolute",
                                right: "10px",
                                width: "20px",
                                height: "20px",
                              },
                              attrs: {
                                slot: "reference",
                                "icon-class": "shaixuan",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onFilterClick(scope)
                                },
                              },
                              slot: "reference",
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            ref: "gradePopover" + scope.column.property,
                            attrs: {
                              trigger: "click",
                              placement: "bottom",
                              "visible-arrow": false,
                              "popper-class": "governance-poper",
                            },
                            on: {
                              hide: function ($event) {
                                return _vm.onHidePopover(scope)
                              },
                              show: function ($event) {
                                return _vm.onShowPopover(scope)
                              },
                            },
                          },
                          [
                            _c("grade-cascader", {
                              ref:
                                "grade" + scope.column.property + scope.$index,
                              attrs: {
                                status: scope.row.formNameCompareStatus,
                                "show-filter": _vm.isFilter,
                                "filter-options": _vm.gradeOptions,
                              },
                              on: { closeFilter: _vm.onCloseGradeFilter },
                            }),
                            _c(
                              "div",
                              {
                                attrs: { slot: "reference" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onGradeFilterClick(scope)
                                  },
                                },
                                slot: "reference",
                              },
                              [
                                _c("svg-icon", {
                                  staticClass: "filter-icon",
                                  staticStyle: {
                                    position: "absolute",
                                    right: "16px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    width: "18px",
                                    height: "18px",
                                  },
                                  attrs: {
                                    "icon-class": _vm.gradeIcon(
                                      scope.row.formNameCompareStatus
                                    ),
                                  },
                                }),
                                _c("transition", [
                                  _vm.gradeIconFolded(scope)
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-caret-bottom icon-class",
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "el-icon-caret-top icon-class",
                                      }),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "厂商", prop: "manufacturerCompare" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("厂商")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "fl" }, [
                          _vm._v(_vm._s(scope.row.manufacturerCompare)),
                        ]),
                        _vm.isShowErrorDetails(scope)
                          ? _c("span", { staticClass: "error-details fr" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.manufacturerCompareerrorTypeStr
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { width: "50", prop: "manufacturerCompareStatus" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c("span", {
                          staticStyle: {
                            display: "inline-block",
                            width: "3px",
                            height: "3px",
                          },
                        }),
                        _c(
                          "el-popover",
                          {
                            ref: "elPopover" + scope.column.property,
                            attrs: {
                              trigger: "click",
                              placement: "right",
                              "popper-class": "governance-poper",
                              "visible-arrow": false,
                            },
                            on: {
                              hide: function ($event) {
                                return _vm.onHidePopover(scope)
                              },
                              show: function ($event) {
                                return _vm.onShowPopover(scope)
                              },
                            },
                          },
                          [
                            _c("filter-cascader", {
                              ref: "filter" + scope.$index,
                              attrs: {
                                "show-filter": _vm.isFilter,
                                "filter-options": _vm.filterOptions,
                                column: scope,
                              },
                              on: { closeFilter: _vm.onCloseFilter },
                            }),
                            _c("svg-icon", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isFilter,
                                  expression: "isFilter",
                                },
                              ],
                              staticClass: "filter-icon",
                              staticStyle: {
                                position: "absolute",
                                right: "10px",
                                width: "20px",
                                height: "20px",
                              },
                              attrs: {
                                slot: "reference",
                                "icon-class": "shaixuan",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onFilterClick(scope)
                                },
                              },
                              slot: "reference",
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            ref: "gradePopover" + scope.column.property,
                            attrs: {
                              trigger: "click",
                              placement: "bottom",
                              "visible-arrow": false,
                              "popper-class": "governance-poper",
                            },
                            on: {
                              hide: function ($event) {
                                return _vm.onHidePopover(scope)
                              },
                              show: function ($event) {
                                return _vm.onShowPopover(scope)
                              },
                            },
                          },
                          [
                            _c("grade-cascader", {
                              ref:
                                "grade" + scope.column.property + scope.$index,
                              attrs: {
                                status: scope.row.manufacturerCompareStatus,
                                "show-filter": _vm.isFilter,
                                "filter-options": _vm.gradeOptions,
                              },
                              on: { closeFilter: _vm.onCloseGradeFilter },
                            }),
                            _c(
                              "div",
                              {
                                attrs: { slot: "reference" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onGradeFilterClick(scope)
                                  },
                                },
                                slot: "reference",
                              },
                              [
                                _c("svg-icon", {
                                  staticClass: "filter-icon",
                                  staticStyle: {
                                    position: "absolute",
                                    right: "16px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    width: "18px",
                                    height: "18px",
                                  },
                                  attrs: {
                                    "icon-class": _vm.gradeIcon(
                                      scope.row.manufacturerCompareStatus
                                    ),
                                  },
                                }),
                                _c("transition", [
                                  _vm.gradeIconFolded(scope)
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-caret-bottom icon-class",
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "el-icon-caret-top icon-class",
                                      }),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "批准文号", prop: "approvalNoCompare" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("批准文号")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "fl" }, [
                          _vm._v(_vm._s(scope.row.approvalNoCompare)),
                        ]),
                        _vm.isShowErrorDetails(scope)
                          ? _c("span", { staticClass: "error-details fr" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.approvalNoCompareerrorTypeStr
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { width: "50", prop: "approvalNoCompareStatus" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c("span", {
                          staticStyle: {
                            display: "inline-block",
                            width: "3px",
                            height: "3px",
                          },
                        }),
                        _c(
                          "el-popover",
                          {
                            ref: "elPopover",
                            attrs: {
                              trigger: "click",
                              placement: "right",
                              "popper-class": "governance-poper",
                              "visible-arrow": false,
                            },
                            on: {
                              hide: function ($event) {
                                return _vm.onHidePopover(scope)
                              },
                              show: function ($event) {
                                return _vm.onShowPopover(scope)
                              },
                            },
                          },
                          [
                            _c("filter-cascader", {
                              ref: "filter" + scope.$index,
                              attrs: {
                                "show-filter": _vm.isFilter,
                                "filter-options": _vm.filterOptions,
                                column: scope,
                              },
                              on: { closeFilter: _vm.onCloseFilter },
                            }),
                            _c("svg-icon", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isFilter,
                                  expression: "isFilter",
                                },
                              ],
                              staticClass: "filter-icon",
                              staticStyle: {
                                position: "absolute",
                                right: "10px",
                                width: "20px",
                                height: "20px",
                              },
                              attrs: {
                                slot: "reference",
                                "icon-class": "shaixuan",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onFilterClick(scope)
                                },
                              },
                              slot: "reference",
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            ref: "gradePopover" + scope.column.property,
                            attrs: {
                              trigger: "click",
                              placement: "bottom",
                              "visible-arrow": false,
                              "popper-class": "governance-poper",
                            },
                            on: {
                              hide: function ($event) {
                                return _vm.onHidePopover(scope)
                              },
                              show: function ($event) {
                                return _vm.onShowPopover(scope)
                              },
                            },
                          },
                          [
                            _c("grade-cascader", {
                              ref:
                                "grade" + scope.column.property + scope.$index,
                              attrs: {
                                status: scope.row.approvalNoCompareStatus,
                                "show-filter": _vm.isFilter,
                                "filter-options": _vm.gradeOptions,
                              },
                              on: { closeFilter: _vm.onCloseGradeFilter },
                            }),
                            _c(
                              "div",
                              {
                                attrs: { slot: "reference" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onGradeFilterClick(scope)
                                  },
                                },
                                slot: "reference",
                              },
                              [
                                _c("svg-icon", {
                                  staticClass: "filter-icon",
                                  staticStyle: {
                                    position: "absolute",
                                    right: "16px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    width: "18px",
                                    height: "18px",
                                  },
                                  attrs: {
                                    "icon-class": _vm.gradeIcon(
                                      scope.row.approvalNoCompareStatus
                                    ),
                                  },
                                }),
                                _c("transition", [
                                  _vm.gradeIconFolded(scope)
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-caret-bottom icon-class",
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "el-icon-caret-top icon-class",
                                      }),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-footer",
        [
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.tableSearchObj.pageNum,
              limit: _vm.tableSearchObj.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.tableSearchObj, "pageNum", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.tableSearchObj, "pageSize", $event)
              },
              pagination: _vm.getTableHandle,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }