<template>
  <div class="app-container" style="overflow: auto">


    <div ref="headerQuery">
      <el-row class="headerClass">
        <el-col :span="4" style="border-left: 5px solid #0073E9;padding-left: 10px;">开发者用户列表</el-col>
        <el-col :span="20">
          <el-form :model="queryParams" ref="queryForm" :inline="true" style="float: right">


            <el-form-item label="用户ID/APPID:" prop="searchValue">
              <el-input v-model="queryParams.searchValue" maxlength="80" clearable style="width: 300px;"></el-input>
            </el-form-item>


            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">检索</el-button>
              <el-button icon="fa fa-cog" size="small" @click="columnSettings">设置</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>


    <div ref="tableContainer">
      <el-table
        border
        stripe
        ref="refundTable"
        :data="tableDataList"
        :height="tableHeight"
        v-loading="loading"
        @sort-change="tableSortChange"
        @row-contextmenu="rowContextmenu"
      >
        <el-table-column
          v-for="data in columnOptions"
          :key="'columnOptions'+data.id"
          :prop="data.prop"
          :label="data.name"
          :fixed="data.fixed"
          :resizable="false"
          :sortable="data.sortable"
          :min-width="data.width"
          :align="data.align"
        >
          <template slot-scope="scope">

            <div v-if="data.prop == 'status'">
              <el-tag type="success" v-show="scope.row.status==1">正常</el-tag>
              <el-tag type="info" v-show="scope.row.status==2">停用</el-tag>
            </div>

            <div v-else-if="data.prop == 'apiSize'">
              <el-tag v-if="scope.row.apiRegistrationList">
                {{ scope.row.apiRegistrationList.length }}
              </el-tag>
            </div>

            <div v-else>
              <span>{{ scope.row[data.prop] }}</span>
            </div>
          </template>
        </el-table-column>


        <el-table-column label="状态" align="center" key="status" width="80" :resizable="false" fixed="right">
          <template slot-scope="scope">
            <el-switch
              class="switch"
              active-color="#5DB730"
              active-text='启用'
              inactive-text='停用'
              inactive-color="#B5CCF5"
              v-model="scope.row.status"
              active-value="1"
              inactive-value="2"
              @change="handleStatusChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>

        <template slot="empty" v-if="tableDataList.length==0">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
    </div>

    <table-context-menu v-if="tableContextMenuVisible"
                        @foo="foo" ref="contextbutton"
                        :action="tableAction"
                        @handleTableAction="handleTableAction"
    ></table-context-menu>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="selectAppUserList"
      :page-sizes="[10, 20, 50, 100]"
    />


    <el-dialog title="开发者信息编辑" width="730px"
               :visible.sync="apiAppUserDialogVisible"
               @close="cancelEditUserAuth"
               :close-on-click-modal="false"
    >
      <el-form ref="appUserEditForm" status-icon :model="appUserEditFormData" label-width="140px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="用户名称:" prop="userName"
            >
              <el-input :disabled="true"
                        v-model="appUserEditFormData.userName"
              />
            </el-form-item>
            <el-form-item label="授权接口:" prop="userName"
            >
              <el-tree
                ref="apiSelectTree"
                :data="apiSelectTreeData"
                show-checkbox
                node-key="code"
                :default-checked-keys="apiSelectTreeCheckedKeys"
                :props="treeProps"
              >
              </el-tree>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20" style="margin-top: 40px;">
            <el-button type="primary" size="small"
                       style="margin-left: 20px;background: #2B60F8;float:right;"
                       @click="confirmEditUserAuth"
            >确定
            </el-button>
            <el-button style="float: right;margin-right: 10px;"
                       size="small" @click="cancelEditUserAuth"
            >取消
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>


  </div>
</template>

<script>

import conceptConst from '@/const/conceptConst'
import AcrossUtil from '@/utils/acrossUtil'
import { UCgetConfig } from '@/utils/columnSettings'
import { GLOBAL_CONFIGURATION_CONFIGKEY } from '@/utils/constants'
import { isEmpty } from '@/utils/common'
import { selectAppUserList, userInfo,updateUserStatus } from '@/api/system/appUserRegistration'
import { apiAuthorization } from '@/api/system/apiGateway'

export default {
  name: 'AppUserList',
  components: {},
  data() {
    return {
      conceptConst,
      // 遮罩层
      loading: true,
      tableDataList: [],
      // 总条数
      total: 0,
      // 表格高度
      tableHeight: window.innerHeight - 100 - 100,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        searchValue: null,
        orderByColumn: undefined,
        isAsc: undefined
      },
      sltConfigKey: '',
      sltConfigClassify: '',
      columnOptions: [],
      tableOptions: [
        {
          id: 1,
          prop: 'orgId',
          name: '机构Id',
          fixed: true,
          show: true,
          align: 'center',
          width: '60'
        },
        {
          id: 2,
          prop: 'orgName',
          name: '机构名称',
          fixed: true,
          show: true,
          align: 'center',
          width: '100'
        },
        {
          id: 3,
          prop: 'userId',
          name: '用户Id',
          fixed: true,
          show: true,
          align: 'center',
          width: '60'
        },
        {
          id: 4,
          prop: 'userName',
          name: '用户名称',
          fixed: true,
          show: true,
          align: 'center',
          width: '100'
        },
        {
          id: 5,
          prop: 'appId',
          name: 'APPID',
          fixed: true,
          show: true,
          align: 'center',
          width: '120'
        },
        {
          id: 6,
          prop: 'updateTime',
          name: '更新时间',
          fixed: false,
          show: true,
          align: 'center',
          width: '80'
        },
        {
          id: 7,
          prop: 'apiSize',
          name: '已授权api数量',
          fixed: false,
          show: true,
          align: 'center',
          width: '70'
        }
      ],
      /*表格右键菜单显示隐藏*/
      tableContextMenuVisible: false,
      tableAction: [{ name: '授权管理' }],
      apiAppUserDialogVisible: false,
      appUserEditFormData: {},
      apiSelectTreeData: [],
      apiSelectTreeCheckedKeys: [],
      treeProps: {
        children: 'childrenNodes',
        label: 'name'
      }

    }
  },
  created() {
    let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 71)
    this.sltConfigKey = item.configKey
    this.sltConfigClassify = item.configClassify
    this.getSettingTable().then(() => {
      this.$nextTick(() => {
        this.setTableHeight()
        //页面初始化完毕，默认触发一次排序
        this.$refs.refundTable.sort('appId', 'descending')
      })
    })

  },
  activated() {
    //激活时重新设置表格高度
    this.setTableHeight()
  },
  mounted() {
    var _this = this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      AcrossUtil.$emit('resetTableHeight')
    }

    AcrossUtil.$on('resetTableHeight', function(param) {
      setTimeout(function() {
        _this.setTableHeight()
      }, 300)
    })

  },
  methods: {
    /*设置表格的高度*/
    setTableHeight() {
      // 获取高度值 （内容高+padding+边框）
      let formHeight = this.$refs.headerQuery.offsetHeight
      this.tableHeight = window.innerHeight - formHeight - 150
    },
    // 设置按钮-获取设置的数据
    async getSettingTable() {
      let _this = this
      let obj = {}
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 71)
      this.sltConfigKey = item.configKey
      this.sltConfigClassify = item.configClassify
      obj.configKey = this.sltConfigKey
      this.columnOptions = []
      let res = await UCgetConfig(obj)
      if (res.data && res.data.configValue) {
        let list = JSON.parse(res.data.configValue).list
        if (list.length > 0) {
          list.forEach(function(item) {
            if (item.show == undefined || item.show == true) {
              _this.columnOptions.push(item)
            }
          })
        } else {
          _this.msgError('获取数据表格配置信息失败')
        }
      } else {
        let defaultConfig = this.tableOptions
        defaultConfig.forEach(function(item) {
          if (item.show == undefined || item.show == true) {
            _this.columnOptions.push(item)
          }
        })
      }
      _this.$nextTick(() => {
        _this.$refs.refundTable.doLayout()
      })
    },
    // 设置
    async columnSettings() {
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 71)
      this.sltConfigKey = item.configKey
      this.sltConfigClassify = item.configClassify
      let _this = this
      _this.$ColumnSettings.showColSettings(
        _this.sltConfigKey,
        _this.sltConfigClassify,
        _this.tableOptions,
        _this.getSettingTable
      )
    },
    /*鼠标右键点击事件*/
    rowContextmenu(row, column, event) {
      this.tableContextMenuVisible = false
      this.tableContextMenuVisible = true
      // 阻止右键默认行为
      event.preventDefault()
      this.$nextTick(() => {
        this.$refs.contextbutton.init(row, column, event)
      })
    },
    /* 取消鼠标监听事件 菜单栏*/
    foo() {
      this.tableContextMenuVisible = false
      document.removeEventListener('click', this.foo)
    },
    /* 处理表格右键菜单点击*/
    handleTableAction(actionName, row, column) {
      this.substanceName = row.substanceName
      if (actionName === '授权管理') {
        this.handleEdit(row)
      }
    },
    /*查询全部开发者用户列表 */
    async selectAppUserList() {
      this.loading = true
      let response = await selectAppUserList(this.queryParams)
      this.tableDataList = response.rows
      this.tableDataList.forEach(item=>{
        if(item.sysUser){
          item.userName=item.sysUser.userName
          item.nickName=item.sysUser.nickName
          item.orgId=item.sysUser.deptId
          if(item.sysUser.dept){
            item.orgName=item.sysUser.dept.deptName
          }
        }
      })
      this.total = response.total
      this.loading = false
    },
    /*搜索按钮操作*/
    handleQuery() {
      this.isResult = true
      this.queryParams.pageNum = 1
      this.selectAppUserList()
    },
    /*新增按钮操作*/
    handleAdd() {

    },
    /*表格排序切换*/
    tableSortChange({ column, prop, order }) {
      if (order != null) {
        order = order == 'descending' ? 'desc' : 'asc'
      } else {
        order = ''
      }
      this.queryParams.orderByColumn = prop
      this.queryParams.isAsc = order
      this.handleQuery()
    },
    /*编辑*/
    handleEdit(row) {
      this.apiAppUserDialogVisible = true
      userInfo(row.appId).then(res => {
        this.apiSelectTreeData = res.data.apiTreeBeanList
        this.apiSelectTreeCheckedKeys = res.data.checkTreeNodeKeyList
        this.appUserEditFormData.userName=row.sysUser.nickName
        this.appUserEditFormData.appId=res.data.appId

      })

    },
    /* 取消编辑api权限*/
    cancelEditUserAuth() {
      this.apiAppUserDialogVisible = false
      this.apiSelectTreeData=[]
      this.apiSelectTreeCheckedKeys=[]
    },
    /* 确定编辑api权限*/
    confirmEditUserAuth() {
      let checkedKeys = this.$refs.apiSelectTree.getCheckedKeys()
      let checkedApiId=[]
      checkedKeys.forEach(item=>{
        if(item.indexOf("node")>=0){
          checkedApiId.push(item.replaceAll("node",""))
        }
      })
      if(checkedApiId.length==0){
        this.msgInfo("请选择需要授权的接口！")
        return
      }
      let appId=this.appUserEditFormData.appId
      apiAuthorization(appId,checkedApiId.toString()).then(res=>{
        this.apiAppUserDialogVisible = false
        this.msgSuccess("授权成功")
        this.selectAppUserList()

      })
    },
    /*开发者用户状态修改*/
    handleStatusChange(row) {
      let text = row.status === '1' ? '启用' : '停用'
      this.$confirm('确认要' + text + '"[' + row.appId + ']-' + row.userName + '"吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        return updateUserStatus(row.appId, row.status)
      }).then(() => {
        this.msgSuccess(text + '成功')
        this.selectAppUserList()
      }).catch(function() {
        row.status = row.status === '1' ? '2' : '1'
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.el-table .cell .el-form-item {
  margin-bottom: 0px;
}

</style>


<style>


</style>
