<template>
  <div class="app-container" style="overflow: auto">


    <div ref="headerQuery">
      <el-row class="headerClass">
        <el-col :span="4" style="border-left: 5px solid #0073E9;padding-left: 10px;">CFDA药品列表</el-col>
        <el-col :span="20">
          <el-form :model="queryParams" ref="queryForm" :inline="true" style="float: right">


            <el-form-item label="药品名称:" prop="searchValue">
              <el-input v-model="queryParams.searchValue" placeholder="药品名称" maxlength="50" clearable></el-input>
            </el-form-item>

            <el-form-item label="本位码/批准文号:" prop="searchCode">
              <el-input v-model="queryParams.searchCode" placeholder="本位码/批准文号" maxlength="50" clearable></el-input>
            </el-form-item>

            <el-form-item label="生产企业:" prop="manufacturer">
              <el-input v-model="queryParams.manufacturer" placeholder="生产企业/上市许可持有人" maxlength="50" clearable></el-input>
            </el-form-item>




            <el-form-item label="版本号:" prop="versionNo">
              <el-select
                v-model="queryParams.versionNo"
                placeholder="版本号"
                size="small"
                class="statusClass"
                style="width: 200px"
                @change="versionNoChange"
                clearable
              >
                <el-option
                  v-for="item in versionNoOptions"
                  :key="item.versionNo+'versionNo'"
                  :label="item.versionNo"
                  :value="item.versionNo"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="类型:" prop="modifyType" v-if="this.queryParams.versionNo">
              <el-select
                v-model="queryParams.modifyType"
                placeholder="类型"
                size="small"
                class="statusClass"
                style="width: 200px"
              >
                <el-option
                  v-for="item in modifyTypeOptions"
                  :key="item.code+'modifyType'"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">检索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>


    <div ref="tableContainer">
      <el-table
        border
        stripe
        ref="refundTable"
        :data="drugList"
        :height="tableHeight"
        v-loading="loading"
        @sort-change="tableSortChange"
        @row-contextmenu="rowContextmenu"
        :span-method="objectSpanMethod"
        :cell-class-name="compareTableCellClass"
      >
        <el-table-column
          v-for="data in tableOptions"
          :key="'tableOptions'+data.id"
          :prop="data.prop"
          :label="data.name"
          :fixed="data.fixed"
          :resizable="false"
          :sortable="data.sortable"
          :min-width="data.width"
          :align="data.align"
        >
          <template slot-scope="scope">


            <div v-if="data.prop == 'modifyType'">
              <el-tag type="info" v-show="scope.row.modifyType==0">无变化</el-tag>
              <el-tag  v-show="scope.row.modifyType==1">修改</el-tag>
              <el-tag type="success" v-show="scope.row.modifyType==2">新增</el-tag>
              <el-tag type="danger" v-show="scope.row.modifyType==3">删除</el-tag>
            </div>
            <div v-else-if="data.prop == 'domesticOrImport'">
              <el-tag  v-show="scope.row.domesticOrImport==1">国产</el-tag>
              <el-tag type="success" v-show="scope.row.domesticOrImport==2">进口</el-tag>
            </div>


            <div v-else>
              <span>{{ scope.row[data.prop] }}</span>
            </div>
          </template>
        </el-table-column>

        <template slot="empty" v-if="drugList.length==0">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
    </div>

    <table-context-menu v-if="tableContextMenuVisible"
                        @foo="foo" ref="contextbutton"
                        :action="tableAction"
                        @handleTableAction="handleTableAction"
    ></table-context-menu>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="selectCfdaDrugList"
      :page-sizes="[10, 20, 50, 100]"
    />






  </div>
</template>

<script>

import conceptConst from '@/const/conceptConst'
import AcrossUtil from '@/utils/acrossUtil'
import { UCgetConfig } from '@/utils/columnSettings'
import { GLOBAL_CONFIGURATION_CONFIGKEY } from '@/utils/constants'
import { isEmpty } from '@/utils/common'
import { cfdaDrugList,selectAllVersionNo } from '@/api/assistTool/cfdaDrug'
import { list as fullCfdaDrugList } from '@/api/dkm/dictCfdaDrug'

export default {
  name: 'CfdaDrugList',
  components: {},
  data() {
    return {
      conceptConst,
      // 遮罩层
      loading: true,
      drugList: [],
      // 总条数
      total: 0,
      // 表格高度
      tableHeight: window.innerHeight - 100 - 100,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        searchValue: null,
        orderByColumn: undefined,
        isAsc: undefined,
        versionNo:undefined,
        modifyType:4,
        searchCode:undefined,
        relevanceStatus:0
      },
      /*表格右键菜单显示隐藏*/
      tableContextMenuVisible: false,
      tableAction: [],
      versionNoOptions:[],
      modifyTypeOptions:[
        {code:4,name:'当前版本全量数据'},
        {code:0,name:'与之前版本完全一致'},
        {code:1,name:'当前版本修改'},
        {code:2,name:'当前版本新增'},
        {code:3,name:'当前版本删除'},
      ],
      relevanceStatusOptions:[
        {code:0,name:'全部'},
        {code:1,name:'已关联'},
        {code:2,name:'未关联'},
      ]


    }
  },
  computed: {
    tableOptions() {
      return [
        {
          id: 1,
          prop: 'standardCode',
          name: '本位码',
          fixed: false,
          show: true,
          sortable: this.queryParams.versionNo?false:'custom',
          align: 'center',
          width: '100'
        },
        {
          id: 2,
          prop: 'drugName',
          name: '药品名称',
          fixed: false,
          show: true,
          sortable: this.queryParams.versionNo?false:'custom',
          align: 'center',
          width: '120'
        },
        {
          id: 3,
          prop: 'approvalNum',
          name: '批准文号',
          fixed: false,
          show: true,
          sortable: this.queryParams.versionNo?false:'custom',
          align: 'center',
          width: '100'
        },
        {
          id: 4,
          prop: 'dosageForm',
          name: '剂型',
          fixed: false,
          show: true,
          align: 'center',
          width: '80'
        },
        {
          id: 5,
          prop: 'spec',
          name: '规格',
          fixed: false,
          show: true,
          align: 'center',
          width: '100'
        },
        {
          id: 6,
          prop: 'domesticOrImport',
          name: '国产进口标志',
          fixed: false,
          show: true,
          align: 'center',
          width: '80'
        },
        {
          id: 7,
          prop: 'mah',
          name: '上市许可持有人',
          fixed: false,
          show: true,
          sortable: this.queryParams.versionNo?false:'custom',
          align: 'center',
          width: '100'
        },{
          id: 8,
          prop: 'manufacturer',
          name: '生产企业',
          fixed: false,
          show: true,
          sortable: this.queryParams.versionNo?false:'custom',
          align: 'center',
          width: '100'
        },{
          id: 9,
          prop: 'standardCodeRemark',
          name: '备注',
          fixed: false,
          show: true,
          align: 'center',
          width: '100'
        },{
          id: 10,
          prop: 'modifyType',
          name: '数据类型',
          fixed: false,
          show: true,
          align: 'center',
          width: '60'
        }
      ]
    }
  },
  created() {
    this.$nextTick(() => {
      this.setTableHeight()
      if(this.$route.query && this.$route.query.versionNo){
        // 从其他页面路由传参版本号，快速搜索
        this.queryParams.versionNo=this.$route.query.versionNo
      }
      /* 查询全部版本号*/
      selectAllVersionNo().then(response => {
        this.versionNoOptions = response.data
        if( this.versionNoOptions.length==0){
          this.msgInfo("当前无数据版本，请先执行上传操作")
          this.loading=false
          return
        }
        //页面初始化完毕，默认触发一次排序
        //this.$refs.refundTable.sort('drugName','descending');
        this.handleQuery()
      })
    })

  },
  activated() {
    //激活时重新设置表格高度
    this.setTableHeight()
  },
  mounted() {
    var _this = this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      AcrossUtil.$emit('resetTableHeight')
    }

    AcrossUtil.$on('resetTableHeight', function(param) {
      setTimeout(function() {
        _this.setTableHeight()
      }, 300)
    })

  },
  methods: {
    /*设置表格的高度*/
    setTableHeight() {
      // 获取高度值 （内容高+padding+边框）
      if( this.$refs.headerQuery){
        let formHeight = this.$refs.headerQuery.offsetHeight
        this.tableHeight = window.innerHeight - formHeight - 150
      }

    },
    /*鼠标右键点击事件*/
    rowContextmenu(row, column, event) {
      this.tableContextMenuVisible = false
      this.tableContextMenuVisible = true
      // 阻止右键默认行为
      event.preventDefault()
      this.$nextTick(() => {
        this.$refs.contextbutton.init(row, column, event)
      })
    },
    /* 取消鼠标监听事件 菜单栏*/
    foo() {
      this.tableContextMenuVisible = false
      document.removeEventListener('click', this.foo)
    },
    /* 处理表格右键菜单点击*/
    handleTableAction(actionName, row, column) {
      // console.log(row)
      this.substanceName = row.substanceName
      if (actionName === '差异比较') {
        this.handleCompare(row)
      }
    },
    /** 查询上传记录列表 */
    async selectCfdaDrugList() {
      this.loading = true
      let response
      if(this.queryParams.versionNo){
        //有版本号，查询带版本的数据
        response=await cfdaDrugList(this.queryParams)
      }else{
        //没有版本号，查询全量数据
        response=await fullCfdaDrugList(this.queryParams)
      }
      this.drugList = response.rows
      this.total = response.total
      this.loading = false
      /*if(this.queryParams.modifyType==1){
        this.handleUpdateTableData()
      }*/
      this.handleUpdateTableData()
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.isResult = true
      this.queryParams.pageNum = 1
      this.selectCfdaDrugList()
    },
    /*表格排序切换*/
    tableSortChange({column, prop, order}){
      if(order!=null){
        order=order=='descending'?'desc':'asc';
      }else{
        order=''
      }
      this.queryParams.orderByColumn=prop
      this.queryParams.isAsc=order
      this.handleQuery()
    },

    compareTableCellClass({row, column, rowIndex, columnIndex}){
      let property=column.property
      if(['drugName','dosageForm','spec','approvalNum','standardCodeRemark',
        'mahZh','mahEn','manufacturerZh','manufacturerEn','mah','manufacturer'].indexOf(property)>-1 ){
        let nowVersionRow
        let lastVersionRow
        if(row.dataType=='当前版本'){
           nowVersionRow=row
           lastVersionRow=this.drugList[rowIndex+1]
        }else if(row.dataType=='上一个版本'){
          nowVersionRow=this.drugList[rowIndex-1]
          lastVersionRow=row
        }
       if(nowVersionRow &&lastVersionRow && nowVersionRow[property]!=lastVersionRow[property]){
          return 'difference-cell';
        }
      }else{
        return '';
      }
    },
    /*处理-修改类型的数据*/
    handleUpdateTableData(){
      let newDrugList=[]
      this.drugList.forEach(item=>{
        let nowVersion=item
        let lastVerson=item.lastVersionCfdaDrug
        if(item.modifyType==1){
          for(let key in nowVersion){
            if(['drugName','dosageForm','spec','approvalNum','standardCodeRemark',
              'mahZh','mahEn','manufacturerZh','manufacturerEn','mah','manufacturer'].indexOf(key)>-1){
              if(nowVersion[key]!=lastVerson[key]){
                nowVersion.hasDifference=true
                nowVersion.dataType='当前版本'
                lastVerson.hasDifference=true
                lastVerson.dataType='上一个版本'
                lastVerson.modifyType=1
              }
            }
          }
          newDrugList.push(nowVersion,lastVerson)
        }else{
          newDrugList.push(nowVersion)
        }

      })
      this.drugList=newDrugList
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      //console.log("column",column)
      let property=column.property
      if (property === "standardCode" ) {
        if(row.dataType=='当前版本'){
          return {
            rowspan: 2,
            colspan: 1
          }
        }else if(row.dataType=='上一个版本'){
          return {
            rowspan: 0,
            colspan: 0
          }
        }else{
          return {
            rowspan: 1,
            colspan: 1
          }
        }
      }
    },
    /*版本号变化*/
    versionNoChange(val){
      this.queryParams.relevanceStatus=0
      this.handleQuery()
    }



  }
}
</script>

<style lang="scss" scoped>
.el-table .cell .el-form-item {
  margin-bottom: 0px;
}

</style>



<style>

.el-table .difference-cell .cell {
  color:#f50707;
}


</style>
