<template>
  <div class="app-container" style="height: 100%;">
    <el-row style="height: 100%;" v-loading="loading">
      <el-col :span="12" style="height: 100%;">
        <div style="height: 50px;line-height: 50px;background: #f4f4f5;
        padding-left:20px;border-right: 1px solid #DCDFE6">
          <el-input v-model="treeSearchVal" size="small" placeholder="请输入内容" style="width: 300px;"></el-input>


          <el-button type="info" size="small" style="margin-left: 30px;"
                     icon="el-icon-refresh" @click="selectResourceCategoryTree">刷新
          </el-button>

          <el-button type="primary" size="small" style="margin-left: 10px;"
                     icon="el-icon-circle-plus-outline" @click="addCategory">新增
          </el-button>
        </div>
        <div style="height: calc(100% - 50px);overflow: auto;padding-top: 20px;">
          <el-tree :data="treeData" ref="categoryTree"
                   default-expand-all
                   :props="defaultTreeProps"
                   :expand-on-click-node="false"
                   :highlight-current="true"
                   @node-click="handleNodeClick"
                   :filter-node-method="filterTreeNode"></el-tree>
        </div>
      </el-col>
      <el-col :span="12" style="height: 100%;overflow: auto;">
        <div style="height: 50px;line-height: 50px;background: #f4f4f5;padding-left:20px">
          {{ editCategoryForm.typeName }}
        </div>
        <div style="height: calc(100% - 50px);overflow: auto;;padding-top: 20px;">
          <el-form :model="editCategoryForm" ref="editCategoryForm"
                   style="width: calc(100% - 10px)"
                   v-show="editCategoryForm.id"
                   class="edit-category-form" label-width="120px">

            <el-form-item
              label="上级分类"
              prop="pid"
            >
              <treeselect
                v-if="selectTreeShow"
                v-model="editCategoryForm.pid"
                :options="treeData"
                :normalizer="normalizer"
                :show-count="true"
                placeholder="选择上级分类"
              />
            </el-form-item>
            <el-form-item label="排序码:">
              <el-input v-model="editCategoryForm.orderNo"></el-input>
            </el-form-item>

            <el-form-item label="分类名称:" prop="typeName"
                          :rules="[{ required: true, message: '请输入分类名称', trigger: 'blur'}]">
              <el-input v-model="editCategoryForm.typeName" maxlength="50" clearable show-word-limit></el-input>
            </el-form-item>

            <el-form-item label="国内外标签:">
              <el-radio-group v-model="editCategoryForm.domesticFlag" size="small">
                <el-radio-button :label="1">国内</el-radio-button>
                <el-radio-button :label="2">国外</el-radio-button>
              </el-radio-group>
            </el-form-item>

            <el-divider></el-divider>
            <el-form-item v-for="(item, index) in editCategoryForm.propertyList"
                          :prop="'propertyList.' + index + '.contents'"
                          :key="'propertyList-'+index"
                          :rules="[{ validator: (rule, value, callback)=>{validateProperty(rule, value, callback,item)}, trigger: 'blur'}]"
                          :label="item.dictName+':'">
              <el-input v-model="item.contents" v-if="item.dictType==1" style="width: calc(100% - 60px)"></el-input>
              <el-upload v-if="item.dictType==2"
                         style="float: left;width: calc(100% - 60px)"
                         :action="baseURL+'/system/file/uploadFile'"
                         :file-list="item.fileList"
                         :data="uploadParams"
                         :on-preview="handlePicturePreview"
                         accept=".bmp, .gif, .jpg, .jpeg, .png"
                         :auto-upload="true"
                         :before-upload="beforeUpload"
                         :on-remove="(file, fileList)=>{handleFileRemove(file, fileList,item,index)}"
                         :on-success="(res, file, fileList)=>{uploadSuccess(res, file, fileList,item,index)}"
                         list-type="picture-card">

                <i slot="default" class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__tip">只能上传bmp/gif/jpg/jpeg/png文件，且大小不超过5MB</div>
              </el-upload>
              <el-date-picker v-if="item.dictType==3"
                              value-format="yyyy-MM"
                              format="yyyy-MM"
                              style="width: calc(100% - 60px)"
                              v-model="item.contents" type="date" placeholder="选择日期">
              </el-date-picker>
              <i class="el-icon-remove" style="" @click.prevent="delProperty(item, index)"></i>
            </el-form-item>
            <el-form-item style="margin-bottom: 50px;">
              <el-popover
                placement="top-start"
                width="400"
                trigger="click">
                <el-table :data="propertyDict">
                  <el-table-column min-width="100" property="dictName" label="名称"></el-table-column>
                  <el-table-column min-width="80" property="dictType" label="类型">
                    <template slot-scope="scope">
                      <el-tag type="success" v-if="scope.row.dictType==1">文本输入</el-tag>
                      <el-tag v-if="scope.row.dictType==2">图片上传</el-tag>
                      <el-tag type="warning" v-if="scope.row.dictType==3">日期(yyyy-MM)</el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column width="80" p label="操作">
                    <template slot-scope="scope">
                      <el-button @click="handleAddProperty(scope.row)" type="text" size="small">选择</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-button type="primary" plain class="addData" slot="reference"
                           style="width: calc(100% - 100px)">
                  <i class="el-icon-circle-plus el-icon--left"
                     style="color:#7C9DF7;width:16px;height:16px;display:inline-block;"></i>
                  添加属性
                </el-button>
              </el-popover>

            </el-form-item>

          </el-form>
        </div>
        <el-col  v-show="editCategoryForm.id"
          style="height: 80px;width:200px;padding-right: 0px;position: fixed;right: 20px;bottom: 6px;">
          <el-button type="danger" @click="delCategory()" size="small">删除</el-button>
          <el-button type="primary" @click="submitEdit()" size="small">保存</el-button>
        </el-col>
      </el-col>

    </el-row>
    <el-dialog v-dialogDrag title="图片预览" width="70%" :visible.sync="picturePreviewDialogVisible">
      <img width="100%" :src="picturePreviewUrl" alt="">
    </el-dialog>
    <el-dialog v-dialogDrag
      title="编辑分类信息"
      :visible.sync="editCategoryDialogVisible"
      width="50%"
      :close-on-click-modal="false"
    >
      <el-row>
        <el-col :span="24">
          <el-form :model="addCategoryForm" ref="addCategoryForm" label-width="120px">

            <el-form-item
              label="上级分类"
              prop="pid"
            >
              <treeselect
                v-if="selectTreeShow"
                v-model="addCategoryForm.pid"
                :options="treeData"
                :normalizer="normalizer"
                :show-count="true"
                placeholder="选择上级分类"
              />
            </el-form-item>
            <el-form-item label="排序码:">
              <el-input v-model="addCategoryForm.orderNo"></el-input>
            </el-form-item>

            <el-form-item label="分类名称:" prop="typeName"
                          :rules="[{ required: true, message: '请输入分类名称', trigger: 'blur'}]">
              <el-input v-model="addCategoryForm.typeName" maxlength="50" clearable show-word-limit></el-input>
            </el-form-item>

            <el-form-item label="国内外标签:">
              <el-radio-group v-model="addCategoryForm.domesticFlag" size="small">
                <el-radio-button :label="1">国内</el-radio-button>
                <el-radio-button :label="2">国外</el-radio-button>
              </el-radio-group>
            </el-form-item>

          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer" style="margin-right:20px;">
        <el-button @click="editCategoryDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submitAdd()" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>


</template>

<script>

import {isEmpty} from '@/utils/common'
import {getResourceCategoryTree} from '@/utils/resourceReference'
import {delFile} from '@/api/system/fileUpload'
import {
  editResourceCategory,
  resourceCategoryInfo,
  getResourcePropertyDict,
  delResourceCategory
} from '@/api/dkm/resource'
import Treeselect from "@riophae/vue-treeselect";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {deepClone} from "../../../../utils";

export default {
  name: 'ResourceCategory',
  components: {Treeselect},
  data() {
    return {
      // 遮罩层
      loading: true,
      baseURL: process.env.VUE_APP_BASE_API,
      uploadParams: {
        fileClassify: 4,
        tempFlag: 1
      },
      treeSearchVal: '',
      treeData: [],
      defaultTreeProps: {
        children: 'children',
        label: 'typeName'
      },
      editCategoryDialogVisible: false,
      addCategoryForm: {},
      addFlag:false,
      selectTreeShow: true,
      editCategoryForm: {
        propertyList: []
      },
      propertyDict: [],
      picturePreviewDialogVisible: false,
      picturePreviewUrl: '',

    }
  },
  watch: {
    // 监听输入框的值变化
    treeSearchVal(val) {
      this.$refs.categoryTree.filter(val);
    }
  },
  created() {
    this.selectResourceCategoryTree()
    getResourcePropertyDict().then((res) => {
      this.propertyDict = res.data
    })

  },
  activated() {
  },
  mounted() {
  },
  methods: {
    //获取分类的树形数据
    selectResourceCategoryTree() {
      this.loading=true
      getResourceCategoryTree().then(result => {
        this.treeData = result
        this.$nextTick(() => {
          // 触发点击事件,默认点击第一个节点
          this.$refs.categoryTree.$el.querySelector('div[role=treeitem]').click()
        })
        this.loading=false
      })
    },
    //树节点过滤
    filterTreeNode(value, data) {
      if (isEmpty(value)) {
        return true;
      }
      return data.typeName.indexOf(value) !== -1
        || (data.spell && data.spell.toLowerCase().indexOf(value) !== -1);
    },
    //新增分类信息
    addCategory(data) {
      this.editCategoryDialogVisible = true
      this.selectTreeShow = false
      this.addFlag=true
      this.addCategoryForm = {
        domesticFlag: 1
      }
      this.resetForm("addCategoryForm");
      // 刷新选择父级分类的树
      this.$nextTick(() => {
        this.selectTreeShow = true
      })
    },
    //转换选择上级树的结构
    normalizer(node) {
      if (!(node.children && node.children.length > 0)) {
        delete node.children
      }
      let isDisabled = false
      let editForm=this.editCategoryForm
      if (this.addFlag==false && editForm && editForm.id == node.id) {
        isDisabled = true
      }
      return {
        id: node.id,
        label: node.typeName,
        children: node.children,
        isDisabled: isDisabled
      }
    },
    //新增
    submitAdd() {
      this.$refs['addCategoryForm'].validate(valid => {
        if (valid) {
          editResourceCategory(this.addCategoryForm).then(response => {
            this.msgSuccess()
            this.selectResourceCategoryTree()
            this.editCategoryDialogVisible = false
            this.addFlag=false
          })
        } else {
          this.msgError('请规范填写')
        }
      })
    },
    /* 左侧树节点点击*/
    handleNodeClick(data, node) {
      let resourceCategoryId = data.id
      resourceCategoryInfo(resourceCategoryId).then(res => {
        this.editCategoryForm = deepClone(res.data)
        this.addFlag=false
        this.selectTreeShow = false
        let pid = this.editCategoryForm.pid
        if (pid == 0) {
          this.editCategoryForm.pid = undefined
        }
        this.editCategoryForm.propertyList.forEach(item => {
          if (item.dictType == 2) {
            item.fileList = []
            let baseURL = this.baseURL
            if (!isEmpty(item.contents)) {
              item.contents.split("，").forEach(i => {
                let fileId = i.trim()
                item.fileList.push({
                  fileId: fileId,
                  url: baseURL + '/system/file/preview?id=' + fileId,
                })
              })
            }
          }
        })
        // 刷新选择父级分类的树
        this.$nextTick(() => {
          this.selectTreeShow = true
        })
      })

    },
    // 删除属性
    delProperty(item, index) {
      if (item.dictType == 2 && item.fileList.length > 0) {
        this.msgInfo("该属性下仍有图片文件，请先删除图片文件再执行删除操作")
        return
      }
      this.editCategoryForm.propertyList.splice(index, 1)
    },
    // 添加属性
    handleAddProperty(row) {
      this.editCategoryForm.propertyList.push({
        dictId: row.dictId,
        dictName: row.dictName,
        dictType: row.dictType,
        categoryId: this.editCategoryForm.id,
        fileList: []
      })

    },
    // 执行上传文件
    submitUpload() {
      //this.$refs.upload.submit();
    },
    beforeUpload(file) {
      if (file.size / 1024 / 1024 > 5) {
        this.$message.error('上传图片大小不能超过 5MB');
        return false;
      }
    },
    // 图片预览
    handlePicturePreview(file) {
      this.picturePreviewDialogVisible = true
      console.log(file.url)
      this.picturePreviewUrl = file.url
    },
    //移除文件
    handleFileRemove(file, fileList, item, index) {
      if (file.fileId == undefined) {
        return
      }
      let nowFileList = this.editCategoryForm.propertyList[index].fileList
      //移除预览文件
      let fileIndex = -1
      nowFileList.some((t, i) => {
        if (t.uid == file.uid) {
          fileIndex = i
          return true
        }
      })
      nowFileList.splice(fileIndex, 1)
      //调用接口删除文件记录
      delFile(file.fileId)
    },
    // 文件上传结束的回调
    uploadSuccess(res, file, fileList, item, index) {
      this.editCategoryForm.propertyList[index].contents = "#"
      let nowFileList = this.editCategoryForm.propertyList[index].fileList
      if (res.code == 200) {
        this.msgSuccess("上传成功")
        let fileItem = {
          fileId: res.data.id,
          name: file.name,
          url: this.baseURL + '/system/file/preview?id=' + res.data.id,
          status: "success",
          uid: file.uid
        }
        nowFileList.push(fileItem)
      } else {
        this.msgError(res.msg || '上传失败')
        //移除预览文件
        let fileIndex = -1
        fileList.some((t, i) => {
          if (t.uid == file.uid) {
            fileIndex = i
            return true
          }
        })
        fileList.splice(fileIndex, 1)
      }

    },
    // 保存编辑
    submitEdit() {
      this.$refs['editCategoryForm'].validate(valid => {
        if (valid) {
          editResourceCategory(this.editCategoryForm).then(response => {
            this.msgSuccess()
            this.selectResourceCategoryTree()
          })
        } else {
          this.msgError('请规范填写')
        }
      })

    },
    //删除分类
    delCategory() {
      let form = this.editCategoryForm
      let _this = this
      this.$confirm('确认要删除' + '[' + form.typeName + ']-' + '吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        delResourceCategory(form.id).then(response => {
          _this.msgSuccess()
          _this.selectResourceCategoryTree()
        })
      })


    },
    validateProperty(rule, value, callback, item) {
      if (isEmpty(value) && item.dictType == 1) {
        callback(new Error('请输入' + item.dictName))
      } else if (item.dictType == 2 && item.fileList.length == 0) {
        callback(new Error('请上传图片'))
      } else if (isEmpty(value) && item.dictType == 3) {
        callback(new Error('请输入' + item.dictName))
      }else {
        callback()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/*删除按钮*/
.el-icon-remove {
  color: #F52C2C;
  font-size: 20px;
  padding: 0px 6px;
}


</style>

<style>
/*调整图片上传组件缩略图的大小*/
.edit-category-form .el-upload-list .el-upload-list__item {
  width: 100px;
  height: 100px;
}

.edit-category-form .el-upload {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
</style>
