var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
      staticStyle: { overflow: "auto" },
    },
    [
      _c(
        "div",
        { ref: "headerQuery" },
        [
          _c(
            "el-row",
            { staticClass: "headerClass" },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "border-left": "5px solid #0073E9",
                    "padding-left": "10px",
                  },
                  attrs: { span: 4 },
                },
                [_vm._v("上传记录")]
              ),
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "queryForm",
                      staticStyle: { float: "right" },
                      attrs: { model: _vm.queryParams, inline: true },
                    },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-search",
                                size: "small",
                              },
                              on: { click: _vm.handleQuery },
                            },
                            [_vm._v("检索")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "addClass",
                              attrs: { icon: "el-icon-upload2", size: "small" },
                              on: { click: _vm.handleUpload },
                            },
                            [_vm._v("同步数据")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { ref: "tableContainer" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "refundTable",
              attrs: {
                border: "",
                stripe: "",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
                data: _vm.uploadList,
                height: _vm.tableHeight,
                "default-sort": { prop: "versionNo", order: "descending" },
              },
              on: {
                "sort-change": _vm.tableSortChange,
                "row-contextmenu": _vm.rowContextmenu,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", "min-width": "50", label: "序号" },
              }),
              _c("el-table-column", {
                attrs: {
                  "min-width": "100",
                  property: "versionNo",
                  label: "版本号",
                  sortable: "custom",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  "min-width": "180",
                  property: "uploadTime",
                  label: "操作时间",
                  sortable: "custom",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  "min-width": "80",
                  property: "totalSize",
                  label: "总数据量",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  "min-width": "200",
                  property: "dataDetail",
                  label: "更新明细",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            staticStyle: {
                              width: "100px",
                              margin: "0px 4px 4px 0px",
                            },
                            attrs: { type: "info" },
                          },
                          [_vm._v("无变化:" + _vm._s(scope.row.noChangeSize))]
                        ),
                        _c("el-tag", { staticStyle: { width: "100px" } }, [
                          _vm._v("修改:" + _vm._s(scope.row.updateSize)),
                        ]),
                        _c("br"),
                        _c(
                          "el-tag",
                          {
                            staticStyle: {
                              width: "100px",
                              margin: "0px 4px 4px 0px",
                            },
                            attrs: { type: "success" },
                          },
                          [_vm._v("新增:" + _vm._s(scope.row.addSize))]
                        ),
                        _c(
                          "el-tag",
                          {
                            staticStyle: { width: "100px" },
                            attrs: { type: "danger" },
                          },
                          [_vm._v("删除:" + _vm._s(scope.row.delSize))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { "min-width": "80", property: "status", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.status == 2,
                                expression: "scope.row.status==2",
                              },
                            ],
                            attrs: { type: "success" },
                          },
                          [_vm._v("成功")]
                        ),
                        _c(
                          "el-tag",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.status == -1,
                                expression: "scope.row.status==-1",
                              },
                            ],
                            attrs: { type: "danger" },
                          },
                          [_vm._v("失败")]
                        ),
                        _c(
                          "el-tag",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.status == 1,
                                expression: "scope.row.status==1",
                              },
                            ],
                            attrs: { type: "warning" },
                          },
                          [_vm._v("运行中")]
                        ),
                        _c("br"),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.status == -1,
                                expression: "scope.row.status==-1",
                              },
                            ],
                          },
                          [_vm._v(_vm._s(scope.row.errorMsg))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  "min-width": "80",
                  property: "elapsedTime",
                  label: "执行耗时(分钟)",
                },
              }),
              _vm.uploadList.length == 0
                ? _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-booking.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ])
                : _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-left-data.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("未查询到结果，请修改查询条件重试！")]),
                  ]),
            ],
            2
          ),
        ],
        1
      ),
      _vm.tableContextMenuVisible
        ? _c("table-context-menu", {
            ref: "contextbutton",
            attrs: { action: _vm.tableAction },
            on: { foo: _vm.foo, handleTableAction: _vm.handleTableAction },
          })
        : _vm._e(),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
          "page-sizes": [10, 20, 50, 100],
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.selectUploadRecordList,
        },
      }),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "数据处理进度",
            width: "70%",
            "append-to-body": "",
            visible: _vm.progressDialogVisible,
            "close-on-click-modal": false,
            "show-close": _vm.progressDialogShowClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.progressDialogVisible = $event
            },
            close: _vm.handleQuery,
          },
        },
        [
          _c(
            "el-steps",
            { attrs: { active: _vm.progress.active, "align-center": "" } },
            [
              _c("el-step", {
                attrs: {
                  title: "步骤1 开始",
                  description: _vm.progress.step1Desc,
                },
              }),
              _c("el-step", {
                attrs: {
                  title: "步骤2 下载国家医保数据",
                  description: _vm.progress.step2Desc,
                },
              }),
              _c("el-step", {
                attrs: {
                  title: "步骤3 与上个版本差异比较",
                  description: _vm.progress.step3Desc,
                },
              }),
              _c("el-step", {
                attrs: {
                  title: "步骤4 生成差异报告",
                  description: _vm.progress.step4Desc,
                },
              }),
              _c("el-step", {
                attrs: {
                  title: "步骤5 完成",
                  description: _vm.progress.step5Desc,
                },
              }),
            ],
            1
          ),
          _c("p", { staticStyle: { color: "red" } }, [
            _vm._v(_vm._s(_vm.progress.errorMsg)),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }