import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/common'
// 查询全部api列表
export function selectApiList(query) {
  return request({
    url: '/api/selectApiList',
    method: 'post',
    params: query
  })
}


// 查询全部api所属服务列表
export function selectApiServerNameList() {
  return request({
    url: '/api/selectApiServerNameList',
    method: 'post',
  })
}


// 保存或新增api
export function saveOrUpdateApi(data) {
  return request({
    url: '/api/saveOrUpdateApi',
    method: 'post',
    data: data
  })
}


// 删除api
export function delApi(apiId) {
  return request({
    url: '/api/delApi/' + praseStrEmpty(apiId),
    method: 'get'
  })
}

// 转发post请求 x-www-form-urlencoded
export function executeFormPost(params,url,apiToken) {
  return request({
    url: '/api/execute/'+url,
    method: 'post',
    params:params,
    timeout: 30 * 1000,
    data:{},
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'api-token':apiToken
    }
  })
}


// 转发get请求
export function executeFormGet(params,url,apiToken) {
  return request({
    url: '/api/execute/'+url,
    method: 'get',
    params:params,
    timeout: 30 * 1000,
    data:{},
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'api-token':apiToken
    }
  })
}



// 转发post请求  application/json
export function executeJsonPost(data,url,apiToken) {
  return request({
    url: '/api/execute/'+url,
    method: 'post',
    data:data,
    timeout: 30 * 1000,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      'api-token':apiToken
    }
  })
}


// 给appId授权api
export function apiAuthorization(appId,apiId) {
  return request({
    url: '/api/apiAuthorization',
    method: 'post',
    params: {apiId:apiId,appId:appId}
  })
}


// 根据appId查询全部已授权的api列表
export function selectApiListByAppId(appId) {
  return request({
    url: '/api/selectApiListByAppId',
    method: 'post',
    params: {appId:appId}
  })
}



// 测试用户填写的callbackUrl是否可以连通
export function testCallbackUrl(callbackUrl,token) {
  return request({
    url: '/api/testCallbackUrl',
    method: 'get',
    params: {callbackUrl:callbackUrl,token:token}
  })
}


// 查询消息推送历史记录
export function pushMsgLogList(query) {
  return request({
    url: '/api/pushMsgLogList',
    method: 'post',
    params: query
  })
}

// 推送消息
export function pushMsg(appId,msgXml) {
  return request({
    url: '/api/pushMsg',
    method: 'post',
    params: {appId:appId,msgXml:msgXml}
  })
}



