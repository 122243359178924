<!--平台审核报告操作的表格-->
<template>
  <el-container class="layout-container platform-report-container">
    <el-header class="platform-report-header">
      <div class="title-header-container">
        <span class="title-header"></span>
        <h4 class="title-text">审核报告</h4>
      </div>
      <el-form :model="tableSearchObj" :inline="true">
        <el-form-item>
          <el-input v-model="tableSearchObj.searchValue" clearable placeholder="输入编号/药品通用名" />
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" icon="el-icon-search" @click="onSearchReportTable">查询</el-button>
        </el-form-item>
        <el-form-item label="筛选模式">
          <el-switch
            v-model="isFilter"
            active-color="#2c5ff9"
            inactive-color="#b5ccf5"
            @change="filterChange">
          </el-switch>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="onPublishReport">发布报告</el-button>
        </el-form-item>
      </el-form>
    </el-header>
    <el-main class="platform-report-main">
      <el-table
        :data="tableData"
        :header-cell-class-name="headerGradeInfo"
        :cell-class-name="gradeInfo"
        :header-cell-style="{textAlign:'center'}"
        style="width: 100%;"
        height="100%"
        class="platform-report-table"
        border>
        <el-table-column
          align="center"
          width="160"
          prop="drugId">
          <template slot="header" slot-scope="scope">
            <span>药品编码</span>
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.drugId }}</span>
          </template>
        </el-table-column>
        <!--药品通用名-->
        <el-table-column
          width="180"
          prop="genericNameCompare">
          <template slot="header" slot-scope="scope">
            <span>药品通用名</span>
          </template>
          <template slot-scope="scope">
            <span class="fl">{{ scope.row.genericNameCompare }}</span>
            <span v-if="isShowErrorDetails(scope)" class="error-details fr">
              {{scope.row.genericNameCompareerrorTypeStr}}
            </span>
          </template>
        </el-table-column>
        <!--药品通用名的打分和筛选模式-->
        <el-table-column
          width="50"
          class="none-border"
          prop="genericNameCompareStatus">
          <template slot="header" slot-scope="scope">
            <span style="display: inline-block;width: 3px;height: 3px;"></span>
            <el-popover
              :ref="'elPopover' + scope.column.property"
              trigger="click"
              placement="right"
              popper-class="governance-poper"
              :visible-arrow="false"
              @hide="onHidePopover(scope)"
              @show="onShowPopover(scope)">
              <filter-cascader
                :ref="'filter' + scope.$index"
                :show-filter="isFilter"
                :filter-options="filterOptions"
                :column="scope"
                @closeFilter="onCloseFilter" />
              <svg-icon
                slot="reference"
                icon-class="shaixuan"
                class="filter-icon"
                v-show="isFilter"
                style="position: absolute; right: 10px;width: 20px;height: 20px;"
                @click="onFilterClick(scope)"/>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <!--打分（成功、疑问、失败）-->
            <el-popover
              :ref="'gradePopover' + scope.column.property"
              trigger="click"
              placement="bottom"
              :visible-arrow="false"
              popper-class="governance-poper"
              @hide="onHidePopover(scope)"
              @show="onShowPopover(scope)">
              <grade-cascader
                :ref="'grade' + scope.column.property + scope.$index"
                :status="scope.row.genericNameCompareStatus"
                :show-filter="isFilter"
                :filter-options="gradeOptions"
                @closeFilter="onCloseGradeFilter" />
              <div slot="reference" @click="onGradeFilterClick(scope)">
                <svg-icon
                  :icon-class="gradeIcon(scope.row.genericNameCompareStatus)"
                  class="filter-icon"
                  style="position: absolute; right: 16px;top: 50%;transform: translateY(-50%);width: 18px;height: 18px;"/>
                  <transition>
                    <i v-if="gradeIconFolded(scope)" class="el-icon-caret-bottom icon-class"/>
                    <i v-else class="el-icon-caret-top icon-class"/>
                  </transition>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          label="规格"
          prop="specCompare">
          <template slot="header" slot-scope="scope">
            <span>规格</span>
            <!-- <el-popover
              ref="elPopover"
              trigger="click"
              placement="right"
              popper-class="governance-poper"
              :visible-arrow="false"
              @hide="onHidePopover(scope)"
              @show="onShowPopover(scope)">
              <filter-cascader
                :ref="'filter' + scope.$index"
                :show-filter="isFilter"
                :filter-options="filterOptions"
                :column="scope"
                @closeFilter="onCloseFilter" />
              <svg-icon
                slot="reference"
                icon-class="shaixuan"
                class="filter-icon"
                v-show="isFilter"
                style="position: absolute; right: 10px;width: 20px;height: 20px;"
                @click="onFilterClick(scope)"/>
            </el-popover> -->
          </template>
          <template slot-scope="scope">
            <span class="fl">{{ scope.row.specCompare }}</span>
            <span v-if="isShowErrorDetails(scope)" class="error-details fr">
              {{scope.row.specCompareerrorTypeStr}}
            </span>
          </template>
        </el-table-column>
        <!--规格的打分和筛选模式-->
        <el-table-column
          width="50"
          prop="specCompareStatus">
          <!--筛选模式-->
          <template slot="header" slot-scope="scope">
            <span style="display: inline-block;width: 3px;height: 3px;"></span>
            <el-popover
              :ref="'elPopover' + scope.column.property"
              trigger="click"
              placement="right"
              popper-class="governance-poper"
              :visible-arrow="false"
              @hide="onHidePopover(scope)"
              @show="onShowPopover(scope)">
              <filter-cascader
                :ref="'filter' + scope.$index"
                :show-filter="isFilter"
                :filter-options="filterOptions"
                :column="scope"
                @closeFilter="onCloseFilter" />
              <svg-icon
                slot="reference"
                icon-class="shaixuan"
                class="filter-icon"
                v-show="isFilter"
                style="position: absolute; right: 10px;width: 20px;height: 20px;"
                @click="onFilterClick(scope)"/>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <!--打分（成功、疑问、失败）-->
            <el-popover
              :ref="'gradePopover' + scope.column.property"
              trigger="click"
              placement="bottom"
              :visible-arrow="false"
              popper-class="governance-poper"
              @hide="onHidePopover(scope)"
              @show="onShowPopover(scope)">
              <grade-cascader
                :ref="'grade' + scope.column.property + scope.$index"
                :status="scope.row.specCompareStatus"
                :show-filter="isFilter"
                :filter-options="gradeOptions"
                @closeFilter="onCloseGradeFilter" />
              <div slot="reference" @click="onGradeFilterClick(scope)">
                <svg-icon
                  :icon-class="gradeIcon(scope.row.specCompareStatus)"
                  class="filter-icon"
                  style="position: absolute; right: 16px;top: 50%;transform: translateY(-50%);width: 18px;height: 18px;"/>
                  <transition>
                    <i v-if="gradeIconFolded(scope)" class="el-icon-caret-bottom icon-class"/>
                    <i v-else class="el-icon-caret-top icon-class"/>
                  </transition>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          label="剂型名称"
          width="180"
          prop="formNameCompare">
          <template slot="header" slot-scope="scope">
            <span>剂型名称</span>
          </template>
          <template slot-scope="scope">
            <span class="fl">{{ scope.row.formNameCompare }}</span>
            <span v-if="isShowErrorDetails(scope)" class="error-details fr">
              {{scope.row.formNameCompareerrorTypeStr}}
            </span>
          </template>
        </el-table-column>
        <!--剂型名称的筛选模式和打分-->
        <el-table-column
          width="50"
          prop="formNameCompareStatus">
          <template slot="header" slot-scope="scope">
            <span style="display: inline-block;width: 3px;height: 3px;"></span>
            <el-popover
              :ref="'elPopover' + scope.column.property"
              trigger="click"
              placement="right"
              popper-class="governance-poper"
              :visible-arrow="false"
              @hide="onHidePopover(scope)"
              @show="onShowPopover(scope)">
              <filter-cascader
                :ref="'filter' + scope.$index"
                :show-filter="isFilter"
                :filter-options="filterOptions"
                :column="scope"
                @closeFilter="onCloseFilter" />
              <svg-icon
                slot="reference"
                icon-class="shaixuan"
                class="filter-icon"
                v-show="isFilter"
                style="position: absolute; right: 10px;width: 20px;height: 20px;"
                @click="onFilterClick(scope)"/>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <!--打分（成功、疑问、失败）-->
            <el-popover
              :ref="'gradePopover' + scope.column.property"
              trigger="click"
              placement="bottom"
              :visible-arrow="false"
              popper-class="governance-poper"
              @hide="onHidePopover(scope)"
              @show="onShowPopover(scope)">
              <grade-cascader
                :ref="'grade' + scope.column.property + scope.$index"
                :status="scope.row.formNameCompareStatus"
                :show-filter="isFilter"
                :filter-options="gradeOptions"
                @closeFilter="onCloseGradeFilter" />
              <div slot="reference" @click="onGradeFilterClick(scope)">
                <svg-icon
                  :icon-class="gradeIcon(scope.row.formNameCompareStatus)"
                  class="filter-icon"
                  style="position: absolute; right: 16px;top: 50%;transform: translateY(-50%);width: 18px;height: 18px;"/>
                  <transition>
                    <i v-if="gradeIconFolded(scope)" class="el-icon-caret-bottom icon-class"/>
                    <i v-else class="el-icon-caret-top icon-class"/>
                  </transition>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <!--厂商-->
        <el-table-column
          label="厂商"
          prop="manufacturerCompare">
          <template slot="header" slot-scope="scope">
            <span>厂商</span>
          </template>
          <template slot-scope="scope">
            <span class="fl">{{ scope.row.manufacturerCompare }}</span>
            <span v-if="isShowErrorDetails(scope)" class="error-details fr">
              {{scope.row.manufacturerCompareerrorTypeStr}}
            </span>
          </template>
        </el-table-column>
        <!--厂商的筛选模式和打分-->
        <el-table-column
          width="50"
          prop="manufacturerCompareStatus">
          <template slot="header" slot-scope="scope">
            <!--筛选模式-->
            <span style="display: inline-block;width: 3px;height: 3px;"></span>
            <el-popover
              :ref="'elPopover' + scope.column.property"
              trigger="click"
              placement="right"
              popper-class="governance-poper"
              :visible-arrow="false"
              @hide="onHidePopover(scope)"
              @show="onShowPopover(scope)">
              <filter-cascader
                :ref="'filter' + scope.$index"
                :show-filter="isFilter"
                :filter-options="filterOptions"
                :column="scope"
                @closeFilter="onCloseFilter" />
              <svg-icon
                slot="reference"
                icon-class="shaixuan"
                class="filter-icon"
                v-show="isFilter"
                style="position: absolute; right: 10px;width: 20px;height: 20px;"
                @click="onFilterClick(scope)"/>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <!--打分（成功、疑问、失败）-->
            <el-popover
              :ref="'gradePopover' + scope.column.property"
              trigger="click"
              placement="bottom"
              :visible-arrow="false"
              popper-class="governance-poper"
              @hide="onHidePopover(scope)"
              @show="onShowPopover(scope)">
              <grade-cascader
                :ref="'grade' + scope.column.property + scope.$index"
                :status="scope.row.manufacturerCompareStatus"
                :show-filter="isFilter"
                :filter-options="gradeOptions"
                @closeFilter="onCloseGradeFilter" />
              <div slot="reference" @click="onGradeFilterClick(scope)">
                <svg-icon
                  :icon-class="gradeIcon(scope.row.manufacturerCompareStatus)"
                  class="filter-icon"
                  style="position: absolute; right: 16px;top: 50%;transform: translateY(-50%);width: 18px;height: 18px;"/>
                  <transition>
                    <i v-if="gradeIconFolded(scope)" class="el-icon-caret-bottom icon-class"/>
                    <i v-else class="el-icon-caret-top icon-class"/>
                  </transition>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <!--批准文号-->
        <el-table-column
          label="批准文号"
          prop="approvalNoCompare">
          <template slot="header" slot-scope="scope">
            <span>批准文号</span>
          </template>
          <template slot-scope="scope">
            <span class="fl">{{ scope.row.approvalNoCompare }}</span>
            <span v-if="isShowErrorDetails(scope)" class="error-details fr">
              {{scope.row.approvalNoCompareerrorTypeStr}}
            </span>
          </template>
        </el-table-column>
        <!--批准文号筛选模式和打分-->
        <el-table-column
          width="50"
          prop="approvalNoCompareStatus">
          <template slot="header" slot-scope="scope">
            <span style="display: inline-block;width: 3px;height: 3px;"></span>
            <el-popover
              ref="elPopover"
              trigger="click"
              placement="right"
              popper-class="governance-poper"
              :visible-arrow="false"
              @hide="onHidePopover(scope)"
              @show="onShowPopover(scope)">
              <filter-cascader
                :ref="'filter' + scope.$index"
                :show-filter="isFilter"
                :filter-options="filterOptions"
                :column="scope"
                @closeFilter="onCloseFilter" />
              <svg-icon
                slot="reference"
                icon-class="shaixuan"
                class="filter-icon"
                v-show="isFilter"
                style="position: absolute; right: 10px;width: 20px;height: 20px;"
                @click="onFilterClick(scope)"/>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <!--打分（成功、疑问、失败）-->
            <el-popover
              :ref="'gradePopover' + scope.column.property"
              trigger="click"
              placement="bottom"
              :visible-arrow="false"
              popper-class="governance-poper"
              @hide="onHidePopover(scope)"
              @show="onShowPopover(scope)">
              <grade-cascader
                :ref="'grade' + scope.column.property + scope.$index"
                :status="scope.row.approvalNoCompareStatus"
                :show-filter="isFilter"
                :filter-options="gradeOptions"
                @closeFilter="onCloseGradeFilter" />
              <div slot="reference" @click="onGradeFilterClick(scope)">
                <svg-icon
                  :icon-class="gradeIcon(scope.row.approvalNoCompareStatus)"
                  class="filter-icon"
                  style="position: absolute; right: 16px;top: 50%;transform: translateY(-50%);width: 18px;height: 18px;"/>
                  <transition>
                    <i v-if="gradeIconFolded(scope)" class="el-icon-caret-bottom icon-class"/>
                    <i v-else class="el-icon-caret-top icon-class"/>
                  </transition>
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="tableSearchObj.pageNum"
        :limit.sync="tableSearchObj.pageSize"
        @pagination="getTableHandle"/>
    </el-footer>
  </el-container>
  
</template>
<script>
  import {
    CHECK_REPORT_QUERY_OBJ,
    UPDATE_COLUMN_OBJ
  } from '@/utils/dm/platformGovernanceReport/data'
  import {
    getCheckRecordListDataApi,
    updateRecordDataApi,
    publishReportApi } from '@/api/dm/platformGovernanceReport'
  import {
    FILTER_OPTIONS,
    GRADE_OPTIONS,
    TABLE_DATA,
    TABLE_DATA_ORIGIN,
    TABLE_COLUMN_LIST
  } from '@/utils/dm/institutionGovernanceReport/data'
  import {
    handleTableData
  } from '@/utils/dm/institutionGovernanceReport/handle.js'
  import { UCgetConfig } from '@/utils/columnSettings'
  import { GLOBAL_CONFIGURATION_CONFIGKEY } from '@/utils/constants'
  import FilterCascader from '@/views/dm/institutionGovernanceReport/components/FilterCascader'
  import GradeCascader from '@/views/dm/institutionGovernanceReport/components/GradeCascader'
  export default {
    name: 'institutionGovernanceReport',
    components: {
      FilterCascader,
      GradeCascader
    },
    data() {
      return {
        filterOptions: this.$deepClone(FILTER_OPTIONS),
        gradeOptions: this.$deepClone( GRADE_OPTIONS),
        tableSearchObj: this.$deepClone(CHECK_REPORT_QUERY_OBJ),
        spanArr: [],
        pos: 0,
        isFilter: false,
        total: 0,
        tableData: [],
        cloneTableData: [],
        initTableData: [], // 表格获取到的接口数据，没有进行处理的
        currentRowItemByGrade: null, // 当前操作的表格数据
        updateColumnObj: this.$deepClone(UPDATE_COLUMN_OBJ), // 修改
        sltConfigKey: '', // 物质管理-物质列表-表格配置
        sltConfigClassify: '',
      }
    },
    computed: {
      gradeIcon: function () {
        return function (status) {
          if (Number(status) === 3) {
            // 失败
            return 'kc-empty'
          } else if (Number(status) === 2) {
            // 成功
            return 'duihao2'
          } else if (Number(status) === 1) {
            // 疑问
            return 'wenhao'
          }
        }
      },
      // 是否显示风险级别或问题类型的具体详情
      isShowErrorDetails: function () {
        return function (scope) {
          const riskLevel = scope.row[scope.column.property + 'riskLevel']
          if ((riskLevel === '3' || riskLevel === '4') && scope.$index % 2 === 0) {
            // 只有机构那一条且满足条件数据显示
            return true
          } else {
            return false
          }
        }
      },
      // 打分的折叠和展开图表切换
      gradeIconFolded: function () {
        return function (scope) {
          // 例如：formNameCompareStatus，将Status截取掉
          const propertyLen = scope.column.property.length
          let prop = scope.column.property.substring(0, propertyLen - 6) + 'isFolded'
          if (scope.row[prop]) {
            return true
          } else {
            return false
          }
        }
      }
    },
    async mounted() {
    },
    async created() {
      // await this.fetchTableData()
      await this.fetchData()
    },
    methods: {
      async fetchData() {
        this.tableSearchObj.applyId = this.$route.query.applyId
        let res = await getCheckRecordListDataApi(this.tableSearchObj)
        if (res.code === 200) {
          let tableData = res.data.rows || []
          this.initTableData = this.$deepClone(res.data.rows)
          this.cloneTableData = handleTableData(tableData)
          this.columnTableDataHandle() // 打分的图标初始为折叠状态
          this.tableData = this.$deepClone(this.cloneTableData)
          this.setMergeArr(this.tableData)
          this.total = res.data.total
        }
      },
      async fetchTableData() {
        const tableData = this.$deepClone(TABLE_DATA_ORIGIN)
        this.cloneTableData = handleTableData(tableData)
        this.columnTableDataHandle()
        this.tableData = this.$deepClone(this.cloneTableData)
        this.setMergeArr(this.tableData)
      },
      filterChange(val) {
        if (val) {
        }
      },
      setMergeArr(data) {　
        this.spanArr = []
        for (var i = 0; i < data.length; i++) {
          if (i === 0) {
            this.spanArr.push(1)
            this.pos = 0
          } else {
              // 判断当前元素与上一个元素是否相同
            if (data[i].ustdCode === data[i - 1].ustdCode) {
              this.spanArr[this.pos] += 1
              this.spanArr.push(0)
            } else {
              this.spanArr.push(1)
              this.pos = i
            }
          }
        }
      },
      getSummaries({ row, column, rowIndex, columnIndex }) {
        /* if (columnIndex === 0) { // 设置需要合并的列（索引）
          if (rowIndex % 2 === 0) { // 设置合并多少行
            return {
              rowspan: 2, // 需要合并的行数
              colspan: 1 // 需要合并的列数，设置为0，不显示该列
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        } else if (columnIndex % 2 === 0) { // 合并第二列所有行
          const _row = this.spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            // rowspan: _row,
            // colspan: _col
            rowspan: 2,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        } */
        if (columnIndex % 2 === 0) {
          if (rowIndex % 2 === 0) {
            return {
              rowspan: 2,
              colspan: 1
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        }
      },
      headerGradeInfo({ row, column, rowIndex, columnIndex }) {
        if (columnIndex % 2 === 1 && columnIndex !== 0) {
          return 'none-border'
        }
      },
      gradeInfo({ row, column, rowIndex, columnIndex }) {
        let color = this.tableDataHandle(row, column, rowIndex, columnIndex)
        return color
      },
      // 表格每一个列对应的打分列进行数据处理
      columnTableDataHandle(scope) {
        // 当column有值的时候表示操作的是当前列，当前列item[key + 'isFolded'] = false
        if (scope) {
          const propertyLen = scope.column.property.length
          let prop = scope.column.property.substring(0, propertyLen - 6) + 'isFolded'
          scope.row[prop] = false // 展开
          return
        }
        for (let key of TABLE_COLUMN_LIST) {
          this.cloneTableData.forEach(item => {
            item[key + 'isFolded'] = true // 打分的图标初始时为折叠状态
          })
        }
        this.tableData = this.$deepClone(this.cloneTableData)
      },
      // 处理表格数据，风险项和优化项要显示背景颜色
      tableDataHandle(row, column, rowIndex, columnIndex) {
        for (let i = 0; i < this.tableData.length ; i++) {
          let item = this.tableData[i]
          // let nextItem = this.tableData[i + 1]
          const {
            genericNameCompareStatus,
            specCompareStatus,
            formNameCompareStatus,
            manufacturerCompareStatus,
            approvalNoCompareStatus
          } = item
          // 当风险级别为风险项和优化项的时候，机构那一行显示详情
          if (column.property && column.property !== 'drugId') {
            const propName = row[column.property + 'riskLevel']
            // 风险级别为风险项显示红色，为优化项显示黄色
            if (propName === '3' && columnIndex % 2 === 1) {
              if (rowIndex % 2 === 0) {
                return 'error-color error-reason'
              }
              return 'error-color'
            } else if (propName === '4' && columnIndex % 2 === 1) {
              item.className = 'warning-color'
              if (rowIndex % 2 === 0) {
                return 'warning-color warning-reason'
              }
              return 'warning-color'
              // nextItem.className = 'warning-color'
              
            } else if ((propName === '2' || propName === '1')  && i === rowIndex) {
              return ''
            }
          }
        }
      },
      // 筛选模式使用的组件popover隐藏时的事件
      onHidePopover(scope) {
        const filterName = 'filter' + scope.$index
        this.$nextTick(() => {
          this.columnTableDataHandle()
          if (this.$refs[filterName] && this.$refs[filterName].filterOptions) {
            this.$refs[filterName].filterOptions.forEach(option => {
              option.showChildren = false
            })
          }
          if (this.filterOptions) {
            this.filterOptions.forEach(option => {
              option.showChildren = false
            })
          }
        })
      },
      // 筛选模式使用的组件popover显示时的事件
      onShowPopover(scope) {
        const filterName = 'filter' + scope.$index
        this.$nextTick(() => {
          if (this.$refs[filterName]) {
            this.$refs[filterName].checkList = []
            this.$refs[filterName].isShowOptions = true
          }
        })
      },
      // 筛选模式，点击筛选图标事件
      onFilterClick(scope) {
        console.log(scope)
        const filterName = 'filter' + scope.$index
        this.$nextTick(() => {
          if (this.$refs[filterName]) {
            this.$refs[filterName].onFilterClick(scope)
          }
        })
      },
      // 打分图标按钮
      onGradeFilterClick(scope) {
        this.columnTableDataHandle(scope) // 当前打分图标展开
        const refName = 'grade' + scope.column.property + scope.$index
        this.currentRowItemByGrade = scope.row
        this.$nextTick(() => {
          if (this.$refs[refName]) {
            this.$refs[refName].onFilterClick(scope)
          }
        })
      },
      // 打分下拉项关闭
      async onCloseGradeFilter(selectObj, scope) {
        this.columnTableDataHandle()
        const refName = 'gradePopover' + scope.column.property
        this.$nextTick(() => {
          this.$refs[refName] && this.$refs[refName].doClose()
        })
        this.filterVisible = false
        const propertyLen = scope.column.property.length
        await this.onUpdateGradeRecord(selectObj, scope)
        // 修改打分
      },
      // 筛选模式下拉项关闭
      onCloseFilter(selectObj, scope) {
        this.columnTableDataHandle() // 打分的图标初始为折叠状态
        // const refName = 'popover' + scope.column.property + scope.$index
        const refName = 'elPopover' + scope.column.property
        this.$nextTick(() => {
          this.$refs[refName] && this.$refs[refName].doClose()
        })
        this.filterVisible = false
        let propertyLen = scope.column.property.length
        //****** 筛选图标的那一列的prop = 厂商等字段+ Status ******//
        
        // item[scope.column.property + 'riskLevel']
        if (selectObj.parentItem.value === '1') {
          // 风险级别（3---风险项、4---优化项）
          let property = scope.column.property.substring(0, propertyLen - 6) + 'riskLevel'
          let tableData = this.cloneTableData.filter(item => {
            if (selectObj.checkList.includes(item[property])) {
              return item
            }
          })
          this.tableData = this.$deepClone(tableData)
          console.log(tableData)
        } else if (selectObj.parentItem.value === '2') {
          // 问题类型（1.缺失，2，不准确，3.不完整，4.颗粒度大，5.不规范）
          let property = scope.column.property.substring(0, propertyLen - 6) + 'errorType'
          // item[scope.column.property + 'errorType']
          let tableData = this.cloneTableData.filter(item => {
            if (selectObj.checkList.includes(item[property])) {
              return item
            }
          })
          this.tableData = this.$deepClone(tableData)
          console.log(tableData)
        }
      },
      // 点击查询按钮
      async onSearchReportTable() {
        this.tableSearchObj.pageNum = 1
        await this.fetchData()
      },
      // 发布报告
      async onPublishReport() {
        let res = await publishReportApi({ applyId: this.tableSearchObj.applyId })
        if (res.code === 200) {
          // 发布报告成功，回到审核报告列表页面
          this.$message.success('发布报告成功')
          this.$router.replace('/dm/platformGovernanceReport')
        }
      },
      // 分页查询
      async getTableHandle() {
        await this.fetchData()
      },
      // 打分修改
      async onUpdateGradeRecord(selectObj, scope) {
        // 处理数据
        let item = this.initTableData.find(row => row.drugId === this.currentRowItemByGrade.drugId)
        let finalItem = Object.assign(item, this.currentRowItemByGrade.applyId)
        finalItem.applyId = this.tableSearchObj.applyId
        // 1.修改问题类型errorType,风险级别riskLevel；2.修改status，成功、疑问、失败
        let propertyLen = scope.column.property.length
        let fieldName = scope.column.property.substring(0, propertyLen - 6)
        this.updateColumnObj.applyId = this.tableSearchObj.applyId
        this.updateColumnObj.drugId = this.currentRowItemByGrade.drugId
        this.updateColumnObj.fieldName = fieldName
        if (selectObj.parentItem.value !== '3') {
          // 打分项为成功和疑问的时候
          finalItem.status = selectObj.parentItem.value
          this.updateColumnObj.status = selectObj.parentItem.value
        } else {
          this.updateColumnObj.status = selectObj.parentItem.value
          this.updateColumnObj.errorType = selectObj.checkList.problemList
          this.updateColumnObj.riskLevel = selectObj.checkList.riskList
        }
        let res = await updateRecordDataApi(this.updateColumnObj)
        if (res.code === 200) {
          await this.fetchData()
        }
      },
      // 列表自定义
      async columnSettings() {
        let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 1)
        this.sltConfigKey = item.configKey
        this.sltConfigClassify = item.configClassify
        let _this = this
        _this.$ColumnSettings.showColSettings(
          _this.sltConfigKey,
          _this.sltConfigClassify,
          _this.tableOptions,
          _this.getSettingTable
        )
      },
      // 设置按钮-获取设置的数据
      async getSettingTable () {
        let _this = this
        let obj = {}
        let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 1)
        this.sltConfigKey = item.configKey // 药物管理-药物列表-表格配置
        this.sltConfigClassify = item.configClassify // 配置分类
        obj.configKey = this.sltConfigKey
        this.columnOptions = []
        let res = await UCgetConfig(obj)
        if (res.data  && res.data.configValue) {
          let list = JSON.parse(res.data.configValue).list
          if (list.length > 0) {
            list.forEach(function (item) {
              if (item.show==undefined || item.show==true) {
                _this.columnOptions.push(item)
              }
            })
          }else {
            _this.msgError('获取数据表格配置信息失败')
          }
        }else{
          let defaultConfig=this.tableOptions
          defaultConfig.forEach(function (item) {
            if (item.show==undefined || item.show==true) {
              _this.columnOptions.push(item)
            }
          })
        }
        _this.$nextTick(() => {
          _this.$refs.refundTable.doLayout()
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
.platform-report-container {
  .platform-report-header {
    display: flex;
    height: 64px !important;
    justify-content: space-between;
    align-items: center;
    .title-header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      .title-header {
        width: 4px;
        height: 18px;
        background: #0073E9;
        border-radius: 2px;
      }
      .title-text {
        display: inline-block;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
        margin-left: 5px;
        vertical-align: top;
      }
    }
    .el-form {
      .el-form-item {
        margin-bottom: 0 !important;
      }
    }
  }
  .platform-report-main {
    padding: 0 20px 20px !important;
    .platform-report-table {
      ::v-deep .el-table--border th.none-border, ::v-deep .el-table--border td.none-border {
        background-color: blue;
        border-right: none !important;
      }
      ::v-deep td.none-border, ::v-deep th.none-border {
        border-right: none !important;
      }
      ::v-deep td {
        padding: 3px 5px !important;
      }
      ::v-deep td.error-color {
        background-color: #FFDBE1;
      }
      ::v-deep td.warning-color {
        background-color: #FFE9D1;
      }
      ::v-deep .error-reason, ::v-deep .warning-reason {
        .cell {
          position: relative;
          .error-details {
            display: inline-block;
            padding: 5px !important;
            color: #2B60F8;
            background-color: #D7E3FA;
            font-size: 14px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
          }
        }
      }
      .icon-class {
        display: inline-block;
        position: absolute;
        right: 0px;
        transform: translateY(-50%);
        color: #2B60F8;
        cursor: pointer;
      }
    }
  }
}
</style>
  