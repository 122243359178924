var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShowDialog
    ? _c(
        "el-dialog",
        {
          staticClass: "vertical-middle-dialog",
          attrs: {
            title: "设置审核字段",
            visible: _vm.isShowDialog,
            "append-to-body": true,
            width: "500px",
            center: "",
          },
          on: { close: _vm.closeDialog },
        },
        [
          _c(
            "el-table",
            {
              staticClass: "right-table-wrap",
              attrs: {
                data: _vm.tableData,
                "header-cell-style": { textAlign: "center", color: "#333" },
                border: "",
                height: "100%",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "60",
                  align: "center",
                },
              }),
              _vm._v("> "),
              _c("el-table-column", {
                attrs: { prop: "fieldName", label: "字段名称" },
              }),
              _vm._v("> "),
              _c("el-table-column", {
                attrs: { "show-overflow-tooltip": true, align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-checkbox",
                            {
                              attrs: {
                                indeterminate: _vm.isAllGovernedIndeterminate,
                              },
                              on: { change: _vm.handleCheckAllGovern },
                              model: {
                                value: _vm.checkAllGovern,
                                callback: function ($$v) {
                                  _vm.checkAllGovern = $$v
                                },
                                expression: "checkAllGovern",
                              },
                            },
                            [_vm._v("加入治理")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-checkbox", {
                            on: { change: _vm.handleCheckGovern },
                            model: {
                              value: scope.row.dmStatusBoolean,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "dmStatusBoolean", $$v)
                              },
                              expression: "scope.row.dmStatusBoolean",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3422640156
                ),
              }),
              _c("el-table-column", {
                attrs: { "show-overflow-tooltip": true, align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-checkbox",
                            {
                              attrs: {
                                indeterminate: _vm.isAllQCIndeterminate,
                              },
                              on: { change: _vm.handleCheckAllQC },
                              model: {
                                value: _vm.checkAllQC,
                                callback: function ($$v) {
                                  _vm.checkAllQC = $$v
                                },
                                expression: "checkAllQC",
                              },
                            },
                            [_vm._v("加入质控")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-checkbox", {
                            on: { change: _vm.handleCheckQC },
                            model: {
                              value: scope.row.evalStatusBoolean,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "evalStatusBoolean", $$v)
                              },
                              expression: "scope.row.evalStatusBoolean",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1668672888
                ),
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }