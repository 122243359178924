/**平台标准药品数据 */
import store from '@/store'
const { provinceCode } = store.getters.dept
let showShangHai = false
if (provinceCode === '310000') {
  showShangHai = true
} else {
  showShangHai = false
}
const PLATFORM_DTAT_OBJ = {
    dkbPdpId: '',
    dkbPdpMatId: '',
    dkbPdId: '',
    dkbPpId: '',
    dkbPpSpecId: '',
    dkbDrugId: '', // 药物id
    drugName: '',
    drugId: '', // 药品唯一ID
    ustdCode: '', // 上海阳光采购平台药品统编码
    classCode: '', // 中西草生物制品类型代码
    className: '', // 中西草生物制品类型名称（药物类别）
    genericName: '', // 药品通用名
    tradeName: '', // 药品商品名
    specText: '', // 药品规格
    formCode: '', // 剂型代码
    formName: '', // 剂型名称
    phaClassCode: '', // 分类代码
    phaClassName: '', // 分类名称
    minPackUnit: '', // 最小单位
    doseUnit: '', // 规格单位
    doseFactor: '', // 规格系数
    packUnit: '', // 包装单位
    packFactor: '', // 包装系数
    manufacturerCode: '', // 厂家代码
    manufacturerName: '', // 厂家名称
    approvalNo: '', // 批准文号
    nedFlag: '', // 国基标志
    shNedFlag: '', // 上基标志
    ncpFlag: '', // 集采标志
    highRiskLvCode: '', // 高危药品分级代码	
    highRiskLvName: '', // 高危药品分级名称
    antibioticLvCode: '', // 抗菌药物分级代码
    antibioticLvName: '', // 抗菌药物分级名称
    toxicNarcoticTypeCode: '', // 精麻毒放标志代码
    toxicNarcoticTypeName: '', // 精麻毒放标志名称
    dddUnit: '' // DDD单位
}
// 机构药品表格模拟数据
const INSTI_TABLE_DATA = [
  {
    code: 1,
    name: '氯化钠注射液',
    spec: '0.5g*5支/盒',
    manufacturerName: '北大医药股份有限公司',
    mapShow: '未匹配',
    approvalNo: 'H310216600'
  },
  {
    code: 2,
    name: '氯化钠注射液',
    spec: '0.5g*5支/盒',
    manufacturerName: '北大医药股份有限公司',
    approvalNo: 'H310216600'
  },
  {
    code: 3,
    name: '氯化钠注射液',
    spec: '0.5g*5支/盒',
    manufacturerName: '北大医药股份有限公司',
    approvalNo: 'H310216600'
  },
  {
    code: 4,
    name: '氯化钠注射液',
    spec: '0.5g*5支/盒',
    manufacturerName: '北大医药股份有限公司',
    approvalNo: 'H310216600'
  },
  {
    code: 5,
    name: '氯化钠注射液',
    spec: '0.5g*5支/盒',
    manufacturerName: '北大医药股份有限公司',
    approvalNo: 'H310216600'
  },
  {
    code: 6,
    name: '氯化钠注射液',
    spec: '0.5g*5支/盒',
    manufacturerName: '北大医药股份有限公司',
    approvalNo: 'H310216600'
  },
  {
    code: 7,
    name: '氯化钠注射液',
    spec: '0.5g*5支/盒',
    manufacturerName: '北大医药股份有限公司',
    approvalNo: 'H310216600'
  },
  {
    code: 8,
    name: '氯化钠注射液',
    spec: '0.5g*5支/盒',
    manufacturerName: '北大医药股份有限公司',
    approvalNo: 'H310216600'
  },
  {
    code: 9,
    name: '氯化钠注射液',
    spec: '0.5g*5支/盒',
    manufacturerName: '北大医药股份有限公司',
    approvalNo: 'H310216600'
  },
  {
    code: 10,
    name: '氯化钠注射液',
    spec: '0.5g*5支/盒',
    manufacturerName: '北大医药股份有限公司',
    approvalNo: 'H310216600'
  }
]
// 查询版本轴数据对象
const QUERY_OBJ = {
  type: 1, // 选择年份还是季度或者月份
  searchValue: '',
  yearMonth: null, // 年份--月份
  year: null,
  quarterly: null, // 季度
  month: null // 月
}
const QUATER_OPTIONS = [
  {
    value: 1,
    label: '第一季度'
  },
  {
    value: 2,
    label: '第二季度'
  },
  {
    value: 3,
    label: '第三季度'
  },
  {
    value: 4,
    label: '第四季度'
  }
]
const TYPE_OPTIONS = [
  {
    value: 1,
    label: '年度'
  },
  {
    value: 2,
    label: '季度'
  },
  {
    value: 3,
    label: '月份'
  }
]
const MONTH_OPTIONS = [
  {
    value: 1,
    label: '1月'
  },
  {
    value: 2,
    label: '2月'
  },
  {
    value: 3,
    label: '3月'
  },
  {
    value: 4,
    label: '4月'
  },
  {
    value: 5,
    label: '5月'
  },
  {
    value: 6,
    label: '6月'
  },
  {
    value: 7,
    label: '7月'
  },
  {
    value: 8,
    label: '8月'
  },
  {
    value: 9,
    label: '9月'
  },
  {
    value: 10,
    label: '10月'
  },
  {
    value: 11,
    label: '11月'
  },
  {
    value: 12,
    label: '12月'
  }
]
const MAP_SHOW_LIST = [
  // 药品映射层级 1:材料 2:包装 3:药品 4:规格 5:制剂 6:药物
  {
    cdoe: '1',
    name: '包装材料', // 材料
  },
  {
    cdoe: '2',
    name: '药品包装', // 包装
  },
  {
    cdoe: '3',
    name: '药品',
  },
  {
    cdoe: '4',
    name: '制剂规格',
  },
  {
    cdoe: '5',
    name: '制剂',
  },
  {
    cdoe: '6',
    name: '药物',
  }
]
const NO_MATCHED_LIST = [
  {
    cdoe: '0',
    name: '未匹配',
  },
  {
    cdoe: '2',
    name: '非药品',
  },
  {
    cdoe: '3',
    name: '不匹配',
  }
]
let ID_COLUMN = [] // 不同的匹配层级“药品ID”列显示的内容以及列名
let NAME_COLUMN = [] // 不同的匹配层级“名称”列显示的内容以及列名
let PACK_COLUMN_LIST1 = [
  /* special：（表示这一列要特殊处理）1--药品id  2--药品包装名称 */
  // {
  //   id: '1',
  //   rank: 1,
  //   label: '药品ID',
  //   // label: ID_COLUMN[0].label,
  //   isSplited: false,
  //   prop: 'drugCategoryShow',
  //   sortable: false,
  //   show: true,
  //   fixed: true,
  //   width: '',
  //   align: 'center',
  //   special: 1
  // },
  {
    id: '2',
    rank: 2,
    isSplited: false,
    label: '药品包装名称',
    prop: 'drugCategoryShow',
    sortable: false,
    show: true,
    fixed: false,
    width: '',
    align: 'center',
    special: 2
  },
  {
    id: '4',
    rank: 4,
    isSplited: false,
    label: '剂型',
    prop: 'formName',
    sortable: false,
    show: true,
    fixed: false,
    width: '',
    align: 'center'
  },
  {
    id: '5',
    rank: 5,
    label: '规格包装',
    isSplited: false,
    // prop: ['doseFactor', 'doseUnit', 'minPackUnit', 'packFactor', 'minPackUnit', 'packUnit'],
    prop: 'buildUpSpecPack',
    sortable: false,
    show: true,
    fixed: false,
    width: '',
    align: 'center'
  },
  {
    id: '6',
    rank: 6,
    label: '生产厂家',
    prop: 'manufacturerName',
    sortable: false,
    show: true,
    fixed: false,
    width: '',
    align: 'center'
  }
]
let PACK_COLUMN_LIST2 = [
  {
    id: '7',
    rank: 7,
    label: '批准文号',
    prop: 'approvalNo',
    sortable: false,
    show: true,
    fixed: true,
    width: '',
    align: 'center'
  },
  {
    id: '8',
    label: 8,
    label: '商品名',
    prop: 'tradeName',
    sortable: false,
    show: true,
    fixed: true,
    width: '',
    align: 'center'
  },
  {
    id: '9',
    label: 9,
    label: '包装材料',
    // prop: 'pdpName',
    prop: 'pdpMatTxt',
    sortable: false,
    show: true,
    fixed: true,
    width: '130',
    align: 'center'
  },
  {
    id: '10',
    rank: 10,
    label: '统编码',
    prop: 'ustdCode',
    sortable: false,
    show: showShangHai,
    fixed: true,
    width: '',
    align: 'center'
  },
  {
    id: '11',
    rank: 11,
    label: '国家贯标码',
    prop: 'nhsaCode',
    sortable: false,
    show: true,
    fixed: true,
    width: '',
    align: 'center'
  },
]
let PACK_COLUMN_LIST3 = [
  {
    id: '12',
    rank: 12,
    label: '国基标志',
    prop: 'nedFlag',
    sortable: false,
    show: true,
    fixed: true,
    width: '',
    align: 'center'
  },
  {
    id: '13',
    rank: 13,
    label: '上基标志',
    prop: 'shNedFlag',
    sortable: false,
    show: showShangHai,
    fixed: true,
    width: '',
    align: 'center'
  },
  {
    id: '14',
    rank: 14,
    label: '集采标志',
    prop: 'ncpFlag',
    sortable: false,
    show: true,
    fixed: true,
    width: '',
    align: 'center'
  },
  {
    id: '15',
    rank: 15,
    label: '抗菌药物分级',
    prop: 'antibioticLvName',
    sortable: false,
    show: true,
    fixed: true,
    width: '',
    align: 'center'
  },
  {
    id: '16',
    rank: 16,
    label: '高危药品分级',
    prop: 'highRiskLvName',
    sortable: false,
    show: true,
    fixed: true,
    width: '',
    align: 'center'
  }
]
let PACK_COLUMN_LIST4 = [
  {
    id: '17',
    rank: 17,
    label: '药品分类',
    prop: 'phaClassName',
    sortable: false,
    show: true,
    fixed: true,
    width: '',
    align: 'center'
  },
  {
    id: '18',
    rank: 18,
    label: '精麻毒放分类',
    prop: 'toxicNarcoticTypeName',
    sortable: false,
    show: true,
    fixed: true,
    width: '',
    align: 'center'
  },
  {
    id: '19',
    rank: 19,
    label: 'DDD',
    // isSplited: true,
    // prop: ['dddValue', 'dddUnit'],
    isSplited: false,
    prop: 'buildUpDDD',
    sortable: false,
    show: true,
    fixed: true,
    width: '',
    align: 'center'
  },
  {
    id: '20',
    rank: 20,
    isSplited: false,
    label: '药物类别',
    prop: 'className',
    sortable: false,
    show: true,
    fixed: false,
    width: '',
    align: 'center'
  },
  // {
  //   id: '3',
  //   rank: 3,
  //   isSplited: false,
  //   label: '药品分类',
  //   prop: 'phaClassName',
  //   sortable: false,
  //   show: true,
  //   fixed: false,
  //   width: '',
  //   align: 'center'
  // },
]

function onColumn() {
  // 用于处理不同匹配层级第一列和第二列显示的列名和对应的字段
  let item = {}
  for (let i = 0; i < MAP_SHOW_LIST.length; i++) {
    item = MAP_SHOW_LIST[i]
    if (item.code === '2') {
      // 包装
      ID_COLUMN.push({
        code: item.code,
        name: item.name,
        label: '',
        prop: ''
      })
    } else if (item.code === '1') {
      // 材料

    } else if (item.code === '3') {

    }
    
  }
}
/**
 * 
 * @param {*String} mapLevel 匹配层级名称code
 */
function columnHandle(instiRow, row) {
  // 药品映射层级 1:材料 2:包装 3:药品 4:规格 5:制剂 6:药物
  /* 药品包装：不显示"包装材料"
      药品：不显示“包装材料”
      制剂规格：不显示“批准文号、商品名、包装材料”
      制剂：不显示“生产厂家、批准文号、商品名、包装材料、国基、上基”
      药物：不显示“规格包装、生产厂家、批准文号、商品名、包装材料、国基、上基、抗菌药物等级、高危药品分级”
  */
  ID_COLUMN = []
  NAME_COLUMN = []
  if (Number(row.shNedFlag) === 1 ) {
    row.shNedFlag = '是'
  } else if (Number(row.shNedFlag) === 0) {
    row.shNedFlag = '否'
  }
  if (Number(row.nedFlag) === 1 ) {
    row.nedFlag = '是'
  } else if (Number(row.nedFlag) === 0) {
    row.nedFlag = '否'
  }
  if (Number(row.ncpFlag) === 1 ) {
    row.ncpFlag = '是'
  } else if (Number(row.ncpFlag) === 0) {
    row.ncpFlag = '否'
  }
  const level = Number(instiRow.mapLevel)
  let index0 = PACK_COLUMN_LIST1.findIndex(item => item.special === 1)
  index0 = index0 ? index0 : -1
  let index1 = PACK_COLUMN_LIST1.findIndex(item => item.special === 2)
  index1 = index1 ? index1 : -1
  let nullSignArr = [] // 不同匹配层级一些字段需要显示为’---‘
  const nullSign = 'N/A'
  switch (level) {
    case 1:
      ID_COLUMN.push({
        isSplited: true, // 该列是否是由多个字段拼接
        label: '药品ID',
        prop: ['drugId', 'dkbPdpId', 'dkbPdpMatId']
      })
      NAME_COLUMN.push({
        isSplited: false, // 该列是否是由多个字段拼接
        label: '药品包装名称',
        prop: ['pdpName']
      })
      if (PACK_COLUMN_LIST1[index0]) {
        PACK_COLUMN_LIST1[index0].label = '药品ID'
        PACK_COLUMN_LIST1[index0].prop = ['drugId', 'dkbPdpId', 'dkbPdpMatId']
      }
      if (PACK_COLUMN_LIST1[index1]) {
        PACK_COLUMN_LIST1[index1].label = '药品包装名称'
        PACK_COLUMN_LIST1[index1].prop = ['pdpName']
      }
      break
    case 2:
      ID_COLUMN.push({
        isSplited: true, // 该列是否是由多个字段拼接
        label: '药品ID',
        prop: ['drugId', 'dkbPdpId']
      })
      NAME_COLUMN.push({
        isSplited: false, // 该列是否是由多个字段拼接
        label: '药品包装名称',
        prop: ['pdpName']
      })
      if (PACK_COLUMN_LIST1[index0]) {
        PACK_COLUMN_LIST1[index0].label = '药品ID'
        PACK_COLUMN_LIST1[index0].prop = ['drugId', 'dkbPdpId', 'dkbPdpMatId']
      }
      if (PACK_COLUMN_LIST1[index1]) {
        PACK_COLUMN_LIST1[index1].label = '药品包装名称'
        PACK_COLUMN_LIST1[index1].prop = ['pdpName']
      }
      // row.pdpName = nullSign // 表示包装匹配层级，包装材料不显示数据，即不存在包装材料这个概念
      row.pdpMatTxt = nullSign // 包装材料
      row.nhsaCode = nullSign
      row.ustdCode = nullSign
      break
    case 3:
      ID_COLUMN.push({
        isSplited: false, // 该列是否是由多个字段拼接
        label: '药品ID',
        prop: ['drugId']
      })
      NAME_COLUMN.push({
        isSplited: false, // 该列是否是由多个字段拼接
        label: '药品通用名',
        prop: ['genericName']
      })
      if (PACK_COLUMN_LIST1[index0]) {
        PACK_COLUMN_LIST1[index0].label = '药品ID'
        PACK_COLUMN_LIST1[index0].prop = ['drugId']
      }
      if (PACK_COLUMN_LIST1[index1]) {
        PACK_COLUMN_LIST1[index1].label = '药品通用名'
        PACK_COLUMN_LIST1[index1].prop = ['genericName']
      }
      row.pdpName = nullSign // 表示包装匹配层级，包装材料不显示数据，即不存在包装材料这个概念
      row.pdpMatTxt = nullSign // 包装材料
    break
    case 4:
      ID_COLUMN.push({
        isSplited: true, // 该列是否是由多个字段拼接
        label: '制剂ID',
        prop: ['dkbPpId', 'dkbPpSpecId']
      })
      NAME_COLUMN.push({
        isSplited: false, // 该列是否是由多个字段拼接
        label: '制剂名称',
        prop: ['ppName']
      })
      if (PACK_COLUMN_LIST1[index0]) {
        PACK_COLUMN_LIST1[index0].label = '制剂ID'
        PACK_COLUMN_LIST1[index0].prop = ['dkbPpId', 'dkbPpSpecId']
      }
      if (PACK_COLUMN_LIST1[index1]) {
        PACK_COLUMN_LIST1[index1].label = '制剂名称'
        PACK_COLUMN_LIST1[index1].prop = ['ppName']
      }
      // 制剂规格：不显示“批准文号、商品名、包装材料”
      row.approvalNo = nullSign
      row.tradeName = nullSign
      row.pdpName = nullSign
      row.pdpMatTxt = nullSign
    break
    case 5:
      ID_COLUMN.push({
        isSplited: false, // 该列是否是由多个字段拼接
        label: '制剂ID',
        prop: ['dkbPpId']
      })
      NAME_COLUMN.push({
        isSplited: false, // 该列是否是由多个字段拼接
        label: '制剂名称',
        prop: ['ppName']
      })
      if (PACK_COLUMN_LIST1[index0]) {
        PACK_COLUMN_LIST1[index0].label = '制剂ID'
        PACK_COLUMN_LIST1[index0].prop = ['dkbPpId']
      }
      if (PACK_COLUMN_LIST1[index1]) {
        PACK_COLUMN_LIST1[index1].label = '制剂名称'
        PACK_COLUMN_LIST1[index1].prop = ['ppName']
      }
      // 制剂：不显示“生产厂家、批准文号、商品名、包装材料、国基、上基”
      row.manufacturerName = nullSign
      row.approvalNo = nullSign
      row.tradeName = nullSign
      row.pdpName = nullSign
      row.pdpMatTxt = nullSign
      row.nedFlag = nullSign
      row.shNedFlag = nullSign
    break
    case 6:
      ID_COLUMN.push({
        isSplited: false, // 该列是否是由多个字段拼接
        label: '药物ID',
        prop: ['dkbDrugId']
      })
      NAME_COLUMN.push({
        isSplited: false, // 该列是否是由多个字段拼接
        label: '药物名称',
        prop: ['drugName']
      })
      if (PACK_COLUMN_LIST1[index0]) {
        PACK_COLUMN_LIST1[index0].label = '药物ID'
        PACK_COLUMN_LIST1[index0].prop = ['dkbDrugId']
      }
      if (PACK_COLUMN_LIST1[index1]) {
        PACK_COLUMN_LIST1[index1].label = '药物名称'
        PACK_COLUMN_LIST1[index1].prop = ['drugName']
      }
      // 制剂：不显示“生产厂家、批准文号、商品名、包装材料、国基、上基”
      // 药物：不显示“规格包装、生产厂家、批准文号、商品名、包装材料、国基、上基、抗菌药物等级、高危药品分级”
      row.manufacturerName = nullSign
      row.approvalNo = nullSign
      row.tradeName = nullSign
      row.pdpName = nullSign
      row.pdpMatTxt = nullSign
      row.nedFlag = nullSign
      row.shNedFlag = nullSign
      row.antibioticLvName = nullSign
      row.highRiskLvName = nullSign
    break
    default:
      if (PACK_COLUMN_LIST1[index0]) {
        PACK_COLUMN_LIST1[index0].label = '药品ID'
        PACK_COLUMN_LIST1[index0].prop = ''
      }
      if (PACK_COLUMN_LIST1[index1]) {
        PACK_COLUMN_LIST1[index1].label = '药品包装名称'
        PACK_COLUMN_LIST1[index1].prop = ''
      }
  }
}
// columnHandle('药品')
// console.log(PACK_COLUMN_LIST1)
export {
  QUERY_OBJ,
  QUATER_OPTIONS,
  MONTH_OPTIONS,
  TYPE_OPTIONS,
  PLATFORM_DTAT_OBJ,
  MAP_SHOW_LIST,
  NO_MATCHED_LIST,
  ID_COLUMN,
  NAME_COLUMN,
  PACK_COLUMN_LIST1,
  PACK_COLUMN_LIST2,
  PACK_COLUMN_LIST3,
  PACK_COLUMN_LIST4,
  columnHandle
}
