var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "layout-container platform-report" },
    [
      _c(
        "el-header",
        { staticClass: "platform-report-header" },
        [
          _c(
            "div",
            {
              staticClass: "title-header-container",
              attrs: { id: "1" },
              on: { click: _vm.onSearchTable },
            },
            [
              _c("span", { staticClass: "title-header" }),
              _c("h4", { staticClass: "title-text" }, [_vm._v("审核报告")]),
            ]
          ),
          _c(
            "el-form",
            { attrs: { model: _vm.queryObj, inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-autocomplete", {
                    staticStyle: {
                      width: "200px",
                      height: "36px!important",
                      "line-height": "36px",
                    },
                    attrs: {
                      "fetch-suggestions": _vm.onOrgSearch,
                      placeholder: "输入机构名称",
                      clearable: "",
                      size: "medium",
                      "value-key": "name",
                    },
                    model: {
                      value: _vm.queryObj.searchValue,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.queryObj,
                          "searchValue",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "queryObj.searchValue",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "申请时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "至",
                      "value-format": "yyyy-MM-dd",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.queryObj.timeList,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryObj, "timeList", $$v)
                      },
                      expression: "queryObj.timeList",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.queryObj.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryObj, "status", $$v)
                        },
                        expression: "queryObj.status",
                      },
                    },
                    _vm._l(_vm.statusOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        icon: "el-icon-search",
                      },
                      on: { click: _vm.onSearchTable },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-main",
        { staticClass: "platform-report-main" },
        [
          _c(
            "el-table",
            {
              staticClass: "platform-report-table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "header-cell-style": { textAlign: "center" },
                stripe: "",
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", width: "100", fixed: "" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("申请编号")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.applyId))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("机构名称")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.orgName))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("申请时间")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.startTime))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("最后操作时间")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.endTime))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "statusStr" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("状态")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { class: _vm.statusText(scope) }, [
                          _vm._v(_vm._s(scope.row.statusStr)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "100",
                  type: "index",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === "1"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onCheckReport(scope)
                                  },
                                },
                              },
                              [_vm._v("审核报告")]
                            )
                          : _vm._e(),
                        scope.row.status === "2"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onViewReport(scope)
                                  },
                                },
                              },
                              [_vm._v("查看报告")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-footer",
        [
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.queryObj.pageNum,
              limit: _vm.queryObj.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryObj, "pageNum", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryObj, "pageSize", $event)
              },
              pagination: _vm.getTableHandle,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }