<template>
  <div class="app-container">
    <div ref="headerQuery">
      <el-row class="headerClass">
        <el-col :span="4" style="border-left: 5px solid #0073E9;padding-left: 10px;">药物管理</el-col>
        <el-col :span="20">
          <el-form :model="queryParams" ref="queryForm"  class="query-form"  :inline="true" v-show="showSearch" style="float: right">

            <el-form-item label="" style="width: 140px;">
              <el-select
                v-model="queryParams.drugClassify"
                filterable
                remote
                size="small"
                multiple
                collapse-tags
                clearable
                placeholder="药物分类">
                <el-option
                  v-for="item in drugClassifyOptions"
                  :key="'dkb_drug_classify'+item.conceptId"
                  :label="item.conceptVal"
                  :value="item.conceptId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" style="width: 200px;" class="el-item-dropdown-300">
              <el-select
                :popper-append-to-body="false"
                v-model="queryParams.substanceIds"
                filterable
                remote
                multiple
                size="small"
                clearable
                collapse-tags
                reserve-keyword
                placeholder="物质ID/名称"
                :remote-method="remoteMethodSL"
                :loading="loading">
                <el-option
                  v-for="item in substanceList"
                  :key="item.substanceId+item.name"
                  :label="item.name"
                  :value="item.substanceId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" style="width: 200px;" class="el-item-dropdown-300" >
              <el-autocomplete
                :popper-append-to-body="false"
                v-model="queryParams.searchValue"
                :fetch-suggestions="querySearchAsync"
                placeholder="药物ID/名称"
                @select="handleQuery"
                @clear="setBlur()"
                clearable
                size="small"
                value-key="name"
                style="height: 36px!important;line-height: 36px;"
              >
                <el-select v-model="queryParams.searchValueSearchType"
                           style="width: 74px;"
                           slot="prepend" placeholder="请选择">
                  <el-option label="模糊" value="1"></el-option>
                  <el-option label="精确" value="2"></el-option>
                  <el-option label="前缀" value="3"></el-option>
                </el-select>
              </el-autocomplete>
              <!--            width: 300px;-->
            </el-form-item>
            <el-form-item label="" prop="status">
              <el-select
                v-model="queryParams.status"
                placeholder="状态"
                clearable
                size="small"
                class="statusClass"
                style="width: 74px"
              >
                <el-option
                  v-for="dict in statusOptions"
                  :key="dict.conceptId"
                  :label="dict.conceptVal"
                  :value="dict.conceptId"
                />
              </el-select>
            </el-form-item>

            <el-form-item>
              <!--            <el-checkbox v-model="checked" style="padding-right:10px;">包含停用物质成分的记录</el-checkbox>-->
              <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">检索</el-button>
              <el-button icon="el-icon-circle-plus-outline" size="small" @click="handleAdd" class="addClass">新增药物</el-button>
              <el-button icon="el-icon-circle-plus-outline" size="small" @click="columnSettings">设置</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>


    <div ref="tableContainer">
      <el-table
        border
        stripe
        ref="refundTable"
        :data="drugList"
        :height="tableHeight"
        v-loading="loading"
        @sort-change="tableSortChange"
        @row-contextmenu="rowContextmenu"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          v-if="isSelection"
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          v-for="data in columnOptions"
          :key="'drug-list'+data.id"
          :prop="data.prop"
          :label="data.name"
          :fixed="data.fixed"
          :resizable="false"
          :sortable="data.sortable"
          :min-width="data.width"
          :align="data.align"
        >
          <template slot-scope="scope">
            <div v-if="data.prop == 'substanceComponent'">
              <el-popover trigger="click" placement="top">
                <div>
                  <p style="text-align:center;"><b>{{ scope.row.name }}</b></p>
                  <p v-for="item1 in scope.row.dkbDrugIngredientList" >
                    [<span>{{ item1.substanceId }}</span>]-<span>{{ item1.substanceName }}</span>(<span>{{ item1.substanceTypeDesc }}</span>)；
                  </p>
                </div>
                <span slot="reference" class="name-wrapper setTwoLine">
                  <span v-for="item1 in scope.row.dkbDrugIngredientList" >
                    <span :class="{delClass: item1.status === '1'}">
                      [<span>{{ item1.substanceId }}</span>]-<span>{{ item1.substanceName }}</span>(<span>{{ item1.substanceTypeDesc }}</span>)；
                    </span>
                  </span>
                </span>
              </el-popover>
            </div>
            <div v-else-if="data.prop == 'name'">
<!--              class="leftIconName"-->
              <el-popover trigger="click" placement="top">
                <div v-for="item in scope.row.dkbDrugAliasList" :key="item.id">
                  <div v-if="item.isPrimary==='1'">
                    <p v-for="item1 in item.dkbReferenceInfoList" :key="item1.id">
                      <span>{{'['+item1.resourceTypeName+']'}}</span>{{item1.resourceName||''}}
                    </p>
                  </div>
                </div>
                <span slot="reference" style="width:50px;" v-if="isShowIcon(scope.row.dkbDrugAliasList)">
                  <!-- v-if="isShowIcon(scope.row.dkbDrugAliasList)" -->
                  <i class="el-icon-question" @click="showResourceReference(scope.row,tableConst.DKB_DRUG_ALIAS)" title="点击查看来源引用"></i>
                </span>
              </el-popover>
<!--              class="rightContentName"-->
              <el-popover trigger="click" placement="top">
                <p v-for="item in scope.row.dkbDrugAliasList" :key="item.id">
                {{ item.alias }}
                </p>
                <span slot="reference" class="name-wrapper">
                  <span style="color: #2B60F8;font-weight: 700;margin-right: 5px;" title="点击查看全部药物名称"
                  >{{
                      scope.row[data.prop]
                    }}</span>
                   <span style="color: #2B60F8;" v-for="item in scope.row.drugCategoryList"
                         :key="item.id"
                   >
                    <svg-icon :icon-class="item.icon" v-if="item.icon"/>
                   </span>
                </span>
              </el-popover>
            </div>
            <div v-else>
              <span>{{ scope.row[data.prop] }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center" key="status" width="100" fixed="right">
          <template slot-scope="scope">
            <el-switch
              class="switch"
              active-color="#5DB730"
              active-text='启用'
              inactive-text='停用'
              inactive-color="#B5CCF5"
              v-model="scope.row.status"
              active-value="0"
              inactive-value="1"
              @change="handleStatusChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>


        <template slot="empty" v-if="isResult === false">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
    </div>

    <table-context-menu v-if="tableContextMenuVisible"
                        @foo="foo" ref="contextbutton"
                        :action="tableAction"
                        @handleTableAction="handleTableAction"></table-context-menu>
    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="selectDrugList"
      :page-sizes="[10, 20, 50, 100]"
    />

    <el-dialog v-dialogDrag :title="'引用来源管理[' + drugTitle + ']'" :visible.sync="resourceTableVisible">
      <el-row>
        <el-col :span="24" style="margin-bottom: 10px;">
          <el-button style="float: right;margin-right: 10px;" icon="el-icon-circle-plus-outline"
                     size="small" @click="addResource"
          >添加
          </el-button>
        </el-col>
      </el-row>
      <el-form ref="resourceTableForm" status-icon :model="resourceTableForm">
        <el-table
          border
          stripe
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
          :data="resourceTableForm.list">
          <el-table-column property="date" label="引用资源类型" >
            <template slot-scope="scope">
              <el-form-item
                :prop="'list.' + scope.$index + '.'+'resourceTypeId'"
                :rules="[{ required: true, message: '请选择药物名称', trigger: 'blur'}]"
              >
                <el-select
                  v-model="scope.row.resourceTypeId"
                  placeholder="引用资源类型"
                  size="small"
                  style="width: 200px"
                >
                  <el-option
                    v-for="dict in resourceTypeOption"
                    :key="'resourceTypeOption'+dict.id"
                    :label="dict.typeName"
                    :value="dict.id"
                  />
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column property="name" label="引用资源名称" >
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.resourceId"
                filterable
                remote
                style="width: 200px"
                reserve-keyword
                placeholder="请输入关键词"
                @change="resourceNameChange($event,scope.$index)"
                :remote-method="(query)=>{resourceNameQuery(query,scope.row.resourceTypeId)}"
                :loading="loading"
                :disabled="!scope.row.resourceTypeId?true:false"
              >
                <el-option
                  v-for="item in resourceDataOption"
                  :key="'resourceDataOption'+item.id"
                  :label="item.name"
                  :value="item.id"
                />
                <el-option
                  v-for="item in [scope.row]"
                  :key="item.resourceId+'original'"
                  :label="item.resourceName"
                  :value="item.resourceId"
                />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="200">
            <template slot-scope="scope">

              <el-button
                size="mini"
                type="text"
                style="color: #FF3333;"
                @click="handleResourceDelete(scope.$index,scope.row)"
              >删除
              </el-button>
            </template>
          </el-table-column>
          <template slot="empty">
            <img src="@/assets/images/no-booking.svg" alt="">
            <p>暂无数据</p>
          </template>
        </el-table>
      </el-form>
      <el-row>
        <el-col :span="24" style="margin-top: 10px;">
          <el-button type="primary" style="float: right;margin-right: 10px;"
                     size="small" @click="saveResource"
          >保存
          </el-button>
          <el-button style="float: right;margin-right: 10px;"
                     size="small" @click="resourceTableVisible=false"
          >取消
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog v-dialogDrag
      title="新增药物组"  :close-on-click-modal="false"
      :visible.sync="drawer">
      <el-form :model="drugGroup" ref="drugGroupForm">
        <el-form-item label="药物组名称：" prop="name" :rules="[ { required: true, message: '请输入药物组名称', trigger: 'blur'}]">
          <el-input v-model="drugGroup.name" autocomplete="off" style="width: 300px"></el-input>
        </el-form-item>
        <el-row style="padding: 0px 0px 15px 15px;":gutter="20">
          <el-col :span="22" class="title-bar">药物组管理</el-col>
          <el-col :span="22">
            <el-table
              :data="resultNameOptions"
              :height="400"
              border
              stripe
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              ref="rno">
              <el-table-column property="drugId" label="药物ID" width="150"></el-table-column>
              <el-table-column property="drugName" label="药物名称"></el-table-column>
              <el-table-column property="drugName" label="药物名称"></el-table-column>
              <el-table-column label="主要药物" align="center" key="status" width="100">
                <template slot-scope="scope">
                  <el-switch
                    class="switch"
                    active-color="#5DB730"
                    active-text='是'
                    inactive-text='否'
                    inactive-color="#B5CCF5"
                    v-model="scope.row.isPrimary"
                    active-value="1"
                    inactive-value="0"
                    @change="handlePrimaryChange(scope.row, scope.index)"
                  ></el-switch>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="150">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.$index,scope.row)" type="text" size="small" style="color: #FF3333;">删除</el-button>
                </template>
              </el-table-column>
              <template slot="empty">
                <img src="@/assets/images/no-booking.svg" alt="">
                <p>暂无数据</p>
              </template>
            </el-table>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button @click="drawer = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">保存</el-button>
      </div>
    </el-dialog>
    <el-dialog v-dialogDrag
      title="已有药物组"  :close-on-click-modal="false"
      :visible.sync="areadyDrawer">
      <el-form :model="areadyDrugGroup" ref="areadyDrugGroupForm">
        <el-form-item label="药物组名称：">
          <el-form-item label="" prop="name" :rules="[ { required: true, message: '请输入药物组名称', trigger: 'blur'}]">
            <el-autocomplete
              v-model="areadyDrugGroup.name"
              :fetch-suggestions="querySearchGroupAsync"
              placeholder="输入药物组名称"
              clearable
              size="small"
              value-key="name"
              style="width: 300px;height: 36px!important;line-height: 36px;"
              @select="handleDrugGroupQuery"
              @clear="setBlur()"
            ></el-autocomplete>
          </el-form-item>
<!--          <el-input v-model="areadyDrugGroup.name" autocomplete="off" style="width: 300px"></el-input>-->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button @click="areadyDrawer = false">取 消</el-button>
        <el-button type="primary" @click="areadySubmitForm">保存</el-button>
      </div>
    </el-dialog>


    <drug-category-edit ref="drugCategoryEdit"></drug-category-edit>


  </div>
</template>

<script>
import {  selectSubstanceNameList } from '@/api/dkm/substance'
import { del, list, updateStatus, selectDrugNameList } from '@/api/dkm/drug'
import { selectReferenceInfo, saveOrUpdateInfoList, resourceNameQuery } from '@/api/dkm/resource'
import { getResourceCategory,getReferenceInfo } from '@/utils/resourceReference'
import tableConst from '@/const/tableConst'
import { getConcept } from '@/utils/concept'
import { UCgetConfig } from '@/utils/columnSettings'
import { GLOBAL_CONFIGURATION_CONFIGKEY } from '@/utils/constants'
import { DEEP_CLONE } from '@/utils/constants'

import conceptConst from '@/const/conceptConst'
import { saveOrUpdate,selectDrugGroupNameList,info } from '@/api/dkm/drugGroup'
import { setCache, getCache } from '@/utils/cacheUtil'
import AcrossUtil from '@/utils/acrossUtil'
export default {
  name: 'Drug',
  components: {},
  data() {
    return {
      resultNameOptions:[],
      areadyResultNameOptions: [],
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      areadyDrugGroup: {
        name: '', // 中西草标识Id
        id: null, // 药物id
        dkbDrugGroupMappingList: [], // 关联的药物成分
      },
      drugGroup: {
        name: '', // 中西草标识Id
        id: null, // 药物id
        dkbDrugGroupMappingList: [], // 关联的药物成分
      },
      drawer: false,
      areadyDrawer: false,
      drugTitle: '',
      isResult: false,
      conceptConst,
      substanceList: [], // 物质列表
      tableConst,
      // 遮罩层
      loading: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 药品维护表格数据
      drugList: [],
      // 表格高度
      tableHeight: window.innerHeight - 100 - 100,
      // tableHeight: window.innerHeight - 100 - 140,
      // 状态数据字典
      statusOptions: [],
      //药物分类-中西草标示数据字典
      drugClassifyOptions:[],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        substanceIds: [],
        drugClassify:[],
        searchValue: null,
        searchValueSearchType:'1',
        orderByColumn:undefined,
        isAsc:undefined,
      },
      /* 控制引用来源表格弹窗显示隐藏*/
      resourceTableVisible: false,
      /*来源类型下拉列表*/
      resourceTypeOption: [],
      resourceDataOption: [],
      /*当前操作表格行数据*/
      editTableRow: [],
      /* 来源引用表格数据*/
      resourceTableForm: {
        list: []
      },
      sltConfigKey: '', // 药物管理-药物列表-表格配置
      sltConfigClassify: '',
      // searchValue: '',
      columnOptions: [],
      refundForm: {
        refundTableData: []
      },
      options: [
        {
          id: 1,
          prop: 'id',
          name: '药物ID',
          fixed: false,
          show: true,
          sortable:'custom',
          align: 'center',
          width: '100'
        },
        {
          id: 2,
          prop: 'name',
          name: '药物名称',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '400'
        },
        {
          id: 3,
          prop: 'classifyDesc',
          name: '中西药标识',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '100'
        },
        {
          id: 4,
          prop: 'substanceComponent',
          name: '药物成分',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '500'
        },
        {
          id: 5,
          prop: 'drugGroupName',
          name: '药物组',
          fixed: false,
          show: true,
          sortable:'custom',
          align: 'center',
          width: '150'
        },
        {
          id: 6,
          prop: 'drugGroupId',
          name: '药物组ID',
          fixed: false,
          show: true,
          sortable:'custom',
          align: 'center',
          width: '150'
        },
        {
          id: 7,
          prop: 'updateTime',
          name: '更新时间',
          fixed: false,
          show: true,
          sortable:  'custom',
          align: 'center',
          width: '150'
        }
      ],
      checked: true,
        /*表格右键菜单显示隐藏*/
      tableContextMenuVisible:false,
      tableAction:[
        {name:"修改"},
        {name:"复制"},
        {name:"来源"},
        {name:"新增制剂"},
        {name:"查询相关制剂"},
        {name:"多选"},
        {name:"删除",style:'color: #FF3333;'},
        {name:"分类维护"},
        {name:"查看"}
      ],
      drugGroupList: [],
      isSelection: false,
      combinedDrug: []
    }
  },
  created() {
    let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 3)
    this.sltConfigKey = item.configKey // 药物管理-药物列表-表格配置
    this.sltConfigClassify = item.configClassify // 配置分类
    this.getSettingTable().then(() => {
      this.$nextTick(() => {
        this.setTableHeight()
        //页面初始化完毕，默认触发一次按照更新时间排序
        this.$refs.refundTable.sort('id','descending');
      })
    })

    //获取引用资源类型
    getResourceCategory().then(result => {
      this.resourceTypeOption = result
    })
    //获取状态数据字典
    getConcept(conceptConst.PUB_DATA_STATUS, 0).then(result => {
      this.statusOptions = result
    })

    //获取中西药物标识数据字典
     getConcept(conceptConst.DKB_DRUG_CLASSIFY, 0).then(result => {
      this.drugClassifyOptions = result
    })

  },
  activated() {
    //激活时重新设置表格高度
    this.setTableHeight()
  },
  mounted() {
    var _this=this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      AcrossUtil.$emit('resetTableHeight');
    }
    /*监听编辑页面发送的事件，执行相应的函数*/
    AcrossUtil.$on('tableReload', function (param) {
      _this.selectDrugList()
    })

    AcrossUtil.$on('resetTableHeight', function (param) {
      setTimeout(function () {
        _this.setTableHeight()
      }, 300);
    })
  },
  methods: {
    /*设置表格的高度*/
    setTableHeight(){
      // 获取高度值 （内容高+padding+边框）
      if(this.$refs.headerQuery){
        let formHeight = this.$refs.headerQuery.offsetHeight
        this.tableHeight = window.innerHeight - formHeight-140
      }

    },
    /** 保存按钮--新增药物组 */
    submitForm() {
      this.$refs['drugGroupForm'].validate(valid => {
        if (valid) {
          if (this.resultNameOptions.length === 0) {
            this.msgError('至少选择一条药物成分')
          } else {
            this.drugGroup.dkbDrugGroupMappingList = this.resultNameOptions
            saveOrUpdate(this.drugGroup).then(response => {
              this.msgSuccess(response.msg)
              this.drawer = false
            })
          }
        } else {
          this.msgError('请规范填写')
        }
      })
    },
    /** 保存按钮--添加到已有药物组 */
    areadySubmitForm() {
      this.$refs['areadyDrugGroupForm'].validate(valid => {
        if (valid) {
          if (this.areadyResultNameOptions.length === 0) {
            this.msgError('至少选择一条药物成分')
          } else {
            this.areadyDrugGroup.dkbDrugGroupMappingList = this.combinedDrug
            saveOrUpdate(this.areadyDrugGroup).then(response => {
              this.msgSuccess(response.msg)
              this.areadyDrawer = false
            })
          }
        } else {
          this.msgError('请规范填写')
        }
      })
    },
    handleClick(index, row) {
      // console.log(index, row)
      this.resultNameOptions.splice(index, 1)
    },
    handleSelectionChange(val) {
      // console.log(val)
      this.drugGroupList = val
    },
    // 新增制剂
    addPs(row) {
      // console.log(row)
      this.$router.push({
        path: '/preparationSpecification/edit',
        query: {
          drugData: JSON.stringify(row)
        }
      })
    },
    /*修改药物组里药物是否为默认药物*/
    handlePrimaryChange(row, index) {
      // console.log(row)
      let that = this
      let text = row.isPrimary === '0' ? '取消' : '默认'
      this.$confirm('确认要"' + text + '"ID为"' + row.drugId + '"的药物吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        if (row.isPrimary === '1') {
          // console.log(that.resultNameOptions)
          that.resultNameOptions.forEach((item) => {
            item.isPrimary = '0'
          })
          that.resultNameOptions[index].isPrimary = '1'
          // console.log(that.resultNameOptions)
        }
      }).then(() => {
        this.msgSuccess(text + '成功')
      }).catch(function() {
        row.isPrimary = row.isPrimary === '0' ? '1' : '0'
      })
    },
    // 新增药物组，将当前已选择数据带过去
    addDrugGroup(data) {
      this.drugGroup.name=undefined
      if (this.drugGroupList !== null && this.drugGroupList.length > 0) {
        // console.log(this.drugGroupList)
        let arrData = []
        // let arrNameData = []
        this.drugGroupList.forEach((item)=> {
          let arrObj = {}
          arrObj.drugId = item.id
          arrObj.drugName = item.name
          arrObj.isPrimary = '0'
          arrData.push(arrObj)
          // arrNameData.push(item.name)
        })
        this.resultNameOptions = arrData
        // console.log(this.resultNameOptions)
        this.drawer = true
        this.$refs.refundTable.clearSelection();
        this.isSelection = false
        this.tableAction = [{name:"修改"},{name:"复制"},{name:"来源"},{name:"新增制剂"},{name:"多选"},{name:"删除",style:'color: #FF3333;'}, {name:"分类维护"},{name:"查看"}]
      } else {
        this.msgError('至少选择一条药物成分')
        return false
      }

    },
    handleDrugGroupQuery(val) {
      // console.log(val)
      let id = val.id
      if (id) {
        this.getDrugInfo(id)
      }
    },
    /*根据药物id获取药物明细*/
    async getDrugInfo(id) {
      let that = this
      let response = await info(id)
      if (response.code === 200) {
        // console.log(response)
        let dkbDrugGroupMappingList = []
        this.combinedDrug = []
        this.areadyDrugGroup.id = response.data.id
        this.areadyDrugGroup.name = response.data.name
        dkbDrugGroupMappingList = response.data.dkbDrugGroupMappingList
        // console.log(dkbDrugGroupMappingList)
        // console.log(this.areadyResultNameOptions)
        this.combinedDrug = [...dkbDrugGroupMappingList,...this.areadyResultNameOptions]
        // console.log(this.combinedDrug)
      }
    },
    /* 药物组名称检索*/
    querySearchGroupAsync(queryString, cb) {
      if (queryString && queryString.trim() !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          let queryData = {}
          queryData.queryStr = queryString.trim()
          selectDrugGroupNameList(queryData).then(response => {
            cb(response.data);
          })
        }, 200)
      } else {
        cb([]);
      }
    },
    // 将当前已选择数据带到已有药物组，
    areadyAddDrugGroup(data) {
      this.areadyDrugGroup.name=undefined
      if (this.drugGroupList !== null && this.drugGroupList.length > 0) {
        // console.log(this.drugGroupList)
        let arrData = []
        // let arrNameData = []
        this.drugGroupList.forEach((item)=> {
          let arrObj = {}
          arrObj.drugId = item.id
          arrObj.drugName = item.name
          arrObj.isPrimary = '0'
          arrData.push(arrObj)
          // arrNameData.push(item.name)
        })
        this.areadyResultNameOptions = arrData
        this.areadyDrawer = true
        this.$refs.refundTable.clearSelection();
        this.isSelection = false
        this.tableAction = [{name:"修改"},{name:"复制"},{name:"来源"},{name:"新增制剂"},{name:"多选"},{name:"删除",style:'color: #FF3333;'}, {name:"分类维护"},{name:"查看"}]
      } else {
        this.msgError('至少选择一条药物成分')
        return false
      }

    },
    // 远程搜索获取物质名称
    async remoteMethodSL (query) {
      if (query !== '') {
        this.loading = true
        // let queryData = {}
        // queryData.queryStr = query
        let res = await selectSubstanceNameList(query)
        if (res.code === 200) {
          this.loading = false
          this.substanceList = res.data
        }
      } else {
        this.substanceList = []
      }
    },
    // 设置按钮-获取设置的数据
    async getSettingTable () {
      let _this = this
      let obj = {}
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 3)
      this.sltConfigKey = item.configKey // 药物管理-药物列表-表格配置
      this.sltConfigClassify = item.configClassify // 配置分类
      obj.configKey = this.sltConfigKey
      this.columnOptions = []
      let res = await UCgetConfig(obj)
      if (res.data  && res.data.configValue) {
        let list = JSON.parse(res.data.configValue).list
        if (list.length > 0) {
          list.forEach(function (item) {
            if (item.show==undefined || item.show==true) {
              _this.columnOptions.push(item)
            }
          })
        }else {
          _this.msgError('获取数据表格配置信息失败')
        }
      }else{
        let defaultConfig=this.options
        defaultConfig.forEach(function (item) {
          if (item.show==undefined || item.show==true) {
            _this.columnOptions.push(item)
          }
        })
      }
      _this.$nextTick(() => {
        _this.$refs.refundTable.doLayout()
      })
    },
    // 设置
    async columnSettings() {
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 3)
      this.sltConfigKey = item.configKey
      this.sltConfigClassify = item.configClassify
      let _this = this
      _this.$ColumnSettings.showColSettings(
        _this.sltConfigKey,
        _this.sltConfigClassify,
        _this.options,
        _this.getSettingTable
      )
    },
    // 药物名称是否显示icon
    isShowIcon(val) {
      // console.log(val)
      if (val.length === 0) {
        return false
      } else {
        const item = val.find(item => item.isPrimary === "1")
        if(item) {
          if (item.dkbReferenceInfoList === null || item.dkbReferenceInfoList === undefined || item.dkbReferenceInfoList === '' ||item.dkbReferenceInfoList.length === 0) {
            return false
          } else {
            return true
          }
        }
      }
    },
    /** 查询药品维护列表 */
    selectDrugList() {
      this.loading = true
      // this.queryParams.substanceIds = this.queryParams.substanceIds.toString()
      // // console.log(this.queryParams.substanceIds)
      // console.log(typeof (this.queryParams.substanceIds))
      let obj = DEEP_CLONE(this.queryParams)
      obj.substanceIds = obj.substanceIds.toString()
      obj.drugClassify = obj.drugClassify.toString()
      // console.log(this.queryParams.substanceIds)
      // console.log(typeof (this.queryParams.substanceIds))
      list(obj).then(response => {
        this.queryParams.total=response.total
        setCache("drugQueryParams",this.queryParams)
        setCache("drugPageData",response.rows)
        this.drugList = response.rows
        this.drugList.forEach((item) => {
          // 药物成分
          item.substanceComponent = []
          item.dkbDrugIngredientList.forEach((j) => {
            let code = ''
            code = '[' + j.substanceId + ']-' + j.substanceName + '(' + j.substanceTypeDesc + ');'
            item.substanceComponent.push(code).toString()
          })
          item.substanceComponent = item.substanceComponent.toString().replace(/,/g, '')
          // 药物名称
          item.dkbDrugAliasList.forEach((j) => {
            if (j.isPrimary === "1") {
              item.name = j.alias
            }
          })
          //药物组
         /* if(item.dkbDrugGroupMappingList && item.dkbDrugGroupMappingList.length>0){
            item.drugGroupName=item.dkbDrugGroupMappingList[0].drugGroupName
          }*/


        })
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.isResult = true
      this.queryParams.pageNum = 1
      this.selectDrugList()
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.$router.push('/drug/edit/')
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      const durgId = row.id
      this.$router.push('/drug/edit/' + durgId)
    },
    /** 查看按钮操作 */
    handleSearchdate(row) {
      const durgId = row.id
      this.$router.push('/drug/search/' + durgId)
    },
    // 复制数据
    handleCopydate(row) {
      const durgId = row.id
      // this.$router.push('/drug/edit/' + durgId +'？type="cope"')
      this.$router.push({
        path: '/drug/edit/' + durgId,
        query: {
          copyType: 'copy'
        }
      })
    },
    /** 来源按钮操作 */
    handleSource(row) {
      this.editTableRow = row
      this.resourceTableForm.list =[]
      //查询药物来源引用信息
      //查询数据库获取引用资源信息
      selectReferenceInfo(tableConst.DKB_DRUG, row.id, '').then(response => {
        //this.resourceTableData = response.data
        this.resourceTableForm.list = response.data
        this.resourceTableVisible = true
        if (response.data.length > 0) {
          //还原表单验证
          this.resetForm('resourceTableForm')
        }
      })

    },

    /** 删除按钮操作 */
    handleDelete(row) {
      const id = row.id
      let that = this
      this.$confirm('确认要删除' + '"[' + row.id + ']-' + row.name + '"吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        del(id).then(response => {
          // console.log(response)
          that.selectDrugList()
          that.msgSuccess('删除成功')
        })
      })
    },
    /*药物状态修改*/
    handleStatusChange(row) {
      let text = row.status === '0' ? '启用' : '停用'
      this.$confirm('确认要"' + text + '"ID为"' + row.id + '"的药物吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        return updateStatus(row.id, row.status)
      }).then(() => {
        this.msgSuccess(text + '成功')
      }).catch(function() {
        row.status = row.status === '0' ? '1' : '0'
      })
    },
    /*添加一个来源*/
    addResource() {
      this.resourceTableForm.list.push({
        sourceTable: tableConst.DKB_DRUG,
        sourceTableField: '',
        sourceTableId: this.editTableRow.id,
        resourceTypeId: null,
        resourceId: null
      })
    },
    /* 保存引用资源*/
    saveResource() {
      this.$refs['resourceTableForm'].validate(valid => {
        if (valid) {
          saveOrUpdateInfoList(this.resourceTableForm.list, this.editTableRow.id, tableConst.DKB_DRUG).then(response => {
            this.resourceTableVisible = false;
            this.msgSuccess()
          })
        }else{
          this.msgError('请规范填写')
        }
      })

    },
    resourceNameChange() {
      this.resourceDataOption = []
    },
    /*引用资源名称下拉列表搜索*/
    resourceNameQuery(query, resourceTypeId) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          resourceNameQuery(resourceTypeId, query).then(response => {
            this.resourceDataOption = response.data

          })
        }, 200)
      } else {
        this.options = []
      }
    },
    /*处理来源引用表格删除*/
    handleResourceDelete(index, row) {
      this.resourceTableForm.list.splice(index, 1)

    },
    /*重置表单*/
    resetForm(formName) {
      let form = this.$refs[formName]
      if (form) {
        form.resetFields()
      }
    },
   /* 点击表格内部图标查看药物名称和描述的来源引用*/
    showResourceReference(row,tableName){
      // console.log(row)
      // console.log(tableName)
      let id;
      let data;
      if(tableName=== tableConst.DKB_DRUG_ALIAS){
        if(row.dkbDrugAliasList && row.dkbDrugAliasList[0]){
          data=row.dkbDrugAliasList[0]
        }
      }
      // console.log(data)
      getReferenceInfo(tableName,data.id).then(result => {
        if(result.length==0){
          result=null
        }
        data.dkbReferenceInfos=result
      })

    },
   /* 药物名称检索*/
    querySearchAsync(queryString, cb) {
      if (queryString && queryString.trim() !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          selectDrugNameList(queryString.trim()).then(response => {
            cb(response.data);
          })
        }, 200)
      } else {
        cb([]);
      }
    },
    /*表格排序切换*/
    tableSortChange({column, prop, order}){
      if(order!=null){
        order=order=='descending'?'desc':'asc';
      }else{
        order=''
      }
      this.queryParams.orderByColumn=prop
      this.queryParams.isAsc=order
      this.handleQuery()
    },
    /*鼠标右键点击事件*/
    rowContextmenu (row, column, event) {
      this.tableContextMenuVisible = false
      this.tableContextMenuVisible = true
      // 阻止右键默认行为
      event.preventDefault()
      this.$nextTick(() => {
        this.$refs.contextbutton.init(row,column,event)
      })
    },
    /* 取消鼠标监听事件 菜单栏*/
    foo() {
      this.tableContextMenuVisible = false
      document.removeEventListener('click', this.foo)
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    /* 处理表格右键菜单点击*/
    handleTableAction (actionName,row, column) {
      this.drugTitle = row.name
      if(actionName==='修改'){
        this.handleUpdate(row)
      }else if(actionName==='复制'){
        this.handleCopydate(row)
      }else if(actionName==='来源'){
        this.handleSource(row)
      }else if(actionName==='新增制剂'){
        this.addPs(row)
      } else if(actionName==='多选'){
        this.isSelection = true
        this.tableAction = [{name:"新增药物组"},{name:"添加到已有药物组"},{name:"取消",style:'color: #FF3333;'}]
        // this.drawer = true
      } else if(actionName==='取消'){
        this.$refs.refundTable.clearSelection();
        this.isSelection = false
        this.tableAction = [{name:"修改"},{name:"复制"},{name:"来源"},{name:"新增制剂"},{name:"多选"},{name:"删除",style:'color: #FF3333;'}, {name:"分类维护"},{name:"查看"}]
      }else if(actionName==='新增药物组'){
        this.addDrugGroup(row)
      }else if(actionName==='添加到已有药物组'){
        this.areadyAddDrugGroup(row)
      }else if(actionName==='删除'){
        this.handleDelete(row)
      }else if(actionName==='查询相关制剂'){
        this.queryRelatedPreparation(row)
      } else if(actionName==='查看'){
        this.handleSearchdate(row)
      }else if(actionName==='分类维护'){
        this.handleDrugCategoryEdit(row.id)
      }
    },
    /*查询相关制剂*/
    queryRelatedPreparation(row){
      this.$store.dispatch('tagsView/delCachedView', {name: "PreparationSpecification"}).then(res => {
        this.$router.push({
          path:'/dkm/preparationSpecification',
          query: {
            drugName: row.name,
          }
        })
      })

    },
    // 处理分类维护
    handleDrugCategoryEdit(dataId){
      this.$refs.drugCategoryEdit.refreshDrugCategoryTree(dataId,3)
    },

  }
}
</script>

<style lang="scss" scoped>
.el-table .cell .el-form-item {
  margin-bottom: 0px;
}

/*查看来源引用图标*/
.el-icon-question{
  color: #E99B00;
  font-size: 16px;
  margin-right: 6px;
}
.leftIconName{
  display:block;
  float:left;
  text-align:right;
  min-width:40px;
}
.rightContentName{
  display:block;
  float:left;
  word-break:break-all;
  display:-webkit-box; /**对象作为伸缩盒子模型展示**/
  -webkit-box-orient:vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
  -webkit-line-clamp:2; /**显示的行数**/
  overflow:hidden;
  text-align:left;
}
.leftIcon{
  display:block;
  float:left;
  // width:50px;
}
.rightContent{
  display:block;
  float:left;
  width:650px;
  word-break:break-all;
  display:-webkit-box; /**对象作为伸缩盒子模型展示**/
  -webkit-box-orient:vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
  -webkit-line-clamp:2; /**显示的行数**/
  overflow:hidden;
  text-align:left;
}
.iconStyle{
  display: inline-block;
  // background: #ff0;
  min-width: 30px;
  text-align: right;
}
.delClass{
  text-decoration: line-through #ff0000;
}
.title-bar {
  border-left: 5px solid #0073E9;
  padding-left: 10px;
  border-radius: 2px;
  margin-bottom: 20px;
}


</style>


<style>
.headerClass .query-form .el-form-item__content{
  line-height: 0px;
}
</style>
