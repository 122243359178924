<template>
  <div class="mainTable">
    <div class="header">
      <div class="title">
        <div class="txt">DDD管理</div>
      </div>
    </div>
		<div class="search">
			<div class="leftSearch">
				<el-input @keyup.enter.native="search" v-model="searchName" placeholder="输入分类中文名/英文名/ATC代码"></el-input>
				<el-select style="margin-left: 10px;" class="version" v-model="version" clearable placeholder="版本">
					<el-option
						v-for="item in versionList"
						:key="item.version"
						:label="item.version"
						:value="item.version">
					</el-option>
				</el-select>
				<el-select class="isrefSelect" style="margin-left: 10px;" v-model="isref" clearable placeholder="是否有关联数据">
					<el-option
						v-for="item in isrefList"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
				<el-button @click="search" style="margin-left: 10px;" type="primary">查询</el-button>
			</div>
			<div class="rightSearch">
				<!-- <el-button icon="el-icon-plus" type="primary">批量导入</el-button> -->
				<el-button icon="el-icon-plus" type="primary" @click="newAdd">单条新增</el-button>
			</div>
		</div>
    <div class="tableList" ref="tableList">
      <el-table
        v-loading="loading"
        :data="tableData"
				highlight-current-row
        border
        stripe
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
				ref="mainTable"
				:row-class-name="tableRowClassName"
        :height="tableHeight"
				@row-click="displayAssociateTable"
				@sort-change="tableSortChange"
        style="width: 100%"
				:default-sort="{prop: 'id', order: 'ascending'}">
        <el-table-column
          prop="id"
          width="80"
					sortable="custom"
          label="编号">
        </el-table-column>
        <el-table-column
          prop="atcCode"
          sortable="custom"
          label="ATC代码">
        </el-table-column>
        <el-table-column
          prop="chName"
          sortable="custom"
          label="中文名">
        </el-table-column>
				<el-table-column
          prop="">
					<template slot="header" slot-scope="scope">
						<div style="display: flex;align-items: center;justify-content: center">
							<div>DDD值</div>
							<el-tooltip class="item" effect="dark" placement="top">
								<div style="width: 200px" slot="content">
									表中“DDD值”：无标记的为WHO-ATC中DDD值；左上角标记“*”的为DDD（C），是抗菌药监测网按WHO－ATC制定DDD的原则，经过专家讨论制定的DDD，仅供监测网范围内统计使用。
								</div>
								<img style="width:20px;height:20px;margin-left:5px" src="../../../assets/images/tip.png" />
							</el-tooltip>
						</div>
					</template>
					<template slot-scope="scope">
						<div>
							{{`${scope.row.dddWho ? scope.row.dddWho : ''}${scope.row.dddWhoUnitName ? scope.row.dddWhoUnitName : ''}${scope.row.dddC ? '*' : ''}${scope.row.dddC ? scope.row.dddC : ''}${scope.row.dddCUnitName ? scope.row.dddCUnitName : ''}`}}
						</div>
          </template>
        </el-table-column>
				<el-table-column
          prop=""
          label="给药途径">
					<template slot="header" slot-scope="scope">
						<div style="display: flex;align-items: center;justify-content: center">
							<div>给药途径</div>
							<el-tooltip class="item" effect="dark" placement="top">
								<div slot="content">
									Chewing gum = Chewing gum (口嚼胶剂)<br/>
									implant = implant (植入)<br/>
									Inhal.aerosol = Inhal.aerosol (吸入用气雾剂)<br/>
									Inhal.powder = Inhal.powder (吸入用粉剂)<br/>
									Inhal.solution = Inhal.solution (吸入用溶液)<br/>
									N = nasal (鼻内)<br/>
									O = oral (口服用药)<br/>
									ointment = ointment (软膏)<br/>
									oral aerosol = oral aerosol (口腔气雾剂)<br/>
									P = parenteral (肠道外给药(注射给药))<br/>
									R = rectal (直肠)<br/>
									SL = sublingual/buccal (舌下/颊)<br/>
									TD = transdermal (经皮)<br/>
									urethral = urethral (尿道给药)<br/>
									V = vaginal (阴道给药)<br/>
								</div>
								<img style="width:20px;height:20px;margin-left:5px" src="../../../assets/images/tip.png" />
							</el-tooltip>
						</div>
					</template>
					<template slot-scope="scope">
						<el-tooltip class="item" effect="dark" :content="`${scope.row.enName} ${scope.row.chName}`" placement="top">
							<div>{{scope.row.routeName}}</div>
						</el-tooltip>
          </template>
        </el-table-column>
				<el-table-column
          prop="refDataSize"
					width="60"
          label="关联数量">
        </el-table-column>
				<el-table-column
          prop="remark"
					width="80"
					show-overflow-tooltip
          label="备注">
        </el-table-column>
				<el-table-column
          prop="enName"
          sortable="custom"
          label="英文名">
        </el-table-column>
				<el-table-column
          prop="version"
          label="版本号">
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="100" align="center">
          <template slot-scope="scope">
						<div class="operateItem">
							<div @click.stop="edit(scope.row.id)" class="edit">编辑</div>
							<div @click.stop="del(scope.row.id)" class="delete">删除</div>
						</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
			<el-pagination
				background
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="currentPage"
				:page-sizes="[50, 100, 200, 400]"
				:page-size="pageSize"
				layout="total, sizes, prev, pager, next"
				:total="total">
			</el-pagination>
    </div>
		<newAdd :addVisible="addVisible" :isEdit="isEdit" :DDDid="DDDid" :title="title" @submit="submit" @closeAddForm="addVisible=false"></newAdd>
  </div>
</template>
<script>
import {getDDDTable, delDDDList, getVersionList} from '../../../api/dkm/DDDApi'
import newAdd from './add.vue'
import AcrossUtil from '@/utils/acrossUtil'
export default {
  components: {
		newAdd
  },
  data() {
    return {
			drugsTip: [],
			addVisible: false,
			title: '',
      tableHeight: window.innerHeight - 250,
			searchName: '',
			loading: false,
      tableData: [],
      total: 0,
      pageSize: 50,
      currentPage: 1,
			DDDid: '',
			isEdit: false,
			isAsc: 'asc',
      orderByColumn:'id',
			version: '',
			versionList: [],
			isref: '',
			isrefList: [{
				label: '是',
				value: '1'
			},{
				label: '否',
				value: '0'
			}]
    }
  },
  created() {
  },
  destroyed() {
    window.onresize = null;
  },
activated() {
  //激活时重新设置表格高度
  this.setTableHeight()
  this.$nextTick(() => {
    this.$refs.mainTable.doLayout()
  })

},
  mounted() {
    var _this=this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      AcrossUtil.$emit('resetTableHeight');
    }

    AcrossUtil.$on('resetTableHeight', function (param) {
      setTimeout(function () {
        _this.setTableHeight()
      }, 300);
    })



    // window.addEventListener('resize', () => {
		// 	this.tableHeight = 0
		// 	this.$nextTick(() => {
    //     this.tableHeight = this.$refs.tableList.clientHeight
    //   })
    // })
    // this.$nextTick(() => {
    //   this.tableHeight = this.$refs.tableList.clientHeight
    // })
		this.$eventBus.$on('refreshMainTable', (DDDid) => {
			this.getMainTable(DDDid)
		})
		this.getVersionList()
		this.getMainTable()
		// this.$refs.mainTable.setCurrentRow(this.tableData[0])
  },
  methods: {
    /*设置表格的高度*/
    setTableHeight(){
      this.tableHeight = window.innerHeight - 250

    },
		async getVersionList() {
			let res = await getVersionList()
			if(res.code == 200) {
				this.versionList = res.data
			}
		},
    /*表格排序切换*/
    tableSortChange({ column, prop, order }) {
      if (order != null) {
        order = order == 'descending' ? 'desc' : 'asc'
      } else {
        order = ''
      }
      this.orderByColumn = prop
      this.isAsc = order
      this.getMainTable()
    },
		sortchange(val) {
			this.isAsc = val.order == 'descending' ? 'desc' : 'asc'
      this.getMainTable()
		},
		tableRowClassName({row, rowIndex}) {
			if (row.refDataSize != '0') {
				return 'warning-row';
			}
			return '';
		},
		async getMainTable(val) {
			const params = {
				pageNum: this.currentPage,
				pageSize: this.pageSize,
				searchStr: this.searchName,
				orderByColumn: this.orderByColumn,
				isAsc: this.isAsc,
				version: this.version,
				isRef: this.isref
			}
			this.loading = true
			let res = await getDDDTable(params)
			this.loading = false
			if(res.code == 200) {
				this.tableData = res.rows
				this.total = res.total
				if(val) {
					this.tableData.forEach((item,index) => {
						if(val == item.id) {
							this.$refs.mainTable.setCurrentRow(this.tableData[index])
						}
					})
				} else {
					this.$refs.mainTable.setCurrentRow(this.tableData[0])
					this.$eventBus.$emit('displayAssociateTable', this.tableData[0].id, this.tableData[0].chName)
				}
			}
		},
		del(id) {
			this.$confirm('此操作将同时删除关联关系, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then( async () => {
				const params = {
					id:parseInt(id)
				}
				let res = await delDDDList(params)
				if(res.code == 200) {
					this.$message({
						type: 'success',
						message: '删除成功!'
					})
					this.getMainTable()
				}
			}).catch(() => {
			});
		},
		search() {
			this.currentPage = 1
			this.getMainTable()
		},
		submit() {
			this.addVisible = false
			this.currentPage = 1
			this.getMainTable()
		},
		edit(id) {
			this.title = '编辑'
			this.isEdit = true
			this.DDDid = id
			this.addVisible = true
		},
		newAdd() {
			this.title = '单条新增'
			this.isEdit = false
			this.addVisible = true
		},
		displayAssociateTable(row, column, event) {
			this.$eventBus.$emit('displayAssociateTable', row.id, row.chName)
		},
		handleSizeChange(val) {
      this.pageSize = val
      this.getMainTable()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getMainTable()
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-table .warning-row {
	background: #F5F8FA;
}

/* to fix 竖向滚动条被fixed列覆盖bug */
::v-deep .el-table .el-table__fixed-right {
  margin-right: 12px !important; // 12px为滚动条宽度
}

.mainTable {
  flex: 3;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: hidden;
  .pagination {
    //margin-top: 20px;
    border-top: unset;
    flex: 0 0 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #EBEEF5;
    border-radius: 0px 0px 8px 8px;
  }
  .tableList {
    //flex: 1;
    //overflow-y: hidden;
    .operateItem {
      display: flex;
      justify-content: center;
      .edit {
				cursor: pointer;
				width: 48px;
				font-size: 14px;
				font-weight: 400;
				color: #444444;
				line-height: 21px;
			}
			.delete {
				cursor: pointer;
				width: 48px;
				font-size: 14px;
				font-weight: 400;
				color: #F58289;
				line-height: 21px;
			}
			div +div {
				margin-left: 10px;
			}
    }
  }
	.search {
		margin: 10px 0;
		flex: 0 0 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		::v-deep .el-input {
			max-width: 300px;
		}
		.leftSearch {
			display: flex;
			align-items: center;
      .version{
        ::v-deep .el-input {
          max-width: 200px;
        }
      }
			.isrefSelect {
				::v-deep .el-input {
					max-width: 150px;
				}
			}
		}
		.rightSearch {
			display: flex;
			align-items: center;
		}
	}
  .header {
    flex: 0 0 32px;
    display: flex;
    justify-content: space-between;
    .title {
      display: flex;
      align-items: center;
      .txt {
        font-size: 20px;
        font-weight: 800;
        color: #333333;
        line-height: 30px;
      }
    }
  }
}
</style>
