<template>
  <el-header class="report-table-header">
    <div class="title-header-container">
      <span class="title-header"></span>
      <h4 class="title-text">
        匹配情况
      </h4>
    </div>
    <el-form :model="tableSearchObj" :inline="true">
      <el-form-item v-if="!isSelectedMatch" label="未匹配原因：">
        <el-select v-model="tableSearchObj.errorCodeList" multiple collapse-tags placeholder="请选择">
          <el-option
            v-for="item in errorOptions"
            :key="item.code"
            :label="item.name"
            :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model="tableSearchObj.searchValue" clearable placeholder="输入编号/药品通用名" />
      </el-form-item>
      <el-form-item v-if="isSelectedMatch" label="只看问题字段">
        <el-switch
          v-model="tableSearchObj.errorProblemnFlag"
          active-color="#2c5ff9"
          inactive-color="#b5ccf5"
          @change="errorFilterChange">
        </el-switch>
      </el-form-item>
      <el-form-item v-if="isSelectedMatch" label="筛选">
        <el-switch
          v-model="tableSearchObj.isFilter"
          active-color="#2c5ff9"
          inactive-color="#b5ccf5">
        </el-switch>
      </el-form-item>
      <el-form-item v-if="isSelectedMatch">
        <el-button size="small" type="primary" @click="onFilterReset">筛选重置</el-button>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="onSearchReportTable">查询</el-button>
      </el-form-item>
      <el-form-item v-if="isSelectedMatch">
        <el-button
          v-if="!enlarge"
          size="small"
          icon="el-icon-zoom-in"
          type="primary"
          @click="onEnlarge">放大</el-button>
        <el-button
          v-else
          size="small"
          icon="el-icon-zoom-in"
          type="primary"
          @click="onEnNormal">放大</el-button>
      </el-form-item>
    </el-form>
  </el-header>
</template>
<script>
  export default {
    props: {
      tableSearchObj: {
        type: Object,
        required: true
      },
      isSelectedMatch: {
        type: Boolean,
        required: true
      },
      errorOptions: {
        type: Array
      }
    },
    data() {
      return {
        enlarge: false, // 是否放大
      }
    },
    methods: {
      // 放大
      onEnlarge() {
        this.enlarge = true
      },
      onEnNormal() {
        this.enlarge = false
      },
      // 只看问题字段
      async errorFilterChange(val) {
        this.tableSearchObj.errorFilterFlag = val ? '1' : ''
        this.tableSearchObj.pageNum = 1
        await this.getTableHandle()
      },
      // 筛选重置
      onFilterReset() {
        if (this.tableSearchObj.isFilter) {
          this.$refs.matchTable && this.$refs.matchTable.resetFilterHandle()
        }
      },
      // 点击查询按钮
      async onSearchReportTable() {
        this.tableSearchObj.pageNum = 1
        await this.getTableHandle()
      },
    }
  }
</script>