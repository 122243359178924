/**
 * 单位转换系数相关
 */
import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/common'

// 根据domainCode获取转换系数链表
export function getConversionCoefficientLink(domainCode) {
  return request({
    url: '/term/unit/conversionCoefficient/getConversionCoefficientLink',
    method: 'get',
    params: {domainCode:domainCode}
  })
}

// 保存转换关系链表
export function saveConversionCoefficientLink(data) {
  return request({
    url: '/term/unit/conversionCoefficient/saveConversionCoefficientLink',
    method: 'post',
    data: data
  })
}

// 刷新缓存
export function refreshCache() {
  return request({
    url: '/term/unit/conversionCoefficient/refreshCache',
    method: 'get',
  })
}
