<!--klib维护页面-->
<template>
  <el-container class="layout-container klib-container">
    <el-header class="klib-header">
      <div class="title-container">
        <span class="title-text">klib发布管理</span>
      </div>
      <el-form :inline="true" :model="searchObj" class="search-form-wrap">
        <el-form-item label="版本号：">
          <el-input v-model="searchObj.version" placeholder="输入版本号" />
        </el-form-item>
        <el-form-item label="发布时间：">
          <el-date-picker
            v-model="searchObj.pubDateArr"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="dateChange">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="searchObj.pubStatus" placeholder="请选择" class="status-select">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain icon="el-icon-circle-plus-outline" @click="onAdd">新建KIib</el-button>
        </el-form-item>
      </el-form>
    </el-header>
    <el-main class="klib-main">
      <el-table
        ref="tableRef"
        :data="tableData"
        :header-cell-style="{textAlign: 'center', color: '#333'}"
        border
        stripe
        highlight-current-row
        height="100%"
        class="table-wrap">
        <el-table-column
          prop="id"
          label="编号"
          width="80"
          align="center"
          fixed="left">
        </el-table-column>
        <el-table-column
          prop="version"
          label="版本号"
          align="center"
          fixed="left">
        </el-table-column>
        <el-table-column
          prop="pubDate"
          label="发布日期"
          width="100">
        </el-table-column>
        <el-table-column
          prop="downloadLinkList"
          label="下载链接"
          show-overflow-tooltip
          min-width="200">
          <template slot-scope="scope">
            <!-- <div
              v-for="(item, index) in scope.row.downloadLinkList"
              :key="index"
              style="width: 100%"> -->
              <span
                v-for="(item, index) in scope.row.downloadLinkList"
                :key="index"
                class="clearfix url-p-wrap"
                style="margin-bottom: 5px;">
                <span
                  v-if="item.isPrimary === 1 && scope.row.downloadLinkList.length > 1"
                  class="primary-icon">主</span>
                <span v-if="item.isPrimary === 0" class="non-main-icon">备{{ index }}</span>
                <span>{{ item.url }}</span>
                <!-- <span style="color: #654CEE;margin-left: 10px;" class="fr">{{ item.passwd }}</span> -->
              </span>
            <!-- </div> -->
          </template>
        </el-table-column>
        <el-table-column
          prop="downloadLinkList"
          label="口令">
          <template slot-scope="scope">
            <div
              v-for="(item, index) in scope.row.downloadLinkList"
              :key="index">
              <p class="clearfix url-p-wrap" style="margin-bottom: 5px;">
                <!-- <span class="fl">{{ item.url }}</span> -->
                <span style="color: #654CEE;margin-left: 10px;">{{ item.passwd }}</span>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="vcMd5"
          label="md5"
          align="center">
        </el-table-column>
        <el-table-column
          prop="createBy"
          label="创建人"
          align="center"
          width="100">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间">
        </el-table-column>
        <el-table-column
          prop="updateBy"
          label="更新人"
          align="center"
          width="100">
        </el-table-column>
        <el-table-column
          prop="updateTime"
          label="更新时间"
          align="center">
        </el-table-column>
        <el-table-column
          prop="pubStatusStr"
          label="状态"
          align="center"
          width="90">
          <template slot-scope="scope">
            <div :class="pubStatusClass(scope.row.pubStatus)">{{ scope.row.pubStatusStr }}</div>
          </template>
        </el-table-column>
        <el-table-column
          align="left"
          width="130"
          type="index"
          fixed="right">
          <template slot="header" slot-scope="scope">
            <span>操作</span>
          </template>
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.pubStatus === 0 || scope.row.pubStatus === 9"
              type="text"
              class="primary-btn"
              @click="onEdit(scope.row)">编辑</el-button>
            <el-button
              v-if="scope.row.pubStatus === 0 || scope.row.pubStatus === 9"
              type="text"
              class="del-btn"
              @click="onDel(scope.row)">删除</el-button>
            <el-button
              v-if="scope.row.pubStatus === 0"
              type="text"
              class="primary-btn"
              @click="onPublish(scope.row)">发布</el-button>
            <el-button
              v-if="scope.row.pubStatus === 1"
              type="text"
              class="del-btn"
              @click="onRecall(scope.row)">撤回</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-footer class="klib-footer">
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="searchObj.pageNum"
        :limit.sync="searchObj.pageSize"
        @pagination="fetchTableData"/>
    </el-footer>
    <edit-klib-dialog-vue
      v-if="isShowDialog"
      :is-show-dialog.sync="isShowDialog"
      :current-row="currentRow"
      @save="saveHandle"
      @publish="publishHandle" />
  </el-container>
</template>
<script>
  import {
    STATUS_OPTIONS,
    QUERY_DATA_OBJ
  } from '@/utils/rationalDrugUse/klib'
  import {
    getKlibListApi,
    editKlibStatusApi,
    delKlibApi
  } from '@/api/rationalDrugUse/klib'
  import EditKlibDialogVue from './components/EditKlibDialog.vue'
  export default {
    name: 'Klib',
    components: {
      EditKlibDialogVue
    },
    data() {
      return {
        tableData: [],
        searchObj: this.$deepClone(QUERY_DATA_OBJ),
        statusOptions: this.$deepClone(STATUS_OPTIONS),
        value1: '',
        isShowDialog: false,
        isEdit: false,
        currentRow: {},
        total: 0
      }
    },
    computed: {
      pubStatusClass() {
        return (status) => {
          if (status === 0) {
            // 待发布
            return 'warning-btn'
          } else if (status === 1) {
            // 已发布
            return 'success-btn'
          } else if (status === 9) {
            // 已撤回
            return 'error-btn'
          }
        }
      }
    },
    async activated() {
      await this.fetchTableData()
    },
    methods: {
      async fetchTableData() {
        const { pubDateArr } = this.searchObj
        this.searchObj.startTime = pubDateArr && pubDateArr.length === 2 ? pubDateArr[0] : ''
        this.searchObj.endTime = pubDateArr && pubDateArr.length === 2 ? pubDateArr[1] : ''
        let res = await getKlibListApi(this.searchObj)
        if (res.code === 200) {
          res.data.rows && res.data.rows.forEach(item => {
            item.downloadLinkList.forEach(link => link.isEdit = false)
          })
          this.tableData = res.data.rows || []
          this.total = res.data.total
        }
      },
      dateChange() {
        console.log(this.value1)
      },
      // 查询
      async onSearch() {
        this.searchObj.pageNum = 1
        await this.fetchTableData()
      },
      onAdd() {
        this.currentRow = {}
        this.isShowDialog = true
        this.isEdit = false
      },
      // 编辑
      async onEdit(row) {
        this.isShowDialog = true
        this.isEdit = true
        this.currentRow = this.$deepClone(row)
      },
      // 发布
      async onPublish(row) {
        let obj = {
          id: row.id,
          pubStatus: 1
        }
        let res = await editKlibStatusApi(obj)
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '发布成功'
          })
          await this.fetchTableData()
        }
      },
      // 删除
      async onDel(row) {
        this.$confirm('确定要删除此条数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await this.delAxios(row)
        }).catch((action) => {
          if (action === 'cancel') {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })    
          } 
        })
      },
      // 删除调接口
      async delAxios(row) {
        const obj = {
          id: row.id
        }
        let res = await delKlibApi(obj)
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '删除成功'
          })
          await this.fetchTableData()
        }
      },
      // 撤回
      async onRecall(row) {
        let obj = {
          id: row.id,
          pubStatus: 9
        }
        let res = await editKlibStatusApi(obj)
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '撤回成功'
          })
          await this.fetchTableData()
        }
      },
      // 编辑或新增  保存
      async saveHandle(data) {
        if (data.status) {
          await this.onSearch()
        }
      },
      // 编辑或新增  发布
      async publishHandle(data) {
        if (data.status) {
          await this.onSearch()
        }
      }
    },
  }
</script>
<style lang="scss" scoped>
  .klib-container {
    .klib-header {
      min-height: 60px !important;
      height: auto !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 10px;
      .title-container {
        &:before {
          content: '';
          display: inline-block;
          width: 4px;
          height: 18px;
          background: #654CEE;
          border-radius: 2px;
          margin-right: 6px;
          vertical-align: middle;
        }
        .title-text {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #3A3A3A;
          vertical-align: top;
        }
      }
      .search-form-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 10px;
        .el-form-item {
          margin-bottom: 0;
          ::v-deep.el-select.status-select {
            .el-input {
              width: 120px !important;
            }
          }
          ::v-deep .el-date-editor {
            width: 240px !important;
          }
        }
      }
    }
    .klib-main {
      padding-top: 0 !important;
      .table-wrap {
        ::v-deep .url-p-wrap {
          margin-bottom: 6px !important;
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          &:last-child {
            margin-bottom: 0;
          }
        }
        ::v-deep .primary-icon {
          display: inline-block;
          background-color: #654CEE;
          border-radius: 2px;
          font-size: 12px;
          color: #fff;
          /* padding: 0px 6px; */
          width: 26px;
          height: 18px;
          text-align: center;
          margin-right: 5px;
        }
        ::v-deep .non-main-icon {
          display: inline-block;
          border: 1px solid #654CEE;
          border-radius: 2px;
          font-size: 12px;
          color: #654CEE;
          width: 26px;
          height: 18px;
          text-align: center;
          margin-right: 5px;
        }
        ::v-deep .warning-btn {
          width: 58px;
          height: 26px;
          background: #FDEEDA;
          border-radius: 3px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #E19100;
          margin: 0 auto;
          line-height: 26px;
        }
        ::v-deep .error-btn {
          width: 58px;
          height: 26px;
          background: #FFE0E2;
          border-radius: 3px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #FF3333;
          margin: 0 auto;
          line-height: 26px;
        }
        ::v-deep .success-btn {
          width: 58px;
          height: 26px;
          background: #D4FFCC;
          border-radius: 3px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #5DB730;
          margin: 0 auto;
          line-height: 26px;
        }
        ::v-deep .del-btn {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #F53A32;
        }
        ::v-deep .primary-btn {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #2A52F4;
        }
      }
    }
  }
</style>