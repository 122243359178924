var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "layout-container klib-container" },
    [
      _c(
        "el-header",
        { staticClass: "klib-header" },
        [
          _c("div", { staticClass: "title-container" }, [
            _c("span", { staticClass: "title-text" }, [_vm._v("klib发布管理")]),
          ]),
          _c(
            "el-form",
            {
              staticClass: "search-form-wrap",
              attrs: { inline: true, model: _vm.searchObj },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "版本号：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入版本号" },
                    model: {
                      value: _vm.searchObj.version,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchObj, "version", $$v)
                      },
                      expression: "searchObj.version",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发布时间：" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "至",
                      "value-format": "yyyy-MM-dd",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    on: { change: _vm.dateChange },
                    model: {
                      value: _vm.searchObj.pubDateArr,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchObj, "pubDateArr", $$v)
                      },
                      expression: "searchObj.pubDateArr",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态：" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "status-select",
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.searchObj.pubStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchObj, "pubStatus", $$v)
                        },
                        expression: "searchObj.pubStatus",
                      },
                    },
                    _vm._l(_vm.statusOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.onSearch },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        plain: "",
                        icon: "el-icon-circle-plus-outline",
                      },
                      on: { click: _vm.onAdd },
                    },
                    [_vm._v("新建KIib")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-main",
        { staticClass: "klib-main" },
        [
          _c(
            "el-table",
            {
              ref: "tableRef",
              staticClass: "table-wrap",
              attrs: {
                data: _vm.tableData,
                "header-cell-style": { textAlign: "center", color: "#333" },
                border: "",
                stripe: "",
                "highlight-current-row": "",
                height: "100%",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "id",
                  label: "编号",
                  width: "80",
                  align: "center",
                  fixed: "left",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "version",
                  label: "版本号",
                  align: "center",
                  fixed: "left",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "pubDate", label: "发布日期", width: "100" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "downloadLinkList",
                  label: "下载链接",
                  "show-overflow-tooltip": "",
                  "min-width": "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(
                        scope.row.downloadLinkList,
                        function (item, index) {
                          return _c(
                            "span",
                            {
                              key: index,
                              staticClass: "clearfix url-p-wrap",
                              staticStyle: { "margin-bottom": "5px" },
                            },
                            [
                              item.isPrimary === 1 &&
                              scope.row.downloadLinkList.length > 1
                                ? _c("span", { staticClass: "primary-icon" }, [
                                    _vm._v("主"),
                                  ])
                                : _vm._e(),
                              item.isPrimary === 0
                                ? _c("span", { staticClass: "non-main-icon" }, [
                                    _vm._v("备" + _vm._s(index)),
                                  ])
                                : _vm._e(),
                              _c("span", [_vm._v(_vm._s(item.url))]),
                            ]
                          )
                        }
                      )
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "downloadLinkList", label: "口令" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(
                        scope.row.downloadLinkList,
                        function (item, index) {
                          return _c("div", { key: index }, [
                            _c(
                              "p",
                              {
                                staticClass: "clearfix url-p-wrap",
                                staticStyle: { "margin-bottom": "5px" },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#654CEE",
                                      "margin-left": "10px",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.passwd))]
                                ),
                              ]
                            ),
                          ])
                        }
                      )
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "vcMd5", label: "md5", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createBy",
                  label: "创建人",
                  align: "center",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "updateBy",
                  label: "更新人",
                  align: "center",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "updateTime",
                  label: "更新时间",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "pubStatusStr",
                  label: "状态",
                  align: "center",
                  width: "90",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { class: _vm.pubStatusClass(scope.row.pubStatus) },
                          [_vm._v(_vm._s(scope.row.pubStatusStr))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "left",
                  width: "130",
                  type: "index",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("操作")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.pubStatus === 0 || scope.row.pubStatus === 9
                          ? _c(
                              "el-button",
                              {
                                staticClass: "primary-btn",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onEdit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        scope.row.pubStatus === 0 || scope.row.pubStatus === 9
                          ? _c(
                              "el-button",
                              {
                                staticClass: "del-btn",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDel(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                        scope.row.pubStatus === 0
                          ? _c(
                              "el-button",
                              {
                                staticClass: "primary-btn",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onPublish(scope.row)
                                  },
                                },
                              },
                              [_vm._v("发布")]
                            )
                          : _vm._e(),
                        scope.row.pubStatus === 1
                          ? _c(
                              "el-button",
                              {
                                staticClass: "del-btn",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onRecall(scope.row)
                                  },
                                },
                              },
                              [_vm._v("撤回")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-footer",
        { staticClass: "klib-footer" },
        [
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.searchObj.pageNum,
              limit: _vm.searchObj.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.searchObj, "pageNum", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.searchObj, "pageSize", $event)
              },
              pagination: _vm.fetchTableData,
            },
          }),
        ],
        1
      ),
      _vm.isShowDialog
        ? _c("edit-klib-dialog-vue", {
            attrs: {
              "is-show-dialog": _vm.isShowDialog,
              "current-row": _vm.currentRow,
            },
            on: {
              "update:isShowDialog": function ($event) {
                _vm.isShowDialog = $event
              },
              "update:is-show-dialog": function ($event) {
                _vm.isShowDialog = $event
              },
              save: _vm.saveHandle,
              publish: _vm.publishHandle,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }