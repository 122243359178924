/**系统管理--用户管理 */
const FORM_DATA = {
	userId: undefined,
	deptId: undefined,
	userName: undefined,
	nickName: undefined,
	password: undefined,
	phonenumber: undefined,
	email: undefined,
	sex: undefined,
	status: "0",
	remark: undefined,
	postIds: [],
	roleIds: []
}
export {
	FORM_DATA
}
