var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "associateTable" },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "title" }, [
          _c("div", { staticClass: "txt" }, [_vm._v("关联列表")]),
          _c(
            "div",
            {
              staticClass: "txt",
              staticStyle: { "margin-left": "20px", color: "#6f78f5" },
            },
            [_vm._v(_vm._s(_vm.associateTitle))]
          ),
        ]),
      ]),
      _c("div", { staticClass: "search" }, [
        _c(
          "div",
          { staticClass: "leftSearch" },
          [
            _c("el-input", {
              staticStyle: { width: "200px" },
              attrs: { placeholder: "请输入名称" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.getRefTableList($event)
                },
              },
              model: {
                value: _vm.searchName,
                callback: function ($$v) {
                  _vm.searchName = $$v
                },
                expression: "searchName",
              },
            }),
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { type: "primary" },
                on: { click: _vm.getRefTableList },
              },
              [_vm._v("查询")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "rightSearch" },
          [
            _c(
              "el-button",
              {
                attrs: { icon: "el-icon-plus", type: "primary" },
                on: { click: _vm.newAddRefTable },
              },
              [_vm._v("新增")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { ref: "tableList", staticClass: "tableList" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "associateTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
                fit: "",
                stripe: "",
                height: _vm.tableHeight,
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "refTypeName", width: "80", label: "标识类型" },
              }),
              _c("el-table-column", {
                attrs: { prop: "refId", width: "80", label: "标识ID" },
              }),
              _c("el-table-column", {
                attrs: { prop: "refName", label: "名称" },
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "更新时间" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "80",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "operateItem" }, [
                          _c(
                            "div",
                            {
                              staticClass: "delete",
                              on: {
                                click: function ($event) {
                                  return _vm.del(scope.row)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("refAdd", {
        attrs: {
          associateTitle: _vm.associateTitle,
          selectedID: _vm.selectedID,
          refaddVisible: _vm.refaddVisible,
          DDDid: _vm.DDDid,
        },
        on: {
          confirmrefAddForm: _vm.confirmrefAddForm,
          closerefAddForm: function ($event) {
            _vm.refaddVisible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }