import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/common'


// 查询全部开发者用户列表
export function selectAppUserList(query) {
  return request({
    url: '/appUser/selectAppUserList',
    method: 'post',
    params: query
  })
}


// 开发者用户信息明细
export function userInfo(appId) {
  return request({
    url: '/appUser/userInfo/' + praseStrEmpty(appId),
    method: 'get'
  })
}



// 更新开发者账号状态
export function updateUserStatus(appId,status) {
  return request({
    url: '/appUser/updateUserStatus',
    method: 'post',
    params: {appId:appId,status:status}
  })
}


// 生成开发者密码
export function createAppSecret() {
  return request({
    url: '/appUser/createAppSecret',
    method: 'post',
  })
}


// 获取当前登录人的开发者用户信息明细
export function getAppUserInfo() {
  return request({
    url: '/appUser/getAppUserInfo',
    method: 'get'
  })
}

// 注册开发者账号
export function register() {
  return request({
    url: '/appUser/register',
    method: 'post',
    data: {}
  })
}


// 更新开发者账号信息
export function saveOrUpdateUserInfo(data) {
  return request({
    url: '/appUser/saveOrUpdateUserInfo',
    method: 'post',
    data: data
  })
}
