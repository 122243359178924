var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "layout-container platform-report" },
    [
      _c(
        "el-header",
        { staticClass: "platform-report-header" },
        [
          _c(
            "div",
            {
              staticClass: "title-header-container",
              attrs: { id: "1" },
              on: { click: _vm.onSearchTable },
            },
            [
              _c("span", { staticClass: "title-header" }),
              _c("h4", { staticClass: "title-text" }, [_vm._v("新药入库维护")]),
            ]
          ),
          _c(
            "el-form",
            { attrs: { model: _vm.queryObj, inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-autocomplete", {
                    staticStyle: {
                      width: "200px",
                      height: "36px!important",
                      "line-height": "36px",
                    },
                    attrs: {
                      "fetch-suggestions": _vm.onOrgSearch,
                      placeholder: "输入机构名称",
                      clearable: "",
                      size: "medium",
                      "value-key": "name",
                    },
                    on: { select: _vm.onSelectOrg, clear: _vm.onClearOrg },
                    model: {
                      value: _vm.queryObj.orgName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.queryObj,
                          "orgName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "queryObj.orgName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: {
                      width: "200px",
                      height: "36px!important",
                      "line-height": "36px",
                    },
                    attrs: {
                      placeholder: "输入贯标码/药品通用名",
                      clearable: "",
                      size: "medium",
                      "value-key": "name",
                    },
                    model: {
                      value: _vm.queryObj.searchVal,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.queryObj,
                          "searchVal",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "queryObj.searchVal",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "申请时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "至",
                      "value-format": "yyyy-MM-dd",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.queryObj.timeList,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryObj, "timeList", $$v)
                      },
                      expression: "queryObj.timeList",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否已处理" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.queryObj.webStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryObj, "webStatus", $$v)
                        },
                        expression: "queryObj.webStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("未处理"),
                      ]),
                      _c("el-radio", { attrs: { label: 4 } }, [
                        _vm._v("已处理"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        icon: "el-icon-search",
                      },
                      on: { click: _vm.onSearchTable },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-main",
        { staticClass: "platform-report-main" },
        [
          _c(
            "el-table",
            {
              ref: "tableRef",
              staticClass: "platform-report-table",
              staticStyle: { width: "100%", height: "100%" },
              attrs: {
                data: _vm.tableData,
                "header-cell-style": { textAlign: "center" },
                stripe: "",
                border: "",
              },
              on: { "sort-change": _vm.onSortChange },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", width: "100", fixed: "" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("机构ID")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.orgId))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("机构名称")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.orgName))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("贯标码")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.stateMedicareNum)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v("药品通用名")]),
                        _c("svg-icon", {
                          staticStyle: {
                            width: "24px",
                            height: "16px",
                            cursor: "pointer",
                          },
                          attrs: { "icon-class": _vm.genericNameOrderIcon },
                          on: { click: _vm.genericNameOrder },
                        }),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.pdpName))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("规格包装")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.pdpSpecTxt))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("包装材料")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.wrapperDesc))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("生产厂家")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.manufacturerName)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "addTime",
                  sortable: "custom",
                  "column-key": "addTime",
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("进入列表时间")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { class: _vm.statusText(scope) }, [
                          _vm._v(_vm._s(scope.row.addTime)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", width: "100", type: "index" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("状态")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.storageStatusDesc)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "100",
                  type: "index",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("操作")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.showHandle(scope.row.storageStatus)
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toHandle(scope.row)
                                  },
                                },
                              },
                              [_vm._v("处理")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toView(scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-footer",
        [
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.queryObj.pageNum,
              limit: _vm.queryObj.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryObj, "pageNum", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryObj, "pageSize", $event)
              },
              pagination: _vm.getTableHandle,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }