var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "layout-container insti-report-container" },
    [
      _c(
        "el-header",
        { staticClass: "insti-report-header box-shadow-radius" },
        [
          _c("div", { staticClass: "fl common-title" }, [_vm._v(" 审核报告 ")]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.searchObj,
                inline: true,
                "label-width": "88px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "申请时间：" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.searchObj.year,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchObj, "year", $$v)
                      },
                      expression: "searchObj.year",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "查看范围：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "范围" },
                      model: {
                        value: _vm.searchObj.time,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchObj, "time", $$v)
                        },
                        expression: "searchObj.time",
                      },
                    },
                    _vm._l(_vm.typeOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary", icon: "el-icon-search" } },
                    [_vm._v("申请治理报告")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-main",
        { staticStyle: { padding: "0" } },
        [
          _c(
            "el-container",
            { staticClass: "layout-container insti-report-content-container" },
            [
              _c(
                "el-aside",
                {
                  staticClass: "box-shadow-radius",
                  staticStyle: {
                    height: "100%",
                    "min-height": "auto",
                    padding: "10px 15px",
                  },
                },
                [_c("history-list")],
                1
              ),
              _c(
                "el-main",
                { staticClass: "content-main" },
                [
                  _c(
                    "el-container",
                    { staticClass: "layout-container" },
                    [
                      _c(
                        "el-header",
                        { staticClass: "chart-container box-shadow-radius" },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: { xs: 8, sm: 8, md: 6, lg: 6, xl: 6 },
                                },
                                [
                                  _c("h4", { staticClass: "chart-title" }, [
                                    _vm._v("匹配项、未匹配项占比"),
                                  ]),
                                  _c("div", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "150px",
                                    },
                                    attrs: { id: "pie-chart-one" },
                                  }),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: { xs: 8, sm: 8, md: 6, lg: 6, xl: 6 },
                                },
                                [
                                  _c("h4", { staticClass: "chart-title" }, [
                                    _vm._v("出现问题、未发现问题占比"),
                                  ]),
                                  _c("div", {
                                    staticStyle: { width: "", height: "150px" },
                                    attrs: { id: "pie-chart-two" },
                                  }),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: { xs: 8, sm: 8, md: 6, lg: 6, xl: 6 },
                                },
                                [
                                  _c("h4", { staticClass: "chart-title" }, [
                                    _vm._v("风险项、优化项占比"),
                                  ]),
                                  _c("div", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "150px",
                                    },
                                    attrs: { id: "pie-chart-three" },
                                  }),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: { xs: 8, sm: 8, md: 6, lg: 6, xl: 6 },
                                },
                                [
                                  _c("h4", { staticClass: "chart-title" }, [
                                    _vm._v("各字段错误占比"),
                                  ]),
                                  _c("div", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "150px",
                                    },
                                    attrs: { id: "pie-chart-four" },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-main",
                        {
                          staticClass: "box-shadow-radius",
                          staticStyle: { padding: "0" },
                        },
                        [
                          _c(
                            "el-container",
                            { staticClass: "layout-container table-container" },
                            [
                              _c(
                                "el-header",
                                [
                                  _c(
                                    "el-form",
                                    { attrs: { model: _vm.tableSearchObj } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "筛选模式" } },
                                        [
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#2c5ff9",
                                              "inactive-color": "#b5ccf5",
                                            },
                                            on: { change: _vm.filterChange },
                                            model: {
                                              value:
                                                _vm.tableSearchObj.isFilter,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.tableSearchObj,
                                                  "isFilter",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tableSearchObj.isFilter",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-main",
                                [
                                  _c(
                                    "el-table",
                                    {
                                      staticClass: "grade-table",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        data: _vm.tableData,
                                        "span-method": _vm.getSummaries,
                                        "header-cell-class-name":
                                          _vm.headerGradeInfo,
                                        "cell-class-name": _vm.gradeInfo,
                                        height: "100%",
                                        border: "",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          width: "160",
                                          prop: "ustdCode",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "header",
                                            fn: function (scope) {
                                              return [
                                                _c("span", [
                                                  _vm._v("药品编码"),
                                                ]),
                                                _c(
                                                  "el-popover",
                                                  {
                                                    ref: "elPopover",
                                                    attrs: {
                                                      trigger: "click",
                                                      placement: "right",
                                                      "popper-class":
                                                        "governance-poper",
                                                      "visible-arrow": false,
                                                    },
                                                    on: {
                                                      hide: function ($event) {
                                                        return _vm.onHidePopover(
                                                          scope
                                                        )
                                                      },
                                                      show: function ($event) {
                                                        return _vm.onShowPopover(
                                                          scope
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("filter-cascader", {
                                                      ref:
                                                        "filter" + scope.$index,
                                                      attrs: {
                                                        "show-filter":
                                                          _vm.tableSearchObj
                                                            .isFilter,
                                                        "filter-options":
                                                          _vm.filterOptions,
                                                        column: scope,
                                                      },
                                                      on: {
                                                        closeFilter:
                                                          _vm.onCloseFilter,
                                                      },
                                                    }),
                                                    _vm.tableSearchObj.isFilter
                                                      ? _c("svg-icon", {
                                                          staticClass:
                                                            "filter-icon",
                                                          staticStyle: {
                                                            position:
                                                              "absolute",
                                                            right: "10px",
                                                            width: "20px",
                                                            height: "20px",
                                                          },
                                                          attrs: {
                                                            slot: "reference",
                                                            "icon-class":
                                                              "shaixuan",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onFilterClick(
                                                                scope
                                                              )
                                                            },
                                                          },
                                                          slot: "reference",
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(scope.row.ustdCode)
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _c("el-table-column", {
                                        attrs: { width: "180" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "header",
                                            fn: function (scope) {
                                              return [
                                                _c("span", [
                                                  _vm._v("药品通用名"),
                                                ]),
                                                _c(
                                                  "el-popover",
                                                  {
                                                    ref: "elPopover",
                                                    attrs: {
                                                      trigger: "click",
                                                      placement: "right",
                                                      "popper-class":
                                                        "governance-poper",
                                                      "visible-arrow": false,
                                                    },
                                                    on: {
                                                      hide: function ($event) {
                                                        return _vm.onHidePopover(
                                                          scope
                                                        )
                                                      },
                                                      show: function ($event) {
                                                        return _vm.onShowPopover(
                                                          scope
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("filter-cascader", {
                                                      ref:
                                                        "filter" + scope.$index,
                                                      attrs: {
                                                        "show-filter":
                                                          _vm.tableSearchObj
                                                            .isFilter,
                                                        "filter-options":
                                                          _vm.filterOptions,
                                                        column: scope,
                                                      },
                                                      on: {
                                                        closeFilter:
                                                          _vm.onCloseFilter,
                                                      },
                                                    }),
                                                    _vm.tableSearchObj.isFilter
                                                      ? _c("svg-icon", {
                                                          staticClass:
                                                            "filter-icon",
                                                          staticStyle: {
                                                            position:
                                                              "absolute",
                                                            right: "10px",
                                                            width: "20px",
                                                            height: "20px",
                                                          },
                                                          attrs: {
                                                            slot: "reference",
                                                            "icon-class":
                                                              "shaixuan",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onFilterClick(
                                                                scope
                                                              )
                                                            },
                                                          },
                                                          slot: "reference",
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row
                                                        .genericNameCompare
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _c("el-table-column", {
                                        staticClass: "none-border",
                                        attrs: { width: "50" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row
                                                        .genericNameCompareStatus
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _c("el-table-column", {
                                        attrs: { label: "规格" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.specCompare
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          width: "50",
                                          prop: "specCompareStatus",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "el-popover",
                                                  {
                                                    ref:
                                                      "popoverspecCompareStatus" +
                                                      scope.$index,
                                                    attrs: {
                                                      trigger: "click",
                                                      placement: "bottom",
                                                      "visible-arrow": false,
                                                      "popper-class":
                                                        "governance-poper",
                                                    },
                                                    on: {
                                                      hide: function ($event) {
                                                        return _vm.onHidePopover(
                                                          scope
                                                        )
                                                      },
                                                      show: function ($event) {
                                                        return _vm.onShowPopover(
                                                          scope
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("grade-cascader", {
                                                      ref:
                                                        "filterspecCompareStatus" +
                                                        scope.$index,
                                                      attrs: {
                                                        status:
                                                          scope.row
                                                            .specCompareStatus,
                                                        "show-filter":
                                                          _vm.tableSearchObj
                                                            .isFilter,
                                                        "filter-options":
                                                          _vm.gradeOptions,
                                                      },
                                                      on: {
                                                        closeFilter: function (
                                                          $event
                                                        ) {
                                                          return _vm.onCloseFilter(
                                                            scope
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        attrs: {
                                                          slot: "reference",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onGradeFilterClick(
                                                              scope
                                                            )
                                                          },
                                                        },
                                                        slot: "reference",
                                                      },
                                                      [
                                                        _vm.tableSearchObj
                                                          .isFilter
                                                          ? _c("svg-icon", {
                                                              staticClass:
                                                                "filter-icon",
                                                              staticStyle: {
                                                                position:
                                                                  "absolute",
                                                                right: "16px",
                                                                top: "50%",
                                                                transform:
                                                                  "translateY(-50%)",
                                                                width: "18px",
                                                                height: "18px",
                                                              },
                                                              attrs: {
                                                                "icon-class":
                                                                  _vm.gradeIcon(
                                                                    scope.row
                                                                      .specCompareStatus
                                                                  ),
                                                              },
                                                            })
                                                          : _vm._e(),
                                                        _c("transition", [
                                                          scope.row.isFolded
                                                            ? _c("i", {
                                                                staticClass:
                                                                  "el-icon-caret-bottom icon-class",
                                                              })
                                                            : _c("i", {
                                                                staticClass:
                                                                  "el-icon-caret-top icon-class",
                                                              }),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "剂型名称",
                                          width: "180",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.formNameCompare
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _c("el-table-column", {
                                        attrs: { width: "50" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row
                                                        .formNameCompareStatus
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _c("el-table-column", {
                                        attrs: { label: "厂商" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row
                                                        .manufacturerCompare
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _c("el-table-column", {
                                        attrs: { width: "50" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row
                                                        .manufacturerCompareStatus
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _c("el-table-column", {
                                        attrs: { label: "批准文号" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row
                                                        .approvalNoCompare
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _c("el-table-column", {
                                        attrs: { width: "50" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row
                                                        .approvalNoCompareStatus
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-footer",
                                [
                                  _c("pagination", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.total > 0,
                                        expression: "total > 0",
                                      },
                                    ],
                                    attrs: {
                                      total: _vm.total,
                                      page: _vm.currentPage,
                                      limit: _vm.pageSize,
                                    },
                                    on: {
                                      "update:page": function ($event) {
                                        _vm.currentPage = $event
                                      },
                                      "update:limit": function ($event) {
                                        _vm.pageSize = $event
                                      },
                                      pagination: _vm.getTableHandle,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }