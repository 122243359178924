var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
      staticStyle: { overflow: "auto" },
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            {
              staticStyle: {
                "border-left": "5px solid #0073E9",
                "padding-left": "10px",
              },
              attrs: { span: 4 },
            },
            [_vm._v("开发者用户信息")]
          ),
        ],
        1
      ),
      !_vm.appUserInfo
        ? _c(
            "el-row",
            { staticStyle: { margin: "30px 50px" } },
            [
              _c(
                "span",
                {
                  staticStyle: { "padding-right": "10px", "font-size": "14px" },
                },
                [_vm._v("您还不是开发者用户")]
              ),
              _c(
                "el-link",
                { attrs: { type: "primary" }, on: { click: _vm.register } },
                [_vm._v("立即申请")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.appUserInfo
        ? _c(
            "el-row",
            { staticStyle: { margin: "30px 50px" } },
            [
              _c("el-col", { staticClass: "label-item", attrs: { span: 4 } }, [
                _c("span", [_vm._v(" 开发者ID(AppID):")]),
              ]),
              _c("el-col", { attrs: { span: 20 } }, [
                _c("p", { staticClass: "val-item" }, [
                  _vm._v(_vm._s(_vm.appUserInfo.appId)),
                ]),
                _c("p", { staticClass: "val-item" }, [
                  _vm._v(
                    "开发者ID是平台的开发识别码，配合开发者密码可调用平台的数据接口"
                  ),
                ]),
              ]),
              _c("el-col", {
                staticStyle: { "margin-top": "30px" },
                attrs: { span: 24 },
              }),
              _c("el-col", { staticClass: "label-item", attrs: { span: 4 } }, [
                _c("span", [_vm._v(" 开发者密码(AppSecret):")]),
              ]),
              _c("el-col", { attrs: { span: 20 } }, [
                _c(
                  "span",
                  { staticClass: "val-item" },
                  [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.resetAppSecret },
                      },
                      [
                        _vm._v("重置"),
                        _c("i", {
                          staticClass: "el-icon-view el-icon-s-tools",
                          staticStyle: { "padding-left": "5px" },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c("p", { staticClass: "val-item" }, [
                  _vm._v(
                    " 开发者密码是校验平台开发者身份的密码。切记勿把密码直接交给第三方开发者或直接存储在代码中。 为保障帐号安全，平台不储存AppSecret；如果遗忘，请重置"
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.appUserInfo ? _c("el-divider") : _vm._e(),
      _vm.appUserInfo
        ? _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "border-left": "5px solid #0073E9",
                    "padding-left": "10px",
                  },
                  attrs: { span: 4 },
                },
                [_vm._v("服务器配置")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.appUserInfo
        ? _c(
            "el-row",
            { staticStyle: { margin: "10px 50px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: { size: "medium" },
                      on: { click: _vm.showServerMsgDialog },
                    },
                    [_vm._v("修改配置")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-col",
                    { staticClass: "label-item", attrs: { span: 4 } },
                    [_vm._v(" 服务器地址(URL): ")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "val-item", attrs: { span: 20 } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.appUserInfo.callbackUrl || "未填写") +
                          " "
                      ),
                    ]
                  ),
                  _c("el-col", {
                    staticStyle: { "margin-top": "30px" },
                    attrs: { span: 24 },
                  }),
                  _c(
                    "el-col",
                    { staticClass: "label-item", attrs: { span: 4 } },
                    [_vm._v(" 令牌(Token): ")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "val-item", attrs: { span: 20 } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.appUserInfo.token || "未填写") + " "
                      ),
                    ]
                  ),
                  _c("el-col", {
                    staticStyle: { "margin-top": "30px" },
                    attrs: { span: 24 },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.appUserInfo ? _c("el-divider") : _vm._e(),
      _vm.appUserInfo
        ? _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "border-left": "5px solid #0073E9",
                    "padding-left": "10px",
                  },
                  attrs: { span: 4 },
                },
                [_vm._v("接口权限")]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-top": "30px", padding: "0px 30px" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        border: "",
                        stripe: "",
                        "header-cell-style": {
                          "text-align": "center",
                          background: "#ECF2F0",
                        },
                        "cell-style": { "text-align": "center" },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "apiServerName",
                          label: "服务名称",
                          "min-width": "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "apiName",
                          label: "API名称",
                          "min-width": "200",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "apiUrl",
                          label: "API地址",
                          "min-width": "300",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "服务器信息编辑",
            width: "730px",
            visible: _vm.serverMsgDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.serverMsgDialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "margin-bottom": "30px" } }, [
            _c("p", { staticClass: "val-item" }, [
              _vm._v("请填写接口配置信息，此信息需要你拥有自己的服务器资源。"),
            ]),
            _c("p", { staticClass: "val-item" }, [
              _vm._v(
                "填写的URL需要正确响应知识库系统发送的Token验证，请阅读接入指南。"
              ),
            ]),
          ]),
          _c(
            "el-form",
            {
              ref: "serverMsgEditForm",
              attrs: {
                "status-icon": "",
                model: _vm.serverMsgEditFormData,
                "label-width": "160px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "URL:",
                    prop: "callbackUrl",
                    rules: [
                      { required: true, message: "请输入URL", trigger: "blur" },
                      { validator: _vm.validateCallbackUrl, trigger: "blur" },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { "min-width": "200px", "max-width": "400px" },
                    attrs: {
                      placeholder: "请输入URL",
                      maxlength: "150",
                      clearable: "",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.serverMsgEditFormData.callbackUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.serverMsgEditFormData, "callbackUrl", $$v)
                      },
                      expression: "serverMsgEditFormData.callbackUrl",
                    },
                  }),
                  _c("div", { staticClass: "sub-title" }, [
                    _vm._v("必须以http://或https://开头。"),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Token:",
                    prop: "token",
                    rules: [
                      {
                        required: true,
                        message: "请输入token",
                        trigger: "blur",
                      },
                      { validator: _vm.validateToken, trigger: "blur" },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { "min-width": "200px", "max-width": "400px" },
                    attrs: {
                      placeholder: "请输入Token",
                      maxlength: "32",
                      minlength: "10",
                      clearable: "",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.serverMsgEditFormData.token,
                      callback: function ($$v) {
                        _vm.$set(_vm.serverMsgEditFormData, "token", $$v)
                      },
                      expression: "serverMsgEditFormData.token",
                    },
                  }),
                  _c("div", { staticClass: "sub-title" }, [
                    _vm._v("必须为英文或数字，长度为10-32字符。"),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            "margin-left": "20px",
                            background: "#2B60F8",
                            float: "right",
                          },
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.confirmEditServerMsg },
                        },
                        [_vm._v("确定 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-right": "10px",
                          },
                          attrs: { size: "small" },
                        },
                        [_vm._v("取消 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }