<!--新建或编辑klib-->
<!--设置审核字段-->
<template>
  <el-dialog
		v-if="isShowDialog"
		:title="title"
		:visible="isShowDialog"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="closeDialog"
		width="660px"
    class="vertical-middle-dialog edit-klib-dialog"
		center>
    <el-form :inline="false" :model="editDataObj" label-width="82px" class="search-form-wrap">
      <el-form-item label="版本号：">
        <el-input v-model="editDataObj.version" placeholder="输入版本号" />
      </el-form-item>
      <el-form-item label="发布日期：">
        <el-date-picker
          v-model="editDataObj.pubDate"
          type="date"
          range-separator="至"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
          @change="dateChange">
        </el-date-picker>
      </el-form-item>
      <el-form-item
        label="下载链接与口令：">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-input v-model="downloadObj.url" placeholder="填写下载地址" />
          </el-col>
          <el-col :span="12">
            <el-row type="flex" justify="end">
              <el-col :span="14">
                <el-input v-model="downloadObj.passwd" placeholder="填写口令" style="width: 130px;" />
              </el-col>
              <el-col :span="10">
                <el-button type="primary" style="width: 100%;" @click="onAdd">添加</el-button>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item>
        <el-table
          ref="tableRef"
          :data="editDataObj.downloadLinkList"
          :header-cell-style="{textAlign: 'center', color: '#333'}"
          border
          stripe
          highlight-current-row
          height="260"
          class="table-wrap"
          @cell-mouse-enter="onCellMouseEnter"
          @cell-mouse-leave="onCellMouseLeave">
          <el-table-column
            prop="url"
            label="下载地址"
            align="center"
            show-overflow-tooltip
            class-name="link-address">
            <template slot-scope="scope">
              <el-form-item :prop="'downloadLinkList.' + scope.$index + '.url'">
                <el-input
                  v-if="scope.row.isEdit"
                  type="textarea"
                  autosize
                  size="mini"
                  v-model.trim="scope.row.url"
                  placeholder="请输入"
                  clearable>
                  <i
                    class="el-icon-edit el-input__icon"
                    slot="suffix"
                    @click="handleIconClick">
                  </i>
                </el-input>
                  <span v-else>{{ scope.row.url }}</span>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            prop="passwd"
            label="下载口令"
            align="center">
            <template slot-scope="scope">
              <el-form-item :prop="'downloadLinkList.' + scope.$index + '.passwd'">
                <el-input
                  v-if="scope.row.isEdit"
                  size="mini"
                  v-model.trim="scope.row.passwd"
                  placeholder="请输入"
                  clearable />
                  <span v-else>{{ scope.row.passwd }}</span>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            prop="isPrimary"
            label="主地址"
            align="center"
            width="70">
            <template slot-scope="scope">
              <el-form-item :prop="'downloadLinkList.' + scope.$index + '.isPrimary'" label-width="0px">
                <el-radio
                  v-model="scope.row.isPrimary"
                  :label="1"
                  @change="(val) => onChangePrimary(val, scope.row, scope.$index)">
                  <span></span>
                </el-radio>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              width="70"
              type="index"
              fixed="right">
              <template slot="header" slot-scope="scope">
                <span>操作</span>
              </template>
              <template slot-scope="scope">
                <el-button type="text" @click="onDel(scope.$index, scope.row)">删除</el-button>
              </template>
            </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="MD5：">
        <el-input v-model="editDataObj.vcMd5" placeholder="输入MD5" />
      </el-form-item>
      <el-form-item label="备注：">
        <el-input
          type="textarea"
          autosize
          v-model="editDataObj.comments"
          placeholder="输入备注内容" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onSave">保存</el-button>
      <el-button type="primary" @click="onPublish">发布</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import {
    EDIT_DATA_OBJ,
    QUERY_DATA_OBJ,
    DOWNLOAD_LINK_PASSWORD_OBJ
  } from '@/utils/rationalDrugUse/klib'
  import {
    getKlibListApi,
    editKlibApi
  } from '@/api/rationalDrugUse/klib'
  export default {
    name: 'EditKlibDialog',
    props: {
      isShowDialog: {
        type: Boolean,
        required: true
      },
      currentRow: {
        type: Object
      }
    },
    data() {
      return {
        title: '新增Klib',
        editDataObj: this.$deepClone(EDIT_DATA_OBJ),
        downloadObj: this.$deepClone(DOWNLOAD_LINK_PASSWORD_OBJ),
        tableData: []
      }
    },
    watch: {
      
    },
    async created() {
      if (this.currentRow && this.currentRow.hasOwnProperty('pubDate')) {
        this.title = '编辑Klib'
      }
      this.editDataObj = Object.assign(this.editDataObj, this.currentRow)
      if (this.editDataObj.pubDate === '') {
        this.editDataObj.pubDate = this.$moment(new Date()).format('YYYY-MM-DD')
      }
    },
    methods: {
      closeDialog() {
        this.$emit('update:isShowDialog', false)
      },
      dateChange() {

      },
      onAdd() {
        let obj = this.$deepClone(this.downloadObj)
        if (this.editDataObj.downloadLinkList.length === 0) {
          obj.isPrimary = 1 // 默认第一个为主地址
        }
        this.editDataObj.downloadLinkList.push(obj)
        console.log(this.editDataObj.downloadLinkList)
      },
      // 删除
      onDel(index, row) {
        this.editDataObj.downloadLinkList.splice(index, 1)
      },
      onChangePrimary(val, row, index) {
        if (val === 1) {
          this.editDataObj.downloadLinkList.forEach(item => item.isPrimary = 0)
          this.editDataObj.downloadLinkList[index].isPrimary = 1
        }
      },
      async onPublish() {
        this.editDataObj.pubStatus = 1 // 发布
        let res = await editKlibApi(this.editDataObj)
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '发布成功'
          })
          this.$emit('publish', { status: true })
          this.closeDialog()
        }
      },
      // 保存
      async onSave() {
        this.editDataObj.pubStatus = 0 // 待发布
        let res = await editKlibApi(this.editDataObj)
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '保成功'
          })
          this.$emit('save', { status: true })
          this.closeDialog()
        }
      },
      handleIconClick() {

      },
      onCellMouseEnter(row, column, cell, event) {
        row.isEdit = true
      },
      onCellMouseLeave(row, column, cell, event) {
        row.isEdit = false
      },
    }
  }
</script>
<style lang="scss" scoped>
  .edit-klib-dialog {
    ::v-deep.el-dialog__body {
      padding: 15px 40px;
    }
  }
  ::v-deep.el-form-item {
    position: relative;
    margin-bottom: 10px !important;
    .el-form-item__label {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      line-height: 18px;
      font-weight: normal;
      color: #333;
    }
    .link-address .el-form-item__content {
      span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        display: inline-block;
      }
    }
    .el-date-editor.el-date-editor{
      width: 100% !important;
    }
  }
</style>