<template>
  <div class="associateTable">
    <div class="header">
      <div class="title">
        <div class="txt">关联列表</div>
				<div style="margin-left: 20px;color: #6f78f5" class="txt">{{associateTitle}}</div>
      </div>
    </div>
		<div class="search">
			<div class="leftSearch">
				<el-input @keyup.enter.native="getRefTableList" v-model="searchName" style="width: 200px;" placeholder="请输入名称"></el-input>
				<el-button @click="getRefTableList" style="margin-left: 10px;" type="primary">查询</el-button>
			</div>
			<div class="rightSearch">
				<el-button @click="newAddRefTable" icon="el-icon-plus" type="primary">新增</el-button>
			</div>
		</div>
    <div class="tableList" ref="tableList">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
				fit
        stripe
        ref="associateTable"
        :height="tableHeight"
        style="width: 100%">
        <el-table-column
          prop="refTypeName"
          width="80"
          label="标识类型">
        </el-table-column>
        <el-table-column
          prop="refId"
          width="80"
          label="标识ID">
        </el-table-column>
        <el-table-column
          prop="refName"
          label="名称">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="更新时间">
        </el-table-column>
        <el-table-column label="操作" width="80" fixed="right" align="center">
          <template slot-scope="scope">
						<div class="operateItem">
							<div @click="del(scope.row)" class="delete">删除</div>
						</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <refAdd :associateTitle="associateTitle" :selectedID="selectedID" :refaddVisible="refaddVisible" :DDDid="DDDid" @confirmrefAddForm="confirmrefAddForm" @closerefAddForm="refaddVisible=false"></refAdd>
  </div>
</template>
<script>
import {getRefDataList, delRefData} from '../../../api/dkm/DDDApi'
import refAdd from './refAdd.vue'
import AcrossUtil from '@/utils/acrossUtil'
export default {
  components: {
    refAdd
  },
  data() {
    return {
      tableHeight: window.innerHeight - 250,
			searchName: '',
			loading: false,
			associateTitle: '',
			DDDid: '',
      tableData: [],
      refaddVisible: false,
      selectedID: []
    }
  },
  created() {
  },
  activated() {
    //激活时重新设置表格高度
    this.setTableHeight()
    this.$nextTick(() => {
      this.$refs.associateTable.doLayout()
    })

  },
  destroyed() {
    window.onresize = null;
  },
  mounted() {
    var _this=this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      AcrossUtil.$emit('resetTableHeight');
    }

    AcrossUtil.$on('resetTableHeight', function (param) {
      setTimeout(function () {
        _this.setTableHeight()
      }, 300);
    })
		this.$eventBus.$on('displayAssociateTable', (data1, data2) => {
			this.DDDid = data1
			this.associateTitle = data2
      this.getRefTableList()
		})
  },
  methods: {
    /*设置表格的高度*/
    setTableHeight(){
      this.tableHeight = window.innerHeight - 250

    },
    del(row) {
      this.$confirm(`是否删除名称为${row.refName}的关联数据?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then( async () => {
				const params = {
					id:parseInt(row.id)
				}
				let res = await delRefData(params)
				if(res.code == 200) {
					this.$message({
						type: 'success',
						message: '删除成功!'
					})
          this.$eventBus.$emit('refreshMainTable', this.DDDid)
					this.getRefTableList()
				}
			}).catch(() => {
			});
    },
    confirmrefAddForm() {
      this.refaddVisible = false
      this.$eventBus.$emit('refreshMainTable', this.DDDid)
      this.getRefTableList()
    },
    newAddRefTable() {
      this.refaddVisible = true
    },
    async getRefTableList() {
      const params = {
        dddId: this.DDDid,
        searchStr: this.searchName
      }
      this.loading = true
      let res = await getRefDataList(params)
      this.loading = false
      this.selectedID = []
      if(res.code == 200) {
        this.tableData = res.data
        this.tableData.forEach(element => {
          this.selectedID.push(element.refId)
        })
      }
    }
  },
}
</script>
<style lang="scss" scoped>

/* to fix 竖向滚动条被fixed列覆盖bug */
::v-deep .el-table .el-table__fixed-right {
  margin-right: 12px !important; // 12px为滚动条宽度
}

.associateTable {
  flex: 2;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  .pagination {
    //margin-top: 20px;
    border-top: unset;
    flex: 0 0 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #EBEEF5;
    border-radius: 0px 0px 8px 8px;
  }
  .tableList {
    flex: 1;
    overflow-y: hidden;
    .operateItem {
      display: flex;
      justify-content: center;
      .edit {
				cursor: pointer;
				width: 48px;
				font-size: 14px;
				font-weight: 400;
				color: #444444;
				line-height: 21px;
			}
			.delete {
				cursor: pointer;
				width: 48px;
				font-size: 14px;
				font-weight: 400;
				color: #F58289;
				line-height: 21px;
			}
			div +div {
				margin-left: 10px;
			}
    }
  }
	.search {
		margin: 10px 0;
		flex: 0 0 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		::v-deep .el-input {
			max-width: 300px;
		}
		.leftSearch {
			display: flex;
			align-items: center;
		}
		.rightSearch {
			display: flex;
			align-items: center;
		}
	}
  .header {
    flex: 0 0 32px;
    display: flex;
    justify-content: space-between;
    .title {
      display: flex;
      align-items: center;
      .txt {
        font-size: 20px;
        font-weight: 800;
        color: #333333;
        line-height: 30px;
      }
    }
  }
}
</style>
