<!--平台治理报告-->
<template>
  <el-container class="layout-container platform-report">
    <el-header class="platform-report-header">
      <div class="title-header-container" id="1" @click="onSearchTable">
        <span class="title-header"></span>
        <h4 class="title-text">审核报告</h4>
      </div>
      <el-form :model="queryObj" :inline="true">
        <el-form-item>
          <el-autocomplete
            v-model.trim="queryObj.searchValue"
            :fetch-suggestions="onOrgSearch"
            placeholder="输入机构名称"
            clearable
            size="medium"
            value-key="name"
            style="width: 200px;height: 36px!important;line-height: 36px;"/>
        </el-form-item>
        <el-form-item label="申请时间">
          <el-date-picker
            v-model="queryObj.timeList"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="queryObj.status" placeholder="请选择" style="width: 100px;">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" icon="el-icon-search" @click="onSearchTable">查询</el-button>
        </el-form-item>
      </el-form>
    </el-header>
    <el-main class="platform-report-main">
      <el-table
        :data="tableData"
        :header-cell-style="{textAlign:'center'}"
        style="width: 100%;"
        stripe
        border
        class="platform-report-table">
      <el-table-column
        align="center"
        width="100"
        fixed>
        <template slot="header" slot-scope="scope">
          <span>申请编号</span>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.applyId }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center">
        <template slot="header" slot-scope="scope">
          <span>机构名称</span>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.orgName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center">
        <template slot="header" slot-scope="scope">
          <span>申请时间</span>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.startTime }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center">
        <template slot="header" slot-scope="scope">
          <span>最后操作时间</span>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.endTime }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="statusStr">
        <template slot="header" slot-scope="scope">
          <span>状态</span>
        </template>
        <template slot-scope="scope">
          <span :class="statusText(scope)">{{ scope.row.statusStr }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="100"
        type="index"
        fixed="right">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status === '1'" type="text" @click="onCheckReport(scope)">审核报告</el-button>
          <el-button v-if="scope.row.status === '2'" type="text" @click="onViewReport(scope)">查看报告</el-button>
        </template>
      </el-table-column>
    </el-table>
    </el-main>
    <el-footer>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryObj.pageNum"
        :limit.sync="queryObj.pageSize"
        @pagination="getTableHandle"/>
    </el-footer>
  </el-container>
</template>
<script>
import { QUERY_OBJ, STATUS_OPTIONS } from '@/utils/dm/platformGovernanceReport/data'
import { getRecordListDataApi, getOrgDataApi } from '@/api/dm/platformGovernanceReport'
export default {
  computed: {
    statusText() {
      return function (scope) {
        if (scope.row.status === '1') {
          // 报告生成中（待审核）
          return 'to-check-status-text'
        } else if (scope.row.status === '2') {
          return 'published-status-text'
        }
      }
    }
  },
  data() {
    return {
      total: 0,
      queryObj: this.$deepClone(QUERY_OBJ),
      tableData: [],
      statusOptions: this.$deepClone(STATUS_OPTIONS)
    }
  },
  async created() {
    await this.fetchTableData()
  },
  async mounted() {
    this.$nextTick(() => {
      console.log(document.getElementById('1'))
    })
  },
  methods: {
    async fetchTableData() {
      if (this.queryObj.timeList && this.queryObj.timeList.length > 0) {
        this.queryObj.startTime = this.queryObj.timeList[0]
        this.queryObj.endTime = this.queryObj.timeList[1]
      } else {
        this.queryObj.startTime = ''
        this.queryObj.endTime = ''
      }
      let res = await getRecordListDataApi(this.queryObj)
      // const obj = {
      //   a: 1,
      //   b: [
      //     {
      //       name: '1',
      //       age: 2
      //     },
      //     {
      //       name: '2',
      //       age: 3
      //     }
      //   ]
      // }
      // let res = await getRecordListDataApi(obj)
      if (res.code === 200) {
        this.tableData = res.data.rows || []
        this.total = res.data.total
      }
    },
    // 
    // 分页查询
    async getTableHandle() {
      await this.fetchTableData()
    },
    // 审核报告
    onCheckReport(scope) {
      this.$router.push({
        path: '/dm/checkReport',
        query: {
          applyId: scope.row.applyId
        }
      })
    },
    // 查看治理报告
    onViewReport(scope) {
      this.$router.push({
        path: '/dm/viewReport',
        query: {
          applyId: scope.row.applyId
        }
      })
    },
    // 查询按钮
    async onSearchTable() {
      $('#1').removeAttr('onclick')
      await this.fetchTableData()
    },
    // 机构查询
    async onOrgSearch(queryString, cb) {
      if (queryString && queryString.trim() !== '') {
        let res = await getOrgDataApi({ searchValue: queryString })
        if (res.code === 200) {
          cb(res.data)
        }
      } else {
        cb([])
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.platform-report {
  .platform-report-header {
    display: flex;
    flex-wrap: wrap;
    height: auto !important;
    min-height: 55px;
    justify-content: space-between;
    align-items: center;
    .title-header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      .title-header {
        width: 4px;
        height: 18px;
        background: #0073E9;
        border-radius: 2px;
      }
      .title-text {
        display: inline-block;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
        margin-left: 5px;
        vertical-align: top;
      }
    }
    .el-form {
      .el-form-item {
        margin-bottom: 0 !important;
        ::v-deep.el-form-item__label {
          font-size: 14px;
          /* color: #333;
          font-weight: 500; */
        }
      }
    }
  }
  .platform-report-main {
    padding: 0 20px !important;
    overflow: auto;
    .el-table {
      overflow: auto;
      height: 100%;
      .to-check-status-text{
        display: inline-block;
        padding: 5px !important;
        color: #E4860A;
        background-color: #FFEBD6;
        font-size: 14px;
        text-align: center;
      }
      .published-status-text {
        display: inline-block;
        padding: 5px !important;
        color: #2B60F8;
        background-color: #D7E3FA;
        font-size: 14px;
        text-align: center;
      }
    }
  }
}
</style>
