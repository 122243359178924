<template>
  <div class="app-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基础模块" name="pdf_base_admin"></el-tab-pane>
      <el-tab-pane label="药品知识库" name="pdf_sys_dkm"></el-tab-pane>
      <el-tab-pane label="数据治理" name="pdf_sys_dm"></el-tab-pane>
      <el-tab-pane label="概念域" name="pdf_sys_term"></el-tab-pane>
      <el-tab-pane label="系统工具" name="pdf_sys_tools"></el-tab-pane>
    </el-tabs>

    <el-table
      :data="nowActiveTabData"
      border
      stripe
      :header-cell-style="{'text-align':'center'}"
      :cell-style="{'text-align':'center'}"
      style="width: 100%">
      <el-table-column prop="apiName" label="api名称" min-width="100"></el-table-column>
      <el-table-column prop="apiDesc" label="描述" min-width="150"></el-table-column>
      <el-table-column prop="apiUrl" label="url" min-width="100"></el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="handleClickExecute(scope.row)" icon="el-icon-video-play" type="text" size="small">执行
          </el-button>
        </template>
      </el-table-column>
    </el-table>


    <el-dialog :title="selectRow.apiName||'执行'" width="70%" append-to-body :visible.sync="executeDialogVisible"
               :close-on-click-modal="false"
    >
      <div v-loading="loading">
        <h3>{{ selectRow.apiDesc }}</h3>
        <h3>{{ selectRow.apiUrl }}</h3>

        <el-divider content-position="left">参数</el-divider>
        <p v-if="!selectRow.params && selectRow.contentType!='application/json;charset=utf-8'">无</p>
        <el-form ref="apiForm" status-icon :model="apiForm" label-width="150px">
          <el-form-item v-for="param in selectRow.params"
                        v-if="selectRow.contentType!='application/json;charset=utf-8'"
                        :label="param.paramName" :prop="param.paramName"
                        :rules="[{ required: param.required, message: '请输入', trigger: 'blur'}]"
          >
            <el-input placeholder="" maxlength="100" clearable show-word-limit v-model="apiForm[param.paramName]"
                      style="min-width: 250px;"
            />
          </el-form-item>
          <el-form-item v-if="selectRow.contentType=='application/json;charset=utf-8'"
                        label="请求参数" :prop="apiForm.requestJson"
          >
            <el-input type="textarea" :rows="3" placeholder="请求json参数"
                      v-model="apiForm.requestJson"
                      show-word-limit
                      style="min-width: 250px;"
            />
          </el-form-item>
        </el-form>


        <el-divider content-position="left">执行结果</el-divider>
        <div style="min-width: 250px;height: 100px;padding:10px;overflow: auto;border: 1px solid #dddddd">
          <p>{{ executeResult }}</p>
        </div>


      </div>
      <span slot="footer" class="dialog-footer" style="margin-right:20px;">
         <el-button @click="executeApi()" size="small" icon="el-icon-video-play" type="primary">执行</el-button>
      </span>

    </el-dialog>
  </div>


</template>

<script>
import qs from 'qs'
import request from '@/utils/request'
import {isEmpty} from "../../../utils/common";

export default {
  name: "ExecuteApi",
  data() {
    return {
      loading: false,
      activeName: 'pdf_sys_dkm',
      executeDialogVisible: false,
      selectRow: {},
      apiForm: {},
      executeResult: "",
      apiTableData:[],
      apiTableData2: [
        {
          "source": "pdf_base_admin",
          "apiName": "用户行为记录日志上报",
          "apiDesc": "",
          "apiUrl": "/system/actionRecord/report",
          "type": "post",
          "contentType": "application/json;charset=utf-8"
        },
        {
          "source": "pdf_sys_dkm",
          "apiName": "刷新药品分类目录数据缓存",
          "apiDesc": "刷新药品分类目录数据缓存",
          "apiUrl": "/dkm/category/dir/refreshCategoryDirCache",
          "type": "get",
          "contentType": "application/x-www-form-urlencoded"
        },
        {
          "source": "pdf_sys_term",
          "apiName": "根据domainCode获取概念域字典",
          "apiDesc": "概念域字典获取",
          "apiUrl": "/term/concept/getConceptsByDomainCode",
          "type": "get",
          "params": [
            {
              "paramName": "domainCode",
              "required": true
            },
            {
              "paramName": "includeSon",
              "required": false
            }
          ]
        },
        {
          "source": "pdf_sys_dm",
          "apiName": "清除后台知识库查询的缓存",
          "apiDesc": "清除后台知识库查询的缓存",
          "apiUrl": "/dm/backdoor/clearDkbCache",
          "type": "get",
          "params": [
            {
              "paramName": "version",
              "required": false
            }
          ]
        },
      ],
    };
  },
  computed: {
    nowActiveTabData() {
      return this.apiTableData.filter((row) => row.source == this.activeName)
    }
  },
  created() {
    this.getConfigKey("executeApiConfigJson").then(res=>{
      try{
        var jsonConfig = eval('(' + res.msg + ')');
        if(!this.isArray(jsonConfig)){
          this.msgError("json配置有误:"+jsonConfig)
        }else{
          this.apiTableData=jsonConfig
        }
      }catch(err){
        this.msgError("json配置解析失败:"+err.message)
      }

      //console.log("JSON.parse(res.msg)",JSON.parse(res.msg))
      //this.apiTableData=JSON.parse(res.msg)
    })

    this.$notify({
      title: '提示',
      message: '配置接口可以在【系统管理】--> 【参数设置】中，修改键名为【executeApiConfigJson】的配置项实现',
      duration: 0
    });

  },
  activated() {

  },
  methods: {
    isArray(obj) {
      return obj && 'object' === typeof obj && Array === obj.constructor;
    },
    handleClick(tab, event) {
    },
    //点击执行按钮
    handleClickExecute(row) {
      this.selectRow = row
      this.executeDialogVisible = true
      this.apiForm = {}
      this.executeResult=""
      //设置默认值
      if(row.params){
        row.params.forEach(paramItem=>{
          if(!isEmpty(paramItem.defaultVal)){
            this.$set(this.apiForm, paramItem.paramName, paramItem.defaultVal)
          }
        })
      }

    },
    //执行api
    executeApi() {
      this.$refs['apiForm'].validate(valid => {
        if (valid) {
          this.doExecuteApi()
        } else {
          this.msgError('请规范填写')
        }
      })

    },
    doExecuteApi() {
      this.loading = true
      let form = this.apiForm
      let row = this.selectRow
      let config = {
        url: row.apiUrl,
        method: row.type,
        timeout: 600 * 1000,
      }
      if (row.contentType == 'application/x-www-form-urlencoded') {
        config.headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
        //config.data = qs.stringify(form) || {}
        config.data =form
      } else if (row.contentType == 'application/json;charset=utf-8') {
        config.headers = {
          'Content-Type': 'application/json;charset=utf-8',
        }
        config.data = form.requestJson

      } else {
        config.params = form
      }

      request(config).then(res => {
        this.loading = false
        this.executeResult = JSON.stringify(res)
      }).catch(e=>{
        this.loading = false
        this.executeResult ="执行失败:"+e
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.app-container {
  width: 100%;
  height: 100%;
}
</style>
