var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { ref: "headerQuery" },
        [
          _c(
            "el-row",
            { staticClass: "headerClass" },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "border-left": "5px solid #0073E9",
                    "padding-left": "10px",
                  },
                  attrs: { span: 4 },
                },
                [_vm._v("制剂规格管理")]
              ),
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showSearch,
                          expression: "showSearch",
                        },
                      ],
                      ref: "queryForm",
                      staticClass: "query-form",
                      staticStyle: { float: "right" },
                      attrs: { model: _vm.zJquery, inline: true },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "el-item-dropdown-300",
                          attrs: { label: "", prop: "name" },
                        },
                        [
                          _c(
                            "el-autocomplete",
                            {
                              staticStyle: {
                                width: "250px",
                                height: "36px!important",
                                "line-height": "36px",
                              },
                              attrs: {
                                "popper-append-to-body": false,
                                "fetch-suggestions": _vm.querySearchAsync,
                                placeholder: "输入制剂ID/名称",
                                clearable: "",
                                size: "small",
                                "value-key": "name",
                              },
                              on: {
                                select: _vm.handleQuery,
                                clear: function ($event) {
                                  return _vm.setBlur()
                                },
                              },
                              model: {
                                value: _vm.zJquery.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.zJquery, "name", $$v)
                                },
                                expression: "zJquery.name",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "74px" },
                                  attrs: {
                                    slot: "prepend",
                                    placeholder: "请选择",
                                  },
                                  slot: "prepend",
                                  model: {
                                    value: _vm.zJquery.nameSearchType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.zJquery,
                                        "nameSearchType",
                                        $$v
                                      )
                                    },
                                    expression: "zJquery.nameSearchType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "模糊", value: "1" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "精确", value: "2" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "前缀", value: "3" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "el-item-dropdown-300",
                          staticStyle: { width: "250px" },
                          attrs: { label: "" },
                        },
                        [
                          _c(
                            "el-autocomplete",
                            {
                              staticStyle: {
                                height: "36px!important",
                                "line-height": "36px",
                              },
                              attrs: {
                                "popper-append-to-body": false,
                                "fetch-suggestions": _vm.queryDrugName,
                                placeholder: "药物名称",
                                clearable: "",
                                size: "small",
                                "value-key": "name",
                              },
                              on: {
                                select: _vm.handleQuery,
                                clear: function ($event) {
                                  return _vm.setBlur()
                                },
                              },
                              nativeOn: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.handleQuery($event)
                                },
                              },
                              model: {
                                value: _vm.zJquery.drugSearch,
                                callback: function ($$v) {
                                  _vm.$set(_vm.zJquery, "drugSearch", $$v)
                                },
                                expression: "zJquery.drugSearch",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "74px" },
                                  attrs: {
                                    slot: "prepend",
                                    placeholder: "请选择",
                                  },
                                  slot: "prepend",
                                  model: {
                                    value: _vm.zJquery.drugSearchType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.zJquery,
                                        "drugSearchType",
                                        $$v
                                      )
                                    },
                                    expression: "zJquery.drugSearchType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "模糊", value: "1" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "精确", value: "2" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "前缀", value: "3" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "status" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "statusClass",
                              staticStyle: { width: "74px" },
                              attrs: {
                                placeholder: "状态",
                                clearable: "",
                                size: "small",
                              },
                              model: {
                                value: _vm.zJquery.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.zJquery, "status", $$v)
                                },
                                expression: "zJquery.status",
                              },
                            },
                            _vm._l(_vm.statusOptions, function (dict) {
                              return _c("el-option", {
                                key: dict.conceptId,
                                attrs: {
                                  label: dict.conceptVal,
                                  value: dict.conceptId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-search",
                                size: "small",
                              },
                              on: { click: _vm.handleQuery },
                            },
                            [_vm._v("检索")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "addClass",
                              attrs: {
                                icon: "el-icon-circle-plus-outline",
                                size: "small",
                              },
                              on: { click: _vm.handleAdd },
                            },
                            [_vm._v("新增制剂")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-circle-plus-outline",
                                size: "small",
                              },
                              on: { click: _vm.columnSettings },
                            },
                            [_vm._v("设置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { ref: "tableContainer", attrs: { id: "prepTableList" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "refundTable",
              attrs: {
                border: "",
                stripe: "",
                data: _vm.preparationList,
                height: _vm.tableHeight,
                "row-class-name": _vm.psRowClass,
              },
              on: {
                "sort-change": _vm.tableSortChange,
                "row-contextmenu": _vm.rowContextmenu,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand", width: "45", fixed: "left" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              border: "",
                              stripes: "",
                              "header-cell-style": { "text-align": "center" },
                              "cell-style": { "text-align": "center" },
                              data: props.row.specList,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { label: "规格ID", width: "120" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-right": "5px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.specId) +
                                                "-" +
                                                _vm._s(scope.row.id)
                                            ),
                                          ]
                                        ),
                                        _vm._l(
                                          scope.row.drugCategoryList,
                                          function (item) {
                                            return _c(
                                              "span",
                                              { key: item.id },
                                              [
                                                item.icon
                                                  ? _c("svg-icon", {
                                                      attrs: {
                                                        "icon-class": item.icon,
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "showSpec",
                                label: "规格",
                                width: "200",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "specDesc",
                                label: "规格描述",
                                "show-overflow-tooltip": "",
                                width: "300",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "isDefault",
                                label: "首选项",
                                width: "180",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.isDefault === true
                                          ? _c("span", [_vm._v("是")])
                                          : _c("span"),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: { label: "操作", width: "200" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addDrugProd(
                                                  scope.$index,
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("新增药品")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.queryRelatedDrugProd(
                                                  scope.row,
                                                  2
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("查询相关药品")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addIngredient(
                                                  scope.$index,
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("成分")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("template", { slot: "empty" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/no-booking.svg"),
                                  alt: "",
                                },
                              }),
                              _c("p", [_vm._v("暂无数据")]),
                            ]),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._l(_vm.columnOptions, function (data) {
                return _c("el-table-column", {
                  key: data.id,
                  attrs: {
                    prop: data.prop,
                    label: data.name,
                    fixed: data.fixed,
                    resizable: false,
                    sortable: data.sortable,
                    "min-width": data.width,
                    align: data.align,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            data.prop == "drugName"
                              ? _c("div", [
                                  scope.row.drugStatus === "1"
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "text-decoration":
                                              "line-through #ff0000",
                                            "margin-right": "5px",
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row[data.prop]))]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-right": "5px",
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row[data.prop]))]
                                      ),
                                ])
                              : data.prop == "preparationAlias"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "5px" },
                                      },
                                      [_vm._v(_vm._s(scope.row[data.prop]))]
                                    ),
                                    _vm._l(
                                      scope.row.drugCategoryList,
                                      function (item) {
                                        return _c(
                                          "span",
                                          { key: item.id },
                                          [
                                            item.icon
                                              ? _c("svg-icon", {
                                                  attrs: {
                                                    "icon-class": item.icon,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _c("div", [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row[data.prop])),
                                  ]),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                key: "status",
                attrs: {
                  label: "状态",
                  align: "center",
                  width: "100",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          staticClass: "switch",
                          attrs: {
                            "active-color": "#5DB730",
                            "active-text": "启用",
                            "inactive-text": "停用",
                            "inactive-color": "#B5CCF5",
                            "active-value": "0",
                            "inactive-value": "1",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleStatusChange(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.status,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "status", $$v)
                            },
                            expression: "scope.row.status",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm.isResult === false
                ? _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-booking.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ])
                : _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-left-data.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("未查询到结果，请修改查询条件重试！")]),
                  ]),
            ],
            2
          ),
        ],
        1
      ),
      _vm.tableContextMenuVisible
        ? _c("table-context-menu", {
            ref: "contextbutton",
            attrs: { action: _vm.tableAction },
            on: { foo: _vm.foo, handleTableAction: _vm.handleTableAction },
          })
        : _vm._e(),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.zJquery.pageNum,
          limit: _vm.zJquery.pageSize,
          "page-sizes": [10, 20, 50, 100],
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.zJquery, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.zJquery, "pageSize", $event)
          },
          pagination: _vm.selectPreparationList,
        },
      }),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            visible: _vm.channelDialogVisible,
            width: "600",
            title: "用药途径管理[" + _vm.psTitle + "]",
            center: "",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "before-close": _vm.channelHandleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.channelDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ppRouteBeanList",
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.ppRouteBeanList },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "用药途径列表",
                    rules: [
                      {
                        required: true,
                        message: "请输入查询内容",
                        trigger: "blur",
                      },
                      {
                        min: 1,
                        max: 30,
                        message: "查询内容长度在 1 到 30 个字符",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        filterable: "",
                        remote: "",
                        "popper-class": "select_component",
                        "remote-method": _vm.remoteMethod_Route,
                        "reserve-keyword": "",
                        clearable: "",
                        placeholder: "输入用药途径名称",
                      },
                      on: {
                        change: function (val) {
                          return _vm.changeRemote_Route(val)
                        },
                      },
                      model: {
                        value: _vm.ppRouteBeanList.routeName,
                        callback: function ($$v) {
                          _vm.$set(_vm.ppRouteBeanList, "routeName", $$v)
                        },
                        expression: "ppRouteBeanList.routeName",
                      },
                    },
                    [
                      _c("div", { staticClass: "clearfix table_Header" }, [
                        _c(
                          "span",
                          { staticStyle: { width: "200px", flex: "2" } },
                          [_vm._v("用药途径")]
                        ),
                        _c(
                          "span",
                          { staticStyle: { width: "100px", flex: "1" } },
                          [_vm._v("操作")]
                        ),
                      ]),
                      _vm._l(_vm.routeTableData, function (item, index) {
                        return _c(
                          "el-option",
                          {
                            key: item.routeId,
                            attrs: {
                              label: item.routeName,
                              value: item.routeId,
                            },
                          },
                          [
                            _c("div", { staticClass: "clearfix spanWrap" }, [
                              _c(
                                "p",
                                {
                                  staticStyle: { width: "200px", flex: "2" },
                                  attrs: { title: item.routeName },
                                },
                                [_vm._v(_vm._s(item.routeName))]
                              ),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    width: "100px",
                                    flex: "1",
                                    color: "#2B60F8",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addRoute(index, item)
                                    },
                                  },
                                },
                                [_vm._v("添加")]
                              ),
                            ]),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "routeName",
              attrs: {
                data: _vm.resultNameOptions02,
                border: "",
                stripe: "",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
                height: 400,
              },
            },
            [
              _c("el-table-column", {
                attrs: { property: "routeName", label: "用药途径" },
              }),
              _c("el-table-column", {
                attrs: { label: "首选项" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择用药途径" },
                            model: {
                              value: scope.row.isDefault,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "isDefault", $$v)
                              },
                              expression: "scope.row.isDefault",
                            },
                          },
                          _vm._l(_vm.firstChoiceOptions, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "#FF3333" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteRouteRow(
                                  scope.$index,
                                  _vm.resultNameOptions02
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                        scope.row.isDefault === true
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { color: "#1e1e1e" },
                                attrs: {
                                  type: "text",
                                  size: "small",
                                  disabled: "",
                                },
                              },
                              [_vm._v("默认推荐值")]
                            )
                          : scope.row.isDefault === false
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setRouteRow(
                                      scope.$index,
                                      _vm.resultNameOptions02
                                    )
                                  },
                                },
                              },
                              [_vm._v("设为推荐值")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("template", { slot: "empty" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/no-booking.svg"),
                    alt: "",
                  },
                }),
                _c("p", [_vm._v("暂无数据")]),
              ]),
            ],
            2
          ),
          _c(
            "el-footer",
            { staticStyle: { "text-align": "right", "margin-top": "20px" } },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.returnBackList } },
                [_vm._v("返回")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.saveRoute },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "用药途径检索",
            visible: _vm.ppRouteBeanList.drugGroupTableVisible,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(
                _vm.ppRouteBeanList,
                "drugGroupTableVisible",
                $event
              )
            },
          },
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                border: "",
                stripe: "",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
                data: _vm.ppRouteBeanList.drugGroupTableData,
                height: 300,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  property: "routeName",
                  label: "用药途径",
                  width: "200",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-success",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.chooseRouteBean(
                                  scope.$index,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("选择 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("template", { slot: "empty" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/no-booking.svg"),
                    alt: "",
                  },
                }),
                _c("p", [_vm._v("暂无数据")]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            visible: _vm.siteDialogVisible,
            width: "600",
            title: "给药部位管理[" + _vm.psTitle + "]",
            center: "",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "before-close": _vm.siteHandleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.siteDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ppSiteBeanListForm",
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.ppSiteBeanList },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "给药部位列表",
                    rules: [
                      {
                        required: true,
                        message: "请输入查询内容",
                        trigger: "blur",
                      },
                      {
                        min: 1,
                        max: 30,
                        message: "查询内容长度在 1 到 30 个字符",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        filterable: "",
                        remote: "",
                        "popper-class": "select_component",
                        "remote-method": _vm.remoteMethod_site,
                        "reserve-keyword": "",
                        clearable: "",
                        placeholder: "输入给药部位名称",
                      },
                      on: {
                        change: function (val) {
                          return _vm.changeRemote_site(val)
                        },
                      },
                      model: {
                        value: _vm.ppSiteBeanList.siteName,
                        callback: function ($$v) {
                          _vm.$set(_vm.ppSiteBeanList, "siteName", $$v)
                        },
                        expression: "ppSiteBeanList.siteName",
                      },
                    },
                    [
                      _c("div", { staticClass: "clearfix table_Header" }, [
                        _c(
                          "span",
                          { staticStyle: { width: "200px", flex: "2" } },
                          [_vm._v("用药部位")]
                        ),
                        _c(
                          "span",
                          { staticStyle: { width: "100px", flex: "1" } },
                          [_vm._v("操作")]
                        ),
                      ]),
                      _vm._l(_vm.siteTableData, function (item, index) {
                        return _c(
                          "el-option",
                          {
                            key: item.siteId,
                            attrs: { label: item.siteName, value: item.siteId },
                          },
                          [
                            _c("div", { staticClass: "clearfix spanWrap" }, [
                              _c(
                                "p",
                                {
                                  staticStyle: { width: "200px", flex: "2" },
                                  attrs: { title: item.siteName },
                                },
                                [_vm._v(_vm._s(item.siteName))]
                              ),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    width: "100px",
                                    flex: "1",
                                    color: "#2B60F8",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addSite(index, item)
                                    },
                                  },
                                },
                                [_vm._v("添加")]
                              ),
                            ]),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "siteName",
              attrs: {
                data: _vm.resultNameOptions,
                border: "",
                stripe: "",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
                height: 400,
              },
            },
            [
              _c("el-table-column", {
                attrs: { property: "siteName", label: "用药部位" },
              }),
              _c("el-table-column", {
                attrs: { label: "首选项" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择用药部位" },
                            model: {
                              value: scope.row.isDefault,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "isDefault", $$v)
                              },
                              expression: "scope.row.isDefault",
                            },
                          },
                          _vm._l(_vm.firstChoiceOptions, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "#FF3333" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteSiteRow(
                                  scope.$index,
                                  _vm.resultNameOptions
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                        scope.row.isDefault === true
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { color: "#1e1e1e" },
                                attrs: {
                                  type: "text",
                                  size: "small",
                                  disabled: "",
                                },
                              },
                              [_vm._v("默认推荐值")]
                            )
                          : scope.row.isDefault === false
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setSiteRow(
                                      scope.$index,
                                      _vm.resultNameOptions
                                    )
                                  },
                                },
                              },
                              [_vm._v("设为推荐值")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("template", { slot: "empty" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/no-booking.svg"),
                    alt: "",
                  },
                }),
                _c("p", [_vm._v("暂无数据")]),
              ]),
            ],
            2
          ),
          _c(
            "el-footer",
            { staticStyle: { "text-align": "right", "margin-top": "20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.returnBackSiteList },
                },
                [_vm._v("返回")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.saveSite },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "给药部位检索",
            visible: _vm.ppSiteBeanList.drugGroupTableVisible,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(
                _vm.ppSiteBeanList,
                "drugGroupTableVisible",
                $event
              )
            },
          },
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                border: "",
                stripe: "",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
                data: _vm.ppSiteBeanList.drugGroupTableData,
                height: 300,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  property: "siteName",
                  label: "用药部位",
                  width: "200",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-success",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.chooseSiteBean(
                                  scope.$index,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("选择 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("template", { slot: "empty" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/no-booking.svg"),
                    alt: "",
                  },
                }),
                _c("p", [_vm._v("暂无数据")]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            visible: _vm.aliasDialogVisible,
            width: "850px",
            title: "别名管理[" + _vm.psTitle + "]",
            center: "",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "before-close": _vm.aliasHandleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.aliasDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "aliasManagementForm",
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.aliasManagement },
            },
            [
              _vm._l(
                _vm.aliasManagement.preparationAliasList,
                function (item, index) {
                  return _c(
                    "el-form-item",
                    {
                      key: "preparation-name-" + index,
                      attrs: {
                        label: index == 0 ? "制剂名称:" : "",
                        rules: [
                          {
                            required: true,
                            message: "请输入制剂名称",
                            trigger: "blur",
                          },
                          {
                            validator: _vm.validatePreparationAlias,
                            trigger: "blur",
                          },
                        ],
                        prop:
                          "preparationAliasList." + index + ".preparationAlias",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "450px", float: "left" },
                        attrs: {
                          placeholder: "请输入制剂名称",
                          maxlength: "300",
                          clearable: "",
                          "show-word-limit": "",
                        },
                        model: {
                          value: item.preparationAlias,
                          callback: function ($$v) {
                            _vm.$set(item, "preparationAlias", $$v)
                          },
                          expression: "item.preparationAlias",
                        },
                      }),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "80px", float: "left" },
                          attrs: { placeholder: "请选择语言" },
                          model: {
                            value: item.languageId,
                            callback: function ($$v) {
                              _vm.$set(item, "languageId", $$v)
                            },
                            expression: "item.languageId",
                          },
                        },
                        _vm._l(_vm.languageConcept, function (item) {
                          return _c("el-option", {
                            key: item.conceptId,
                            attrs: {
                              label: item.conceptVal,
                              value: item.conceptId,
                            },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            float: "left",
                            "margin-bottom": "0px",
                          },
                          attrs: {
                            rules: [
                              {
                                required: true,
                                message: "请选择名称类型",
                                trigger: "blur",
                              },
                              {
                                validator: _vm.validateAliasType,
                                trigger: "blur",
                              },
                            ],
                            prop:
                              "preparationAliasList." + index + ".aliasType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "120px", float: "left" },
                              attrs: { placeholder: "请选择类型" },
                              model: {
                                value: item.aliasType,
                                callback: function ($$v) {
                                  _vm.$set(item, "aliasType", $$v)
                                },
                                expression: "item.aliasType",
                              },
                            },
                            _vm._l(_vm.aliasTypeConcept, function (item) {
                              return _c("el-option", {
                                key: item.conceptId,
                                attrs: {
                                  label: item.conceptVal,
                                  value: item.conceptId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c("i", {
                        staticClass: "el-icon-remove",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.delSubstanceName(item)
                          },
                        },
                      }),
                      _c(
                        "el-radio",
                        {
                          staticStyle: {
                            "margin-right": "10px",
                            padding: "8px 8px 0 5px",
                          },
                          attrs: { label: index, border: "", size: "small" },
                          on: {
                            change: function ($event) {
                              return _vm.primaryChange($event, "name", index)
                            },
                          },
                          model: {
                            value: _vm.aliasManagement.namePrimary,
                            callback: function ($$v) {
                              _vm.$set(_vm.aliasManagement, "namePrimary", $$v)
                            },
                            expression: "aliasManagement.namePrimary",
                          },
                        },
                        [_vm._v(" 首选项 ")]
                      ),
                    ],
                    1
                  )
                }
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        type: "primary",
                        plain: "",
                        icon: "el-icon-circle-plus-outline",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addSubstanceName("中文")
                        },
                      },
                    },
                    [_vm._v("添加中文制剂名称 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        type: "primary",
                        plain: "",
                        icon: "el-icon-circle-plus-outline",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addSubstanceName("英语")
                        },
                      },
                    },
                    [_vm._v("添加英文制剂名称 ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-footer",
            { staticStyle: { "text-align": "right", "margin-top": "20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.returnBackAliasList },
                },
                [_vm._v("返回")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.saveAlias },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            visible: _vm.specDialogVisible,
            width: "1000px",
            title: "制剂规格管理[" + _vm.psTitle + "]",
            center: "",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "before-close": _vm.specHandleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.specDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c("el-col", { staticClass: "title-bar", attrs: { span: 12 } }, [
                _vm._v("制剂规格列表"),
              ]),
              _c(
                "el-col",
                { staticStyle: { "text-align": "right" }, attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        "text-align": "right",
                        background: "#2B60F8",
                      },
                      attrs: { type: "primary" },
                      on: { click: _vm.addGg },
                    },
                    [_vm._v(" 添加规格 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "specTableData",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                stripe: "",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "规格ID",
                  prop: "specId",
                  sortable: "",
                  width: "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticStyle: { "margin-right": "5px" } }, [
                          _vm._v(
                            _vm._s(scope.row.specId) +
                              "-" +
                              _vm._s(scope.row.id)
                          ),
                        ]),
                        _vm._l(scope.row.drugCategoryList, function (item) {
                          return _c(
                            "span",
                            { key: item.id },
                            [
                              item.icon
                                ? _c("svg-icon", {
                                    attrs: { "icon-class": item.icon },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "showSpec", sortable: "", label: "规格" },
              }),
              _c("el-table-column", {
                attrs: { prop: "isDefault", label: "首选项" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isDefault === true
                          ? _c("span", [_vm._v("是")])
                          : _c("span"),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "descList", sortable: "", label: "规格描述" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.descList, function (item) {
                        return _c("div", { key: item.id }, [
                          item.isDefault === true
                            ? _c("div", [
                                _c("span", [_vm._v(_vm._s(item.specDesc))]),
                              ])
                            : _vm._e(),
                        ])
                      })
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                key: "status",
                attrs: { label: "启用", align: "center", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          staticClass: "switch",
                          attrs: {
                            "active-color": "#5DB730",
                            "active-text": "启用",
                            "inactive-text": "停用",
                            "inactive-color": "#B5CCF5",
                            "active-value": "0",
                            "inactive-value": "1",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleSpecStatusChange(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.status,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "status", $$v)
                            },
                            expression: "scope.row.status",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "center",
                  "class-name": "small-padding fixed-width",
                  width: "250",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.updateSpec(scope.row, scope.$index)
                              },
                            },
                          },
                          [_vm._v("修改 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.copydateSpec(scope.row, scope.$index)
                              },
                            },
                          },
                          [_vm._v("复制 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "#FF3333" },
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteSpec(scope.row, scope.$index)
                              },
                            },
                          },
                          [_vm._v("删除 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleSource02(
                                  scope.row,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("来源 ")]
                        ),
                        scope.row.id
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setDrugProd(
                                      scope.row,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [_vm._v("新建药品 ")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.editSpecIngredient(
                                  scope.row,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("成分 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.copySpec(scope.row)
                              },
                            },
                          },
                          [_vm._v("创建同规格 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.drugCategoryEdit.refreshDrugCategoryTree(
                                  scope.row.id,
                                  5
                                )
                              },
                            },
                          },
                          [_vm._v("分类维护 ")]
                        ),
                        scope.row.isDefault === true
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { color: "#1e1e1e" },
                                attrs: {
                                  size: "mini",
                                  type: "text",
                                  disabled: "",
                                },
                              },
                              [_vm._v("默认首选规格 ")]
                            )
                          : scope.row.isDefault === false
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setSpecPrimary(
                                      scope.row,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [_vm._v("设为首选规格 ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("template", { slot: "empty" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/no-booking.svg"),
                    alt: "",
                  },
                }),
                _c("p", [_vm._v("暂无数据")]),
              ]),
            ],
            2
          ),
          _c(
            "el-footer",
            { staticStyle: { "text-align": "right", "margin-top": "20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.returnBackSpecList },
                },
                [_vm._v("返回")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.saveSpec },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "引用来源管理[" + _vm.psTitle + "]",
            visible: _vm.resourceTableVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.resourceTableVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { span: 24 },
                },
                [
                  _vm.isSpecShow === false
                    ? _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-right": "10px",
                          },
                          attrs: {
                            icon: "el-icon-circle-plus-outline",
                            size: "small",
                          },
                          on: { click: _vm.addResource },
                        },
                        [_vm._v("添加 ")]
                      )
                    : _vm.isSpecShow === true
                    ? _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-right": "10px",
                          },
                          attrs: {
                            icon: "el-icon-circle-plus-outline",
                            size: "small",
                          },
                          on: { click: _vm.addResource02 },
                        },
                        [_vm._v("添加 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "resourceTableForm",
              attrs: { "status-icon": "", model: _vm.resourceTableForm },
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    stripe: "",
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    data: _vm.resourceTableForm.list,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { property: "date", label: "引用资源类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "list." +
                                    scope.$index +
                                    "." +
                                    "resourceTypeId",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择药物名称",
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      placeholder: "引用资源类型",
                                      size: "small",
                                    },
                                    model: {
                                      value: scope.row.resourceTypeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "resourceTypeId",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.resourceTypeId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.resourceTypeOption,
                                    function (dict) {
                                      return _c("el-option", {
                                        key: "resourceTypeOption" + dict.id,
                                        attrs: {
                                          label: dict.typeName,
                                          value: dict.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { property: "name", label: "引用资源名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "200px" },
                                attrs: {
                                  filterable: "",
                                  remote: "",
                                  "reserve-keyword": "",
                                  placeholder: "请输入关键词",
                                  "remote-method": function (query) {
                                    _vm.resourceNameQuery(
                                      query,
                                      scope.row.resourceTypeId
                                    )
                                  },
                                  loading: _vm.loading,
                                  disabled: !scope.row.resourceTypeId
                                    ? true
                                    : false,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.resourceNameChange(
                                      $event,
                                      scope.$index
                                    )
                                  },
                                },
                                model: {
                                  value: scope.row.resourceId,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "resourceId", $$v)
                                  },
                                  expression: "scope.row.resourceId",
                                },
                              },
                              [
                                _vm._l(_vm.resourceDataOption, function (item) {
                                  return _c("el-option", {
                                    key: "resourceDataOption" + item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                _vm._l([scope.row], function (item) {
                                  return _c("el-option", {
                                    key: item.resourceId + "original",
                                    attrs: {
                                      label: item.resourceName,
                                      value: item.resourceId,
                                    },
                                  })
                                }),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "center",
                      "class-name": "small-padding fixed-width",
                      width: "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "#FF3333" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleResourceDelete(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-booking.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "margin-top": "10px" }, attrs: { span: 24 } },
                [
                  _vm.isSpecShow === false
                    ? _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-right": "10px",
                          },
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.saveResource },
                        },
                        [_vm._v("保存 ")]
                      )
                    : _vm.isSpecShow === true
                    ? _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-right": "10px",
                          },
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.saveResource02 },
                        },
                        [_vm._v("保存 ")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.resourceTableVisible = false
                        },
                      },
                    },
                    [_vm._v("取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isAddSF
        ? _c("AddSpecification", {
            attrs: {
              visible: _vm.isAddSF,
              specGroupKey: _vm.specGroupKey,
              preparationId: _vm.preparationId,
              specMode: _vm.specMode,
              specData: _vm.specData,
              isEdit: _vm.isEdit,
              specModeChoice: _vm.specModeChoice,
              specTableData: _vm.tableData,
            },
            on: { tableValue: _vm.tableValue, closeAddSF: _vm.closeAddSF },
          })
        : _vm._e(),
      _c("drug-category-edit", { ref: "drugCategoryEdit" }),
      _vm.isEditIngredient
        ? _c("edit-ingredient", {
            attrs: {
              EditDialogVisible: _vm.isEditIngredient,
              singleSpecId: _vm.singleSpecId,
              specId: _vm.specId,
              ingredientData: _vm.ppSpecIngredientList,
              isGetArrShow: _vm.isGetArrShow,
            },
            on: {
              closeEditIngredient: _vm.closeEditIngredient,
              editFinish: _vm.handleIngredientEditFinish,
              getIngredientList: _vm.getIngredientList,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }