/***映射关系维护 */
// 
export const QUERY_OBJ = {
  orgName: '', // 机构名称
  status: '' // 状态
}
export const STATUS_OPTIONS = [
  // 1:正常 2:维护中 3:升级匹配中 
  {
    value: '1',
    label: '正常'
  },
  {
    value: '2',
    label: '维护中'
  },
  {
    value: '3',
    label: '升级匹配中'
  }
]
export const SEARCH_OBJ = {
  orgName: '', // 机构名称
  pageNum: 1,
  pageSize: 10,
  status: '',
  statusList: []
}
