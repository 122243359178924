<!--下载标准数据-->
<template>
  <el-dialog
		v-if="dialogVisible"
    title="下载标准数据"
    :visible.sync="dialogVisible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="download-data-dialog"
    width="330px"
		:append-to-body="true"
    center
    :before-close="handleClose">
    <el-form ref="form" :model="formData" label-position="top" label-width="130px">
		<el-form-item label="请选择下载文件格式：" class="form-item">
			<el-select v-model="formData.downloadType" placeholder="请选择">
				<el-option
					v-for="item in typeOptions"
					:key="item.code"
					:label="item.name"
					:value="item.code" />
			</el-select>
		</el-form-item>
	</el-form>
    <span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="downloadHandle">下载</el-button>
			<el-button @click="dialogVisible = false">取消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
	downloadTypeApi,
	downloadDataApi
} from '@/api/dm/institutionDrugStorage'
import {
  onStartDownload
} from '@/utils/downloadFile'
export default {
  props: {
    version: {
      type: String,
      required: true
    }
  },
  watch: {
    version: async function(newVal, oldVal) {
      if (newVal !== '') {
        this.formData.version = this.version
        let res = await downloadTypeApi()
        if (res && res.code === 200) {
          this.typeOptions = res.data
          this.formData.downloadType = this.typeOptions[0] ? this.typeOptions[0].code : ''
        }
      }
    }
  },
	data() {
		return {
			dialogVisible: false,
			typeOptions: [],
			formData: {
				downloadType: '',
        version: ''
			}
		}
	},
	async created() {
    // this.formData.version = this.version
		// let res = await downloadTypeApi()
		// if (res && res.code === 200) {
		// 	this.typeOptions = res.data
		// 	this.formData.downloadType = this.typeOptions[0] ? this.typeOptions[0].code : ''
		// }
	},
	methods: {
		// 关闭对话框
		handleClose(done) {
			/* this.$confirm('确认关闭？')
				.then(_ => {
					done()
				})
				.catch(_ => {}) */
      done()
		},
    closeDialog() {
      this.dialogVisible = false
    },
		// 下载
		async downloadHandle() {
			let res = await downloadDataApi(this.formData)
      // let res = await downloadDataApi()
      onStartDownload(res, this.closeDialog())
			// let downloadName = `${new Date().getTime()}.xlsx`
			// if (this.formData.downloadType === 2) {
			// 	// json
			// 	downloadName = `${new Date().getTime()}.json`
			// }
			/* if (!res) {
				return
			}
      let downloadName = decodeURI(data.headers['filename'])
      let url = window.URL.createObjectURL(new Blob([res.data]))
			let link = document.createElement('a')
			link.style.display = 'none'
			link.href = url
			link.setAttribute('download', downloadName)

			document.body.appendChild(link)
			link.click()
			this.$message({
				message: '下载成功',
				type: 'success'
			})
			this.closeDialog() */
		}
	}
}
</script>
<style lang="scss" scoped>
.download-data-dialog {
	::v-deep .el-dialog__body {
		padding: 20px 0 !important;
	}
	::v-deep .form-item {
		label {
			/* display: block;
			width: 100% !important;
			text-align: center; */
			font-size: 14px;
			font-weight: 400;
			color: #333;
			margin-left: 50px;
		}
		.el-form-item__content {
			/* margin-left: 0 !important;
			display: block;
			text-align: center; */
			margin-left: 50px;
		}
	}
	::v-deep .el-dialog__footer {
		.el-button {
			padding: 10px 40px !important;
		}
	}
}
</style>
