var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dialogVisible,
            width: "30%",
            center: "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "140px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "ATC代码", prop: "atcCode" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "50", "show-word-limit": "" },
                    model: {
                      value: _vm.ruleForm.atcCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "atcCode", $$v)
                      },
                      expression: "ruleForm.atcCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分类英文名", prop: "enName" } },
                [
                  _c("el-input", {
                    attrs: {
                      oninput: "value=value.replace(/[\\u4e00-\\u9fa5]/g, '')",
                      maxlength: "50",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.ruleForm.enName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "enName", $$v)
                      },
                      expression: "ruleForm.enName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分类中文名", prop: "chName" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "200", "show-word-limit": "" },
                    model: {
                      value: _vm.ruleForm.chName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "chName", $$v)
                      },
                      expression: "ruleForm.chName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { flex: "1" },
                      attrs: { label: "DDD值(WHO-ATC)", prop: "dddWho" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled:
                            Boolean(_vm.ruleForm.dddC) ||
                            Boolean(_vm.ruleForm.dddCUnit),
                          oninput:
                            "value=(value.match(/(^(0|[1-9][0-9]*))+(\\.[0-9]{0,3})?/g) ?? [''])[0]",
                          maxlength: "10",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.ruleForm.dddWho,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "dddWho", $$v)
                          },
                          expression: "ruleForm.dddWho",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "dddWhoUnit",
                      attrs: { label: "", prop: "dddWhoUnit" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100px", "margin-left": "5px" },
                          attrs: {
                            disabled:
                              Boolean(_vm.ruleForm.dddC) ||
                              Boolean(_vm.ruleForm.dddCUnit),
                            clearable: "",
                            placeholder: "",
                          },
                          model: {
                            value: _vm.ruleForm.dddWhoUnit,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "dddWhoUnit", $$v)
                            },
                            expression: "ruleForm.dddWhoUnit",
                          },
                        },
                        _vm._l(_vm.unitList, function (item) {
                          return _c("el-option", {
                            key: item.conceptId,
                            attrs: {
                              label: item.conceptVal,
                              value: item.conceptId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { flex: "1" },
                      attrs: { label: "DDD值(C)", prop: "dddC" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled:
                            Boolean(_vm.ruleForm.dddWho) ||
                            Boolean(_vm.ruleForm.dddWhoUnit),
                          oninput:
                            "value=(value.match(/(^(0|[1-9][0-9]*))+(\\.[0-9]{0,3})?/g) ?? [''])[0]",
                          maxlength: "10",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.ruleForm.dddC,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "dddC", $$v)
                          },
                          expression: "ruleForm.dddC",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "dddWhoUnit",
                      attrs: { label: "", prop: "dddCUnit" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100px", "margin-left": "5px" },
                          attrs: {
                            disabled:
                              Boolean(_vm.ruleForm.dddWho) ||
                              Boolean(_vm.ruleForm.dddWhoUnit),
                            clearable: "",
                            placeholder: "",
                          },
                          model: {
                            value: _vm.ruleForm.dddCUnit,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "dddCUnit", $$v)
                            },
                            expression: "ruleForm.dddCUnit",
                          },
                        },
                        _vm._l(_vm.unitList, function (item) {
                          return _c("el-option", {
                            key: item.conceptId,
                            attrs: {
                              label: item.conceptVal,
                              value: item.conceptId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "给药途径", prop: "route" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm.route,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "route", $$v)
                        },
                        expression: "ruleForm.route",
                      },
                    },
                    _vm._l(_vm.useDrugList, function (item) {
                      return _c("el-option", {
                        key: item.conceptId,
                        attrs: {
                          label: item.conceptVal,
                          value: item.conceptId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "版本号", prop: "version" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm.version,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "version", $$v)
                        },
                        expression: "ruleForm.version",
                      },
                    },
                    _vm._l(_vm.versionList, function (item) {
                      return _c("el-option", {
                        key: item.version,
                        attrs: { label: item.version, value: item.version },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      "show-word-limit": "",
                      maxlength: "200",
                      rows: 3,
                    },
                    model: {
                      value: _vm.ruleForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "remark", $$v)
                      },
                      expression: "ruleForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }