<template>
  <div class="app-container" style="overflow: auto">


    <div ref="headerQuery">
      <el-tabs v-model="queryParams.configType" @tab-click="handleTabClick">
        <el-tab-pane label="用户行为屏蔽管理" name="1"></el-tab-pane>
        <el-tab-pane label="URL映射管理" name="2"></el-tab-pane>
        <el-tab-pane label="行为标签管理" name="3"></el-tab-pane>
      </el-tabs>
      <el-row class="headerClass">
        <el-col :span="20">
          <el-form :model="queryParams" ref="queryForm" :inline="true" style="float: right">


            <el-form-item label="" prop="searchValue">
              <el-input v-model="queryParams.searchValue" maxlength="80" clearable style="width: 300px;"></el-input>
            </el-form-item>


            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">检索</el-button>
              <el-button type="primary" icon="el-icon-circle-plus" size="small"
                         v-if="queryParams.configType=='2'" @click="handleAddUrlMapping">新增映射关系
              </el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>


    <div ref="tableContainer">
      <el-table
        border
        stripe
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        ref="refundTable"
        :data="tableDataList"
        :height="tableHeight"
        v-loading="loading"
        @sort-change="tableSortChange"
      >
        <el-table-column prop="id" label="id" width="100"></el-table-column>
        <el-table-column prop="configKey" label="actionKey" min-width="200"
                         v-if="queryParams.configType=='1'"></el-table-column>
        <el-table-column prop="configVal1" label="url" min-width="200" sortable="custom"
                         v-if="queryParams.configType=='1'"></el-table-column>
        <el-table-column prop="comment" label="内容" min-width="150"
                         v-if="queryParams.configType=='1'"></el-table-column>

        <el-table-column prop="configVal1" label="源URL" min-width="200"
                         v-if="queryParams.configType=='2'"></el-table-column>
        <el-table-column prop="configKey" label="映射URL" min-width="200"
                         v-if="queryParams.configType=='2'"></el-table-column>
        <el-table-column prop="comment" label="说明" min-width="200"
                         v-if="queryParams.configType=='2'"></el-table-column>


        <el-table-column prop="configVal2" label="actionKey" min-width="200"
                         v-if="queryParams.configType=='3'"></el-table-column>
        <el-table-column prop="configVal1" label="标签名称" min-width="200"
                         v-if="queryParams.configType=='3'"></el-table-column>

        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="delConfig(scope.row)">删除</el-button>
          </template>
        </el-table-column>

        <template slot="empty" v-if="tableDataList.length==0">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
    </div>


    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="selectConfigList"
      :page-sizes="[10, 20, 50, 100]"
    />

    <el-dialog title="新增URL映射" width="700px"
               :visible.sync="addUrlMappingDialogVisible"
               :close-on-click-modal="false"
    >

      <el-form ref="addUrlMappingForm" status-icon :model="addUrlMappingFormData" label-width="140px">
        <el-form-item label="原始URL:" prop="sourceUrl"
                      :rules="[{ required: true, message: '请输入原始URL', trigger: 'blur'}]"
        >
          <el-input placeholder="请输入原始URL" maxlength="100" clearable show-word-limit
                    v-model="addUrlMappingFormData.sourceUrl"
                    style="width: 500px;"
          />
          <div class="sub-title">示例值:/substance/edit/11325</div>
        </el-form-item>
        <el-form-item label="映射URL:" prop="mappingUrl"
                      :rules="[{ required: true, message: '请输入映射URL', trigger: 'blur'}]"
        >
          <el-input placeholder="请输入映射URL" maxlength="100" clearable show-word-limit
                    v-model="addUrlMappingFormData.mappingUrl"
                    style="width: 500px;"
          />
          <div class="sub-title">示例值:/substance/edit/*</div>
        </el-form-item>

        <el-form-item label="说明:" prop="comment"
                      :rules="[{ required: true, message: '请输入说明', trigger: 'blur'}]"
        >
          <el-input placeholder="请输入说明" maxlength="100" clearable show-word-limit
                    v-model="addUrlMappingFormData.comment"
                    style="width: 500px;"
          />
        </el-form-item>

        <el-row>
          <el-col :span="20" style="margin-top: 40px;">
            <el-button type="primary" size="small"
                       style="margin-left: 20px;background: #2B60F8;float:right;"
                       @click="confirmAddUrlMapping"
            >确定
            </el-button>
            <el-button style="float: right;margin-right: 10px;"
                       size="small" @click="addUrlMappingDialogVisible=false"
            >取消
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>


  </div>
</template>

<script>

import AcrossUtil from '@/utils/acrossUtil'
import {isEmpty} from '@/utils/common'
import {selectConfigList, delConfig, addUrlMapping} from '@/api/system/sysActionRecord'

export default {
  name: 'ConfigList',
  components: {},
  data() {
    return {
      // 遮罩层
      loading: true,
      tableDataList: [],
      // 总条数
      total: 0,
      // 表格高度
      tableHeight: window.innerHeight - 100 - 100,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        searchValue: null,
        orderByColumn: undefined,
        isAsc: undefined,
        configType: '1',
      },
      addUrlMappingDialogVisible: false,
      addUrlMappingFormData: {
        sourceUrl: "",
        mappingUrl: "",
        comment:""
      }

    }
  },
  created() {
    this.$nextTick(() => {
      this.setTableHeight()
      //页面初始化完毕，默认触发一次排序
      this.$refs.refundTable.sort('id', 'descending')
    })
  },
  activated() {
    //激活时重新设置表格高度
    this.setTableHeight()
  },
  mounted() {
    var _this = this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      AcrossUtil.$emit('resetTableHeight')
    }

    AcrossUtil.$on('resetTableHeight', function (param) {
      setTimeout(function () {
        _this.setTableHeight()
      }, 300)
    })

  },
  methods: {
    /*设置表格的高度*/
    setTableHeight() {
      // 获取高度值 （内容高+padding+边框）
      let formHeight = this.$refs.headerQuery.offsetHeight
      this.tableHeight = window.innerHeight - formHeight - 150
    },
    /*tab页切换*/
    handleTabClick(tab, event) {
      this.handleQuery()
    },
    /*查询全部api列表 */
    async selectConfigList() {
      this.loading = true
      let response = await selectConfigList(this.queryParams)
      this.tableDataList = response.rows
      this.total = response.total
      this.loading = false
    },
    /*搜索按钮操作*/
    handleQuery() {
      this.isResult = true
      this.queryParams.pageNum = 1
      this.selectConfigList()
    },

    /*表格排序切换*/
    tableSortChange({column, prop, order}) {
      if (order != null) {
        order = order == 'descending' ? 'desc' : 'asc'
      } else {
        order = ''
      }
      this.queryParams.orderByColumn = prop
      this.queryParams.isAsc = order
      this.handleQuery()
    },
    /*删除配置*/
    delConfig(row) {
      delConfig(row.id, row.configType).then(res => {
        this.selectConfigList()
      })
    },
    /*显示新增url映射弹窗*/
    handleAddUrlMapping() {
      this.addUrlMappingDialogVisible = true
      this.addUrlMappingFormData.mappingUrl = ""
      this.addUrlMappingFormData.sourceUrl = ""
      this.addUrlMappingFormData.comment = ""
    },
    /*确定保存url映射*/
    confirmAddUrlMapping() {
      this.$refs['addUrlMappingForm'].validate(valid => {
        if (valid) {
          let form = this.addUrlMappingFormData
          addUrlMapping(form.sourceUrl, form.mappingUrl,form.comment).then(res => {
            this.addUrlMappingDialogVisible = false
            this.selectConfigList()
          })
        } else {
          this.msgError('请规范填写')
        }
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.el-table .cell .el-form-item {
  margin-bottom: 0px;
}

</style>


<style>


</style>
