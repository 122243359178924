var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
      staticStyle: { overflow: "auto" },
    },
    [
      _c(
        "div",
        { ref: "headerQuery" },
        [
          _c(
            "el-row",
            { staticClass: "headerClass" },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "border-left": "5px solid #0073E9",
                    "padding-left": "10px",
                  },
                  attrs: { span: 4 },
                },
                [_vm._v("上传记录")]
              ),
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "queryForm",
                      staticStyle: { float: "right" },
                      attrs: { model: _vm.queryParams, inline: true },
                    },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-search",
                                size: "small",
                              },
                              on: { click: _vm.handleQuery },
                            },
                            [_vm._v("检索")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "addClass",
                              attrs: { icon: "el-icon-upload2", size: "small" },
                              on: { click: _vm.handleUpload },
                            },
                            [_vm._v("上传数据")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { icon: "fa fa-cog", size: "small" },
                              on: { click: _vm.columnSettings },
                            },
                            [_vm._v("设置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { ref: "tableContainer" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "refundTable",
              attrs: {
                border: "",
                stripe: "",
                data: _vm.uploadList,
                height: _vm.tableHeight,
              },
              on: {
                "sort-change": _vm.tableSortChange,
                "row-contextmenu": _vm.rowContextmenu,
              },
            },
            [
              _vm._l(_vm.columnOptions, function (data) {
                return _c("el-table-column", {
                  key: "columnOptions" + data.id,
                  attrs: {
                    prop: data.prop,
                    label: data.name,
                    fixed: data.fixed,
                    resizable: false,
                    sortable: data.sortable,
                    "min-width": data.width,
                    align: data.align,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            data.prop == "fileList"
                              ? _c("div", [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(scope.row.ypjcxxFileName) +
                                        _vm._s(
                                          "(" + scope.row.ypjcxxFileRows + ")"
                                        )
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(scope.row.storeFileName) +
                                        _vm._s(
                                          "(" + scope.row.storeFileRows + ")"
                                        )
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(scope.row.hospitalFileName) +
                                        _vm._s(
                                          "(" + scope.row.hospitalFileRows + ")"
                                        )
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(scope.row.ybblgzFileName) +
                                        _vm._s(
                                          "(" + scope.row.ybblgzFileRows + ")"
                                        )
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(scope.row.ybkbxgzFileName) +
                                        _vm._s(
                                          "(" + scope.row.ybkbxgzFileRows + ")"
                                        )
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(scope.row.ypcggzFileName) +
                                        _vm._s(
                                          "(" + scope.row.ypcggzFileRows + ")"
                                        )
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(scope.row.ypjggzFileName) +
                                        _vm._s(
                                          "(" + scope.row.ypjggzFileRows + ")"
                                        )
                                    ),
                                  ]),
                                ])
                              : data.prop == "dataDetail"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-tag",
                                      {
                                        staticStyle: {
                                          width: "100px",
                                          margin: "0px 4px 4px 0px",
                                        },
                                        attrs: { type: "info" },
                                      },
                                      [
                                        _vm._v(
                                          "无变化:" +
                                            _vm._s(scope.row.noChangeSize) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-tag",
                                      { staticStyle: { width: "100px" } },
                                      [
                                        _vm._v(
                                          "修改:" + _vm._s(scope.row.updateSize)
                                        ),
                                      ]
                                    ),
                                    _c("br"),
                                    _c(
                                      "el-tag",
                                      {
                                        staticStyle: {
                                          width: "100px",
                                          margin: "0px 4px 4px 0px",
                                        },
                                        attrs: { type: "success" },
                                      },
                                      [
                                        _vm._v(
                                          "新增:" + _vm._s(scope.row.addSize)
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-tag",
                                      {
                                        staticStyle: { width: "100px" },
                                        attrs: { type: "danger" },
                                      },
                                      [
                                        _vm._v(
                                          "删除:" + _vm._s(scope.row.delSize)
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : data.prop == "status"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-tag",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: scope.row.status == 2,
                                            expression: "scope.row.status==2",
                                          },
                                        ],
                                        attrs: { type: "success" },
                                      },
                                      [_vm._v("成功")]
                                    ),
                                    _c(
                                      "el-tag",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: scope.row.status == -1,
                                            expression: "scope.row.status==-1",
                                          },
                                        ],
                                        attrs: { type: "danger" },
                                      },
                                      [_vm._v("失败")]
                                    ),
                                    _c(
                                      "el-tag",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: scope.row.status == 1,
                                            expression: "scope.row.status==1",
                                          },
                                        ],
                                        attrs: { type: "warning" },
                                      },
                                      [_vm._v("运行中")]
                                    ),
                                    _c("br"),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: scope.row.status == -1,
                                            expression: "scope.row.status==-1",
                                          },
                                        ],
                                      },
                                      [_vm._v(_vm._s(scope.row.errorMsg))]
                                    ),
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row[data.prop])),
                                  ]),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _vm.uploadList.length == 0
                ? _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-booking.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ])
                : _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-left-data.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("未查询到结果，请修改查询条件重试！")]),
                  ]),
            ],
            2
          ),
        ],
        1
      ),
      _vm.tableContextMenuVisible
        ? _c("table-context-menu", {
            ref: "contextbutton",
            attrs: { action: _vm.tableAction },
            on: { foo: _vm.foo, handleTableAction: _vm.handleTableAction },
          })
        : _vm._e(),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
          "page-sizes": [10, 20, 50, 100],
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.selectUploadRecordList,
        },
      }),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "文件上传",
            width: "650px",
            "append-to-body": "",
            visible: _vm.uploadFilesDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadFilesDialogVisible = $event
            },
            close: _vm.cancelUpload,
          },
        },
        [
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { padding: "20px" },
              attrs: { id: "uploadFilesDiv" },
            },
            [
              _c(
                "el-form",
                {
                  ref: "uploadFilesForm",
                  attrs: {
                    "status-icon": "",
                    model: _vm.uploadFilesForm,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "药品zip文件:",
                                prop: "smpaZipFile",
                                rules: [
                                  {
                                    required: true,
                                    message:
                                      "请选择西药药品信息zip文件(如YPQLFBWJ_201701.zip)",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("div", { staticClass: "sub-title" }, [
                                _vm._v(
                                  "请选择西药药品信息zip文件(如YPQLFBWJ_201701.zip)"
                                ),
                              ]),
                              _c("el-input", {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  id: "smpaZipFile",
                                  clearable: "",
                                  type: "file",
                                },
                                model: {
                                  value: _vm.uploadFilesForm.smpaZipFile,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.uploadFilesForm,
                                      "smpaZipFile",
                                      $$v
                                    )
                                  },
                                  expression: "uploadFilesForm.smpaZipFile",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "margin-left": "20px",
                                background: "#2B60F8",
                                float: "right",
                              },
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.uploadFiles },
                            },
                            [_vm._v("确定 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                float: "right",
                                "margin-right": "10px",
                              },
                              attrs: { size: "small" },
                              on: { click: _vm.cancelUpload },
                            },
                            [_vm._v("取消 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "数据处理进度",
            width: "90%",
            "append-to-body": "",
            visible: _vm.uploadProgressDialogVisible,
            "close-on-click-modal": false,
            "show-close": _vm.uploadProgressDialogShowClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadProgressDialogVisible = $event
            },
            close: _vm.handleQuery,
          },
        },
        [
          _c(
            "el-steps",
            {
              attrs: { active: _vm.uploadProgress.active, "align-center": "" },
            },
            [
              _c("el-step", {
                attrs: {
                  title: "步骤1 文件校验",
                  description: _vm.uploadProgress.step1Desc,
                },
              }),
              _c("el-step", {
                attrs: {
                  title: "步骤2 解析医院药品",
                  description: _vm.uploadProgress.step2Desc,
                },
              }),
              _c("el-step", {
                attrs: {
                  title: "步骤3 解析药店药品",
                  description: _vm.uploadProgress.step3Desc,
                },
              }),
              _c("el-step", {
                attrs: {
                  title: "步骤4 解析药品基础信息",
                  description: _vm.uploadProgress.step4Desc,
                },
              }),
              _c("el-step", {
                attrs: {
                  title: "步骤5 医保报销比例规则",
                  description: _vm.uploadProgress.step5Desc,
                },
              }),
              _c("el-step", {
                attrs: {
                  title: "步骤6 药品医保可报销条件规则",
                  description: _vm.uploadProgress.step6Desc,
                },
              }),
              _c("el-step", {
                attrs: {
                  title: "步骤7 药品采购规则",
                  description: _vm.uploadProgress.step7Desc,
                },
              }),
              _c("el-step", {
                attrs: {
                  title: "步骤8 药品价格规则",
                  description: _vm.uploadProgress.step8Desc,
                },
              }),
              _c("el-step", {
                attrs: {
                  title: "步骤9 合并数据",
                  description: _vm.uploadProgress.step9Desc,
                },
              }),
              _c("el-step", {
                attrs: {
                  title: "步骤10 比较数据",
                  description: _vm.uploadProgress.step10Desc,
                },
              }),
              _c("el-step", {
                attrs: {
                  title: "步骤11 生成差异报告",
                  description: _vm.uploadProgress.step11Desc,
                },
              }),
              _c("el-step", {
                attrs: {
                  title: "步骤12 完成",
                  description: _vm.uploadProgress.step12Desc,
                },
              }),
            ],
            1
          ),
          _c("p", { staticStyle: { color: "red" } }, [
            _vm._v(_vm._s(this.uploadProgress.errorMsg)),
          ]),
          _c(
            "el-link",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.uploadProgress.hospitalAndStoreDrugCompareReport,
                  expression:
                    "uploadProgress.hospitalAndStoreDrugCompareReport",
                },
              ],
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleDownload(_vm.uploadProgress.versionNo, 1)
                },
              },
            },
            [_vm._v(" 点此下载药店药品和医院药品的差异比较报告 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }