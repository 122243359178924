<template>
  <div class="app-container" style="overflow: auto">


    <div ref="headerQuery">
      <el-row class="headerClass">
        <el-col :span="4" style="border-left: 5px solid #0073E9;padding-left: 10px;">已接入api列表</el-col>
        <el-col :span="20">
          <el-form :model="queryParams" ref="queryForm" :inline="true" style="float: right">


            <el-form-item label="api名称/访问URL:" prop="searchValue">
              <el-input v-model="queryParams.searchValue" maxlength="80" clearable style="width: 300px;"></el-input>
            </el-form-item>


            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">检索</el-button>
              <el-button icon="el-icon-circle-plus-outline" size="small" class="addClass" @click="handleAdd">新增API
              </el-button>
              <el-button icon="fa fa-cog" size="small" @click="columnSettings">设置</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>


    <div ref="tableContainer">
      <el-table
        border
        stripe
        ref="refundTable"
        :data="tableDataList"
        :height="tableHeight"
        v-loading="loading"
        @sort-change="tableSortChange"
        @row-contextmenu="rowContextmenu"
      >
        <el-table-column
          v-for="data in columnOptions"
          :key="'columnOptions'+data.id"
          :prop="data.prop"
          :label="data.name"
          :fixed="data.fixed"
          :resizable="false"
          :sortable="data.sortable"
          :min-width="data.width"
          :align="data.align"
        >
          <template slot-scope="scope">


            <div v-if="data.prop == 'status'">
              <el-tag type="info" v-if="scope.row.status=='0'">停用</el-tag>
              <el-tag type="success" v-if="scope.row.status=='1'">启用</el-tag>
            </div>

            <div v-else-if="data.prop == 'apiServerName'">
             <p>{{scope.row.apiServerName}}</p>
              <p>{{scope.row.apiServerUrl}}</p>
            </div>

            <div v-else>
              <span>{{ scope.row[data.prop] }}</span>
            </div>
          </template>
        </el-table-column>

        <template slot="empty" v-if="tableDataList.length==0">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
    </div>

    <table-context-menu v-if="tableContextMenuVisible"
                        @foo="foo" ref="contextbutton"
                        :action="tableAction"
                        @handleTableAction="handleTableAction"
    ></table-context-menu>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="selectApiList"
      :page-sizes="[10, 20, 50, 100]"
    />


    <el-dialog title="API测试" width="900px"
               :visible.sync="apiTestDialogVisible"
               @close="cancelApiTest"
               :close-on-click-modal="false"
    >
      <el-row v-loading="apiTestLoading">
        <el-col :span="16">
          <el-form ref="apiTestForm" status-icon :model="apiTestFormData" label-width="110px">

            <el-form-item label="API名称:" prop="apiName"
            >
              <el-input :disabled="true"
                        v-model="apiTestFormData.apiName"
                        style="min-width: 200px;max-width:400px;"
              />
            </el-form-item>

            <el-form-item label="访问地址:" prop="apiUrl"
                          :rules="[{ required: true, message: '请输入API访问地址', trigger: 'blur'}]"
            >
              <el-input placeholder="请输入api访问地址" maxlength="200" clearable show-word-limit
                        v-model="apiTestFormData.apiUrl"
                        style="min-width: 200px;max-width:400px;"
              />
            </el-form-item>

            <el-form-item label="请求方式:" prop="requestType">
              <el-radio-group v-model="apiTestFormData.requestType" size="small">
                <el-radio-button label="post"></el-radio-button>
                <el-radio-button label="get"></el-radio-button>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="API_TOKEN:" prop="apiToken"
                          :rules="[{ required: true, message: '请输入API_TOKEN', trigger: 'blur'}]"
            >
              <el-input placeholder="请输入API_TOKEN" maxlength="50" clearable show-word-limit
                        v-model="apiTestFormData.apiToken"
                        style="min-width: 200px;max-width:400px;"
              />
            </el-form-item>

            <el-form-item label="contentType:" prop="contentType">
              <el-radio-group v-model="apiTestFormData.contentType" size="small">
                <el-radio-button label="x-www-form-urlencoded"></el-radio-button>
                <el-radio-button label="application/json"></el-radio-button>
              </el-radio-group>
            </el-form-item>


            <el-form-item label="请求参数:" prop="jsonParams"
                          :rules="[{ validator: validateJsonParams, trigger: 'blur'}]"
                          v-show="apiTestFormData.contentType=='application/json'"
            >
              <el-input type="textarea" :rows="4" placeholder="请输入请求参数(json)"
                        v-model="apiTestFormData.jsonParams"
                        maxlength="1500" clearable show-word-limit
                        style="min-width: 200px;max-width:400px;"
              />
            </el-form-item>

            <el-form-item label="请求参数:"
                          v-show="apiTestFormData.contentType=='x-www-form-urlencoded'"
            >
              <el-table border stripe
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                        :data="apiTestFormData.formParams"
                        style="min-width: 200px;max-width:400px;"
              >
                <el-table-column label="参数名" min-width="150">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'formParams.' + scope.$index + '.'+'name'"
                      :rules="[{ required: false, message: '请输入参数名称', trigger: 'blur'}]"
                    >
                      <el-input v-model="scope.row.name" size="small" placeholder="请输入参数名称"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="参数值" min-width="150">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'formParams.' + scope.$index + '.'+'value'"
                      :rules="[{ required: false, message: '请输入参数值', trigger: 'blur'}]"
                    >
                      <el-input v-model="scope.row.value" size="small" placeholder="请输入参数值"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="60">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="text"
                      @click="delFormParams(scope.$index, scope.row)"
                    >删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-row style="margin-top: 10px;">
                <el-button icon="el-icon-circle-plus-outline" size="small" @click="addFormParams">新增参数</el-button>
              </el-row>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" style="width: 200px;" @click="executeApiTest">测试</el-button>
            </el-form-item>

          </el-form>
        </el-col>
        <el-col :span="8" style="padding: 20px;">
          <el-form ref="apiTestResultForm" status-icon :model="apiTestResult" label-width="0px">
            <p>响应内容</p>
            <div>
              <el-form-item label="" prop="comment">
                <el-input type="textarea" :rows="20"  placeholder="响应信息"
                          v-model="apiTestResult.res"
                          maxlength="500000" clearable show-word-limit
                          style="width: 250px;"
                />
              </el-form-item>
            </div>
          </el-form>
        </el-col>
      </el-row>

    </el-dialog>


    <el-dialog title="API编辑" width="730px"
               :visible.sync="apiEditDialogVisible"
               @close="cancelEditApi"
               :close-on-click-modal="false"
    >
      <el-form ref="apiEditForm" status-icon :model="apiEditFormData" label-width="140px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="API名称:" prop="apiName"
                          :rules="[{ required: true, message: '请输入API名称', trigger: 'blur'}]"
            >
              <el-input placeholder="请输入API名称" maxlength="50" clearable show-word-limit
                        v-model="apiEditFormData.apiName"
                        style="width: 500px;"
              />
            </el-form-item>

            <el-form-item label="状态:" prop="status"
            >
              <el-radio-group v-model="apiEditFormData.status">
                <el-radio-button label="0">停用</el-radio-button>
                <el-radio-button label="1">启用</el-radio-button>
              </el-radio-group>
            </el-form-item>


            <el-form-item label="是否需要权限:" prop="needAuth"
            >
              <el-radio-group v-model="apiEditFormData.needAuth">
                <el-radio-button :label='0'>否</el-radio-button>
                <el-radio-button :label='1'>是</el-radio-button>
              </el-radio-group>
            </el-form-item>




            <el-form-item label="访问地址:" prop="apiUrl"
                          :rules="[{ required: true, message: '请输入API访问地址', trigger: 'blur'}]"
            >
              <el-input placeholder="请输入api访问地址" maxlength="200" clearable show-word-limit
                        v-model="apiEditFormData.apiUrl"
                        style="width: 500px;"
              />
            </el-form-item>

            <el-form-item label="API所属服务:" prop="apiServerId"
                          :rules="[{ required: true, message: '请选择API所属服务', trigger: 'blur'}]"
            >
              <el-select style="width: 500px;"
                         v-model="apiEditFormData.apiServerId"
              >
                <el-option
                  v-for="item in apiServerOptions"
                  :key="item.id+'apiServerOptions'"
                  :label="item.serverName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="转发地址:" prop="forwardUrl"
                          :rules="[{ required: true, message: '请输入API转发地址', trigger: 'blur'}]"
            >
              <el-input placeholder="请输入api转发地址" maxlength="200" clearable show-word-limit
                        v-model="apiEditFormData.forwardUrl"
                        style="width: 500px;"
              />
            </el-form-item>

            <el-form-item label="API描述信息:" prop="apiDesc">
              <el-input type="textarea" :rows="6" placeholder="请输入API描述信息"
                        v-model="apiEditFormData.apiDesc"
                        maxlength="500" clearable show-word-limit
                        style="width: 500px;float: left;"
              />
            </el-form-item>

          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20" style="margin-top: 40px;">
            <el-button type="primary" size="small"
                       style="margin-left: 20px;background: #2B60F8;float:right;"
                       @click="confirmEditAPi"
            >确定
            </el-button>
            <el-button style="float: right;margin-right: 10px;"
                       size="small" @click="cancelEditApi"
            >取消
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>


  </div>
</template>

<script>

import conceptConst from '@/const/conceptConst'
import AcrossUtil from '@/utils/acrossUtil'
import { UCgetConfig } from '@/utils/columnSettings'
import { GLOBAL_CONFIGURATION_CONFIGKEY } from '@/utils/constants'
import { isEmpty } from '@/utils/common'
import {
  selectApiList, selectApiServerNameList, saveOrUpdateApi,
  delApi, executeFormPost, executeJsonPost,executeFormGet
} from '@/api/system/apiGateway'
import { deepClone } from '../../../utils'

export default {
  name: 'ApiList',
  components: {},
  data() {
    return {
      conceptConst,
      // 遮罩层
      loading: true,
      tableDataList: [],
      // 总条数
      total: 0,
      // 表格高度
      tableHeight: window.innerHeight - 100 - 100,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        searchValue: null,
        orderByColumn: undefined,
        isAsc: undefined
      },
      sltConfigKey: '',
      sltConfigClassify: '',
      columnOptions: [],
      tableOptions: [
        {
          id: 1,
          prop: 'apiName',
          name: 'api名称',
          fixed: true,
          show: true,
          sortable: 'custom',
          align: 'center',
          width: '100'
        },
        {
          id: 2,
          prop: 'apiUrl',
          name: 'api访问url',
          fixed: false,
          show: true,
          align: 'center',
          width: '200'
        },
        {
          id: 3,
          prop: 'apiServerName',
          name: '服务名称',
          fixed: false,
          show: true,
          align: 'center',
          width: '80'
        },
        {
          id: 4,
          prop: 'forwardUrl',
          name: 'api转发url',
          fixed: false,
          show: true,
          align: 'center',
          width: '200'
        },
        {
          id: 5,
          prop: 'status',
          name: '状态',
          fixed: false,
          show: true,
          align: 'center',
          width: '70'
        },
        {
          id: 6,
          prop: 'updateTime',
          name: '更新时间',
          fixed: false,
          show: true,
          align: 'center',
          width: '100'
        },
        {
          id: 7,
          prop: 'needAuth',
          name: '是否需要权限权限',
          fixed: false,
          show: true,
          align: 'center',
          width: '100'
        }
      ],
      /*表格右键菜单显示隐藏*/
      tableContextMenuVisible: false,
      tableAction: [{ name: '修改' }, { name: 'API测试' }, { name: '删除', style: 'color: #FF3333;' }],
      /* api编辑弹窗显示隐藏*/
      apiEditDialogVisible: false,
      /* api编辑表单数据*/
      apiEditFormData: {},
      apiServerOptions: [],
      /* api测试弹窗显示隐藏*/
      apiTestDialogVisible: false,
      /* api测试表单数据*/
      apiTestFormData: {
        requestType: 'post',
        apiName: '',
        apiUrl: '',
        contentType: 'x-www-form-urlencoded',
        apiToken: '',
        formParams: [{ name: 'pageNum', value: '1' },{ name: 'pageSize', value: '10' }],
        jsonParams: undefined
      },
     /* api测试响应结果*/
      apiTestResult: {
        res:undefined
      },
      apiTestLoading:false

    }
  },
  created() {
    let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 70)
    this.sltConfigKey = item.configKey
    this.sltConfigClassify = item.configClassify
    this.getSettingTable().then(() => {
      this.$nextTick(() => {
        this.setTableHeight()
        //页面初始化完毕，默认触发一次排序
        this.$refs.refundTable.sort('apiName', 'descending')
        this.getApiServerList()
      })
    })

  },
  activated() {
    //激活时重新设置表格高度
    this.setTableHeight()
  },
  mounted() {
    var _this = this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      AcrossUtil.$emit('resetTableHeight')
    }

    AcrossUtil.$on('resetTableHeight', function(param) {
      setTimeout(function() {
        _this.setTableHeight()
      }, 300)
    })

  },
  methods: {
    /*设置表格的高度*/
    setTableHeight() {
      // 获取高度值 （内容高+padding+边框）
      let formHeight = this.$refs.headerQuery.offsetHeight
      this.tableHeight = window.innerHeight - formHeight - 150
    },
    // 设置按钮-获取设置的数据
    async getSettingTable() {
      let _this = this
      let obj = {}
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 70)
      this.sltConfigKey = item.configKey
      this.sltConfigClassify = item.configClassify
      obj.configKey = this.sltConfigKey
      this.columnOptions = []
      let res = await UCgetConfig(obj)
      if (res.data && res.data.configValue) {
        let list = JSON.parse(res.data.configValue).list
        if (list.length > 0) {
          list.forEach(function(item) {
            if (item.show == undefined || item.show == true) {
              _this.columnOptions.push(item)
            }
          })
        } else {
          _this.msgError('获取数据表格配置信息失败')
        }
      } else {
        let defaultConfig = this.tableOptions
        defaultConfig.forEach(function(item) {
          if (item.show == undefined || item.show == true) {
            _this.columnOptions.push(item)
          }
        })
      }
      _this.$nextTick(() => {
        _this.$refs.refundTable.doLayout()
      })
    },
    // 设置
    async columnSettings() {
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 70)
      this.sltConfigKey = item.configKey
      this.sltConfigClassify = item.configClassify
      let _this = this
      _this.$ColumnSettings.showColSettings(
        _this.sltConfigKey,
        _this.sltConfigClassify,
        _this.tableOptions,
        _this.getSettingTable
      )
    },
    /*鼠标右键点击事件*/
    rowContextmenu(row, column, event) {
      this.tableContextMenuVisible = false
      this.tableContextMenuVisible = true
      // 阻止右键默认行为
      event.preventDefault()
      this.$nextTick(() => {
        this.$refs.contextbutton.init(row, column, event)
      })
    },
    /* 取消鼠标监听事件 菜单栏*/
    foo() {
      this.tableContextMenuVisible = false
      document.removeEventListener('click', this.foo)
    },
    /* 处理表格右键菜单点击*/
    handleTableAction(actionName, row, column) {
      if (actionName === '修改') {
        this.handleUpdate(row)
      } else if (actionName === '删除') {
        this.handleDelete(row)
      } else if (actionName === 'API测试') {
        this.handleApiTest(row)
      }
    },
    /*查询全部api列表 */
    async selectApiList() {
      this.loading = true
      let response = await selectApiList(this.queryParams)
      this.tableDataList = response.rows
      this.total = response.total
      this.loading = false
    },
    /*搜索按钮操作*/
    handleQuery() {
      this.isResult = true
      this.queryParams.pageNum = 1
      this.selectApiList()
    },
    /*新增按钮操作*/
    handleAdd() {
      this.apiEditDialogVisible = true
      let form = this.$refs['apiEditForm']
      if (form) {
        form.resetFields()
      }
      this.apiEditFormData = {status:"1",needAuth:1}
    },
    /*编辑按钮操作*/
    handleUpdate(row) {
      this.apiEditDialogVisible = true
      let form = this.$refs['apiEditForm']
      if (form) {
        form.resetFields()
      }
      this.apiEditFormData = deepClone(row)

    },
    /*删除按钮操作*/
    handleDelete(row) {
      let that = this
      this.$confirm('确认要删除' + '"[' + row.id + ']-' + row.apiName + '"吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        delApi(row.id).then(response => {
          that.selectApiList()
          that.msgSuccess('删除成功')
        })
      })
    },
    /*API测试*/
    handleApiTest(row) {
      this.apiTestDialogVisible = true
      this.apiTestFormData.apiName = row.apiName
      this.apiTestFormData.apiUrl = row.apiUrl

    },
    /*表格排序切换*/
    tableSortChange({ column, prop, order }) {
      if (order != null) {
        order = order == 'descending' ? 'desc' : 'asc'
      } else {
        order = ''
      }
      this.queryParams.orderByColumn = prop
      this.queryParams.isAsc = order
      this.handleQuery()
    },
    /*取消编辑*/
    cancelEditApi() {
      this.apiEditFormData = {}
      this.apiEditDialogVisible = false
    },
    /*确定编辑*/
    confirmEditAPi() {
      this.$refs['apiEditForm'].validate(valid => {
        if (valid) {
          saveOrUpdateApi(this.apiEditFormData).then(response => {
            this.msgSuccess(response.msg)
            this.manufacturerFormData = {}
            this.selectApiList()
            this.apiEditDialogVisible = false
          })
        } else {
          this.msgError('请规范填写')
        }
      })
    },
    /*获取全部API所属服务列表*/
    getApiServerList() {
      selectApiServerNameList().then(res => {
        this.apiServerOptions = res.data
      })
    },
    /* 删除FormParams参数*/
    delFormParams(index, row) {
      this.apiTestFormData.formParams.splice(index, 1)
    },
    /* 新增FormParams参数*/
    addFormParams(index, row) {
      this.apiTestFormData.formParams.push({ code: '', value: '' })
    },
    /*取消api测试*/
    cancelApiTest(){
      this.apiTestLoading = false
    },
    /*执行api测试*/
    executeApiTest() {
      this.apiTestResult.res=''
      this.$refs['apiTestForm'].validate(valid => {
        if (valid) {
          this.apiTestLoading = true
          let requestType = this.apiTestFormData.requestType
          let contentType = this.apiTestFormData.contentType
          let apiUrl = this.apiTestFormData.apiUrl
          let apiToken = this.apiTestFormData.apiToken
          if (requestType == 'post' && contentType == 'x-www-form-urlencoded') {
            let params = {}
            this.apiTestFormData.formParams.forEach(item => {
              if (!isEmpty(item.name)) {
                params[item.name.trim()] = item.value
              }
            })
            executeFormPost(params, apiUrl, apiToken).then(res => {
             let resStr=JSON.stringify(res);
              this.apiTestResult=deepClone(res)
              this.apiTestResult.res=resStr
              this.apiTestLoading = false
            })
          } else if (requestType == 'post' && contentType == 'application/json') {
            executeJsonPost(this.apiTestFormData.jsonParams, apiUrl, apiToken).then(res => {
              let resStr=JSON.stringify(res);
              this.apiTestResult=deepClone(res)
              this.apiTestResult.res=resStr
              this.apiTestLoading = false
            })
          }else if(requestType == 'get'){
            let params = {}
            this.apiTestFormData.formParams.forEach(item => {
              if (!isEmpty(item.name)) {
                params[item.name.trim()] = item.value
              }
            })
            executeFormGet(params, apiUrl, apiToken).then(res => {
              let resStr=JSON.stringify(res);
              this.apiTestResult=deepClone(res)
              this.apiTestResult.res=resStr
              this.apiTestLoading = false
            })
          }

        } else {
          this.msgError('请规范填写')
        }
      })
    },
    /* 校验json请求参数*/
    validateJsonParams(rule, value, callback) {
      if (isEmpty(value) && this.apiTestFormData.contentType == 'application/json') {
        callback(new Error('请输入请求参数(json)'))
      } else {
        callback()
      }

    }

  }
}
</script>

<style lang="scss" scoped>
.el-table .cell .el-form-item {
  margin-bottom: 0px;
}

</style>


<style>


</style>
