<template>
  <div class="app-container" style="overflow: auto"
       v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="请稍后">


    <div ref="headerQuery">
      <el-row class="headerClass">
        <el-col :span="4" style="border-left: 5px solid #0073E9;padding-left: 10px;">国家医保自制制剂</el-col>
        <el-col :span="20">
          <el-form :model="queryParams" ref="queryForm" :inline="true" style="float: right">

            <el-form-item label="医疗机构名称:" prop="hosId">
              <el-select
                v-model="queryParams.hosId"
                placeholder="医疗机构名称"
                size="small"
                filterable
                class="statusClass"
                style="width: 200px"
                clearable
              >
                <el-option
                  v-for="item in hosNameOptions"
                  :key="item.hosId+'--'+item.hosName"
                  :label="item.hosName"
                  :value="item.hosId"
                />
              </el-select>
            </el-form-item>


            <el-form-item label="制剂代码/名称:" prop="searchValue">
              <el-input v-model="queryParams.searchValue" placeholder="制剂代码/名称" maxlength="50" clearable></el-input>
            </el-form-item>

            <el-form-item label="批准文号:" prop="approvalCode">
              <el-input v-model="queryParams.approvalCode" placeholder="批准文号" maxlength="50" clearable></el-input>
            </el-form-item>


            <el-form-item label="版本号:" prop="versionNo">
              <el-select
                v-model="queryParams.versionNo"
                placeholder="版本号"
                size="small"
                class="statusClass"
                style="width: 200px"
                @change="versionNoChange"
                clearable
              >
                <el-option
                  v-for="item in versionNoOptions"
                  :key="item.versionNo+'versionNo'"
                  :label="item.versionNo"
                  :value="item.versionNo"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="类型:" prop="modifyType" v-if="this.queryParams.versionNo">
              <el-select
                v-model="queryParams.modifyType"
                placeholder="类型"
                size="small"
                class="statusClass"
                style="width: 200px"
                clearable
              >
                <el-option
                  v-for="item in modifyTypeOptions"
                  :key="item.code+'modifyType'"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">检索</el-button>
              <el-button type="primary"  icon="el-icon-document" size="small" @click="handleExport">导出</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>


    <div ref="tableContainer">
      <el-table
        border
        stripe
        ref="refundTable"
        :data="drugList"
        :height="tableHeight"
        v-loading="loading"
        @sort-change="tableSortChange"
        @row-contextmenu="rowContextmenu"
        :span-method="objectSpanMethod"
        :cell-class-name="compareTableCellClass"
      >
        <el-table-column
          v-for="data in tableOptions"
          :key="'tableOptions'+data.id"
          :prop="data.prop"
          :label="data.name"
          :fixed="data.fixed"
          :resizable="false"
          :sortable="data.sortable"
          :min-width="data.width"
          v-if="data.show"
          :align="data.align"
        >
          <template slot-scope="scope">

            <div v-if="data.prop == 'modifyType'">
              <el-tag type="info" v-show="scope.row.modifyType==0">无变化</el-tag>
              <el-tag  v-show="scope.row.modifyType==1">修改</el-tag>
              <el-tag type="success" v-show="scope.row.modifyType==2">新增</el-tag>
              <el-tag type="danger" v-show="scope.row.modifyType==3">删除</el-tag>
            </div>

            <div v-else>
              <span>{{ scope.row[data.prop] }}</span>
            </div>
          </template>
        </el-table-column>

        <template slot="empty" v-if="drugList.length==0">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
    </div>

    <table-context-menu v-if="tableContextMenuVisible"
                        @foo="foo" ref="contextbutton"
                        :action="tableAction"
                        @handleTableAction="handleTableAction"
    ></table-context-menu>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="selectNhsaHosDrugList"
      :page-sizes="[10, 20, 50, 100]"
    />



  </div>
</template>

<script>

import conceptConst from '@/const/conceptConst'
import AcrossUtil from '@/utils/acrossUtil'
import { isEmpty,axiosDownload } from '@/utils/common'
import { nhsaHosDrugList,selectAllVersionNo,downloadNhsaHosDrugList } from '@/api/assistTool/nhsaHosDrug'
import { nhsaHosDrugList as fullNhsaHosDrugList ,downloadNhsaHosDrugList as downloadFullNhsaHosDrugList,getNhsaHosDrugHosList} from '@/api/dkm/dictDrug'
export default {
  name: 'NhsaDrugList',
  components: {},
  data() {
    return {
      fullscreenLoading: false,
      conceptConst,
      // 遮罩层
      loading: true,
      drugList: [],
      // 总条数
      total: 0,
      // 表格高度
      tableHeight: window.innerHeight - 100 - 150,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        searchValue: null,
        orderByColumn: undefined,
        isAsc: undefined,
        versionNo:undefined,
        modifyType:4,
        approvalCode:undefined,
        relevanceStatus:0,
        manufacturer:""
      },
      /*表格右键菜单显示隐藏*/
      tableContextMenuVisible: false,
      tableAction: [],
      hosNameOptions:[],
      versionNoOptions:[],
      modifyTypeOptions:[
        {code:4,name:'当前版本全量数据'},
        {code:0,name:'与之前版本完全一致'},
        {code:1,name:'当前版本修改'},
        {code:2,name:'当前版本新增'},
        {code:3,name:'当前版本删除'},
      ],
      relevanceStatusOptions:[
        {code:0,name:'全部'},
        {code:1,name:'已关联'},
        {code:2,name:'未关联'},
      ],
      /*查询关联知识库药品查询参数*/
      relevanceDrugQueryParams:{
        pageNum: 1,
        pageSize: 10,
        orderByColumn: undefined,
        isAsc: undefined,
        approvalNum:undefined,
        standardCode:undefined,
        stateMedicareNum:undefined

      },
      /*关联知识库药品表格数据*/
      relevanceDrugTableData:[],
      relevanceDrugTableDataTotal:0,
      relevanceDrugDialogVisible:false,
      selectTableRow:{},
      editDrugCommentDialogVisible:false,
      drugComment:"",
      drugId:""


    }
  },
  computed: {
    tableOptions() {
      return [
        {
          id: 1,
          prop: 'preparationCode',
          name: '制剂代码',
          fixed: true,
          show: true,
          sortable: 'custom',
          align: 'center',
          width: '100'
        },
        {
          id: 2,
          prop: 'hosName',
          name: '医疗机构名称',
          fixed: true,
          show: true,
          sortable: 'custom',
          align: 'center',
          width: '120'
        },
        {
          id: 3,
          prop: 'preparationName',
          name: '制剂名称',
          fixed: false,
          show: true,
          sortable: 'custom',
          align: 'center',
          width: '70'
        },
        {
          id: 4,
          prop: 'preparationType',
          name: '制剂类别',
          fixed: false,
          show: true,
          align: 'center',
          width: '70'
        },
        {
          id: 5,
          prop: 'form',
          name: '剂型',
          fixed: false,
          show: true,
          sortable: 'custom',
          align: 'center',
          width: '80'
        },
        {
          id: 6,
          prop: 'spec',
          name: '规格',
          fixed: false,
          show: true,
          align: 'center',
          width: '80'
        },
        {
          id: 7,
          prop: 'factor',
          name: '最小包装数量',
          fixed: false,
          show: true,
          align: 'center',
          width: '80'
        },
        {
          id: 8,
          prop: 'unit',
          name: '最小制剂单位',
          fixed: false,
          show: true,
          align: 'center',
          width: '80'
        },
        {
          id: 9,
          prop: 'packUnit',
          name: '最小包装单位',
          fixed: false,
          show: true,
          align: 'center',
          width: '80'
        },
        {
          id: 10,
          prop: 'wrapper',
          name: '包装材质',
          fixed: false,
          show: true,
          align: 'center',
          width: '80'
        },
        {
          id: 11,
          prop: 'approvalCode',
          name: '批准文号',
          fixed: false,
          show: true,
          sortable: 'custom',
          align: 'center',
          width: '80'
        },{
          id: 12,
          prop: 'modifyType',
          name: '数据类型',
          fixed: false,
          show: this.queryParams.versionNo?true:false,
          align: 'center',
          width: '80'
        }
      ]
    }
  },
  created() {
    this.$nextTick(() => {
      this.setTableHeight()
      if(this.$route.query && this.$route.query.versionNo){
        // 从其他页面路由传参版本号，快速搜索
        this.queryParams.versionNo=this.$route.query.versionNo
      }
      /* 查询全部版本号*/
      selectAllVersionNo().then(response => {
        this.versionNoOptions = response.data
        if( this.versionNoOptions.length==0){
          this.msgInfo("当前无数据版本，请先执行上传操作")
          this.loading=false
          return
        }
        //页面初始化完毕，默认触发一次排序
        this.$refs.refundTable.sort('preparationCode','ascending');
      })

      /* 查询全部医院列表*/
      getNhsaHosDrugHosList().then(response => {
        this.hosNameOptions = response.data
      })


    })

  },
  activated() {
    //激活时重新设置表格高度
    this.setTableHeight()
  },
  mounted() {
    var _this = this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      AcrossUtil.$emit('resetTableHeight')
    }

    AcrossUtil.$on('resetTableHeight', function(param) {
      setTimeout(function() {
        _this.setTableHeight()
      }, 300)
    })

  },
  methods: {
    /*设置表格的高度*/
    setTableHeight() {
      // 获取高度值 （内容高+padding+边框）
      let formHeight = this.$refs.headerQuery.offsetHeight
      this.tableHeight = window.innerHeight - formHeight - 150
    },


    /*鼠标右键点击事件*/
    rowContextmenu(row, column, event) {
      this.tableContextMenuVisible = false
      this.tableContextMenuVisible = true
      // 阻止右键默认行为
      event.preventDefault()
      this.$nextTick(() => {
        this.$refs.contextbutton.init(row, column, event)
      })
    },
    /* 取消鼠标监听事件 菜单栏*/
    foo() {
      this.tableContextMenuVisible = false
      document.removeEventListener('click', this.foo)
    },
    /* 处理表格右键菜单点击*/
    handleTableAction(actionName, row, column) {
      if (actionName === '查询相关知识库药品') {
        if(this.queryParams.versionNo){
          this.msgError("当前查看的是版本["+this.queryParams.versionNo+"]的数据，暂不支持该操作，请移除版本号筛选条件后重试")
        }
      }
    },
    /** 查询上传记录列表 */
    async selectNhsaHosDrugList() {
      this.loading = true
      let response
      if(this.queryParams.versionNo){
        //有版本号，查询带版本的数据
         response=await nhsaHosDrugList(this.queryParams)
      }else{
        //没有版本号，查询全量数据
         response=await fullNhsaHosDrugList(this.queryParams)
      }
      this.drugList = response.rows
      this.drugList.forEach(item=>{
        // if(item.goodsCode){
        //   item.ypbm=item.goodsCode
        // }
        // let modifyType=item.modifyType
        // Object.assign(item, item.currentDrug)
        // item.modifyType=modifyType
      })
      this.total = response.total
      //解决表格固定列-滚动时候错位
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.refundTable.doLayout()
        })
      }, 300)
      this.loading = false

      this.handleUpdateTableData()
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.isResult = true
      this.queryParams.pageNum = 1
      this.selectNhsaHosDrugList()
    },
    /** 导出按钮操作 */
    async handleExport(){
      this.fullscreenLoading=true
      let response
      if(this.queryParams.versionNo){
        //有版本号，查询带版本的数据
        response=await downloadNhsaHosDrugList(this.queryParams)
      }else{
        //没有版本号，查询全量数据
        response=await downloadFullNhsaHosDrugList(this.queryParams)
      }
      axiosDownload("国家医保自制制剂目录.xlsx", response)
      this.fullscreenLoading=false
    },
    /*表格排序切换*/
    tableSortChange({column, prop, order}){
      if(order!=null){
        order=order=='descending'?'desc':'asc';
      }else{
        order=''
      }
      this.queryParams.orderByColumn=prop
      this.queryParams.isAsc=order
      this.handleQuery()
    },

    compareTableCellClass({row, column, rowIndex, columnIndex}){
      let property=column.property
      if(['hosName','preparationName','preparationType','form',
        'spec','factor','unit','packUnit','wrapper','approvalCode'].indexOf(property)>-1 ){
        let nowVersionRow
        let lastVersionRow
        if(row.dataType=='当前版本'){
           nowVersionRow=row
           lastVersionRow=this.drugList[rowIndex+1]
        }else if(row.dataType=='上一个版本'){
          nowVersionRow=this.drugList[rowIndex-1]
          lastVersionRow=row
        }
       if(nowVersionRow &&lastVersionRow && nowVersionRow[property]!=lastVersionRow[property]){
          return 'difference-cell';
        }
      }else{
        return '';
      }
    },
    /*处理-修改类型的数据*/
    handleUpdateTableData(){
      let newDrugList=[]
      this.drugList.forEach(item=>{
        if(item.modifyType!=1){
          newDrugList.push(item)
          return
        }
        let nowVersion=item
        let lastVerson=item.lastVersionDrug
        if(item.modifyType==1){
          for(let key in nowVersion){
            if(['hosName','preparationName','preparationType','form',
              'spec','factor','unit','packUnit','wrapper','approvalCode'].indexOf(key)>-1){
              if(nowVersion[key]!=lastVerson[key]){
                nowVersion.hasDifference=true
                nowVersion.dataType='当前版本'
                lastVerson.hasDifference=true
                lastVerson.dataType='上一个版本'
                lastVerson.modifyType=1
              }
            }
          }
          newDrugList.push(nowVersion,lastVerson)
        }else{
          newDrugList.push(nowVersion)
        }

      })
      this.drugList=newDrugList
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      let property=column.property
      if (property === "preparationCode" ) {
        if(row.dataType=='当前版本'){
          return {
            rowspan: 2,
            colspan: 1
          }
        }else if(row.dataType=='上一个版本'){
          return {
            rowspan: 0,
            colspan: 0
          }
        }else{
          return {
            rowspan: 1,
            colspan: 1
          }
        }
      }
    },
    /*版本号变化*/
    versionNoChange(val){
      // if(this.queryParams.versionNo){
      //   //有版本号，查询带版本的数据
      //   this.queryParams.orderByColumn="preparationCode"
      // }else{
      //   //没有版本号，查询全量数据
      //   this.queryParams.orderByColumn="preparationCode"
      // }
      // this.queryParams.relevanceStatus=0
      this.handleQuery()
    },




  }
}
</script>

<style lang="scss" scoped>
.el-table .cell .el-form-item {
  margin-bottom: 0px;
}

</style>



<style>

.el-table .difference-cell .cell {
  color:#f50707;
}


</style>
