var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { height: "100%" } },
    [
      _c(
        "el-row",
        { staticStyle: { height: "100%" } },
        [
          _c(
            "el-col",
            {
              staticStyle: { height: "100%", "padding-right": "10px" },
              attrs: { span: 16 },
            },
            [_c("mainTable")],
            1
          ),
          _c(
            "el-col",
            { staticStyle: { height: "100%" }, attrs: { span: 8 } },
            [_c("associateTable")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }