var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { overflow: "auto" } },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch",
            },
          ],
          ref: "queryForm",
          attrs: { model: _vm.queryParams, inline: true },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "菜单名称", prop: "menuName" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入菜单名称",
                  clearable: "",
                  size: "small",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery($event)
                  },
                },
                model: {
                  value: _vm.queryParams.menuName,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "menuName", $$v)
                  },
                  expression: "queryParams.menuName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "status" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "菜单状态",
                    clearable: "",
                    size: "small",
                  },
                  model: {
                    value: _vm.queryParams.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "status", $$v)
                    },
                    expression: "queryParams.status",
                  },
                },
                _vm._l(_vm.statusOptions, function (dict) {
                  return _c("el-option", {
                    key: dict.dictValue,
                    attrs: { label: dict.dictLabel, value: dict.dictValue },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini",
                  },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("检索")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["system:menu:add"],
                      expression: "['system:menu:add']",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-plus",
                    size: "mini",
                  },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function ($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function ($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.menuList,
            "row-key": "menuId",
            "tree-props": { children: "children", hasChildren: "hasChildren" },
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "menuName",
              label: "菜单名称",
              "show-overflow-tooltip": true,
              width: "160",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "icon",
              label: "图标",
              align: "center",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("svg-icon", { attrs: { "icon-class": scope.row.icon } }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "orderNum", label: "排序", width: "60" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "perms",
              label: "权限标识",
              "show-overflow-tooltip": true,
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "component",
              label: "组件路径",
              "show-overflow-tooltip": true,
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: "状态",
              formatter: _vm.statusFormat,
              width: "80",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "创建时间", align: "center", prop: "createTime" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.parseTime(scope.row.createTime))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["system:menu:edit"],
                            expression: "['system:menu:edit']",
                          },
                        ],
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-edit",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(scope.row)
                          },
                        },
                      },
                      [_vm._v("修改")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["system:menu:add"],
                            expression: "['system:menu:add']",
                          },
                        ],
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-plus",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleAdd(scope.row)
                          },
                        },
                      },
                      [_vm._v("新增")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["system:menu:remove"],
                            expression: "['system:menu:remove']",
                          },
                        ],
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-delete",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "60%",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "上级菜单" } },
                        [
                          _c("treeselect", {
                            attrs: {
                              options: _vm.menuOptions,
                              normalizer: _vm.normalizer,
                              "show-count": true,
                              placeholder: "选择上级菜单",
                            },
                            model: {
                              value: _vm.form.parentId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "parentId", $$v)
                              },
                              expression: "form.parentId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "菜单类型", prop: "menuType" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.menuType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "menuType", $$v)
                                },
                                expression: "form.menuType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "M" } }, [
                                _vm._v("目录"),
                              ]),
                              _c("el-radio", { attrs: { label: "C" } }, [
                                _vm._v("菜单"),
                              ]),
                              _c("el-radio", { attrs: { label: "F" } }, [
                                _vm._v("按钮"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _vm.form.menuType != "F"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "菜单图标" } },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "bottom-start",
                                    width: "460",
                                    trigger: "click",
                                  },
                                  on: {
                                    show: function ($event) {
                                      return _vm.$refs["iconSelect"].reset()
                                    },
                                  },
                                },
                                [
                                  _c("IconSelect", {
                                    ref: "iconSelect",
                                    on: { selected: _vm.selected },
                                  }),
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        placeholder: "点击选择图标",
                                        readonly: "",
                                      },
                                      slot: "reference",
                                      model: {
                                        value: _vm.form.icon,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "icon", $$v)
                                        },
                                        expression: "form.icon",
                                      },
                                    },
                                    [
                                      _vm.form.icon
                                        ? _c("svg-icon", {
                                            staticClass: "el-input__icon",
                                            staticStyle: {
                                              height: "32px",
                                              width: "16px",
                                            },
                                            attrs: {
                                              slot: "prefix",
                                              "icon-class": _vm.form.icon,
                                            },
                                            slot: "prefix",
                                          })
                                        : _c("i", {
                                            staticClass:
                                              "el-icon-search el-input__icon",
                                            attrs: { slot: "prefix" },
                                            slot: "prefix",
                                          }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "菜单名称", prop: "menuName" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入菜单名称" },
                            model: {
                              value: _vm.form.menuName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "menuName", $$v)
                              },
                              expression: "form.menuName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "显示排序", prop: "orderNum" } },
                        [
                          _c("el-input-number", {
                            attrs: { "controls-position": "right", min: 0 },
                            model: {
                              value: _vm.form.orderNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "orderNum", $$v)
                              },
                              expression: "form.orderNum",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.form.menuType != "F"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "是否外链" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.isFrame,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isFrame", $$v)
                                    },
                                    expression: "form.isFrame",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "0" } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.form.menuType != "F"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "路由地址", prop: "path" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入路由地址" },
                                model: {
                                  value: _vm.form.path,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "path", $$v)
                                  },
                                  expression: "form.path",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.form.menuType == "C"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "组件路径", prop: "component" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入组件路径" },
                                model: {
                                  value: _vm.form.component,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "component", $$v)
                                  },
                                  expression: "form.component",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.form.menuType != "M"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "权限标识" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请权限标识",
                                  maxlength: "50",
                                },
                                model: {
                                  value: _vm.form.perms,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "perms", $$v)
                                  },
                                  expression: "form.perms",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.form.menuType != "F"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "显示状态" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.visible,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "visible", $$v)
                                    },
                                    expression: "form.visible",
                                  },
                                },
                                _vm._l(_vm.visibleOptions, function (dict) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: dict.dictValue,
                                      attrs: { label: dict.dictValue },
                                    },
                                    [_vm._v(_vm._s(dict.dictLabel))]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.form.menuType != "F"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "菜单状态" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "status", $$v)
                                    },
                                    expression: "form.status",
                                  },
                                },
                                _vm._l(_vm.statusOptions, function (dict) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: dict.dictValue,
                                      attrs: { label: dict.dictValue },
                                    },
                                    [_vm._v(_vm._s(dict.dictLabel))]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.form.menuType == "C"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "是否缓存" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.isCache,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isCache", $$v)
                                    },
                                    expression: "form.isCache",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "0" } }, [
                                    _vm._v("缓存"),
                                  ]),
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v("不缓存"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }