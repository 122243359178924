<!--药品元数据上传----卡片模块-->
<template>
  <div>
    <el-row
      v-for="(cell, index) in cellCardArr"
      :gutter="20"
      :key="index"
      :id="'cellRow' + index"
      align="middle"
      class="row-cell">
      <el-col
        v-for="(item, cardIndex) in cell"
        :key="item.recordId"
        :sm="12" :md="8" :lg="6" :xl="4"
        :class="cardListClass(cardIndex)"
        :style="cardStyle(cardIndex)">
        <el-card class="card-wrap" shadow="hover">
          <!-- <section v-if="Number(item.status) === 1" :class="tagHeader(item.status)">
            <img src="../../../../assets//images/institution/chuan.png" />
            <span>已上传</span>
          </section>
          <section v-else-if="Number(item.status) === 2" :class="tagHeader(item.status)">
            <img src="../../../../assets//images/institution/tohandle.png" />
            <span>待处理</span>
          </section>
          <section v-else-if="Number(item.status) === 3" :class="tagHeader(item.status)">
            <img src="../../../../assets//images/institution/pi.png" />
            <span>待匹配</span>
          </section>
          <section v-else-if="Number(item.status) === 4" :class="tagHeader(item.status)">
            <img src="../../../../assets//images/institution/cheng.png" />
            <span>匹配完成</span>
          </section> -->
          <section :class="tagHeader(item.status)">
            <img :src="statusShow(item).img" />
            <span>{{ statusShow(item).text}}</span>
          </section>
          <section class="card-content-section">
            <div :id="'chart' + item.recordId" :ref="'chart' + item.recordId" style="width: 120px;height: 120px"></div>
            <h3 class="total-number">{{ item.recordCount }}</h3>
            <div class="total-text">上传总数</div>
          </section>
          <section class="card-footer-section">
            <div class="item">
              <span class="circle"></span>
              <span class="item-label">上传时间：</span>
              <span class="item-text">{{ item.uploadTime }}</span>
            </div>
            <div class="item">
              <span class="circle"></span>
              <span class="item-label">最近操作：</span>
              <span class="item-text">{{ item.latestOperTime }}</span>
            </div>
            <div class="item">
              <span class="circle"></span>
              <span class="item-label">上传方式：</span>
              <span class="item-text item-way">{{ item.uploadType | uploadText}}</span>
            </div>
            <!--已上传-->
            <div v-if="Number(item.status) === 1" class="item-footer">
              <el-button class="see-btn" @click="matchViewClick(item)">查看</el-button>
              <el-button class="delete-btn" @click="deleteHandle(item)">删除</el-button>
            </div>
            <!--待匹配或待处理-->
            <div v-else-if="Number(item.status) === 2 || Number(item.status) === 3" class="item-footer">
              <el-button class="see-btn" @click="matchViewClick(item)">查看</el-button>
              <el-button class="delete-btn" @click="cancelHandle(item)">撤回</el-button>
            </div>
            <!--已撤回-->
            <div v-else-if="Number(item.status) === 5" class="item-footer">
              <el-button class="see-btn" @click="matchViewClick(item)">查看</el-button>
              <el-button class="reUpload-btn" @click="reUploadHandle(item)">重新上传</el-button>
              <el-button class="delete-btn" @click="stopHandle(item)">终止任务</el-button>
            </div>
            <!--已驳回-->
            <div v-else-if="Number(item.status) === 6" class="item-footer">
              <el-button class="see-btn" @click="matchViewClick(item)">查看</el-button>
              <el-button class="reUpload-btn" @click="reUploadHandle(item)">重新上传</el-button>
              <el-button class="delete-btn" @click="deleteHandle(item)">删除</el-button>
            </div>
            <div v-else class="item-footer">
              <el-button class="see-btn" @click="matchViewClick(item)">查看</el-button>
            </div>
          </section>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {
  deleteRecordApi,
  recallRecordApi,
  terminationRecordApi
} from '@/api/dm/drugMetadataUpload/drugMetadataUpload'
import { buildPieChart } from '@/utils/pieChart.js'
export default {
  props: {
    cardList: {
      type: Array,
      required: true
    }
  },
  filters: {
    /*statusShow(item) {
      // 1:已上传;2:待处理;3:待匹配;4:配置完成;5:已撤回;6:已驳回;7:已终止
      const obj = {
        text: '',
        img: ''
      }
      const val = item.status
      if (Number(val) === 1) {
        obj.text = '已上传'
        obj.img = require('../../../../assets//images/institution/chuan.png')
      } else if (Number(val) === 2) {
        obj.text = '待处理'
        obj.img = require('../../../../assets//images/institution/tohandle.png')
      } else if (Number(val) === 3) {
        obj.text = '待匹配'
        obj.img = require('../../../../assets//images/institution/pi.png')
      } else if (Number(val) === 4) {
        obj.text = '配置完成'
        obj.img = require('../../../../assets//images/institution/cheng.png')
      } else if (Number(val) === 5) {
        obj.text = '已撤回'
        obj.img = require('../../../../assets//images/institution/cheng.png')
      } else if (Number(val) === 6) {
        obj.text = '已驳回'
        obj.img = require('../../../../assets//images/institution/chuan.png')
      } else if (Number(val) === 7) {
        obj.text = '已终止'
        obj.img = require('../../../../assets//images/institution/chuan.png')
      }
      return obj
    },*/
    uploadText(val) {
      if (Number(val) === 1) {
        return 'WEB'
      } else if (Number(val) === 2) {
        return 'API'
      }
    }
  },
  computed: {
    statusShow(item) {
      // 1:已上传;2:待处理;3:待匹配;4:配置完成;5:已撤回;6:已驳回;7:已终止
      return function (item) {
        const obj = {
          text: '',
          img: ''
        }
        const val = item.status
        if (Number(val) === 1) {
          obj.text = '已上传'
          obj.img = require('../../../../assets//images/institution/chuan.png')
        } else if (Number(val) === 2) {
          obj.text = '待处理'
          obj.img = require('../../../../assets//images/institution/tohandle.png')
        } else if (Number(val) === 3) {
          obj.text = '待匹配'
          obj.img = require('../../../../assets//images/institution/pi.png')
        } else if (Number(val) === 4) {
          obj.text = '匹配完成'
          obj.img = require('../../../../assets//images/institution/cheng.png')
        } else if (Number(val) === 5) {
          obj.text = '已撤回'
          obj.img = require('../../../../assets//images/institution/recalled.png')
        } else if (Number(val) === 6) {
          obj.text = '已驳回'
          obj.img = require('../../../../assets//images/institution/rejected.png')
        } else if (Number(val) === 7) {
          obj.text = '已终止'
          obj.img = require('../../../../assets//images/institution/stopped.png')
        }
        return obj
      }
    },
    tagHeader() {
      return function (val) {
        if (Number(val) === 1) {
          // 已上传
          return 'uploaded-tag tag-header'
        } else if (Number(val) === 2) {
          // 待处理
          return 'handle-tag tag-header'
        } else if (Number(val) === 3) {
          // 待匹配
          return 'match-tag tag-header'
        } else if (Number(val) === 4) {
          // 匹配完成
          return 'finished-tag tag-header'
        } else if (Number(val) === 5) {
          // 已撤回
          return 'recalled-tag tag-header'
        } else if (Number(val) === 6) {
          // 已驳回
          return 'rejected-tag tag-header'
        } else if (Number(val) === 7) {
          // 已终止
          return 'stopped-tag tag-header'
        }
      }
    },
    cardListClass: function() {
      return function (index) {
        return `card-list-div${index}`
      }
    },
    cellCardStyle: {
      get() {
        let height = 0
        if (this.cardVisibleBoxH < this.cardContentH) {
        // 可视区域高度 < 卡片总内容高度
          height = `height: ${this.cardVisibleBoxH + 20}px`
        }
        return height
      },
      set(height) {
        return height
      }
    },
    cardStyle: function() {
      return function (index) {
        for (let i = 0; i < this.lastRowArr.length; i++) {
          if (index < this.lastRowArr[i]) {
            return 'margin-bottom: 10px'
          } else {
            // 最后一行不需要设置margin-bottom
            return 'margin-bottom: 0'
          }
        }
      }
    }
  },
  data() {
    return {
      cardVisibleBoxH: 0,
      cardContentH: 0,
      layoutNumber: 4, // 卡片列表每一行排列几个dom
      visibleRow: 1, // 可视区域卡片排几行
      // cardList: [],
      initCardList: [], // 搜索条件为全部的时候获取到的数据
      lastRowArr: [], // 最后一行的下标
      cellCardArr: [], // 二维数组
      isDeleted: false // 来判断cardList是被删除还是增加
    }
  },
  watch: {
    cardList(newVal, oldVal) {
      if (oldVal) {
        this.$nextTick(() => {
          this.numberByLayout()
          this.visibleCardShow()
          // 判断新数据是否在旧数据中
          if (newVal.length > this.initCardList.length) {
            this.isDeleted = false
          } else {
            for (let item of newVal) {
              if (this.initCardList.find(list => list.recordId === item.recordId)) {
                this.isDeleted = false
                break
              } else {
                this.isDeleted = true
              }
            }
          }
          // 新增，找到新增的那一个dom；删除，echarts不变
          // this.chartHandle() // 环形图生成
        })
      }
    }
  },
  async created() {
    this.initCardList = this.cardList
    this.numberByLayout()
    this.visibleCardShow()
  },
  mounted() {
    this.$nextTick(() => {
      this.chartHandle() // 环形图生成
      // this.cardHeightHandle() // 卡片高度以及可视区高度计算
      // this.cellCardStyle()
      let _this = this
      window.addEventListener('resize', () => {
        _this.numberByLayout()
        _this.visibleCardShow()
        _this.chartHandle() // 环形图生成
      }, false)
      // window.onresize = () => {
      //   this.numberByLayout()
      //   this.visibleCardShow()
      //   this.chartHandle() // 环形图生成
      // }
    })
  },
  updated() {
    if (!this.isDeleted) {
      this.chartHandle()
    }
  },
  beforeDestory() {
    window.removeEventListener('resize', this.numberByLayout)
    window.removeEventListener('resize', this.visibleCardShow)
    window.removeEventListener('resize', this.chartHandle)
  },
  beforeRouteLeave(to, from, next) {
    this.$destroy()
    next()
  },
  destroyed() {
  },
  methods: {
    onRemoveListenerMethod() {
      window.removeEventListener('resize', this.numberByLayout)
      window.removeEventListener('resize', this.visibleCardShow)
      window.removeEventListener('resize', this.chartHandle)
    },
    cellCardStyle() {
      if (this.cardVisibleBoxH < this.cardContentH) {
        // 可视区域高度 < 卡片总内容高度
        // return `height: ${this.cardVisibleBoxH}px`
        $('.row-cell').height(this.cardVisibleBoxH + 20)
      }
    },
    // 卡片高度计算
    cardHeightHandle() {
      let cardVisibleBoxH = $('.card-container').eq(0).height() // 卡片列表容器可视区高度
      let cardContentH = $('.card-list-box').eq(0).height() // 卡片容器总高度
      if (cardVisibleBoxH > cardContentH) {
        // 可视区域高度 > 卡片总内容高度
        this.visibleRow = Math.floor(cardVisibleBoxH / cardContentH)
      } else {
        this.visibleRow = Math.ceil(cardVisibleBoxH / cardContentH)
      }
      this.cardContentH = cardContentH
      this.cardVisibleBoxH = cardVisibleBoxH
    },
    // 卡片图表生成
    chartHandle() {
      let optionObj = {
        subtext: '',
        getvalue: []
      }
      // 获取每个卡片对应的图表容器
      for (let dom of this.cardList) {
        optionObj.getvalue = [dom.matchRatio]
        let option = buildPieChart(optionObj)
        let domById = document.getElementById(`chart${dom.recordId}`)
        if (!domById) {
          return
        }
        let chartDom = this.$echarts.init(domById)
        chartDom.setOption(option)
        window.onresize = function () {
          chartDom.resize()
        }
      }
    },
    // 计算layout布局一行排列几个
    numberByLayout() {
      // 768 992 1200 1920
      let width = window.innerWidth
      if (width < 768) {
        // xs
        this.layoutNumber = 1
      } else if (width >= 768 && width < 992) {
        // sm
        this.layoutNumber = 2
      } else if (width >= 992 && width < 1200) {
        // md
        this.layoutNumber = 3
      } else if (width >= 1200 && width < 1920) {
        // lg
        this.layoutNumber = 4
      } else if(width >= 1920) {
        // xl
        this.layoutNumber = 6
      }
      let len = this.cardList.length
      let rows = Math.ceil(len / this.layoutNumber)
      let prevRow = rows * this.layoutNumber
      let lastRowCount = len - ((rows - 1) * this.layoutNumber) // 最后一行排列的个数
      let lastRowStartIndex = this.layoutNumber * (rows - 1) // 最后一行开始的下标（从0开始）
      this.lastRowArr = [] // 用来存放最后一行元素的下标，从0开始
      for (let j = lastRowStartIndex; j < len; j++) {
        this.lastRowArr.push(j)
      }
    },
    // 处理排列的列表数据
    visibleCardShow() {
      // 二维数组
      const len = Math.ceil(this.cardList.length / this.layoutNumber)
      let arr = this.$deepClone(this.cardList)
      this.cellCardArr = []
      for (let i = 0; i < len; i++) {
        let arr1 = arr.splice(i * this.layoutNumber, this.layoutNumber)
        arr = this.$deepClone(this.cardList)
        this.cellCardArr.push(arr1)
      }
    },
    async fetchData() {
      let res = await recordCardListApi(this.queryParams)
      if (res.code === 200) {
        this.cardList = res.data.rows || []
        this.cardList.forEach(item => {
          item.matchRatio = item.matchRatio ? item.matchRatio : 0
        })
      }
    },
    // 删除卡片中的某一条记录调接口
    async deleteDataHandle(item) {
      const obj = {
        recordId: item.recordId
      }
      let res = await deleteRecordApi(obj)
      if (res.code === 200) {
        this.$message({
          message: '删除成功',
          type: 'success'
        })
      }
    },
    // 删除事件
    deleteHandle(item) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.deleteDataHandle(item)
        // await this.fetchData()
        this.$emit('initData')
      }).catch((action) => {
        if (action === 'cancel') {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        }
      })
    },
    // 申请撤销
    cancelHandle(item) {
      this.$confirm('是否撤回?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // 申请撤销功能下一版再做
        let res = await recallRecordApi(item)
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '已撤回'
          })
          this.$emit('initData')
        }
      }).catch((action) => {
        if (action === 'cancel') {
          this.$message({
            type: 'info',
            message: '已取消撤回'
          })
        }
      })
    },
    // 重新上传
    reUploadHandle(item) {
      this.$emit('reUpload', item)
    },
    // 终止任务
    stopHandle(item) {
      this.$confirm('是否终止?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // 申请撤销功能下一版再做
        let res = await terminationRecordApi(item)
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '已终止'
          })
          this.$emit('initData')
        }
      }).catch((action) => {
        if (action === 'cancel') {
          this.$message({
            type: 'info',
            message: '已取消终止任务'
          })
        }
      })
    },
    // 查看
    matchViewClick(item) {
      this.$router.push(`/drugMetadataUpload/list/matchView/${item.recordId}`)
    }
  }
}
</script>
<style scoped>
  .row-cell {
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }
</style>
