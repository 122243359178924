<template>
  <div class="app-container">
    <div ref="headerQuery">
      <el-row class="headerClass">
        <el-col :span="4" style="border-left: 5px solid #19B319;padding-left: 10px;">粉丝列表</el-col>
        <el-col :span="20">
          <el-form :model="queryParams" ref="queryForm" :inline="true" style="float: right">
<!--            <el-form-item label="昵称" prop="nickName">-->
<!--              <el-input v-model="queryParams.nickName"-->
<!--                        placeholder="请输入昵称"-->
<!--                        clearable-->
<!--                        style="width: 110px;height: 36px!important;line-height: 36px;"/>-->
<!--            </el-form-item>-->
            <el-form-item label="微信号" prop="wxNumber">
              <el-input v-model="queryParams.wxNumber"
                        placeholder="请输入微信号"
                        clearable
                        style="width: 150px;height: 36px!important;line-height: 36px;"/>
            </el-form-item>
            <el-form-item label="真实姓名" prop="realName">
              <el-input v-model="queryParams.realName"
                        placeholder="请输入真实姓名"
                        clearable
                        style="width: 150px;height: 36px!important;line-height: 36px;"/>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input v-model="queryParams.remark"
                        placeholder="请输入备注"
                        clearable
                        style="width: 150px;height: 36px!important;line-height: 36px;"/>
            </el-form-item>
            <el-form-item label="关注状态" prop="subscribe">
              <el-select
                v-model="queryParams.subscribe"
                placeholder="关注状态"
                clearable
                size="small"
                class="statusClass"
                style="width: 150px;"
              >
                <el-option
                  v-for="item in payattentionStateList"
                  :key="'payattentionStateList'+item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="群状态" prop="groupStatus">
              <el-select
                v-model="queryParams.groupStatus"
                placeholder="群状态"
                clearable
                size="small"
                class="statusClass"
                style="width: 150px;"
              >
                <el-option
                  v-for="item in intoGroupStateList"
                  :key="'intoGroupStateList'+item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="所在医院" prop="hospitalName">
              <el-input v-model="queryParams.hospitalName"
                        placeholder="请输入所在医院"
                        clearable
                        style="width: 150px;height: 36px!important;line-height: 36px;"/>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery()">查询</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <el-table
      ref="oraTableData"
      v-loading="loading"
      border
      stripe
      :height="tableHeight"
      :header-cell-style="{'text-align':'center'}"
      :cell-style="{'text-align':'center'}"
      :data="applyList">
      <el-table-column label="序号" prop="id" width="80" />
      <el-table-column label="OpenID" prop="openId" width="300" />
      <el-table-column label="昵称" width="150" prop="nickName" :show-overflow-tooltip="true"/>
      <el-table-column label="微信号" width="150" prop="wxNumber" :show-overflow-tooltip="true"/>
      <el-table-column label="真实姓名" width="150" prop="realName" :show-overflow-tooltip="true"/>
      <el-table-column label="医院名称" width="200" prop="hospitalName" :show-overflow-tooltip="true"/>
<!--      <el-table-column label="头像" prop="headImgUrl" width="80" />-->
      <el-table-column label="关注状态" align="center" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.subscribe === true" class="dsp">关注中</span>
          <span v-else-if="scope.row.subscribe === false" class="ytg">取消关注</span>
          <span v-else class="yjj">未知</span>
        </template>
      </el-table-column>
      <el-table-column label="关注时间" align="center" prop="subscribeTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.subscribeTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="取关时间" align="center" prop="unsubscribeTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.unsubscribeTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="群状态" align="center" width="200">
        <template slot-scope="scope">
          <section v-for="item in scope.row.groupStatusList">
<!--            未申请-->
            <span
              v-if="item.groupStatus === 0"
              class="wsq">
              {{ item.groupName }} [{{ item.groupStatusStr }}]
            </span>
<!--            申请进群-->
            <span
              v-else-if="item.groupStatus === 1"
              class="sqjq">
               {{ item.groupName }} [{{ item.groupStatusStr }}]
            </span>
<!--            审核通过-->
            <span
              v-else-if="item.groupStatus === 2"
              class="shtg">
               {{ item.groupName }} [{{ item.groupStatusStr }}]
            </span>
<!--            进群不通过-->
            <span
              v-else-if="item.groupStatus === 3"
              class="jqbtg">
               {{ item.groupName }} [{{ item.groupStatusStr }}]
            </span>
<!--            退群-->
            <span
              v-else-if="item.groupStatus === 4"
              class="tq">
               {{ item.groupName }} [{{ item.groupStatusStr }}]
            </span>
            <!--            管理员清理出群-->
            <span
              v-else-if="item.groupStatus === 5"
              class="glyqlcq">
               {{ item.groupName }} [{{ item.groupStatusStr }}]
            </span>
            <!--            已进群-->
            <span
              v-else-if="item.groupStatus === 6"
              class="yjq">
               {{ item.groupName }} [{{ item.groupStatusStr }}]
            </span>
          </section>
<!--          <span v-if="scope.row.statusStr === '待审批'" class="dsp">{{ scope.row.statusStr }}</span>-->
<!--          <span v-else-if="scope.row.statusStr === '已通过'" class="ytg">{{ scope.row.statusStr }}</span>-->
<!--          <span v-else-if="scope.row.statusStr === '已拒绝'" class="yjj">{{ scope.row.statusStr }}</span>-->
        </template>
      </el-table-column>
      <el-table-column label="申请提交时间" align="center" prop="groupApplyTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.groupApplyTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="申请处理完成时间" align="center" prop="groupDealTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.groupDealTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="备注" prop="remark" width="100" />
      <el-table-column label="信息最后更新时间" align="center" prop="updateTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.updateTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="120" fixed="right">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="editDocumentClick(scope.row)"
          >编辑</el-button>
          <el-button
            size="mini"
            type="text"
            style="color:#FF3333;"
            @click="handleDelete(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
      <template slot="empty" v-if="isResult === false">
        <img src="@/assets/images/no-booking.svg" alt="">
        <p>暂无数据</p>
      </template>
      <template slot="empty" v-else>
        <img src="@/assets/images/no-left-data.svg" alt="">
        <p>未查询到结果，请修改查询条件重试！</p>
      </template>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
  </div>
</template>

<script>
import {
  list,
  fansDelete
} from "@/api/frp/fansManagement";
import { getConfigKey } from '@/api/system/config'

export default {
  name: "fansManagement",
  data() {
    return {
      payattentionStateList:[
        {
          value: null,
          label: "全部"
        },
        {
          value: 1,
          label: "关注"
        },
        {
          value: 0,
          label: "未关注"
        }
      ],
      intoGroupStateList: [
        {
          value: null,
          label: "全部"
        },
        {
          value: 0,
          label: "未申请"
        },
        {
          value: 1,
          label: "申请进群"
        },
        {
          value: 2,
          label: "审核通过"
        },
        {
          value: 3,
          label: "进群不通过"
        },
        {
          value: 4,
          label: "自己退群"
        },
        {
          value: 5,
          label: "管理人员清理出群"
        },
        {
          value: 6,
          label: "已进群"
        }
      ],
      // 表格高度
      tableHeight: window.innerHeight - 100 - 100,
      // 查询参数
      queryParams: {
        subscribe: null,
        groupStatus: null,
        appId: null,
        // appId: 'wx3bf47442f04430d8', // 测试环境
        // appId: 'wxc80f952d4c2d4fa7', // 生产环境
        hospitalName: '',
        // nickName: '',
        realName: '',
        wxNumber: '',
        pageNum: 1,
        pageSize: 10,
        remark: ''
      },
      applyData: {
      },
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      // 角色表格数据
      applyList: [],
      // 是否显示弹出层
      isResult: false
    };
  },
  created() {
    this.getList() // list
    this.$nextTick(() => {
      this.setTableHeight()
    })
  },
  activated() {
    //激活时重新设置表格高度
    this.setTableHeight()
  },
  mounted() {
    // 获取高度值 （内容高+padding+边框）
    var _this=this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      _this.setTableHeight()
    }

  },
  methods: {
    /*获取当前环境appId*/
    async getEnvMsg(){
      let res = await getConfigKey("thys.weixin.appId")
      if (res.code === 200){
        this.queryParams.appId = res.msg
      }
    },
    /*设置表格的高度*/
    setTableHeight(){
      // 获取高度值 （内容高+padding+边框）
      let formHeight = this.$refs.headerQuery.offsetHeight
      console.log(formHeight)
      this.tableHeight = window.innerHeight - formHeight-150
    },
    /** 查询粉丝列表 */
    async getList() {
      this.loading = true;
      await this.getEnvMsg()
      let res = await list(this.queryParams)
      if (res.code === 200) {
        this.applyList = res.data.list
        this.total = res.data.totalCount
        this.loading = false
      }
    },
    // 编辑粉丝信息
    editDocumentClick(row) {
      this.$router.push({
        path: '/fansManagement/fansInformationEditing/' + row.id,
      })
    },
    // 删除数据
    handleDelete(row) {
      const id = row.id || ''
      // 是否确认删除字典编码为"' + dictCodes + '"的数据项?
      this.$confirm('是否确认删除该条数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        return fansDelete(id);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    },
    handleQuery() {
      this.isResult = true
      this.queryParams.pageNum = 1
      this.getList();
    }
  }
};
</script>

<style lang="scss" scoped>
.dsp{
  display: inline-block;
  background: #FFEBD6;
  border-radius: 3px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #E4860A;
  width: 58px;
  height: 26px;
  line-height: 26px;
}
.yjj{
  display: inline-block;
  background: #FFE0E5;
  border-radius: 3px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FF3333;
  width: 58px;
  height: 26px;
  line-height: 26px;
}
.ytg{
  display: inline-block;
  background: #D4FFCC;
  border-radius: 3px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #49B812;
  width: 58px;
  height: 26px;
  line-height: 26px;
}
.adoptClass {
  width: 110px;
  height: 32px;
  background: #6CCD3B;
  border-radius: 3px;
}

.refuseClass{
  width: 110px;
  height: 32px;
  background: #FF424C;
  border-radius: 3px;
}
.successClass{
  width: 110px;
  height: 32px;
  background: #2B60F8;
  border-radius: 3px;
}
.failClass{
  width: 110px;
  height: 32px;
  //background: #F3F5F9;
  border-radius: 3px;
}
.approvalContentDiv{
  margin-bottom: 10px;
}
.approvalContent .el-col{
  //width: 145px;
  //height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height:18px;
  padding:8px;
}
.approvalContentRight{
  color: #333333!important;
}
.wsq{
  color: #725777;
}
.sqjq{
  color: #E6AB17;
}
.shtg{
  color: #8D2EE5;
}
.jqbtg{
  color: #FF3333;
}
.tq{ // 退群
  color: #725777;
}
.glyqlcq{ // 管理员清理出群
  color: #725777;
}
.yjq{
  color: #6CCD3B;
}
</style>

<style lang="scss">
.approvalWrap .el-dialog__body {
  padding:20px 20px 30px!important;
}
</style>

