<template>
  <div class="app-container" style="overflow: auto">


    <div ref="headerQuery">
      <el-row class="headerClass">
        <el-col :span="4" style="border-left: 5px solid #0073E9;padding-left: 10px;">消息推送记录</el-col>
        <el-col :span="20">
          <el-form :model="queryParams" ref="queryForm" :inline="true" style="float: right">


            <el-form-item label="appId:" prop="appId">
              <el-input v-model="queryParams.appId" maxlength="50" clearable style="width: 250px;"></el-input>
            </el-form-item>

            <el-form-item label="消息Id:" prop="msgId">
              <el-input v-model="queryParams.msgId" maxlength="50" clearable style="width: 250px;"></el-input>
            </el-form-item>


            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">检索</el-button>
              <el-button icon="el-icon-position" size="small" class="addClass" @click="pushMsgTest">消息推送测试</el-button>
              <el-button icon="fa fa-cog" size="small" @click="columnSettings">设置</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>


    <div ref="tableContainer">
      <el-table
        border
        stripe
        ref="refundTable"
        :data="tableDataList"
        :height="tableHeight"
        v-loading="loading"
        @sort-change="tableSortChange"
        @row-contextmenu="rowContextmenu"
      >
        <el-table-column
          v-for="data in columnOptions"
          :key="'columnOptions'+data.id"
          :prop="data.prop"
          :label="data.name"
          :fixed="data.fixed"
          :resizable="false"
          :sortable="data.sortable"
          :min-width="data.width"
          :align="data.align"
        >
          <template slot-scope="scope">


            <div v-if="data.prop == 'pushStatus'">
              <el-tag type="danger" v-if="scope.row.pushStatus==0">失败</el-tag>
              <el-tag type="success" v-if="scope.row.pushStatus==1">成功</el-tag>
            </div>

            <div v-else-if="data.prop == 'msgContent'">
              <el-popover placement="top" title="全部消息内容"  trigger="click" width="600">
                <div style="border: 1px solid  #2B60F8">
                  <pre style="white-space: pre;">
                    {{ JSON.stringify( JSON.parse(scope.row.msgContent), null, 2) }}
                  </pre>
                </div>
                <span slot="reference" class="setTwoLine" style="color: #2B60F8;font-weight: 700;" title="点击查看全部消息内容">
                  {{ scope.row.msgContent }}
                </span>
              </el-popover>
            </div>

            <div v-else>
              <span>{{ scope.row[data.prop] }}</span>
            </div>
          </template>
        </el-table-column>

        <template slot="empty" v-if="tableDataList.length==0">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
    </div>

    <table-context-menu v-if="tableContextMenuVisible"
                        @foo="foo" ref="contextbutton"
                        :action="tableAction"
                        @handleTableAction="handleTableAction"
    ></table-context-menu>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="selectPushMsgLogList"
      :page-sizes="[10, 20, 50, 100]"
    />



    <el-dialog title="消息推送测试" width="730px"
               :visible.sync="pushMsgDialogVisible"
               :close-on-click-modal="false"
    >
      <el-form ref="pushMsgForm" status-icon :model="pushMsgFormData" label-width="140px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="appId:" prop="appId"
                          :rules="[{ required: true, message: '请输入appId', trigger: 'blur'}]"
            >
              <el-input placeholder="请输入appId" maxlength="50" clearable
                        v-model="pushMsgFormData.appId"
                        style="width: 500px;"
              />
            </el-form-item>


            <el-form-item label="消息xml:" prop="msgXml"
                          :rules="[{ required: true, message: '请输入消息xml', trigger: 'blur'}]"
            >
              <el-input type="textarea" :rows="6" placeholder="请输入消息xml"
                        v-model="pushMsgFormData.msgXml"
                        maxlength="5000" clearable show-word-limit
                        style="width: 500px;float: left;"
              />
            </el-form-item>

          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20" style="margin-top: 40px;">
            <el-button type="primary" size="small"
                       style="margin-left: 20px;background: #2B60F8;float:right;"
                       @click="confirmPush"
            >确定
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>


  </div>
</template>

<script>

import conceptConst from '@/const/conceptConst'
import AcrossUtil from '@/utils/acrossUtil'
import { UCgetConfig } from '@/utils/columnSettings'
import { GLOBAL_CONFIGURATION_CONFIGKEY } from '@/utils/constants'
import {
  pushMsgLogList,pushMsg
} from '@/api/system/apiGateway'

export default {
  name: 'PushMsgLog.vue',
  components: {},
  data() {
    return {
      conceptConst,
      // 遮罩层
      loading: true,
      tableDataList: [],
      // 总条数
      total: 0,
      // 表格高度
      tableHeight: window.innerHeight - 100 - 100,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        appId:undefined,
        msgId:undefined,
        searchValue: null,
        orderByColumn: undefined,
        isAsc: undefined
      },
      sltConfigKey: '',
      sltConfigClassify: '',
      columnOptions: [],
      tableOptions: [
        {
          id: 1,
          prop: 'appId',
          name: 'appId',
          fixed: true,
          show: true,
          sortable: 'custom',
          align: 'center',
          width: '100'
        },
        {
          id: 2,
          prop: 'msgId',
          name: '消息id',
          fixed: false,
          show: true,
          align: 'center',
          width: '100'
        },
        {
          id: 3,
          prop: 'msgContent',
          name: '推送消息内容',
          fixed: false,
          show: true,
          align: 'center',
          width: '100'
        },
        {
          id: 4,
          prop: 'url',
          name: '推送URl',
          fixed: false,
          show: true,
          align: 'center',
          width: '100'
        },
        {
          id: 5,
          prop: 'pushStatus',
          name: '推送状态',
          fixed: false,
          show: true,
          align: 'center',
          sortable: 'custom',
          width: '50'
        },
        {
          id: 6,
          prop: 'resultCode',
          name: '推送结果代码',
          fixed: false,
          show: true,
          align: 'center',
          sortable: 'custom',
          width: '80'
        },
        {
          id: 7,
          prop: 'resultMsg',
          name: '推送结果',
          fixed: false,
          show: true,
          align: 'center',
          width: '100'
        },
        {
          id: 8,
          prop: 'createTime',
          name: '创建时间',
          fixed: false,
          show: true,
          align: 'center',
          sortable: 'custom',
          width: '100'
        },
        {
          id: 9,
          prop: 'elapsedTime',
          name: '耗时',
          fixed: false,
          show: true,
          align: 'center',
          sortable: 'custom',
          width: '50'
        },
        {
          id: 10,
          prop: 'count',
          name: '重试次数',
          fixed: false,
          show: true,
          align: 'center',
          width: '50'
        },

      ],
      /*表格右键菜单显示隐藏*/
      tableContextMenuVisible: false,
      tableAction: [{ name: '修改' }, { name: 'API测试' }, { name: '删除', style: 'color: #FF3333;' }],
      pushMsgDialogVisible:false,
      pushMsgFormData:{
        appId:undefined,
        msgXml:undefined
      }


    }
  },
  created() {
    let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 72)
    this.sltConfigKey = item.configKey
    this.sltConfigClassify = item.configClassify
    this.getSettingTable().then(() => {
      this.$nextTick(() => {
        this.setTableHeight()
        //页面初始化完毕，默认触发一次排序
        this.$refs.refundTable.sort('createTime', 'descending')
      })
    })

  },
  activated() {
    //激活时重新设置表格高度
    this.setTableHeight()
  },
  mounted() {
    var _this = this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      AcrossUtil.$emit('resetTableHeight')
    }

    AcrossUtil.$on('resetTableHeight', function(param) {
      setTimeout(function() {
        _this.setTableHeight()
      }, 300)
    })

  },
  methods: {
    /*设置表格的高度*/
    setTableHeight() {
      // 获取高度值 （内容高+padding+边框）
      let formHeight = this.$refs.headerQuery.offsetHeight
      this.tableHeight = window.innerHeight - formHeight - 150
    },
    // 设置按钮-获取设置的数据
    async getSettingTable() {
      let _this = this
      let obj = {}
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 72)
      this.sltConfigKey = item.configKey
      this.sltConfigClassify = item.configClassify
      obj.configKey = this.sltConfigKey
      this.columnOptions = []
      let res = await UCgetConfig(obj)
      if (res.data && res.data.configValue) {
        let list = JSON.parse(res.data.configValue).list
        if (list.length > 0) {
          list.forEach(function(item) {
            if (item.show == undefined || item.show == true) {
              _this.columnOptions.push(item)
            }
          })
        } else {
          _this.msgError('获取数据表格配置信息失败')
        }
      } else {
        let defaultConfig = this.tableOptions
        defaultConfig.forEach(function(item) {
          if (item.show == undefined || item.show == true) {
            _this.columnOptions.push(item)
          }
        })
      }
      _this.$nextTick(() => {
        _this.$refs.refundTable.doLayout()
      })
    },
    // 设置
    async columnSettings() {
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 72)
      this.sltConfigKey = item.configKey
      this.sltConfigClassify = item.configClassify
      let _this = this
      _this.$ColumnSettings.showColSettings(
        _this.sltConfigKey,
        _this.sltConfigClassify,
        _this.tableOptions,
        _this.getSettingTable
      )
    },
    /*鼠标右键点击事件*/
    rowContextmenu(row, column, event) {
      this.tableContextMenuVisible = false
      this.tableContextMenuVisible = true
      // 阻止右键默认行为
      event.preventDefault()
      this.$nextTick(() => {
        this.$refs.contextbutton.init(row, column, event)
      })
    },
    /* 取消鼠标监听事件 菜单栏*/
    foo() {
      this.tableContextMenuVisible = false
      document.removeEventListener('click', this.foo)
    },
    /* 处理表格右键菜单点击*/
    handleTableAction(actionName, row, column) {
      /*if (actionName === '修改') {
        this.handleUpdate(row)
      } else if (actionName === '删除') {
        this.handleDelete(row)
      } else if (actionName === 'API测试') {
        this.handleApiTest(row)
      }*/
    },
    /*查询全部api列表 */
    async selectPushMsgLogList() {
      this.loading = true
      let response = await pushMsgLogList(this.queryParams)
      this.tableDataList = response.rows
      this.total = response.total
      this.loading = false
    },
    /*搜索按钮操作*/
    handleQuery() {
      this.isResult = true
      this.queryParams.pageNum = 1
      this.selectPushMsgLogList()
    },

    /*表格排序切换*/
    tableSortChange({ column, prop, order }) {
      if (order != null) {
        order = order == 'descending' ? 'desc' : 'asc'
      } else {
        order = ''
      }
      this.queryParams.orderByColumn = prop
      this.queryParams.isAsc = order
      this.handleQuery()
    },
    /*推送消息测试*/
    pushMsgTest(){
      this.pushMsgDialogVisible=true
      this.pushMsgFormData.appId=""
      this.pushMsgFormData.msgXml=""
    },
    confirmPush(){
      this.$refs['pushMsgForm'].validate(valid => {
        if (valid) {
          pushMsg(this.pushMsgFormData.appId,this.pushMsgFormData.msgXml).then(response => {
            this.msgSuccess(response.msg)
            this.pushMsgDialogVisible=false
            this.handleQuery()
          })
        } else {
          this.msgError('请规范填写')
        }
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.el-table .cell .el-form-item {
  margin-bottom: 0px;
}

</style>


<style>


</style>
