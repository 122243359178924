<template>
	<div class="refadd">
		<el-dialog
		:title="'新增关联列表'"
		:visible.sync="dialogVisible"
		width="60%"
		center
		@close="handleClose">
		<div>
			<div class="search">
				<el-input style="width: 300px" v-model="searchName"></el-input>
				<el-select @change="changeDrugType" style="margin-left: 10px;" v-model="selectedVal" placeholder="请选择">
					<el-option
						v-for="item in options"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
				<el-button @click="getTable" style="margin-left: 10px;" type="primary">查询</el-button>
			</div>
			<div style="margin-top: 20px;" class="tableList" ref="tableList">
				<el-table
					v-loading="loading"
					:data="tableData"
					highlight-current-row
					@selection-change="handleSelectionChange"
					border
					stripe
					ref="refTable"
					:height="300"
					style="width: 100%">
					<el-table-column
						type="selection"
						:selectable='selectable'
						width="50">
					</el-table-column>
					<el-table-column
						type="index"
						width="50">
					</el-table-column>
					<el-table-column
						v-for="(item, index) in tableList"
						:key="index"
						:prop="item.prop"
						:label="item.label">
					</el-table-column>
				</el-table>
			</div>
			<div class="pagination">
				<el-pagination
					background
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="currentPage"
					:page-sizes="[10, 20, 50, 100]"
					:page-size="pageSize"
					layout="total, sizes, prev, pager, next"
					:total="total">
				</el-pagination>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button @click="dialogVisible = false">取 消</el-button>
			<el-button type="primary" @click="confirm">确 定</el-button>
		</span>
	</el-dialog>
	</div>
</template>
<script>
import {getDrugGroupList, getDrugList, addRefData} from '../../../api/dkm/DDDApi'
export default {
	components: {
  },
	props: ['refaddVisible', 'DDDid', 'selectedID', 'associateTitle'],
  data() {
    return {
			dialogVisible: false,
			searchName: '',
			tableList: [{
				prop: 'id',
				label: '药物组ID'
			},{
				prop: 'name',
				label: '药物组名称'
			},{
				prop: 'includeDrug',
				label: '包含药物列表'
			}],
			options: [
				{
				label: '药物',
				value: '3'
			},{
				label: '药物组',
				value: '2'
			}],
			selectedVal: '2',
			loading: false,
      tableData: [],
      total: 0,
      pageSize: 20,
      currentPage: 1,
			selectedTableList: []
    }
  },
	watch: {
		refaddVisible(newVal) {
			this.dialogVisible = newVal
      this.selectedVal ='2'
			if(newVal) {
				this.searchName = this.associateTitle
        this.changeDrugType()
				// this.tableList = [{
				// 	prop: 'id',
				// 	label: '药物ID'
				// },{
				// 	prop: 'drugGroupName',
				// 	label: '药物名称'
				// },{
				// 	prop: 'classifyDesc',
				// 	label: '中西药标志'
				// },{
				// 	prop: 'substanceName',
				// 	label: '药物成分'
				// }]
				this.getTable()
			}
		},
	},
  mounted() {
  },
  created() {
  },
  methods: {
		selectable(row,rowIndex) {
      //索引是从0开始，条件1是指只有第2行数据不被禁用
			if(this.selectedID.indexOf(row.id) == -1) {
				return true
			} else {
				return false
			}
		},
		handleSelectionChange(val) {
			this.selectedTableList = []
			val.forEach( item =>{
				this.selectedTableList.push(item.id)
			})
		},
		changeDrugType() {
			if(this.selectedVal == '2') {
				this.tableList = [{
					prop: 'id',
					label: '药物组ID'
				},{
					prop: 'name',
					label: '药物组名称'
				},{
					prop: 'includeDrug',
					label: '包含药物列表'
				}]
			} else {
				this.tableList = [{
					prop: 'id',
					label: '药物ID'
				},{
					prop: 'drugGroupName',
					label: '药物名称'
				},{
					prop: 'classifyDesc',
					label: '中西药标志'
				},{
					prop: 'substanceName',
					label: '药物成分'
				}]
			}
			this.currentPage = 1
			this.getTable()
		},
		async getTable() {
			const params = {
				searchValue: this.searchName,
				pageNum: this.currentPage,
				pageSize: this.pageSize,
				searchValueSearchType: 1,
				orderByColumn: 'id',
				isAsc: 'desc'
			}
			this.tableData = []
			this.loading = true
			let res = ''
			if(this.selectedVal == '2') {
				res = await getDrugGroupList(params)
			} else {
				res = await getDrugList(params)
			}
			this.loading = false
			if(res.code == 200) {
				this.total = res.total
				this.tableData = res.rows
				if(this.selectedVal == '2') {
					this.tableData.forEach(element => {
						element.includeDrug = element.dkbDrugGroupMappingList.map(function(obj,index){
							return `[${obj.drugId}]-${obj.drugName}`
						}).join(";")
					})
				} else {
					this.tableData.forEach(element => {
						element.substanceName = element.dkbDrugIngredientList.map(function(obj,index){
							return `${obj.substanceName}`
						}).join(";")
					})
				}
				this.$nextTick( () =>{
					this.tableData.forEach(element => {
						this.selectedID.forEach(item =>{
							if(item == element.id) {
								this.$refs.refTable.toggleRowSelection(element)
							}
						})
					})
				})
			}
		},
		async confirm() {
			if(this.selectedTableList.length == 0) {
				this.$message({
					type: 'error',
					message: '请选择数据'
				})
				return
			}
			const params = {
				dddId: this.DDDid,
				refIdList: this.selectedTableList,
				refType: this.selectedVal=='2' ? '2' : '1'
			}
			let res = await addRefData(params)
			if(res.code == 200) {
				this.searchName = ''
				this.selectedVal = '3'
				this.pageSize = 20
				this.currentPage = 1
				this.$message({
					type: 'success',
					message: '新增成功!'
				})
				this.$emit('confirmrefAddForm')
			}
		},
		handleClose() {
			this.searchName = ''
			this.selectedVal = '3'
			this.pageSize = 20
			this.currentPage = 1
			this.$emit('closerefAddForm')
		},
		handleSizeChange(val) {
      this.pageSize = val
      this.getTable()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getTable()
    },
  }
}
</script>
<style lang="scss" scoped>
.refadd {
	.search {
		display: flex;
	}
	.pagination {
    //margin-top: 20px;
    border-top: unset;
    flex: 0 0 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #EBEEF5;
    border-radius: 0px 0px 8px 8px;
  }
}
</style>
