<template>
  <div class="login">
    <div class="loginLeftTop">
      <img src="../assets/images/pdBox-login.png" alt=""/>
    </div>
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
      <!-- <h1 class="title" style="font-size: 26px;">PDBox-药数矩阵</h1>
      <h3 class="title" style="font-size: 14px;">Pharmaceutical Data Factory</h3> -->
      <h1 class="title" style="font-size: 26px;">药数矩阵</h1>
      <h3 class="title" style="font-size: 14px;">PDBox</h3>
      <el-form-item prop="username" style="margin-top:30px;">
        <el-input v-model="loginForm.username" type="text" auto-complete="off" placeholder="账号">
          <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" />
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          show-password
          v-model="loginForm.password"
          type="password"
          auto-complete="off"
          placeholder="密码"
          @keyup.enter.native="handleLogin"
        >
          <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
        </el-input>
      </el-form-item>
      <el-form-item  style="display: none">
        <el-input
          v-model="loginForm.code"
          auto-complete="off"
          placeholder="验证码"
          style="width: 63%"
          @keyup.enter.native="handleLogin"
        >
          <svg-icon slot="prefix" icon-class="validCode" class="el-input__icon input-icon" />
        </el-input>
        <div class="login-code">
          <img :src="codeUrl" @click="getCode" class="login-code-img"/>
        </div>
      </el-form-item>
      <div style="display: flex;justify-content: space-between;align-items: center;">
        <div :span="12">
          <el-checkbox v-model="loginForm.rememberMe">记住密码</el-checkbox>
        </div>
        <!-- <div :span="12">
          <el-button type="text" @click="forgetPas">忘记密码？</el-button>
        </div> -->
      </div>
      <el-form-item style="width:100%;">
        <el-button
          :loading="loading"
          size="medium"
          type="primary"
          style="width:100%;
          background: linear-gradient(90deg, #00AAFF, #2B60F8);
          box-shadow: 0px 4px 8px 0px rgba(40, 128, 215, 0.5);
          opacity: 0.5;
          border-radius: 4px;"
          @click.native.prevent="handleLogin">
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>
      </el-form-item>
      <el-form-item style="width:100%;">
        <el-tooltip placement="top" effect="light">
          <div slot="content">若无账号，请先进行机构注册申请，注册成功后，账号将通过短信及电子邮箱发送给您！</div>
          <!-- <el-button>Top center</el-button> -->
          <el-link icon="el-icon-info" @click="registryHandle">机构注册</el-link>
        </el-tooltip>
        <!-- <el-link icon="el-icon-info">机构注册</el-link>
        <el-button type="text">机构注册</el-button> -->
      </el-form-item>
    </el-form>
    <!--  底部  -->
    <div class="el-login-footer">
      <span>Copyright © 2018-2021 winning All Rights Reserved.</span>
    </div>
  </div>
</template>

<script>
import { getCodeImg } from "@/api/login";
import Cookies from "js-cookie";
import { encrypt, decrypt } from '@/utils/jsencrypt'

export default {
  name: "Login",
  data() {
    return {
      codeUrl: "",
      cookiePassword: "",
      loginForm: {
        username: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: ""
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "用户名不能为空" }
        ],
        password: [
          { required: true, trigger: "blur", message: "密码不能为空" }
        ],
        code: [{ required: true, trigger: "change", message: "验证码不能为空" }]
      },
      loading: false,
      redirect: undefined
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created() {
    //this.getCode();
    Cookies.remove("Ip-Admin-Token")
    this.getCookie();
  },
  methods: {
    // 机构注册
    registryHandle() {

    },
    // 忘记密码
    forgetPas() {

    },
    getCode() {
      getCodeImg().then(res => {
        this.codeUrl = "data:image/gif;base64," + res.img;
        this.loginForm.uuid = res.uuid;
      });
    },
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const rememberMe = Cookies.get('rememberMe')
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password: password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
      };
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          if (this.loginForm.rememberMe) {
            Cookies.set("username", this.loginForm.username, { expires: 30 });
            Cookies.set("password", encrypt(this.loginForm.password), { expires: 30 });
            Cookies.set('rememberMe', this.loginForm.rememberMe, { expires: 30 });
          } else {
            Cookies.remove("username");
            Cookies.remove("password");
            Cookies.remove('rememberMe');
          }
          this.$store.dispatch("Login", this.loginForm).then(() => {
            this.$router.push({ path: this.redirect || "/" }).catch(()=>{});
          }).catch(() => {
            this.loading = false;
            this.getCode();
          });
        }
      });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  //height: 100%;
  background-image: url("../assets/images/login-back.png");
  //background-size: cover;
  position: relative;
  background-size: 100% 100%;
  //position: relative;
  position: fixed;
  width: 100%;
  height: 100%;
}
.loginLeftTop{
  position: absolute;
  top:55px;
  left: 83px;
}
.title {
  margin: 0px auto;
  text-align: center;
  font-family: PingFang SC;
  font-weight: 500;
  color: #2B60F8;
  text-shadow: 1px 1px 2px rgba(53, 98, 208, 0.5);
}

.login-form {
  margin-left: 40%;
  background: #F0F4FF;
  border: 9px solid rgba(103, 144, 246, 0.58);
  box-shadow: 0px 0px 20px 0px rgba(4, 8, 17, 0.8);
  border-radius: 8px;
  width: 400px;
  padding: 25px 25px 5px 25px;
  .el-input {
    height: 38px;
    input {
      height: 38px;
    }
  }
  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}
.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}
.login-code {
  width: 33%;
  height: 38px;
  float: right;
  img {
    cursor: pointer;
    vertical-align: middle;
  }
}
.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}
.login-code-img {
  height: 38px;
}

</style>
