<template>
  <div class="app-container">
    <div ref="headerQuery">
      <el-row class="headerClass">
        <el-col :span="4" class="title">组合包装药品管理</el-col>
        <el-col :span="20">
          <el-form :model="queryParams"  class="query-form" ref="queryForm" :inline="true" style="float: right">
            <el-form-item label="">
              <el-autocomplete
                v-model="queryParams.searchValue"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入药品ID/名称"
                @select="handleQuery"
                @clear="setBlur()"
                clearable
                size="small"
                value-key="drugName"
                style="width: 300px;height: 36px!important;line-height: 36px;"
                @keydown.enter.native="handleQuery"
              >
                <el-select v-model="queryParams.searchValueSearchType"
                           style="width: 74px;"
                           slot="prepend" placeholder="请选择">
                  <el-option label="模糊" value="1"></el-option>
                  <el-option label="精确" value="2"></el-option>
                  <el-option label="前缀" value="3"></el-option>
                </el-select>
              </el-autocomplete>
            </el-form-item>

            <el-form-item label="" prop="status">
              <el-select
                v-model="queryParams.status"
                placeholder="状态"
                size="small"
                class="statusClass"
                style="width: 74px"
                clearable
              >
                <el-option
                  v-for="dict in statusOptions"
                  :key="dict.conceptId"
                  :label="dict.conceptVal"
                  :value="dict.conceptId"
                />
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">检索</el-button>
              <el-button icon="el-icon-circle-plus-outline" size="small" class="addClass" @click="handleAdd">新增药品</el-button>
              <el-button icon="fa fa-cog" size="small" @click="columnSettings">设置</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>


    <div ref="tableContainer" id="drugProdDiv">
      <el-table
        border
        stripe
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        :height="tableHeight" v-loading="loading"
        :data="drugList"
        row-key="id"
        :expand-row-keys="drugTableExpandRowIds"
        @expand-change="expandTableRow"
        ref="refTable"
        @sort-change="tableSortChange"
        @row-contextmenu="rowContextmenu"
        :row-class-name="tableRowClass"
      >

        <el-table-column type="expand" width="45" fixed="left">
          <template slot-scope="props">
            <el-table
              border
              stripe
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              :data="props.row.drugProdList"
              v-loading="loading"
              :cell-class-name="tableCellClass" >
              <el-table-column property="id" label="药品ID"  width="100"></el-table-column>
              <el-table-column label="药品名称" width="300">
                <template slot-scope="scope">
                  <span style="margin-right: 5px;">{{scope.row.drugProdName}}</span>
                  <span v-for="item in scope.row.drugCategoryList"
                        :key="item.id"
                  >
                    <svg-icon :icon-class="item.icon" v-if="item.icon"/>
                  </span>
                </template>
              </el-table-column>
              <el-table-column property="approvalNum" label="批准文号" width="200"></el-table-column>
              <el-table-column property="manufacturerName" label="生产企业" width="200"></el-table-column>
              <el-table-column property="specDesc" label="规格" width="200"></el-table-column>
              <template slot="empty">
                <img src="@/assets/images/no-booking.svg" alt="">
                <p>暂无数据</p>
              </template>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column
          v-for="data in columnOptions"
          :key="data.id"
          :prop="data.prop"
          :label="data.name"
          :fixed="data.fixed?data.fixed:false"
          :resizable="false"
          :sortable="data.sortable?data.sortable:false"
          :min-width="data.width?data.width:'80'"
          :align="data.align?data.align:'center'"
        >
          <template slot-scope="scope">
            <div v-if="data.prop == 'indate'">
              {{ scope.row.indate }} {{ scope.row.indateUnitDesc }}
            </div>
            <div v-else-if="data.prop == 'drugName'">
              <span style="margin-right: 5px;">{{ scope.row[data.prop] }}</span>
              <span v-for="item in scope.row.drugCategoryList" :key="item.id">
              <svg-icon :icon-class="item.icon" v-if="item.icon"/>
            </span>
            </div>

            <div v-else-if="data.prop == 'specDesc'">
              {{ scope.row.packQty+'/'+scope.row.specUnitDesc+'/'+scope.row.packQtyUnitDesc }}
            </div>

            <div v-else-if="data.prop == 'pdProdMatList'">
              <P style="text-align: left" v-for="(item,index) in scope.row.pdProdMatList" :key="item.id+'pdProdMatList'">
                {{'['+(index+1)+']'}}{{ item.wrapperDesc}}
                <span v-show="!isEmpty(item.stateMedicareNum)" style="margin-left: 10px;">国家医保代码:{{ item.stateMedicareNum}}</span>
                <span v-show="!isEmpty(item.shMedicareNum)" style="margin-left: 10px;">上海医保代码:{{ item.shMedicareNum}}</span>
                <span v-show="index<scope.row.pdProdMatList.length-1">;</span>
              </P>
            </div>
            <div v-else>
              <span>{{ scope.row[data.prop] }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="状态" align="center" key="status" width="100" fixed="right">
          <template slot-scope="scope">
            <el-switch
              class="switch"
              active-color="#5DB730"
              active-text='启用'
              inactive-text='停用'
              inactive-color="#B5CCF5"
              v-model="scope.row.status"
              active-value="0"
              inactive-value="1"
              @change="handleStatusChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>


        <template slot="empty" v-if="isResult === false">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
    </div>

    <table-context-menu v-if="tableContextMenuVisible"
                        @foo="foo" ref="contextbutton"
                        :action="tableAction"
                        @handleTableAction="handleTableAction"></table-context-menu>


    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getComboDrugList"
      :page-sizes="[10, 20, 50, 100]"
    />


    <el-dialog v-dialogDrag title="引用来源管理" :visible.sync="resourceTableVisible">
      <el-row>
        <el-col :span="24" style="margin-bottom: 10px;">
          <el-button style="float: right;margin-right: 10px;" icon="el-icon-circle-plus-outline"
                     size="small" @click="addResource"
          >添加
          </el-button>
        </el-col>
      </el-row>
      <el-form ref="resourceTableForm" status-icon :model="resourceTableForm">
        <el-table
          border
          stripe
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
          :data="resourceTableForm.list">
          <el-table-column property="date" label="引用资源类型" align="center" width="180">
            <template slot-scope="scope">
              <el-form-item
                :prop="'list.' + scope.$index + '.'+'resourceTypeId'"
                :rules="[{ required: true, message: '请选择物质名称', trigger: 'blur'}]"
              >
                <el-select
                  v-model="scope.row.resourceTypeId"
                  placeholder="引用资源类型"
                  size="small"
                  style="width: 150px"
                  @change="resourceTypeChange($event,scope.$index)"
                >
                  <el-option
                    v-for="dict in resourceTypeOption"
                    :key="'resourceTypeOption'+dict.id"
                    :label="dict.typeName"
                    :value="dict.id"
                  />
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column property="name" align="center" label="引用资源名称">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.resourceId"
                filterable
                remote
                style="width:300px"
                reserve-keyword
                placeholder="请输入关键词"
                @change="resourceNameChange($event,scope.$index)"
                :remote-method="(query)=>{resourceNameQuery(query,scope.row.resourceTypeId)}"
                :loading="loading"
                :disabled="!scope.row.resourceTypeId?true:false"
              >
                <el-option
                  v-for="item in resourceDataOption"
                  :key="'resourceDataOption'+item.id"
                  :label="item.name"
                  :value="item.id"
                />
                <el-option
                  v-for="item in [scope.row]"
                  :key="item.resourceId+'original'"
                  :label="item.resourceName"
                  :value="item.resourceId"
                />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="100">
            <template slot-scope="scope">

              <el-button
                size="mini"
                type="text"
                style="color: #FF3333;"
                @click="handleResourceDelete(scope.$index,scope.row)"
              >删除
              </el-button>
            </template>
          </el-table-column>
          <template slot="empty">
            <img src="@/assets/images/no-booking.svg" alt="">
            <p>暂无数据</p>
          </template>
        </el-table>
      </el-form>
      <el-row>
        <el-col :span="24" style="margin-top: 10px;">
          <el-button type="primary" style="float: right;margin-right: 10px;"
                     size="small" @click="saveResource"
          >保存
          </el-button>
          <el-button style="float: right;margin-right: 10px;"
                     size="small" @click="resourceTableVisible=false"
          >取消
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { comboDrugList,delComboDrug,updateComboDrugStatus,comboDrugInfo,selectComboDrugNameList } from '@/api/dkm/pkgDrugProd'
import { getConcept } from '@/utils/concept'
import conceptConst from '@/const/conceptConst'
import { isEmpty } from '@/utils/common'
import { UCgetConfig } from '@/utils/columnSettings'
import { GLOBAL_CONFIGURATION_CONFIGKEY } from '@/utils/constants'
import { getResourceCategory, getReferenceInfo } from '@/utils/resourceReference'
import { selectReferenceInfo, saveOrUpdateInfoList, resourceNameQuery } from '@/api/dkm/resource'
import tableConst from '@/const/tableConst'
import { setCache, getCache } from '@/utils/cacheUtil'
import AcrossUtil from '@/utils/acrossUtil'
export default {
  name: 'PkgDrugProd',
  data() {
    return {
      conceptConst,
      tableConst,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        searchValue: null,
        searchValueSearchType:"1",
        orderByColumn:undefined,
        isAsc:undefined,
      },
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      // 药品表格数据
      drugList: [],
      // 药品表格 展开行id
      drugTableExpandRowIds:[],
      // 表格高度
      tableHeight: window.innerHeight - 100 - 100,
      // 状态数据字典
      statusOptions: [],
      /*药品列表-表格参数配置*/
      sltConfigKey: '',
      sltConfigClassify: '',
      columnOptions: [],
      tableOptions: [
        {
          id: 1,
          prop: 'id',
          name: '包装药品ID',
          show: true,
          sortable:'custom',
          align: 'center',
          width: '100'
        },
        {
          id: 2,
          prop: 'drugName',
          name: '包装药品名称',
          show: true,
          sortable: false,
          align: 'center',
          width: '400'
        },
        {
          id: 3,
          prop: 'specDesc',
          name: '数量',
          show: true,
          sortable: false,
          align: 'center',
          width: '100'
        },
        {
          id: 4,
          prop: 'pdProdMatList',
          name: '包装材料',
          show: true,
          sortable: false,
          align: 'center',
          width: '400'
        },
        {
          id: 5,
          prop: 'updateTime',
          name: '更新时间',
          fixed: false,
          show: true,
          sortable:  'custom',
          align: 'center',
          width: '150'
        }
      ],
      /* 控制引用来源表格弹窗显示隐藏*/
      resourceTableVisible: false,
      /*来源类型下拉列表*/
      resourceTypeOption: [],
      resourceDataOption: [],
      /*当前操作表格行数据*/
      editTableRow: [],
      /* 来源引用表格数据*/
      resourceTableForm: {
        list: []
      },
      isResult: false,
      /*表格右键菜单显示隐藏*/
      tableContextMenuVisible:false,
      tableAction:[{name:"修改"},{name:"来源"},{name:"删除",style:'color: #FF3333;'}]
    }
  },

  created() {
    this.getSettingTable().then(() => {
      this.$nextTick(() => {
        this.setTableHeight()
        //页面初始化完毕，默认触发一次按照更新时间排序
        this.$refs.refTable.sort('id','descending');
      })
    })
    //获取状态数据字典
    getConcept(this.conceptConst.PUB_DATA_STATUS, 0).then(result => {
      this.statusOptions = result
    })
    //获取引用资源类型
    getResourceCategory().then(result => {
      this.resourceTypeOption = result
    })
    //this.initAllDict()
  },
  activated() {
    //激活时重新设置表格高度
    this.setTableHeight()
  },
  mounted() {
    var _this=this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      AcrossUtil.$emit('resetTableHeight');
    }
    /*监听编辑页面发送的事件，执行相应的函数*/
    AcrossUtil.$on('tableReload', function (param) {
      _this.getComboDrugList()
    })

    AcrossUtil.$on('resetTableHeight', function (param) {
      setTimeout(function () {
        _this.setTableHeight()
      }, 300);
    })

  },
  methods: {
    /*设置表格的高度*/
    setTableHeight(){
      // 获取高度值 （内容高+padding+边框）
      if(this.$refs.headerQuery){
        let formHeight = this.$refs.headerQuery.offsetHeight
        this.tableHeight = window.innerHeight - formHeight-140
      }

    },
    /** 新增按钮操作 */
    handleAdd() {
      this.$router.push('/pkgDrugProd/edit/')
    },
    handleDelete(row){
      let that = this
      this.$confirm('确认要删除' + '"[' + row.id + ']-' + row.drugName + '"吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        delComboDrug(row.id).then(response => {
          that.msgSuccess('删除成功')
          that.getComboDrugList()
        })
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.isResult = true
      this.queryParams.pageNum = 1
      this.getComboDrugList()
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.$router.push('/pkgDrugProd/edit/' + row.id)
    },
    /**药品名称检索*/
    querySearchAsync(queryString, cb) {
      if (!isEmpty(queryString)) {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          selectComboDrugNameList(queryString.trim()).then(response => {
            cb(response.data)
          })
        }, 200)
      } else {
        cb([])
      }
    },
    /** 查询组合包装药品列表 */
    getComboDrugList() {
      this.loading = true
      comboDrugList(this.queryParams).then(response => {
        this.queryParams.total=response.total
        setCache("pkgDrugProdQueryParams",this.queryParams)
        setCache("pkgDrugProdPageData",response.rows)
        this.drugList = response.rows
       this.transitionTableData()
        this.total = response.total
        this.loading = false
      })
    },
    /** 对获取到的表格数据预处理 */
    transitionTableData() {
      this.drugList.forEach((item) => {

      })
    },
    /*组合包装药品状态修改*/
    handleStatusChange(row) {
      let text = row.status === '0' ? '启用' : '停用'
      this.$confirm('确认要' + text + '组合包装药品[' + row.drugName + ']?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        return updateComboDrugStatus(row.id,row.status)
      }).then(() => {
        this.msgSuccess(text + '成功')
        //关闭表格所有展开行展开行
        this.drugTableExpandRowIds=[]
      }).catch(function() {
        row.status = row.status === '0' ? '1' : '0'
      })
    },
    /*表格 行样式*/
    tableRowClass({row, rowIndex}){
     if(row.drugProdSize==0){
        //隐藏表格展开折叠行图标
        return 'hidden-expand';
      }
      return '';
    },
    /* 展开表格行事件*/
    expandTableRow(row, expandedRows) {
      let table=this.$refs.refTable
      let that=this
      expandedRows.forEach(function(item){
        if(item.id==row.id){
          //获取组合包装药品包含的药品信息
          comboDrugInfo(row.id).then(response => {
            if (response.data && response.data.drugProdList) {
              row.drugProdList =response.data.drugProdList
              that.initComboSpecList(row.drugProdList)
              //展开行
              table.toggleRowExpansion(item,true)
            } else {
              that.msgError('获取药品信息失败')
            }
          })
        }else{
          //关闭行
          //table.toggleRowExpansion(item)
        }
      })
    },
   /* 处理获取到的组合包装药品包含的药品信息*/
    initComboSpecList(drugProdList){
      if (drugProdList) {
        drugProdList.forEach(function(dkbDp) {
          if (dkbDp) {
            // 药品名称
            dkbDp.drugProdName =''
            if (dkbDp.dkbDpAliasList) {
              dkbDp.dkbDpAliasList.forEach(function(item, index) {
                if (item.isPrimary === '1') {
                  dkbDp.drugProdName = item.alias
                }
              })
            }


            // 批准文号
            dkbDp.approvalNum = ''
            if (dkbDp.dkbDpApprovalNumList) {
              dkbDp.dkbDpApprovalNumList.forEach(function(item, index) {
                dkbDp.approvalNum += item.approvalNum + ';'
              })
              dkbDp.approvalNum=dkbDp.approvalNum.substr(0,dkbDp.approvalNum.length-1)
            }

            // 规格描述
            dkbDp.specDesc = ''
            if (dkbDp.dkbDpSpecDescList) {
              dkbDp.dkbDpSpecDescList.forEach(function(item, index) {
                dkbDp.specDesc += item.specDesc + ';'
              })
              dkbDp.specDesc=dkbDp.specDesc.substr(0,dkbDp.specDesc.length-1)
            }

            //生产企业
            dkbDp.manufacturerName=''
            if (dkbDp.dkbDpManufacturerList) {
              dkbDp.dkbDpManufacturerList.forEach((manufacturerItem) => {
                if (manufacturerItem.manufacturerName) {
                  dkbDp.manufacturerName += manufacturerItem.manufacturerName+";"
                }
              })
              dkbDp.manufacturerName=dkbDp.manufacturerName.substr(0,dkbDp.manufacturerName.length-1)
            }
          }
        })
      }
    },
    /*组合包装药品--明细表格--停用药品样式*/
    tableCellClass({row, column, rowIndex, columnIndex}){
      // 停用的药品，药品名称加删除线
      if( row.status==='1' && column.property==='drugProdName' ){
        return 'disableData-row';
      }
      return '';
    },
    // 设置按钮-获取设置的数据
    async getSettingTable () {
      let _this = this
      let obj = {}
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 11)
      this.sltConfigKey = item.configKey // 药物管理-药物列表-表格配置
      this.sltConfigClassify = item.configClassify // 配置分类
      obj.configKey = this.sltConfigKey
      this.columnOptions = []
      let res = await UCgetConfig(obj)
      if (res.data  && res.data.configValue) {
        let list = JSON.parse(res.data.configValue).list
        if (list.length > 0) {
          list.forEach(function (item) {
            if (item.show==undefined || item.show==true) {
              _this.columnOptions.push(item)
            }
          })
        }else {
          _this.msgError('获取数据表格配置信息失败')
        }
      }else{
        let defaultConfig=this.tableOptions
        defaultConfig.forEach(function (item) {
          if (item.show==undefined || item.show==true) {
            _this.columnOptions.push(item)
          }
        })
      }
      _this.$nextTick(() => {
        _this.$refs.refTable.doLayout()
      })
    },
    // 设置
    async columnSettings() {
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 11)
      this.sltConfigKey = item.configKey
      this.sltConfigClassify = item.configClassify
      let _this = this
      _this.$ColumnSettings.showColSettings(
        _this.sltConfigKey,
        _this.sltConfigClassify,
        _this.tableOptions,
        _this.getSettingTable
      )
    },
    /*来源按钮点击*/
    handleResource(row){
      this.editTableRow = row
      this.resourceTableForm.list = []
      //查询获取引用资源信息
      selectReferenceInfo(tableConst.DKB_PD_PROD, row.id, '').then(response => {
        this.resourceTableForm.list = response.data
        this.resourceTableVisible = true
        if (response.data.length > 0) {
          //还原表单验证
          this.resetForm('resourceTableForm')
        }

      })
    },
    /*添加一个来源引用*/
    addResource() {
      this.resourceTableForm.list.push({
        sourceTable: tableConst.DKB_PD_PROD,
        sourceTableField: '',
        sourceTableId: this.editTableRow.id,
        resourceTypeId: null,
        resourceId: null
      })
    },
    /* 保存来源引用*/
    saveResource() {
      this.$refs['resourceTableForm'].validate(valid => {
        if (valid) {
          saveOrUpdateInfoList(this.resourceTableForm.list, this.editTableRow.id, tableConst.DKB_PD_PROD).then(response => {
            this.resourceTableVisible = false
            this.msgSuccess()
          })
        } else {
          this.msgError('请规范填写')
        }
      })
    },
    /* 引用资源名称下拉列表数据值变化*/
    resourceNameChange(value,index) {
      let name;
      this.resourceDataOption.forEach((item)=>{
        if(item.id==value){
          name=item.name
        }
      })
      this.resourceTableForm.list[index].resourceName=name
      this.resourceDataOption = []
    },
    /*引用资源名称下拉列表搜索*/
    resourceNameQuery(query, resourceTypeId) {
      if (!isEmpty(query)) {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          resourceNameQuery(resourceTypeId, query.trim()).then(response => {
            this.resourceDataOption = response.data

          })
        }, 200)
      } else {
        this.options = []
      }
    },
    /*处理来源引用表格删除*/
    handleResourceDelete(index, row) {
      this.resourceTableForm.list.splice(index, 1)

    },
    /*引用资源类型--下拉列表值变化*/
    resourceTypeChange(value,index){
      this.resourceDataOption=[];
      let item=this.resourceTableForm.list[index];
      item.resourceId=undefined
      item.resourceName=undefined

    },
    /*重置表单*/
    resetForm(formName) {
      let form = this.$refs[formName]
      if (form) {
        form.resetFields()
      }
    },
    /*表格排序切换*/
    tableSortChange({column, prop, order}){
      if(order!=null){
        order=order=='descending'?'desc':'asc';
      }else{
        order=''
      }
      this.queryParams.orderByColumn=prop
      this.queryParams.isAsc=order
      this.handleQuery()
    },
    /*鼠标右键点击事件*/
    rowContextmenu (row, column, event) {
      this.tableContextMenuVisible = false
      this.tableContextMenuVisible = true
      // 阻止右键默认行为
      event.preventDefault()
      this.$nextTick(() => {
        this.$refs.contextbutton.init(row,column,event)
      })
    },
    /* 取消鼠标监听事件 菜单栏*/
    foo() {
      this.tableContextMenuVisible = false
      document.removeEventListener('click', this.foo)
    },
    /* 处理表格右键菜单点击*/
    handleTableAction (actionName,row, column) {
      if(actionName==='修改'){
        this.handleUpdate(row)
      }else if(actionName==='来源'){
        this.handleResource(row)
      }else if(actionName==='删除'){
        this.handleDelete(row)
      }
    },
    isEmpty(str){
      return isEmpty(str)
    }


  }
}
</script>

<style>
#drugProdDiv .el-table__expanded-cell {
  padding: 0px !important;
  padding-left: 44px !important;
  border-bottom: 1px solid #dfe6ec;
}

.hiderow .el-table__expand-column .el-icon {
  visibility: hidden;
}

.el-table .disableData-row {
  text-decoration: line-through #f50707;
}

/*隐藏表格行的展开折叠按钮*/
.hidden-expand .el-table__expand-column .cell {
  display: none;
}

.headerClass .query-form .el-form-item__content{
  line-height: 0px;
}

</style>


<style lang="scss" scoped>
.title {
  border-left: 5px solid #0073E9;
  padding-left: 10px;
}

/*解决表格内部表单元素错位*/
.el-table .cell .el-form-item {
  margin-bottom: 0px;
}

</style>
