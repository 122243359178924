/**用户反馈信息 */
import request from '@/utils/request'

// 反馈列表（分页查询）
export function getFeedbackListApi(data) {
  return request({
    url: '/dm/defect/feedback/list',
    method: 'get',
    params: data
  })
}
// 获取缺失反馈信息
export function getFeedbackApi(data) {
  return request({
    url: '/dm/defect/feedback/info/' + data.id,
    method: 'get'
  })
}
// 更新药品缺失反馈状态缺失反馈信息
export function updateFeedbackApi(data) {
  return request({
    url: '/dm/defect/feedback/processStatus',
    method: 'put',
    params: data
  })
}
// 机构查询-最多返回前50条
export function getOrgDataApi(data) {
  return request({
    url: '/dm/defect/feedback/org/limit',
    method: 'get',
    params: data
  })
}
// 获取图片地址
export function getImageUrlApi(data) {
  return request({
    url: '/system/file/preview',
    method: 'get',
    params: data
  })
}
