/**新药入库维护 */
import request from '@/utils/request'

// 分页查询接口
export function getDrugListApi(data) {
  return request({
    url: '/dm/appDrug/web/selectDrugList',
    method: 'post',
    params: data
  })
}
// 更新药品状态接口
export function updateDrugStatusApi(data) {
  return request({
    url: '/dm/appDrug/updateDrugStatus',
    method: 'post',
    params: data
  })
}
