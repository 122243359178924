<template>
  <div class="app-container" style="height: 100%;">
    <el-row style="height: 100%;">
      <el-col :span="16" style="height: 100%;padding-right: 10px;">
        <mainTable></mainTable>
      </el-col>
      <el-col :span="8" style="height: 100%;">
        <associateTable></associateTable>
      </el-col>
    </el-row>
  </div>

</template>
<script>
import mainTable from './mainTable.vue'
import associateTable from './associateTable.vue'
export default {
  name: 'Ddd',
	components: {
		mainTable,
		associateTable
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.DDD {
	width: 100%;
	height: 100vh;
	display: flex;
	overflow: hidden;
	padding: 20px;
	box-sizing: border-box;
}
</style>
