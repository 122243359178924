<template>
  <div class="app-container" style="height: 100%">
    <el-container style="height: 100%">
      <el-header class="header">
        <el-tabs tab-position="top" v-model="activeTabName" @tab-click="handleTabClick">
          <el-tab-pane label="计量单位(pub_measure_unit)" name="pub_measure_unit"></el-tab-pane>
          <el-tab-pane label="质量单位(pub_mass_unit)" name="pub_mass_unit"></el-tab-pane>
          <el-tab-pane label="体积单位(pub_volume_unit)" name="pub_volume_unit"></el-tab-pane>
        </el-tabs>
        <div>
          <el-button type="info" size="small" style=""
                     icon="el-icon-refresh" @click="refreshClick">刷新缓存
          </el-button>
          <el-button type="primary" size="small" style=""
                     icon="el-icon-circle-plus-outline" @click="addLink">新增
          </el-button>
        </div>

      </el-header>
      <el-main>
        <div style="overflow: auto;height: 100%;">
          <el-table
            border
            stripe
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
            v-loading="tableLoading"
            :data="conversionCoefficientLink"
            height="100%"
            style="width: 100%">
            <el-table-column
              type="index"
              width="50">
            </el-table-column>
            <el-table-column
              prop="name"
              label="转换关系"
              min-width="180">
              <template slot-scope="scope">


                <span v-for="(t,i) in scope.row.dataList">
                  <span class="col" v-if="t.type==1">
                    {{ t.conceptName }}
                  </span>
                  <span class="col" v-if="t.type==2">
                    <el-divider content-position="center">{{ t.conversionCoefficient }}</el-divider>
                  </span>

                </span>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="120">
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="editRow(scope.row)"
                  type="text"
                  size="small">
                  编辑
                </el-button>
                <el-button
                  @click.native.prevent="delRow(scope.row)"
                  type="text" style="color: #F56C6C"
                  size="small">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>


        </div>
      </el-main>
    </el-container>
    <el-dialog title="编辑" append-to-body width="80%" :visible.sync="editDialogVisible"
               :close-on-click-modal="false"
    >
      <el-form ref="form" status-icon :model="editRowData" label-width="10px">
        <div style="overflow-y: hidden;overflow-x: auto;width: 100%;white-space: nowrap;">
          <el-button type="primary" icon="el-icon-circle-plus" size="mini" @click="addFirstConversionItem()"
                     circle></el-button>
          <div v-for="(t,i) in editRowData.dataList"
               style="min-width: 150px;height:80px;display: inline-block;">
            <el-form-item label="" v-if="t.type==1"
                          :rules="[{ required: true, message: '请输入', trigger: 'blur'}]"
                          :prop="'dataList.' + i + '.conceptId'"
            >
              <el-select size="small" filterable
                         v-model="t.conceptId" placeholder="请选择" style="width: 150px;">
                <el-option
                  v-for="item in conceptListDict"
                  :key="item.conceptId"
                  :label="item.conceptVal"
                  :value="item.conceptId"
                ></el-option>
              </el-select>
              <el-button type="primary" icon="el-icon-circle-plus" size="mini" @click="addConversionItem(i)"
                         circle v-show="t.type==1 && i<editRowData.dataList.length-1"></el-button>
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="delConversionItem(i)"
                         circle
                         v-show="t.type==1 && i<editRowData.dataList.length-1 && editRowData.dataList.length>3"></el-button>
            </el-form-item>
            <el-form-item label="" v-if="t.type==2"
                          :rules="[{ required: true, message: '请输入', trigger: 'blur'}]"
                          :prop="'dataList.' + i + '.conversionCoefficient'"
            >
              <el-input-number size="small" style="width: 150px; margin: 0px 10px;"
                               v-model="t.conversionCoefficient"
                               controls-position="right" :min="1" :max="10000000"></el-input-number>
            </el-form-item>


          </div>
          <el-button type="primary" icon="el-icon-circle-plus" size="mini" @click="addLastConversionItem()"
                     circle></el-button>
          <el-button type="danger" icon="el-icon-delete" size="mini"
                     @click="delConversionItem(editRowData.dataList.length-1)"
                     circle v-show="editRowData.dataList && editRowData.dataList.length>3"></el-button>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer" style="margin-right:20px;">
        <el-button @click="editDialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleSave()" size="small">确 定</el-button>
      </span>

    </el-dialog>

  </div>
</template>

<script>
import {
  getConversionCoefficientLink,
  saveConversionCoefficientLink,
  refreshCache
} from '@/api/term/unit/conversionCoefficient'
import {getConcept} from '@/utils/concept'
import conceptConst from '@/const/conceptConst'
import {deepClone} from "../../../utils";

export default {
  name: "conversionCoefficient",
  data() {
    return {
      tableLoading: false,
      activeTabName: 'pub_measure_unit',
      editDialogVisible: false,
      conceptConst,
      conversionCoefficientLink: [],
      //单位字典
      conceptListDict: [],
      editRowData: {
        dataList: []
      },
      oldEditRowData: {
        dataList: []
      }
    }
  },
  created() {
    this.getTableData();

    //获取计量单位字典
    getConcept(conceptConst.PUB_MEASURE_UNIT, 0).then(result => {
      this.conceptListDict = result
    })
  },
  methods: {
    handleTabClick() {
      this.getTableData()
      getConcept(this.activeTabName, 0).then(result => {
        this.conceptListDict = result
      })
    },
    refreshClick() {
      refreshCache().then(result => {
        this.msgSuccess()
        this.getTableData()
      })

    },
    getTableData() {
      this.tableLoading = true
      getConversionCoefficientLink(this.activeTabName).then(res => {
        this.conversionCoefficientLink = []
        res.data.forEach(item => {
          this.conversionCoefficientLink.push({
            editFlag: false,
            dataList: this.handleCoefficientLinkItem(item)
          })
        })
        this.tableLoading = false
      })
    },
    handleCoefficientLinkItem(linkItem) {
      let list = []
      let next = linkItem
      while (next) {
        list.push({
          id: next.id,
          type: 1,
          conceptId: next.sourceConceptId,
          conceptName: next.sourceConceptName,
        })
        list.push({
          id: next.id,
          type: 2,
          conversionCoefficient: next.conversionCoefficient,
        })
        if (!next.next) {
          list.push({
            id: next.id,
            type: 1,
            conceptId: next.targetConceptId,
            conceptName: next.targetConceptName
          })
        }

        next = next.next
      }
      return list
    },
    // 编辑表格行
    editRow(rowData) {
      this.editDialogVisible = true
      this.editRowData = deepClone(rowData)
      this.oldEditRowData = deepClone(rowData)

    },
    // 删除表格行
    delRow(rowData) {
      this.oldEditRowData = deepClone(rowData)
      let _this = this
      this.$confirm('确认要删除吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        let params = {
          domainCode: _this.activeTabName,
          delFlag: true,
          oldData: _this.oldEditRowData.dataList,
          newData: []
        }
        saveConversionCoefficientLink(params).then(res => {
          _this.msgSuccess()
          _this.getTableData();
        })
      })
    },

    //头部添加节点
    addFirstConversionItem() {
      this.editRowData.dataList.unshift({
        type: 1,
        conceptId: undefined,
        conceptName: ''
      }, {
        type: 2,
        conversionCoefficient: 1
      })
    },
    //尾部添加节点
    addLastConversionItem() {
      this.editRowData.dataList.push({
        type: 2,
        conversionCoefficient: 1,
      }, {
        type: 1,
        conceptId: undefined,
        conceptName: '',
      })
    },
    //中间添加节点
    addConversionItem(i) {
      this.editRowData.dataList.splice(i + 1, 0, {
        type: 2,
        conversionCoefficient: 1
      }, {
        type: 1,
        conceptId: undefined,
        conceptName: ''
      })
    },
    delConversionItem(i) {
      if (i == 0) {
        this.editRowData.dataList.splice(0, 2)
      } else {
        this.editRowData.dataList.splice(i - 1, 2)
      }
    },
    //保存
    handleSave: function () {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          let existFlag = this.checkLink(this.editRowData.dataList)
          if (existFlag) {
            this.msgError("转换链存在循环，无法保存")
            return
          }
          let params = {
            domainCode: this.activeTabName,
            oldData: this.oldEditRowData.dataList,
            newData: this.editRowData.dataList
          }
          saveConversionCoefficientLink(params).then(res => {
            this.msgSuccess()
            this.getTableData();
            this.editDialogVisible = false
          })
        } else {
          this.msgError('请规范填写')
        }
      })

    },
    //保存前检查链表是否存在循环
    checkLink(dataList) {
      let conceptIdList = [];
      let existFlag = dataList.some(item => {
        if (conceptIdList.indexOf(item.conceptId) >= 0) {
          return true
        }
        if (item.type == 1) {
          conceptIdList.push(item.conceptId)
        }
      })
      return existFlag
    },
    //新增一条转换链
    addLink() {
      this.editDialogVisible = true
      this.oldEditRowData.dataList = []
      this.editRowData.dataList = []
      this.editRowData.dataList.push({
        type: 1,
        conceptId: undefined,
        conceptName: ''
      }, {
        type: 2,
        conversionCoefficient: 1
      }, {
        type: 1,
        conceptId: undefined,
        conceptName: ''
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-wrap: wrap;
  height: auto !important;
  min-height: 55px;
  justify-content: space-between;
  align-items: center;
}

.el-main {
  padding: 6px 0px;
}



.col {
  height: 30px;
  line-height: 30px;
}

.col .el-divider {
  width: 150px;
  display: inline-block;
  margin: 4px;

  ::v-deep .el-divider__text {
    padding: 0 1px;
  }
}

</style>
