var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { overflow: "auto" } },
    [
      _c(
        "div",
        { ref: "headerQuery" },
        [
          _c(
            "el-row",
            { staticClass: "headerClass" },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "border-left": "5px solid #0073E9",
                    "padding-left": "10px",
                  },
                  attrs: { span: 4 },
                },
                [_vm._v("用户行为记录列表")]
              ),
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "queryForm",
                      staticStyle: { float: "right" },
                      attrs: { model: _vm.queryParams, inline: true },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户Id/名称:", prop: "userQuery" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { maxlength: "30", clearable: "" },
                            model: {
                              value: _vm.queryParams.userQuery,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "userQuery", $$v)
                              },
                              expression: "queryParams.userQuery",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "行为标签:", prop: "actionLabel" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "300px" },
                              attrs: {
                                "popper-append-to-body": false,
                                filterable: "",
                                remote: "",
                                "collapse-tags": "",
                                multiple: "",
                                "reserve-keyword": "",
                                placeholder: "标签名称",
                                "remote-method": _vm.queryLabelList,
                                loading: _vm.loading,
                              },
                              model: {
                                value: _vm.queryParams.actionLabels,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "actionLabels", $$v)
                                },
                                expression: "queryParams.actionLabels",
                              },
                            },
                            _vm._l(_vm.labelList, function (item) {
                              return _c("el-option", {
                                key: item.id + item.configVal1,
                                attrs: {
                                  label: item.configVal1,
                                  value: item.configVal1,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "操作时间" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "240px" },
                            attrs: {
                              size: "small",
                              "value-format": "yyyy-MM-dd",
                              type: "daterange",
                              "range-separator": "-",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                            },
                            model: {
                              value: _vm.queryParams.dateRange,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "dateRange", $$v)
                              },
                              expression: "queryParams.dateRange",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-search",
                                size: "small",
                              },
                              on: { click: _vm.handleQuery },
                            },
                            [_vm._v("检索")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { icon: "fa fa-cog", size: "small" },
                              on: { click: _vm.columnSettings },
                            },
                            [_vm._v("设置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { ref: "tableContainer" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "refundTable",
              attrs: {
                border: "",
                stripe: "",
                data: _vm.tableDataList,
                height: _vm.tableHeight,
              },
              on: {
                "sort-change": _vm.tableSortChange,
                "row-contextmenu": _vm.rowContextmenu,
              },
            },
            [
              _vm._l(_vm.columnOptions, function (data) {
                return _c("el-table-column", {
                  key: "columnOptions" + data.id,
                  attrs: {
                    prop: data.prop,
                    label: data.name,
                    fixed: data.fixed,
                    resizable: false,
                    sortable: data.sortable,
                    "min-width": data.width,
                    align: data.align,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            data.prop == "status"
                              ? _c(
                                  "div",
                                  [
                                    scope.row.status == "0"
                                      ? _c(
                                          "el-tag",
                                          { attrs: { type: "info" } },
                                          [_vm._v("停用")]
                                        )
                                      : _vm._e(),
                                    scope.row.status == "1"
                                      ? _c(
                                          "el-tag",
                                          { attrs: { type: "success" } },
                                          [_vm._v("启用")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : data.prop == "userId"
                              ? _c("div", [
                                  _vm._v(
                                    " [" +
                                      _vm._s(scope.row.userId) +
                                      "]" +
                                      _vm._s(scope.row.userName) +
                                      " "
                                  ),
                                ])
                              : data.prop == "browserName"
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.browserName) +
                                      "(" +
                                      _vm._s(scope.row.browserVersion) +
                                      ") "
                                  ),
                                ])
                              : data.prop == "nodeContent"
                              ? _c("div", [
                                  _c("p", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        scope.row.showNodeContent
                                      ),
                                    },
                                  }),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            scope.row.nodeName == "input" ||
                                            scope.row.nodeName == "textarea",
                                          expression:
                                            "scope.row.nodeName=='input' || scope.row.nodeName=='textarea'",
                                        },
                                      ],
                                    },
                                    [_vm._v(_vm._s(scope.row.nodeText))]
                                  ),
                                ])
                              : data.prop == "actionLabel"
                              ? _c(
                                  "div",
                                  _vm._l(
                                    scope.row.actionLabelList,
                                    function (item) {
                                      return _c(
                                        "el-tag",
                                        {
                                          key: "actionLabel" + item,
                                          attrs: { size: "mini" },
                                        },
                                        [_vm._v(_vm._s(item))]
                                      )
                                    }
                                  ),
                                  1
                                )
                              : data.prop == "actionRecordDetailList"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "top",
                                          title: "全部api",
                                          trigger: "click",
                                          width: "400",
                                          height: "400",
                                          "popper-class":
                                            "actionRecordDetailListPopover",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          _vm._l(
                                            scope.row.actionRecordDetailList,
                                            function (item) {
                                              return _c(
                                                "p",
                                                {
                                                  key:
                                                    "actionRecordDetail" +
                                                    item.id,
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.apiName ||
                                                          item.requestUrl
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "setTwoLine1",
                                            staticStyle: {
                                              color: "#2B60F8",
                                              "font-weight": "700",
                                            },
                                            attrs: {
                                              slot: "reference",
                                              title: "点击查看全部物质描述",
                                            },
                                            slot: "reference",
                                          },
                                          _vm._l(
                                            scope.row.actionRecordDetailList
                                              .length > 5
                                              ? scope.row.actionRecordDetailList.slice(
                                                  0,
                                                  5
                                                )
                                              : scope.row
                                                  .actionRecordDetailList,
                                            function (item) {
                                              return _c("p", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.apiName ||
                                                        item.requestUrl
                                                    )
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row[data.prop])),
                                  ]),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _vm.tableDataList.length == 0
                ? _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-booking.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ])
                : _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-left-data.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("未查询到结果，请修改查询条件重试！")]),
                  ]),
            ],
            2
          ),
        ],
        1
      ),
      _vm.tableContextMenuVisible
        ? _c("table-context-menu", {
            ref: "contextbutton",
            attrs: { action: _vm.tableAction },
            on: { foo: _vm.foo, handleTableAction: _vm.handleTableAction },
          })
        : _vm._e(),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
          "page-sizes": [10, 20, 50, 100],
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.actionRecordList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }