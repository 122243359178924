var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "el-dialog",
        {
          staticClass: "details-dialog vertical-middle-dialog",
          attrs: {
            title: "处理",
            visible: _vm.dialogVisible,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            width: "480px",
            "append-to-body": true,
            center: "",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          !_vm.isEnlarge
            ? _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.formObj,
                    "label-position": "top",
                    "label-width": "130px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "form-item", attrs: { label: "药品描述" } },
                    [
                      _c("div", { staticClass: "desc-div" }, [
                        _vm.formObj.drugDesc && _vm.formObj.drugDesc !== ""
                          ? _c("span", [_vm._v(_vm._s(_vm.formObj.drugDesc))])
                          : _c("span", { staticStyle: { color: "#c0c4cc" } }, [
                              _vm._v("暂无信息"),
                            ]),
                      ]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "form-item", attrs: { label: "药品图片" } },
                    [
                      _vm._l(_vm.imageObj.urlList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: item.index,
                            staticClass: "drug-image-container",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "img-container",
                                on: {
                                  mouseenter: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onImageHover(item)
                                  },
                                  mouseleave: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onImageMouseout(item)
                                  },
                                  click: function ($event) {
                                    return _vm.onEnlargeImage(item)
                                  },
                                },
                              },
                              [
                                _c("img", { attrs: { src: item.url } }),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.isHovered,
                                        expression: "item.isHovered",
                                      },
                                    ],
                                    staticClass: "img-mask",
                                    class: _vm.imgMask(item),
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-search zoom-in-icon",
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      _vm.noImage
                        ? _c("div", [
                            _c("span", { staticStyle: { color: "#c0c4cc" } }, [
                              _vm._v("暂无信息"),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "form-item", attrs: { label: "处理选项" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formObj.processStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.formObj, "processStatus", $$v)
                            },
                            expression: "formObj.processStatus",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("暂不处理"),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("已处理"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("div", { staticClass: "enlarge-image-container" }, [
                _c("img", { attrs: { src: _vm.currentImg } }),
                _c("i", {
                  staticClass: "el-icon-circle-close",
                  on: { click: _vm.onCloseEnlarge },
                }),
              ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmHandle },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }