var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "layout-container version-upgrade-container" },
    [
      _c("el-header", { staticClass: "layout-header" }, [
        _c("section", { staticClass: "header-top" }, [
          _c("h3", { staticClass: "title-header-container" }, [
            _c("span", { staticClass: "title-header" }),
            _c("h4", { staticClass: "title-text" }, [_vm._v("映射关系维护")]),
          ]),
          _c("div", [
            _c("span", { staticStyle: { "font-size": "16px" } }, [
              _vm._v("当前知识库版本号："),
            ]),
            _c(
              "span",
              { staticStyle: { color: "#666", "font-size": "16px" } },
              [_vm._v(_vm._s(_vm.latestVersionObj.version))]
            ),
            _c(
              "span",
              { staticStyle: { "margin-left": "20px", "font-size": "16px" } },
              [_vm._v("更新时间：")]
            ),
            _c(
              "span",
              { staticStyle: { color: "#666", "font-size": "16px" } },
              [_vm._v(_vm._s(_vm.latestVersionObj.pubTime))]
            ),
          ]),
        ]),
        _c(
          "section",
          { staticClass: "header-btm" },
          [
            _c(
              "el-form",
              {
                attrs: {
                  model: _vm.formObj,
                  inline: true,
                  "label-width": "90px",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "机构名称：" } },
                  [
                    _c("el-autocomplete", {
                      staticStyle: {
                        width: "200px",
                        height: "36px!important",
                        "line-height": "36px",
                      },
                      attrs: {
                        "fetch-suggestions": _vm.onOrgSearch,
                        placeholder: "输入机构名称",
                        clearable: "",
                        size: "medium",
                        "value-key": "name",
                      },
                      model: {
                        value: _vm.formObj.orgName,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formObj,
                            "orgName",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formObj.orgName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "状态：" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100px" },
                        attrs: { placeholder: "请选择", clearable: "" },
                        model: {
                          value: _vm.formObj.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.formObj, "status", $$v)
                          },
                          expression: "formObj.status",
                        },
                      },
                      _vm._l(_vm.statusOptions, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "small",
                          type: "primary",
                          icon: "el-icon-search",
                        },
                        on: { click: _vm.onSearchTable },
                      },
                      [_vm._v("查询")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-main",
        [
          _c(
            "el-table",
            {
              staticClass: "platform-report-table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "header-cell-style": { textAlign: "center" },
                height: "100%",
                stripe: "",
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", width: "100", prop: "id", fixed: "" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("编号")])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("机构名称")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.orgName))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("机构库版本号")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.orgVersion))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("知识库版本号")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.dkbVersion))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("最近操作时间")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.updateTime))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "statusStr" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [_c("span", [_vm._v("状态")])]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { class: _vm.statusText(scope) }, [
                          _vm._v(
                            _vm._s(_vm._f("statusShow")(scope.row.status))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "100",
                  type: "index",
                  label: "操作",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === "1" || scope.row.status === "3"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onHandleRow(scope.row, "view")
                                  },
                                },
                              },
                              [_vm._v(" 查看 ")]
                            )
                          : _vm._e(),
                        scope.row.status === "1" || scope.row.status === "2"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onHandleRow(scope.row, "edit")
                                  },
                                },
                              },
                              [_vm._v(" 维护 ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-footer",
        [
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.formObj.pageNum,
              limit: _vm.formObj.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.formObj, "pageNum", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.formObj, "pageSize", $event)
              },
              pagination: _vm.getTableHandle,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }