var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { overflow: "auto" } },
    [
      _c(
        "div",
        { ref: "headerQuery" },
        [
          _c(
            "el-row",
            { staticClass: "headerClass" },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "border-left": "5px solid #0073E9",
                    "padding-left": "10px",
                  },
                  attrs: { span: 4 },
                },
                [_vm._v("物质管理")]
              ),
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showSearch,
                          expression: "showSearch",
                        },
                      ],
                      ref: "queryForm",
                      staticClass: "query-form",
                      staticStyle: { float: "right" },
                      attrs: { model: _vm.queryParams, inline: true },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "name" } },
                        [
                          _c(
                            "el-autocomplete",
                            {
                              staticStyle: {
                                width: "300px",
                                height: "36px!important",
                                "line-height": "36px",
                              },
                              attrs: {
                                "fetch-suggestions": _vm.querySearchAsync,
                                placeholder:
                                  "请输入物质ID/名称/代码/拼音/五笔码",
                                clearable: "",
                                size: "small",
                                "value-key": "name",
                              },
                              on: {
                                select: _vm.handleQuery,
                                clear: function ($event) {
                                  return _vm.setBlur()
                                },
                              },
                              nativeOn: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.handleQuery($event)
                                },
                              },
                              model: {
                                value: _vm.queryParams.searchValue,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "searchValue", $$v)
                                },
                                expression: "queryParams.searchValue",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "74px" },
                                  attrs: {
                                    slot: "prepend",
                                    placeholder: "请选择",
                                  },
                                  slot: "prepend",
                                  model: {
                                    value:
                                      _vm.queryParams.searchValueSearchType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "searchValueSearchType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "queryParams.searchValueSearchType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "模糊", value: "1" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "精确", value: "2" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "前缀", value: "3" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "status" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "statusClass",
                              staticStyle: { width: "74px" },
                              attrs: {
                                placeholder: "状态",
                                clearable: "",
                                size: "small",
                              },
                              model: {
                                value: _vm.queryParams.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "status", $$v)
                                },
                                expression: "queryParams.status",
                              },
                            },
                            _vm._l(_vm.statusOptions, function (dict) {
                              return _c("el-option", {
                                key: "statusOptions" + dict.conceptId,
                                attrs: {
                                  label: dict.conceptVal,
                                  value: dict.conceptId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-search",
                                size: "small",
                              },
                              on: { click: _vm.handleQuery },
                            },
                            [_vm._v("检索")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "addClass",
                              attrs: {
                                icon: "el-icon-circle-plus-outline",
                                size: "small",
                              },
                              on: { click: _vm.handleAdd },
                            },
                            [_vm._v("新增物质")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { icon: "fa fa-cog", size: "small" },
                              on: { click: _vm.columnSettings },
                            },
                            [_vm._v("设置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { ref: "tableContainer" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "refundTable",
              attrs: {
                border: "",
                stripe: "",
                data: _vm.drugList,
                height: _vm.tableHeight,
              },
              on: {
                "sort-change": _vm.tableSortChange,
                "row-contextmenu": _vm.rowContextmenu,
              },
            },
            [
              _vm._l(_vm.columnOptions, function (data) {
                return _c("el-table-column", {
                  key: "columnOptions" + data.id,
                  attrs: {
                    prop: data.prop,
                    label: data.name,
                    fixed: data.fixed,
                    resizable: false,
                    sortable: data.sortable,
                    "min-width": data.width,
                    align: data.align,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            data.prop == "dkbSubstanceCode"
                              ? _c("div", [
                                  _c("p", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        scope.row.substanceCode
                                      ),
                                    },
                                  }),
                                ])
                              : data.prop == "substanceProps"
                              ? _c("div", [
                                  _c("p", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        scope.row.substanceProps
                                      ),
                                    },
                                  }),
                                ])
                              : data.prop == "substanceDesc"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              scope.row
                                                .substanceDescReferenceInfos
                                                .length > 0,
                                            expression:
                                              "scope.row.substanceDescReferenceInfos.length>0",
                                          },
                                        ],
                                        attrs: {
                                          placement: "top",
                                          title: "来源引用信息",
                                          width: "200",
                                          trigger: "click",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          _vm._l(
                                            scope.row
                                              .substanceDescReferenceInfos,
                                            function (item) {
                                              return _c(
                                                "p",
                                                {
                                                  key:
                                                    "substanceDescReferenceInfos" +
                                                    item.id,
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        "[" +
                                                          item.resourceTypeName +
                                                          "]"
                                                      ) +
                                                      _vm._s(
                                                        item.resourceName || ""
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _c("i", {
                                          staticClass: "el-icon-question",
                                          attrs: {
                                            slot: "reference",
                                            title: "点击查看来源引用",
                                          },
                                          slot: "reference",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "top",
                                          title: "全部物质描述",
                                          trigger: "click",
                                          width: "600",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          _vm._l(
                                            scope.row.dkbSubstanceDesc,
                                            function (item) {
                                              return _c(
                                                "p",
                                                {
                                                  key:
                                                    "dkbSubstanceDesc" +
                                                    item.id,
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.substanceDesc
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "setTwoLine",
                                            staticStyle: {
                                              color: "#2B60F8",
                                              "font-weight": "700",
                                            },
                                            attrs: {
                                              slot: "reference",
                                              title: "点击查看全部物质描述",
                                            },
                                            slot: "reference",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  scope.row.substanceDesc
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : data.prop == "substanceName"
                              ? _c("div", [
                                  _c(
                                    "span",
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                scope.row
                                                  .substanceNameReferenceInfos
                                                  .length > 0,
                                              expression:
                                                "scope.row.substanceNameReferenceInfos.length>0",
                                            },
                                          ],
                                          attrs: {
                                            placement: "top",
                                            title: "来源引用信息",
                                            width: "200",
                                            trigger: "click",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            _vm._l(
                                              scope.row
                                                .substanceNameReferenceInfos,
                                              function (item) {
                                                return _c(
                                                  "p",
                                                  {
                                                    key:
                                                      "substanceNameReferenceInfos" +
                                                      item.id,
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          "[" +
                                                            item.resourceTypeName +
                                                            "]"
                                                        ) +
                                                        _vm._s(
                                                          item.resourceName ||
                                                            ""
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _c("i", {
                                            staticClass: "el-icon-question",
                                            attrs: {
                                              slot: "reference",
                                              title: "点击查看来源引用",
                                            },
                                            slot: "reference",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "top",
                                            title: "全部名称",
                                            width: "400",
                                            trigger: "click",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            _vm._l(
                                              scope.row.dkbSubstanceName,
                                              function (item) {
                                                return _c(
                                                  "p",
                                                  {
                                                    key:
                                                      "dkbSubstanceName" +
                                                      item.id,
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.name) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "#2B60F8",
                                                "font-weight": "700",
                                              },
                                              attrs: {
                                                slot: "reference",
                                                title: "点击查看全部物质名称",
                                              },
                                              slot: "reference",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "5px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.substanceName
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._l(
                                                scope.row.drugCategoryList,
                                                function (item) {
                                                  return _c(
                                                    "span",
                                                    { key: item.id },
                                                    [
                                                      item.icon
                                                        ? _c("svg-icon", {
                                                            attrs: {
                                                              "icon-class":
                                                                item.icon,
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _c("div", [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row[data.prop])),
                                  ]),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                key: "status",
                attrs: {
                  label: "状态",
                  align: "center",
                  width: "100",
                  resizable: false,
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          staticClass: "switch",
                          attrs: {
                            "active-color": "#5DB730",
                            "active-text": "启用",
                            "inactive-text": "停用",
                            "inactive-color": "#B5CCF5",
                            "active-value": "0",
                            "inactive-value": "1",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleStatusChange(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.status,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "status", $$v)
                            },
                            expression: "scope.row.status",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm.isResult === false
                ? _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-booking.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ])
                : _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-left-data.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("未查询到结果，请修改查询条件重试！")]),
                  ]),
            ],
            2
          ),
        ],
        1
      ),
      _vm.tableContextMenuVisible
        ? _c("table-context-menu", {
            ref: "contextbutton",
            attrs: { action: _vm.tableAction },
            on: { foo: _vm.foo, handleTableAction: _vm.handleTableAction },
          })
        : _vm._e(),
      _c("drug-category-edit", { ref: "drugCategoryEdit" }),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
          "page-sizes": [10, 20, 50, 100],
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.selectSubstanceList,
        },
      }),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "引用来源管理[" + _vm.substanceName + "]",
            visible: _vm.resourceTableVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.resourceTableVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: {
                        icon: "el-icon-circle-plus-outline",
                        size: "small",
                      },
                      on: { click: _vm.addResource },
                    },
                    [_vm._v("添加 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "resourceTableForm",
              attrs: { "status-icon": "", model: _vm.resourceTableForm },
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    stripe: "",
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    data: _vm.resourceTableForm.list,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { property: "date", label: "引用资源类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "list." +
                                    scope.$index +
                                    "." +
                                    "resourceTypeId",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择物质名称",
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      placeholder: "引用资源类型",
                                      size: "small",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.resourceTypeChange(
                                          $event,
                                          scope.$index
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.resourceTypeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "resourceTypeId",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.resourceTypeId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.resourceTypeOption,
                                    function (dict) {
                                      return _c("el-option", {
                                        key: "resourceTypeOption" + dict.id,
                                        attrs: {
                                          label: dict.typeName,
                                          value: dict.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { property: "name", label: "引用资源名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "200px" },
                                attrs: {
                                  filterable: "",
                                  remote: "",
                                  "reserve-keyword": "",
                                  placeholder: "请输入关键词",
                                  "remote-method": function (query) {
                                    _vm.resourceNameQuery(
                                      query,
                                      scope.row.resourceTypeId
                                    )
                                  },
                                  loading: _vm.loading,
                                  disabled: !scope.row.resourceTypeId
                                    ? true
                                    : false,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.resourceNameChange(
                                      $event,
                                      scope.$index
                                    )
                                  },
                                },
                                model: {
                                  value: scope.row.resourceId,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "resourceId", $$v)
                                  },
                                  expression: "scope.row.resourceId",
                                },
                              },
                              [
                                _vm._l(_vm.resourceDataOption, function (item) {
                                  return _c("el-option", {
                                    key: "resourceDataOption" + item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                _vm._l([scope.row], function (item) {
                                  return _c("el-option", {
                                    key: item.resourceId + "original",
                                    attrs: {
                                      label: item.resourceName,
                                      value: item.resourceId,
                                    },
                                  })
                                }),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "center",
                      "class-name": "small-padding fixed-width",
                      width: "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "#FF3333" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleResourceDelete(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-booking.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "margin-top": "10px" }, attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.saveResource },
                    },
                    [_vm._v("保存 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.resourceTableVisible = false
                        },
                      },
                    },
                    [_vm._v("取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }