var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShowDialog
    ? _c(
        "el-dialog",
        {
          staticClass: "vertical-middle-dialog edit-klib-dialog",
          attrs: {
            title: _vm.title,
            visible: _vm.isShowDialog,
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "660px",
            center: "",
          },
          on: { close: _vm.closeDialog },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "search-form-wrap",
              attrs: {
                inline: false,
                model: _vm.editDataObj,
                "label-width": "82px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "版本号：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入版本号" },
                    model: {
                      value: _vm.editDataObj.version,
                      callback: function ($$v) {
                        _vm.$set(_vm.editDataObj, "version", $$v)
                      },
                      expression: "editDataObj.version",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发布日期：" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      "range-separator": "至",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "选择日期",
                    },
                    on: { change: _vm.dateChange },
                    model: {
                      value: _vm.editDataObj.pubDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.editDataObj, "pubDate", $$v)
                      },
                      expression: "editDataObj.pubDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "下载链接与口令：" } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "填写下载地址" },
                            model: {
                              value: _vm.downloadObj.url,
                              callback: function ($$v) {
                                _vm.$set(_vm.downloadObj, "url", $$v)
                              },
                              expression: "downloadObj.url",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-row",
                            { attrs: { type: "flex", justify: "end" } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 14 } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "130px" },
                                    attrs: { placeholder: "填写口令" },
                                    model: {
                                      value: _vm.downloadObj.passwd,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.downloadObj, "passwd", $$v)
                                      },
                                      expression: "downloadObj.passwd",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 10 } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { type: "primary" },
                                      on: { click: _vm.onAdd },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-table",
                    {
                      ref: "tableRef",
                      staticClass: "table-wrap",
                      attrs: {
                        data: _vm.editDataObj.downloadLinkList,
                        "header-cell-style": {
                          textAlign: "center",
                          color: "#333",
                        },
                        border: "",
                        stripe: "",
                        "highlight-current-row": "",
                        height: "260",
                      },
                      on: {
                        "cell-mouse-enter": _vm.onCellMouseEnter,
                        "cell-mouse-leave": _vm.onCellMouseLeave,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "url",
                          label: "下载地址",
                          align: "center",
                          "show-overflow-tooltip": "",
                          "class-name": "link-address",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "downloadLinkList." +
                                          scope.$index +
                                          ".url",
                                      },
                                    },
                                    [
                                      scope.row.isEdit
                                        ? _c(
                                            "el-input",
                                            {
                                              attrs: {
                                                type: "textarea",
                                                autosize: "",
                                                size: "mini",
                                                placeholder: "请输入",
                                                clearable: "",
                                              },
                                              model: {
                                                value: scope.row.url,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "url",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "scope.row.url",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-edit el-input__icon",
                                                attrs: { slot: "suffix" },
                                                on: {
                                                  click: _vm.handleIconClick,
                                                },
                                                slot: "suffix",
                                              }),
                                            ]
                                          )
                                        : _c("span", [
                                            _vm._v(_vm._s(scope.row.url)),
                                          ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          421230747
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "passwd",
                          label: "下载口令",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "downloadLinkList." +
                                          scope.$index +
                                          ".passwd",
                                      },
                                    },
                                    [
                                      scope.row.isEdit
                                        ? _c("el-input", {
                                            attrs: {
                                              size: "mini",
                                              placeholder: "请输入",
                                              clearable: "",
                                            },
                                            model: {
                                              value: scope.row.passwd,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "passwd",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression: "scope.row.passwd",
                                            },
                                          })
                                        : _c("span", [
                                            _vm._v(_vm._s(scope.row.passwd)),
                                          ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2526762979
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "isPrimary",
                          label: "主地址",
                          align: "center",
                          width: "70",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "downloadLinkList." +
                                          scope.$index +
                                          ".isPrimary",
                                        "label-width": "0px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: 1 },
                                          on: {
                                            change: function (val) {
                                              return _vm.onChangePrimary(
                                                val,
                                                scope.row,
                                                scope.$index
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.isPrimary,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "isPrimary",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.isPrimary",
                                          },
                                        },
                                        [_c("span")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          940154823
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          width: "70",
                          type: "index",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function (scope) {
                                return [_c("span", [_vm._v("操作")])]
                              },
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onDel(
                                            scope.$index,
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3028452140
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "MD5：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入MD5" },
                    model: {
                      value: _vm.editDataObj.vcMd5,
                      callback: function ($$v) {
                        _vm.$set(_vm.editDataObj, "vcMd5", $$v)
                      },
                      expression: "editDataObj.vcMd5",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注：" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: "",
                      placeholder: "输入备注内容",
                    },
                    model: {
                      value: _vm.editDataObj.comments,
                      callback: function ($$v) {
                        _vm.$set(_vm.editDataObj, "comments", $$v)
                      },
                      expression: "editDataObj.comments",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onSave } }, [_vm._v("保存")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onPublish } },
                [_vm._v("发布")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }