var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.cellCardArr, function (cell, index) {
      return _c(
        "el-row",
        {
          key: index,
          staticClass: "row-cell",
          attrs: { gutter: 20, id: "cellRow" + index, align: "middle" },
        },
        _vm._l(cell, function (item, cardIndex) {
          return _c(
            "el-col",
            {
              key: item.recordId,
              class: _vm.cardListClass(cardIndex),
              style: _vm.cardStyle(cardIndex),
              attrs: { sm: 12, md: 8, lg: 6, xl: 4 },
            },
            [
              _c(
                "el-card",
                { staticClass: "card-wrap", attrs: { shadow: "hover" } },
                [
                  _c("section", { class: _vm.tagHeader(item.status) }, [
                    _c("img", { attrs: { src: _vm.statusShow(item).img } }),
                    _c("span", [_vm._v(_vm._s(_vm.statusShow(item).text))]),
                  ]),
                  _c("section", { staticClass: "card-content-section" }, [
                    _c("div", {
                      ref: "chart" + item.recordId,
                      refInFor: true,
                      staticStyle: { width: "120px", height: "120px" },
                      attrs: { id: "chart" + item.recordId },
                    }),
                    _c("h3", { staticClass: "total-number" }, [
                      _vm._v(_vm._s(item.recordCount)),
                    ]),
                    _c("div", { staticClass: "total-text" }, [
                      _vm._v("上传总数"),
                    ]),
                  ]),
                  _c("section", { staticClass: "card-footer-section" }, [
                    _c("div", { staticClass: "item" }, [
                      _c("span", { staticClass: "circle" }),
                      _c("span", { staticClass: "item-label" }, [
                        _vm._v("上传时间："),
                      ]),
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(item.uploadTime)),
                      ]),
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("span", { staticClass: "circle" }),
                      _c("span", { staticClass: "item-label" }, [
                        _vm._v("最近操作："),
                      ]),
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(item.latestOperTime)),
                      ]),
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("span", { staticClass: "circle" }),
                      _c("span", { staticClass: "item-label" }, [
                        _vm._v("上传方式："),
                      ]),
                      _c("span", { staticClass: "item-text item-way" }, [
                        _vm._v(_vm._s(_vm._f("uploadText")(item.uploadType))),
                      ]),
                    ]),
                    Number(item.status) === 1
                      ? _c(
                          "div",
                          { staticClass: "item-footer" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "see-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.matchViewClick(item)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "delete-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteHandle(item)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        )
                      : Number(item.status) === 2 || Number(item.status) === 3
                      ? _c(
                          "div",
                          { staticClass: "item-footer" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "see-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.matchViewClick(item)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "delete-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.cancelHandle(item)
                                  },
                                },
                              },
                              [_vm._v("撤回")]
                            ),
                          ],
                          1
                        )
                      : Number(item.status) === 5
                      ? _c(
                          "div",
                          { staticClass: "item-footer" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "see-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.matchViewClick(item)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "reUpload-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.reUploadHandle(item)
                                  },
                                },
                              },
                              [_vm._v("重新上传")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "delete-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.stopHandle(item)
                                  },
                                },
                              },
                              [_vm._v("终止任务")]
                            ),
                          ],
                          1
                        )
                      : Number(item.status) === 6
                      ? _c(
                          "div",
                          { staticClass: "item-footer" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "see-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.matchViewClick(item)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "reUpload-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.reUploadHandle(item)
                                  },
                                },
                              },
                              [_vm._v("重新上传")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "delete-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteHandle(item)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "item-footer" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "see-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.matchViewClick(item)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                          ],
                          1
                        ),
                  ]),
                ]
              ),
            ],
            1
          )
        }),
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }