<!--目标数据审核设置-->
<template>
  <el-container class="layout-container platform-report">
    <el-header class="platform-report-header">
      <div class="title-header-container" id="1" @click="onSearchTable">
        <span class="title-header"></span>
        <h4 class="title-text">目标数据审核设置</h4>
      </div>
      <el-form :model="queryObj" :inline="true">
        <el-form-item>
          <el-autocomplete
            v-model.trim="queryObj.orgName"
            :fetch-suggestions="onOrgSearch"
            placeholder="输入机构名称"
            clearable
            size="medium"
            value-key="name"
            style="width: 200px;height: 36px!important;line-height: 36px;"/>
        </el-form-item>
        <!-- <el-form-item label="最近操作时间">
          <el-date-picker
            v-model="queryObj.timeList"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item> -->
        <el-form-item>
          <el-button size="small" type="primary" icon="el-icon-search" @click="onSearchTable">查询</el-button>
        </el-form-item>
      </el-form>
    </el-header>
    <el-main class="platform-report-main">
      <el-table
        ref="tableWrap"
        :data="tableData"
        :header-cell-style="{textAlign:'center'}"
        style="width: 100%;height: 100%"
        stripe
        border
        class="platform-report-table"
        @sort-change="onSortChange">
      <el-table-column
        align="center"
        width="100"
        fixed>
        <template slot="header" slot-scope="scope">
          <span>机构ID</span>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.orgId }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center">
        <template slot="header" slot-scope="scope">
          <span>机构名称</span>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.orgName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center">
        <template slot="header" slot-scope="scope">
          <span>审核字段数</span>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.dmFieldStr }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center">
        <template slot="header" slot-scope="scope">
          <span>质控字段数</span>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.evalFieldStr }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        align="center"
        prop="addTime"
        sortable="custom"
        column-key="addTime">
        <template slot="header" slot-scope="scope">
          <span>进入列表时间</span>
        </template>
        <template slot-scope="scope">
          <span :class="statusText(scope)">{{ scope.row.addTime }}</span>
        </template>
      </el-table-column> -->
      <el-table-column
        align="center"
        width="100"
        type="index"
        fixed="right">
        <template slot="header" slot-scope="scope">
          <span>操作</span>
        </template>
        <template slot-scope="scope">
          <el-button type="text" @click="onSet(scope.row)">设置</el-button>
        </template>
      </el-table-column>
    </el-table>
    </el-main>
    <el-footer>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryObj.pageNum"
        :limit.sync="queryObj.pageSize"
        @pagination="getTableHandle"/>
    </el-footer>
    <settings-dialog
      v-if="isShowDialog"
      :org-id="currentRow.orgId"
      :is-show-dialog="isShowDialog"
      @closeDialog="onCloseDialog" />
  </el-container>
</template>
<script>
import { QUERY_DATA_OBJ } from '@/utils/dm/targetDataAuditSettings/data'
import {
  getFieldSettingsListApi,
  getOrgDataApi
} from '@/api/dm/targetDataAuditSettings'
import SettingsDialog from './components/SettingsDialog'
export default {
  name: 'TargetDataAuditSettings',
  components: {
    SettingsDialog
  },
  computed: {
    statusText() {
      return function (scope) {
        if (scope.row.status === '1') {
          // 报告生成中（待审核）
          return 'to-check-status-text'
        } else if (scope.row.status === '2') {
          return 'published-status-text'
        }
      }
    }
  },
  data() {
    return {
      genericNameOrderIcon: 'letter-paixu', // 通用名排序icon
      genericNameOrderFlag: false, // 为false表示显示的是升序图标反之则是降序图标
      total: 0,
      queryObj: this.$deepClone(QUERY_DATA_OBJ),
      tableData: [],
      currentRow: null,
      isShowDialog: false
    }
  },
  async activated() {
    this.currentRow = null
    await this.fetchTableData()
  },
  async mounted() {
    this.$nextTick(() => {
      console.log(document.getElementById('1'))
    })
  },
  methods: {
    async fetchTableData() {
      // if (this.queryObj.timeList && this.queryObj.timeList.length > 0) {
      //   this.queryObj.beginTime = this.queryObj.timeList[0]
      //   this.queryObj.endTime = this.queryObj.timeList[1]
      // } else {
      //   this.queryObj.beginTime = ''
      //   this.queryObj.endTime = ''
      // }
      let res = await getFieldSettingsListApi(this.queryObj)
      if (res.code === 200) {
        this.tableData = res.data.rows || []
        this.total = res.data.total
      }
    },
    // 
    // 分页查询
    async getTableHandle(e) {
      await this.fetchTableData()
      this.$nextTick(() => {
        // console.log(this.$refs.tableWrap.bodyWrapper.scrollTop)
        this.$refs.tableWrap.$el.scrollTop = 0
      })
    },
    // 查询按钮
    async onSearchTable() {
      $('#1').removeAttr('onclick')
      this.queryObj.orderByColumn = ''
      this.queryObj.isAsc = ''
      await this.fetchTableData()
    },
    // 机构查询
    async onOrgSearch(queryString, cb) {
      if (queryString && queryString.trim() !== '') {
        let res = await getOrgDataApi({ searchValue: queryString })
        if (res.code === 200) {
          cb(res.data)
        }
      } else {
        cb([])
      }
    },
    // 药品通用名排序
    async genericNameOrder() {
      this.queryObj.pageNum = 1
      this.queryObj.orderByColumn = 'pdpName'
      this.genericNameOrderFlag = !this.genericNameOrderFlag
      if (this.genericNameOrderFlag) {
        this.genericNameOrderIcon = 'letter-paixu'
        this.queryObj.isAsc = 'asc'
      } else {
        this.genericNameOrderIcon = 'letter-paixu-1'
        this.queryObj.isAsc = 'desc'
      }
      await this.fetchTableData()
    },
    // 进入列表时间排序
    async onSortChange({ column, prop, order }) {
      this.queryObj.pageNum = 1
      this.queryObj.orderByColumn = 'addTime'
      if (order === 'ascending') {
        this.queryObj.isAsc = 'asc'
      } else if (order === 'descending') {
        this.queryObj.isAsc = 'desc'
      } else {
        this.queryObj.isAsc = ''
        this.queryObj.orderByColumn = ''
      }
      await this.fetchTableData()
    },
    //
    async onHandleChange(val, row) {
      console.log(row)
      console.log(val)
      let storageStatus = 1
      storageStatus = val ? 1 : 3
      const obj = {
        pdpMatId: row.pdpMatId,
        storageStatus
      }
      let res = await updateDrugStatusApi(obj)
      if (res.code === 200) {
        await this.fetchTableData()
      }
    },
    // 设置
    onSet(row) {
      this.currentRow = row
      this.isShowDialog = true
    },
    async onCloseDialog(val, sign) {
      this.isShowDialog = val
      if (sign === 'success') {
        await this.fetchTableData()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.platform-report {
  .platform-report-header {
    display: flex;
    flex-wrap: wrap;
    height: auto !important;
    min-height: 55px;
    justify-content: space-between;
    align-items: center;
    .title-header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      .title-header {
        width: 4px;
        height: 18px;
        background: #0073E9;
        border-radius: 2px;
      }
      .title-text {
        display: inline-block;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
        margin-left: 5px;
        vertical-align: top;
      }
    }
    .el-form {
      .el-form-item {
        margin-bottom: 0 !important;
        ::v-deep.el-form-item__label {
          font-size: 14px;
          /* color: #333;
          font-weight: 500; */
        }
        .el-radio {
          margin-right: 10px !important;
        }
      }
    }
  }
  .platform-report-main {
    padding: 0 20px !important;
    overflow: auto;
    .el-table {
      position: relative;
      overflow: auto;
      width: 100%;
      .to-check-status-text{
        display: inline-block;
        padding: 5px !important;
        color: #E4860A;
        background-color: #FFEBD6;
        font-size: 14px;
        text-align: center;
      }
      .published-status-text {
        display: inline-block;
        padding: 5px !important;
        color: #2B60F8;
        background-color: #D7E3FA;
        font-size: 14px;
        text-align: center;
      }
    }
  }
}
</style>
