<!--机构治理报告-->
<template>
  <el-container class="layout-container insti-report-container">
    <el-header class="insti-report-header box-shadow-radius">
      <div class="fl common-title">
        审核报告
      </div>
      <el-form ref="form" :model="searchObj" :inline="true" label-width="88px">
        <el-form-item label="申请时间：">
          <el-date-picker
            v-model="searchObj.year"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="查看范围：">
          <el-select v-model="searchObj.time" clearable placeholder="范围">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search">申请治理报告</el-button>
        </el-form-item>
      </el-form>
    </el-header>
    <el-main style="padding: 0;">
      <el-container class="layout-container insti-report-content-container">
        <el-aside class="box-shadow-radius" style="height: 100%; min-height: auto;padding: 10px 15px;">
          <history-list />
        </el-aside>
        <el-main class="content-main">
          <el-container class="layout-container">
            <el-header class="chart-container box-shadow-radius">
              <el-row>
                <el-col :xs="8" :sm="8" :md="6" :lg="6" :xl="6">
                  <h4 class="chart-title">匹配项、未匹配项占比</h4>
                  <div id="pie-chart-one" style="width: 100%;height: 150px;"></div>
                </el-col>
                <el-col :xs="8" :sm="8" :md="6" :lg="6" :xl="6">
                  <h4 class="chart-title">出现问题、未发现问题占比</h4>
                  <div id="pie-chart-two" style="width: ;height: 150px;"></div>
                </el-col>
                <el-col :xs="8" :sm="8" :md="6" :lg="6" :xl="6">
                  <h4 class="chart-title">风险项、优化项占比</h4>
                  <div id="pie-chart-three" style="width: 100%;height: 150px;"></div>
                </el-col>
                <el-col :xs="8" :sm="8" :md="6" :lg="6" :xl="6">
                  <h4 class="chart-title">各字段错误占比</h4>
                  <div id="pie-chart-four" style="width: 100%;height: 150px;"></div>
                </el-col>
              </el-row>
            </el-header>
            <el-main class="box-shadow-radius" style="padding: 0;">
              <el-container class="layout-container table-container">
                <el-header>
                  <el-form :model="tableSearchObj">
                    <el-form-item label="筛选模式">
                      <el-switch
                        v-model="tableSearchObj.isFilter"
                        active-color="#2c5ff9"
                        inactive-color="#b5ccf5"
                        @change="filterChange">
                      </el-switch>
                    </el-form-item>
                  </el-form>
                </el-header>
                <el-main>
                  <el-table
                    :data="tableData"
                    :span-method="getSummaries"
                    :header-cell-class-name="headerGradeInfo"
                    :cell-class-name="gradeInfo"
                    style="width: 100%;"
                    height="100%"
                    class="grade-table"
                    border>
                    <el-table-column
                      align="center"
                      width="160"
                      prop="ustdCode">
                      <template slot="header" slot-scope="scope">
                        <span>药品编码</span>
                        <el-popover
                          ref="elPopover"
                          trigger="click"
                          placement="right"
                          popper-class="governance-poper"
                          :visible-arrow="false"
                          @hide="onHidePopover(scope)"
                          @show="onShowPopover(scope)">
                          <filter-cascader
                            :ref="'filter' + scope.$index"
                            :show-filter="tableSearchObj.isFilter"
                            :filter-options="filterOptions"
                            :column="scope"
                            @closeFilter="onCloseFilter" />
                          <svg-icon
                            slot="reference"
                            icon-class="shaixuan"
                            class="filter-icon"
                            v-if="tableSearchObj.isFilter"
                            style="position: absolute; right: 10px;width: 20px;height: 20px;"
                            @click="onFilterClick(scope)"/>
                        </el-popover>
                      </template>
                      <template slot-scope="scope">
                        <span>{{ scope.row.ustdCode }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      width="180">
                      <template slot="header" slot-scope="scope">
                        <span>药品通用名</span>
                        <el-popover
                          ref="elPopover"
                          trigger="click"
                          placement="right"
                          popper-class="governance-poper"
                          :visible-arrow="false"
                          @hide="onHidePopover(scope)"
                          @show="onShowPopover(scope)">
                          <filter-cascader
                            :ref="'filter' + scope.$index"
                            :show-filter="tableSearchObj.isFilter"
                            :filter-options="filterOptions"
                            :column="scope"
                            @closeFilter="onCloseFilter" />
                          <svg-icon
                            slot="reference"
                            icon-class="shaixuan"
                            class="filter-icon"
                            v-if="tableSearchObj.isFilter"
                            style="position: absolute; right: 10px;width: 20px;height: 20px;"
                            @click="onFilterClick(scope)"/>
                        </el-popover>
                      </template>
                      <template slot-scope="scope">
                        <span>{{ scope.row.genericNameCompare }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      width="50"
                      class="none-border">
                      <template slot-scope="scope">
                        <span>{{ scope.row.genericNameCompareStatus }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="规格">
                      <template slot-scope="scope">
                        <span>{{ scope.row.specCompare }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      width="50"
                      prop="specCompareStatus">
                      <template slot-scope="scope">
                        <el-popover
                          :ref="'popoverspecCompareStatus' + scope.$index"
                          trigger="click"
                          placement="bottom"
                          :visible-arrow="false"
                          popper-class="governance-poper"
                          @hide="onHidePopover(scope)"
                          @show="onShowPopover(scope)">
                          <grade-cascader
                            :ref="'filterspecCompareStatus' + scope.$index"
                            :status="scope.row.specCompareStatus"
                            :show-filter="tableSearchObj.isFilter"
                            :filter-options="gradeOptions"
                            @closeFilter="onCloseFilter(scope)" />
                          <div slot="reference" @click="onGradeFilterClick(scope)">
                            <svg-icon
                              :icon-class="gradeIcon(scope.row.specCompareStatus)"
                              class="filter-icon"
                              v-if="tableSearchObj.isFilter"
                              style="position: absolute; right: 16px;top: 50%;transform: translateY(-50%);width: 18px;height: 18px;"/>
                              <transition>
                                <i v-if="scope.row.isFolded" class="el-icon-caret-bottom icon-class"/>
                                <i v-else class="el-icon-caret-top icon-class"/>
                              </transition>
                          </div>
                        </el-popover>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="剂型名称"
                      width="180">
                      <template slot-scope="scope">
                        <span>{{ scope.row.formNameCompare }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      width="50">
                      <template slot-scope="scope">
                        <span>{{ scope.row.formNameCompareStatus }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="厂商">
                      <template slot-scope="scope">
                        <span>{{ scope.row.manufacturerCompare }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      width="50">
                      <template slot-scope="scope">
                        <span>{{ scope.row.manufacturerCompareStatus }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="批准文号">
                      <template slot-scope="scope">
                        <span>{{ scope.row.approvalNoCompare }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      width="50">
                      <template slot-scope="scope">
                        <span>{{ scope.row.approvalNoCompareStatus }}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-main>
                <el-footer>
                  <pagination
                  v-show="total > 0"
                  :total="total"
                  :page.sync="currentPage"
                  :limit.sync="pageSize"
                  @pagination="getTableHandle"/>
                </el-footer>
              </el-container>
            </el-main>
          </el-container>
        </el-main>
      </el-container>
    </el-main>
  </el-container>
</template>
<script>
import {
  getChartsDataApi
} from '@/api/dm/institutionGovernanceReport'
import {
  FILTER_OPTIONS,
  GRADE_OPTIONS,
  TABLE_DATA,
  TABLE_DATA_ORIGIN,
  handleTableData
} from '@/utils/dm/institutionGovernanceReport/data'
import FilterCascader from './components/FilterCascader'
import GradeCascader from './components/GradeCascader'
import HistoryList from './components/HistoryList'
export default {
  name: 'institutionGovernanceReport',
  components: {
    FilterCascader,
    GradeCascader,
    HistoryList
  },
  data() {
    return {
      searchObj: {},
      filterOptions: this.$deepClone(FILTER_OPTIONS),
      gradeOptions: this.$deepClone( GRADE_OPTIONS),
      props: {
        multiple: true
      },
      tableSearchObj: {
        isFilter: true // 是否是筛选模式
      },
      spanArr: [],
      pos: 0,
      currentPage: 1,
      total: 10,
      pageSize: 10,
      chartsDataObj: {
        matchResult: [],
        problemItemResult: [],
        riskLevelResult: [],
        fieldProblemResult: []
      },
      tableData: [],
      typeOptions: [
        {}
      ]
    }
  },
  computed: {
    gradeIcon: function () {
      return function (status) {
        if (Number(status) === 1) {
          return 'kc-empty'
        } else if (Number(status) === 2) {
          return 'duihao2'
        } else if (Number(status) === 3) {
          return 'wenhao'
        }
      }
    }
  },
  async mounted() {
    await this.fetchChartsData()
    const data1 = this.chartsDataHandle(this.chartsDataObj.matchResult)
    let legendData1 = data1.legendData
    let seriesData1 = data1.seriesData
    const color1 = ['#768EBE', '#63DAAB']
    const data2 = this.chartsDataHandle(this.chartsDataObj.problemItemResult)
    let legendData2 = data2.legendData
    let seriesData2 = data2.seriesData
    const color2 = ['#E66E52', '#F6BD16']
    const data3 = this.chartsDataHandle(this.chartsDataObj.riskLevelResult)
    let legendData3 = data3.legendData
    let seriesData3 = data3.seriesData
    const color3 = ['#E66E52', '#5B8FF9', '#63DAAB']
    const data4 = this.chartsDataHandle(this.chartsDataObj.fieldProblemResult)
    let legendData4 = data4.legendData
    let seriesData4 = data4.seriesData
    const color4 = ['#5B8FF9', '#63DAAB', '#5D7092', '#E66E52', '#F7C122', '#945FB9']
    this.createPieCHart('pie-chart-one', color1, legendData1, seriesData1)
    this.createPieCHart('pie-chart-two', color2, legendData2, seriesData2)
    this.createPieCHart('pie-chart-three', color3, legendData3, seriesData3, true)
    this.createPieCHart('pie-chart-four', color4, legendData4, seriesData4, true)
  },
  async created() {
    await this.fetchTableData()
    console.log(this.$refs)
  },
  methods: {
    // 创建饼图的公共方法
    createPieCHart(chart, color, legendData, seriesData, isRing = false) {
      let radius = '50%'
      if (isRing) {
        // 环形图
        radius = ['50%', '50%'] //饼图的半径，第一个为内半径，第二个为外半径
      }
      let pie = this.$echarts.init(document.getElementById(chart))
      let data = this.buildData()
      let option = {
        backgroundColor: '#fff',
        title: {
          show: false,
          text: '销售量统计',
          x: 'center'
        },
        color,
        tooltip: {
          trigger: 'item',
          backgroundColor: '#fff',
          extraCssText: 'box-shadow: 0px 0px 12px 0px rgba(110, 110, 110, 0.6); border-radius: 4px;',
          textStyle: {
            color: '#333'
          },
          formatter: "{a} <br/>{b}  {c} ({d}%)"
        },
        legend: {
          show: true,
          orient: 'vertical',
          top: 'center',
          right: '25%',
          data: legendData
        },
        series: [{
          name: '星期',
          type: 'pie',
          radius,
          center: ['25%', '50%'],
          data: seriesData,
          label: {
            show: true,
            position: 'inside',
            formatter: function (p) {   //指示线对应文字,百分比
              return p.percent + "%"
            }
          },
          itemStyle: {
            borderWidth: 1, //设置border的宽度有多大
            borderColor:'#fff',
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              backgroundColor: '#fff',
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
        }]
      }
      pie.setOption(option)
      pie.on('click', function(params) {
        console.log(params.name, params.value, params.percent, params.seriesName, params.seriesType)
      })
      window.onresize = function () {
        pie.resize()
      }
    },
    // 获取图表数据
    async fetchChartsData() {
      let res = await getChartsDataApi({ applyId: '1' })
      if (res.code === 200) {
        this.chartsDataObj.matchResult = res.data.matchResult
        this.chartsDataObj.problemItemResult = res.data.problemItemResult
        this.chartsDataObj.riskLevelResult = res.data.riskLevelResult
        this.chartsDataObj.fieldProblemResult = res.data.fieldProblemResult
      }
    },
    // 处理图表获取到的数据
    chartsDataHandle(data) {
      let seriesData = []
      let legendData = []
      for (let item of data) {
        seriesData.push({
          value: item.count,
          name: item.name
        })
        legendData.push(
          {
            name: item.name,
            icon: 'circle',
            textStyle: {
              color: '#333',
              fontSize: 12
            }
          }
        )
      }
      return {
        seriesData,
        legendData
      }
    },
    buildData() {
      let labels = ['匹配项', '未匹配项']
      let values = []
      for (let i = 0; i < labels.length; i++) {
        values.push({
          value: parseInt(Math.random() * 10000),
          name: labels[i],
          color: this.randomColor()
        })
      }
      return {
        labels,
        values
      }
    },
    randomColor() {
      var r = Math.floor(Math.random() * 256)
      var g = Math.floor(Math.random() * 256)
      var b = Math.floor(Math.random() * 256)
      var color = '#' + r.toString(16) + g.toString(16) + b.toString(16)
      return color
    },
    async fetchTableData() {
      const tableData = this.$deepClone(TABLE_DATA_ORIGIN)
      let data = handleTableData(tableData)
      data.forEach(item => {
        item.isFolded = true // 打分的图标初始时为折叠状态
      })
      this.tableData = this.$deepClone(data)
      this.setMergeArr(this.tableData)
    },
    getTableHandle() {
      
    },
    filterChange(val) {
      if (val) {
      }
    },
    setMergeArr(data) {　
      this.spanArr = []
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1)
          this.pos = 0
        } else {
            // 判断当前元素与上一个元素是否相同
          if (data[i].ustdCode === data[i - 1].ustdCode) {
            this.spanArr[this.pos] += 1
            this.spanArr.push(0)
          } else {
            this.spanArr.push(1)
            this.pos = i
          }
        }
      }
    },
    getSummaries({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) { // 设置需要合并的列（索引）
        if (rowIndex % 2 === 0) { // 设置合并多少行
          return {
            rowspan: 2, // 需要合并的行数
            colspan: 1 // 需要合并的列数，设置为0，不显示该列
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      } else if (columnIndex % 2 === 0) { // 合并第二列所有行
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    // 表头渲染
    renderFilter(creatElement, { column, $index }) {
      return (
        <span class='el-dropdown-link' style="color:#ffffff"> 等级 <i class='el-icon-sort el-icon--right'></i></span>
      )
    },
    headerGradeInfo({ row, column, rowIndex, columnIndex }) {
      if (columnIndex % 2 === 1 && columnIndex !== 0) {
        return 'none-border'
      }
      if (columnIndex === 4) {
        console.log(column)
      }
    },
    gradeInfo({ row, column, rowIndex, columnIndex }) {
      let color = this.tableDataHandle(row, column, rowIndex, columnIndex)
      return color
    },
    tableDataHandle(row, column, rowIndex, columnIndex) {
      for (let i = 0; i < this.tableData.length ; i++) {
        let item = this.tableData[i]
        // let nextItem = this.tableData[i + 1]
        // 药品通用名
        if (item.genericNameCompareStatus === '1' && i === rowIndex && columnIndex % 2 === 1) {
          item.className = 'error-color'
          // nextItem.className = 'error-color'
          return 'error-color'
        } else if (item.genericNameCompareStatus === '2' && i === rowIndex && columnIndex % 2 === 1) {
          item.className = 'warning-color'
          // nextItem.className = 'warning-color'
          return 'warning-color'
        }
        // 规格
        if (item.specCompareStatus === '1' && i === rowIndex && columnIndex % 2 === 1) {
          item.className = 'error-color'
          // nextItem.className = 'error-color'
          return 'error-color'
        } else if (item.specCompareStatus === '2' && i === rowIndex && columnIndex % 2 === 1) {
          item.className = 'warning-color'
          // nextItem.className = 'warning-color'
          return 'warning-color'
        }
        // 剂型名称
        if (item.formNameCompareStatus === '1' && i === rowIndex && columnIndex % 2 === 1) {
          item.className = 'error-color'
          // nextItem.className = 'error-color'
          return 'error-color'
        } else if (item.formNameCompareStatus === '2' && i === rowIndex && columnIndex % 2 === 1) {
          item.className = 'warning-color'
          // nextItem.className = 'warning-color'
          return 'warning-color'
        }
        // 厂商
        if (item.manufacturerCompareStatus === '1' && i === rowIndex && columnIndex % 2 === 1) {
          item.className = 'error-color'
          // nextItem.className = 'error-color'
          return 'error-color'
        } else if (item.manufacturerCompareStatus === '2' && i === rowIndex && columnIndex % 2 === 1) {
          item.className = 'warning-color'
          // nextItem.className = 'warning-color'
          return 'warning-color'
        }
        // 批准文号
        if (item.approvalNoCompareStatus === '1' && i === rowIndex && columnIndex % 2 === 1) {
          item.className = 'error-color'
          // nextItem.className = 'error-color'
          return 'error-color'
        } else if (item.approvalNoCompareStatus === '2' && i === rowIndex && columnIndex % 2 === 1) {
          item.className = 'warning-color'
          // nextItem.className = 'warning-color'
          return 'warning-color'
        }
      }
    },
    // 筛选模式使用的组件popover隐藏时的事件
    onHidePopover(scope) {
      const filterName = 'filter' + scope.$index
      this.$nextTick(() => {
        this.tableData.forEach(item => {
          item.isFolded = true // 打分的图标初始时为折叠状态
        })
        if (this.$refs[filterName] && this.$refs[filterName].filterOptions) {
          this.$refs[filterName].filterOptions.forEach(option => {
            option.showChildren = false
          })
        }
        if (this.filterOptions) {
          this.filterOptions.forEach(option => {
            option.showChildren = false
          })
        }
      })
    },
    // 筛选模式使用的组件popover显示时的事件
    onShowPopover(scope) {
      const filterName = 'filter' + scope.$index
      this.$nextTick(() => {
        if (this.$refs[filterName]) {
          this.$refs[filterName].checkList = []
          this.$refs[filterName].isShowOptions = true
        }
      })
    },
    // 筛选模式，点击筛选图标事件
    onFilterClick(scope) {
      console.log(scope)
      const filterName = 'filter' + scope.$index
      this.$nextTick(() => {
        if (this.$refs[filterName]) {
          this.$refs[filterName].onFilterClick()
        }
      })
    },
    onGradeFilterClick(scope) {
      console.log(scope)
      scope.row.isFolded = false // 展开
      const refName = 'filter' + scope.column.property + scope.$index
      this.$nextTick(() => {
        if (this.$refs[refName]) {
          this.$refs[refName].onFilterClick()
        }
      })
    },
    // 打分下拉项关闭
    onCloseFilter(scope) {
      this.tableData.forEach(item => {
        item.isFolded = true // 打分的图标初始时为折叠状态
      })
      console.log(this.$refs)
      const refName = 'popover' + scope.column.property + scope.$index
      this.$nextTick(() => {
        if (this.$refs.elPopover) {
          // this.$refs.elPopover.$listeners.hide()
          this.$refs[refName].doClose()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.box-shadow-radius {
  box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
  border-radius: 4px;
}
.icon-class {
  display: inline-block;
  position: absolute;
  right: 0px;
  transform: translateY(-50%);
  color: #2B60F8;
  cursor: pointer;
}
.filter-icon {
  cursor: pointer;
  &::after {
    content: '4444';
    display: inline-block;
    width: 12px;
    height: 12px;
    background: pink;
  }
}
.chart-title {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  &:before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 16px;
    background: #A9BBFF;
    border-radius: 2px;
    transform: translateY(3px);
    margin-right: 5px;
  }
}
.el-table--border th.none-border, .el-table--border td.none-border {
  border-right: none !important;
}
.el-tabe td.none-border {
  border-right: none !important;
}
.el-main, .el-header {
  padding: 15px;
}
.insti-report-container {
  background-color: #EBEFF7;
  .insti-report-header {
    margin: 10px;
    background: #fff;
    height: auto !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 15px !important;
    .el-form-item {
      margin-bottom: 0 !important;
    }
  }
  .insti-report-content-container {
    padding-left: 10px;
    /* height: calc(100% - 40px) !important; */
    .el-aside {
      background: #fff;
    }
    .content-main {
      padding: 0 10px 0 !important;
      .chart-container {
        height: 200px !important;
        margin-bottom: 10px;
        background-color: #fff;
      }
      .table-container {
        background: #fff;
        font-size: 12px !important;
        ::v-deep td {
          padding: 3px 5px !important;
        }
        ::v-deep.grade-table {
          th.none-border, td.none-border {
            border-right: none !important;
          }
          td.error-color {
            background-color: #FFDBE1;
          }
          td.warning-color {
            background-color: #FFE9D1;
          }
        }
      }
    }
  }
}
</style>
