var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { ref: "headerQuery" },
        [
          _c(
            "el-row",
            { staticClass: "headerClass" },
            [
              _c("el-col", { staticClass: "title", attrs: { span: 4 } }, [
                _vm._v("组合包装药品管理"),
              ]),
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "queryForm",
                      staticClass: "query-form",
                      staticStyle: { float: "right" },
                      attrs: { model: _vm.queryParams, inline: true },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-autocomplete",
                            {
                              staticStyle: {
                                width: "300px",
                                height: "36px!important",
                                "line-height": "36px",
                              },
                              attrs: {
                                "fetch-suggestions": _vm.querySearchAsync,
                                placeholder: "请输入药品ID/名称",
                                clearable: "",
                                size: "small",
                                "value-key": "drugName",
                              },
                              on: {
                                select: _vm.handleQuery,
                                clear: function ($event) {
                                  return _vm.setBlur()
                                },
                              },
                              nativeOn: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.handleQuery($event)
                                },
                              },
                              model: {
                                value: _vm.queryParams.searchValue,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "searchValue", $$v)
                                },
                                expression: "queryParams.searchValue",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "74px" },
                                  attrs: {
                                    slot: "prepend",
                                    placeholder: "请选择",
                                  },
                                  slot: "prepend",
                                  model: {
                                    value:
                                      _vm.queryParams.searchValueSearchType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "searchValueSearchType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "queryParams.searchValueSearchType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "模糊", value: "1" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "精确", value: "2" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "前缀", value: "3" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "status" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "statusClass",
                              staticStyle: { width: "74px" },
                              attrs: {
                                placeholder: "状态",
                                size: "small",
                                clearable: "",
                              },
                              model: {
                                value: _vm.queryParams.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "status", $$v)
                                },
                                expression: "queryParams.status",
                              },
                            },
                            _vm._l(_vm.statusOptions, function (dict) {
                              return _c("el-option", {
                                key: dict.conceptId,
                                attrs: {
                                  label: dict.conceptVal,
                                  value: dict.conceptId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-search",
                                size: "small",
                              },
                              on: { click: _vm.handleQuery },
                            },
                            [_vm._v("检索")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "addClass",
                              attrs: {
                                icon: "el-icon-circle-plus-outline",
                                size: "small",
                              },
                              on: { click: _vm.handleAdd },
                            },
                            [_vm._v("新增药品")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { icon: "fa fa-cog", size: "small" },
                              on: { click: _vm.columnSettings },
                            },
                            [_vm._v("设置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { ref: "tableContainer", attrs: { id: "drugProdDiv" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "refTable",
              attrs: {
                border: "",
                stripe: "",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
                height: _vm.tableHeight,
                data: _vm.drugList,
                "row-key": "id",
                "expand-row-keys": _vm.drugTableExpandRowIds,
                "row-class-name": _vm.tableRowClass,
              },
              on: {
                "expand-change": _vm.expandTableRow,
                "sort-change": _vm.tableSortChange,
                "row-contextmenu": _vm.rowContextmenu,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand", width: "45", fixed: "left" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            attrs: {
                              border: "",
                              stripe: "",
                              "header-cell-style": { "text-align": "center" },
                              "cell-style": { "text-align": "center" },
                              data: props.row.drugProdList,
                              "cell-class-name": _vm.tableCellClass,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                property: "id",
                                label: "药品ID",
                                width: "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: { label: "药品名称", width: "300" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-right": "5px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.drugProdName)
                                            ),
                                          ]
                                        ),
                                        _vm._l(
                                          scope.row.drugCategoryList,
                                          function (item) {
                                            return _c(
                                              "span",
                                              { key: item.id },
                                              [
                                                item.icon
                                                  ? _c("svg-icon", {
                                                      attrs: {
                                                        "icon-class": item.icon,
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                property: "approvalNum",
                                label: "批准文号",
                                width: "200",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                property: "manufacturerName",
                                label: "生产企业",
                                width: "200",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                property: "specDesc",
                                label: "规格",
                                width: "200",
                              },
                            }),
                            _c("template", { slot: "empty" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/no-booking.svg"),
                                  alt: "",
                                },
                              }),
                              _c("p", [_vm._v("暂无数据")]),
                            ]),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._l(_vm.columnOptions, function (data) {
                return _c("el-table-column", {
                  key: data.id,
                  attrs: {
                    prop: data.prop,
                    label: data.name,
                    fixed: data.fixed ? data.fixed : false,
                    resizable: false,
                    sortable: data.sortable ? data.sortable : false,
                    "min-width": data.width ? data.width : "80",
                    align: data.align ? data.align : "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            data.prop == "indate"
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.indate) +
                                      " " +
                                      _vm._s(scope.row.indateUnitDesc) +
                                      " "
                                  ),
                                ])
                              : data.prop == "drugName"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "5px" },
                                      },
                                      [_vm._v(_vm._s(scope.row[data.prop]))]
                                    ),
                                    _vm._l(
                                      scope.row.drugCategoryList,
                                      function (item) {
                                        return _c(
                                          "span",
                                          { key: item.id },
                                          [
                                            item.icon
                                              ? _c("svg-icon", {
                                                  attrs: {
                                                    "icon-class": item.icon,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : data.prop == "specDesc"
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.packQty +
                                          "/" +
                                          scope.row.specUnitDesc +
                                          "/" +
                                          scope.row.packQtyUnitDesc
                                      ) +
                                      " "
                                  ),
                                ])
                              : data.prop == "pdProdMatList"
                              ? _c(
                                  "div",
                                  _vm._l(
                                    scope.row.pdProdMatList,
                                    function (item, index) {
                                      return _c(
                                        "P",
                                        {
                                          key: item.id + "pdProdMatList",
                                          staticStyle: { "text-align": "left" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s("[" + (index + 1) + "]") +
                                              _vm._s(item.wrapperDesc) +
                                              " "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.isEmpty(
                                                    item.stateMedicareNum
                                                  ),
                                                  expression:
                                                    "!isEmpty(item.stateMedicareNum)",
                                                },
                                              ],
                                              staticStyle: {
                                                "margin-left": "10px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "国家医保代码:" +
                                                  _vm._s(item.stateMedicareNum)
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.isEmpty(
                                                    item.shMedicareNum
                                                  ),
                                                  expression:
                                                    "!isEmpty(item.shMedicareNum)",
                                                },
                                              ],
                                              staticStyle: {
                                                "margin-left": "10px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "上海医保代码:" +
                                                  _vm._s(item.shMedicareNum)
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    index <
                                                    scope.row.pdProdMatList
                                                      .length -
                                                      1,
                                                  expression:
                                                    "index<scope.row.pdProdMatList.length-1",
                                                },
                                              ],
                                            },
                                            [_vm._v(";")]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _c("div", [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row[data.prop])),
                                  ]),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                key: "status",
                attrs: {
                  label: "状态",
                  align: "center",
                  width: "100",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          staticClass: "switch",
                          attrs: {
                            "active-color": "#5DB730",
                            "active-text": "启用",
                            "inactive-text": "停用",
                            "inactive-color": "#B5CCF5",
                            "active-value": "0",
                            "inactive-value": "1",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleStatusChange(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.status,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "status", $$v)
                            },
                            expression: "scope.row.status",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm.isResult === false
                ? _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-booking.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ])
                : _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-left-data.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("未查询到结果，请修改查询条件重试！")]),
                  ]),
            ],
            2
          ),
        ],
        1
      ),
      _vm.tableContextMenuVisible
        ? _c("table-context-menu", {
            ref: "contextbutton",
            attrs: { action: _vm.tableAction },
            on: { foo: _vm.foo, handleTableAction: _vm.handleTableAction },
          })
        : _vm._e(),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
          "page-sizes": [10, 20, 50, 100],
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.getComboDrugList,
        },
      }),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: { title: "引用来源管理", visible: _vm.resourceTableVisible },
          on: {
            "update:visible": function ($event) {
              _vm.resourceTableVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: {
                        icon: "el-icon-circle-plus-outline",
                        size: "small",
                      },
                      on: { click: _vm.addResource },
                    },
                    [_vm._v("添加 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "resourceTableForm",
              attrs: { "status-icon": "", model: _vm.resourceTableForm },
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    stripe: "",
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    data: _vm.resourceTableForm.list,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      property: "date",
                      label: "引用资源类型",
                      align: "center",
                      width: "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "list." +
                                    scope.$index +
                                    "." +
                                    "resourceTypeId",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择物质名称",
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "150px" },
                                    attrs: {
                                      placeholder: "引用资源类型",
                                      size: "small",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.resourceTypeChange(
                                          $event,
                                          scope.$index
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.resourceTypeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "resourceTypeId",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.resourceTypeId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.resourceTypeOption,
                                    function (dict) {
                                      return _c("el-option", {
                                        key: "resourceTypeOption" + dict.id,
                                        attrs: {
                                          label: dict.typeName,
                                          value: dict.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "name",
                      align: "center",
                      label: "引用资源名称",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "300px" },
                                attrs: {
                                  filterable: "",
                                  remote: "",
                                  "reserve-keyword": "",
                                  placeholder: "请输入关键词",
                                  "remote-method": function (query) {
                                    _vm.resourceNameQuery(
                                      query,
                                      scope.row.resourceTypeId
                                    )
                                  },
                                  loading: _vm.loading,
                                  disabled: !scope.row.resourceTypeId
                                    ? true
                                    : false,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.resourceNameChange(
                                      $event,
                                      scope.$index
                                    )
                                  },
                                },
                                model: {
                                  value: scope.row.resourceId,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "resourceId", $$v)
                                  },
                                  expression: "scope.row.resourceId",
                                },
                              },
                              [
                                _vm._l(_vm.resourceDataOption, function (item) {
                                  return _c("el-option", {
                                    key: "resourceDataOption" + item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                _vm._l([scope.row], function (item) {
                                  return _c("el-option", {
                                    key: item.resourceId + "original",
                                    attrs: {
                                      label: item.resourceName,
                                      value: item.resourceId,
                                    },
                                  })
                                }),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "center",
                      "class-name": "small-padding fixed-width",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "#FF3333" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleResourceDelete(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-booking.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "margin-top": "10px" }, attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.saveResource },
                    },
                    [_vm._v("保存 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.resourceTableVisible = false
                        },
                      },
                    },
                    [_vm._v("取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }