import request from '@/utils/request'

// 查询上传记录列表
export function uploadRecordList(query) {
  return request({
    url: '/tools/cfdaDrug/uploadRecordList',
    method: 'post',
    params: query
  })
}


// 上传文件
export function uploadFiles(data) {
  return request({
    url: '/tools/cfdaDrug/uploadFiles',
    method: 'post',
    data: data,
    timeout: 30 * 1000,
  })
}


// 获取上传任务执行进度
export function getProgress() {
  return request({
    url: '/tools/cfdaDrug/getProgress',
    method: 'get'
  })
}


// 删除版本数据
export function delVersion(versionNo) {
  return request({
    url: '/tools/cfdaDrug/delVersion/' + versionNo,
    method: 'get',
    timeout: 60 * 1000,
  })
}


// 下载差异报告
export function downloadReport(versionNo) {
  return request({
    url: '/tools/cfdaDrug/downloadReportFile',
    method: 'get',
    params: {versionNo:versionNo},
    responseType: 'blob'
  })
}



// 查询全部版本号
export function selectAllVersionNo() {
  return request({
    url: '/tools/cfdaDrug/selectAllVersionNo',
    method: 'get'
  })
}



// 查询药品列表
export function cfdaDrugList(query) {
  return request({
    url: '/tools/cfdaDrug/drugList',
    method: 'post',
    params: query,
    timeout: 30 * 1000,
  })
}
