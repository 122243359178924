var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { ref: "headerQuery" },
        [
          _c(
            "el-row",
            { staticClass: "headerClass" },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "border-left": "5px solid #0073E9",
                    "padding-left": "10px",
                  },
                  attrs: { span: 4 },
                },
                [_vm._v("药物管理")]
              ),
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showSearch,
                          expression: "showSearch",
                        },
                      ],
                      ref: "queryForm",
                      staticClass: "query-form",
                      staticStyle: { float: "right" },
                      attrs: { model: _vm.queryParams, inline: true },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "140px" },
                          attrs: { label: "" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                remote: "",
                                size: "small",
                                multiple: "",
                                "collapse-tags": "",
                                clearable: "",
                                placeholder: "药物分类",
                              },
                              model: {
                                value: _vm.queryParams.drugClassify,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "drugClassify", $$v)
                                },
                                expression: "queryParams.drugClassify",
                              },
                            },
                            _vm._l(_vm.drugClassifyOptions, function (item) {
                              return _c("el-option", {
                                key: "dkb_drug_classify" + item.conceptId,
                                attrs: {
                                  label: item.conceptVal,
                                  value: item.conceptId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "el-item-dropdown-300",
                          staticStyle: { width: "200px" },
                          attrs: { label: "" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                "popper-append-to-body": false,
                                filterable: "",
                                remote: "",
                                multiple: "",
                                size: "small",
                                clearable: "",
                                "collapse-tags": "",
                                "reserve-keyword": "",
                                placeholder: "物质ID/名称",
                                "remote-method": _vm.remoteMethodSL,
                                loading: _vm.loading,
                              },
                              model: {
                                value: _vm.queryParams.substanceIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "substanceIds", $$v)
                                },
                                expression: "queryParams.substanceIds",
                              },
                            },
                            _vm._l(_vm.substanceList, function (item) {
                              return _c("el-option", {
                                key: item.substanceId + item.name,
                                attrs: {
                                  label: item.name,
                                  value: item.substanceId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "el-item-dropdown-300",
                          staticStyle: { width: "200px" },
                          attrs: { label: "" },
                        },
                        [
                          _c(
                            "el-autocomplete",
                            {
                              staticStyle: {
                                height: "36px!important",
                                "line-height": "36px",
                              },
                              attrs: {
                                "popper-append-to-body": false,
                                "fetch-suggestions": _vm.querySearchAsync,
                                placeholder: "药物ID/名称",
                                clearable: "",
                                size: "small",
                                "value-key": "name",
                              },
                              on: {
                                select: _vm.handleQuery,
                                clear: function ($event) {
                                  return _vm.setBlur()
                                },
                              },
                              model: {
                                value: _vm.queryParams.searchValue,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "searchValue", $$v)
                                },
                                expression: "queryParams.searchValue",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "74px" },
                                  attrs: {
                                    slot: "prepend",
                                    placeholder: "请选择",
                                  },
                                  slot: "prepend",
                                  model: {
                                    value:
                                      _vm.queryParams.searchValueSearchType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "searchValueSearchType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "queryParams.searchValueSearchType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "模糊", value: "1" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "精确", value: "2" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "前缀", value: "3" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "status" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "statusClass",
                              staticStyle: { width: "74px" },
                              attrs: {
                                placeholder: "状态",
                                clearable: "",
                                size: "small",
                              },
                              model: {
                                value: _vm.queryParams.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "status", $$v)
                                },
                                expression: "queryParams.status",
                              },
                            },
                            _vm._l(_vm.statusOptions, function (dict) {
                              return _c("el-option", {
                                key: dict.conceptId,
                                attrs: {
                                  label: dict.conceptVal,
                                  value: dict.conceptId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-search",
                                size: "small",
                              },
                              on: { click: _vm.handleQuery },
                            },
                            [_vm._v("检索")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "addClass",
                              attrs: {
                                icon: "el-icon-circle-plus-outline",
                                size: "small",
                              },
                              on: { click: _vm.handleAdd },
                            },
                            [_vm._v("新增药物")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-circle-plus-outline",
                                size: "small",
                              },
                              on: { click: _vm.columnSettings },
                            },
                            [_vm._v("设置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { ref: "tableContainer" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "refundTable",
              attrs: {
                border: "",
                stripe: "",
                data: _vm.drugList,
                height: _vm.tableHeight,
              },
              on: {
                "sort-change": _vm.tableSortChange,
                "row-contextmenu": _vm.rowContextmenu,
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              _vm.isSelection
                ? _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  })
                : _vm._e(),
              _vm._l(_vm.columnOptions, function (data) {
                return _c("el-table-column", {
                  key: "drug-list" + data.id,
                  attrs: {
                    prop: data.prop,
                    label: data.name,
                    fixed: data.fixed,
                    resizable: false,
                    sortable: data.sortable,
                    "min-width": data.width,
                    align: data.align,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            data.prop == "substanceComponent"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          trigger: "click",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticStyle: {
                                                  "text-align": "center",
                                                },
                                              },
                                              [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(scope.row.name)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._l(
                                              scope.row.dkbDrugIngredientList,
                                              function (item1) {
                                                return _c("p", [
                                                  _vm._v(" ["),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(item1.substanceId)
                                                    ),
                                                  ]),
                                                  _vm._v("]-"),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item1.substanceName
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v("("),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item1.substanceTypeDesc
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(")； "),
                                                ])
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "name-wrapper setTwoLine",
                                            attrs: { slot: "reference" },
                                            slot: "reference",
                                          },
                                          _vm._l(
                                            scope.row.dkbDrugIngredientList,
                                            function (item1) {
                                              return _c("span", [
                                                _c(
                                                  "span",
                                                  {
                                                    class: {
                                                      delClass:
                                                        item1.status === "1",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(" ["),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item1.substanceId
                                                        )
                                                      ),
                                                    ]),
                                                    _vm._v("]-"),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item1.substanceName
                                                        )
                                                      ),
                                                    ]),
                                                    _vm._v("("),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item1.substanceTypeDesc
                                                        )
                                                      ),
                                                    ]),
                                                    _vm._v(")； "),
                                                  ]
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : data.prop == "name"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          trigger: "click",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _vm._l(
                                          scope.row.dkbDrugAliasList,
                                          function (item) {
                                            return _c("div", { key: item.id }, [
                                              item.isPrimary === "1"
                                                ? _c(
                                                    "div",
                                                    _vm._l(
                                                      item.dkbReferenceInfoList,
                                                      function (item1) {
                                                        return _c(
                                                          "p",
                                                          { key: item1.id },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  "[" +
                                                                    item1.resourceTypeName +
                                                                    "]"
                                                                )
                                                              ),
                                                            ]),
                                                            _vm._v(
                                                              _vm._s(
                                                                item1.resourceName ||
                                                                  ""
                                                              ) + " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e(),
                                            ])
                                          }
                                        ),
                                        _vm.isShowIcon(
                                          scope.row.dkbDrugAliasList
                                        )
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: { width: "50px" },
                                                attrs: { slot: "reference" },
                                                slot: "reference",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-question",
                                                  attrs: {
                                                    title: "点击查看来源引用",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showResourceReference(
                                                        scope.row,
                                                        _vm.tableConst
                                                          .DKB_DRUG_ALIAS
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          trigger: "click",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _vm._l(
                                          scope.row.dkbDrugAliasList,
                                          function (item) {
                                            return _c("p", { key: item.id }, [
                                              _vm._v(
                                                " " + _vm._s(item.alias) + " "
                                              ),
                                            ])
                                          }
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "name-wrapper",
                                            attrs: { slot: "reference" },
                                            slot: "reference",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "#2B60F8",
                                                  "font-weight": "700",
                                                  "margin-right": "5px",
                                                },
                                                attrs: {
                                                  title: "点击查看全部药物名称",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(scope.row[data.prop])
                                                ),
                                              ]
                                            ),
                                            _vm._l(
                                              scope.row.drugCategoryList,
                                              function (item) {
                                                return _c(
                                                  "span",
                                                  {
                                                    key: item.id,
                                                    staticStyle: {
                                                      color: "#2B60F8",
                                                    },
                                                  },
                                                  [
                                                    item.icon
                                                      ? _c("svg-icon", {
                                                          attrs: {
                                                            "icon-class":
                                                              item.icon,
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row[data.prop])),
                                  ]),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              _c("el-table-column", {
                key: "status",
                attrs: {
                  label: "状态",
                  align: "center",
                  width: "100",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          staticClass: "switch",
                          attrs: {
                            "active-color": "#5DB730",
                            "active-text": "启用",
                            "inactive-text": "停用",
                            "inactive-color": "#B5CCF5",
                            "active-value": "0",
                            "inactive-value": "1",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleStatusChange(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.status,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "status", $$v)
                            },
                            expression: "scope.row.status",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm.isResult === false
                ? _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-booking.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ])
                : _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-left-data.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("未查询到结果，请修改查询条件重试！")]),
                  ]),
            ],
            2
          ),
        ],
        1
      ),
      _vm.tableContextMenuVisible
        ? _c("table-context-menu", {
            ref: "contextbutton",
            attrs: { action: _vm.tableAction },
            on: { foo: _vm.foo, handleTableAction: _vm.handleTableAction },
          })
        : _vm._e(),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
          "page-sizes": [10, 20, 50, 100],
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.selectDrugList,
        },
      }),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "引用来源管理[" + _vm.drugTitle + "]",
            visible: _vm.resourceTableVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.resourceTableVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: {
                        icon: "el-icon-circle-plus-outline",
                        size: "small",
                      },
                      on: { click: _vm.addResource },
                    },
                    [_vm._v("添加 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "resourceTableForm",
              attrs: { "status-icon": "", model: _vm.resourceTableForm },
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    stripe: "",
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    data: _vm.resourceTableForm.list,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { property: "date", label: "引用资源类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "list." +
                                    scope.$index +
                                    "." +
                                    "resourceTypeId",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择药物名称",
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      placeholder: "引用资源类型",
                                      size: "small",
                                    },
                                    model: {
                                      value: scope.row.resourceTypeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "resourceTypeId",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.resourceTypeId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.resourceTypeOption,
                                    function (dict) {
                                      return _c("el-option", {
                                        key: "resourceTypeOption" + dict.id,
                                        attrs: {
                                          label: dict.typeName,
                                          value: dict.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { property: "name", label: "引用资源名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "200px" },
                                attrs: {
                                  filterable: "",
                                  remote: "",
                                  "reserve-keyword": "",
                                  placeholder: "请输入关键词",
                                  "remote-method": function (query) {
                                    _vm.resourceNameQuery(
                                      query,
                                      scope.row.resourceTypeId
                                    )
                                  },
                                  loading: _vm.loading,
                                  disabled: !scope.row.resourceTypeId
                                    ? true
                                    : false,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.resourceNameChange(
                                      $event,
                                      scope.$index
                                    )
                                  },
                                },
                                model: {
                                  value: scope.row.resourceId,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "resourceId", $$v)
                                  },
                                  expression: "scope.row.resourceId",
                                },
                              },
                              [
                                _vm._l(_vm.resourceDataOption, function (item) {
                                  return _c("el-option", {
                                    key: "resourceDataOption" + item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                _vm._l([scope.row], function (item) {
                                  return _c("el-option", {
                                    key: item.resourceId + "original",
                                    attrs: {
                                      label: item.resourceName,
                                      value: item.resourceId,
                                    },
                                  })
                                }),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "center",
                      "class-name": "small-padding fixed-width",
                      width: "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "#FF3333" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleResourceDelete(
                                      scope.$index,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-booking.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "margin-top": "10px" }, attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.saveResource },
                    },
                    [_vm._v("保存 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right", "margin-right": "10px" },
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.resourceTableVisible = false
                        },
                      },
                    },
                    [_vm._v("取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "新增药物组",
            "close-on-click-modal": false,
            visible: _vm.drawer,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "drugGroupForm", attrs: { model: _vm.drugGroup } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "药物组名称：",
                    prop: "name",
                    rules: [
                      {
                        required: true,
                        message: "请输入药物组名称",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.drugGroup.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.drugGroup, "name", $$v)
                      },
                      expression: "drugGroup.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { padding: "0px 0px 15px 15px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c(
                    "el-col",
                    { staticClass: "title-bar", attrs: { span: 22 } },
                    [_vm._v("药物组管理")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "rno",
                          attrs: {
                            data: _vm.resultNameOptions,
                            height: 400,
                            border: "",
                            stripe: "",
                            "header-cell-style": { "text-align": "center" },
                            "cell-style": { "text-align": "center" },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              property: "drugId",
                              label: "药物ID",
                              width: "150",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { property: "drugName", label: "药物名称" },
                          }),
                          _c("el-table-column", {
                            attrs: { property: "drugName", label: "药物名称" },
                          }),
                          _c("el-table-column", {
                            key: "status",
                            attrs: {
                              label: "主要药物",
                              align: "center",
                              width: "100",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-switch", {
                                      staticClass: "switch",
                                      attrs: {
                                        "active-color": "#5DB730",
                                        "active-text": "是",
                                        "inactive-text": "否",
                                        "inactive-color": "#B5CCF5",
                                        "active-value": "1",
                                        "inactive-value": "0",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handlePrimaryChange(
                                            scope.row,
                                            scope.index
                                          )
                                        },
                                      },
                                      model: {
                                        value: scope.row.isPrimary,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "isPrimary", $$v)
                                        },
                                        expression: "scope.row.isPrimary",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "操作", width: "150" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { color: "#FF3333" },
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClick(
                                              scope.$index,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("template", { slot: "empty" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/no-booking.svg"),
                                alt: "",
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.drawer = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "已有药物组",
            "close-on-click-modal": false,
            visible: _vm.areadyDrawer,
          },
          on: {
            "update:visible": function ($event) {
              _vm.areadyDrawer = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "areadyDrugGroupForm",
              attrs: { model: _vm.areadyDrugGroup },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "药物组名称：" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "",
                        prop: "name",
                        rules: [
                          {
                            required: true,
                            message: "请输入药物组名称",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-autocomplete", {
                        staticStyle: {
                          width: "300px",
                          height: "36px!important",
                          "line-height": "36px",
                        },
                        attrs: {
                          "fetch-suggestions": _vm.querySearchGroupAsync,
                          placeholder: "输入药物组名称",
                          clearable: "",
                          size: "small",
                          "value-key": "name",
                        },
                        on: {
                          select: _vm.handleDrugGroupQuery,
                          clear: function ($event) {
                            return _vm.setBlur()
                          },
                        },
                        model: {
                          value: _vm.areadyDrugGroup.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.areadyDrugGroup, "name", $$v)
                          },
                          expression: "areadyDrugGroup.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.areadyDrawer = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.areadySubmitForm },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("drug-category-edit", { ref: "drugCategoryEdit" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }