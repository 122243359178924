<template>
  <div class="app-container" style="overflow: auto">


    <div ref="headerQuery">
      <el-row class="headerClass">
        <el-col :span="4" style="border-left: 5px solid #0073E9;padding-left: 10px;">用户行为记录列表</el-col>
        <el-col :span="20">
          <el-form :model="queryParams" ref="queryForm" :inline="true" style="float: right">


            <el-form-item label="用户Id/名称:" prop="userQuery">
              <el-input v-model="queryParams.userQuery" maxlength="30" clearable style="width: 200px;"></el-input>
            </el-form-item>

            <el-form-item label="行为标签:" prop="actionLabel">

              <el-select
                :popper-append-to-body="false"
                v-model="queryParams.actionLabels"
                filterable
                remote
                collapse-tags
                multiple
                style="width: 300px;"
                reserve-keyword
                placeholder="标签名称"
                :remote-method="queryLabelList"
                :loading="loading">
                <el-option
                  v-for="item in labelList"
                  :key="item.id+item.configVal1"
                  :label="item.configVal1"
                  :value="item.configVal1">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="操作时间">
              <el-date-picker
                v-model="queryParams.dateRange"
                size="small"
                style="width: 240px"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">检索</el-button>
              <el-button icon="fa fa-cog" size="small" @click="columnSettings">设置</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>


    <div ref="tableContainer">
      <el-table
        border
        stripe
        ref="refundTable"
        :data="tableDataList"
        :height="tableHeight"
        v-loading="loading"
        @sort-change="tableSortChange"
        @row-contextmenu="rowContextmenu"
      >
        <el-table-column
          v-for="data in columnOptions"
          :key="'columnOptions'+data.id"
          :prop="data.prop"
          :label="data.name"
          :fixed="data.fixed"
          :resizable="false"
          :sortable="data.sortable"
          :min-width="data.width"
          :align="data.align"
        >
          <template slot-scope="scope">


            <div v-if="data.prop == 'status'">
              <el-tag type="info" v-if="scope.row.status=='0'">停用</el-tag>
              <el-tag type="success" v-if="scope.row.status=='1'">启用</el-tag>
            </div>

            <div v-else-if="data.prop == 'userId'">
              [{{ scope.row.userId }}]{{ scope.row.userName }}
            </div>

            <div v-else-if="data.prop == 'browserName'">
              {{ scope.row.browserName }}({{ scope.row.browserVersion }})
            </div>
            <div v-else-if="data.prop == 'nodeContent'">
              <p v-html="scope.row.showNodeContent"></p>
              <p v-show="scope.row.nodeName=='input' || scope.row.nodeName=='textarea'">{{ scope.row.nodeText }}</p>
            </div>
            <!--            用户行为标签-->
            <div v-else-if="data.prop == 'actionLabel'">
              <el-tag size="mini" :key="'actionLabel'+item" v-for="item in scope.row.actionLabelList">{{ item }}</el-tag>
            </div>

            <div v-else-if="data.prop == 'actionRecordDetailList'">
              <el-popover placement="top" title="全部api" trigger="click" width="400" height="400" popper-class="actionRecordDetailListPopover">
                <div>
                  <p v-for="item in scope.row.actionRecordDetailList" :key="'actionRecordDetail'+item.id">
                    {{ item.apiName || item.requestUrl }}
                  </p>
                </div>
                <span slot="reference" class="setTwoLine1" style="color: #2B60F8;font-weight: 700;" title="点击查看全部物质描述">
                  <p v-for="item in scope.row.actionRecordDetailList.length>5?
                  scope.row.actionRecordDetailList.slice(0,5):scope.row.actionRecordDetailList">
                    {{ item.apiName || item.requestUrl }}</p>

                </span>
              </el-popover>
            </div>

            <div v-else>
              <span>{{ scope.row[data.prop] }}</span>
            </div>
          </template>
        </el-table-column>

        <template slot="empty" v-if="tableDataList.length==0">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
    </div>

    <table-context-menu v-if="tableContextMenuVisible"
                        @foo="foo" ref="contextbutton"
                        :action="tableAction"
                        @handleTableAction="handleTableAction"
    ></table-context-menu>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="actionRecordList"
      :page-sizes="[10, 20, 50, 100]"
    />


  </div>
</template>

<script>

import AcrossUtil from '@/utils/acrossUtil'
import {UCgetConfig} from '@/utils/columnSettings'
import {GLOBAL_CONFIGURATION_CONFIGKEY} from '@/utils/constants'
import {isEmpty} from '@/utils/common'
import {actionRecordList, addFilterActionKey, addActionLabel, selectActionLabelList} from '@/api/system/sysActionRecord'

export default {
  name: 'RecordList',
  components: {},
  data() {
    return {
      // 遮罩层
      loading: true,
      tableDataList: [],
      // 总条数
      total: 0,
      // 表格高度
      tableHeight: window.innerHeight - 100 - 100,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        userQuery: null,
        dateRange: [],
        actionLabels: undefined,
        orderByColumn: undefined,
        isAsc: undefined
      },
      sltConfigKey: '',
      sltConfigClassify: '',
      columnOptions: [],
      tableOptions: [
        {
          id: 1,
          prop: 'userId',
          name: '用户信息',
          fixed: true,
          show: true,
          sortable: 'custom',
          align: 'center',
          width: '100'
        },
        {
          id: 2,
          prop: 'actionTime',
          name: '操作时间',
          fixed: false,
          show: true,
          align: 'center',
          sortable: 'custom',
          width: '100'
        },
        {
          id: 3,
          prop: 'url',
          name: '访问url',
          fixed: false,
          show: true,
          align: 'center',
          sortable: 'custom',
          width: '100'
        },
        {
          id: 4,
          prop: 'osName',
          name: '操作系统',
          fixed: false,
          show: true,
          align: 'center',
          width: '60'
        },
        {
          id: 5,
          prop: 'browserName',
          name: '浏览器',
          fixed: false,
          show: true,
          align: 'center',
          width: '100'
        },
        {
          id: 6,
          prop: 'actionLabel',
          name: '行为标签',
          fixed: false,
          show: true,
          align: 'center',
          width: '100'
        },
        {
          id: 7,
          prop: 'nodeContent',
          name: '点击内容',
          fixed: false,
          show: true,
          align: 'center',
          width: '200'
        },
        {
          id: 8,
          prop: 'actionRecordDetailList',
          name: '调用api列表',
          fixed: false,
          show: true,
          align: 'center',
          width: '200'
        },
        {
          id: 9,
          prop: 'actionKey',
          name: 'actionKey',
          fixed: false,
          show: true,
          align: 'center',
          width: '150'
        },
        {
          id: 10,
          prop: 'userIp',
          name: '用户IP',
          fixed: false,
          show: true,
          align: 'center',
          width: '100'
        }
      ],
      /*表格右键菜单显示隐藏*/
      tableContextMenuVisible: false,
      tableAction: [{name: '屏蔽该行为'}, {name: '新增标签'}],
      labelList: []

    }
  },
  created() {
    let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 73)
    this.sltConfigKey = item.configKey
    this.sltConfigClassify = item.configClassify
    this.getSettingTable().then(() => {
      this.$nextTick(() => {
        this.setTableHeight()
        //页面初始化完毕，默认触发一次排序
        this.$refs.refundTable.sort('actionTime', 'descending')
      })
    })

  },
  activated() {
    //激活时重新设置表格高度
    this.setTableHeight()
  },
  mounted() {
    var _this = this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      AcrossUtil.$emit('resetTableHeight')
    }

    AcrossUtil.$on('resetTableHeight', function (param) {
      setTimeout(function () {
        _this.setTableHeight()
      }, 300)
    })

  },
  methods: {
    /*设置表格的高度*/
    setTableHeight() {
      // 获取高度值 （内容高+padding+边框）
      let formHeight = this.$refs.headerQuery.offsetHeight
      this.tableHeight = window.innerHeight - formHeight - 150
    },
    // 设置按钮-获取设置的数据
    async getSettingTable() {
      let _this = this
      let obj = {}
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 73)
      this.sltConfigKey = item.configKey
      this.sltConfigClassify = item.configClassify
      obj.configKey = this.sltConfigKey
      this.columnOptions = []
      let res = await UCgetConfig(obj)
      if (res.data && res.data.configValue) {
        let list = JSON.parse(res.data.configValue).list
        if (list.length > 0) {
          list.forEach(function (item) {
            if (item.show == undefined || item.show == true) {
              _this.columnOptions.push(item)
            }
          })
        } else {
          _this.msgError('获取数据表格配置信息失败')
        }
      } else {
        let defaultConfig = this.tableOptions
        defaultConfig.forEach(function (item) {
          if (item.show == undefined || item.show == true) {
            _this.columnOptions.push(item)
          }
        })
      }
      _this.$nextTick(() => {
        _this.$refs.refundTable.doLayout()
      })
    },
    // 设置
    async columnSettings() {
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 73)
      this.sltConfigKey = item.configKey
      this.sltConfigClassify = item.configClassify
      let _this = this
      _this.$ColumnSettings.showColSettings(
        _this.sltConfigKey,
        _this.sltConfigClassify,
        _this.tableOptions,
        _this.getSettingTable
      )
    },
    /*鼠标右键点击事件*/
    rowContextmenu(row, column, event) {
      this.tableContextMenuVisible = false
      this.tableContextMenuVisible = true
      // 阻止右键默认行为
      event.preventDefault()
      this.$nextTick(() => {
        this.$refs.contextbutton.init(row, column, event)
      })
    },
    /* 取消鼠标监听事件 菜单栏*/
    foo() {
      this.tableContextMenuVisible = false
      document.removeEventListener('click', this.foo)
    },
    /* 处理表格右键菜单点击*/
    handleTableAction(actionName, row, column) {
      if (actionName === '屏蔽该行为') {
        this.addFilterAction(row)
      } else if (actionName === '新增标签') {
        this.handleMark(row)
      }
    },
    /*添加到屏蔽列表*/
    addFilterAction(row) {
      addFilterActionKey(row.actionKey, row.url, row.parentNodeContent).then(res => {
        console.log(res);
      })
    },
    /*查询全部api列表 */
    async actionRecordList() {
      this.loading = true
      let params = this.queryParams
      params.actionLabelsStr = params.actionLabels.toString()
      if (params.dateRange && params.dateRange.length == 2) {
        params.beginTime = params.dateRange[0]
        params.endTime = params.dateRange[1]
      }
      let response = await actionRecordList(this.queryParams)
      response.rows.forEach(row => {
        /*将点击内容,加红显示*/
        let node = '<span style="background: #ACD0FD;">' + row.nodeContent + '<span/>'
        row.showNodeContent = row.parentNodeContent.replace(row.nodeContent, node)
      })
      this.tableDataList = response.rows
      this.total = response.total
      this.loading = false
    },
    /*搜索按钮操作*/
    handleQuery() {
      this.isResult = true
      this.queryParams.pageNum = 1
      this.queryParams.beginTime=''
      this.queryParams.endTime=''
      this.actionRecordList()
    },

    /*表格排序切换*/
    tableSortChange({column, prop, order}) {
      if (order != null) {
        order = order == 'descending' ? 'desc' : 'asc'
      } else {
        order = ''
      }
      this.queryParams.orderByColumn = prop
      this.queryParams.isAsc = order
      this.handleQuery()
    },
    /*行为标记*/
    handleMark(row) {
      this.$prompt('请输入标签名称（15字符以内）', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^.{0,15}$/,
        inputErrorMessage: '标签长度不能超过15字符'
      }).then(({value}) => {
        addActionLabel(row.actionKey, value.trim()).then(res => {
          this.msgSuccess("添加成功")
          this.actionRecordList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    // 远程搜索标签名称
    queryLabelList(query) {
      if (!isEmpty(query)) {
        this.loading = true
        selectActionLabelList(query.trim()).then(res => {
          this.labelList = res.data
          this.loading = false
        })
      } else {
        this.labelList = []
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.el-table .cell .el-form-item {
  margin-bottom: 0px;
}


</style>


<style>
.actionRecordDetailListPopover{
  min-height: 100px;
  max-height: 300px;
  overflow: auto;
}

</style>
