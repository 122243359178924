var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "historyUserMatch_wrap layout-container" },
    [
      _c(
        "el-aside",
        { staticClass: "historyUserMatch_left", attrs: { width: "550px" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-tabs",
                    {
                      on: { "tab-click": _vm.handleClick },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _c("el-tab-pane", {
                        attrs: { label: "未处理", name: "0" },
                      }),
                      _c("el-tab-pane", {
                        attrs: { label: "已处理", name: "1" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "right", "line-height": "54px" },
                  attrs: { span: 4 },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: { click: _vm.toRefreshLeftData },
                    },
                    [
                      _c("i", { staticClass: "el-icon-refresh" }),
                      _c("span", [_vm._v("刷新")]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { margin: "5px 0px 10px" } },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "border-left": "5px solid #19B319",
                    "padding-left": "10px",
                    "line-height": "20px",
                  },
                },
                [_vm._v("可能成为历史用户")]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        border: "",
                        stripe: "",
                        "header-cell-style": { "text-align": "center" },
                        "cell-style": { "text-align": "center" },
                        height: "500",
                        "highlight-current-row": "",
                      },
                      on: { "current-change": _vm.handleCurrentChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "openId",
                          label: "OpenID",
                          width: "150",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "wxNumber", label: "微信号" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "relationTypeStr", label: "关联类型" },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "150", fixed: "right" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editGroupInfoClick(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("查看")]
                                ),
                                scope.row.notice === true &&
                                _vm.activeName == "0"
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { color: "#FF3333" },
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.notDealClick(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("不处理")]
                                    )
                                  : _vm._e(),
                                _vm.activeName == "1"
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { color: "#FF3333" },
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.rebackDealClick(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("撤销处理")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.originListTotal > 0,
                        expression: "originListTotal>0",
                      },
                    ],
                    attrs: {
                      total: _vm.originListTotal,
                      page: _vm.originListQuery.pageNum,
                      limit: _vm.originListQuery.pageSize,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.originListQuery, "pageNum", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.originListQuery, "pageSize", $event)
                      },
                      pagination: _vm.getOriginList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-main",
        { staticClass: "historyUserMatch_right" },
        [
          _c(
            "el-row",
            { staticStyle: { margin: "5px 0px 10px" } },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "border-left": "5px solid #19B319",
                    "padding-left": "10px",
                    "line-height": "20px",
                  },
                },
                [_vm._v("历史用户信息 ")]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.nowTableData,
                        border: "",
                        stripe: "",
                        "header-cell-style": { "text-align": "center" },
                        "cell-style": { "text-align": "center" },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "openId",
                          label: "现匹配OpenID",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "nickName", label: "现匹配昵称" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form",
                {
                  attrs: {
                    "label-position": _vm.labelPosition,
                    "label-width": "90px",
                    model: _vm.historyListQuery,
                  },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "微信号/昵称" } },
                                [
                                  _c("el-input", {
                                    attrs: { clearable: "" },
                                    model: {
                                      value: _vm.historyListQuery.wxNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.historyListQuery,
                                          "wxNumber",
                                          $$v
                                        )
                                      },
                                      expression: "historyListQuery.wxNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "真实姓名" } },
                                [
                                  _c("el-input", {
                                    attrs: { clearable: "" },
                                    model: {
                                      value: _vm.historyListQuery.realName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.historyListQuery,
                                          "realName",
                                          $$v
                                        )
                                      },
                                      expression: "historyListQuery.realName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "医院" } },
                                [
                                  _c("el-input", {
                                    attrs: { clearable: "" },
                                    model: {
                                      value: _vm.historyListQuery.hospitalName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.historyListQuery,
                                          "hospitalName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "historyListQuery.hospitalName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "地区" } },
                                [
                                  _c("el-input", {
                                    attrs: { clearable: "" },
                                    model: {
                                      value: _vm.historyListQuery.region,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.historyListQuery,
                                          "region",
                                          $$v
                                        )
                                      },
                                      expression: "historyListQuery.region",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        "line-height": "60px",
                        "padding-left": "10px",
                      },
                      attrs: { span: 4 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            background: "#19B319",
                            color: "#FFFFFF",
                            width: "100px",
                          },
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.getHistoryList()
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData02,
                        border: "",
                        stripe: "",
                        "header-cell-style": { "text-align": "center" },
                        "cell-style": { "text-align": "center" },
                        height: "500",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "id", label: "序号", width: "60" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "nickName",
                          label: "昵称",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "userName",
                          label: "微信号",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "realName",
                          label: "真实姓名",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "hospitalName",
                          label: "医院",
                          width: "200",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "region", label: "所在地" },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "80", fixed: "right" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { color: "#2D5AFA" },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.rightMatchClick(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("确定匹配")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.historyListTotal > 0,
                        expression: "historyListTotal>0",
                      },
                    ],
                    attrs: {
                      total: _vm.historyListTotal,
                      page: _vm.historyListQuery.pageNum,
                      limit: _vm.historyListQuery.pageSize,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.historyListQuery, "pageNum", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(
                          _vm.historyListQuery,
                          "pageSize",
                          $event
                        )
                      },
                      pagination: _vm.getHistoryList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "approvalWrap",
          attrs: {
            title: "查看用户信息",
            visible: _vm.isHistoryGroupInfo,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isHistoryGroupInfo = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: { "line-height": "40px!important" },
              attrs: { id: "lookInfo" },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        "border-left": "5px solid #19B319",
                        "padding-left": "10px",
                        "line-height": "20px",
                      },
                    },
                    [_vm._v("个人信息")]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 真实姓名 ")]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "personalInfo_content",
                      attrs: { span: 18 },
                    },
                    [_vm._v(" " + _vm._s(_vm.originShowBean.realName) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 所在地区 ")]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "personalInfo_content",
                      attrs: { span: 18 },
                    },
                    [_vm._v(" " + _vm._s(_vm.originShowBean.location) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 所在单位 ")]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "personalInfo_content",
                      attrs: { span: 18 },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.originShowBean.hospitalName) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 职业 ")]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "personalInfo_content",
                      attrs: { span: 18 },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.originShowBean.occupationName) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 职称 ")]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "personalInfo_content",
                      attrs: { span: 18 },
                    },
                    [_vm._v(" " + _vm._s(_vm.originShowBean.titleName) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 工牌 ")]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "personalInfo_content",
                      attrs: { span: 18 },
                    },
                    [
                      _c(
                        "el-image",
                        {
                          staticStyle: {
                            width: "210px",
                            height: "130px",
                            "border-radius": "10px",
                          },
                          attrs: {
                            src: _vm.originShowBean.workCardUrl,
                            fit: _vm.fit,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              staticStyle: {
                                width: "210px",
                                height: "130px",
                                "line-height": "130px",
                                "text-align": "center",
                                "border-radius": "10px",
                                background: "#F2F2F2",
                              },
                              attrs: { slot: "error" },
                              slot: "error",
                            },
                            [
                              _c(
                                "i",
                                {
                                  staticClass: "el-icon-picture-outline",
                                  staticStyle: {
                                    color: "#999999",
                                    "font-size": "12px",
                                  },
                                },
                                [_vm._v(" 暂无工牌 ")]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 主攻方向 ")]
                  ),
                  _vm.originShowBean.mainDirectionStrList
                    ? _c(
                        "el-col",
                        {
                          staticClass: "personalInfo_content",
                          attrs: { span: 18 },
                        },
                        _vm._l(
                          _vm.originShowBean.mainDirectionStrList,
                          function (item) {
                            return _c(
                              "ul",
                              {
                                staticClass: "zgfx_ul",
                                staticStyle: { float: "left" },
                              },
                              [
                                _c(
                                  "li",
                                  {
                                    staticClass: "zgfx_li",
                                    staticStyle: {
                                      display: "inline-block",
                                      padding: "0px 5px",
                                      height: "30px",
                                      "line-height": "30px",
                                      background: "#FAEBFF",
                                      border: "1px dashed #CF75EB",
                                      "text-align": "center",
                                      "border-radius": "4px",
                                      "font-size": "14px",
                                      "font-weight": "400",
                                      color: "#CF75EB",
                                      "margin-right": "5px",
                                    },
                                  },
                                  [_vm._v(_vm._s(item))]
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        "border-left": "5px solid #19B319",
                        "padding-left": "10px",
                        "line-height": "20px",
                      },
                    },
                    [_vm._v("匹配历史用户信息")]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 序号 ")]
                  ),
                  _vm.historyShowBean
                    ? _c(
                        "el-col",
                        {
                          staticClass: "personalInfo_content",
                          attrs: { span: 18 },
                        },
                        [_vm._v(" " + _vm._s(_vm.historyShowBean.id) + " ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 真实姓名 ")]
                  ),
                  _vm.historyShowBean
                    ? _c(
                        "el-col",
                        {
                          staticClass: "personalInfo_content",
                          attrs: { span: 18 },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.historyShowBean.realName) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 所在地区 ")]
                  ),
                  _vm.historyShowBean
                    ? _c(
                        "el-col",
                        {
                          staticClass: "personalInfo_content",
                          attrs: { span: 18 },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.historyShowBean.location) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 所在单位 ")]
                  ),
                  _vm.historyShowBean
                    ? _c(
                        "el-col",
                        {
                          staticClass: "personalInfo_content",
                          attrs: { span: 18 },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.historyShowBean.hospitalName) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 职业 ")]
                  ),
                  _vm.historyShowBean
                    ? _c(
                        "el-col",
                        {
                          staticClass: "personalInfo_content",
                          attrs: { span: 18 },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.historyShowBean.occupationName) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 职称 ")]
                  ),
                  _vm.historyShowBean
                    ? _c(
                        "el-col",
                        {
                          staticClass: "personalInfo_content",
                          attrs: { span: 18 },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.historyShowBean.titleName) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 主攻方向 ")]
                  ),
                  _vm.historyShowBean
                    ? _c(
                        "el-col",
                        {
                          staticClass: "personalInfo_content",
                          attrs: { span: 18 },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.historyShowBean.medicalDirectionName) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "personalInfo" },
                [
                  _c(
                    "el-col",
                    { staticClass: "personalInfo_label", attrs: { span: 6 } },
                    [_vm._v(" 群信息 ")]
                  ),
                  _vm.historyShowBean && _vm.historyShowBean.groupStrList
                    ? _c(
                        "el-col",
                        {
                          staticClass: "personalInfo_content",
                          attrs: { span: 18 },
                        },
                        _vm._l(
                          _vm.historyShowBean.groupStrList,
                          function (item) {
                            return _c(
                              "ul",
                              {
                                staticClass: "zgfx_ul",
                                staticStyle: { float: "left" },
                              },
                              [
                                _c(
                                  "li",
                                  {
                                    staticStyle: {
                                      display: "inline-block",
                                      padding: "0px 5px",
                                      height: "30px",
                                      "line-height": "30px",
                                      "/*background": "#FAEBFF",
                                      "*/\n                /*border":
                                        "1px dashed #CF75EB",
                                      "*/\n                text-align":
                                        "center",
                                      "border-radius": "4px",
                                      "font-size": "14px",
                                      "font-weight": "400",
                                      color: "#19B319",
                                      "margin-right": "5px",
                                    },
                                  },
                                  [_vm._v(_vm._s(item))]
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }