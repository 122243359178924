<!---机构和平台---查看报告的时候显示的已匹配表格-->
<template>
  <el-table
    v-if="false"
    :data="tableData"
    :span-method="getSummaries"
    :header-cell-class-name="headerGradeInfo"
    :cell-class-name="gradeInfo"
    style="width: 100%;"
    height="100%"
    class="grade-table"
    border>
    <el-table-column
      align="center"
      width="160"
      prop="ustdCode">
      <template slot="header" slot-scope="scope">
        <span>药品编码</span>
        <el-popover
          ref="elPopover"
          trigger="click"
          placement="right"
          popper-class="governance-poper"
          :visible-arrow="false"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <filter-cascader
            :ref="'filter' + scope.$index"
            :show-filter="tableSearchObj.isFilter"
            :filter-options="filterOptions"
            :column="scope"
            @closeFilter="onCloseFilter" />
          <svg-icon
            slot="reference"
            icon-class="shaixuan"
            class="filter-icon"
            v-if="tableSearchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
        </el-popover>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.ustdCode }}</span>
      </template>
    </el-table-column>
    <el-table-column
      width="180">
      <template slot="header" slot-scope="scope">
        <span>药品通用名</span>
        <el-popover
          ref="elPopover"
          trigger="click"
          placement="right"
          popper-class="governance-poper"
          :visible-arrow="false"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <filter-cascader
            :ref="'filter' + scope.$index"
            :show-filter="tableSearchObj.isFilter"
            :filter-options="filterOptions"
            :column="scope"
            @closeFilter="onCloseFilter" />
          <svg-icon
            slot="reference"
            icon-class="shaixuan"
            class="filter-icon"
            v-if="tableSearchObj.isFilter"
            style="position: absolute; right: 10px;width: 20px;height: 20px;"
            @click="onFilterClick(scope)"/>
        </el-popover>
      </template>
      <template slot-scope="scope">
        <span>{{ scope.row.genericNameCompare }}</span>
      </template>
    </el-table-column>
    <el-table-column
      width="50"
      class="none-border">
      <template slot-scope="scope">
        <span>{{ scope.row.genericNameCompareStatus }}</span>
      </template>
    </el-table-column>
    <el-table-column
      label="规格">
      <template slot-scope="scope">
        <span>{{ scope.row.specCompare }}</span>
      </template>
    </el-table-column>
    <el-table-column
      width="50"
      prop="specCompareStatus">
      <template slot-scope="scope">
        <el-popover
          :ref="'popoverspecCompareStatus' + scope.$index"
          trigger="click"
          placement="bottom"
          :visible-arrow="false"
          popper-class="governance-poper"
          @hide="onHidePopover(scope)"
          @show="onShowPopover(scope)">
          <grade-cascader
            :ref="'filterspecCompareStatus' + scope.$index"
            :status="scope.row.specCompareStatus"
            :show-filter="tableSearchObj.isFilter"
            :filter-options="gradeOptions"
            @closeFilter="onCloseFilter(scope)" />
          <div slot="reference" @click="onGradeFilterClick(scope)">
            <svg-icon
              :icon-class="gradeIcon(scope.row.specCompareStatus)"
              class="filter-icon"
              v-if="tableSearchObj.isFilter"
              style="position: absolute; right: 16px;top: 50%;transform: translateY(-50%);width: 18px;height: 18px;"/>
              <transition>
                <i v-if="scope.row.isFolded" class="el-icon-caret-bottom icon-class"/>
                <i v-else class="el-icon-caret-top icon-class"/>
              </transition>
          </div>
        </el-popover>
      </template>
    </el-table-column>
    <el-table-column
      label="剂型名称"
      width="180">
      <template slot-scope="scope">
        <span>{{ scope.row.formNameCompare }}</span>
      </template>
    </el-table-column>
    <el-table-column
      width="50">
      <template slot-scope="scope">
        <span>{{ scope.row.formNameCompareStatus }}</span>
      </template>
    </el-table-column>
    <el-table-column
      label="厂商">
      <template slot-scope="scope">
        <span>{{ scope.row.manufacturerCompare }}</span>
      </template>
    </el-table-column>
    <el-table-column
      width="50">
      <template slot-scope="scope">
        <span>{{ scope.row.manufacturerCompareStatus }}</span>
      </template>
    </el-table-column>
    <el-table-column
      label="批准文号">
      <template slot-scope="scope">
        <span>{{ scope.row.approvalNoCompare }}</span>
      </template>
    </el-table-column>
    <el-table-column
      width="50">
      <template slot-scope="scope">
        <span>{{ scope.row.approvalNoCompareStatus }}</span>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
  import {
    FILTER_OPTIONS,
    GRADE_OPTIONS,
    TABLE_DATA_ORIGIN
  } from '@/utils/dm/institutionGovernanceReport/data'
  import {
    handleTableData
  } from '@/utils/dm/institutionGovernanceReport/handle.js'
  import { getNoMatchRecordListApi } from '@/api/dm/institutionGovernanceReport'
  export default {
    name: 'institutionMatchReport',
    props: {
      searchObj: {
        required: true,
        type: Object
      },
      applyId: {
        type: Number,
        required: true
      }
    },
    filters: {
      noMapReason(reason) {
        if (reason) {
          return reason.join(',')
        }
      }
    },
    computed: {
      noMapReasonList: function () {
        return function (list) {
          if (list && list.length > 1) {
            return [list[0], '...']
          }
        }
      }
    },
    watch: {
      searchObj: {
        async handler(newVal, oldVal) {
          // await this.fetchData()
        },
        deep: true
      }
    },
    data() {
      return {
        tableData: []
      }
    },
    computed: {
    },
    async mounted() {
    },
    async created() {
      this.searchObj.applyId = this.applyId
      // await this.fetchData()
      await this.fetchTableData()
    },
    methods: {
      async fetchData() {
        let res = await getNoMatchRecordListApi(this.searchObj)
        if (res.code === 200) {
          this.tableData = res.data.rows || []
          this.$emit('matchTable', res.data)
        }
      },
      async fetchTableData() {
        const tableData = this.$deepClone(TABLE_DATA_ORIGIN)
        let data = handleTableData(tableData)
        data.forEach(item => {
          item.isFolded = true // 打分的图标初始时为折叠状态
        })
        this.tableData = this.$deepClone(data)
        this.setMergeArr(this.tableData)
      },
      setMergeArr(data) {　
        this.spanArr = []
        for (var i = 0; i < data.length; i++) {
          if (i === 0) {
            this.spanArr.push(1)
            this.pos = 0
          } else {
              // 判断当前元素与上一个元素是否相同
            if (data[i].ustdCode === data[i - 1].ustdCode) {
              this.spanArr[this.pos] += 1
              this.spanArr.push(0)
            } else {
              this.spanArr.push(1)
              this.pos = i
            }
          }
        }
      },
      getSummaries({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0) { // 设置需要合并的列（索引）
          if (rowIndex % 2 === 0) { // 设置合并多少行
            return {
              rowspan: 2, // 需要合并的行数
              colspan: 1 // 需要合并的列数，设置为0，不显示该列
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        } else if (columnIndex % 2 === 0) { // 合并第二列所有行
          const _row = this.spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col
          }
        }
      },
      // 表头渲染
      // renderFilter(creatElement, { column, $index }) {
      //   return (
      //     <span class='el-dropdown-link' style="color:#ffffff"> 等级 <i class='el-icon-sort el-icon--right'></i></span>
      //   )
      // },
      headerGradeInfo({ row, column, rowIndex, columnIndex }) {
        if (columnIndex % 2 === 1 && columnIndex !== 0) {
          return 'none-border'
        }
        if (columnIndex === 4) {
          console.log(column)
        }
      },
      gradeInfo({ row, column, rowIndex, columnIndex }) {
        let color = this.tableDataHandle(row, column, rowIndex, columnIndex)
        return color
      },
      tableDataHandle(row, column, rowIndex, columnIndex) {
        for (let i = 0; i < this.tableData.length ; i++) {
          let item = this.tableData[i]
          // let nextItem = this.tableData[i + 1]
          // 药品通用名
          if (item.genericNameCompareStatus === '1' && i === rowIndex && columnIndex % 2 === 1) {
            item.className = 'error-color'
            // nextItem.className = 'error-color'
            return 'error-color'
          } else if (item.genericNameCompareStatus === '2' && i === rowIndex && columnIndex % 2 === 1) {
            item.className = 'warning-color'
            // nextItem.className = 'warning-color'
            return 'warning-color'
          }
          // 规格
          if (item.specCompareStatus === '1' && i === rowIndex && columnIndex % 2 === 1) {
            item.className = 'error-color'
            // nextItem.className = 'error-color'
            return 'error-color'
          } else if (item.specCompareStatus === '2' && i === rowIndex && columnIndex % 2 === 1) {
            item.className = 'warning-color'
            // nextItem.className = 'warning-color'
            return 'warning-color'
          }
          // 剂型名称
          if (item.formNameCompareStatus === '1' && i === rowIndex && columnIndex % 2 === 1) {
            item.className = 'error-color'
            // nextItem.className = 'error-color'
            return 'error-color'
          } else if (item.formNameCompareStatus === '2' && i === rowIndex && columnIndex % 2 === 1) {
            item.className = 'warning-color'
            // nextItem.className = 'warning-color'
            return 'warning-color'
          }
          // 厂商
          if (item.manufacturerCompareStatus === '1' && i === rowIndex && columnIndex % 2 === 1) {
            item.className = 'error-color'
            // nextItem.className = 'error-color'
            return 'error-color'
          } else if (item.manufacturerCompareStatus === '2' && i === rowIndex && columnIndex % 2 === 1) {
            item.className = 'warning-color'
            // nextItem.className = 'warning-color'
            return 'warning-color'
          }
          // 批准文号
          if (item.approvalNoCompareStatus === '1' && i === rowIndex && columnIndex % 2 === 1) {
            item.className = 'error-color'
            // nextItem.className = 'error-color'
            return 'error-color'
          } else if (item.approvalNoCompareStatus === '2' && i === rowIndex && columnIndex % 2 === 1) {
            item.className = 'warning-color'
            // nextItem.className = 'warning-color'
            return 'warning-color'
          }
        }
      },
      // 筛选模式使用的组件popover隐藏时的事件
      onHidePopover(scope) {
        const filterName = 'filter' + scope.$index
        this.$nextTick(() => {
          this.tableData.forEach(item => {
            item.isFolded = true // 打分的图标初始时为折叠状态
          })
          if (this.$refs[filterName] && this.$refs[filterName].filterOptions) {
            this.$refs[filterName].filterOptions.forEach(option => {
              option.showChildren = false
            })
          }
          if (this.filterOptions) {
            this.filterOptions.forEach(option => {
              option.showChildren = false
            })
          }
        })
      },
      // 筛选模式使用的组件popover显示时的事件
      onShowPopover(scope) {
        const filterName = 'filter' + scope.$index
        this.$nextTick(() => {
          if (this.$refs[filterName]) {
            this.$refs[filterName].checkList = []
            this.$refs[filterName].isShowOptions = true
          }
        })
      },
      // 筛选模式，点击筛选图标事件
      onFilterClick(scope) {
        console.log(scope)
        const filterName = 'filter' + scope.$index
        this.$nextTick(() => {
          if (this.$refs[filterName]) {
            this.$refs[filterName].onFilterClick()
          }
        })
      },
      onGradeFilterClick(scope) {
        console.log(scope)
        scope.row.isFolded = false // 展开
        const refName = 'filter' + scope.column.property + scope.$index
        this.$nextTick(() => {
          if (this.$refs[refName]) {
            this.$refs[refName].onFilterClick()
          }
        })
      },
      // 打分下拉项关闭
      onCloseFilter(scope) {
        this.tableData.forEach(item => {
          item.isFolded = true // 打分的图标初始时为折叠状态
        })
        const refName = 'popover' + scope.column.property + scope.$index
        this.$nextTick(() => {
          if (this.$refs.elPopover) {
            // this.$refs.elPopover.$listeners.hide()
            this.$refs[refName].doClose()
          }
        })
      }
    }
  }
</script>
