var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return false
    ? _c(
        "el-table",
        {
          staticClass: "grade-table",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "span-method": _vm.getSummaries,
            "header-cell-class-name": _vm.headerGradeInfo,
            "cell-class-name": _vm.gradeInfo,
            height: "100%",
            border: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", width: "160", prop: "ustdCode" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function (scope) {
                    return [
                      _c("span", [_vm._v("药品编码")]),
                      _c(
                        "el-popover",
                        {
                          ref: "elPopover",
                          attrs: {
                            trigger: "click",
                            placement: "right",
                            "popper-class": "governance-poper",
                            "visible-arrow": false,
                          },
                          on: {
                            hide: function ($event) {
                              return _vm.onHidePopover(scope)
                            },
                            show: function ($event) {
                              return _vm.onShowPopover(scope)
                            },
                          },
                        },
                        [
                          _c("filter-cascader", {
                            ref: "filter" + scope.$index,
                            attrs: {
                              "show-filter": _vm.tableSearchObj.isFilter,
                              "filter-options": _vm.filterOptions,
                              column: scope,
                            },
                            on: { closeFilter: _vm.onCloseFilter },
                          }),
                          _vm.tableSearchObj.isFilter
                            ? _c("svg-icon", {
                                staticClass: "filter-icon",
                                staticStyle: {
                                  position: "absolute",
                                  right: "10px",
                                  width: "20px",
                                  height: "20px",
                                },
                                attrs: {
                                  slot: "reference",
                                  "icon-class": "shaixuan",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onFilterClick(scope)
                                  },
                                },
                                slot: "reference",
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.ustdCode))])]
                  },
                },
              ],
              null,
              false,
              2339575288
            ),
          }),
          _c("el-table-column", {
            attrs: { width: "180" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function (scope) {
                    return [
                      _c("span", [_vm._v("药品通用名")]),
                      _c(
                        "el-popover",
                        {
                          ref: "elPopover",
                          attrs: {
                            trigger: "click",
                            placement: "right",
                            "popper-class": "governance-poper",
                            "visible-arrow": false,
                          },
                          on: {
                            hide: function ($event) {
                              return _vm.onHidePopover(scope)
                            },
                            show: function ($event) {
                              return _vm.onShowPopover(scope)
                            },
                          },
                        },
                        [
                          _c("filter-cascader", {
                            ref: "filter" + scope.$index,
                            attrs: {
                              "show-filter": _vm.tableSearchObj.isFilter,
                              "filter-options": _vm.filterOptions,
                              column: scope,
                            },
                            on: { closeFilter: _vm.onCloseFilter },
                          }),
                          _vm.tableSearchObj.isFilter
                            ? _c("svg-icon", {
                                staticClass: "filter-icon",
                                staticStyle: {
                                  position: "absolute",
                                  right: "10px",
                                  width: "20px",
                                  height: "20px",
                                },
                                attrs: {
                                  slot: "reference",
                                  "icon-class": "shaixuan",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onFilterClick(scope)
                                  },
                                },
                                slot: "reference",
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(scope.row.genericNameCompare)),
                      ]),
                    ]
                  },
                },
              ],
              null,
              false,
              762239002
            ),
          }),
          _c("el-table-column", {
            staticClass: "none-border",
            attrs: { width: "50" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(scope.row.genericNameCompareStatus)),
                      ]),
                    ]
                  },
                },
              ],
              null,
              false,
              1123583939
            ),
          }),
          _c("el-table-column", {
            attrs: { label: "规格" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.specCompare))])]
                  },
                },
              ],
              null,
              false,
              2510600292
            ),
          }),
          _c("el-table-column", {
            attrs: { width: "50", prop: "specCompareStatus" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-popover",
                        {
                          ref: "popoverspecCompareStatus" + scope.$index,
                          attrs: {
                            trigger: "click",
                            placement: "bottom",
                            "visible-arrow": false,
                            "popper-class": "governance-poper",
                          },
                          on: {
                            hide: function ($event) {
                              return _vm.onHidePopover(scope)
                            },
                            show: function ($event) {
                              return _vm.onShowPopover(scope)
                            },
                          },
                        },
                        [
                          _c("grade-cascader", {
                            ref: "filterspecCompareStatus" + scope.$index,
                            attrs: {
                              status: scope.row.specCompareStatus,
                              "show-filter": _vm.tableSearchObj.isFilter,
                              "filter-options": _vm.gradeOptions,
                            },
                            on: {
                              closeFilter: function ($event) {
                                return _vm.onCloseFilter(scope)
                              },
                            },
                          }),
                          _c(
                            "div",
                            {
                              attrs: { slot: "reference" },
                              on: {
                                click: function ($event) {
                                  return _vm.onGradeFilterClick(scope)
                                },
                              },
                              slot: "reference",
                            },
                            [
                              _vm.tableSearchObj.isFilter
                                ? _c("svg-icon", {
                                    staticClass: "filter-icon",
                                    staticStyle: {
                                      position: "absolute",
                                      right: "16px",
                                      top: "50%",
                                      transform: "translateY(-50%)",
                                      width: "18px",
                                      height: "18px",
                                    },
                                    attrs: {
                                      "icon-class": _vm.gradeIcon(
                                        scope.row.specCompareStatus
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              _c("transition", [
                                scope.row.isFolded
                                  ? _c("i", {
                                      staticClass:
                                        "el-icon-caret-bottom icon-class",
                                    })
                                  : _c("i", {
                                      staticClass:
                                        "el-icon-caret-top icon-class",
                                    }),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              471426213
            ),
          }),
          _c("el-table-column", {
            attrs: { label: "剂型名称", width: "180" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row.formNameCompare))]),
                    ]
                  },
                },
              ],
              null,
              false,
              1013438768
            ),
          }),
          _c("el-table-column", {
            attrs: { width: "50" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(scope.row.formNameCompareStatus)),
                      ]),
                    ]
                  },
                },
              ],
              null,
              false,
              3046466532
            ),
          }),
          _c("el-table-column", {
            attrs: { label: "厂商" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(scope.row.manufacturerCompare)),
                      ]),
                    ]
                  },
                },
              ],
              null,
              false,
              2699984566
            ),
          }),
          _c("el-table-column", {
            attrs: { width: "50" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(scope.row.manufacturerCompareStatus)),
                      ]),
                    ]
                  },
                },
              ],
              null,
              false,
              2856777314
            ),
          }),
          _c("el-table-column", {
            attrs: { label: "批准文号" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row.approvalNoCompare))]),
                    ]
                  },
                },
              ],
              null,
              false,
              1152080423
            ),
          }),
          _c("el-table-column", {
            attrs: { width: "50" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(scope.row.approvalNoCompareStatus)),
                      ]),
                    ]
                  },
                },
              ],
              null,
              false,
              2688782835
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }